<template>
    <f7-card outline>
        <f7-card-header class="display-flex justify-content-space-between align-items-center">
            <div class="display-flex align-items-center">
                <font-awesome-icon icon="syringe" size="lg" class="margin-right"></font-awesome-icon>
                <div>
                    <small>Vacuna agregada</small>
                    <div>
                        <b>{{ vaccineCardTitle }}</b>
                    </div>
                </div>
            </div>
            <div class="display-flex align-items-center">
                <div class="display-flex align-items-center">
                    <template v-if="canEdit">
                        <f7-button
                            tooltip="Editar vacuna"
                            @click="emitEditingVaccine(vaccine.id)"
                            class="no-padding-right"
                        >
                            <font-awesome-icon size="lg" icon="edit" color="gray"></font-awesome-icon>
                        </f7-button>
                        <f7-button
                            tooltip="Eliminar vacuna"
                            @click="emitDeleteVaccine(vaccine.id)"
                            class="no-padding-right"
                        >
                            <font-awesome-icon size="lg" icon="trash-alt" color="gray"></font-awesome-icon>
                        </f7-button>
                    </template>
                </div>

                <div class="display-flex align-items-center">
                    <f7-button :tooltip="showLess ? 'Mostrar más' : 'Mostrar menos'" class="margin-left-half" @click="showLess = !showLess">
                        <font-awesome-icon size="lg" :icon="showLess ? 'caret-down' : 'caret-up'" color="gray"></font-awesome-icon>
                    </f7-button>
                </div>
            </div>
        </f7-card-header>
        <f7-card-content class="no-padding-top" v-if="!showLess">
            <f7-row>
                <f7-col>
                    <f7-list>
                        <f7-list-item header="Vacuna recibida" :title="vaccine.vac.name"></f7-list-item>
                        <!-- <f7-list-item header="Comuna Establecimiento" :title="vaccine.address ? vaccine.address.comuna : 'No indica'"></f7-list-item> -->
                        <f7-list-item header="Número de lote" :title="vaccine.lote || 'No indica'"></f7-list-item>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list>
                        <f7-list-item header="Establecimiento" :title="vaccine.establecimiento || 'No indica'"></f7-list-item>
                        <f7-list-item header="Fecha de vacunación" :title="vaccine.fechavac"></f7-list-item>
                    </f7-list>
                </f7-col>
            </f7-row>
        </f7-card-content>
    </f7-card>
</template>

<script>
import Alert from "../../../components/Alert.vue";

export default {
    components: {
        "f7c-alert": Alert,
    },

    props: {
        solicitud: Object,
        vaccine: Object,
        patientIsVerified: Boolean,
        solicitudIsTakenByMe: Boolean,
    },

    data() {
        return {
            showLess: true,
        };
    },

    computed: {

        vaccineCardTitle() {
            return `${this.vaccine.vac.name || "Vacuna no identificada"} - ${this.vaccine.fechavac || "Sin fecha de vacunación"}`;
        },

        canEdit() {
            return this.patientIsVerified && this.solicitudIsTakenByMe && 
                this.solicitud.est !== "Cerrada" && this.solicitud.est !== "Aceptada";
        }
    },

    methods: {

        emitDeleteVaccine(vaccineId) {
            this.$f7.dialog.confirm("¿Está seguro de eliminar esta vacuna?", "Eliminar vacuna", () => {
                this.$emit("vaccine-removed", vaccineId);
            });
        },

        emitEditingVaccine(vaccineId) {
            this.$emit("vaccine-editing", vaccineId);
        },
    },
};
</script>
