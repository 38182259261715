<template>
    <f7-list class="no-margin-top" media-list>
        <ul>
            <f7-list-item v-if="solicitudes && !solicitudes.length" title="No hay solicitudes"> </f7-list-item>

            <solicitud-list-item
                v-for="solicitud in solicitudes"
                :key="solicitud.id"
                :solicitud="solicitud"
                :list-type="listType"
                @tomar-solicitud="handleTomarSolicitud"
            ></solicitud-list-item>
        </ul>
    </f7-list>
</template>

<script>
import { mapState } from "vuex";
import SolicitudListItem from "./solicitud-list-item.vue";

export default {
    components: {
        "solicitud-list-item": SolicitudListItem,
    },

    props: {
        listType: String,
        solicitudes: Array,
    },

    computed: {
        ...mapState(["user", "dev"]),
    },

    methods: {
        async handleTomarSolicitud(payload) {
            if (!this.user) return;

            const { solicitud } = payload;

            if (solicitud.est === "En revisión" || solicitud.est === "Aceptada" || solicitud.est === "Cerrada") {
                this.$store.commit("homologacionVacunas/set_current_solicitud_object", solicitud);

                this.$f7.views.main.router.navigate("/gestor_homologacion_vacunas/resolving");

                this.$emit("solicitud-taken", true);
            } else {
                try {
                    this.$f7.dialog.preloader("Tomando solicitud");

                    const toggleTakeSolicitudHomologacion = this.$firebase.functions().httpsCallable("admin-toggleTakeSolicitudHomologacion");

                    const { data } = await toggleTakeSolicitudHomologacion({
                        solicitudId: solicitud.id,
                        dev: this.dev ? true : false,
                        take: true,
                    });

                    if (data.error) {
                        throw new Error(data.error.message);
                    }

                    const updatedSolicitud = Object.assign({}, solicitud, data.solicitudUpdate);

                    this.$store.commit("homologacionVacunas/set_current_solicitud_object", updatedSolicitud);

                    this.$emit("solicitud-taken", true);

                    this.$f7.views.main.router.navigate("/gestor_homologacion_vacunas/resolving");

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            }
        },
    },
};
</script>
