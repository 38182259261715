<template>
    <div>
        <div v-if="vaccineStatus === 'Rechazada' && vaccineDecisionRejectedIsFinal" class="margin-bottom text-color-red">
            <div>{{ vaccineStatusInfo }}</div>
            <b>{{ vaccineRejectionReason }}</b>
        </div>
        <div v-else-if="vaccineStatus === 'Aceptada' && vaccineDecisionIsFinal" class="margin-bottom text-color-primary">
            <div>{{ vaccineStatusInfo }}</div>
        </div>

        <div v-if="canShowResolutionOptions">
            <p>
                <b>Decisión sobre esta vacuna</b>
            </p>
            <f7-input type="select" @change="handleDecisionChanged">
                <option value="">Seleccione</option>
                <option value="Aceptada" :disabled="vaccineStatus === 'Aceptada'" :selected="vaccineStatus === 'Aceptada'">Aceptar vacuna</option>
                <option value="Rechazada" :disabled="vaccineStatus === 'Rechazada'" :selected="vaccineStatus === 'Rechazada'">Rechazar vacuna</option>
            </f7-input>

            <div v-if="selectedDecision === 'Rechazada'">
                <p>
                    <b>Motivo de rechazo</b>
                </p>
                <f7-input type="select" @change="handleMotivoRechazoChanged">
                    <option value="">Seleccione motivo de rechazo</option>
                    <option v-for="(motivo, index) in motivosRechazo" :key="index" :value="motivo.motiv">{{ motivo.motiv }}</option>
                </f7-input>
            </div>

            <div v-if="selectedMotivoRechazo">
                <p>
                    <b>Instrucciones para el usuario</b>
                </p>
                <f7-input
                    type="textarea"
                    :value="selectedMotivoRechazoEdited"
                    @blur="handleMotivoRechazoEditedChanged"
                    class="margin-bottom-half"
                    resizable
                ></f7-input>
                <small class="text-color-gray">Este campo es editable</small>
            </div>
        </div>
        <div v-else-if="!vaccineDecisionIsFinal">
            <span v-if="locked">No puedes decidir sobre esta vacuna ya que otro usuario la esta resolviendo.</span>
            <span v-else-if="!patientIsVerified">No puedes decidir sobre esta vacuna porque el paciente no ha sido verificado.</span>
            <span v-else-if="solicitudStatus === 'Cerrada'">No puedes decidir sobre esta vacuna porque la solicitud fue cerrada.</span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        solicitudStatus: String,
        vaccine: Object,
        locked: Boolean,
        patientIsVerified: Boolean,
    },

    data() {
        return {
            selectedDecision: "",
            selectedMotivoRechazo: "",
            selectedMotivoRechazoEdited: "",
        };
    },

    computed: {
        ...mapState("homologacionVacunas", ["motivosRechazo"]),

        vaccineStatus() {
            return this.vaccine.est && this.vaccine.est.resol;
        },
        vaccineDecisionIsFinal() {
            return this.vaccine.est && this.vaccineStatus === "Aceptada" && this.vaccine.est.when;
        },
        vaccineDecisionRejectedIsFinal() {
            return this.vaccine.est && this.vaccineStatus === "Rechazada" && this.vaccine.est.when;
        },
        vaccineStatusInfo() {
            if (!this.vaccine.est) return "";

            const by = this.vaccine.est.by;
            const status = this.vaccine.est.resol;
            const when = this.$dayjs.unix(this.vaccine.est.when).format("DD/MM/YYYY HH:mm");

            let statusText = `${status} por: ${by} el ${when}.`;

            if (status === "Aceptada") {
                statusText += ` Registrada a paciente ${this.vaccine.est.toPaxid || ''}`;
            }

            return statusText;
        },
        vaccineRejectionReason() {
            return `Motivo: ${(this.vaccine.est && this.vaccine.est.motiv) || "Sin motivo seleccionado"}`;
        },

        canShowResolutionOptions() {
            return !this.locked && this.patientIsVerified && this.solicitudStatus !== "Aceptada" && this.solicitudStatus !== "Cerrada" && !this.vaccineDecisionIsFinal;
        },
    },

    created() {
        if (this.vaccine.est) {
            this.selectedDecision = this.vaccine.est.resol;
            this.selectedMotivoRechazo = this.vaccine.est.motiv;
            this.selectedMotivoRechazoEdited = this.vaccine.est.motiv;
        }
    },

    methods: {
        handleDecisionChanged(e) {
            this.selectedDecision = e.target.value;

            this.selectedMotivoRechazo = "";
            this.selectedMotivoRechazoEdited = "";

            this.$emit("resolution-changed", {
                whatChanged: "decision",
                vaccine: this.vaccine,
                decision: this.selectedDecision,
            });
        },
        handleMotivoRechazoChanged(e) {
            this.selectedMotivoRechazo = e.target.value;
            this.selectedMotivoRechazoEdited = e.target.value;

            this.$emit("resolution-changed", {
                whatChanged: "reject-reason",
                vaccine: this.vaccine,
                reason: this.selectedMotivoRechazoEdited,
            });
        },
        handleMotivoRechazoEditedChanged(e) {
            this.selectedMotivoRechazoEdited = e.target.value;

            this.$emit("resolution-changed", {
                whatChanged: "reject-reason",
                vaccine: this.vaccine,
                reason: this.selectedMotivoRechazoEdited,
            });
        },
    },
};
</script>
