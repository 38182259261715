<template>
    <f7-page name="vacunatorio">
        <f7-navbar :title="vacunatorioEnFoco.name" subtitle="Vacunatorio" back-link="Back"></f7-navbar>

        <f7-block-title>Datos vacunatorio</f7-block-title>
        <f7-list inset class="no-margin-vertical marfixicons">
            <f7-list-item @click="updateValue('name')" link="#" header="Nombre" :title="vacunatorioEnFoco.name">
                <f7-preloader slot="after" v-if="updt.name"></f7-preloader>
                <font-awesome-icon v-else slot="after" icon="pencil-alt"></font-awesome-icon>
                <font-awesome-icon slot="media" icon="hospital-alt" class="fa-lg text-color-primary" />
            </f7-list-item>
            <f7-list-item @click="updateValue('direccion')" link="#" header="Dirección" :title="vacunatorioEnFoco.direccion">
                <f7-preloader slot="after" v-if="updt.direccion"></f7-preloader>
                <font-awesome-icon v-else slot="after" icon="pencil-alt"></font-awesome-icon>
                <font-awesome-icon slot="media" icon="map-marker-alt" class="fa-lg text-color-primary" />
            </f7-list-item>
            <f7-list-item @click="updateValue('deis')" link="#" header="Código DEIS Vacunatorio" :title="vacunatorioEnFoco.deis">
                <f7-preloader slot="after" v-if="updt.deis"></f7-preloader>
                <font-awesome-icon v-else slot="after" icon="pencil-alt"></font-awesome-icon>
                <font-awesome-icon slot="media" icon="hospital-alt" class="fa-lg text-color-primary" />
            </f7-list-item>

            <f7-list-item
                header="Comuna"
                :title="`${vacunatorioEnFoco.address.name} (${vacunatorioEnFoco.address.provname})`"
                :footer="vacunatorioEnFoco.address.region"
            >
                <font-awesome-icon slot="media" icon="map-marked-alt" class="fa-lg text-color-primary" />
            </f7-list-item>
        </f7-list>

        <f7-block class="no-margin-bottom">
            Números de teléfono
        </f7-block>
        <f7-list inset class="no-margin-top margin-bottom marfixicons">
            <f7-list-item
                v-if="!vacunatorioEnFoco.phones || !Object.keys(vacunatorioEnFoco.phones).length"
                title="Sin números de teléfono de contacto para este vacunatorio"
            ></f7-list-item>
            <f7-list-item v-for="(phone, id) in vacunatorioEnFoco.phones" :key="id" :title="phone">
                <div slot="after" class="linkpad">
                    <f7-link color="gray" @click="phoneModela('del', id)"><font-awesome-icon icon="trash-alt" class="fa-lg linkmarleft"/></f7-link>
                </div>
                <font-awesome-icon slot="media" icon="phone-volume" class="fa-lg text-color-primary" />
            </f7-list-item>
        </f7-list>
        <f7-block class="no-margin-top" v-if="!vacunatorioEnFoco.phones || Object.keys(vacunatorioEnFoco.phones).length < 4"
            ><f7-button @click="promtpPhone()"> <font-awesome-icon icon="plus"></font-awesome-icon> Agregar número de teléfono</f7-button></f7-block
        >

        <f7-block>
            <f7-button v-if="!vacunatorioEnFoco.inactive" color="red" @click="preinactivar()"
                ><f7-preloader v-if="updt.inactive"></f7-preloader><span v-else>Inactivar Vacunatorio</span></f7-button
            >
            <f7-button v-else color="teal" @click="preinactivar()" fill raised large
                ><f7-preloader v-if="updt.inactive"></f7-preloader><span v-else>Re-activar Vacunatorio</span></f7-button
            >
        </f7-block>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],
    props: ["id"],
    data() {
        return {
            updt: {
                name: false,
                direccion: false,
                deis: false,
                inactive: false,
            },
            flds: {
                name: "un nuevo nombre",
                direccion: "una nueva dirección",
                deis: "un nuevo código DEIS",
            },
            toastCenter: null,
        };
    },
    computed: {
        vacunatorioEnFoco() {
            return this.$store.getters.getUno("vacunatorios", this.id);
        },
    },
    methods: {
        preinactivar() {
            if (this.vacunatorioEnFoco.inactive) {
                this.guardarCambio({ cual: "inactive", valor: false });
            } else {
                this.$f7.dialog.confirm("Cuentas de ese vacunatorio perderán acceso", "Confirme que desea inactivar este vacunatorio", () => {
                    this.guardarCambio({ cual: "inactive", valor: true });
                });
            }
        },
        updateValue(cual) {
            return this.$f7.dialog.prompt(
                "Indique " + this.flds[cual],
                cambio => {
                    if (!cambio) {
                        return this.$f7.dialog.alert("Nueva propiedad no puede ser vacía", "Error");
                    }
                    //  console.log('nuevo es',cual,cambio);
                    this.guardarCambio({
                        cual: cual,
                        valor: cambio.trim(),
                    });
                },
                () => {},
                this[cual]
            );
        },
        async guardarCambio({ cual, valor }) {
            try {
                this.updt[cual] = true;
                let datous = await this.commonExecute({ id: this.id, cual, valor }, "admin-editaVacunatorioInfo", false);
                this.updt[cual] = false;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                //console.log('creacion ok',datous);
                let nueaprop = {};
                nueaprop[cual] = valor;
                let nuevovac = Object.assign({}, this.$store.getters.getUno("vacunatorios", this.id), nueaprop);

                if (cual === "name") {
                    nuevovac.norm = nuevovac.name
                        .trim()
                        .split(" ")
                        .join("")
                        .toUpperCase();
                }
                this.$store.commit("updtObjProperty", { what: "vacunatorios", to: nuevovac });
                if (!this.toastCenter) {
                    this.toastCenter = this.$f7.toast.create({
                        text: "Cambios guardados OK",
                        position: "center",
                        closeTimeout: 2000,
                    });
                }
                this.toastCenter.open();
            } catch (error) {
                this.updt[cual] = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },

        promtpPhone() {
            this.$f7.dialog.prompt(
                "Indique un teléfono de contacto",
                "Nuevo teléfono",
                newvalue => {
                    if (!newvalue || newvalue.length < 7) {
                        return this.$f7.dialog.alert("El numero de teléfono " + newvalue + " parece inválido", "teléfono inválido");
                    }
                    this.phoneModela("add", newvalue);
                },
                () => {},
                "+56"
            );
        },
        async phoneModela(acciontype, valor) {
            try {
                this.$f7.dialog.preloader("Guardando cambios...");
                let datous = await this.commonExecute({ id: this.id, acciontype, valor }, "admin-editaVacunatoriosPhones", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                this.$f7.dialog.close();
                // console.log('creacion ok',datous);
                if (acciontype == "add") {
                    this.$store.commit("addItemAColeccionNested", {
                        vid: this.id,
                        col: "vacunatorios",
                        prop: "phones",
                        pid: datous.payload,
                        val: valor,
                    });
                } else if (acciontype == "del") {
                    this.$store.commit("borrarItemDeColeccionNested", { vid: this.id, col: "vacunatorios", prop: "phones", pid: valor });
                    // console.log('tratando de borrar', {vid: this.id, pid: valor })
                }
                if (!this.toastCenter) {
                    this.toastCenter = this.$f7.toast.create({
                        text: "Cambios guardados OK",
                        position: "center",
                        closeTimeout: 2000,
                    });
                }
                this.toastCenter.open();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
    },
};
</script>
<style></style>
