<template>
    <f7-page @page:beforeout="handlePageBeforeout">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Solicitudes de" subtitle="Validación de vacunas recibidas en Chile"></f7-nav-title>
            <f7-nav-right>
                <f7-link tooltip="Descargar excel" popover-open=".popover-excel"
                    ><font-awesome-icon icon="cloud-download-alt"></font-awesome-icon
                ></f7-link>
                <f7-link @click="goToHistoricoAceptadas" tooltip="Ver historico aceptadas"
                    ><font-awesome-icon icon="check"></font-awesome-icon
                ></f7-link>

                <f7-link tooltip="Buscar solicitud" @click="openSearchSolicitudes"><font-awesome-icon icon="search"></font-awesome-icon></f7-link>

                <f7-link :class="{ disabled: isLoading }" @click="fetchSolicitudesByCola(true)" tooltip="Actualizar listado"
                    ><font-awesome-icon :class="{ 'fa-spin': isLoading }" icon="sync-alt"></font-awesome-icon
                ></f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-toolbar tabbar top>
            <f7-link tab-link="#tab-en-espera" :tab-link-active="selectedCola === 'En espera'" @click="selectedCola = 'En espera'"
                >En espera <template v-if="solicitudesEnEspera.length > 0"> ({{ solicitudesEnEspera.length }})</template></f7-link
            >
            <f7-link tab-link="#tab-en-revision" :tab-link-active="selectedCola === 'En revisión'" @click="selectedCola = 'En revisión'"
                >En revisión <template v-if="solicitudesEnRevision.length > 0"> ({{ solicitudesEnRevision.length }})</template></f7-link
            >
            <f7-link tab-link="#tab-con-conflicto" :tab-link-active="selectedCola === 'En conflicto'" @click="selectedCola = 'En conflicto'"
                >En conflicto <template v-if="solicitudesEnConflicto.length > 0"> ({{ solicitudesEnConflicto.length }})</template></f7-link
            >
        </f7-toolbar>
        <f7-tabs>
            <f7-tab id="tab-en-espera" tab-active>
                <div class="display-flex margin">
                    <div class="margin-right display-flex align-items-center">
                        <f7-radio
                            name="filter-en-espera"
                            :checked="enEsperaFilter === 'all'"
                            @change="$store.commit('homologacionVacunasNac/set_filter_lista_en_espera', 'all')"
                            class="margin-right-half"
                        ></f7-radio>
                        Todas las solicitudes
                    </div>

                    <div class="margin-right display-flex align-items-center">
                        <f7-radio
                            name="filter-en-espera"
                            :checked="enEsperaFilter === 'no_verified'"
                            @change="$store.commit('homologacionVacunasNac/set_filter_lista_en_espera', 'no_verified')"
                            class="margin-right-half"
                        ></f7-radio>
                        Pacientes no verificados
                    </div>

                    <div class="margin-right display-flex align-items-center">
                        <f7-radio
                            name="filter-en-espera"
                            :checked="enEsperaFilter === 'verified'"
                            @change="$store.commit('homologacionVacunasNac/set_filter_lista_en_espera', 'verified')"
                            class="margin-right-half"
                        ></f7-radio>
                        Pacientes verificados
                    </div>
                </div>
                <solicitudes-list list-type="En espera" :solicitudes="solicitudesEnEspera"></solicitudes-list>
            </f7-tab>
            <f7-tab id="tab-en-revision">
                <solicitudes-list list-type="En revisión" :solicitudes="solicitudesEnRevision"></solicitudes-list>
            </f7-tab>
            <f7-tab id="tab-con-conflicto">
                <solicitudes-list list-type="En conflicto" :solicitudes="solicitudesEnConflicto"></solicitudes-list>
            </f7-tab>
        </f7-tabs>

        <div class="display-flex justify-content-center">
            <f7-button large fill raised @click="loadMoreSolicitudesByCola" class="padding-horizontal">Cargar más</f7-button>
        </div>

        <f7-popup class="solicitud-search" :opened="searchSolicitudesPopupOpened" @popup:closed="handleSearchPopupClosed">
            <f7-page>
                <f7-navbar title="Buscar solicitud">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <div class="margin">
                    <p>Tipo de búsqueda</p>
                    <f7-segmented strong tag="p">
                        <f7-button @click="selectedSearchTypeChanged('user_email')" :active="selectedSearchType === 'user_email'">Email</f7-button>
                        <f7-button @click="selectedSearchTypeChanged('patient_id')" :active="selectedSearchType === 'patient_id'"
                            >Documento</f7-button
                        >
                        <f7-button @click="selectedSearchTypeChanged('nombres_apellidos')" :active="selectedSearchType === 'nombres_apellidos'"
                            >Nombres</f7-button
                        >
                        <f7-button @click="selectedSearchTypeChanged('solicitud_id')" :active="selectedSearchType === 'solicitud_id'">Folio</f7-button>
                    </f7-segmented>
                </div>

                <f7-list class="no-margin" no-hairlines v-if="selectedSearchType === 'user_email'">
                    <f7-list-input
                        type="text"
                        label="Email usuario MeVacuno"
                        @blur="e => searchInputChanged(e, 'user_email')"
                        :value="search.user_email"
                        placeholder="usuario@test.cl"
                    ></f7-list-input>
                </f7-list>

                <f7-list class="no-margin-vertical" v-if="selectedSearchType === 'patient_id'">
                    <f7-list-input  label="Tipo documento" type="select" :value="search.tipoDoc" @input="e => searchInputChanged(e, 'tipoDoc')">
                        <option value="RUN">RUN</option>
                        <option value="PAS">PASAPORTE</option>
                        <option value="DNI">DNI</option>
                    </f7-list-input>
                    <f7-list-input
                        type="text"
                        label="Número documento"
                        @blur="e => searchInputChanged(e, 'numDoc')"
                        :value="search.numDoc"
                        placeholder="Ej: 112223334"
                    ></f7-list-input>
                </f7-list>

                <f7-row no-gap v-if="selectedSearchType === 'nombres_apellidos'">
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Nombres" @blur="e => searchInputChanged(e, 'nombres')"></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Apellido 1" @blur="e => searchInputChanged(e, 'apellido1')"></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Apellido 2" @blur="e => searchInputChanged(e, 'apellido2')"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row no-gap v-if="selectedSearchType === 'solicitud_id'">
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="ID Solicitud" @blur="e => searchInputChanged(e, 'sid')"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <div class="margin">
                    <f7-button fill color="primary" @click="selectedSearchType === 'patient_id' ? preSearchPatients() : searchSolicitudes(false)"
                        >Buscar</f7-button
                    >
                    <f7-button v-if="foundSolicitudes.length" class="margin-top" small @click="foundSolicitudes = []"
                        >Limpiar resultados de búsqueda</f7-button
                    >
                </div>

                <solicitudes-list
                    list-type="Busqueda nacional"
                    :solicitudes="foundSolicitudesOrdered"
                    @solicitud-taken="handleSolicitudTakenFromBusqueda"
                ></solicitudes-list>

                <f7-button v-if="selectedSearchType !== 'solicitud_id'" @click="searchSolicitudes(true)">Cargar más</f7-button>
            </f7-page>
        </f7-popup>

        <select-patient-popup
            :opened="selectPatientPopupOpened"
            :patients="patientsToSelect"
            @popup-closed="selectPatientPopupOpened = false"
            @patient-selected="handlePatientSelected"
        ></select-patient-popup>

        <f7-popover class="popover-excel">
            <f7-list>
                <f7-list-item title="Descarga excel"></f7-list-item>
                <f7-list-input
                    label="Fecha Inicio"
                    type="text"
                    placeholder="dd/mm/aaaa"
                    :value="rangeDate.startDate"
                    @input="rangeDate.startDate = $event.target.value"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    label="Fecha Fin"
                    type="text"
                    placeholder="dd/mm/aaaa"
                    :value="rangeDate.endDate"
                    @input="rangeDate.endDate = $event.target.value"
                    clear-button
                >
                </f7-list-input>
                <f7-list-input
                    class="select-region-item"
                    v-if="!centralAdminAccount.regional"
                    type="select"
                    label="Región"
                    :value="regionSelected"
                    @input="regionSelected = $event.target.value"
                    placeholder="Seleccione una región"
                >
                    <option value="all">Todas</option>
                    <option v-for="region in regiones" :key="region.number" :value="region.id">
                        {{ `${region.name} (${region.id})` }}
                    </option>
                </f7-list-input>
            </f7-list>
            <f7-button @click="generaDescarga()">Descargar</f7-button>
        </f7-popover>
    </f7-page>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../../mixins/common";
import { comunasHelper } from "@/js/comunas.js";
import SolicitudesList from "./components/solicitudes-list.vue";
import SelectPatientPopup from "../../components/select-patient-popup.vue";

export default {
    mixins: [commonmixin],

    components: {
        "solicitudes-list": SolicitudesList,
        "select-patient-popup": SelectPatientPopup,
    },
    data() {
        return {
            selectedCola: "En espera",
            searchSolicitudesPopupOpened: false,
            tipo: "nac",

            regionSelected: null,

            search: {},

            rangeDate: {
                startDate: null,
                endDate: null,
            },

            foundSolicitudes: [],
            avisaDescargaok: null,

            selectedSearchType: "user_email",

            patientsToSelect: [],
            selectPatientPopupOpened: false,
            MIN_DATE: "01/01/2021",
        };
    },

    watch: {
        selectedCola: {
            immediate: true,
            handler() {
                this.fetchSolicitudesByCola();
            },
        },
    },

    computed: {
        ...mapState(["dev"]),

        ...mapState("homologacionVacunasNac", [
            "isLoading",
            "observableEnEsperaNac",
            "observableEnRevisionNac",
            "observableEnConflictoNac",
            "enEsperaFilter",
            "SOLICITUDES_FETCH_SIZE",
            "SOLICITUDES_LOAD_MORE_FETCH_SIZE",
        ]),

        ...mapGetters("homologacionVacunasNac", ["solicitudesEnEspera", "solicitudesEnRevision", "solicitudesEnConflicto"]),
        ...mapGetters(["centralAdminAccount"]),

        foundSolicitudesOrdered() {
            return this.foundSolicitudes.sort((a, b) => {
                if (a.wh < b.wh) return -1;
                if (a.wh > b.wh) return 1;
                return 0;
            });
        },
        regiones() {
            return comunasHelper.data().comunas.map(c => {
                return {
                    id: c.region_iso_3166_2,
                    name: c.region,
                    number: c.region_number,
                };
            }).filter(r => r.id);
        },
    },

    methods: {
        formattedDates() {
            const formattedDates = {
                startDate: null,
                endDate: null,
            };
            const minima = this.$dayjs(this.MIN_DATE, "DD/MM/YYYY");
            if (this.rangeDate.startDate){
                const fechaInicio = this.$dayjs(this.rangeDate.startDate, "DD/MM/YYYY");
                if (!fechaInicio.isValid() || fechaInicio.isBefore(minima))
                    throw new Error("Fecha inicio inválida");
                
                formattedDates.startDate = this.$dayjs(this.rangeDate.startDate, "DD/MM/YYYY").unix();
            }
            if (this.rangeDate.endDate) {
                const fechaFin = this.$dayjs(this.rangeDate.endDate, "DD/MM/YYYY");
                if (!fechaFin.isValid() || fechaFin.isBefore(minima))
                    throw new Error("Fecha fin inválida");
                formattedDates.endDate = this.$dayjs(this.rangeDate.endDate, "DD/MM/YYYY").unix();
            }
            if (this.rangeDate.startDate && !this.rangeDate.endDate) {
                formattedDates.endDate = this.$dayjs().add(1, 'day').unix();
            }
            if (!this.rangeDate.startDate && this.rangeDate.endDate) {
                formattedDates.startDate = minima.unix();
            }
            if (!this.rangeDate.startDate && !this.rangeDate.endDate) {
                formattedDates.startDate = minima.unix();
                formattedDates.endDate = this.$dayjs().add(1, 'day').unix();
            }
            return formattedDates;
        },
        async generaDescarga() {
            try {
                console.log(this.selectedCola);
                const { startDate, endDate } = this.formattedDates();
                this.$f7.dialog.preloader("Generando archivo...");
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let envx = this.$store.state.dev ? "dev" : "prod";
                let response = await axios.get(
                    `${this.$store.getters.getApiUrl}/prosex-homolog_export/getcolahomologpending/${envx}/${this.selectedCola}/${this.tipo}/${startDate}/${endDate}/${this.regionSelected}`,
                    {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob", // important
                    }
                );
                this.$f7.dialog.close();
                console.log("response", response.data.type);
                if (response.data.type == "application/json") {
                    var textPromise = await response.data.text();
                    console.log("error", textPromise);
                    return this.$f7.dialog.alert(textPromise, "Error");
                } else if (response.data.type == "text/html") {
                    var textPromise = await response.data.text();
                    return this.$f7.dialog
                        .create({
                            title: "Excel generado",
                            text: "Puede descargar el Excel presionando el botón.",
                            buttons: [
                                {
                                    text: "Cancelar",
                                },
                                {
                                    text: "Descargar",
                                    onClick: function() {
                                        window.open(textPromise, "_blank");
                                    },
                                },
                            ],
                        })
                        .open();
                }
                this.avisaOk("Descarga OK");
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error, error.message, error.response, error?.response?.data);
                this.$f7.dialog.alert(error.response?.data?.message || error.message);
            }
        },
        fetchSolicitudesByCola(refetch = false) {
            let targetObservable;

            if (!refetch) {
                if (this.selectedCola === "En espera") {
                    targetObservable = this.observableEnEsperaNac;
                } else if (this.selectedCola === "En revisión") {
                    targetObservable = this.observableEnRevisionNac;
                } else if (this.selectedCola === "En conflicto") {
                    targetObservable = this.observableEnConflictoNac;
                }

                console.log("observable existe", targetObservable !== null);

                if (targetObservable) return;
            } else {
                this.$store.commit("homologacionVacunasNac/unset_all_observables");
            }

            const reg = this.centralAdminAccount.regiones ? Object.keys(this.centralAdminAccount.regiones) : [];

            if (this.centralAdminAccount.regional) {
                if (reg.length == 0) {
                return this.$f7.dialog.alert("No tiene regiones asignadas");
                } else if (reg.length > 10) {
                    return this.$f7.dialog.alert("No puede tener más de 10 regiones asignadas");
                }
            } else {
                reg.push("todas");
            }

           
            
            this.$store.dispatch("homologacionVacunasNac/fetchSolicitudes", {
                selectedCola: this.selectedCola,
                firestore: this.$firebase.firestore(),
                refetch,
                dev: this.dev ? true : false,
                regiones: reg,
            });
        },
        loadMoreSolicitudesByCola() {
            const reg = this.centralAdminAccount.regiones ? Object.keys(this.centralAdminAccount.regiones) : [];
            if (this.centralAdminAccount.regional) {
                if (reg.length == 0) {
                    return this.$f7.dialog.alert("No tiene regiones asignadas");
                } else if (reg.length > 10) {
                    return this.$f7.dialog.alert("No puede tener más de 10 regiones asignadas");
                }
            } else {
                reg.push("todas");
            }

            this.$store.dispatch("homologacionVacunasNac/loadMoreSolicitudes", {
                selectedCola: this.selectedCola,
                firestore: this.$firebase.firestore(),
                dev: this.dev ? true : false,
                regiones: reg,
            });
        },

        openSearchSolicitudes() {
            this.searchSolicitudesPopupOpened = true;
        },

        goToHistoricoAceptadas() {
            this.$f7.views.main.router.navigate(`/gestor_homologacion_vacunas_nac/accepted/nac`);
            
        },

        searchInputChanged(e, property) {
            const value = e.target.value;

            if (value) {
                this.$set(this.search, property, e.target.value);
            } else {
                this.$delete(this.search, property);
            }
        },

        selectedSearchTypeChanged(searchType) {
            this.selectedSearchType = searchType;

            if (this.selectedSearchType === "user_email") {
                this.$delete(this.search, "tipoDoc");
                this.$delete(this.search, "numDoc");
                this.$delete(this.search, "nombres");
                this.$delete(this.search, "apellido1");
                this.$delete(this.search, "apellido2");
                this.$delete(this.search, "sid");
            } else if (this.selectedSearchType === "patient_id") {
                this.$set(this.search, "tipoDoc", "RUN");
                this.$delete(this.search, "nombres");
                this.$delete(this.search, "apellido1");
                this.$delete(this.search, "apellido2");
                this.$delete(this.search, "user_email");
                this.$delete(this.search, "sid");
            } else if (this.selectedSearchType === "nombres_apellidos") {
                this.$delete(this.search, "user_email");
                this.$delete(this.search, "tipoDoc");
                this.$delete(this.search, "numDoc");
                this.$delete(this.search, "sid");
            } else if (this.selectedSearchType === "solicitud_id") {
                this.$delete(this.search, "user_email");
                this.$delete(this.search, "tipoDoc");
                this.$delete(this.search, "numDoc");
                this.$delete(this.search, "nombres");
                this.$delete(this.search, "apellido1");
                this.$delete(this.search, "apellido2");
            }
        },

        async preSearchPatients() {
            if (!this.search.tipoDoc || !this.search.numDoc) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe ingresar un tipo y número de documento");
                return;
            }
            const [nroDoc, iso2] = this.search.numDoc.split("_");
            const tipoDoc = this.search.tipoDoc;

            let formatedNroDoc = nroDoc;

            if (iso2) {
                formatedNroDoc = `${formatedNroDoc}_${iso2}`;
            }

            const trimBusqueda = formatedNroDoc.trim();

            if (tipoDoc === "RUN") {
                await this.searchSolicitudes();
            } else {
                this.$f7.dialog.preloader("Buscando...");

                const response = await this.commonExecute({ tipoDoc, nroDoc: trimBusqueda, dev: this.dev }, "admin-preSearchPatient", false);

                if (response.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(response.error.message);
                    return;
                }

                const { patients } = response;

                if (patients.length === 0) {
                    this.$f7.dialog.close();
                    await this.searchSolicitudes(true);
                    return;
                }

                if (patients.length === 1 && patients[0].identity === trimBusqueda) {
                    this.$f7.dialog.close();

                    const patient = patients[0];

                    const { idenType, identity } = patient;

                    this.search.tipoDoc = idenType;
                    this.search.numDoc = identity;

                    await this.searchSolicitudes();
                } else {
                    this.patientsToSelect = patients;
                    this.selectPatientPopupOpened = true;
                }

                this.$f7.dialog.close();
            }
        },

        async handlePatientSelected(patient) {
            const { idenType, identity } = patient;

            this.search.tipoDoc = idenType;
            this.search.numDoc = identity;

            await this.searchSolicitudes();

            this.patientsToSelect = [];
            this.selectPatientPopupOpened = false;
        },

        async searchSolicitudes(loadMore = false) {
            try {
                const preloaderText = loadMore ? "Cargando más resultados..." : "Buscando solicitudes...";

                let  collection = this.dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";

                let query = this.$firebase
                    .firestore()
                    .collection(collection)
                    .where("est", "in", ["En espera", "En revisión", "En conflicto", "Cerrada"]);

                if (this.selectedSearchType === "user_email") {
                    if (!this.search.user_email) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar un email");
                        return;
                    }

                    query = query.where("user_email", "==", this.search.user_email);
                } else if (this.selectedSearchType === "patient_id") {
                    if (!this.search.tipoDoc || !this.search.numDoc) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar un número de documento");
                        return;
                    }

                    query = query.where("pat.pid", "==", `${this.search.tipoDoc}_${this.search.numDoc}`);
                } else if (this.selectedSearchType === "nombres_apellidos") {
                    if (!this.search.nombres && !this.search.apellido1 && !this.search.apellido2) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar al menos un campo de búsqueda");
                        return;
                    }

                    if (this.search.apellido1) {
                        query = query.where("pat.apellido1", "==", this.search.apellido1);
                    }

                    if (this.search.apellido2) {
                        query = query.where("pat.apellido2", "==", this.search.apellido2);
                    }

                    if (this.search.nombres) {
                        query = query.where("pat.nombres", "==", this.search.nombres);
                    }
                } else if (this.selectedSearchType === "solicitud_id") {
                    if (!this.search.sid) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar un ID de solicitud");
                        return;
                    }

                    const custom = this.$firebase
                    .firestore()
                    .collection(collection)
                    .doc(this.search.sid);

                    const snapshot = await custom.get();
                    this.foundSolicitudes = [snapshot.data()];
                    return;
                }

                query = query.orderBy("wh");

                if (loadMore) {
                    const lastFoundSolicitud = this.foundSolicitudes[this.foundSolicitudes.length - 1];

                    if (lastFoundSolicitud) {
                        query = query.startAfter(lastFoundSolicitud.wh);
                    }
                }

                query = query.limit(this.SOLICITUDES_LOAD_MORE_FETCH_SIZE);

                const snapshot = await query.get();

                const solicitudes = snapshot.docs.map(doc => doc.data());

                if (!loadMore) {
                    this.foundSolicitudes = solicitudes;
                } else {
                    this.foundSolicitudes = this.foundSolicitudes.concat(solicitudes);
                }

                if (this.foundSolicitudes.length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron solicitudes");
                    return;
                }
            } catch (error) {
                console.error("error buscando solicitudes", error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Error buscando solicitudes. " + error);
            }
        },

        handleSearchPopupClosed() {
            this.searchSolicitudesPopupOpened = false;
            this.search = {};
            this.foundSolicitudes = [];
        },

        handleSolicitudTakenFromBusqueda() {
            this.searchSolicitudesPopupOpened = false;
        },

        handlePageBeforeout() {
            if (this.$f7.views.main.router.url === "/gestor_homologacion_vacunas_nac/resolving/nac") return;
            this.$store.commit("homologacionVacunasNac/unset_all_observables");
        },
    },
};
</script>
