<template>
    <f7-card outline>
        <f7-card-header>
            <b>Información sobre la solicitud</b>
        </f7-card-header>
        <f7-card-content class="no-padding-top">
            <f7-row class="no-margin-top" no-gap>
                <f7-col>
                    <f7-list class="no-margin" media list>
                        <f7-list-item 
                            media-item 
                            header="¿Esquemas anteriores al 2013?" 
                            :title="periodoEsquemaPre">
                        </f7-list-item>
                        <f7-list-item 
                            media-item 
                            header="Motivo Solicitud" 
                            :title="solicitud.mtv.tipo" 
                            :footer="solicitud.mtv.tipo === 'otro' ? solicitud.mtv.desc : ''">
                        </f7-list-item>
                        <f7-list-item 
                            v-if="isPostNacSolicitud"
                            media-item
                            header="Registros a certificar">
                            <div slot="footer">
                                <span v-if="solicitud.vacs_opt.tipo === 'algunos'">
                                    <li v-for="(vac, index) in solicitud.vacs_opt.vacs">
                                        {{ index + 1}} - {{ vac.name ? vac.name : vac }}
                                    </li>
                                </span>
                                <span v-else>
                                    Todos
                                </span>
                            </div>
                        </f7-list-item>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list class="no-margin" media list>
                        <f7-list-item
                            media-item
                            header="Comuna del trámite"
                            :title="solicitud.address.comuna">
                        </f7-list-item>
                        <f7-list-item
                            media-item 
                            header="Apostillamiento" 
                            :title="solicitud.actApos ? solicitud.actApos.descripcion : 'No solicitado'">
                        </f7-list-item>
                    </f7-list>
                </f7-col>
            </f7-row>
        </f7-card-content>
    </f7-card>
</template>
<script>
export default {
    props: {
        solicitud: Object,
    },
    data() {
        return {}
    },
    computed: {
        isPostNacSolicitud() {
            return this.solicitud.periodo === "post2013";
        },
        periodoEsquemaPre() {
            return this.isPostNacSolicitud ? "No" : "Si";
        }
    }
}
</script>