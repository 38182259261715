<template>
    <f7-page
        @page:beforein="llamaSolicitudes({ reinit: true })"
        @page:beforeremove="apagaObservable()"
        :infinite="selectedFilter !== 'hide_homopend'"
        :infinite-distance="50"
        :infinite-preloader="showPreloader"
        @infinite="loadMore"
        name="verifidentity"
    >
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Solicitudes de" subtitle="Verificación de Identidad"></f7-nav-title>
            <f7-nav-right>
                <f7-link tooltip="Descargar excel solicitudes de verificación de Identidad" @click="generaDescarga()"
                    ><font-awesome-icon icon="cloud-download-alt"></font-awesome-icon
                ></f7-link>
                <f7-block class="no-margin no-padding">
                    <f7-link tooltip="Filtros" popover-open=".popover-filtros"><font-awesome-icon icon="filter"></font-awesome-icon></f7-link>
                </f7-block>
                <f7-block class="no-margin no-padding">
                    <f7-link tooltip="Busqueda" popover-open=".popover-busqueda"><font-awesome-icon icon="search"></font-awesome-icon></f7-link>
                </f7-block>
                 <f7-block class="no-margin no-padding">
                    <f7-link tooltip="Histórico" popover-open=".popover-historico"><font-awesome-icon icon="history"></font-awesome-icon></f7-link>
                </f7-block>
                <f7-block class="no-margin no-padding">
                    <f7-link :class="{ disabled: cargandoSolicitudes }" @click="llamaSolicitudes({ reinit: true })" tooltip="Actualizar listado"
                        ><font-awesome-icon :class="{ 'fa-spin': cargandoSolicitudes }" icon="sync-alt"></font-awesome-icon></f7-link
                ></f7-block>
            </f7-nav-right>
        </f7-navbar>

        <f7-popover class="popover-historico">
            <f7-list class="no-margin">
                <f7-list-item title="Historial de solicitudes"></f7-list-item>
                <f7-list-item>
                    <f7-link @click="navToHistoricoRechazadas">
                        <font-awesome-icon icon="user-times"></font-awesome-icon>
                        &nbsp;Solicitudes rechazadas
                    </f7-link>
                </f7-list-item>
                <f7-list-item>
                    <f7-link @click="navToHistoricoAprobadas">
                        <font-awesome-icon icon="user-check"></font-awesome-icon>
                        &nbsp;Solicitudes aprobadas
                    </f7-link>
                </f7-list-item>
            </f7-list>
        </f7-popover>


        <div class="margin-half">
            Viendo lista con filtro: <b>{{ filters[selectedFilter] || 'Unknown' }}</b>
        </div>

        <f7-list class="no-margin-top" media-list>
            <ul>
                <f7-list-item v-if="!cargandoSolicitudes && !contentToList.length" title="No hay verificaciones pendientes"></f7-list-item>

                <one-verif
                    @evaluar="gotogestion"
                    v-for="solicitud in filteredSolicitudList"
                    :tomadapor="casosTomadosPorAgente[solicitud.id]"
                    :key="solicitud.id"
                    :solicitud="solicitud"
                ></one-verif>
            </ul>
        </f7-list>

        <f7-popover class="popover-busqueda">
            <f7-list class="no-margin">
                <f7-list-input label="Tipo búsqueda" type="select" defaultValue="email" :value="tipoSearch" @input="tipoSearch = $event.target.value">
                    <option selected value="email">e-mail</option>
                    <option selected value="send/identity">RUN/PAS/DNI</option>
                </f7-list-input>
                <f7-list-input
                    label="valor"
                    type="text"
                    :placeholder="`${tipoSearch == 'email' ? 'usuario@test.cl' : '11223334'}`"
                    :value="busqueda"
                    @keyup.native.enter="buscar()"
                    @input="busqueda = $event.target.value"
                    clear-button
                >
                </f7-list-input>
            </f7-list>
            <f7-button @click="buscar()">Buscar</f7-button>
        </f7-popover>

        <f7-popover class="popover-filtros">
            <f7-list>
                <f7-list-item title="Filtrar solicitudes"></f7-list-item>
                <!-- Additional "radio" prop to enable radio list item -->
                <f7-list-item
                    radio
                    value="all"
                    name="filter-solicitudes-radio"
                    :checked="selectedFilter === 'all'"
                    title="Mostrar todo"
                    @change="handleFilterChanged('all')"
                ></f7-list-item>
                <f7-list-item
                    radio
                    value="show_homopend"
                    name="filter-solicitudes-radio"
                    :checked="selectedFilter === 'show_homopend'"
                    @change="handleFilterChanged('show_homopend')"
                >
                    <div slot="title" style="white-space: normal">
                        Solicitudes asociadas a validación
                    </div>
                </f7-list-item>
                <f7-list-item
                    radio
                    value="hide_homopend"
                    name="filter-solicitudes-radio"
                    :checked="selectedFilter === 'hide_homopend'"
                    @change="handleFilterChanged('hide_homopend')"
                >
                    <div slot="title" style="white-space: normal">
                        Solicitudes NO asociadas a validación
                    </div>
                </f7-list-item>
            </f7-list>
        </f7-popover>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import oneVerif from "../components/oneVerif.vue";
import axios from "axios";

export default {
    mixins: [commonmixin],
    components: { oneVerif },
    data() {
        return {
            tipoSearch: "email",
            busqueda: "",
            observaSolicitudes: null,
            cargandoSolicitudes: false,
            lastnotikeyCargada: null,
            contentYaRendereado: {},
            contentToList: [],
            postsbyload: 20,
            showPreloader: true,
            casosTomadosPorAgente: {},

            selectedFilter: "all",

            filters: {
                all: "Todo",
                show_homopend: "Solicitudes asociadas a validación",
                hide_homopend: "Solicitudes NO asociadas a validación"
            }
        };
    },
    computed: {
        ...mapState(["user", "dev"]),
        ...mapGetters(["centralAdminAccount"]),

        filteredSolicitudList() {
            if (this.selectedFilter === "all") {
                return this.contentToList;
            } else if (this.selectedFilter === "show_homopend") {
                return this.contentToList.filter(solicitud => {
                    return solicitud.homopend;
                });
            } else if (this.selectedFilter === "hide_homopend") {
                return this.contentToList.filter(solicitud => {
                    return !solicitud.homopend;
                });
            }
        },
    },
    created() {
        this.verAgentesYCasos({});
        this.traerTiposRechazos();
    },
    methods: {
        handleFilterChanged(filter) {
            this.selectedFilter = filter;
            this.llamaSolicitudes({});
            this.$f7.popover.close(".popover-filtros");
        },

        async buscar() {
            console.log("va a buscar", this.tipoSearch, this.busqueda);
            let limpio =
                this.tipoSearch == "send/identity"
                    ? this.busqueda
                          .split(".")
                          .join("")
                          .split("/")
                          .join("")
                          .split("-")
                          .join("")
                          .split("_")
                          .join("")
                          .split("/")
                          .join("")
                          .split(" ")
                          .join("")
                          .toUpperCase()
                    : this.busqueda.trim();
            this.$f7.popover.close(".popover-busqueda", true);
            try {
                this.contentToList = [];
                this.lastnotikeyCargada = null;
                this.contentYaRendereado = Object.assign({});
                this.$f7.dialog.preloader("Buscando...");
                let potresults = await this.$firebase_coreapp
                    .database(this.$store.getters.usrtrxDatabaseUrl)
                    .ref(`cola_solicitudes/${this.dev ? "d" : "p"}`)
                    .orderByChild(this.tipoSearch)
                    .equalTo(limpio)
                    .once("value");
                if (!potresults.exists()) {
                    throw new Error("No existen solicitudes indexadas por ese atributo de búsqueda.");
                }
                this.contentToList = Object.values(potresults.val());
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        verAgentesYCasos() {
            //cola_sols_agentes
            console.log("escuchando agentes");
            //(auth.token.email).replace('.', '|') == $tfid
            this.observaSolicitudes = this.$firebase_coreapp
                .database(this.$store.getters.usrtrxDatabaseUrl)
                .ref(`cola_sols_agentes/${this.dev ? "d" : "p"}`);
            this.observaSolicitudes.on("value", snapshot => {
                console.log("cambio de agente tomado");
                let casosTomados = {};
                snapshot.forEach(childsnap => {
                    casosTomados[childsnap.val()] = childsnap.key.split("|").join(".");
                });
                this.casosTomadosPorAgente = Object.assign({}, casosTomados);
                console.log("casosTomadosPorAgente", this.casosTomadosPorAgente);
            });
        },
        async traerTiposRechazos() {
            try {
                let protipos = await this.$firebase_coreapp
                    .database(this.$store.getters.usrtrxDatabaseUrl)
                    .ref("solicit_tipos_rechazo")
                    .once("value");
                console.log("tiposRechazo", protipos.val());
                this.$store.commit("setWhatTo", { what: "tiposRechazo", to: protipos.val() || {} });
            } catch (error) {
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        apagaObservable() {
            if (this.observaSolicitudes) {
                this.observaSolicitudes.off();
            }
        },
        async gotogestion(soliticudMeta) {
            console.log("a nevagar a ", soliticudMeta);
            try {
                this.$f7.dialog.preloader("Cargando información del caso...");
                let datous = await this.commonExecute({ solId: soliticudMeta.id }, "admin-loadCaseInfo", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }

                console.log("payload", datous.payload, soliticudMeta);
                let superReturner = {
                    identidadDeclarada: soliticudMeta.send,
                    historialSolicitudes: datous.payload.solsByUserId, //arreglo
                    otrosUsuariosMismaIdentidad: datous.payload.solsIdentity, //arreglo
                    infoDelPaciente: datous.payload.paciente,
                    soliticudMeta,
                    foto: datous.payload.foto,
                    docu: datous.payload.docu,
                    ownerPrivx: datous.payload.ownerPrivx,
                };
                let myemail = this.user.email.split(".").join("|");
                console.log("myemail", myemail);

                await this.$firebase_coreapp
                    .database(this.$store.getters.usrtrxDatabaseUrl)
                    .ref(`cola_sols_agentes/${this.dev ? "d" : "p"}/${myemail}`)
                    .set(soliticudMeta.id);
                this.$f7.dialog.close();

                // console.log('TODA LA INFO DE LA SOLICITUD',superReturner)
                this.$f7.views.main.router.navigate("/gestion_verifica/", {
                    props: superReturner,
                });
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },

        loadMore() {
            if (this.selectedFilter !== "hide_homopend"){
                console.log("llama mas");
                this.llamaSolicitudes({});
            }
        },
        async llamaSolicitudes({ force, reinit }) {
            try {
                if (this.cargandoSolicitudes) {
                    return false;
                }
                if (reinit) {
                    this.lastnotikeyCargada = null;
                    this.contentYaRendereado = {};
                    this.contentToList = [];
                    this.showPreloader = true;

                    this.selectedFilter = "all";
                }
                if (!this.showPreloader) {
                    return false;
                }
                this.cargandoSolicitudes = true;
                let promload;
                if (!this.lastnotikeyCargada) {
                    console.log("pidiendo fresco");
                    //es primer llamado, solo pedir las ultimas #postbyload
                    promload = this.$firebase_coreapp
                        .database(this.$store.getters.usrtrxDatabaseUrl)
                        .ref(`cola_solicitudes/${this.dev ? "d" : "p"}`)
                        .orderByChild("wh")
                        .limitToFirst(this.postsbyload);
                } else {
                    promload = this.$firebase_coreapp
                        .database(this.$store.getters.usrtrxDatabaseUrl)
                        .ref(`cola_solicitudes/${this.dev ? "d" : "p"}`)

                    if (this.selectedFilter === "hide_homopend") {
                        promload = promload.orderByChild("homopend").equalTo(null).limitToFirst(100);
                    } else {
                        promload = promload.orderByChild("wh").startAt(this.lastnotikeyCargada).limitToFirst(this.postsbyload)
                    }
                }
                let snapshot = await promload.once("value");
                console.log("recibiendo solicitudes");
                //llego data Ok. Voy a borrar el holder de lastnotikeycargada, para poder anotar el nuevo
                // this.lastnotikeyCargada=null;

                //arreglo interno, para luego mezclar con arreglo global
                let innerArray = [];
                if (snapshot.exists()) {
                    snapshot.forEach(data => {
                        //preparar objeto con la llave incluida
                        let elemntoToLoad = data.val();
                        //la primera que me llega en esta pasada, sera la ultima key cargada. Luego omitir
                        this.lastnotikeyCargada = elemntoToLoad.wh;
                        console.log("lastnotikeyCargada", this.lastnotikeyCargada);
                        // this.actuponrecievednoti(data,this.globalnotipointer);
                        //ver si la agrego o no al arreglo
                        if (!this.contentYaRendereado[data.key]) {
                            //no le habia agregado, agregar
                            innerArray.push(elemntoToLoad);
                            this.contentYaRendereado[data.key] = true;
                        }
                    });
                }

                //al terminar el loop, mezclar el arreglo interno, con el arreglo padre
                this.contentToList = this.contentToList.concat(innerArray);
                if (snapshot.exists() || snapshot.numChildren() < this.postsbyload) {
                    this.showPreloader = false;
                }
                this.cargandoSolicitudes = false;
                //si llegaron menos de las que pedi, se acabaron, ensconder el preloader de infinite scroll y no apagar el freno de iAmLoading
                console.log("done loading");
            } catch (error) {
                this.cargandoSolicitudes = false;
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
                console.error(error);
            }
        },
        formattedDates() {
            const formattedDates = {
                startDate: "",
                endDate: "",
            };
            if (!this.filters.rangeDate.length) {
                return formattedDates;
            }
            const dates = this.filters.rangeDate.split(" - ");
            formattedDates.startDate = dates[0] || "";
            formattedDates.endDate = dates[1] || "";
            return formattedDates;
        },
        async generaDescarga() {
            try {
                this.$f7.dialog.preloader("Generando archivo...");
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let envx = this.$store.state.dev ? "dev" : "prod";

                let response = await axios.get(`${this.$store.getters.getApiUrl}/prosex-identity_export/getcolaidentitypending/${envx}/${this.filters[this.selectedFilter]}/${null}/${null}`, {
                    headers: { Authorization: "Bearer " + idToken },
                    responseType: "blob", // important
                });
                this.$f7.dialog.close();
                if (response.data.type == "application/json") {
                    var textPromise = await response.data.text();
                    console.log("error", textPromise);
                    return this.$f7.dialog.alert(textPromise, "Error");
                }
                const fileURL = window.URL.createObjectURL(
                    new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                );
                let fileLink = document.createElement("a");
                document.body.appendChild(fileLink);
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "solicitudes_verificaciones_identidad.xlsx");
                fileLink.classList.add("external");
                fileLink.click();
                window.URL.revokeObjectURL(fileURL);
                if (!this.avisaDescargaok) {
                    this.avisaDescargaok = this.$f7.toast.create({
                        text: "Descarga OK",
                        position: "center",
                        closeTimeout: 2000,
                    });
                }
                this.avisaDescargaok.open();
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error, error.message, error.response, error?.response?.data);
                this.$f7.dialog.alert(error.response?.data?.message || error.message);
            }
        },
        navToHistoricoRechazadas() {
            this.$f7.popover.close(".popover-historico", true);
            this.$f7.views.main.router.navigate("/verifidentity/historico-rechazadas/");
        },
        navToHistoricoAprobadas() {
            this.$f7.popover.close(".popover-historico", true);
            this.$f7.views.main.router.navigate("/verifidentity/historico-aprobadas/");
        },
    },
};
</script>
<style></style>