<template>
    <f7-card outline>
        <f7-card-header>
            <b>Información del solicitante</b>
               <font-awesome-icon size="2x" icon="user" :color="iconColorByPatientGender"></font-awesome-icon>
        </f7-card-header>
        <f7-card-content class="no-padding-top">
            <f7-list class="no-margin" media-list>
                        <f7-list-item media-item header="Identidad" :title="`${clonedPatient.idenType} ${clonedPatient.identity}`"></f7-list-item>
                    </f7-list>
            <f7-row class="no-margin-top" no-gap>
                <f7-col>
                    <f7-list class="no-margin" media-list>
                        <f7-list-item media-item header="Nombres" :title="clonedPatient.nombres"></f7-list-item>
                        <f7-list-item media-item header="Apellido 1" :title="clonedPatient.apellido1"></f7-list-item>
                        <f7-list-item media-item header="Apellido 2" :title="clonedPatient.apellido2"></f7-list-item>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list class="no-margin" media-list>
                        <f7-list-item
                            media-item
                            header="Fecha nacimiento"
                            :title="$dayjs(clonedPatient.fechanac, 'YYYYMMDD').format('DD/MM/YYYY')"
                        ></f7-list-item>
                        <f7-list-item media-item header="Nacionalidad" :title="clonedPatient.nac_iso2"></f7-list-item>
                        <f7-list-item media-item header="Email" :title="emailSolicitante"></f7-list-item>
                    </f7-list>
                </f7-col>
            </f7-row>

            <div v-if="!clonedPatient.verified" class="margin-top text-align-center">
                <f7-preloader v-if="cargandoInfoSolicitudVerifIdentidad"></f7-preloader>
                <f7-button v-else-if="soliticudMeta" color="red" fill @click="cargaMetaSolicitudYNavega()">
                    Resolver solicitud de verificacion de identidad
                </f7-button>
                <f7-block v-else
                    >Este usuario no tiene una identidad verificada, y no cuenta con una solicitud de verificacion de identidad pendiente.</f7-block
                >
            </div>
        </f7-card-content>
    </f7-card>
</template>

<script>
import { commonmixin } from "../../../mixins/common";

export default {
    mixins: [commonmixin],
    props: {
        patient: Object,
        uidSolicitante: String,
        emailSolicitante: String
    },

    data() {
        return {
            cargandoInfoSolicitudVerifIdentidad: false,
            soliticudMeta: null,
            clonedPatient: {},
        };
    },

    computed: {
        recheckPaxInfo_homolacion() {
            return this.$store.state.recheckPaxInfo_homolacion;
        },
        iconColorByPatientGender() {
            let color = "gray";

            if (!this.clonedPatient) return color;

            if (this.clonedPatient.sexo === "M") {
                color = "lightblue";
            } else if (this.clonedPatient.sexo === "F") {
                color = "pink";
            }

            return color;
        },
    },
    watch: {
        recheckPaxInfo_homolacion() {
            this.evaluaEstadoSolicitudVerificIdentidad();
        },
    },
    mounted() {
        this.clonedPatient = JSON.parse(JSON.stringify(this.patient));
        console.log("PAXINFO", this.clonedPatient);
        if (!this.clonedPatient.verified) {
            console.log("identidad de paciente no verificada, evaluando su solicitud");
            this.evaluaEstadoSolicitudVerificIdentidad();
        }
    },
    methods: {
        async evaluaEstadoSolicitudVerificIdentidad() {
            try {
                this.cargandoInfoSolicitudVerifIdentidad = true;
                //ver si este usuario tiene una pendiente enviada y no rechazada
                // y ademas cargar nueva info de su estado de identidad
                let dbtrxs = this.$firebase_coreapp.database(this.$store.getters.usrtrxDatabaseUrl);
                let promesax = [
                    dbtrxs.ref(`solicit_verif_open/${this.$store.state.dev ? "d" : "p"}/${this.uidSolicitante}`).once("value"),
                    this.$firebase.database().ref(`privx/${this.uidSolicitante}`).once("value")
                ];
                let prosnphot = await Promise.all(promesax);
                //todo check si user no existte
               let neoInfoUser = prosnphot[1].val();
                if(neoInfoUser){
                    this.clonedPatient = Object.assign({}, this.clonedPatient, neoInfoUser);
                    this.$emit("updatePatientInffo", this.clonedPatient);
                }
               
                if (!this.clonedPatient.verified) {
                    let soliticudMeta = prosnphot[0].val();
                    if (soliticudMeta && soliticudMeta.wh && !soliticudMeta.rjt) {
                        //el tipo no esta verificado, y hay una solicitud pendiente valida, esa es la info del paciente valida
                        let infopaxval={
                           apellido1: soliticudMeta.send.apellido1,
                           apellido2: soliticudMeta.send.apellido2,
                           fechanac: soliticudMeta.send.fechax,
                           idenType: soliticudMeta.send.idenType,
                           identity: soliticudMeta.send.identity,
                           nac_iso2: soliticudMeta.send.nacionalidad,
                           nombres: soliticudMeta.send.nombres,
                           pid: `${soliticudMeta.send.idenType}_${soliticudMeta.send.identity}`,
                           sexo: soliticudMeta.send.sexo,
                           verified: null
                        };
                        this.clonedPatient = Object.assign({}, this.clonedPatient, infopaxval);
                         this.$emit("updatePatientInffo", this.clonedPatient);
                        this.soliticudMeta = Object.assign({}, soliticudMeta);
                    }
                    else{
                        this.soliticudMeta = null;
                    }
                }
                // else{
                //      let neoInfoUser = prosnphot[1].val();
                //     if(neoInfoUser){
                //         this.clonedPatient = Object.assign({}, this.clonedPatient, neoInfoUser);
                //         this.$emit("updatePatientInffo", this.clonedPatient);
                //     }
                // }
                this.cargandoInfoSolicitudVerifIdentidad = false;

            } catch (error) {
                this.cargandoInfoSolicitudVerifIdentidad = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async cargaMetaSolicitudYNavega() {
            try {
                let soliticudMeta = this.soliticudMeta;
                this.$f7.dialog.preloader("Cargando info...");
                //ver si este usuario tiene una pendiente enviada y no rechazad
                let datous = await this.commonExecute({ solId: soliticudMeta.id }, "admin-loadCaseInfo", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }

                console.log("payload", datous.payload, soliticudMeta);
                let superReturner = {
                    identidadDeclarada: soliticudMeta.send,
                    historialSolicitudes: datous.payload.solsByUserId, //arreglo
                    otrosUsuariosMismaIdentidad: datous.payload.solsIdentity, //arreglo
                    infoDelPaciente: datous.payload.paciente,
                    soliticudMeta,
                    foto: datous.payload.foto,
                    docu: datous.payload.docu,
                    ownerPrivx: datous.payload.ownerPrivx,
                };
                let myemail = this.$store.state.user.email.split(".").join("|");
                await this.$firebase_coreapp
                    .database(this.$store.getters.usrtrxDatabaseUrl)
                    .ref(`cola_sols_agentes/${this.$store.state.dev ? "d" : "p"}/${myemail}`)
                    .set(soliticudMeta.id);
                // console.log('TODA LA INFO DE LA SOLICITUD',superReturner)

                this.$f7.dialog.close();
                this.$f7.views.main.router.navigate("/gestion_verifica/", {
                    props: superReturner,
                });
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
    },
};
</script>
