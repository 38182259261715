<template>
    <f7-view>
        <f7-page name="criterios_usuario_editar">
            <f7-navbar title="Editar Criterios en base a propiedades de Usuario"> </f7-navbar>

            <f7-list class="no-margin-top no-margin-bottom">
                <f7-list-item divider title="Edad como criterio de Exclusión"></f7-list-item>
                <f7-list-item
                    :title="
                        tosend.agemin
                            ? `${tosend.agemin.y} años ${tosend.agemin.m} meses y ${tosend.agemin.d} días`
                            : 'Sin restricción de edad mínima'
                    "
                    header="Edad mínima"
                >
                    <f7-link v-if="tosend.agemin" @click="tosend.agemin = null" slot="title"
                        ><font-awesome-icon class="text-color-gray margin-left-half" icon="trash"></font-awesome-icon
                    ></f7-link>
                    <f7-row no-gap>
                        <f7-col>
                            <f7-list class="no-margin noulpaddingleft">
                                <f7-list-input
                                    type="select"
                                    label="Años"
                                    :value="tosend.agemin ? tosend.agemin.y : 'no'"
                                    @change="handleAgeChange('agemin', 'y', $event.target.value)"
                                >
                                    <option value="no">-</option>
                                    <option :value="ano - 1" v-for="ano in 126" :key="`m-${ano - 1}`">
                                        {{ ano - 1 }}
                                    </option>
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list class="no-margin noulpaddingleft">
                                <f7-list-input
                                    type="select"
                                    label="Meses"
                                    :value="tosend.agemin ? tosend.agemin.m : ''"
                                    @change="handleAgeChange('agemin', 'm', $event.target.value)"
                                >
                                    <option v-if="!tosend.agemin" value="">-</option>
                                    <option :value="mes - 1" v-for="mes in 12" :key="`m-${mes - 1}`">
                                        {{ mes - 1 }}
                                    </option>
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list class="no-margin noulpaddingleft">
                                <f7-list-input
                                    type="select"
                                    label="Días"
                                    :value="tosend.agemin ? tosend.agemin.d : ''"
                                    @change="handleAgeChange('agemin', 'd', $event.target.value)"
                                >
                                    <option v-if="!tosend.agemin" value="">-</option>
                                    <option :value="dia - 1" v-for="dia in 32" :key="`d-${dia - 1}`">
                                        {{ dia - 1 }}
                                    </option>
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                </f7-list-item>
                <f7-list-item
                    :title="
                        tosend.agemax
                            ? `${tosend.agemax.y} años ${tosend.agemax.m} meses y ${tosend.agemax.d} días`
                            : 'Sin restricción de edad máxima'
                    "
                    header="Edad máxima"
                >
                    <f7-link v-if="tosend.agemax" @click="tosend.agemax = null" slot="title"
                        ><font-awesome-icon class="text-color-gray margin-left-half" icon="trash"></font-awesome-icon
                    ></f7-link>
                    <f7-row no-gap>
                        <f7-col>
                            <f7-list class="no-margin noulpaddingleft">
                                <f7-list-input
                                    type="select"
                                    label="Años"
                                    :value="tosend.agemax ? tosend.agemax.y : 'no'"
                                    @change="handleAgeChange('agemax', 'y', $event.target.value)"
                                >
                                    <option value="no">-</option>
                                    <option :value="ano - 1" v-for="ano in 126" :key="`m-${ano - 1}`">
                                        {{ ano - 1 }}
                                    </option>
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list class="no-margin noulpaddingleft">
                                <f7-list-input
                                    type="select"
                                    label="Meses"
                                    :value="tosend.agemax ? tosend.agemax.m : ''"
                                    @change="handleAgeChange('agemax', 'm', $event.target.value)"
                                >
                                    <option v-if="!tosend.agemax" value="">-</option>
                                    <option :value="mes - 1" v-for="mes in 12" :key="`m-${mes - 1}`">
                                        {{ mes - 1 }}
                                    </option>
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                        <f7-col>
                            <f7-list class="no-margin noulpaddingleft">
                                <f7-list-input
                                    type="select"
                                    label="Días"
                                    :value="tosend.agemax ? tosend.agemax.d : ''"
                                    @change="handleAgeChange('agemax', 'd', $event.target.value)"
                                >
                                    <option v-if="!tosend.agemax" value="">-</option>
                                    <option :value="dia - 1" v-for="dia in 32" :key="`d-${dia - 1}`">
                                        {{ dia - 1 }}
                                    </option>
                                </f7-list-input>
                            </f7-list>
                        </f7-col>
                    </f7-row>
                </f7-list-item>
                <f7-list-item divider title="Sexo Biológico"></f7-list-item>
                <f7-list-item
                    :disabled="tosend.sex.F && !tosend.sex.M && !tosend.sex.I"
                    checkbox
                    :checked="tosend.sex.F"
                    @change="tosend.sex.F = $event.target.checked"
                    color="teal"
                    value="F"
                    title="Femenino"
                ></f7-list-item>
                <f7-list-item
                    :disabled="tosend.sex.M && !tosend.sex.F && !tosend.sex.I"
                    checkbox
                    :checked="tosend.sex.M"
                    @change="tosend.sex.M = $event.target.checked"
                    color="teal"
                    value="M"
                    title="Masculino"
                ></f7-list-item>
                <f7-list-item
                    :disabled="tosend.sex.I && !tosend.sex.M && !tosend.sex.F"
                    checkbox
                    :checked="tosend.sex.I"
                    @change="tosend.sex.I = $event.target.checked"
                    color="teal"
                    value="I"
                    title="Intersex"
                ></f7-list-item>
                <f7-list-item divider title="Nacionalidades"></f7-list-item>
                <f7-list-item v-for="(valtru, pais) in tosend.nacionalidades" :key="pais" :title="$store.getters.getUno('paises', pais).name">
                    <f7-link @click="eliminaEstePais(pais)" slot="after"
                        ><font-awesome-icon class="text-color-gray" icon="trash"></font-awesome-icon
                    ></f7-link>
                </f7-list-item>
            </f7-list>
            <f7-list class="no-margin">
                <ul>
                    <select-pais :included="Object.values(tosend.nacionalidades)" @nuevopais="agregaUnPais"></select-pais>
                </ul>
            </f7-list>
            <f7-list class="no-margin">
                <f7-list-item divider title="Comuna de residencia"></f7-list-item>
            </f7-list>
            <f7-block class="bg-color-white padding-top padding-bottom no-margin">
                <f7-treeview>
                    <f7-treeview-item v-for="region in regionsSelector" :key="region.id" :label="region.region">
                        <font-awesome-icon icon="globe-americas" class="fa-lg text-color-primary" slot="media"></font-awesome-icon>
                        <f7-checkbox
                            color="teal"
                            slot="content-start"
                            :checked="region.nsel == region.coms"
                            :indeterminate="region.nsel > 0 && region.nsel != region.coms"
                            @change="updateRegion($event.target.checked, region.id)"
                        />
                        <f7-treeview-item v-for="province in region.provinces" :key="province.id" :label="province.name">
                            <font-awesome-icon icon="map-marked-alt" class="fa-lg text-color-primary" slot="media"></font-awesome-icon>
                            <f7-checkbox
                                color="teal"
                                slot="content-start"
                                :checked="province.nsel == Object.values(province.comunes).length"
                                :indeterminate="province.nsel > 0 && province.nsel != Object.values(province.comunes).length"
                                @change="updateProvincia($event.target.checked, region.id, province.id)"
                            />
                            <f7-treeview-item v-for="comune in province.comunes" :key="comune.code" :label="comune.name">
                                <font-awesome-icon icon="map-pin" class="fa-lg text-color-primary" slot="media"></font-awesome-icon>
                                <f7-checkbox
                                    color="teal"
                                    slot="content-start"
                                    :checked="tosend.comunas[comune.code]"
                                    @change="updateComuna($event.target.checked, region.id, province.id, comune.code)"
                                />
                            </f7-treeview-item>
                        </f7-treeview-item>
                    </f7-treeview-item>
                </f7-treeview>
            </f7-block>
            <f7-block>
                <f7-row>
                    <f7-col><f7-button popup-close large outline color="red">Cancelar</f7-button></f7-col>
                    <f7-col>
                        <f7-button v-if="false" @click="preparasave(false)" large outline raised color="teal">Evalua cambios</f7-button>
                        <f7-button v-else @click="preparasave(true)" large fill raised color="teal">Guardar cambios</f7-button>
                    </f7-col>
                </f7-row>
            </f7-block>
        </f7-page>
    </f7-view>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";

import selectPais from "../components/selectPais.vue";
export default {
    mixins: [commonmixin],
    props: ["critusarios", "campid"],
    components: {
        selectPais,
    },
    data() {
        return {
            regionsSelector: {},
            enedicion: "",
            tosend: {
                agemin: null,
                agemax: null,
                sex: {
                    M: true,
                    F: true,
                    I: true,
                },
                nacionalidades: {},
                comunas: {},
            },
        };
    },
    computed: {
        ...mapState(["comunas"]),
        ...mapGetters(["regiones"]),
        campanaEnFoco() {
            return this.$store.getters.getUno("campanas", this.campid);
        },
    },
    created() {
        console.log("con", this.critusarios);
        let prepare = {
            agemin: this.critusarios.agemin || null,
            agemax: this.critusarios.agemax || null,
            sex: {
                M: this.critusarios.sex ? this.critusarios.sex.M : true,
                F: this.critusarios.sex ? this.critusarios.sex.F : true,
                I: this.critusarios.sex ? this.critusarios.sex.I : true,
            },
            nacionalidades: this.critusarios.nacionalidades || {},
            comunas: this.critusarios.comunas || this.dameAllComunasCheked(),
        };
        this.tosend = Object.assign({}, prepare);
        //supralogica this.critusarios.comunas
        this.modifyUserCriteria();
    },
    methods: {
        async guardaModificacion({ action, payload }) {
            try {
                this.$f7.dialog.preloader(action == "evalua" ? "Evaluando impacto" : "Guardando cambios");
                let datous = await this.commonExecute(
                    { campanaid: this.campid, action, tipocriterio: "usuarios", payload },
                    "admin-cambiaCriteriosCampana",
                    false
                );
                this.$f7.dialog.close();
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log("creacion ok", datous);

                if (action == "evalua") {
                    console.log("mostrar resultado evaluacion y mostrar boton guardar", datous.evaluacion);
                } else if (action == "guarda") {
                    console.log("elguardado", datous.tosave), console.log("cerrar emitiendo");
                    this.$emit("nuevosaved", datous.tosave);
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        dameAllComunasCheked() {
            let comunchecks = {};
            Object.keys(this.comunas).forEach(unacomcod => {
                comunchecks[unacomcod] = true;
            });
            return comunchecks;
        },
        preparasave(realsave) {
            console.log("vamosa a guardar");
            let aguardar = {};
            if (this.tosend.agemin) {
                aguardar.agemin = this.tosend.agemin;
            }
            if (this.tosend.agemax) {
                aguardar.agemax = this.tosend.agemax;
            }
            if (!this.tosend.sex.M || !this.tosend.sex.F || !this.tosend.sex.I) {
                aguardar.sex = {
                    M: this.tosend.sex.M || null,
                    F: this.tosend.sex.F || null,
                    I: this.tosend.sex.I || null,
                };
            }
            //nacionalidades
            if (Object.values(this.tosend.nacionalidades).length) {
                aguardar.nacionalidades = this.tosend.nacionalidades;
            }

            let faltaUnaComuna = Object.keys(this.comunas).some(unacomecode => {
                return !this.tosend.comunas[unacomecode];
            });
            if (faltaUnaComuna) {
                //solo si falta una, cargar objeto de comunas
                let allcoms = Object.keys(this.tosend.comunas);
                aguardar.comunas = {};
                Object.keys(this.tosend.comunas).forEach(comcode => {
                    if (this.tosend.comunas[comcode]) {
                        aguardar.comunas[comcode] = true;
                    }
                });
            }
            console.log("SE VA A GUARDAR ESTO", aguardar, "realsave", realsave);
            this.guardaModificacion({ action: realsave ? "guarda" : "evalua", payload: aguardar });
        },
        eliminaEstePais(pais) {
            this.$delete(this.tosend.nacionalidades, pais);
        },
        agregaUnPais(elpais) {
            console.log("llegando pais al componente papá", elpais);
            if (elpais !== "") {
                this.$set(this.tosend.nacionalidades, elpais, elpais);
            }
        },
        handleAgeChange(cualrange, property, value) {
            if (value == "no") {
                this.tosend[cualrange] = null;
            } else {
                if (!this.tosend[cualrange]) {
                    this.tosend[cualrange] = {
                        d: 0,
                        m: 0,
                        y: 0,
                    };
                }
                this.tosend[cualrange][property] = parseInt(value);
            }
        },
        updateComuna(nuevoestado, regionid, provinciaid, comunaid) {
            this.regionsSelector = this.regionsSelector.map(unareg => {
                if (unareg.id == regionid) {
                    let provinces = unareg.provinces || {};
                    let comsselected = 0;
                    Object.values(provinces).forEach(unaprovince => {
                        if (provinciaid == unaprovince.id) {
                            let comunes = unaprovince.comunes || {};
                            let comunesArray = Object.values(comunes);
                            let comusels = 0;
                            comunesArray.forEach(unacom => {
                                if (comunaid == unacom.code) {
                                    this.tosend.comunas[unacom.code] = nuevoestado;
                                }
                                if (this.tosend.comunas[unacom.code]) {
                                    comusels++;
                                }
                            });
                            unaprovince.nsel = comusels;
                        }
                        comsselected += unaprovince.nsel;
                    });
                    unareg.nsel = comsselected;
                    return unareg;
                } else {
                    return unareg;
                }
            });
        },
        updateProvincia(nuevoestado, regionid, provinciaid) {
            this.regionsSelector = this.regionsSelector.map(unareg => {
                if (unareg.id == regionid) {
                    let provinces = unareg.provinces || {};
                    let comsselected = 0;
                    Object.values(provinces).forEach(unaprovince => {
                        if (provinciaid == unaprovince.id) {
                            let comunes = unaprovince.comunes || {};
                            let comunesArray = Object.values(comunes);
                            unaprovince.nsel = nuevoestado ? comunesArray.length : 0;
                            comunesArray.forEach(unacom => {
                                this.tosend.comunas[unacom.code] = nuevoestado;
                            });
                        }
                        comsselected += unaprovince.nsel;
                    });
                    unareg.nsel = comsselected;
                    return unareg;
                } else {
                    return unareg;
                }
            });
        },
        updateRegion(nuevoestado, regionid) {
            this.regionsSelector = this.regionsSelector.map(unareg => {
                if (unareg.id == regionid) {
                    let provinces = unareg.provinces || {};
                    unareg.nsel = nuevoestado ? unareg.coms : 0;
                    Object.values(provinces).forEach(unaprovince => {
                        let comunes = unaprovince.comunes || {};
                        let comunesArray = Object.values(comunes);
                        unaprovince.nsel = nuevoestado ? comunesArray.length : 0;
                        comunesArray.forEach(unacom => {
                            this.tosend.comunas[unacom.code] = nuevoestado;
                        });
                    });
                    return unareg;
                } else {
                    return unareg;
                }
            });
        },
        modifyUserCriteria() {
            this.regiones.forEach(unareg => {
                let provinces = unareg.provinces || {};
                unareg.nsel = 0;
                Object.values(provinces).forEach(unaprovince => {
                    let comunes = unaprovince.comunes || {};
                    let comunesArray = Object.values(comunes);
                    unaprovince.nsel = 0;
                    comunesArray.forEach(unacom => {
                        if (this.tosend.comunas[unacom.code]) {
                            unareg.nsel++;
                            unaprovince.nsel++;
                        }
                    });
                });
            });
            console.log(this.regiones);
            this.regionsSelector = [...this.regiones];
        },
    },
};
</script>
<style>
.noulpaddingleft ul {
    padding-left: 0px !important;
}
</style>
