<template>
    <f7-page name="fea">
        <f7-navbar title="Firma Electrónica Avanzada" subtitle="Firma pases internacionales manualmente">
            <!-- Requests status button -->
            <f7-nav-right>
                <f7-link href="/fea/requests-statuses/" tooltip="Ver estado de solicitudes">
                    <font-awesome-icon icon="history"></font-awesome-icon>
                </f7-link>
            </f7-nav-right>
        </f7-navbar>
        
        <div v-for="(solicitud, index) in solicitudes" :key="index">
            <div class="display-flex flex-direction-row justify-content-space-between align-items-center">
                <f7-block-title class="margin-bottom-half">Solicitud n°{{ index + 1 }}</f7-block-title>
                <f7-button v-if="solicitudes.length > 1" @click="deleteSolicitud(index)" class="margin-top-half margin-right-half" small color="red">
                    <font-awesome-icon class="fa-md" icon="trash"></font-awesome-icon>
                    Eliminar
                </f7-button>
            </div>
            <f7-list class="margin-vertical-half">
                <f7-list-input
                    label="Tipo de documento"
                    type="select"
                    :value="solicitud.tipoDoc"
                    @change="solicitud.tipoDoc = $event.target.value"
                >
                    <option value="RUN">RUN</option>
                    <option value="PAS">PAS</option>
                    <option value="DNI">DNI</option>
                </f7-list-input>
                <f7-list-input
                    label="Número de documento"
                    type="text"
                    placeholder="11.111.111-1"
                    :value="solicitud.numeroDoc"
                    @input="solicitud.numeroDoc = $event.target.value"
                ></f7-list-input>
            </f7-list>
        </div>

        <f7-button @click="addSolicitud" outline class="margin">
            <font-awesome-icon class="fa-md margin-right-half" icon="plus"></font-awesome-icon>
            Agregar solicitud
        </f7-button>
        <f7-button @click="sendSolicitudes" fill class="margin">
            <font-awesome-icon class="fa-md margin-right-half" icon="paper-plane"></font-awesome-icon>
            Enviar solicitudes
        </f7-button>
    </f7-page>
</template>

<script>
import { commonmixin } from "../mixins/common";
export default {
    mixins: [commonmixin],

    data() {
        return {
            solicitudes: [
                {
                    tipoDoc: 'RUN',
                    numeroDoc: '',
                },
            ],
        };
    },

    methods: {
        async sendSolicitudes() {
            try {
                const areSolicitudesValid = this.solicitudes.every(s => s.numeroDoc.length > 0);
                if (!areSolicitudesValid) {
                    this.$f7.dialog.alert('Hay solicitudes sin completar. Por favor, complete todos los campos.');
                    return;
                }

                console.log(this.solicitudes);

                this.$f7.dialog.preloader("Enviando solicitudes...");

                const response = await this.commonExecute({ solicitudes: this.solicitudes }, 'admin-processFEAsManually', false);

                if (response.error) {
                    throw new Error(response.error);
                }

                this.solicitudes = [
                    {
                        tipoDoc: 'RUN',
                        numeroDoc: '',
                    },
                ];

                this.$f7.dialog.close();

                this.$f7.views.main.router.navigate('/fea/requests-statuses/');

                this.$f7.toast.show({
                    text: "Los documentos fueron enviados a firmar exitosamente. Recibirás un correo electrónico cuando estén firmados (aprox. 5 minutos).",
                    horizontalPosition: "center",
                    closeTimeout: 3500,
                });
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, 'Error');
                console.error(error.message);
            }
        },
        addSolicitud() {
            this.solicitudes.push({
                tipoDoc: 'RUN',
                numeroDoc: '',
            });
        },
        deleteSolicitud(index) {
            this.solicitudes.splice(index, 1);
        },
    },
}
</script>