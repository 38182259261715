<template>
    <f7-page @page:beforein="fetchLabs">
        <f7-navbar>
            <f7-navbar>
                <f7-nav-left>
                    <f7-link icon-material="menu" panel-open="left"></f7-link>
                </f7-nav-left>
                <f7-nav-title title="API Laboratorios" subtitle="Gestiona las credenciales de los laboratorios para conectarse con la API de MeVacuno"></f7-nav-title>
                <f7-nav-right>
                    <f7-link @click="fetchLabs" icon-material="refresh" tooltip="Refrescar"></f7-link>
                </f7-nav-right>
            </f7-navbar>
        </f7-navbar>

        <f7-block>
            <f7-row v-if="loading">
                <f7-col class="text-align-center">
                    <f7-preloader></f7-preloader>
                </f7-col>
            </f7-row>
            
            <template v-else-if="labs.length > 0">
                <div class="display-flex flex-direction-row justify-content-space-between align-items-center">
                    <h3>Laboratorios</h3>
                    <f7-button @click="showCredentials = !showCredentials">
                        {{ showCredentials ? "Ocultar credenciales" : "Mostrar credenciales" }}
                    </f7-button>
                </div>
                <f7-searchbar
                    class="searchbar-labs no-margin-horizontal"
                    placeholder="Busque por nombre de laboratorio, API Key o API Secret"
                    search-container=".labs-list"
                    search-in=".item-title,.item-text"
                    :backdrop="false"
                ></f7-searchbar>
                <f7-list class="searchbar-not-found">
                    <f7-list-item title="No se encontraron laboratorios"></f7-list-item>
                </f7-list>
                <f7-list
                    ref="labsVl"
                    class="no-margin-top labs-list"
                    media-list
                    virtual-list
                    :virtual-list-params="{
                        items: labs,
                        searchAll,
                        renderExternal,
                        height: 96,
                    }"
                >
                    <ul>
                        <f7-list-item
                            v-for="(lab, index) in vlData.items"
                            :key="index"
                            :style="`top: ${vlData.topPosition}px`"
                        >
                            <b slot="title">{{ lab.name }} <f7-badge v-if="!lab.secret" color="red">Inactivo</f7-badge></b>
                            <span slot="text">
                                API Key: {{ showCredentials ? lab.key : "******" }}<br>
                                API Secret: {{ showCredentials ? lab.secret || "-" : "******" }}
                            </span>
                            <f7-button 
                                @click="toggleActiveStatus(lab)" 
                                slot="after" 
                                :color="lab.secret ? 'red' : 'green'" 
                                small
                            >
                                {{ lab.secret ? "Inactivar" : "Activar" }}
                            </f7-button>
                        </f7-list-item>
                    </ul>
                </f7-list>
            </template>

            <div v-else>
                <span>No hay laboratorios registrados</span>
            </div>
        </f7-block>

        <f7-fab @click="createLab" position="right-bottom" slot="fixed" color="primary">
            <f7-icon material="add"></f7-icon>
        </f7-fab>
    </f7-page>
</template>

<script>
export default {
    data() {
        return {
            labs: [],

            vlData: {
                items: [],
            },
            
            showCredentials: false,
            loading: false,
        };
    },

    methods: {
        fetchLabs() {
            const labsCollection = this.$store.state.dev ? "api_labs_keys_dev" : "api_labs_keys";

            this.loading = true;

            this.$firebase.firestore()
                .collection(labsCollection)
                .orderBy("name", "asc")
                .get()
                .then(snapshot => {
                    this.labs = snapshot.docs.map(doc => doc.data());
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                });
        },
        createLab() {
            this.$f7.dialog.prompt(
                "Ingrese el nombre del laboratorio",
                "Crear laboratorio",
                async (labName) => {
                    try {
                        this.$f7.dialog.preloader();

                        const createLabApiKey = this.$firebase.functions().httpsCallable("admin-createLabApiKey");
                        const response = await createLabApiKey({ labName, dev: this.$store.state.dev });

                        if (response.data.error) {
                            console.error(response.data.error.message);
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(response.data.error.message);
                            return;
                        }

                        const lab = response.data.createdLab;

                        this.labs.push(lab);
                        this.$refs.labsVl.f7VirtualList.replaceAllItems(this.labs);
                        
                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message);
                    }
                }
            );
        },
        toggleActiveStatus(lab) {
            const confirmText = `Se ${lab.secret ? "inactivarán" : "activarán"} las credenciales para este laboratorio`;

            this.$f7.dialog.confirm(
                confirmText,
                "¿Está seguro?",
                async () => {
                    try {
                        this.$f7.dialog.preloader();

                        const toggleActiveStatusLab = this.$firebase.functions().httpsCallable("admin-toggleActiveStatusLab");
                        const response = await toggleActiveStatusLab({ labApiKey: lab.key, dev: this.$store.state.dev });

                        if (response.data.error) {
                            console.error(response.data.error.message);
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(response.data.error.message);
                            return;
                        }

                        const updatedLab = response.data.updatedLab;

                        const index = this.labs.findIndex(l => l.key === updatedLab.key);
                        if (index !== -1) {
                            this.$set(this.labs, index, updatedLab);
                            this.$refs.labsVl.f7VirtualList.replaceItem(index, updatedLab);
                        }

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error(error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message);
                    }
                }
            );
        },

        // Virtual list related methods
        searchAll(query, items) {
            const found = [];
            for (let i = 0; i < items.length; i += 1) {
                if (items[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '')
                    found.push(i);

                if (items[i].key.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '')
                    found.push(i);

                if (items[i].secret && items[i].secret.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '')
                    found.push(i);
            }
            return found;
        },
        renderExternal(vl, vlData) {
            this.vlData = vlData;
        },
    },
}
</script>