<template>
    <f7-page v-if="currentSolicitudCopy" @page:beforein="handleBeforein" @page:beforeout="handleBeforeout" @page:afterin="handleAfterin">
        <f7-navbar>
            <f7-nav-left>
                <f7-link @click="backConfirm" icon-material="arrow_back"></f7-link>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>

            <f7-nav-title title="Resolviendo solicitud" subtitle="de validación de vacunas recibidas en Chile"></f7-nav-title>
        </f7-navbar>

        <f7-block class="margin-top">
            <f7c-alert v-if="!solicitudIsTakenByMe && currentSolicitudCopy.est !== 'Aceptada'" type="warning" class="margin-horizontal-half">
                Esta solicitud esta siendo resuelta por otro usuario.
            </f7c-alert>

            <f7c-alert
                v-if="currentSolicitudCopy.last_resol_by"
                :type="currentSolicitudCopy.est === 'Aceptada' ? 'success' : 'info'"
                class="margin-horizontal-half"
            >
                Última resolución de esta solicitud tomada por {{ currentSolicitudCopy.last_resol_by }} el
                {{ $dayjs.unix(currentSolicitudCopy.last_resol_at).format("DD/MM/YYYY HH:mm") }}.
                <f7-link @click="viewCurrentSolicitudLog">Ver todo el log</f7-link>
            </f7c-alert>

            <f7-row v-if="!isNacPostForm">
                <f7-col width="100" large="50">
                    <solicitud-patient
                        :uidSolicitante="currentSolicitudCopy.uid"
                        :emailSolicitante="currentSolicitudCopy.user_email"
                        @updatePatientInffo="vieneNuevoPaxInfo"
                        :patient="currentSolicitudPatient"
                    ></solicitud-patient>

                    <info-solicitud :solicitud="currentSolicitudCopy"></info-solicitud>

                    <h3 class="margin-horizontal-half padding-top">Vacunas declaradas ({{ currentSolicitudVaccines.length }})</h3>

                    <solicitud-vaccine
                        v-for="vaccine in currentSolicitudVaccines"
                        :key="vaccine.id"
                        :vaccine="vaccine"
                        @vaccine-changed="handleVaccineVaxChanged"
                        @vaccine-info-changed="handleVaccineInfoChanged"
                        :patient-is-verified="patientIsVerified"
                        :solicitud-is-taken-by-me="solicitudIsTakenByMe"
                        :solicitud="currentSolicitudCopy"
                    >
                        <div class="margin">
                            <resolution-box
                                :solicitud-status="currentSolicitudCopy.est"
                                :locked="!solicitudIsTakenByMe"
                                :patient-is-verified="patientIsVerified"
                                :vaccine="vaccine"
                                @resolution-changed="handleVaccineResolutionChanged"
                            ></resolution-box>
                        </div>
                    </solicitud-vaccine>
                </f7-col>
                <f7-col width="100" large="50">
                    <div v-if="loadingFiles" class="text-align-center">
                        <f7-preloader></f7-preloader>
                    </div>
                    <template v-else-if="!errorLoadingImagesAndMessages">
                        <h3>Imagenes</h3>

                        <div style="max-height:55vh; overflow: scroll">
                            <div v-if="solicitudImages.length === 0">
                                No hay imagenes para esta solicitud.
                            </div>
                            <div v-else>
                                <f7-photo-browser
                                    popup-close-link-text="Cerrar"
                                    :photos="solicitudImages"
                                    type="popup"
                                    ref="popupImages"
                                ></f7-photo-browser>
                                <f7-link v-for="image in solicitudImages" :key="image.id" @click="viewImage">
                                    <img :src="image.url" :alt="image.name" width="500" />
                                </f7-link>
                            </div>
                        </div>

                        <h3>Documentos</h3>

                        <div v-if="solicitudDocuments.length === 0">
                            No hay documentos para esta solicitud.
                        </div>
                        <div v-else>
                            <f7-list media-list class="no-margin">
                                <f7-list-item media-item v-for="document in solicitudDocuments" :key="document.id">
                                    <div slot="title">{{ document.name }}</div>
                                    <div slot="after">
                                        <f7-button small @click="viewDocument(document)">Ver</f7-button>
                                    </div>
                                </f7-list-item>
                            </f7-list>
                        </div>

                        <h3>Notas</h3>
                        <f7-card outline class="no-margin">
                            <f7-block class="text-align-center" v-if="loadingFiles"><f7-preloader></f7-preloader></f7-block>
                            <p v-else-if="!solicitudMensajes.length" class="text-color-gray padding-horizontal">
                                No hay notas para esta solicitud.
                            </p>
                            <div style="max-height: 40vh; overflow-y: scroll">
                                <f7-list media-list class="messenger-notes">
                                    <f7-list-item class="list-item-no-padding" v-for="(msg, index) in solicitudMensajes" :key="index">
                                        <div slot="title" style="white-space: normal">
                                            {{ msg.text }}
                                        </div>
                                        <div slot="header">
                                            <span>{{ msg.sender_uid == user.uid ? `Validador ${msg.sender_email}` : "Usuario MeVacuno" }}</span>
                                        </div>
                                        <div slot="footer">
                                            <span>Enviado el {{ $dayjs.unix(msg.wh / 1000) }}</span>
                                        </div>
                                    </f7-list-item>
                                </f7-list>
                            </div>
                            <template
                                v-if="currentSolicitudCopy.est !== 'Aceptada' && currentSolicitudCopy.est !== 'Cerrada' && solicitudIsTakenByMe"
                            >
                                <f7-list media-list class="no-margin">
                                    <f7-list-input
                                        class="list-item-no-padding text-area-size"
                                        outline
                                        type="textarea"
                                        clear-button
                                        placeholder="Escribe tu nota aqui"
                                        :value="messageText"
                                        @input="messageText = $event.target.value"
                                    >
                                    </f7-list-input>
                                </f7-list>
                                <f7-button
                                    @click="handleSendMessage"
                                    :disabled="loadingSendMessage"
                                    outline
                                    icon-material="add"
                                    class="display-flex align-items-center"
                                >
                                    {{ loadingSendMessage ? "Enviando..." : "Añadir nota" }}
                                </f7-button>
                            </template>
                        </f7-card>
                    </template>
                    <div v-else>
                        <h3 class="text-color-red">Hubo un error al cargar imagenes/documentos y notas</h3>

                        <f7-button fill color="primary" @click="fetchSolicitudFilesAndMessages">Volver a cargar</f7-button>
                    </div>
                </f7-col>
            </f7-row>

            <f7-row v-if="isNacPostForm">
                <f7-col width="100" large="50">
                    <solicitud-patient
                        :uidSolicitante="currentSolicitudCopy.uid"
                        :emailSolicitante="currentSolicitudCopy.user_email"
                        @updatePatientInffo="vieneNuevoPaxInfo"
                        :patient="currentSolicitudPatient"
                    ></solicitud-patient>

                    <info-solicitud :solicitud="currentSolicitudCopy"></info-solicitud>
                </f7-col>

                <f7-col width="100" large="50">
                    <h3>Vacunas agregadas por SEREMI</h3>

                    <p v-if="!currentSolicitudVaccines.length" class="text-color-gray padding-horizontal">
                        Aún no se agrega ninguna vacuna a esta solicitud.
                    </p>

                    <added-vaccine
                        v-for="vaccine in currentSolicitudVaccines"
                        :key="vaccine.id"
                        :vaccine="vaccine"
                        @vaccine-removed="handleVaccineRemoved"
                        @vaccine-editing="handleAddOrEditVaccine"
                        :patient-is-verified="patientIsVerified"
                        :solicitud-is-taken-by-me="solicitudIsTakenByMe"
                        :solicitud="currentSolicitudCopy"
                    >
                    </added-vaccine>

                    <f7-row v-if="canShowResolveButton">
                        <f7-col width="100" medium="50"></f7-col>
                        <f7-col width="100" medium="50">
                            <f7-button 
                                icon-material="add" 
                                outline 
                                class="display-flex align-items-center"
                                @click="handleAddOrEditVaccine(null)"
                            >
                                Agregar vacuna
                            </f7-button>
                        </f7-col>
                    </f7-row>

                    <h3>Notas</h3>
                        <f7-card outline class="no-margin">
                            <f7-block class="text-align-center" v-if="loadingFiles"><f7-preloader></f7-preloader></f7-block>
                            <p v-else-if="!solicitudMensajes.length" class="text-color-gray padding-horizontal">
                                No hay notas para esta solicitud.
                            </p>
                            <div style="max-height: 40vh; overflow-y: scroll">
                                <f7-list media-list class="messenger-notes">
                                    <f7-list-item class="list-item-no-padding" v-for="(msg, index) in solicitudMensajes" :key="index">
                                        <div slot="title" style="white-space: normal">
                                            {{ msg.text }}
                                        </div>
                                        <div slot="header">
                                            <span>{{ msg.sender_uid == user.uid ? `Validador ${msg.sender_email}` : "Usuario MeVacuno" }}</span>
                                        </div>
                                        <div slot="footer">
                                            <span>Enviado el {{ $dayjs.unix(msg.wh / 1000) }}</span>
                                        </div>
                                    </f7-list-item>
                                </f7-list>
                            </div>
                            <template
                                v-if="currentSolicitudCopy.est !== 'Aceptada' && currentSolicitudCopy.est !== 'Cerrada' && solicitudIsTakenByMe"
                            >
                                <f7-list media-list class="no-margin">
                                    <f7-list-input
                                        class="list-item-no-padding text-area-size"
                                        outline
                                        type="textarea"
                                        clear-button
                                        placeholder="Escribe tu nota aqui"
                                        :value="messageText"
                                        @input="messageText = $event.target.value"
                                    >
                                    </f7-list-input>
                                </f7-list>
                                <f7-button
                                    @click="handleSendMessage"
                                    :disabled="loadingSendMessage"
                                    outline
                                    icon-material="add"
                                    class="display-flex align-items-center"
                                >
                                    {{ loadingSendMessage ? "Enviando..." : "Añadir nota" }}
                                </f7-button>
                            </template>
                        </f7-card>
                </f7-col>
            </f7-row>

            <f7-row v-if="haveApostillaRequest" class="margin-top">
                <f7-col width="100" large="50">
                    <f7-card outline>
                        <f7-card-header>
                            <b>Solicitud de apostillamiento internacional</b>
                            <font-awesome-icon size="2x" icon="passport"></font-awesome-icon>
                        </f7-card-header>
                        <f7-card-content class="margin-top margin-bottom">
                            <f7-block>
                                <f7-row>
                                    <f7-col>
                                        <f7-list class="no-margin">
                                            <f7-list-item>
                                                <div slot="header">País destino</div>
                                                <div slot="title">{{ currentSolicitudCopy.actApos.descripcion }}</div>
                                            </f7-list-item>
                                        </f7-list>
                                    </f7-col>
                                    <f7-col>
                                        <f7-list class="no-margin">
                                            <f7-list-item>
                                                <div slot="header">Fecha de solicitud</div>
                                                <div slot="title">{{ $dayjs.unix(currentSolicitudCopy.actApos.when).format("DD/MM/YYYY") }}</div>
                                            </f7-list-item>
                                        </f7-list>
                                    </f7-col>
                                </f7-row>

                                <div v-if="canShowDecisionApostilla">
                                    <f7-block-title class="no-margin-bottom"><b>Decisión sobre apostillamiento <span class="text-color-red">*</span></b></f7-block-title>

                                    <f7-list class="no-margin">
                                        <f7-list-input
                                            type="select"
                                            :value="apostillaStatus"
                                            @change="handleChangeStatusApostilla"
                                        >
                                            <option :value="null">Seleccione</option>
                                            <option value="aceptada" :disabled="apostillaStatus === 'aceptada'" :selected="apostillaStatus === 'aceptada'">Aceptar apostillamiento</option>
                                            <option value="rechazada" :disabled="apostillaStatus === 'rechazada'" :selected="apostillaStatus === 'rechazada'">Rechazar apostillamiento</option>
                                        </f7-list-input>
                                    </f7-list>

                                    <template v-if="apostillaStatus === 'aceptada'">
                                        <f7-block-title><b>Detalles del apostillamiento <span class="text-color-red">*</span></b></f7-block-title>
                                    

                                        <f7-list class="no-margin">
                                            <f7-list-input
                                                type="select"
                                                label="Emisor del documento*"
                                                :value="infoApostilla.idEmisor"
                                                @change="handleChangeEmisor"
                                                :disabled="emisoresDocumento.length === 0"
                                            >
                                                <option :value="null">Seleccione</option>
                                                <option v-for="emisor in emisoresDocumento" :key="emisor.id" :value="emisor.id">{{ emisor.descripcion }}</option>
                                            </f7-list-input>
                                            <f7-list-input
                                                type="select"
                                                label="Firmante del documento*"
                                                :value="infoApostilla.idFirmante"
                                                @change="handleChangeFirmante"
                                                :disabled="firmantesDocumento.length === 0"
                                            >
                                                <option :value="null">Seleccione</option>
                                                <option v-for="firmante in firmantesDocumento" :key="firmante.id" :value="firmante.id">{{ firmante.descripcion }}</option>
                                            </f7-list-input>
                                        </f7-list>
                                    </template>
                                </div>
                            </f7-block>
                        </f7-card-content>
                    </f7-card>
                </f7-col>
            </f7-row>

            <f7-list v-if="canShowResolveButton || canShowResolveSignButton || canShowResolveApostillaButton">
                <f7-list-input
                    outline
                    label="Resolución de la solicitud"
                    type="select"
                    defaultValue=""
                    placeholder="Favor seleccione..."
                    @input="tipoDecisionSelected = $event.target.value"
                    :value="tipoDecisionSelected"
                    :info="
                        tipoDecisionSelected == 1
                            ? tipo === 'sign'
                                ? 'Aprueba la firma del certificado de vacunación con la información actual.'
                                : 'Aprueba la solicitud y la envía al certificador SEREMI para su revisión y firma.'
                            : tipoDecisionSelected == 2 ? 'Al rechazar la solicitud esta no puede ser editada por el usuario, pero puede crear una nueva de ser necesario. Debe incluir un motivo de rechazo.' : null
                    "
                >
                    <option value="">Seleccione</option>
                    <option value="1">Aprueba solicitud</option>
                    <option value="2">Rechaza solicitud</option>
                </f7-list-input>

                <f7-list-input
                    v-if="tipoDecisionSelected == 2"
                    class="list-item-no-padding text-area-size"
                    outline
                    type="textarea"
                    clear-button
                    placeholder="Escriba aquí el motivo del rechazo de la solicitud. Este mensaje será visto por el usuario en su cuenta de MeVacuno."
                    :value="freetxt"
                    @input="freetxt = $event.target.value"
                >
                </f7-list-input>
            </f7-list>

            <f7-button
                v-if="(canShowResolveButton || canShowResolveSignButton || canShowResolveApostillaButton) && tipoDecisionSelected == 1"
                fill
                large
                raised
                color="primary"
                class="margin-vertical margin-horizontal-half"
                @click="resolveSolicitud"
            >
                {{ tipo === 'sign' ? 'Firmar solicitud' : 'Finalizar resolución'}}
            </f7-button>
            <f7-button
                v-else-if="(canShowResolveButton || canShowResolveSignButton || canShowResolveApostillaButton) && tipoDecisionSelected == 2"
                fill
                large
                raised
                color="red"
                class="margin-vertical margin-horizontal-half"
                @click="rejectSolicitud"
            >
                {{'Rechazar solicitud'}}
            </f7-button>
            <div v-else-if="currentSolicitudCopy.est != 'Aceptada' || (tipo == 'sign' && currentSolicitudCopy.est == 'Aceptada' && currentSolicitudCopy.cert.status != 'approved')" class="text-align-center margin">
                <div v-if="!solicitudIsTakenByMe">La solicitud esta siendo resuelta por otro usuario</div>
                <div v-else-if="!patientIsVerified">El paciente no ha sido verificado</div>
                <div v-else-if="currentSolicitudCopy.est === 'Cerrada'">La solicitud se encuentra cerrada</div>
            </div>
        </f7-block>

        <f7-popup class="solicitud-log" :opened="solicitudLogPopupOpened" @popup:closed="solicitudLogPopupOpened = false">
            <f7-page>
                <f7-navbar title="Viendo logs de la solicitud"> 
                    <f7-nav-right>
                        <f7-link @click="solicitudLogPopupOpened = false" >Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-list media-list class="no-margin-top">
                    <f7-list-item media-item v-for="(log, index) in orderedSolicitudLogs" :key="index">
                        <div slot="header">{{ $dayjs.unix(log.when).format("DD/MM/YYYY HH:mm") }}</div>
                        <div slot="title" style="white-space: normal">
                            {{ log.action }}. <span v-if="log.action.toLowerCase() === 'vacuna rechazada' && log.motiv">({{ log.motiv }})</span>
                        </div>
                        <div slot="subtitle">
                            <template v-if="log.by">
                                <span>{{ log.by }}</span
                                ><span v-if="log.vaccineDeclId"> &bull; Vacuna declarada {{ getVacunaInfo(log.vaccineDeclId) }}</span>
                            </template>
                            <span v-else>Usuario MeVacuno</span>
                        </div>
                    </f7-list-item>
                </f7-list>
            </f7-page>
        </f7-popup>

        <add-vaccine-popup
            :opened="solicitudAddVaccinePopupOpened"
            :vaccine="vaccineObject"
            @modified="handleNewVaccineAddedOrModified"
            @popup-closed="solicitudAddVaccinePopupOpened = false">
        </add-vaccine-popup>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
import Alert from "../../components/Alert.vue";
import SolicitudPatient from "./components/solicitud-patient.vue";
import SolicitudVaccine from "./components/solicitud-vaccine.vue";
import ResolutionBox from "./components/resolution-box.vue";
import InfoSolicitud from "./components/info-solicitud.vue";
import AddedVaccine from "./components/added-vaccine.vue";
import AddVaccinePopup from "./components/add-vaccine-popup.vue";
import { commonmixin } from "../../mixins/common";

export default {
    mixins: [commonmixin],
    components: {
        "f7c-alert": Alert,
        "solicitud-patient": SolicitudPatient,
        "solicitud-vaccine": SolicitudVaccine,
        "resolution-box": ResolutionBox,
        "info-solicitud": InfoSolicitud,
        "added-vaccine": AddedVaccine,
        "add-vaccine-popup": AddVaccinePopup,
    },
    props: {
        tipo: {
            type: String,
            default: "nac",
        },
    },
    data() {
        return {
            currentSolicitudCopy: null,

            freetxt: "",

            tipoDecisionSelected: "",

            solicitudLog: [],
            solicitudLogPopupOpened: false,
            solicitudAddVaccinePopupOpened: false,

            vaccineObject: null,

            loadingFiles: false,

            allSolicitudFiles: {},
            solicitudMensajes: [],

            messageText: "",
            loadingSendMessage: false,

            solicitudObservable: null,

            solicitudIsTakenByMe: true,

            errorLoadingImagesAndMessages: false,

            apostillaStatus: null,

            infoApostilla: {
                idEmisor: null,
                idFirmante: null,
            },

            emisoresDocumento: [],
            firmantesDocumento: [],
        };
    },

    computed: {
        ...mapState(["user", "dev"]),

        ...mapState("homologacionVacunasNac", ["currentSolicitud", "motivosRechazo", "acabaDeRechazarIdentidad", "vacunasHomologacion"]),

        currentSolicitudPatient() {
            if (!this.currentSolicitudCopy) return {};

            return this.currentSolicitudCopy.pat || {};
        },

        currentSolicitudVaccines() {
            if (!this.currentSolicitudCopy) return [];

            let vacines = Object.values(this.currentSolicitudCopy.vacs || {}).sort((a, b) => {
                let dateA = a.fechavac
                    .split("/")
                    .reverse()
                    .join("");
                let dateB = b.fechavac
                    .split("/")
                    .reverse()
                    .join("");

                if (this.tipo === "nac") {
                    dateA = a.order;
                    dateB = b.order;
                }

                return dateA < dateB ? -1 : dateA > dateB ? 1 : 0;
            });

            return vacines;
        },

        allVaccinesResolved() {
            if (!this.currentSolicitudCopy) return false;

            const totalVaccines = this.currentSolicitudVaccines.length;
            const vaccinesWithResolution = this.currentSolicitudVaccines.filter(vaccine => vaccine.est && vaccine.est.resol).length;

            return totalVaccines === vaccinesWithResolution;
        },

        countAcceptedVaccines() {
            if (!this.currentSolicitudCopy) return 0;

            return this.currentSolicitudVaccines.filter(vaccine => vaccine.est && vaccine.est.resol === "Aceptada").length;
        },
        countRejectedVaccines() {
            if (!this.currentSolicitudCopy) return 0;

            return this.currentSolicitudVaccines.filter(vaccine => vaccine.est && vaccine.est.resol === "Rechazada").length;
        },

        orderedSolicitudLogs() {
            if (!this.solicitudLog) return [];

            return this.solicitudLog.sort((a, b) => {
                if (a.when > b.when) return -1;
                if (a.when < b.when) return 1;
                return 0;
            });
        },

        solicitudImages() {
            if (!this.currentSolicitudCopy) return [];

            const images = Object.values(this.allSolicitudFiles || {}).filter(file => {
                const ext = file.strdname.split(".").pop();

                return ["jpg", "jpeg", "png", "gif"].includes(ext);
            });

            return images;
        },

        solicitudDocuments() {
            if (!this.currentSolicitudCopy) return [];

            const documents = Object.values(this.allSolicitudFiles || {}).filter(file => {
                const ext = file.strdname.split(".").pop();

                return !["jpg", "jpeg", "png", "gif"].includes(ext);
            });

            return documents;
        },

        patientIsVerified() {
            if (!this.currentSolicitudCopy) return false;

            const verifiedStatus = this.currentSolicitudCopy.pat.verified;

            return verifiedStatus !== null && verifiedStatus !== undefined && verifiedStatus !== false;
        },

        canShowResolveButton() {
            return (
                this.tipo === "nac" &&
                this.currentSolicitudCopy.est !== "Aceptada" &&
                this.currentSolicitudCopy.est !== "Cerrada" &&
                this.patientIsVerified &&
                this.solicitudIsTakenByMe
            );
        },

        canShowResolveSignButton() {
            return (
                this.tipo === "sign" &&
                this.currentSolicitudCopy.est === "Aceptada" &&
                this.currentSolicitudCopy.cert.status !== "approved" &&
                this.patientIsVerified &&
                this.solicitudIsTakenByMe 
            );
        },

        canShowResolveApostillaButton() {
            return (
                this.tipo === "apostilla" &&
                this.currentSolicitudCopy.est === "Aceptada" &&
                this.currentSolicitudCopy.cert.status === "approved" &&
                this.currentSolicitudCopy.reqApos &&
                this.patientIsVerified &&
                this.solicitudIsTakenByMe
            );
        },

        canShowDecisionApostilla() {
            return (
                this.tipo !== "nac" &&
                this.tipo !== "view" &&
                this.currentSolicitudCopy.reqApos &&
                this.patientIsVerified &&
                this.solicitudIsTakenByMe
            );
        },

        canShowRechazarButton(){
            return (
                this.currentSolicitudCopy.est !== "Aceptada" &&
                this.currentSolicitudCopy.est !== "Cerrada" &&
                this.patientIsVerified &&
                this.solicitudIsTakenByMe &&
                this.countAcceptedVaccines === 0
            );
        },

        isNacPostForm() {
            return this.tipo === "nac" && this.currentSolicitudCopy.periodo === "post2013";
        },

        haveApostillaRequest() {
            return this.currentSolicitudCopy.reqApos;
        },

        requireApostillaDecision () {
            return this.currentSolicitudCopy.reqApos && (this.tipo === "sign" || this.tipo === "apostilla");
        },
    },

    async created() {
        this.currentSolicitudCopy = JSON.parse(JSON.stringify(this.currentSolicitud));

        const promises = [this.fetchMotivosRechazo(), this.fetchSolicitudFilesAndMessages()];

        await Promise.all(promises);
    },

    methods: {
        handleBeforein() {
            let collection = this.dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";

            this.solicitudObservable = this.$firebase
                .firestore()
                .collection(collection)
                .where("id", "==", this.currentSolicitudCopy.id)
                .onSnapshot(snapshot => {
                    snapshot.docChanges().forEach(change => {
                        if (change.type === "modified") {
                            const solicitudModified = change.doc.data();

                            if (solicitudModified.est === "Cerrada") {
                                this.solicitudIsTakenByMe = false;
                            } else if (this.tipo === "nac" && solicitudModified.last_resol_by && solicitudModified.last_resol_by !== this.user.email) {
                                this.solicitudIsTakenByMe = false;
                            } else if (this.tipo === "sign" && solicitudModified.last_resol_sign_by && solicitudModified.last_resol_sign_by !== this.user.email) {
                                this.solicitudIsTakenByMe = false;
                            } else if (this.tipo === "apostilla" && solicitudModified.last_resol_apostilla_by && solicitudModified.last_resol_apostilla_by !== this.user.email) {
                                this.solicitudIsTakenByMe = false;
                            }
                        }
                    });
                });
        },

        handleBeforeout() {
            if (this.solicitudObservable) {
                this.solicitudObservable();

                this.solicitudObservable = null;
            }
        },

        handleAfterin() {
            if (this.acabaDeRechazarIdentidad) {
                setTimeout(() => {
                    // Hack, sin el setTimeout no se triggerea views.router.back();
                    this.backConfirm();
                }, 250);
            }
        },

        async fetchMotivosRechazo() {
            if (this.motivosRechazo.length > 0) return;

            const motivosSnap = await this.$firebase
                .firestore()
                .collection("homolog_motivs_rechazo")
                .get();

            const motivos = [];

            motivosSnap.docs.forEach(doc => {
                motivos.push(doc.data());
            });

            this.$store.commit("homologacionVacunasNac/set_motivos_rechazo", motivos);
        },
        vieneNuevoPaxInfo(nuevopax) {
            console.log("Viene nuevo pax info", nuevopax);
            if (nuevopax) {
                this.currentSolicitudCopy.pat = Object.assign({}, this.currentSolicitudCopy.pat, nuevopax);
            }
        },
        async handleSendMessage() {
            const message = this.messageText.trim();

            if (message.length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe añadir una nota");
                return;
            }

            try {
                this.loadingSendMessage = true;

                const mandarMensajeHomolog = this.$firebase.functions().httpsCallable("mevacuno-mandarMensajeHomolog");

                const { data } = await mandarMensajeHomolog({
                    solid: this.currentSolicitudCopy.id,
                    msg: message,
                    dev: this.dev ? true : false,
                    tipo: this.tipo,
                });

                if (data.error) {
                    throw new Error(data.error.message || data.error);
                }

                this.solicitudMensajes.unshift(data.payload);

                this.messageText = "";

                this.loadingSendMessage = false;
            } catch (error) {
                this.loadingSendMessage = false;
                console.error(error);
                this.$f7.dialog.alert(error.message);
            }
        },

        async fetchSolicitudFilesAndMessages() {
            try {
                this.errorLoadingImagesAndMessages = false;

                if (this.currentSolicitudCopy) {
                    this.loadingFiles = true;

                    const getAllURLsOneSol = this.$firebase.functions().httpsCallable("mevacuno-getAllURLsOneSol");

                    const { data } = await getAllURLsOneSol({ solid: this.currentSolicitudCopy.id, vacunator: true, dev: this.dev ? true : false, tipo: this.tipo });

                    if (data.error) {
                        throw new Error(data.error.message);
                    }

                    data.payload.files.forEach(file => {
                        this.$set(this.allSolicitudFiles, file.id, file);
                    });

                    const msgsSolicitudEnEspera = Object.values(data.payload.mensajesx || {}).sort((a, b) => {
                        if (a.wh < b.wh) return 1;
                        if (a.wh > b.wh) return -1;
                        return 0;
                    });

                    this.solicitudMensajes = msgsSolicitudEnEspera;

                    this.loadingFiles = false;

                    if (data.payload.filesNotExisting && data.payload.filesNotExisting.length) {
                        data.payload.filesNotExisting.forEach(unaMissingFileId => {
                            if (this.currentSolicitudCopy.files && this.currentSolicitudCopy.files[unaMissingFileId]) {
                                this.$delete(this.currentSolicitudCopy.files, unaMissingFileId);
                                return this.$f7.dialog.alert(
                                    "El usuario cargó un archivo que presenta errores. Favor solicitar al usuario re-cargar su documentación",
                                    "Error"
                                );
                            }
                        });
                    }
                }
            } catch (error) {
                this.loadingFiles = false;
                console.log(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, error.code);

                this.errorLoadingImagesAndMessages = true;
            }
        },

        validateSolicitud() {
            let isValid = true;
            const errors = [];

            if (!this.currentSolicitudCopy.pat.verified) {
                isValid = false;
                errors.push("El paciente no ha sido verificado");
            }

            if (!this.allVaccinesResolved) {
                isValid = false;
                errors.push("Todas las vacunas deben tener una decisión");
            }

            const rejectedVaccines = Object.values(this.currentSolicitudCopy.vacs || {}).filter(
                vaccine => vaccine.est && vaccine.est.resol === "Rechazada"
            );

            const allWithMotiv = rejectedVaccines.every(vaccine => vaccine.est.motiv);

            if (!allWithMotiv) {
                isValid = false;
                errors.push("Todas las vacunas rechazadas deben tener un motivo");
            }

            if (this.isNacPostForm) {
                if (Object.values(this.currentSolicitudVaccines).length === 0) {
                    isValid = false;
                    errors.push("No se puede resolver una solicitud sin vacunas");
                }
            }

            if (this.requireApostillaDecision) {
                if (!this.apostillaStatus) {
                    isValid = false;
                    errors.push("Debe seleccionar una decisión sobre el apostillamiento");
                } else if (this.apostillaStatus === "aceptada") {
                    if (!this.infoApostilla.idEmisor) {
                        isValid = false;
                        errors.push("Debe seleccionar un emisor del documento");
                    }

                    if (!this.infoApostilla.idFirmante) {
                        isValid = false;
                        errors.push("Debe seleccionar un firmante del documento");
                    }
                }
            }

            return { isValid, errors };
        },

        resolveSolicitud() {
            if (!this.user) return;

            const { isValid, errors } = this.validateSolicitud();

            if (!isValid) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(errors.join("<br>"));
                return;
            }

            this.$f7.dialog.confirm("¿Esta seguro?", async () => {
                this.$f7.dialog.preloader("Guardando resolución");

                try {
                    let actionResolve = this.$firebase.functions().httpsCallable("admin-resolveSolicitudNacional");
                    
                    const { id, uid, vacs } = this.currentSolicitudCopy;

                    let modifiedSolicitud = { id, uid, vacs };

                    const { data } = await actionResolve({
                        modifiedSolicitud,
                        dev: this.dev ? true : false,
                        tipo: this.tipo,
                        apostillaDecision: this.requireApostillaDecision ? this.apostillaStatus : null,
                        apostillaDetalle: this.requireApostillaDecision ? this.infoApostilla : null,
                    });

                    if (data.error) {
                        throw new Error(data.error.message);
                    }
                    this.$store.commit("homologacionVacunasNac/update_solicitud", {
                        solicitudId: this.currentSolicitudCopy.id,
                        update: data.originalSolicitud,
                    });

                    this.currentSolicitudCopy = Object.assign({}, this.currentSolicitudCopy, data.originalSolicitud);

                    this.$f7.toast.show({
                        text: "Solicitud resuelta con éxito",
                        closeTimeout: 2500,
                        position: "bottom",
                        horizontalPosition: "center",
                    });

                    this.$f7.views.main.router.back();

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al guardar resolución. " + error);
                    return;
                }
            });
        },

        async rejectSolicitud() {

            if (this.freetxt.trim().length === 0) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe escribir un motivo de rechazo");
                return;
            }

            this.$f7.dialog.confirm("¿Esta seguro de rechazar la solicitud?", async () => {

                this.$f7.dialog.preloader("Guardando resolución");

                try {
                    let actionReject = this.$firebase.functions().httpsCallable("admin-rejectSolicitudNacional");
                    
                    const { id } = this.currentSolicitudCopy;

                    let modifiedSolicitud = { id };

                    const { data } = await actionReject({
                        modifiedSolicitud,
                        dev: this.dev ? true : false,
                        tipo: this.tipo,
                        motivo: this.freetxt
                    });

                    if (data.error) {
                        throw new Error(data.error.message);
                    }
                    this.$store.commit("homologacionVacunasNac/update_solicitud", {
                        solicitudId: this.currentSolicitudCopy.id,
                        update: data.originalSolicitud,
                    });

                    this.currentSolicitudCopy = Object.assign({}, this.currentSolicitudCopy, data.originalSolicitud);

                    this.$f7.toast.show({
                        text: "Solicitud rechazada con éxito",
                        closeTimeout: 2500,
                        position: "bottom",
                        horizontalPosition: "center",
                    });

                    this.$f7.views.main.router.back();

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error al guardar resolución. " + error);
                    return;
                }
            });
        },

        async viewCurrentSolicitudLog() {
            if (this.solicitudLog.length > 0) {
                this.solicitudLogPopupOpened = true;
                return;
            }

            this.$f7.dialog.close();
            this.$f7.dialog.preloader("Cargando logs");

            let collection = this.dev ? "logs_solicitudes_homologacion_nac_dev" : "logs_solicitudes_homologacion_nac";

            const logs = await this.$firebase
                .firestore()
                .collection(`${collection}/${this.currentSolicitudCopy.uid}/${this.currentSolicitudCopy.id}`)
                .get();

            this.solicitudLog = logs.docs.map(doc => doc.data());

            this.$f7.dialog.close();

            this.solicitudLogPopupOpened = true;
        },

        handleVaccineVaxChanged(payload) {
            const { vacuna, vaccine } = payload;

            this.$set(this.currentSolicitudCopy.vacs[vaccine.id], "vac", {
                id: vacuna.vacunaPcId,
                name: vacuna.vacunaPcName,
                labName: vacuna.laboratorioName,
            });

            if (!this.currentSolicitudCopy.vacs[vaccine.id].est) {
                this.$set(this.currentSolicitudCopy.vacs[vaccine.id], "est", {});
            }

            this.$set(this.currentSolicitudCopy.vacs[vaccine.id].est, "upd_by_validador", true);
        },
        handleVaccineInfoChanged(payload) {
            const { vaccine, whatChanged, value } = payload;

            this.$set(this.currentSolicitudCopy.vacs[vaccine.id], whatChanged, value);

            if (!this.currentSolicitudCopy.vacs[vaccine.id].est) {
                this.$set(this.currentSolicitudCopy.vacs[vaccine.id], "est", {});
            }

            this.$set(this.currentSolicitudCopy.vacs[vaccine.id].est, "upd_by_validador", true);
        },

        handleVaccineResolutionChanged(payload) {
            const { whatChanged, vaccine } = payload;

            if (whatChanged === "decision") {
                let update = {};

                update = {
                    by: this.user.email,
                    resol: payload.decision || "",
                    motiv: "",
                    when: null,
                };

                if (!this.currentSolicitudCopy.vacs[vaccine.id].est) {
                    this.$set(this.currentSolicitudCopy.vacs[vaccine.id], "est", {});
                }

                const updatedStatus = {
                    ...this.currentSolicitudCopy.vacs[vaccine.id].est,
                    ...update,
                };

                this.$set(this.currentSolicitudCopy.vacs[vaccine.id], "est", updatedStatus);
            } else if (whatChanged === "reject-reason") {
                this.$set(this.currentSolicitudCopy.vacs[vaccine.id].est, "motiv", payload.reason);
            }
        },

        handleAddOrEditVaccine(vacId = null) {
            if (vacId) {
                this.vaccineObject = this.currentSolicitudCopy.vacs[vacId];
            } else {
                this.vaccineObject = null;
            }
            this.solicitudAddVaccinePopupOpened = true;
        },

        handleNewVaccineAddedOrModified(vac) {
            const estado = {
                by: this.user.email,
                resol: "Aceptada",
                motiv: "",
                when: this.$dayjs().unix(),
            };

            if (!vac.id) {
                const collection = this.dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";
                vac.id = this.$firebase.firestore().collection(collection).doc().id;
                console.log("vac.id", vac.id);
                const size = this.currentSolicitudCopy.vacs ? Object.keys(this.currentSolicitudCopy.vacs).length : 0;
                Object.assign(vac, { est: estado, order: size + 1 });
            }
            

            if (!this.currentSolicitudCopy.vacs) {
                this.$set(this.currentSolicitudCopy, "vacs", {});
            }

            this.$set(this.currentSolicitudCopy.vacs, vac.id, vac);
        },

        handleVaccineRemoved(vaccineId) {
            this.$delete(this.currentSolicitudCopy.vacs, vaccineId);
        },

        viewDocument(document) {
            if (!document.url) return;

            window.open(document.url, "_blank");
        },
        viewImage() {
            this.$refs.popupImages.open();
        },

        getVacunaInfo(vaccineDeclId) {
            const vaccine = this.currentSolicitudCopy.vacs[vaccineDeclId];

            if (!vaccine) return "Vacuna no identificada";

            return `${vaccine.vac.name || "Vacuna no identificada"} ${vaccine.fechavac || "Sin fecha"}`;
        },

        async backConfirm() {
            const back = async () => {
                if (!this.currentSolicitudCopy) return;

                const solicitudId = this.currentSolicitudCopy.id;

                const backFirmando = this.tipo === "sign" && this.currentSolicitudCopy.est === "Aceptada" 
                    && this.currentSolicitudCopy.cert && this.currentSolicitudCopy.cert.status === "signing"
                    && this.currentSolicitudCopy.cert.last_tkn === this.user.email;

                const backApostillando = this.tipo === "apostilla" && this.currentSolicitudCopy.est === "Aceptada" 
                    && this.currentSolicitudCopy.cert.status === "approved"
                    && this.currentSolicitudCopy.actApos && this.currentSolicitudCopy.actApos.status === "reviewing"
                    && this.currentSolicitudCopy.actApos.last_tkn === this.user.email;

                const backNacional = this.tipo === "nac" && this.currentSolicitudCopy.est !== "Aceptada" 
                    && this.currentSolicitudCopy.last_tkn === this.user.email;

                if (backFirmando || backApostillando || backNacional) {
                    try {
                        this.$f7.dialog.preloader("Dejando solicitud");

                        const toggleTakeSolicitudHomologacion = this.$firebase.functions().httpsCallable("admin-toggleTakeSolicitudHomologacion");

                        const { data } = await toggleTakeSolicitudHomologacion({
                            solicitudId: solicitudId,
                            dev: this.dev ? true : false,
                            take: false,
                            tipo: this.tipo
                        });

                        if (data.error) {
                            throw new Error(data.error.message);
                        }

                        const updatedSolicitud = Object.assign({}, this.currentSolicitudCopy, data.solicitudUpdate);

                        this.$store.commit("homologacionVacunasNac/update_solicitud", {
                            solicitudId,
                            update: updatedSolicitud,
                        });

                        this.$f7.dialog.close();
                    } catch (error) {
                        console.error("error al guardar la solicitud", error);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Error al cerrar solicitud. " + error);
                    }
                } else {
                    this.$store.commit("homologacionVacunasNac/set_current_solicitud", null);
                }

                this.$f7.views.main.router.back();
            };

            if (
                (this.currentSolicitudCopy.est === "Aceptada" && this.tipo === "nac") ||
                (this.currentSolicitudCopy.est === "Aceptada" && this.tipo === "sign" && this.currentSolicitudCopy.cert.status === "approved") ||
                (this.currentSolicitudCopy.est === "Aceptada" && this.tipo === "apostilla" && !this.currentSolicitudCopy.reqApos) ||
                this.tipo === "view" ||
                this.currentSolicitudCopy.est === "Cerrada" ||
                !this.solicitudIsTakenByMe ||
                this.acabaDeRechazarIdentidad
            ) {
                this.$store.commit("homologacionVacunasNac/set_current_solicitud", null);

                if (this.acabaDeRechazarIdentidad) {
                    this.$store.commit("homologacionVacunasNac/set_acaba_de_rechazar", null);
                    this.$store.commit("homologacionVacunasNac/remove_solicitud", this.currentSolicitudCopy.id);
                }

                this.$f7.views.main.router.back();
            } else {
                this.$f7.dialog.confirm("¿Está seguro de volver?", async () => await back());
            }
        },

        async handleChangeStatusApostilla(event) {
            this.apostillaStatus = event.target.value;
            this.infoApostilla = {
                idEmisor: null,
                idFirmante: null,
            };

            this.firmantesDocumento = [];

            if (this.apostillaStatus === "aceptada" && this.emisoresDocumento.length === 0) {
                await this.fetchEmisoresApostilla();
            }
        },

        async handleChangeEmisor(event) {
            this.infoApostilla.idEmisor = event.target.value;

            if (!this.infoApostilla.idEmisor) {
                this.firmantesDocumento = [];
                this.infoApostilla.idEmisor = null;
                this.infoApostilla.idFirmante = null;
                return;
            }

            await this.fetchFirmantesApostilla();
        },

        handleChangeFirmante(event) {
            this.infoApostilla.idFirmante = event.target.value;
        },

        async fetchEmisoresApostilla() {
            try {
                this.$f7.dialog.preloader("Cargando emisores");

                const fetchEmisores =  this.$firebase.functions().httpsCallable("prosex-getEmisoresApostilla");

                const { data } = await fetchEmisores({ region: this.currentSolicitudCopy.address.region });
                
                if (data.error) {
                    throw new Error(data.error.message);
                }

                this.emisoresDocumento = data.payload;

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },

        async fetchFirmantesApostilla() {
            try {
                this.$f7.dialog.preloader("Cargando firmantes");

                const fetchFirmantes =  this.$firebase.functions().httpsCallable("prosex-getFirmantesApostilla");

                const { data } = await fetchFirmantes({ region: this.currentSolicitudCopy.address.region, idEmisor: this.infoApostilla.idEmisor });
                
                if (data.error) {
                    throw new Error(data.error.message);
                }

                this.firmantesDocumento = data.payload;

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },
    },
};
</script>
