<template>
    <f7-page>
        <f7-navbar title="Crear nueva cuenta"> </f7-navbar>

        <f7-list class="fixfamarg no-margin-vertical">
            <f7-list-item divider title="1.- Indique RUT a crear con privilegios de admin"></f7-list-item>
            <f7-list-input
                label="RUT"
                type="text"
                placeholder="160476540"
                :value="userInfo.rut"
                @input="escribeRut($event.target.value)"
                @change="evaluaRut()"
                error-message="RUT no parece válido"
                @keyup.native.enter="siguiente()"
                :error-message-force="!userInfo.rutvalido"
                :disabled="userInfo.rutok"
            >
                <font-awesome-icon class="fa-2x text-color-primary" icon="id-badge" slot="media"></font-awesome-icon>
            </f7-list-input>
            <template v-if="userInfo.rutok">
                <f7-list-item divider title="2.- Indique email a crear con privilegios de admin"></f7-list-item>
                <f7-list-input
                    label="email"
                    type="email"
                    placeholder="usuario@dominio.com"
                    input-id="emailinput"
                    :value="userInfo.email"
                    @keyup.native.enter="siguiente()"
                    error-message="e-mail no parece válido"
                    :error-message-force="!userInfo.emailvalido"
                    @input="escribeEmail($event.target.value)"
                    @change="evaluaEmail()"
                    :disabled="userInfo.emailok"
                >
                    <font-awesome-icon class="fa-2x text-color-primary" icon="at" slot="media"></font-awesome-icon>
                </f7-list-input>
                <template v-if="userInfo.emailok">
                    <f7-list-item divider title="3.- Indique datos del usuario"></f7-list-item>
                    <f7-list-input
                        type="select"
                        label="Tipo de cuenta"
                        :value="userInfo.type"
                        @input="userInfo.type = $event.target.value"
                        placeholder="Tipo de cuenta"
                    >
                        <option value="central">Central</option>
                        <option value="regional">Regional</option>
                    </f7-list-input>
                    <f7-list-item
                        class="select-region-item"
                        v-if="userInfo.type == 'regional'"
                        header="Seleccione la(s) región(es) a las que pertenece el usuario"
                        :title="userInfo.regiones.length ? userInfo.regiones.length + ' regiones seleccionadas' : 'Ninguna'"
                        after=""
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: { removeDiacritics: true },
                            searchbarPlaceholder: 'Buscar región',
                            routableModals: false,
                            closeOnSelect: false,
                            virtualList: true,
                            setValueText: false,
                        }"
                    >
                    <select multiple v-model="userInfo.regiones">
                        <option v-for="region in regiones" :key="region.number" :value="region.id">
                            {{ `${region.name} (${region.number})` }}
                        </option>
                    </select>
                    </f7-list-item>
                    <f7-list-input
                        label="Nombre(s)"
                        type="text"
                        input-id="name1input"
                        :value="userInfo.nombres"
                        @input="userInfo.nombres = $event.target.value"
                        placeholder="Nombre(s)"
                        clear-button
                    >
                    </f7-list-input>
                    <f7-list-input
                        label="Primer apellido"
                        type="text"
                        :value="userInfo.apellido1"
                        @input="userInfo.apellido1 = $event.target.value"
                        placeholder="Primer apellido"
                        @keyup.native.enter="siguiente()"
                        clear-button
                    >
                    </f7-list-input>
                    <f7-list-input
                        label="Segundo apellido"
                        type="text"
                        :value="userInfo.apellido2"
                        @input="userInfo.apellido2 = $event.target.value"
                        @keyup.native.enter="siguiente()"
                        placeholder="Segundo apellido"
                        clear-button
                    >
                    </f7-list-input>
                </template>
            </template>
        </f7-list>

        <f7-block>
            <f7-row>
                <f7-col><f7-button @click="abortCreacion()" large color="gray">Cancelar</f7-button></f7-col>
                <f7-col><f7-button @click="siguiente()" fill raised large>Siguiente</f7-button></f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import { comunasHelper } from "@/js/comunas.js";
import * as EmailValidator from "email-validator";

export default {
    mixins: [commonmixin, ],
    props: ["vacunatorioid"],
    data() {
        return {
            popupCrearCuentaOpened: false,
            resultados: {},
            creando: false, //no borrar, sirve como freno para creacion multiple
            userInfo: {
                type: "central",
                apellido1: "",
                apellido2: "",
                nombres: "",
                rut: "",
                rutok: false,
                email: "",
                emailok: false,
                rutvalido: true,
                emailvalido: true,
                regiones: [],
            },
        };
    },
    computed: {
        ...mapState(["user", "dev"]),
        ...mapGetters(["centralAdminAccount"]),
        regiones() {
            return comunasHelper.data().comunas.map(c => {
                return {
                    id: c.region_iso_3166_2,
                    name: c.region,
                    number: c.region_number,
                };
            }).filter(r => r.id);
        },
    },
    methods: {
        handleRegionSelected(e) {
            const regionId = e.target.value;
            const index = this.userInfo.regiones.indexOf(regionId);

            if (index === -1) {
                this.userInfo.regiones.push(regionId);
            } else {
                this.userInfo.regiones.splice(index, 1);
            }
        },
        escribeRut(e) {
            this.userInfo.rutvalido = true;
            this.userInfo.rut = this.formatRut(e);
        },
        escribeEmail(e) {
            this.userInfo.emailvalido = true;
            this.userInfo.email = e;
        },
        evaluaRut() {
            this.userInfo.rutvalido = this.validaRut(this.userInfo.rut);
        },
        evaluaEmail() {
            this.userInfo.emailvalido = EmailValidator.validate(this.userInfo.email);
        },
        abortCreacion() {
            this.$emit("abortCreacion");
        },

        async siguiente() {
            try {
                console.log("userInfo", this.userInfo);
                if (!this.userInfo.rutok) {
                    if (!this.userInfo.rut || this.userInfo.rut.length < 6 || !this.validaRut(this.userInfo.rut)) {
                        return this.$f7.dialog.alert("RUT no parece válido", "RUT inválido");
                    }
                    this.$f7.dialog.preloader("Verificando RUT...");
                    let abuscar = this.getCleanRut(this.userInfo.rut);
                    console.log("buscando", abuscar);
                    let potenciales;
                    if (this.vacunatorioid) {
                        let datous = await this.commonExecute(
                            { fuente: "id", valor: abuscar, vacunatorioid: this.vacunatorioid },
                            "admin-prevetnDuplicatesByVac",
                            false
                        );
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        potenciales = datous.payload || null;
                    } else {
                        let potsnap = await this.$firebase
                            .database()
                            .ref("privx_admn")
                            .orderByChild("id")
                            .equalTo(abuscar)
                            .once("value");
                        potenciales = potsnap.val() || null;
                    }
                    this.$f7.dialog.close();
                    if (potenciales) {
                        let ellaor = Object.values(potenciales)[0];
                        console.log("evaor", ellaor);
                        this.gotoCuenta(ellaor);
                    } else {
                        this.userInfo.rutok = true;
                        setTimeout(() => {
                            document.getElementById("emailinput").focus();
                        }, 300);
                        return false;
                    }
                }
                //ahora verificar email
                else if (!this.userInfo.emailok) {
                    if (!this.userInfo.email || !EmailValidator.validate(this.userInfo.email)) {
                        return this.$f7.dialog.alert("e-mail no parece válido", "e-mail inválido");
                    }
                    this.$f7.dialog.preloader("Verificando email...");
                    let abuscar = this.userInfo.email.toLowerCase();
                    console.log("buscando", abuscar);
                    let potencialesbymail;
                    if (this.vacunatorioid) {
                        let datous = await this.commonExecute(
                            { fuente: "em", valor: abuscar, vacunatorioid: this.vacunatorioid },
                            "admin-prevetnDuplicatesByVac",
                            false
                        );
                        if (datous.error) {
                            throw new Error(datous.error.message);
                        }
                        potencialesbymail = datous.payload || null;
                    } else {
                        let potsnap = await this.$firebase
                            .database()
                            .ref("privx_admn")
                            .orderByChild("em")
                            .equalTo(abuscar)
                            .once("value");
                        potencialesbymail = potsnap.val() || null;
                    }
                    this.$f7.dialog.close();
                    if (potencialesbymail) {
                        let existencia = Object.values(potencialesbymail)[0];
                        console.log(existencia);
                        return this.$f7.dialog
                            .create({
                                title: "Email ya existe",
                                text: `El email ${this.userInfo.email} ya existe como cuenta en admin-web, pero asociado a otro rut: ${this.formatRut(
                                    existencia.id
                                )}.`,
                                buttons: [
                                    {
                                        text: "Cancelar",
                                        color: "red",
                                    },
                                    {
                                        text: "Ver cuenta",
                                        onClick: () => {
                                            this.abortCreacion();
                                            this.gotoCuenta(existencia);
                                        },
                                    },
                                ],
                            })
                            .open();
                    } else {
                        this.userInfo.emailok = true;
                        setTimeout(() => {
                            document.getElementById("name1input").focus();
                        }, 300);
                        return false;
                    }
                } else {
                    if (this.creando) {
                        return false;
                    }
                    //estan ambos OK, tratar de crear
                    //llamar FX del back
                    if (!this.userInfo.apellido1 || !this.userInfo.nombres) {
                        return this.$f7.dialog.alert("Debe indicar al menos el primer apellido y un nombre");
                    }
                    if (!this.userInfo.type) {
                        return this.$f7.dialog.alert("Debe indicar el tipo de cuenta (central o regional)");
                    }
                    if (this.userInfo.type === "regional" && !this.userInfo.regiones.length) {
                        return this.$f7.dialog.alert("Debe indicar al menos una región para la cuenta regional");
                    }
                    this.creando = true;
                    this.$f7.dialog.preloader("Creando cuenta admin...");
                    console.log("enviando", this.userInfo);
                    let tosend = {
                        ap1: this.userInfo.apellido1,
                        ap2: this.userInfo.apellido2,
                        id: this.userInfo.rut,
                        em: this.userInfo.email,
                        nms: this.userInfo.nombres,
                        regional: this.userInfo.type === "regional",
                        regiones: this.userInfo.regiones.length > 0 ? this.userInfo.regiones : null,
                    };
                    if (this.vacunatorioid) {
                        tosend.vacunatorioid = this.vacunatorioid;
                    }
                    let datous = await this.commonExecute(tosend, "admin-crearCuenta", true);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    console.log("creacion ok", datous);
                    this.gotoCuenta(datous.payload);
                    this.$f7.dialog.close();
                    this.creando = false;
                }
            } catch (error) {
                this.creando = false;
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        gotoCuenta(cuenta) {
            this.$emit("gotoCuenta", cuenta);
        },
    },
};
</script>
<style>
.cuentasresults img {
    width: 80px;
}
</style>
