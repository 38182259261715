<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Buscador de" subtitle="registros de vacunación"></f7-nav-title>
        </f7-navbar>

        <h3 class="margin-horizontal">Tipo de búsqueda</h3>
        
        <f7-list class="margin">
            <f7-list-item
                radio
                value="patientId"
                name="search-type-radio"
                :checked="selectedSearchType === 'patientId'"
                title="ID de paciente"
                @change="selectedSearchType = $event.target.value"
            ></f7-list-item>
            <f7-list-item
                radio
                value="inmunoId"
                name="search-type-radio"
                :checked="selectedSearchType === 'inmunoId'"
                title="ID de inmunización"
                @change="selectedSearchType = $event.target.value"
            ></f7-list-item>
        </f7-list>
         
        <div class="checkbox-container">
            <f7-checkbox :checked="includeDeleted" class="checkbox-left" @change="includeDeleted = $event.target.checked"></f7-checkbox>
            <label class="label-left">Incluir registros eliminados en la búsqueda</label>
        </div>


        <f7-list class="margin" v-if="selectedSearchType === 'patientId'">
            <f7-list-input type="select" label="Tipo de documento" @change="tipoDoc = $event.target.value">
                <option value="RUN" :selected="tipoDoc === 'RUN'">RUN</option>
                <option value="PAS" :selected="tipoDoc === 'PAS'">PAS</option>
                <option value="DNI" :selected="tipoDoc === 'DNI'">DNI</option>
            </f7-list-input>
            <f7-list-input
                type="text"
                label="Número de documento"
                :value="nroDoc"
                @change="nroDoc = $event.target.value"
                @keyup.enter.native="preSearchPatients"
            ></f7-list-input>
        </f7-list>

        <f7-list class="margin" v-if="selectedSearchType === 'inmunoId'">
            <f7-list-input
                type="text"
                label="ID de inmunización"
                :value="inmunoId"
                @change="inmunoId = $event.target.value"
                @keyup.enter.native="preSearchPatients"
            ></f7-list-input>
        </f7-list>

        <f7-button @click="preSearchPatients" fill class="margin">Buscar</f7-button>

        <div v-if="registros.length > 0" class="padding-top-half">
            <h3 class="margin-horizontal">Registros encontrados ({{ registros.length }})</h3>
            <f7-list media-list class="margin">
                <f7-list-item v-for="registro in orderedRegistros" :key="registro.id" link="#" @click="goToEditRegistro(registro)">
                    <div slot="header" class="margin-bottom-half">
                        {{ registro.campana.nombre }} &bull; Fecha de vacunación: {{ $dayjs(registro.rv.d, "YYYYMMDD").format("DD/MM/YYYY") }}
                    </div>
                    <div slot="title" class="margin-bottom-half">
                        <b>{{ registro.vacuna.vacunaPcName }}</b> ({{ registro.vacuna.laboratorioName }})
                    </div>
                    <div slot="subtitle" class="margin-bottom-half">Vacunatorio: {{ registro.vacunatorio.name }}</div>
                    <div slot="footer">ID Inmunización: {{ registro.rv.id }}</div>
                    <div slot="after" class="margin-bottom-half">
                        <f7-badge color="red" v-if="registro.rv.el">Eliminado</f7-badge>
                    </div>
                </f7-list-item>
            </f7-list>
        </div>

        <select-patient-popup
            :opened="selectPatientPopupOpened"
            :patients="patientsToSelect"
            @popup-closed="selectPatientPopupOpened = false"
            @patient-selected="handlePatientSelected"
        ></select-patient-popup>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
import { commonmixin } from "../../mixins/common";
import SelectPatientPopup from "../../components/select-patient-popup.vue";

export default {
    mixins: [commonmixin],

    components: {
        "select-patient-popup": SelectPatientPopup,
    },

    data() {
        return {
            selectedSearchType: "patientId",

            tipoDoc: "RUN",
            nroDoc: "",

            inmunoId: "",

            registros: [],

            patientsToSelect: [],
            selectPatientPopupOpened: false,

            includeDeleted: false,
        };
    },

    computed: {
        ...mapState(["dev"]),

        orderedRegistros() {
            return this.registros.sort((a, b) => {
                if (a.rv.d < b.rv.d) return -1;
            });
        },
    },

    methods: {
        async preSearchPatients() {
            if (this.selectedSearchType === "patientId") {
                const trimBusqueda = this.nroDoc.trim();

                if (trimBusqueda.length < 2) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Debe ingresar al menos 2 caracteres");
                    return;
                }

                if (this.tipoDoc === "RUN") {
                    await this.searchRegistros();
                } else {
                    this.$f7.dialog.preloader("Buscando...");

                    const response = await this.commonExecute(
                        { tipoDoc: this.tipoDoc, nroDoc: trimBusqueda, dev: this.dev },
                        "admin-preSearchPatient",
                        false
                    );

                    if (response.error) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(response.error.message);
                        return;
                    }

                    const { patients } = response;

                    if (patients.length === 0) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("No se encontraron pacientes");
                        return;
                    }

                    if (patients.length === 1 && patients[0].identity === trimBusqueda) {
                        this.$f7.dialog.close();

                        const patient = patients[0];

                        this.tipoDoc = patient.idenType;
                        this.nroDoc = patient.identity;

                        await this.searchRegistros();
                    } else {
                        this.patientsToSelect = patients;
                        this.selectPatientPopupOpened = true;
                    }

                    this.$f7.dialog.close();
                }
            } else {
                await this.searchRegistros();
            }
        },

        async handlePatientSelected(patient) {
            this.tipoDoc = patient.idenType;
            this.nroDoc = patient.identity;

            await this.searchRegistros();

            this.patientsToSelect = [];
            this.selectPatientPopupOpened = false;
        },

        async searchRegistros() {
            this.$f7.dialog.preloader("Buscando registros...");

            try {
                const searchRegistroVacunacion = this.$firebase.functions().httpsCallable("admin-searchRegistroVacunacion");

                const searchObj = {};

                if (this.selectedSearchType === "patientId") {
                    if (!this.tipoDoc || !this.nroDoc) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar tipo de documento y número de documento");
                        return;
                    }

                    const nroDoc = this.cleanAndFormatValue(this.nroDoc);

                    searchObj.searchType = "patientId";
                    searchObj.patientId = `${this.tipoDoc}_${nroDoc}`;
                } else if (this.selectedSearchType === "inmunoId") {
                    if (!this.inmunoId) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar el ID de inmunización");
                        return;
                    }

                    searchObj.searchType = "inmunoId";
                    searchObj.inmunoId = this.inmunoId;
                }

                if(this.includeDeleted) searchObj.includeDeleted = true;

                const { data } = await searchRegistroVacunacion({
                    searchObj,
                    dev: this.dev ? true : false,
                });

                if (data.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(data.error.message || data.error.code);
                    return;
                }

                this.registros = data;

                if (this.registros.length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron registros");
                    return;
                }

                if (this.registros.length === 1) {
                    this.$f7.dialog.close();
                    this.goToEditRegistro(this.registros[0]);
                    return;
                }

                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message || error.code);
            }
        },

        goToEditRegistro(registro) {
            this.$store.commit("editorRegistrosVacunas/set_current_registro", registro);
            this.$f7.views.main.router.navigate("/editor_registros_vacunas/edit");
            this.registros = [];
        },

        cleanAndFormatValue(rawIdentity) {
            let a = String(rawIdentity)
                .trim()
                .toUpperCase(); //asegurar que sea string o forzar string, y hacer mayuscula
            let b = a.split(".").join(""); //sacar puntos
            let c = b.split("-").join(""); //sacar dashes
            let d = c.split(" ").join(""); //sacar espacios
            let e = d.split("/").join(""); //sacar slahes
            return e;
        },
    },
};
</script>
<style scoped>
.checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.label-left {
  margin-left: 10px;
  font-size: small;
}

.checkbox-left {
  margin-left: 20px;
}
</style>