<template>
    <f7-list-item
        :title="apename"
        :subtitle="solicitud.email"
        :text="docuinfo"
        link="#"
        @click="$emit('evaluar', solicitud)"
        :header="$dayjs.unix(solicitud.wh).format('HH:mm [-] dddd D [de] MMMM')"
    >
        <f7-link slot="after">{{ tomadapor || "Evaluar" }}</f7-link>
        <f7-link class="margin-left-half" v-if="solicitud.homopend" slot="title" tooltip="Asociado a solicitud de homologación"><font-awesome-icon icon="syringe"></font-awesome-icon></f7-link>
    </f7-list-item>
</template>
<script>
import { commonmixin } from "../mixins/common";

export default {
    props: ["solicitud", "tomadapor"],
    mixins: [commonmixin],

    data() {
        return {};
    },
    computed: {
        apename() {
            return `${this.solicitud.send.apellido1} ${this.solicitud.send.apellido2 || ""}, ${this.solicitud.send.nombres}`;
        },
        docuinfo() {
            return `${this.solicitud.send.idenType} ${
                this.solicitud.send.idenType === "RUN" ? this.formatRut(this.solicitud.send.rawIdentity) : this.solicitud.send.rawIdentity
            }`;
        },
    },
    mounted() {
        console.log("solicitud", this.solicitud);
    },
    methods: {},
};
</script>
<style></style>
