<template>
<f7-view>
  <f7-page name="vacunatorio_crea">
      <f7-navbar title="Nuevo Vacunatorio">
          <f7-nav-right>
            <f7-link popup-close>Cancelar</f7-link>
          </f7-nav-right>
        </f7-navbar>
<f7-block class="margin-bottom">Complete los siguientes campos</f7-block>

<f7-list class="no-margin-vertical">
  <ul>
  <select-comuna :address="tocreate.address" @newAddress="llegandoAddress"></select-comuna>
   <f7-list-input
    label="Dirección"
    type="text"
    placeholder="Nombre de la calle y número"
     :value="tocreate.direccion"
    @blur="checkEsteOblig('direccion')"
     error-message="Este campo es obligatorio"
    :error-message-force="faltaEsteInput.direccion"
   @input="setvalorYBorrarError('direccion',$event.target.value)"
    clear-button
  >
  </f7-list-input>
     <f7-list-input
    label="Nombre"
    type="text"
    placeholder="CESFAM Dr. Lopetegui"
     :value="tocreate.name"
    @blur="checkEsteOblig('name')"
     error-message="Este campo es obligatorio"
    :error-message-force="faltaEsteInput.name"
   @input="setvalorYBorrarError('name',$event.target.value)"
    clear-button
  >
  </f7-list-input>
  <f7-list-input
    label="Código DEIS"
    type="text"
    placeholder="código deis del vacunatorio"
     :value="tocreate.deis"
   @input="tocreate.deis = $event.target.value"
    clear-button
  >
  </f7-list-input>
  </ul>
</f7-list>



<f7-block><f7-button @click="crearVacunatorio()" fill large raised color="teal">Crear Vacunatorio</f7-button></f7-block>

  </f7-page>
</f7-view>
</template>
<script>
import selectComuna from '../components/selectComuna.vue'
import { commonmixin } from '../mixins/common'

export default {
  components:{ selectComuna },
          mixins: [commonmixin],
    data() {
      return {
       tocreate:{
         address:{
            code: null,
            comuna: null,
            provincia: null,
            region: null,
            region_hr: null
         },
         direccion:'',
         name: '',
         deis: null
       },
       faltaEsteInput:{
         direccion:null,
         name: null
       }
      }
    },
    methods:{
      llegandoAddress(newaddress){
        //console.log('escuchando nuevo address',newaddress);
        this.tocreate.address = Object.assign({},newaddress)
      },
      checkEsteOblig(cual){
        if(!this.tocreate[cual]){
          this.faltaEsteInput[cual] = true;
        }
        else{
          this.faltaEsteInput[cual] = false;
        }
      },
      setvalorYBorrarError(cual,valor){
        this.tocreate[cual] = valor;
        this.faltaEsteInput[cual] = false;
      },

      async crearVacunatorio(){
        try{
           if(!this.tocreate.direccion || !this.tocreate.name || !this.tocreate.address.code){
            return this.$f7.dialog.alert('Debe indicar un nombre, dirección y comuna.')
          }
            this.$f7.dialog.preloader('Creando vacunatorio...');
           // console.log('enviando',this.tocreate);
            let datous = await this.commonExecute({direccion: this.tocreate.direccion, name: this.tocreate.name, deis: this.tocreate.deis, code: this.tocreate.address.code},'admin-creaVacunatorio',true);
            if(datous.error){
              console.log('error crudo',datous.error)
              throw new Error(datous.error.message);
            }
           // console.log('creacion ok',datous);
            this.$f7.dialog.close();
            this.$emit('creado',datous.payload);
        } catch(error){
           this.$f7.dialog.close();
           console.error('error procesado',error);
           this.$f7.dialog.alert(error.message, error.code);
        }

      }
    }
}
</script>
<style>


</style>