<template>
  <f7-page name="copmrobanteficha">
      <f7-navbar title="Ficha paciente" subtitle="Info" back-link="Back"></f7-navbar>
      <f7-block v-if="!Object.values(acumuladorPorCampanas).length"> Paciente no posee registros de vacunación en el sistema</f7-block>
      <one-record @descargapdf="generame" @abrepass="verPass" v-for="(unvac,campid) in acumuladorPorCampanas" :key="campid" :campid="campid" :camp="unvac"></one-record>
   
 <f7-popup class="qr-popup" :opened="qrvisoropened" @popup:closed="closeviewer()">
     <passviewer v-if="passforvisor" :personx="existe" :camp="passforvisor"></passviewer>
    </f7-popup>
  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { commonmixin } from '../mixins/common'
import oneRecord from '../components/oneRecord.vue'
  import passviewer from '../components/passviewer.vue';
import axios from 'axios';

export default {
  components: {
       oneRecord, passviewer
    },
    mixins: [commonmixin],
    props: ['existe','acumuladorPorCampanas'],
    data() {
      return {
        qrvisoropened: false,
        passforvisor: null
      }
    },
    computed:{
     ...mapState(['user']),
     ...mapGetters(['centralAdminAccount']),
    },
    mounted(){
      console.log('existe',this.existe)
      console.log('acumuladorPorCampanas',this.acumuladorPorCampanas)
    },
    methods:{
      async generame(campanaid){
        console.log('descargando certificado',campanaid)
      try {
         this.$f7.dialog.preloader('Generando documento...');
        let idToken = await this.$firebase.auth().currentUser.getIdToken();
        let envx = this.$store.state.dev?'dev':'prod';
        let paxid = `${this.existe.idenType}_${this.existe.identity}`;
        let prefix=paxid.split('_')[0];
        let fxToCall = 'certfx_new';//(prefix==='RUN')?'certfx_new':'certfx_new_ext';
        let response = await axios.get(`${this.$store.getters.getApiUrl}/prosex-exporter/corxpt/${fxToCall}/${campanaid}/${paxid}/${envx}`, {
                    headers: {'Authorization': 'Bearer ' + idToken},
                     responseType: 'blob', // important
                    });
        this.$f7.dialog.close();
        if(response.data.type=='application/json'){
          var textPromise = await response.data.text();
          console.log('error',textPromise);
          return this.$f7.dialog.alert(textPromise,'Error');
        }
          const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
          let fileLink = document.createElement('a');
          document.body.appendChild(fileLink);
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'mevacuno.pdf');
          fileLink.classList.add("external");
          fileLink.click();
          window.URL.revokeObjectURL(fileURL);
          this.avisaOk('Descarga OK');

      } catch (error) {
          this.$f7.dialog.close();
        console.log(error, error.message,error.response, error?.response?.data);
         this.$f7.dialog.alert( error.response?.data?.message || error.message);
      }
   },
  verPass(rv){
        console.log('Pidiento abrir pase',rv)
         this.passforvisor = rv;
          this.qrvisoropened = true;
      },
        closeviewer(){
        this.qrvisoropened= false;
        this.passforvisor=null;
      },
    }
}
</script>
<style>


</style>