<template>
    <f7-card outline :class="cardComputedClasses">
        <f7-card-header class="display-flex justify-content-space-between align-items-center">
            <div class="display-flex align-items-center">
                <font-awesome-icon icon="syringe" size="lg" class="margin-right"></font-awesome-icon>
                <div>
                    <small>Vacuna declarada</small>
                    <div>
                        <b>{{ vaccineCardTitle }}</b>
                    </div>
                </div>
            </div>
            <div class="display-flex align-items-center">
                <div class="display-flex align-items-center">
                    <template v-if="!showLess && patientIsVerified && solicitudIsTakenByMe && solicitud.est !== 'Cerrada'">
                        <span v-if="isEditing" class="text-color-gray">Editando</span>
                        <f7-button
                            :tooltip="editButtonText"
                            @click="toggleEditVaccineDeclaration"
                            class="no-padding-right"
                        >
                            <font-awesome-icon size="lg" icon="edit" color="gray"></font-awesome-icon>
                        </f7-button>
                    </template>
                </div>

                <div class="display-flex align-items-center">
                    <font-awesome-icon v-if="vaccineStatus === 'Aceptada'" size="lg" icon="check" color="green"></font-awesome-icon>
                    <font-awesome-icon v-else-if="vaccineStatus === 'Rechazada'" size="lg" icon="times" color="red"></font-awesome-icon>
                    <f7-button :tooltip="showLess ? 'Mostrar más' : 'Mostrar menos'" class="margin-left-half" @click="showLess = !showLess">
                        <font-awesome-icon size="lg" :icon="showLess ? 'caret-down' : 'caret-up'" color="gray"></font-awesome-icon>
                    </f7-button>
                </div>
            </div>
        </f7-card-header>
        <f7-card-content class="no-padding-top" v-if="!showLess">
            <f7-row v-if="!isEditing">
                <f7-col>
                    <f7-list>
                        <f7-list-item header="Vacuna recibida" :title="vaccine.vac.name"></f7-list-item>
                        <!-- <f7-list-item header="Comuna Establecimiento" :title="vaccine.address ? vaccine.address.comuna : 'No indica'"></f7-list-item> -->
                        <f7-list-item header="Número de lote" :title="vaccine.lote || 'No indica'"></f7-list-item>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list>
                        <f7-list-item header="Establecimiento" :title="vaccine.establecimiento || 'No indica'"></f7-list-item>
                        <f7-list-item header="Fecha de vacunación" :title="vaccine.fechavac"></f7-list-item>
                    </f7-list>
                </f7-col>
            </f7-row>
            <f7-row v-else>
                <f7-col>
                    <f7-list media-list>
                        <f7-list-item
                            class="margin-vertical"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: 'Buscar vacuna',
                                popupCloseLinkText: 'Cerrar',
                                closeOnSelect: true,
                                setValueText: false,
                            }"
                            required
                        >
                            <div slot="header" class="header-style">Vacuna recibida <span class="text-color-red">*</span></div>
                            <div slot="inner">{{ vaccine.vac.name || "" }}</div>
                            <select @change="emitVacunaChanged" name="vacuna">
                                <option
                                    v-for="(vacuna, index) in sortedVacunas"
                                    :key="index"
                                    :value="vacuna.vacunaPcId"
                                    :selected="vacuna.vacunaPcId === vaccine.vac.id"
                                >
                                    {{ `${vacuna.vacunaPcName}` }}
                                </option>
                            </select>
                        </f7-list-item>
                        <f7-list-input
                            type="text"
                            label="Número de lote"
                            :value="vaccine.lote"
                            @blur="e => emitVaccineInfoChanged(e, 'lote')"
                        ></f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col>
                    <f7-list media-list>
                        <f7-list-input
                            type="text"
                            label="Establecimiento"
                            :value="vaccine.establecimiento"
                            @blur="e => emitVaccineInfoChanged(e, 'establecimiento')"
                        ></f7-list-input>
                        <f7-list-input
                            type="text"
                            placeholder="DD/MM/YYYY"
                            maxlength="10"
                            :value="vaccine.fechavac | formatDate"
                            @blur="e => emitVaccineInfoChanged(e, 'fechavac')"
                        >
                            <div slot="label">Fecha de vacunación <span class="text-color-red">*</span></div>
                        </f7-list-input>
                    </f7-list>
                </f7-col>
            </f7-row>

            <template v-if="!isEditing">
                <slot></slot>
            </template>
        </f7-card-content>
    </f7-card>
</template>

<script>
import { mapState } from "vuex";
import Alert from "../../../components/Alert.vue";

export default {
    components: {
        "f7c-alert": Alert,
    },

    props: {
        solicitud: Object,
        vaccine: Object,
        patientIsVerified: Boolean,
        solicitudIsTakenByMe: Boolean,
    },

    data() {
        return {
            isEditing: false,

            showLess: false,
        };
    },

    computed: {
        ...mapState("homologacionVacunasNac", ["vacunasHomologacion"]),

        cardComputedClasses() {
            if (this.vaccineStatus === "Aceptada") {
                return "border-color-green";
            } else if (this.vaccineStatus === "Rechazada") {
                return "border-color-red";
            } else {
                return "border-color-gray";
            }
        },

        vaccineCardTitle() {
            return `${this.vaccine.vac.name || "Vacuna no identificada"} - ${this.vaccine.fechavac || "Sin fecha de vacunación"}`;
        },
        vaccineStatus() {
            return (this.vaccine.est && this.vaccine.est.resol) || "Pendiente";
        },

        editButtonText() {
            if (this.isEditing) {
                return "Dejar de editar";
            } else {
                return "Editar declaración de vacuna";
            }
        },

        sortedVacunas() {
            return Object.values(this.vacunasHomologacion || {}).sort((a, b) => a.vacunaPcName.localeCompare(b.vacunaPcName));
        },
    },

    async created() {
        this.fetchCountries();
        await this.fetchVacunas();
    },

    methods: {
        toggleEditVaccineDeclaration() {
            this.isEditing = !this.isEditing;
        },

        emitVacunaChanged(e) {
            const vacuna = this.sortedVacunas.find(vacuna => vacuna.vacunaPcId === e.target.value);

            if (!vacuna) {
                this.$f7.dialog.alert("No se encontró la vacuna seleccionada");
            }

            this.$emit("vaccine-changed", {
                vacuna,
                vaccine: this.vaccine,
            });
        },
        emitVaccineInfoChanged(e, whatChanged) {
            this.$emit("vaccine-info-changed", {
                vaccine: this.vaccine,
                whatChanged,
                value: e.target.value,
            });
        },

        fetchCountries() {
            if (!this.$store.getters.paisesArraySorted.length) {
                this.$firebase
                    .database()
                    .ref("paises")
                    .once("value")
                    .then(snapshot => {
                        this.$store.commit("setWhatTo", { what: "paises", to: snapshot.val() });
                        this.$f7.dialog.close();
                    })
                    .catch(error => {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            }
        },

        async fetchVacunas() {
            if (!Object.values(this.vacunasHomologacion || {}).length) {
                const vacunasSnap = await this.$firebase.database().ref("vacunas").orderByChild("show_hmlg_nac").equalTo(true).once("value");
                const vacunas = vacunasSnap.val() || {};

                this.$store.commit("homologacionVacunasNac/set_vacunas_homologacion", vacunas);
            }
        },
    },
};
</script>
