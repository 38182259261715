<template>
    <f7-page name="formulario">
        <f7-navbar :subtitle="`Estado: ${!formularioEnFoco.published ? 'Borrador' : 'Publicado'}`" :title="formularioEnFoco.name" back-link="Back">
            <f7-nav-right>
                <f7-block>
                    <f7-preloader v-if="updt.publsh"></f7-preloader>
                    <f7-button v-else-if="!formularioEnFoco.published" @click="precambiopuglicacion()" fill raised color="teal">Publicar</f7-button>
                    <f7-button v-else @click="precambiopuglicacion()" outline color="red">Despublicar</f7-button>
                </f7-block>
            </f7-nav-right>
        </f7-navbar>

        <f7-list inset class="marfixicons">
            <f7-list-item @click="updateName()" link="#" header="Nombre" :title="formularioEnFoco.name">
                <f7-preloader slot="after" v-if="updt.name"></f7-preloader>
                <font-awesome-icon v-else slot="after" icon="pen"></font-awesome-icon>
                <font-awesome-icon slot="media" icon="file-medical" class="fa-lg text-color-primary" />
            </f7-list-item>

            <f7-list-item
                class="nowhitespacetitle"
                @click="popInstrucOpened = true"
                link="#"
                header="Instrucciones"
                footer="Texto a mostrar previo a las preguntas (opcional)"
            >
                <font-awesome-icon slot="after" icon="pen"></font-awesome-icon>
                <font-awesome-icon slot="media" icon="notes-medical" class="fa-lg text-color-primary" />

                <div slot="title" v-html="formularioEnFoco.instrucciones ? $sanitize(formularioEnFoco.instrucciones) : '<b>--</b>'"></div>
            </f7-list-item>
        </f7-list>
        <f7-block class="margin-top">
            <f7-block-title><b>Agregar nueva pregunta</b>: seleccione tipo de pregunta a agregar</f7-block-title>
            <f7-link class="margin-right-half" @click="crearPregunta(tipopreg.id)" v-for="tipopreg in forms_logic.tipos_preg" :key="tipopreg.id">
                <f7-chip :text="tipopreg.nm" media-bg-color="deeppurple">
                    <font-awesome-icon slot="media" :icon="tipopreg.iko"></font-awesome-icon>
                </f7-chip>
            </f7-link>
        </f7-block>
        <f7-block v-if="!preguntasOrdenadas.length" class="no-margin-bottom">Este formulario no tiene preguntas creadas<br /></f7-block>

        <f7-block class="no-margin-vertical">
            <f7-preloader v-if="updt.sorting"></f7-preloader>
            <f7-link v-else sortable-toggle=".headpreg"
                ><font-awesome-icon icon="sort-numeric-up" class="fa-lg margin-right-half"></font-awesome-icon> Ordenar Preguntas</f7-link
            >
        </f7-block>
        <f7-list class="no-margin-top headpreg fixfamarg" inset sortable sortable-opposite @sortable:sort="onSort" accordion-list>
            <f7-list-item
                v-for="(unapregunta, idx) in preguntasOrdenadas"
                :key="unapregunta.id"
                accordion-item
                :header="`${forms_logic.tipos_preg[unapregunta.tipo].nm} - ${unapregunta.req ? 'Requerida' : 'Opcional'}`"
            >
                <div slot="title" class="bloqueamultilinea" v-html="$sanitize(unapregunta.encabezado)"></div>
                <f7-link slot="after" class="text-color-red" v-if="warnings_by_preg[unapregunta.id]" :tooltip="warnings_by_preg[unapregunta.id]"
                    ><font-awesome-icon icon="exclamation-triangle" class="fa-lg"></font-awesome-icon
                ></f7-link>
                <f7-accordion-content>
                    <f7-list media-list class="margin" inset>
                        <f7-list-item header="Encabezado" link="#" @click="abreEditorEnunciado(unapregunta.id)">
                            <span slot="title" v-html="$sanitize(unapregunta.encabezado)"></span>
                            <font-awesome-icon icon="pen" slot="after" class="fa-lg"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            v-if="
                                unapregunta.depde &&
                                    (!formularioEnFoco.preguntas[unapregunta.depde.pregid] ||
                                        formularioEnFoco.preguntas[unapregunta.depde.pregid].disabled)
                            "
                            class="text-color-red"
                            header="Regla de visibilidad: en base a"
                            title="PREGUNTA FUE ELIMINADA"
                            subtitle="ELIMINE ESTA REGLA"
                        >
                            <f7-preloader class="margin-right-half" v-if="guardandoeste == unapregunta.id + 'set_regla'" slot="after"></f7-preloader>
                            <f7-link
                                @click="modificaPregunta({ preguntaid: unapregunta.id, action: 'set_regla', valor: null })"
                                color="gray"
                                slot="after"
                                ><font-awesome-icon icon="trash"></font-awesome-icon
                            ></f7-link>
                        </f7-list-item>
                        <f7-list-item
                            v-else-if="unapregunta.depde && formularioEnFoco.preguntas[unapregunta.depde.pregid]"
                            header="Regla de visibilidad: en base a"
                            :title="striphtml(formularioEnFoco.preguntas[unapregunta.depde.pregid].encabezado)"
                            :subtitle="forms_logic.retrix_types[unapregunta.depde.tipo].nm"
                            :text="getAmostrar(unapregunta.depde[unapregunta.depde.tipo], unapregunta.depde.pregid)"
                        >
                            <f7-preloader class="margin-right-half" v-if="guardandoeste == unapregunta.id + 'set_regla'" slot="after"></f7-preloader>
                            <f7-link
                                @click="modificaPregunta({ preguntaid: unapregunta.id, action: 'set_regla', valor: null })"
                                color="gray"
                                slot="after"
                                ><font-awesome-icon icon="trash"></font-awesome-icon
                            ></f7-link>
                        </f7-list-item>
                        <f7-list-item v-else header="Regla de visibilidad" title="Sin reglas de visibilidad">
                            <f7-button v-if="idx > 0" @click="editaReglaPoppOpened = idx" color="teal" outline slot="after">Crear</f7-button>
                            <span v-else slot="after">Reglas desde pregunta 2 en adelante</span>
                        </f7-list-item>
                        <f7-list-item title="Es obligatorio responder esta pregunta" footer="En caso que se cumpla la regla de visibilidad">
                            <f7-preloader
                                class="margin-right-half"
                                v-if="guardandoeste == unapregunta.id + 'edita_obligatoriedad'"
                                slot="after"
                            ></f7-preloader>
                            <f7-toggle
                                slot="after"
                                @toggle:change="modificaPregunta({ preguntaid: unapregunta.id, action: 'edita_obligatoriedad', valor: $event })"
                                :checked="unapregunta.req"
                            ></f7-toggle>
                        </f7-list-item>
                        <template v-if="unapregunta.tipo == 'elige_una' || unapregunta.tipo == 'elige_many'">
                            <f7-list-item class="altoalternativa" divider title="Alternativas"> </f7-list-item>
                            <f7-list-item
                                class="text-color-red"
                                v-if="!unapregunta.alternativas || !Object.keys(unapregunta.alternativas).length"
                                title="No hay alternativas creadas para esta pregunta"
                            >
                            </f7-list-item>

                            <f7-list-item
                                v-for="alternativa in filtrameAlternativas(unapregunta.alternativas)"
                                :key="alternativa.id"
                                :title="alternativa.tx"
                            >
                                <f7-preloader
                                    class="margin-right-half"
                                    v-if="guardandoeste == unapregunta.id + 'elimina_alternativa' + alternativa.id"
                                    slot="after"
                                ></f7-preloader>
                                <f7-link
                                    color="gray"
                                    @click="
                                        modificaPregunta({
                                            preguntaid: unapregunta.id,
                                            action: 'elimina_alternativa',
                                            precision: alternativa.id,
                                            valor: alternativa.id,
                                        })
                                    "
                                    slot="after"
                                    ><font-awesome-icon icon="trash" class="fa-lg"></font-awesome-icon
                                ></f7-link>
                            </f7-list-item>
                            <f7-list-item
                                ><f7-button @click="creaAlternativa(unapregunta.id)" outline color="teal">
                                    <font-awesome-icon icon="plus"></font-awesome-icon> Agregar alternativa</f7-button
                                >
                            </f7-list-item>
                        </template>
                        <template v-else-if="unapregunta.tipo == 'input_numero'">
                            <f7-list-item class="altoalternativa" divider title="Configuraciones"></f7-list-item>
                            <f7-list-item header="Indique límite inferior">
                                <f7-segmented slot="title" class="setwithseg" strong>
                                    <f7-button
                                        @click="modificaPregunta({ preguntaid: unapregunta.id, action: 'set_limite_inferior', valor: null })"
                                        :active="unapregunta.rangemin == null"
                                        >Sin min</f7-button
                                    >
                                    <f7-button @click="setLimiteNum(unapregunta.id, 'inferior')" :active="unapregunta.rangemin != null"
                                        >Con min</f7-button
                                    >
                                </f7-segmented>
                                <f7-preloader slot="after" v-if="guardandoeste == unapregunta.id + 'set_limite_inferior'"></f7-preloader>
                                <f7-chip :text="unapregunta.rangemin == null ? '--' : unapregunta.rangemin" slot="after"></f7-chip>
                            </f7-list-item>
                            <f7-list-item header="Indique límite superior">
                                <f7-segmented slot="title" class="setwithseg" strong>
                                    <f7-button
                                        @click="modificaPregunta({ preguntaid: unapregunta.id, action: 'set_limite_superior', valor: null })"
                                        :active="unapregunta.rangemax == null"
                                        >Sin max</f7-button
                                    >
                                    <f7-button @click="setLimiteNum(unapregunta.id, 'superior')" :active="unapregunta.rangemax != null"
                                        >Con max</f7-button
                                    >
                                </f7-segmented>
                                <f7-preloader slot="after" v-if="guardandoeste == unapregunta.id + 'set_limite_superior'"></f7-preloader>
                                <f7-chip :text="unapregunta.rangemax == null ? '--' : unapregunta.rangemax" slot="after"></f7-chip>
                            </f7-list-item>
                        </template>
                        <template v-else-if="unapregunta.tipo == 'input_fecha'">
                            <f7-list-item class="altoalternativa" divider title="Configuraciones"></f7-list-item>
                            <f7-list-item header="Valor mínimo">
                                <f7-segmented slot="title" class="setwithseg" strong>
                                    <f7-button
                                        @click="modificaPregunta({ preguntaid: unapregunta.id, action: 'set_limite_inferior', valor: null })"
                                        :active="unapregunta.rangemin == null"
                                        >Sin min</f7-button
                                    >
                                    <f7-button @click="abrecalx(unapregunta.id, 'inferior')" :active="unapregunta.rangemin != null"
                                        >Con min</f7-button
                                    >
                                </f7-segmented>
                                <f7-preloader slot="after" v-if="guardandoeste == unapregunta.id + 'set_limite_inferior'"></f7-preloader>
                                <f7-chip
                                    :text="unapregunta.rangemin == null ? '--' : $dayjs(unapregunta.rangemin).format('DD/MM/YYYY')"
                                    slot="after"
                                ></f7-chip>
                            </f7-list-item>
                            <f7-list-item header="Valor máximo">
                                <f7-segmented slot="title" class="setwithseg" strong>
                                    <f7-button
                                        @click="modificaPregunta({ preguntaid: unapregunta.id, action: 'set_limite_superior', valor: null })"
                                        :active="unapregunta.rangemax == null"
                                        >Sin max</f7-button
                                    >
                                    <f7-button @click="abrecalx(unapregunta.id, 'superior')" :active="unapregunta.rangemax != null"
                                        >Con max</f7-button
                                    >
                                </f7-segmented>
                                <f7-preloader slot="after" v-if="guardandoeste == unapregunta.id + 'set_limite_superior'"></f7-preloader>
                                <f7-chip
                                    :text="unapregunta.rangemax == null ? '--' : $dayjs(unapregunta.rangemax).format('DD/MM/YYYY')"
                                    slot="after"
                                ></f7-chip>
                            </f7-list-item>
                        </template>
                        <template v-else-if="unapregunta.tipo == 'input_texto'">
                            <f7-list-item class="altoalternativa" divider title="Configuraciones"></f7-list-item>
                            <f7-list-item title="Permitir múltiples líneas de texto">
                                <f7-preloader
                                    class="margin-right-half"
                                    v-if="guardandoeste == unapregunta.id + 'edita_text_area_size'"
                                    slot="after"
                                ></f7-preloader>
                                <f7-toggle
                                    slot="after"
                                    @toggle:change="modificaPregunta({ preguntaid: unapregunta.id, action: 'edita_text_area_size', valor: $event })"
                                    :checked="unapregunta.txtarea"
                                ></f7-toggle>
                            </f7-list-item>
                        </template>
                    </f7-list>
                    <f7-block class="margin-bottom">
                        <f7-button @click="eliminaPregunta(unapregunta.id)" color="red"
                            ><font-awesome-icon icon="folder-minus"></font-awesome-icon> Eliminar Pregunta</f7-button
                        >
                    </f7-block>
                </f7-accordion-content>
                <font-awesome-icon
                    :icon="forms_logic.tipos_preg[unapregunta.tipo].iko"
                    class="fa-lg text-color-deeppurple"
                    slot="media"
                ></font-awesome-icon>
            </f7-list-item>
        </f7-list>

        <f7-block-title class="bold">Definición de ESAVI Grave</f7-block-title>
        <f7-list media-list>
            <f7-block v-if="!esv_grv_array.length">No hay criterios de definición de ESAVI grave</f7-block>
            <f7-list-item
                v-for="unesavi in esv_grv_array"
                :key="unesavi.pregid"
                header="Será considerado ESAVI grave si responde a"
                :title="striphtml(formularioEnFoco.preguntas[unesavi.pregid].encabezado)"
                :subtitle="forms_logic.retrix_types[unesavi.tipo].nm"
                :text="getAmostrar(unesavi[unesavi.tipo], unesavi.pregid)"
            >
                <f7-link @click="borraEsaviGrave(unesavi.pregid)" color="gray" slot="after"
                    ><font-awesome-icon icon="trash"></font-awesome-icon
                ></f7-link>
            </f7-list-item>
        </f7-list>
        <f7-block>
            <f7-button v-if="todosEnunciadosClean.length" outline @click="definirEsaviGrave = true">Nuevo criterio de ESAVI Grave</f7-button>
            <f7-block v-else>Todas las preguntas ya fueron usadas para definir un ESAVI grave. No puede agregar nueva definición.</f7-block>
        </f7-block>

        <f7-block> <input type="text" class="esconder" placeholder="Select date" readonly="readonly" id="calpickhidden"/></f7-block>

        <f7-popup :opened="popInstrucOpened" @popup:open="abreModifInstrucc()" @popup:closed="popInstrucOpened = false">
            <f7-page>
                <f7-navbar title="Instrucciones del formulario">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block-title>Instrucciones</f7-block-title>
                <f7-block-header
                    >Indique aquí, de forma opcional, instrucciones que aparecerán previo a las preguntas del formulario.</f7-block-header
                >
                <f7-text-editor
                    placeholder="Puede utilizar negrita, subrayado e itálica, y crear listas..."
                    :value="clonedInstrucciones"
                    @texteditor:change="v => (clonedInstrucciones = v)"
                    :buttons="[
                        ['bold', 'italic', 'underline'],
                        ['orderedList', 'unorderedList'],
                    ]"
                />
                <f7-block
                    ><f7-button :disabled="!clonedInstrucciones || updt.instrx" fill large raised color="teal" @click="guardaInstrucciones()"
                        ><f7-preloader v-if="updt.instrx"></f7-preloader><span v-else>Guardar instrucciones</span></f7-button
                    ></f7-block
                >
            </f7-page>
        </f7-popup>

        <f7-popup :opened="neoPreguntaPopOpened" @popup:open="resetNewQuestion()" @popup:closed="neoPreguntaPopOpened = false">
            <f7-page>
                <f7-navbar title="Instrucciones del formulario">
                    <f7-nav-right>
                        <f7-link popup-close>Cancelar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block-title>Instrucciones</f7-block-title>
                <f7-block-header
                    >Indique aquí, de forma opcional, instrucciones que aparecerán previo a las preguntas del formulario.</f7-block-header
                >
                <f7-text-editor
                    placeholder="Puede utilizar negrita, subrayado e itálica, y crear listas..."
                    :value="clonedInstrucciones"
                    @texteditor:change="v => (clonedInstrucciones = v)"
                    :buttons="[
                        ['bold', 'italic', 'underline'],
                        ['orderedList', 'unorderedList'],
                    ]"
                />
                <f7-block
                    ><f7-button :disabled="!clonedInstrucciones || updt.instrx" fill large raised color="teal" @click="guardaInstrucciones()"
                        ><f7-preloader v-if="updt.instrx"></f7-preloader><span v-else>Guardar instrucciones</span></f7-button
                    ></f7-block
                >
            </f7-page>
        </f7-popup>

        <f7-popup :opened="!!editaEnunciadoPopUpOpened" @popup:open="resetNewQuestion()" @popup:closed="editaEnunciadoPopUpOpened = null">
            <f7-page>
                <f7-navbar title="Enunciado de la pregunta" subtitle="Editando">
                    <f7-nav-right>
                        <f7-link popup-close>Cancelar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-block-header>Edite aquí el enunciado de la pregunta.</f7-block-header>
                <f7-text-editor
                    placeholder="Puede utilizar negrita, subrayado e itálica, y crear listas..."
                    :value="enunciadoEditando"
                    @texteditor:change="v => (enunciadoEditando = v)"
                    :buttons="[
                        ['bold', 'italic', 'underline'],
                        ['orderedList', 'unorderedList'],
                    ]"
                />
                <f7-block
                    ><f7-button
                        :disabled="!enunciadoEditando || guardandoeste == editaEnunciadoPopUpOpened + 'edita_enunciado'"
                        fill
                        large
                        raised
                        color="teal"
                        @click="modificaPregunta({ preguntaid: editaEnunciadoPopUpOpened, action: 'edita_enunciado', valor: enunciadoEditando })"
                        ><f7-preloader v-if="guardandoeste == editaEnunciadoPopUpOpened + 'edita_enunciado'"></f7-preloader
                        ><span v-else>Guardar cambios</span></f7-button
                    ></f7-block
                >
            </f7-page>
        </f7-popup>

        <f7-popup :opened="editaReglaPoppOpened != null" @popup:open="resetNewQuestion()" @popup:closed="cerrandoReglaGenerator()">
            <f7-page v-if="editaReglaPoppOpened">
                <f7-navbar title="Crear nueva regla">
                    <f7-nav-right>
                        <f7-link popup-close>Cancelar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-list>
                    <f7-list-item
                        class="fondogrisback"
                        header="Creando nueva regla de visibilidad para pregunta:"
                        :title="pregFocoEdicion"
                    ></f7-list-item>
                </f7-list>
                <f7-block-header>1.- Seleccione la pregunta cuya respuesta definirá si esta pregunta será visible o no.</f7-block-header>
                <f7-list class="no-margin-bottom">
                    <f7-list-input
                        label="Pregunta definitoria"
                        type="select"
                        defaultValue=""
                        placeholder="Seleccione..."
                        :value="nuevaregla.origenid"
                        @input="setPrefDefinitoria($event.target.value)"
                    >
                        <option value="">Seleccione</option>
                        <option v-for="preg in preguntesAntesdePregFoto" :key="preg.id" :value="preg.id">{{ preg.displ }}</option>
                    </f7-list-input>
                </f7-list>
                <f7-block-header class="no-margin-top">Sólo se muestran preguntas previas a la pregunta en foco</f7-block-header>

                <template v-if="nuevaregla.origenid">
                    <f7-block-header>2.- Seleccione el tipo de verificación a aplicar.</f7-block-header>
                    <f7-list media-list>
                        <f7-list-item
                            radio
                            :value="restricid"
                            @change="nuevaregla.restricid = $event.target.value"
                            :checked="nuevaregla.restricid == restricid"
                            name="selec-restriccion"
                            v-for="(cumval, restricid) in forms_logic.tipos_preg[formularioEnFoco.preguntas[nuevaregla.origenid].tipo].res"
                            :key="restricid"
                            :title="forms_logic.retrix_types[restricid].nm"
                            :text="forms_logic.retrix_types[restricid].ds"
                        ></f7-list-item>
                    </f7-list>

                    <template v-if="['elige_al_menos_estas_ids', 'elige_exactamente_estas_ids'].includes(nuevaregla.restricid)">
                        <f7-block-header>3.- Seleccione la o las alternativas a evaluar.</f7-block-header>
                        <f7-list media-list>
                            <f7-block
                                v-if="!filtrameAlternativas(formularioEnFoco.preguntas[nuevaregla.origenid].alternativas).length"
                                class="no-margin text-align-center text-color-red"
                                >No hay alternativas creadas para esa pregunta</f7-block
                            >
                            <f7-list-item
                                v-for="alternat in filtrameAlternativas(formularioEnFoco.preguntas[nuevaregla.origenid].alternativas)"
                                :key="alternat.id"
                                checkbox
                                :value="alternat.id"
                                @change="populaArrayx($event, nuevaregla.restricid)"
                                :checked="nuevaregla[nuevaregla.restricid].includes(alternat.id)"
                                :title="alternat.tx"
                            ></f7-list-item>
                        </f7-list>
                    </template>
                    <template v-if="nuevaregla.restricid == 'equal_to_optionid'">
                        <f7-block-header>3.- Seleccione la alternativa a evaluar.</f7-block-header>
                        <f7-list media-list>
                            <f7-block
                                v-if="!filtrameAlternativas(formularioEnFoco.preguntas[nuevaregla.origenid].alternativas).length"
                                class="no-margin text-align-center text-color-red"
                                >No hay alternativas creadas para esa pregunta</f7-block
                            >
                            <f7-list-item
                                v-for="alternat in filtrameAlternativas(formularioEnFoco.preguntas[nuevaregla.origenid].alternativas)"
                                :key="alternat.id"
                                radio
                                name="uniaoptaevaluar"
                                :value="alternat.id"
                                @change="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                :checked="nuevaregla[nuevaregla.restricid] == alternat.id"
                                :title="alternat.tx"
                            ></f7-list-item>
                        </f7-list>
                    </template>
                    <template v-if="nuevaregla.restricid && nuevaregla.restricid != 'any_value'">
                        <template v-if="formularioEnFoco.preguntas[nuevaregla.origenid].tipo == 'input_texto'">
                            <f7-block-header>3.- Indique el texto a evaluar.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Texto a evaluar"
                                    type="text"
                                    clear-button
                                    :value="nuevaregla[nuevaregla.restricid]"
                                    @input="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                >
                                </f7-list-input>
                            </f7-list>
                        </template>
                        <template v-if="formularioEnFoco.preguntas[nuevaregla.origenid].tipo == 'input_numero'">
                            <f7-block-header>3.- Indique el valor a evaluar.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Valor a evaluar"
                                    type="number"
                                    clear-button
                                    :value="nuevaregla[nuevaregla.restricid]"
                                    @input="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                >
                                </f7-list-input>
                            </f7-list>
                        </template>
                        <template v-if="formularioEnFoco.preguntas[nuevaregla.origenid].tipo == 'input_fecha'">
                            <f7-block-header>3.- Seleccione la fecha a evaluar.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Fecha a evaluar"
                                    type="datepicker"
                                    placeholder="Seleccione fecha"
                                    readonly
                                    :calendar-params="{ dateFormat: 'dd/mm/yyyy', value: [], locale: 'es', closeOnSelect: true }"
                                    clear-button
                                    @change="nuevaregla[nuevaregla.restricid] = $dayjs($event.target.value, 'DD/MM/YYYY').format('YYYYMMDD')"
                                >
                                </f7-list-input>
                            </f7-list>
                        </template>
                    </template>
                </template>
                <f7-block
                    ><f7-button
                        :disabled="!puedoCrearRegla || guardandoeste == preguntasOrdenadas[editaReglaPoppOpened].id + 'set_regla'"
                        fill
                        large
                        raised
                        color="teal"
                        @click="crearRegla()"
                        ><f7-preloader v-if="guardandoeste == preguntasOrdenadas[editaReglaPoppOpened].id + 'set_regla'"></f7-preloader
                        ><span v-else>Crear regla</span></f7-button
                    ></f7-block
                >
            </f7-page>
        </f7-popup>

        <f7-popup :opened="definirEsaviGrave" @popup:open="resetNewQuestion()" @popup:closed="definirEsaviGrave = false">
            <f7-page v-if="definirEsaviGrave">
                <f7-navbar title="Definir ESAVI Grave">
                    <f7-nav-right>
                        <f7-link popup-close>Cancelar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-block-header>1.- Seleccione una pregunta cuya respuesta representa un ESAVI Grave.</f7-block-header>
                <f7-list class="no-margin-bottom">
                    <f7-list-input
                        label="Pregunta definitoria"
                        type="select"
                        defaultValue=""
                        placeholder="Seleccione..."
                        :value="nuevaregla.origenid"
                        @input="setPrefDefinitoria($event.target.value)"
                    >
                        <option value="">Seleccione</option>
                        <option v-for="preg in todosEnunciadosClean" :key="preg.id" :value="preg.id">{{ preg.displ }}</option>
                    </f7-list-input>
                </f7-list>

                <template v-if="nuevaregla.origenid">
                    <f7-block-header>2.- Seleccione el tipo de verificación a aplicar.</f7-block-header>
                    <f7-list media-list>
                        <f7-list-item
                            radio
                            :value="restricid"
                            @change="nuevaregla.restricid = $event.target.value"
                            :checked="nuevaregla.restricid == restricid"
                            name="selec-restriccion"
                            v-for="(cumval, restricid) in forms_logic.tipos_preg[formularioEnFoco.preguntas[nuevaregla.origenid].tipo].res"
                            :key="restricid"
                            :title="forms_logic.retrix_types[restricid].nm"
                            :text="forms_logic.retrix_types[restricid].ds"
                        ></f7-list-item>
                    </f7-list>

                    <template v-if="['elige_al_menos_estas_ids', 'elige_exactamente_estas_ids'].includes(nuevaregla.restricid)">
                        <f7-block-header>3.- Seleccione la o las alternativas a evaluar.</f7-block-header>
                        <f7-list media-list>
                            <f7-block
                                v-if="!filtrameAlternativas(formularioEnFoco.preguntas[nuevaregla.origenid].alternativas).length"
                                class="no-margin text-align-center text-color-red"
                                >No hay alternativas creadas para esa pregunta</f7-block
                            >
                            <f7-list-item
                                v-for="alternat in filtrameAlternativas(formularioEnFoco.preguntas[nuevaregla.origenid].alternativas)"
                                :key="alternat.id"
                                checkbox
                                :value="alternat.id"
                                @change="populaArrayx($event, nuevaregla.restricid)"
                                :checked="nuevaregla[nuevaregla.restricid].includes(alternat.id)"
                                :title="alternat.tx"
                            ></f7-list-item>
                        </f7-list>
                    </template>
                    <template v-if="nuevaregla.restricid == 'equal_to_optionid'">
                        <f7-block-header>3.- Seleccione la alternativa a evaluar.</f7-block-header>
                        <f7-list media-list>
                            <f7-block
                                v-if="!filtrameAlternativas(formularioEnFoco.preguntas[nuevaregla.origenid].alternativas).length"
                                class="no-margin text-align-center text-color-red"
                                >No hay alternativas creadas para esa pregunta</f7-block
                            >
                            <f7-list-item
                                v-for="alternat in filtrameAlternativas(formularioEnFoco.preguntas[nuevaregla.origenid].alternativas)"
                                :key="alternat.id"
                                radio
                                name="uniaoptaevaluar"
                                :value="alternat.id"
                                @change="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                :checked="nuevaregla[nuevaregla.restricid] == alternat.id"
                                :title="alternat.tx"
                            ></f7-list-item>
                        </f7-list>
                    </template>
                    <template v-if="nuevaregla.restricid && nuevaregla.restricid != 'any_value'">
                        <template v-if="formularioEnFoco.preguntas[nuevaregla.origenid].tipo == 'input_texto'">
                            <f7-block-header>3.- Indique el texto a evaluar.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Texto a evaluar"
                                    type="text"
                                    clear-button
                                    :value="nuevaregla[nuevaregla.restricid]"
                                    @input="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                >
                                </f7-list-input>
                            </f7-list>
                        </template>
                        <template v-if="formularioEnFoco.preguntas[nuevaregla.origenid].tipo == 'input_numero'">
                            <f7-block-header>3.- Indique el valor a evaluar.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Valor a evaluar"
                                    type="number"
                                    clear-button
                                    :value="nuevaregla[nuevaregla.restricid]"
                                    @input="nuevaregla[nuevaregla.restricid] = $event.target.value"
                                >
                                </f7-list-input>
                            </f7-list>
                        </template>
                        <template v-if="formularioEnFoco.preguntas[nuevaregla.origenid].tipo == 'input_fecha'">
                            <f7-block-header>3.- Seleccione la fecha a evaluar.</f7-block-header>
                            <f7-list no-hairlines-md>
                                <f7-list-input
                                    label="Fecha a evaluar"
                                    type="datepicker"
                                    placeholder="Seleccione fecha"
                                    readonly
                                    :calendar-params="{ dateFormat: 'dd/mm/yyyy', value: [], locale: 'es', closeOnSelect: true }"
                                    clear-button
                                    @change="nuevaregla[nuevaregla.restricid] = $dayjs($event.target.value, 'DD/MM/YYYY').format('YYYYMMDD')"
                                >
                                </f7-list-input>
                            </f7-list>
                        </template>
                    </template>
                </template>
                <f7-block
                    ><f7-button
                        :disabled="!puedoCrearRegla || guardandoeste == 'set_nuevo_esavi_grave'"
                        fill
                        large
                        raised
                        color="teal"
                        @click="defineEsaviGrave()"
                        ><f7-preloader v-if="guardandoeste == 'set_nuevo_esavi_grave'"></f7-preloader><span v-else>Crear regla</span></f7-button
                    ></f7-block
                >
            </f7-page>
        </f7-popup>
    </f7-page>
</template>
<script>
import { commonmixin } from "../mixins/common";
import { mapState } from "vuex";

export default {
    mixins: [commonmixin],
    props: ["id"],
    data() {
        return {
            updt: {
                name: false,
                publsh: false,
                instrx: false,
                sorting: false,
                enuncix: false,
            },
            popInstrucOpened: false,
            clonedInstrucciones: "",
            neoPreguntaPopOpened: false,
            editaEnunciadoPopUpOpened: null,
            enunciadoEditando: "",
            guardandoeste: null,
            calendarModal: null,
            datlimitsettings: {
                preguntaid: null,
                cuallimite: null,
            },
            editaReglaPoppOpened: null,
            nuevaregla: {
                origenid: null,
                restricid: null,
                elige_al_menos_estas_ids: [],
                elige_exactamente_estas_ids: [],
                equal_to_optionid: null,
                contiene: "",
                equal_to: "",
                any_value: true,
                mayor_que: null,
                menor_que: null,
            },
            definirEsaviGrave: false,
        };
    },
    computed: {
        ...mapState(["forms_logic"]),
        formularioEnFoco() {
            return this.$store.getters.getUno("formularios", this.id);
        },
        esv_grv_array() {
            return Object.values(this.formularioEnFoco.esv_grv || {});
        },
        preguntasOrdenadas() {
            let regunts = this.formularioEnFoco.preguntas || {};
            return Object.values(regunts)
                .filter(unapreg => {
                    return !unapreg.disabled;
                })
                .sort((a, b) => {
                    if (a.orden > b.orden) {
                        return 1;
                    } else if (a.orden < b.orden) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
        },
        pregFocoEdicion() {
            if (this.editaReglaPoppOpened != null) {
                return this.striphtml(this.preguntasOrdenadas[this.editaReglaPoppOpened].encabezado);
            } else {
                return "";
            }
        },
        todosEnunciadosClean() {
            let esgras = this.formularioEnFoco.esv_grv || {};
            return this.preguntasOrdenadas
                .filter(unapre => {
                    return !esgras[unapre.id];
                })
                .map(unapre => {
                    return { id: unapre.id, displ: this.striphtml(unapre.encabezado) };
                });
        },
        preguntesAntesdePregFoto() {
            if (this.editaReglaPoppOpened != null) {
                return this.preguntasOrdenadas
                    .filter((unapreg, idx) => {
                        return idx < this.editaReglaPoppOpened;
                    })
                    .map(unapre => {
                        return { id: unapre.id, displ: this.striphtml(unapre.encabezado) };
                    });
            } else {
                return [];
            }
        },
        puedoCrearRegla() {
            if (!this.nuevaregla.origenid || !this.nuevaregla.restricid) {
                return false;
            }
            if (this.nuevaregla.restricid == "any_value") {
                return true;
            } else if (this.nuevaregla.restricid == "elige_al_menos_estas_ids" && this.nuevaregla["elige_al_menos_estas_ids"].length) {
                return true;
            } else if (this.nuevaregla.restricid == "elige_exactamente_estas_ids" && this.nuevaregla["elige_exactamente_estas_ids"].length) {
                return true;
            } else if (this.nuevaregla[this.nuevaregla.restricid]) {
                return true;
            } else {
                return false;
            }
        },
        warnings_by_preg() {
            let warnings = {};
            this.preguntasOrdenadas.forEach(unapreg => {
                warnings[unapreg.id] = "";
                if (["elige_many", "elige_una"].includes(unapreg.tipo)) {
                    if (!unapreg.alternativas || !Object.keys(unapreg.alternativas).length) {
                        if (warnings[unapreg.id]) {
                            warnings[unapreg.id] += " | ";
                        }
                        warnings[unapreg.id] += "Pregunta de alternativas sin alternativas";
                    }
                }
                if (unapreg.depde) {
                    //ver que regla logica
                    if (!this.formularioEnFoco.preguntas[unapreg.depde.pregid] || this.formularioEnFoco.preguntas[unapreg.depde.pregid].disabled) {
                        if (warnings[unapreg.id]) {
                            warnings[unapreg.id] += " | ";
                        }
                        warnings[unapreg.id] += "Regla depende de pregunta que ya no existe";
                    } else if (this.formularioEnFoco.preguntas[unapreg.depde.pregid].orden > unapreg.orden) {
                        if (warnings[unapreg.id]) {
                            warnings[unapreg.id] += " | ";
                        }
                        warnings[unapreg.id] += "Regla depende de pregunta ulterior";
                    }
                    //ver si dependen de prg con alternativas
                    if (["elige_many", "elige_una"].includes(this.formularioEnFoco.preguntas[unapreg.depde.pregid].tipo)) {
                        //ver que no hayan reglas que dependadn de alternativas que no existan o esten inactivas
                        let hayincosisnteica = false;
                        if (
                            !this.formularioEnFoco.preguntas[unapreg.depde.pregid].alternativas ||
                            !Object.keys(this.formularioEnFoco.preguntas[unapreg.depde.pregid].alternativas).length
                        ) {
                            hayincosisnteica = true;
                        } else if (this.formularioEnFoco.preguntas[unapreg.depde.pregid].tipo === "elige_una") {
                            let unreqid = unapreg.depde[unapreg.depde.tipo];
                            hayincosisnteica =
                                !this.formularioEnFoco.preguntas[unapreg.depde.pregid].alternativas[unreqid] ||
                                this.formularioEnFoco.preguntas[unapreg.depde.pregid].alternativas[unreqid].inct;
                        } else if (this.formularioEnFoco.preguntas[unapreg.depde.pregid].tipo === "elige_many") {
                            let requerimientoarray = unapreg.depde[unapreg.depde.tipo] || [];
                            hayincosisnteica = requerimientoarray.some(unreqid => {
                                return (
                                    !this.formularioEnFoco.preguntas[unapreg.depde.pregid].alternativas[unreqid] ||
                                    this.formularioEnFoco.preguntas[unapreg.depde.pregid].alternativas[unreqid].inct
                                );
                            });
                        }
                        if (hayincosisnteica) {
                            if (warnings[unapreg.id]) {
                                warnings[unapreg.id] += " | ";
                            }
                            warnings[unapreg.id] += "Regla depende de alternativa que ya no existe en pregunta de origen.";
                        }
                    }
                }
            });

            return warnings;
        },
    },
    mounted() {
        this.calendarModal = this.$f7.calendar.create({
            inputEl: "#calpickhidden",
            openIn: "customModal",
            locale: "es",
            dateFormat: "yyyymmdd",
            closeOnSelect: true,
            on: {
                change: (c, v) => {
                    console.log("Calendar canged", v, this.datlimitsettings);
                    this.modificaPregunta({
                        preguntaid: this.datlimitsettings.preguntaid,
                        action: "set_limite_" + this.datlimitsettings.cuallimite,
                        valor: this.$dayjs(v[0]).format("YYYYMMDD"),
                    });
                },
            },
        });
    },
    methods: {
        getAmostrar(unvalorxs, pregid) {
            if (Array.isArray(unvalorxs)) {
                return unvalorxs
                    .map(altid => {
                        return this.formularioEnFoco.preguntas[pregid].alternativas[altid].tx;
                    })
                    .join(" | ");
            } else if (unvalorxs === true) {
                return null;
            } else if (this.formularioEnFoco.preguntas[pregid].tipo === "elige_una") {
                if (!this.formularioEnFoco.preguntas[pregid].alternativas[unvalorxs]) {
                    return "ALTERNATIVA YA NO EXISTE EN PREGUNTA DE ORIGEN. ELIMINE ESTA REGLA.";
                } else {
                    return this.formularioEnFoco.preguntas[pregid].alternativas[unvalorxs].tx;
                }
            } else {
                return unvalorxs;
            }
        },
        defineEsaviGrave() {
            let nuevaregla = {
                pregid: this.nuevaregla.origenid,
                tipo: this.nuevaregla.restricid,
            };
            nuevaregla[this.nuevaregla.restricid] = this.nuevaregla[this.nuevaregla.restricid];
            console.log(nuevaregla);
            this.$f7.dialog.preloader("Configurando ESAVI grave...");
            this.guardaModificacion({
                action: "set_esavi_gr",
                valor: nuevaregla,
            }).then(() => {
                this.definirEsaviGrave = false;
                this.$f7.dialog.close();
                this.cerrandoReglaGenerator();
            });
        },
        borraEsaviGrave(preguntaid) {
            this.$f7.dialog.preloader("Eliminando una definición...");
            this.guardaModificacion({
                action: "borra_def_esavi_gr",
                valor: preguntaid,
            }).then(() => {
                this.$f7.dialog.close();
            });
        },
        crearRegla() {
            //set_regla
            this.$f7.dialog.preloader("Seteando...");
            let nuevaregla = {
                pregid: this.nuevaregla.origenid,
                tipo: this.nuevaregla.restricid,
            };
            nuevaregla[this.nuevaregla.restricid] = this.nuevaregla[this.nuevaregla.restricid];
            this.modificaPregunta({ preguntaid: this.preguntasOrdenadas[this.editaReglaPoppOpened].id, action: "set_regla", valor: nuevaregla }).then(
                () => {
                    this.$f7.dialog.close();
                    this.cerrandoReglaGenerator();
                }
            );
        },
        cerrandoReglaGenerator() {
            this.resetReglaGenerator();
            this.editaReglaPoppOpened = null;
        },
        populaArrayx(event, restriccionid) {
            const value = event.target.value;
            if (event.target.checked) {
                if (!this.nuevaregla[restriccionid].includes(value)) {
                    this.nuevaregla[restriccionid].push(value);
                }
            } else {
                this.nuevaregla[restriccionid].splice(this.nuevaregla[restriccionid].indexOf(value), 1);
            }
        },
        resetReglaGenerator() {
            this.nuevaregla.restricid = null;
            this.nuevaregla.origenid = null;
            this.nuevaregla.elige_al_menos_estas_ids = [];
            this.nuevaregla.elige_exactamente_estas_ids = [];
            this.nuevaregla.contiene = "";
            this.nuevaregla.equal_to = "";
            this.nuevaregla.equal_to_optionid = null;
            this.nuevaregla.mayor_que = null;
            this.nuevaregla.any_value = true;
            this.nuevaregla.menor_que = null;
        },
        setPrefDefinitoria(pregdefinitoriaid) {
            this.resetReglaGenerator();
            this.nuevaregla.origenid = pregdefinitoriaid;
        },
        abrecalx(preguntaid, cuallimite) {
            this.datlimitsettings = { preguntaid, cuallimite };
            this.calendarModal.open();
        },
        filtrameAlternativas(altes) {
            if (altes) {
                return Object.values(altes).filter(unalt => {
                    return !unalt.inct;
                });
            } else {
                return [];
            }
        },
        async modificaPregunta({ preguntaid, action, valor, precision }) {
            try {
                this.guardandoeste = preguntaid + action + (precision || "");
                let datous = await this.commonExecute({ formid: this.id, preguntaid, action, valor }, "admin-editaPreguntaFormulario", false);
                this.guardandoeste = null;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log("creacion ok", datous);
                let estaPregunta = Object.assign({}, this.formularioEnFoco.preguntas[preguntaid]);
                if (action == "edita_enunciado") {
                    estaPregunta.encabezado = valor;
                    this.editaEnunciadoPopUpOpened = null;
                } else if (action == "edita_obligatoriedad") {
                    estaPregunta.req = valor;
                } else if (action == "edita_text_area_size") {
                    estaPregunta.txtarea = valor;
                } else if (action == "crea_alternativa") {
                    if (!estaPregunta.alternativas) {
                        estaPregunta.alternativas = {};
                    }
                    estaPregunta.alternativas[datous.nuevaalternativa] = { id: datous.nuevaalternativa, tx: valor };
                } else if (action == "elimina_alternativa") {
                    estaPregunta.alternativas[valor].inct = true;
                    delete estaPregunta.alternativas[valor];
                } else if (action == "set_limite_inferior") {
                    estaPregunta.rangemin = valor;
                } else if (action == "set_limite_superior") {
                    estaPregunta.rangemax = valor;
                } else if (action == "set_regla") {
                    estaPregunta.depde = valor;
                }
                this.$store.commit("addItemAColeccionNested", {
                    vid: this.id,
                    col: "formularios",
                    prop: "preguntas",
                    pid: preguntaid,
                    val: estaPregunta,
                });
                this.avisaOk();
            } catch (error) {
                this.guardandoeste = null;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        abreEditorEnunciado(preguntaid) {
            this.editaEnunciadoPopUpOpened = preguntaid;
            this.enunciadoEditando = this.formularioEnFoco.preguntas[preguntaid].encabezado || "";
        },
        eliminaPregunta(preguntaid) {
            this.$f7.dialog.confirm("Confirme que desea eliminar esta pregunta", "Confirme", () => {
                console.log("elimina_pregunta");
                this.$f7.dialog.preloader("Eliminando...");
                this.guardaModificacion({
                    action: "elimina_pregunta",
                    valor: preguntaid,
                }).then(() => {
                    this.$f7.dialog.close();
                });
            });
        },
        onSort(data) {
            // Sort data
            console.log(data);
            let clonado = this.preguntasOrdenadas.slice(0);
            clonado.splice(data.to, 0, clonado.splice(data.from, 1)[0]);
            console.log(this.preguntasOrdenadas);
            console.log(clonado);
            let newPreguntas = {};
            let update = {};
            clonado.forEach((unapreg, idx) => {
                unapreg.orden = idx;
                newPreguntas[unapreg.id] = unapreg;
                update["formularios/" + this.id + "/preguntas/" + unapreg.id + "/orden"] = idx;
            });
            this.updt.sorting = true;
            return this.$firebase
                .database()
                .ref()
                .update(update)
                .then(() => {
                    console.log("cambio ok");
                    //newPreguntas
                    this.$store.commit("replaceAllItemsAColeccionNested", { vid: this.id, col: "formularios", prop: "preguntas", val: newPreguntas });
                    this.$f7.sortable.disable(".headpreg");
                    this.updt.sorting = false;
                    this.avisaOk();
                })
                .catch(error => {
                    this.updt.sorting = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
        resetNewQuestion() {
            console.log("resetear neuva pregunta");
        },
        abreModifInstrucc() {
            this.clonedInstrucciones = this.formularioEnFoco.instrucciones || "";
        },
        guardaInstrucciones() {
            this.guardaModificacion({ action: "edita_instrucciones", valor: this.clonedInstrucciones, detalle: null, updt: "instrx" });
        },
        precambiopuglicacion() {
            if (!this.formularioEnFoco.published) {
                if (!this.formularioEnFoco.preguntas || !Object.keys(this.formularioEnFoco.preguntas).length) {
                    return this.$f7.dialog.alert("No puede publicar un formulario sin preguntas", "No tiene preguntas");
                }
                this.guardaModificacion({ action: "publica", valor: true, updt: "publsh" });
            } else {
                this.$f7.dialog.confirm("No estará disponible para nuevas campañas", "Confirme que desea despublicar este formulario", () => {
                    this.guardaModificacion({ action: "despublica", valor: true, updt: "publsh" });
                });
            }
        },
        updateName() {
            return this.$f7.dialog.prompt(
                "Indique nuevo nombre",
                cambio => {
                    if (!cambio) {
                        return this.$f7.dialog.alert("Nombre del formulario no puede ser vacío", "Error");
                    }
                    this.guardaModificacion({
                        action: "edita_nombre",
                        valor: cambio.trim(),
                        updt: "name",
                    });
                },
                () => {},
                this.formularioEnFoco.name
            );
        },
        async guardaModificacion({ action, valor, updt, detalle }) {
            try {
                this.updt[updt] = true;
                let datous = await this.commonExecute({ id: this.id, action, valor, detalle }, "admin-editaFormulario", false);
                this.updt[updt] = false;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log("creacion ok", datous);
                let modific = {};
                if (action == "edita_nombre") {
                    modific.name = valor;
                } else if (action == "despublica") {
                    modific.published = null;
                } else if (action == "publica") {
                    modific.published = true;
                } else if (action == "edita_instrucciones") {
                    modific.instrucciones = valor;
                    this.popInstrucOpened = false;
                } else if (action == "set_esavi_gr") {
                    modific.esv_grv = Object.assign({}, this.formularioEnFoco.esv_grv || {});
                    modific.esv_grv[valor.pregid] = valor;
                } else if (action == "borra_def_esavi_gr") {
                    modific.esv_grv = Object.assign({}, this.formularioEnFoco.esv_grv || {});
                    delete modific.esv_grv[valor];
                }

                if (Object.keys(modific).length) {
                    this.$store.commit("updtObjProperty", {
                        what: "formularios",
                        to: Object.assign({}, this.formularioEnFoco, modific),
                    });
                }

                if (action == "agrega_pregunta") {
                    let nuevapregunta = datous.preguntaAcrear;
                    this.$store.commit("addItemAColeccionNested", {
                        vid: this.id,
                        col: "formularios",
                        prop: "preguntas",
                        pid: nuevapregunta.id,
                        val: nuevapregunta,
                    });
                } else if (action == "elimina_pregunta") {
                    this.$store.commit("borrarItemDeColeccionNested", { vid: this.id, col: "formularios", prop: "preguntas", pid: valor });
                }

                this.avisaOk();
            } catch (error) {
                this.updt[updt] = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        crearPregunta(tipopreg) {
            return this.$f7.dialog.prompt("Indique el encabezado", "Nueva pregunta", neopreg => {
                if (!neopreg) {
                    return this.$f7.dialog.alert("El encabezado no puede ser vacío", "Error");
                }

                this.$f7.dialog.preloader("Creando...");
                this.guardaModificacion({
                    action: "agrega_pregunta",
                    valor: neopreg.trim(),
                    detalle: tipopreg,
                }).then(() => {
                    this.$f7.dialog.close();
                });
            });
        },
        creaAlternativa(preguntaid) {
            return this.$f7.dialog.prompt("Indique el texto a presentar", "Nueva alternativa", neopreg => {
                if (!neopreg) {
                    return this.$f7.dialog.alert("La alternativa no puede ser vacía", "Error");
                }
                this.$f7.dialog.preloader("Creando...");
                this.modificaPregunta({ preguntaid, action: "crea_alternativa", valor: neopreg }).then(() => {
                    this.$f7.dialog.close();
                });
            });
        },
        setLimiteNum(preguntaid, tipolimite) {
            return this.$f7.dialog.prompt("Indique el límite " + tipolimite, "Set límite " + tipolimite, neolit => {
                if (neolit === "") {
                    return this.$f7.dialog.alert("Límite no puede ser vacío", "Error");
                }
                this.$f7.dialog.preloader("Seteando...");
                this.modificaPregunta({ preguntaid, action: "set_limite_" + tipolimite, valor: neolit }).then(() => {
                    this.$f7.dialog.close();
                });
            });
        },
    },
};
</script>
<style>
.altoalternativa {
    height: 20px !important;
    max-height: 20px !important;
    line-height: 20px !important;
}
.headpreg .accordion-item > a.item-link {
    background: #e8e8e8;
    border-top: 1px solid #ffffff;
}

.headpreg .accordion-item > .item-link .item-inner:before,
.headpreg.sortable .sortable-handler:after {
    color: #929292 !important;
}

.headpreg .accordion-item-content {
    background: #d6d6d6;
}
.bloqueamultilinea {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: 23px;
    overflow: hidden;
}
.setwithseg {
    width: 220px;
}
</style>
