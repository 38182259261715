import { clean, format, validate } from "rut.js";
import { load } from "recaptcha-v3";

export const commonmixin = {
    data() {
        return {
            recaptcha: null,
            avisaCambiosOk: null,
        };
    },
    methods: {
        getCleanRut(rut) {
            return clean(rut);
        },
        formatRut(rut) {
            return format(rut);
        },
        suageStrg(fechanac) {
            const date2 = this.$dayjs(fechanac);
            const date1 = this.$dayjs();
            let numer = date1.diff(date2, "y");
            let unidad = "años";
            if (numer < 2) {
                numer = date1.diff(date2, "M");
                unidad = "meses";
                if (numer < 2) {
                    numer = date1.diff(date2, "d");
                    unidad = "días";
                }
            }
            return `${numer} ${unidad}`;
        },
        validaRut(rut) {
            return validate(rut);
        },
        striphtml(alimpiar) {
            return alimpiar.replace(/(<([^>]+)>)/gi, "").replace("&nbsp;", " ");
        },
        normalize(stringotonomral) {
            let a = String(stringotonomral)
                .trim()
                .toUpperCase(); //asegurar que sea string o forzar string, y hacer mayuscula
            let b = a.split(".").join(""); //sacar puntos
            let c = b.split("-").join(""); //sacar dashes
            let d = c.split(" ").join(""); //sacar espacios
            return d;
        },
        avisaOk(phrase) {
            if (!this.avisaCambiosOk) {
                this.avisaCambiosOk = this.$f7.toast.create({
                    text: phrase || "Cambios guardados OK",
                    position: "center",
                    closeTimeout: 2000,
                });
            }
            this.avisaCambiosOk.open();
        },
        async commonExecute(dataToSend, cual, conCaptcha = false) {
            if (conCaptcha) {
                this.recaptcha = await load(process.env.CAPTCHA_KEY);
                dataToSend.captchaToken = await this.recaptcha.execute("declaraIdentidad");
            }
            dataToSend.dev = this.$store.state.dev;
            let fxtocall = this.$firebase.functions().httpsCallable(cual, { timeout: 30000 });
            let result = await fxtocall(dataToSend);
            return result.data;
        },
        async getMeThese(arrayOfStoreStateNames, vienabierto = false) {
            let promesex = [];
            let indexOfRequests = [];
            arrayOfStoreStateNames.forEach(unelem => {
                if (!this.$store.state[unelem] || !Object.keys(this.$store.state[unelem]).length || !this.$store.state.lastFullLoad[unelem]) {
                    indexOfRequests.push(unelem);
                    promesex.push(
                        this.$firebase
                            .database()
                            .ref(unelem)
                            .once("value")
                    );
                }
            });
            if (!promesex.length) {
                if (vienabierto) {
                    this.$f7.dialog.close();
                }
                return true;
            }
            if (!vienabierto) {
                this.$f7.dialog.preloader("Cargando info...");
            }
            let multisnaps = await Promise.all(promesex);
            multisnaps.forEach((onesnap, idx) => {
                this.$store.commit("setWhatTo", { what: indexOfRequests[idx], to: onesnap.val() || {} });
            });
            this.$f7.dialog.close();
        },
        cleanAndFormatValue(value) {
            let a = String(value)
                .trim()
                .toUpperCase(); //asegurar que sea string o forzar string, y hacer mayuscula
            let b = a.split(".").join(""); //sacar puntos
            let c = b.split("-").join(""); //sacar dashes
            let d = c.split(" ").join(""); //sacar espacios
            let e = d.split("/").join(""); //sacar /
            let f = e.split("|").join(""); //sacar |
            let g = f.split("%").join(""); //sacar %
            let h = g.split("$").join(""); //sacar $
            let i = h.split("#").join(""); //sacar #
            let j = i.split("!").join(""); //sacar !
            let k = j.split(":").join(""); //sacar :
            let m = k.split("&").join(""); //sacar &
            let n = m.split("(").join(""); //sacar (
            let o = n.split(")").join(""); //sacar )
            let p = o.split("=").join(""); //sacar =
            let q = p.split("?").join(""); //sacar ?
            let r = q.split("'").join(""); //sacar '
            return r;
        },
        uuidv4() {
            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
                let r = (Math.random() * 16) | 0,
                    v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            });
        },
    },
};
