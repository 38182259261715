<template>
   <f7-page>
        <f7-navbar title="Set seguimiento activo de síntomas" subtitle="para las dosis de refuerzo">
          <f7-nav-right>
            <f7-link popup-close>Cancelar</f7-link>
          </f7-nav-right>
        </f7-navbar>
         <f7-block-title>
          1.- Seleccione el formulario a utilizar
        </f7-block-title>
          <f7-list class="margin-bottom">
                <f7-list-input type="select" @change="selectedFormId = $event.target.value">
                      <option value="">Seleccione el formulario a utilizar</option>
                      <option v-for="formulario in formularios_publicados" :key="formulario.id" :value="formulario.id">{{formulario.name}}</option>
                  </f7-list-input>
          </f7-list>
          <template v-if="selectedFormId">
          <f7-block-title class="no-margin-bottom no-margin-top">
          2.- Indique inicio y días de seguimiento<br/><small><b>0 es el dia que recibe dosis de refuerzo</b></small>
        </f7-block-title>
        <f7-block class="no-margin">
            <f7-list v-for="(unseg,posix) in seguixs" :key="unseg.diax" class="no-margin">
                <f7-list-input
                        label="Enviar un seguimiento el día"
                        type="select"
                        :value="unseg.elegido || null"
                        @change="seguixs[posix].elegido=Number($event.target.value)"
                        :disabled="posix<(seguixs.length-1)"
                    >
                        <option v-for="unaopt in unseg.options" :key="unaopt" :value="unaopt">{{unaopt}}</option>
                        <font-awesome-icon slot="media" v-if="posix<(seguixs.length-1) || seguixs.length==1" class="fa-lg" icon="check-circle"></font-awesome-icon>
                        <f7-link @click="seguixs.pop()" v-else-if="posix==seguixs.length-1 && seguixs.length>1" slot="media" tooltip="Eliminar dia de seguimiento">
                            <font-awesome-icon class="fa-lg" icon="minus-circle"></font-awesome-icon>
                        </f7-link>
                    </f7-list-input>
            </f7-list>
            <f7-button @click="agregaSeuimientox()">Agregar dia de seguimiento</f7-button>
        </f7-block>

        <f7-block class="margin-top" >
                <f7-block-title class="no-margin-bottom no-margin-top">
                3.- Indique reglas de agendamiento<br/><small>(criterio de vacunación que gatilla seguimiento)</small>
                </f7-block-title>
        <f7-list>
            <f7-list-input
                label="Criterio que gatilla seguimiento"
                type="select"
                :value="concriterio"
                @change="concriterio = $event.target.value"
            >
                <option value="">Cualquier criterio gatilla seguimiento</option>
                <option value="1">Segumiento solo gatillado por el siguiente criterio:</option>
            </f7-list-input>
             <f7-list-input v-if="concriterio"
                label="Indique criterio que gatilla seguimiento"
                type="text"
                placeholder="Indique criterio"
                 :value="crietriox"
                @change="crietriox = $event.target.value"
                clear-button
            >
            </f7-list-input> 
        </f7-list>
               
    </f7-block>
                        <f7-block><f7-button @click="guardarProtocolo()" large raised fill color="teal">Guardar protocolo de seguimiento</f7-button></f7-block>

      </template>                  
      </f7-page>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    data() {
      return {
        selectedFormId: '',
        seguixs:[],
        concriterio: '',
        crietriox:''
      }
    },
    computed:{
      ...mapGetters(['formularios_publicados']),
      diaUltimoSeguimiento(){
        let largo=this.seguixs.length;
        return largo?this.seguixs[largo-1].elegido:0;
      }
    },
    mounted(){
        this.agregaSeuimientox();
    },
    methods:{
        agregaSeuimientox(){
            console.log('el dia del ulitimo seguimiento es',this.diaUltimoSeguimiento)
            let arregloMaxim = [...Array(151).keys()].filter(num=>{
                return num>this.diaUltimoSeguimiento;
            });
            let apush={diax:this.seguixs.length,options:arregloMaxim,elegido:arregloMaxim[0]};
            console.log(apush);
            this.seguixs.push(apush);
        },
        guardarProtocolo(){

            //concriterio crietriox
            if(this.concriterio && !this.crietriox){
                return this.$f7.dialog.alert('Debe indicar el criterio que gatillará el seguimiento','Falta criterio');
            }

                let amandarx={};
                Object.values(this.seguixs).forEach(unselex => {
                    amandarx[unselex.elegido]=true;
                });
                  let aemitir={
                    formid: this.selectedFormId,
                    when: amandarx,
                    criterio: this.concriterio?this.crietriox: null
                };
                this.$emit('tosend',aemitir);
        }
    }
}
</script>
<style>


</style>