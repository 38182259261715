<template>
    <f7-page name="fiscalizador">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="información sanitaria" title="Módulo Fiscalizador"></f7-nav-title>
        </f7-navbar>

        <f7-block>
            <f7-row :no-gap="true">
                <f7-col width="30">
                    <f7-list class="no-margin">
                        <f7-list-input
                            label="Tipo documento"
                            type="select"
                            defaultValue="RUN"
                            :value="tipodoc"
                            @input="tipodoc = $event.target.value"
                        >
                            <option selected value="RUN">RUN</option>
                            <option value="PAS">Pasaporte</option>
                            <option value="DNI">DNI</option>
                        </f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col width="40">
                    <f7-list class="no-margin">
                        <f7-list-input
                            label="Documento"
                            type="text"
                            placeholder="documento"
                            :value="busqueda"
                            @keyup.native.enter="preSearchPatients()"
                            @input="busqueda = $event.target.value"
                            clear-button
                        >
                        </f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col width="30"
                    ><f7-button class="normaltobu padding-top-half" @click="preSearchPatients()" fill large raised
                        ><font-awesome-icon class="fa-lg" icon="search"></font-awesome-icon></f7-button
                ></f7-col>
            </f7-row>
        </f7-block>

        <f7-list v-if="!buscando && existe" inset media-list>
            <f7-list-item divider title="Información del Paciente"></f7-list-item>
            <f7-list-item
                :header="`id: ${existe.id}`"
                :title="`${existe.apellido1} ${existe.apellido2 || ''}, ${existe.nombres}`"
                :subtitle="`${existe.idenType} ${existe.idenType == 'RUN' ? formatRut(existe.identity) : existe.identity}  | ${existe.nac_iso2}`"
                :text="`${$dayjs(existe.fechanac).format('DD/MM/YYYY')} - ${suageStrg(existe.fechanac)}`"
            >
                <font-awesome-icon
                    slot="media"
                    class="fa-3x"
                    :class="existe.sexo == 'Intersex' ? 'text-color-gray' : existe.sexo == 'F' ? 'text-color-pink' : 'text-color-blue'"
                    icon="user-circle"
                ></font-awesome-icon>
            </f7-list-item>

            <f7-list-item divider title="Consultas API"></f7-list-item>

            <f7-list-item
                title="Información API Epivigila"
                footer="Estado cuarentena y otros"
                popover-open=".popover-epiinfoxs"
                link="#"
            ></f7-list-item>
            <f7-list-item
                title="Estado Ingreso al País"
                footer="Cuarentena viajero y PCR Negativa"
                popover-open=".popover-estadoingreso"
                link="#"
            ></f7-list-item>
            <f7-list-item title="Información API Toma Muestras" footer="Detalles muestras" popup-open=".popup-tomas" link="#"></f7-list-item>
        </f7-list>

        <f7-popover @popover:open="pideEpiv()" class="popover-epiinfoxs">
            <f7-list>
                <f7-list-item header="En cuarentena" :title="cargandoEpi ? 'Cargando...' : epiinfo.en_cuarentena ? 'true' : 'false'">
                    <f7-preloader v-if="cargandoEpi" slot="after"></f7-preloader>
                </f7-list-item>

                <f7-list-item header="Motivo" :title="epiinfo.detalle ? epiinfo.detalle.motivo : ''"></f7-list-item>
                <f7-list-item header="Fecha Inicio Cuarentena" :title="epiinfo.detalle ? epiinfo.detalle.fecha_inicio_cuarentena : ''"></f7-list-item>
                <f7-list-item header="Fecha Fin Cuarentena" :title="epiinfo.detalle ? epiinfo.detalle.fecha_fin_cuarentena : ''"></f7-list-item>

                <f7-list-item header="Timestamp" :title="cargandoEpi ? 'Cargando...' : epiinfo.timestamp">
                    <f7-preloader v-if="cargandoEpi" slot="after"></f7-preloader>
                </f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popover @popover:open="pideIngreso()" class="popover-estadoingreso">
            <f7-list>
                <f7-list-item header="Ingreso" :title="cargandoIngreso ? 'Cargando...' : ingresoInfo.ingreso ? 'Existe' : 'No Existe'">
                    <f7-preloader v-if="cargandoIngreso" slot="after"></f7-preloader>
                </f7-list-item>

                <f7-list-item header="Puede acortar con PCR?" :title="ingresoInfo.ingreso ? (ingresoInfo.detalle.cua_obl? 'NO' : 'SI') : ''"></f7-list-item>

                <f7-list-item header="Fecha Ingreso País" :title="ingresoInfo.ingreso ? ingresoInfo.detalle.f_in : ''"></f7-list-item>
                <f7-list-item header="Fecha Fin Cuarentena" :title="ingresoInfo.ingreso ? ingresoInfo.detalle.c : ''"></f7-list-item>
                <f7-list-item
                    header="Fecha PCR Negativa"
                    :title="ingresoInfo.ingreso ? (ingresoInfo.detalle.pcrN ? ingresoInfo.detalle.pcrN : 'Sin PCR Negativa') : ''"
                ></f7-list-item>
                <f7-list-item
                    header="Cuarentena reducida por PCR Negativa"
                    :title="ingresoInfo.ingreso ? (cumpleReglaEpi ? 'Verdadero' : 'Falso') : ''"
                ></f7-list-item>
                <f7-list-item
                    header="Cuarentena reducida por Antígeno Negativo"
                    :title="ingresoInfo.ingreso ? (cumpleReglaEpiAnt ? 'Verdadero' : 'Falso') : ''"
                ></f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popup @popup:open="pideToma()" class="popup-tomas">
            <f7-list accordion-list class="no-margin">
                <f7-list-item v-if="cargandoTomas" title="Cargando...">
                    <f7-preloader slot="after"></f7-preloader>
                </f7-list-item>
                <f7-list-item v-if="!cargandoTomas && !labtomas.length" title="Sin información"></f7-list-item>
                <f7-list-item v-if="!cargandoTomas && cumpleReglaEpi" title="Cuarentena acortada por PCR">
                    <font-awesome-icon slot="media" class="fa-2x text-color-blue" icon="check-circle"></font-awesome-icon>
                </f7-list-item>
                <f7-list-item v-else-if="!cargandoTomas && cumpleReglaEpiAnt" title="Cuarentena acortada por Antígeno Negativo">
                    <font-awesome-icon slot="media" class="fa-2x text-color-blue" icon="check-circle"></font-awesome-icon>
                </f7-list-item>
                <f7-list-item
                    accordion-item
                    class="tomaslinfoxs"
                    v-for="(unatoma, idx) in labtomas"
                    :key="idx"
                    :header="unatoma.laboratorio"
                    :title="unatoma.resultado_muestra || 'Pendiente'"
                    :footer="unatoma.fecha_toma_muestra"
                >
                    <f7-accordion-content>
                        <f7-list>
                            <f7-list-item v-for="(valuer, proper) in unatoma" :key="proper" :header="proper" :title="String(valuer)"></f7-list-item>
                        </f7-list>
                    </f7-accordion-content>
                </f7-list-item>
            </f7-list>
        </f7-popup>

        <select-patient-popup
            :opened="selectPatientPopupOpened"
            :patients="patientsToSelect"
            @popup-closed="selectPatientPopupOpened = false"
            @patient-selected="handlePatientSelected"
        ></select-patient-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import SelectPatientPopup from "../components/select-patient-popup.vue";


export default {
    mixins: [commonmixin],

    components: {
        "select-patient-popup": SelectPatientPopup,
    },

    data() {
        return {
            buscado: "",
            tipodoc: "RUN",
            busqueda: "",
            existe: null,
            buscando: false,
            cargandoEpi: true,
            epiinfo: {},
            cargandoTomas: true,
            labtomas: [],
            cumpleReglaEpi: false,
            cumpleReglaEpiAnt: false,
            cargandoIngreso: true,
            ingresoInfo: {},

            patientsToSelect: [],
            selectPatientPopupOpened: false,
        };
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["centralAdminAccount"]),
        tipodocarr() {
            return this.existe ? this.existe.id.split("_") : [];
        },
    },
    methods: {
        async pideEpiv() {
            try {
                this.cargandoEpi = true;
                this.epiinfo = Object.assign({});

                let nroDoc = this.tipodocarr[1];

                if (this.tipodocarr.length === 3) {
                    nroDoc += `_${this.tipodocarr[2]}`;
                }

                let datous = await this.commonExecute(
                    { idenType: this.tipodocarr[0], identity: nroDoc },
                    "admin-comprobanteJustEPIvig",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                this.epiinfo = Object.assign({}, datous.payload);
                this.cargandoEpi = false;
                console.log(datous.payload);
            } catch (error) {
                this.cargandoEpi = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async pideToma() {
            try {
                this.cumpleReglaEpi = false;
                this.cargandoTomas = true;
                this.labtomas = [];

                let nroDoc = this.tipodocarr[1];

                if (this.tipodocarr.length === 3) {
                    nroDoc += `_${this.tipodocarr[2]}`;
                }

                let datous = await this.commonExecute(
                    { idenType: this.tipodocarr[0], identity: nroDoc },
                    "admin-PNTMFiscalizador",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                this.labtomas = datous.payload;
                this.cargandoTomas = false;
                this.cumpleReglaEpi = datous.libera;
                this.cumpleReglaEpiAnt = datous.liberaAnt;
                console.log(datous.payload);
            } catch (error) {
                this.cargandoTomas = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async pideIngreso() {
            try {
                this.cargandoIngreso = true;
                this.ingresoInfo = Object.assign({});

                let nroDoc = this.tipodocarr[1];

                if (this.tipodocarr.length === 3) {
                    nroDoc += `_${this.tipodocarr[2]}`;
                }

                let datous = await this.commonExecute(
                    { idenType: this.tipodocarr[0], identity: nroDoc },
                    "admin-PNTMFiscalizador",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                this.ingresoInfo = Object.assign({}, datous.api);
                this.cargandoIngreso = false;
                this.cumpleReglaEpi = datous.libera;
                this.cumpleReglaEpiAnt = datous.liberaAnt;
                console.log(datous.api);
            } catch (error) {
                this.cargandoIngreso = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },

        async preSearchPatients() {
            const trimBusqueda = this.busqueda.trim();

            if (trimBusqueda.length < 2) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe ingresar al menos 2 caracteres");
                return;
            }

            if (this.tipodoc === "RUN") {
                await this.buscar();
            } else {
                this.$f7.dialog.preloader("Buscando...");

                const response = await this.commonExecute(
                    { tipoDoc: this.tipodoc, nroDoc: trimBusqueda, dev: this.dev },
                    "admin-preSearchPatient",
                    false
                );

                if (response.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(response.error.message);
                    return;
                }

                const { patients } = response;

                if (patients.length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron pacientes");
                    return;
                }

                if (patients.length === 1 && patients[0].identity === trimBusqueda) {
                    this.$f7.dialog.close();

                    const patient = patients[0];

                    this.tipodoc = patient.idenType;
                    this.busqueda = patient.identity;

                    await this.buscar();
                } else {
                    this.patientsToSelect = patients;
                    this.selectPatientPopupOpened = true;
                }

                this.$f7.dialog.close();
            }
        },

        async handlePatientSelected(patient) {
            this.tipodoc = patient.idenType;
            this.busqueda = patient.identity;

            await this.buscar();

            this.patientsToSelect = [];
            this.selectPatientPopupOpened = false;
        },

        async buscar() {
            try {
                this.buscando = true;
                this.buscado = "";
                this.existe = null;
                console.log(this.busqueda);
                this.$f7.dialog.preloader("Buscando...");
                // let datous = await this.commonExecute({idenType:this.tipodoc, identity: this.busqueda },'admin-comprobantegetpax',false);
                let datous = await this.commonExecute({ idenType: this.tipodoc, identity: this.busqueda }, "admin-infoPacienteFiscalizador", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log(datous.payload);
                if (!datous.payload.existe) {
                    throw new Error("Paciente no existe o no tiene registros");
                }
                this.existe = datous.payload.existe;
                this.buscando = false;
                // this.$f7.views.main.router.navigate('/copmrobanteficha/',{props:datous.payload})

                this.$f7.dialog.close();
                this.buscado = this.busqueda;
            } catch (error) {
                this.buscando = false;
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
    },
};
</script>
