<template>
    <f7-page class="backgoris">
        <f7-list media-list class="avatrleftmin no-margin-top">
            <f7-list-item
                class="text-color-white titlebold backgoris"
                :subtitle="`${centralAdminAccount.nms} ${centralAdminAccount.ap1}`"
                :title="user.email || 'Configure su email'"
            >
                <img @click="promptme()" slot="after" src="../static/heros/avatar.jpg" />
                <f7-badge v-if="dev" slot="header" color="red">Desarrollo</f7-badge>
            </f7-list-item>
        </f7-list>

        <f7-block v-if="filteredMenu.length" class="no-margin-bottom no-margin-top">Gestión Central</f7-block>
        <f7-list class="latmenu no-margin-top margin-bottom">
            <f7-list-item
                v-for="menux in filteredMenu"
                :key="menux.urlx"
                link="#"
                :class="{ active: menux.urlx == activeTab }"
                @click="gotoMenux(menux.urlx)"
                panel-close
                :badge="menux.badge ? '4' : null"
                badge-color="red"
                :footer="menux.footer"
            >
                <div slot="title" style="white-space: normal">{{ menux.title }}</div>
                <i slot="media" :class="`fas fa-lg fa-${menux.ico}`"></i>
                <font-awesome-icon icon="exclamation-triangle" class="text-color-red fa-lg" slot="after" v-if="menux.chkConflict" />
                <f7-badge v-if="menux.new" color="red">NEW</f7-badge>
            </f7-list-item>
        </f7-list>
        <template v-if="Object.values(centersAdminPrivs).length">
            <f7-block class="no-margin-bottom no-margin-top">Gestión Vacunatorio</f7-block>
            <f7-list class="no-margin-top latmenu">
                <f7-list-item
                    :class="{ concentrosel: centerInFoco }"
                    class="titlebold selectorcentros"
                    link="/eligeCentro/"
                    :no-chevron="true"
                    :title="!centerInFoco ? 'Seleccionar Vacunatorio' : centerInFocoInfo.name"
                    :header="centerInFoco ? `${centerInFocoInfo.address.name}, ${centerInFocoInfo.address.region}` : 'Click para seleccionar'"
                >
                    <font-awesome-icon icon="caret-square-down" slot="after"></font-awesome-icon>
                </f7-list-item>
                <f7-list-item
                    v-for="menux in filteredMenuvac"
                    :key="menux.urlx"
                    link="#"
                    :class="{ active: menux.urlx == activeTab }"
                    @click="gotoMenux(menux.urlx)"
                    panel-close
                    :title="menux.title"
                    :badge="menux.badge ? '4' : null"
                    badge-color="red"
                    :footer="menux.footer"
                >
                    <i slot="media" :class="`fas fa-lg fa-${menux.ico}`"></i>
                </f7-list-item>
            </f7-list>
        </template>
        <f7-block class="version_latx no-margin-vertical">Vacunator.io {{ appVersion }}</f7-block>
        <f7-block class="no-margin-top"
            ><f7-button @click="logmeout()" color="red"><font-awesome-icon icon="power-off"></font-awesome-icon> Cerrar Sesión</f7-button></f7-block
        >
        <f7-block class="margin fuerzalatotidio"></f7-block>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],
    data() {
        return {
            db: 0,
            pr: 0,
            menu: [
                {
                    urlx: "/accounts/",
                    title: "Cuentas",
                    footer: "Administración",
                    ico: "users",
                    prvNeeded: ["cuentas"],
                },
                {
                    urlx: "/vacunatorios/",
                    title: "Vacunatorios",
                    footer: "Crear centros",
                    ico: "hospital-alt",
                    prvNeeded: ["vacunatorios"],
                },
                {
                    urlx: "/vacunas/",
                    title: "Vacunas",
                    footer: "Base de vacunas",
                    ico: "viruses",
                    prvNeeded: ["vacunas"],
                },
                {
                    urlx: "/formularios/",
                    title: "Formularios",
                    footer: "Efectos adversos",
                    ico: "file-medical-alt",
                    prvNeeded: ["formularios"],
                },
                {
                    urlx: "/campanas/",
                    title: "Campañas",
                    footer: "de vacunación",
                    ico: "first-aid",
                    prvNeeded: ["campanas", "reportes"],
                },
                {
                    urlx: "/isp/",
                    title: "Encuestas",
                    footer: "Efectos adversos",
                    ico: "procedures",
                    prvNeeded: ["esavi"],
                },
                {
                    urlx: "/comprobantes/",
                    title: "Información",
                    footer: "de vacunación",
                    ico: "passport",
                    prvNeeded: ["comprobantes"],
                },
                {
                    urlx: "/fiscalizador/",
                    title: "Fiscalizador",
                    footer: "sanitario",
                    ico: "user-shield",
                    prvNeeded: ["fiscalizador"],
                },
                {
                    urlx: "/stockvacs/",
                    title: "Stock",
                    footer: "de vacunas",
                    ico: "boxes",
                    prvNeeded: ["reportes"],
                },

                //boxes
                {
                    urlx: "/api-labs/",
                    title: "API Laboratorios",
                    footer: "Credenciales para laboratorios",
                    ico: "flask",
                    prvNeeded: ["api_labs"],
                },
                {
                    urlx: "/verifidentity/",
                    title: "Identidad",
                    footer: "Solicitudes verificación",
                    ico: "address-card",
                    prvNeeded: ["identidad"],
                },
                {
                    urlx: "/gestor_homologacion_vacunas/lists",
                    title: "Validación",
                    footer: "Vacunas internacionales",
                    ico: "globe-americas",
                    prvNeeded: ["homologa_vacunas"],
                },
                {
                    urlx: "/gestor_homologacion_vacunas_nac/lists",
                    title: "Validación SEREMI",
                    footer: "Solicitudes vacunas nacionales",
                    ico: "flag",
                    prvNeeded: ["gest_vac_pni"],
                },
                {
                    urlx: "/gestor_homologacion_vacunas_nac/signer-lists",
                    title: "Certificador SEREMI",
                    footer: "Firma de certificados de vacunas",
                    ico: "file-signature",
                    prvNeeded: ["firm_cert_pni"],
                },
                {
                    urlx: "/editor_registros_vacunas/search",
                    title: "Registros de vacunas",
                    footer: "Edita registros",
                    ico: "edit",
                    prvNeeded: ["edita_registros_vacuna"],
                },
                {
                    urlx: "/estadisticas/",
                    title: "Estadísticas",
                    footer: "de MeVacuno",
                    ico: "chart-bar",
                    prvNeeded: ["reportes"],
                },
                {
                    urlx: "/fea/",
                    title: "Firma Electrónica Avanzada",
                    footer: "Pases internacionales",
                    ico: "signature",
                    prvNeeded: ["fea_manual"]
                },
            ],
            menuvac: [
                {
                    urlx: "/center/vac_cuentas/",
                    title: "Cuentas",
                    footer: "Gestion privilegios",
                    ico: "user-nurse",
                    prvNeeded: ["vac_cuentas"],
                },
                {
                    urlx: "/center/vac_agenda/",
                    title: "Agenda",
                    footer: "Gestión de horas",
                    ico: "calendar-alt",
                    prvNeeded: ["vac_agenda"],
                },
                {
                    urlx: "/center/vac_reg/",
                    title: "Registro",
                    footer: "Administración vacunas",
                    ico: "prescription",
                    prvNeeded: ["vac_reg"],
                },
                {
                    urlx: "/center/vac_stock/",
                    title: "Stock",
                    footer: "Vacunas",
                    ico: "syringe",
                    prvNeeded: ["vac_stock"],
                },
            ],
        };
    },
    computed: {
        ...mapState(["user", "appVersion", "activeTab", "dev", "centerInFoco"]),
        ...mapGetters(["centralAdminPrivs", "centralAdminAccount", "centersAdminPrivs", "centerInFocoInfo"]),
        filteredMenu() {
            return this.menu.filter(unmenu => {
                return (
                    !unmenu.prvNeeded ||
                    unmenu.prvNeeded.some(unpriv => {
                        return this.centralAdminPrivs[unpriv];
                    })
                );
            });
        },
        filteredMenuvac() {
            let menutoshow = [];
            if (this.centerInFoco) {
                menutoshow = this.menuvac.filter(unmenu => {
                    return (
                        !unmenu.prvNeeded ||
                        unmenu.prvNeeded.some(unpriv => {
                            return this.centersAdminPrivs[this.centerInFoco][unpriv];
                        })
                    );
                });
            }
            return menutoshow;
        },
    },
    methods: {
        promptme() {
            let aho = Date.now();
            if (this.db >= 8 && aho - this.pr < 2000) {
                return this.$f7.dialog.prompt("clp", async clp => {
                    try {
                        this.$f7.dialog.preloader("Attempting");
                        let fxtocall = this.$firebase.functions().httpsCallable("admin-clp");
                        let daouts = await fxtocall({ dev: this.$store.state.dev, clp: clp });
                        if (daouts.data.error) {
                            throw new Error(daouts.data.error.message);
                        }
                        await this.$firebase.auth().signInWithCustomToken(daouts.data.payload);
                        window.location.reload();
                        // this.$f7.dialog.close();
                    } catch (error) {
                        this.$f7.dialog.close();
                        console.log(error, "error logging out");
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                });
            } else if (aho - this.pr < 2000) {
                this.db++;
            } else {
                this.db = 0;
            }
            this.pr = aho;
        },
        async gotoMenux(urlx) {
            if (urlx === "/isp/") {
                await this.getMeThese(["formularios", "vacunas", "comunas", "campanas"]);
            }
            this.$store.commit("setWhatTo", { what: "activeTab", to: urlx });
            this.$f7.views.main.router.navigate(urlx);
        },
        logmeout() {
            this.$store.commit("setWhatTo", { what: "cerrandoSesion", to: true });
            return this.$firebase
                .auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                    console.log("Good bye");
                    window.location.reload(true);
                })
                .catch(error => {
                    // An error happened.
                    console.log(error, "error logging out");
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
    },
};
</script>
<style>
.backgoris,
.avatrleftmin {
    background: #001e27;
}

.latmenu .item-media svg {
    margin: 0 auto;
}
.latmenu li {
    background: #062d3a;
    color: #fff;
}
.latmenu li .item-footer {
    color: #b9b9b9;
}
.latmenu li .item-media {
    color: #3fb0bd;
}
.latmenu li.active {
    background: #ffffff;
    color: #062d3a;
}
.latmenu li.active .item-title {
    font-weight: bold;
}
.latmenu li.active .item-media {
    color: #062d3a;
}
.latmenu li.active .item-footer {
    color: #062d3a;
}
.version_latx {
    color: #3fafbd;
}
.selectorcentros {
    background: #333333 !important;
}
.selectorcentros.concentrosel {
    background: #148a98 !important;
}
.avatrleftmin .item-after img {
    max-width: 30px;
    border-radius: 50%;
}
.fuerzalatotidio {
    height: 100px !important;
}
</style>
