 <template>
 <f7-list-item
        :after="!included.length?'Inlcuir sólo a este(os) país(es)':'Agregar pais a incluir'"
        :title="!included.length?'Todas las nacionalidades':null"
        smart-select
        :smart-select-params="{
            searchbar: {removeDiacritics: true},
            searchbarPlaceholder: 'Busque pais',
            closeOnSelect: true,
            setValueText: false,
            pageTitle: 'Seleccione país a incluir',
            virtualList: true
        }"
    >
        <select @change="eligiendoPais($event.target.value)" name="pais">
            <option value="">Seleccione País</option>
                    <option
                        v-for="pais in paises"
                        :key="pais.iso2"
                        :value="pais.iso2"
                    >
                        {{ pais.name}}
                    </option>
        </select>
    </f7-list-item>
</template>
<script>
import { mapState } from 'vuex';
export default {
    props:['included'],
    data() {
      return {

      }
    },
    computed:{
        ...mapState(['paises']),
    },
    methods:{
         eligiendoPais(pais) {
            this.$emit('nuevopais',pais)
        },
    }
}


</script>