<template>
    <f7-popup :opened="opened" @popup:closed="$emit('popup-closed')">
        <f7-page>
            <f7-navbar title="Seleccione un paciente">
                <f7-nav-right>
                    <f7-link popup-close>Cancelar</f7-link>
                </f7-nav-right>
            </f7-navbar>
            <f7-list media-list>
                <f7-list-item
                    media-item
                    v-for="patient in patients"
                    :key="patient.id"
                    :title="`${patient.apellido1 || ''} ${patient.apellido2 || ''}, ${patient.nombres || ''}`"
                    :subtitle="patient.id"
                >
                    <div slot="after">
                        <f7-button @click="$emit('patient-selected', patient)">Seleccionar</f7-button>
                    </div>
                </f7-list-item>
            </f7-list>
        </f7-page>
    </f7-popup>
</template>

<script>
export default {
    props: {
        opened: Boolean,
        patients: Array,
    },
};
</script>
