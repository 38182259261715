<template>
  <f7-page name="vacunas"         @page:beforein="prefetchDictios()">
      <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title
                title="Gestor Vacunas"
                subtitle="Mantenedor central diccionarios de vacunas"
            ></f7-nav-title>
            <f7-subnavbar :inner="false">
                    <f7-searchbar
                        class="no-margin"
                        search-container=".listovacunas"
                        search-in=".item-title,.item-text"
                        placeholder="Busque nombre vacuna o enfermedad"
                        :backdrop="false"
                    ></f7-searchbar>
         </f7-subnavbar>
        </f7-navbar>


    <f7-list class="no-margin-vertical searchbar-not-found">
        <f7-list-item title="Sin resultados"></f7-list-item>
    </f7-list>
    <f7-list class="no-margin-vertical listovacunas" media-list>
        <f7-list-item v-if="cargandoDictios" title="Cargando vacunas...">
            <f7-preloader slot="after"></f7-preloader>
        </f7-list-item>
        <f7-list-item
            v-else-if="!vacunas.length"
            title="No existen vacunas creadas,"
        ></f7-list-item>
        <f7-list-item
            v-for="vacuna in vacunas"
            :key="vacuna.vacunaPcId"
            :title="`${vacuna.vacunaPcName} (${vacuna.laboratorioName}) `"
            link="#"
            @click="gotoVacuna(vacuna)"
            :subtitle="vacuna.presentacion"
            :text="printEnfermedades(vacuna.cobertura_detai)"
        >
        <f7-chip outline :text="vacuna.borrador?'Borrador':'Publicada'" :color="vacuna.borrador?'red':'teal'" slot="after"></f7-chip>
        </f7-list-item>
    </f7-list>


<f7-fab v-if="!cargandoDictios" @click="modalCreaVacunaOpened = true" position="right-bottom" slot="fixed" color="teal">
    <f7-icon material="add"></f7-icon>
  </f7-fab>

   <f7-popup class="crea-vac" :close-by-backdrop-click="false" :opened="modalCreaVacunaOpened" @popup:closed="modalCreaVacunaOpened = false">
     <crea-vacuna v-if="modalCreaVacunaOpened" @abort="modalCreaVacunaOpened = false" @creado="creadoOk"></crea-vacuna>
</f7-popup>

  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import creaVacuna from './vacuna_crea.vue';

export default {
      components: { creaVacuna },
    data() {
      return {
       cargandoDictios: false,
       modalCreaVacunaOpened: false
      }
    },
    computed:{
     ...mapGetters(['vacunas','laboratorios']),
    },
    methods:{
        creadoOk(nocreaciones){
       // console.log('Llegao nueo vacuna',nocreaciones);
        this.modalCreaVacunaOpened = false;
        if(nocreaciones.nuevoLab){
            this.$store.commit('updtObjProperty',{what:'laboratorios',to: nocreaciones.nuevoLab});
        }
        nocreaciones.nuevaVac.id=nocreaciones.nuevaVac.vacunaPcId;
        this.$store.commit('updtObjProperty',{what:'vacunas',to: nocreaciones.nuevaVac});
        this.gotoVacuna(nocreaciones.nuevaVac);
      },
       gotoVacuna(neovacuna){
        this.$f7.views.main.router.navigate('/vacuna/'+neovacuna.vacunaPcId)
      },
       printEnfermedades(details = {}) {
         let arayados=Object.values(details);
            return arayados
                .map((un) => {
                    return `${un.enf} - ${un.nm}`;
                })
                .join(" | ");
        },
      prefetchDictios(){
        if (this.vacunas.length === 0 || this.laboratorios.length === 0) {
          this.cargandoDictios = true;
          let whatToGET = [
                    this.$firebase.database().ref("/vacunas").once("value"),
                    this.$firebase.database().ref("/laboratorios").once("value"),
                    this.$firebase.database().ref("/patogenos").once("value")
                ];
               return Promise.all(whatToGET).then(multisnaps=>{
                    this.$store.commit("setObjTo", {what:'vacunas', to: multisnaps[0].val() || {}});
                    this.$store.commit("setObjTo", {what:'laboratorios', to: multisnaps[1].val() || {}});
                    this.$store.commit("setObjTo", {what:'patogenos', to: multisnaps[2].val() || {}});
                    this.cargandoDictios = false;
                }).catch(error=>{
                   this.cargandoDictios = false;
                  console.error(error);
                  this.$f7.dialog.alert(error.message, error.code);
                })
      }
      }
    }
}
</script>
<style>


</style>