<template>
    <f7-popup :opened="opened" @popup:opened="handlePopupOpened" @popup:closed="$emit('closed')">
        <f7-page>
            <f7-navbar title="Crear nuevo rol">
                <f7-nav-right>
                    <f7-link @click="$emit('closed')">Cancelar</f7-link>
                </f7-nav-right>
            </f7-navbar>
            <f7-block>
                <f7-block-title>Información del rol</f7-block-title>
                <f7-list no-hairlines-md>
                    <f7-list-input
                        type="select"
                        label="Tipo de rol (privilegios variarán según el tipo de rol)"
                        :value="role.type"
                        @input="handleRoleTypeChanged($event.target.value)"
                        placeholder="Tipo de rol"
                    >
                        <option value="central">Central</option>
                        <option value="regional">Regional</option>
                    </f7-list-input>
                    <f7-list-input label="Nombre" type="text" placeholder="Nombre del rol" :value="role.name" @input="role.name = $event.target.value"></f7-list-input>
                    <f7-list-input label="Descripción" type="text" placeholder="Descripción del rol" :value="role.description" @input="role.description = $event.target.value"></f7-list-input>
                    <f7-list-item>
                        <span class="text-color-gray">Activo</span>
                        <f7-toggle :checked="role.isActive" @toggle:change="role.isActive = $event"></f7-toggle>
                    </f7-list-item>
                </f7-list>

                <f7-block-title class="no-margin-horizontal">Privilegios</f7-block-title>
                <f7-list>
                    <f7-list-item v-for="privilege in privsByRoleType" :key="privilege.id" media-item>
                        <span slot="title">{{ privilege.id }}</span>
                        <span slot="footer">{{ privilege.descr }}</span>
                        <f7-checkbox
                            slot="after"
                            :checked="role.privileges[privilege.id]"
                            @change="handlePrivilegeCheckboxChanged(privilege.id, $event.target.checked)"
                        ></f7-checkbox>
                    </f7-list-item>
                </f7-list>

                <f7-button v-if="editingRole" fill @click="edit">Guardar cambios</f7-button>
                <f7-button v-else fill @click="create">Crear</f7-button>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { commonmixin } from "../mixins/common";
export default {
    props: {
        editingRole: {
            type: Object,
            required: false,
        },
        opened: {
            type: Boolean,
            default: false,
        },
    },

    mixins: [commonmixin],

    data() {
        return {
            role: {
                id: null,
                name: "",
                description: "",
                isActive: true,
                privileges: {},
                type: "central",
            },
        };
    },

    computed: {
        privsByRoleType() {
            if (this.role.type === "central") return Object.values(this.$store.state.privsCentrales || {}).filter(p => p.central === true);
            else if (this.role.type === "regional") return Object.values(this.$store.state.privsCentrales || {}).filter(p => p.regional === true);
            else return [];
        },
    },

    methods: {
        async create() {
            console.log("Creating role", this.role);
            try {
                this.$f7.dialog.preloader("Creando rol...");

                const response = await this.commonExecute({ role: this.role }, "admin-createRole", false);

                if (response.error) {
                    throw new Error(response.error);
                }

                this.resetForm();
                this.$emit('created', response.role);

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },

        async edit() {
            this.$f7.dialog.confirm("Al modificar privilegios de un rol, todos los usuarios que poseen este rol se verán afectados al cambio, modificando sus privilegios automáticamente. ¿Está seguro?", "CUIDADO: Acción crítica", async () => {
                try {
                    this.$f7.dialog.preloader("Editando rol...");

                    const response = await this.commonExecute({ role: this.role }, "admin-editRole", false);

                    if (response.error) {
                        throw new Error(response.error);
                    }

                    this.resetForm();
                    this.$emit('edited', response.role);

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            });
        },

        handlePopupOpened() {
            if (this.editingRole) {
                console.log("Está editando un rol", this.editingRole);
                this.$set(this.role, "id", this.editingRole.id);
                this.$set(this.role, "name", this.editingRole.name);
                this.$set(this.role, "description", this.editingRole.description);
                this.$set(this.role, "isActive", this.editingRole.isActive);
                this.$set(this.role, "privileges", this.editingRole.privileges);
                this.$set(this.role, "type", this.editingRole.type);
            } else {
                this.resetForm();
            }
        },

        handlePrivilegeCheckboxChanged(privilegeId, checked) {
            if (checked === true) this.$set(this.role.privileges, privilegeId, true);
            else this.$delete(this.role.privileges, privilegeId);
        },

        handleRoleTypeChanged(roleType) {
            this.$set(this.role, "type", roleType);
            if (roleType === "central") this.removeAllPrivilegesFromType("regional");
            else if (roleType === "regional") this.removeAllPrivilegesFromType("central");
        },

        removeAllPrivilegesFromType(type) {
            Object.keys(this.role.privileges).forEach(privilegeId => {
                if (this.$store.state.privsCentrales[privilegeId][type] === true) {
                    this.$delete(this.role.privileges, privilegeId);
                }
            });
        },

        resetForm() {
            this.role = {
                name: "",
                description: "",
                isActive: true,
                privileges: {},
                type: "central",
            };
        },
    },
};
</script>
