<template>
    <f7-app :params="f7params">
        <!-- Left panel with cover effect when hidden -->
        <f7-panel left cover theme-dark :visible-breakpoint="960">
            <f7-view>
                <lepanel></lepanel>
            </f7-view>
        </f7-panel>

        <!-- Your main view, should have "view-main" class -->
        <f7-view main class="safe-areas" url="/"></f7-view>

        <login :loginOpened="loginOpened" :dev="dev" :loading="loading"></login>
    </f7-app>
</template>
<script>
import login from "./login.vue";
import lepanel from "./lepanel.vue";
import { mapState } from "vuex";
import routes from "../js/routes.js";
import idleTimer from "idle-timer";

export default {
    components: {
        login,
        lepanel,
    },
    data() {
        return {
            // Framework7 Parameters
            f7params: {
                name: "Vacunator.io", // App name
                theme: "md", // Automatic theme detection
                // App routes
                routes: routes,
                dialog: {
                    // change default "OK" button text
                    buttonCancel: "Cancelar",
                },
            },
            // Login screen data
            loginOpened: true,
            loading: true,
            inactiveTime: null,
        };
    },
    computed: {
        ...mapState(["dev"]),
    },
    methods: {
        gotoMenux(urlx) {
            this.$store.commit("setWhatTo", { what: "activeTab", to: urlx });
            this.$f7.views.main.router.navigate(urlx);
        },
        logmeout() {
            this.loading = false;
            this.$store.commit("setWhatTo", { what: "cerrandoSesion", to: true });
            return this.$firebase
                .auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                    console.log("Good bye");
                    window.location.reload(true);
                })
                .catch(error => {
                    // An error happened.
                    console.log(error, "error logging out");
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
        evaluaCallback() {
            let url = new URL(window.location.href);
            let j = url.searchParams.get("j");
            let k = url.searchParams.get("k");
            let l = url.searchParams.get("l");
            if (j && k && l) {
                this.passpromtepr(j, k, l);
            }
        },
        passpromtepr(j, k, l) {
            return this.$f7.dialog.prompt("Indique su nueva contraseña para vacunator.io", "Nueva contraseña", neopass => {
                if (!neopass) {
                    return this.$f7.dialog.alert("Contraseña no puede ser vacía", () => {
                        return this.passpromtepr(j, k, l);
                    });
                } else {
                    return this.attemptRcov(j, k, l, neopass);
                }
            });
        },
        async attemptRcov(j, k, l, pass) {
            try {
                this.$f7.dialog.preloader("Estableciendo nueva contraseña");
                window.history.replaceState({}, document.title, "/");
                //iniciar solicitud verificacionidenidad
                let attemptRecov = this.$firebase.functions().httpsCallable("mevacuno-attemptRecov");
                let result = await attemptRecov({ dev: this.$store.state.dev, j, k, l, pass });
                let daouts = result.data;
                if (daouts.error) {
                    throw new Error(daouts.error.message);
                }
                await this.$firebase.auth().signInWithCustomToken(daouts.payload);
                this.$f7.toast.create({
                    text: "Nueva contraseña establecida!",
                    position: "center",
                    closeTimeout: 2000,
                });
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error", () => {
                    this.passpromtepr(j, k, l);
                });
            }
        },
        async wearUserandGo(user) {
            await user.reload();
            await user.getIdToken(true);
            //antes de cualquier cosa, ver si es un user de pass y no tiene email verificado
            if (!user.emailVerified) {
                return user
                    .sendEmailVerification()
                    .then(() => {
                        this.$f7.dialog.close();
                        this.$store.commit("setWhatTo", { what: "loggingin", to: false });
                        this.$firebase.auth().signOut();
                        return this.$f7.dialog.alert(
                            "Revise su bandeja de entrada en " + user.email + " para verificar su cuenta antes de poder ingresar.",
                            "Verificar"
                        );
                    })
                    .catch(error => {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            }

            //PRIVSADMIN y POR VACUNATORIO
            let promsex = [
                this.$firebase
                    .database()
                    .ref("privx_admn/" + user.uid)
                    .once("value"),
                this.$firebase
                    .database()
                    .ref("privsByCenter")
                    .orderByChild("uid")
                    .equalTo(user.uid)
                    .once("value"),
            ];
            return Promise.all(promsex)
                .then(multinsaps => {
                    this.$f7.dialog.close();

                    const privx_admn = multinsaps[0].val();

                    if (privx_admn.inactive) {
                        throw new Error("Su usuario fue inactivado. Por favor contacte a un administrador.");
                    }

                    if (!this.userHasAtLeastOnePrivilege(privx_admn)) {
                        throw new Error("Su usuario no tiene privilegios asignados. Por favor contacte a un administrador.");
                    }

                    // User is signed in.
                    let locluser = {
                        displayName: user.displayName,
                        email: user.email,
                        emailVerified: user.emailVerified,
                        photoURL: user.photoURL,
                        phoneNumber: user.phoneNumber,
                        isAnonymous: user.isAnonymous,
                        uid: user.uid,
                        providerData: user.providerData,
                        admn: multinsaps[0].val() || {},
                        centers: {},
                    };
                    if (multinsaps[1].exists()) {
                        multinsaps[1].forEach(childnsap => {
                            let unperm = childnsap.val();
                            if (unperm.prv && Object.values(unperm.prv).length) {
                                locluser.centers[unperm.cid] = unperm.prv;
                            }
                        });
                    }
                    //console.log("user", user);

                    this.$store.commit("setObjTo", { what: "user", to: locluser });
                    let sunamobr = user.displayName;
                    if (locluser.admn) {
                       // console.log("hay admin info", locluser.admn);
                        sunamobr = `${locluser.admn.nms} ${locluser.admn.ap1}`;
                    }
                    if (window.tidioChatApi) {
                        window.tidioChatApi.setVisitorData({
                            email: user.email,
                            name: sunamobr,
                            distinct_id: user.uid,
                        });
                    } else {
                        //cargar info del usuario para tidio
                        document.tidioIdentify = {
                            distinct_id: user.uid, // Unique user ID in your system
                            email: user.email, // User email
                            name: sunamobr, // Visitor name
                        };
                    }

                   // console.log("user", locluser);
                    if (!this.$store.getters.centralAdminAccount || !Object.values(this.$store.getters.centralAdminAccount).length) {
                        this.loading = false;
                        return this.$f7.dialog.alert("Su usuario no tiene privilegios de admin", "No autorizado", () => {
                           // console.log("post click");
                            return this.logmeout();
                        });
                    } else {
                        let susprivxadmin = this.$store.getters.centralAdminPrivs;
                        let susprivs = Object.keys(susprivxadmin);
                        let vaccenterIdsDondeAdmin = Object.keys(locluser.centers) || [];

                        if (susprivs.length) {
                            //tiene privs CENTRALES
                            //trata de llevarlo a cuentas
                            if (susprivxadmin.cuentas) {
                                this.gotoMenux("/accounts/");
                            } else {
                                let susprivs = Object.keys(susprivxadmin);
                                let unpriv = susprivs[0];
                                let target = unpriv == "reportes" ? "campanas" : unpriv;
                                if (unpriv == 'homologa_vacunas') {
                                    target = 'gestor_homologacion_vacunas/lists'
                                }
                                if(unpriv == 'gest_vac_pni'){
                                    target = 'gestor_homologacion_vacunas_nac/lists'
                                }
                                if(unpriv == 'firm_cert_pni'){
                                    target = 'gestor_homologacion_vacunas_nac/signer-lists'
                                }
                                this.gotoMenux("/" + target + "/");
                            }
                        } else if (vaccenterIdsDondeAdmin.length) {
                            //tomar el primero
                            let uncentroid = vaccenterIdsDondeAdmin[0];
                            //centerInFoco
                            this.$store.commit("setWhatTo", { what: "centerInFoco", to: uncentroid });

                            //hay que llamar al centroinfo, por que es primera carga
                            this.cargaInfoCentr(uncentroid);
                            let susprivsahi = locluser.centers[uncentroid] || {};
                            let privsarrayados = Object.keys(susprivsahi);
                            if (susprivsahi.vac_cuentas) {
                                this.gotoMenux("/center/vac_cuentas/");
                            } else {
                                this.gotoMenux("/center/" + privsarrayados[0] + "/");
                            }
                        } else {
                            this.loading = false;
                        }
                    }
                    this.loginOpened = false;
                    // this.loading=false;
                    // this.$store.commit('setWhatTo',{what:'loggingin', to: false});
                    //this.loadTidio();
                })
                .catch(error => {
                    console.log(error);
                    this.loading = false;
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
        cargaInfoCentr(vaccenterid) {
            return this.$firebase
                .database()
                .ref("vacunatorios/" + vaccenterid)
                .once("value")
                .then(snpsht => {
                    console.log("llegando info vacuntaorio");
                    let vacunatinfo = snpsht.val();
                    this.$store.commit("updtObjProperty", { what: "vacunatorios", to: vacunatinfo });
                })
                .catch(error => {
                    console.log("error de retrieval de vacunatorio", error);
                });
        },
        loadTidio() {
            if (!window.tidioChatApi) {
                let tidioScript = document.createElement("script");
                tidioScript.src = "//code.tidio.co/g8mwujznkdvcy2xermhmtbpnh9lo3euw.js";
                document.body.appendChild(tidioScript);
            }
        },
        async gestionSignIn() {
            await this.$firebase.auth().setPersistence(this.$firebase.auth.Auth.Persistence.SESSION);
            this.reseteam();
        },
        reseteam() {
            this.inactiveTime = idleTimer({
                // function to fire after idle
                callback: this.logmeout,
                // function to fire when active
                activeCallback: () => {
                    console.log("-");
                },
                // Amount of time in milliseconds before becoming idle. default 60000
                idleTime: 1800000,
            });
        },
        userHasAtLeastOnePrivilege(privx) {
            return Object.keys(privx.prv || {}).length > 0;
        },
    },
    mounted() {
        this.$f7ready(f7 => {
            
            this.$firebase.auth().onAuthStateChanged(user => {
                
                if (user) {
                    this.gestionSignIn();
                    this.wearUserandGo(user);
                } else {
                    this.loginOpened = true;
                    this.$store.commit("setObjTo", { what: "user", to: {} });
                    this.loading = false;
                    this.evaluaCallback();
                    if (this.inactiveTime) {
                        this.inactiveTime.destroy();
                    }
                    // this.loadTidio();
                }
            });
        });
    },
};
</script>

<style></style>
