import Vue from "vue";

export default {
    namespaced: true,

    state: {
        SOLICITUDES_FETCH_SIZE: 20,
        SOLICITUDES_LOAD_MORE_FETCH_SIZE: 20,

        motivosRechazo: [],

        currentSolicitud: null,

        isLoading: false,
        allSolicitudes: {},

        observableEnEsperaNac: null,
        observableEnRevisionNac: null,
        observableEnConflictoNac: null,
        observableEnEsperaFirmaNac: null,
        observableFirmandoNac: null,
        observableApostillamientoNac: null,
        observableApostillandoNac: null,

        enEsperaFilter: "all",

        enEsperaFirmaFilter: "all",

        acabaDeRechazarIdentidad: null,

        vacunasHomologacion: {},
    },

    getters: {
        solicitudesEnEspera: state => {
            const solicitudesEnEspera = Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "En espera")
                .sort((a, b) => a.wh - b.wh);

            if (state.enEsperaFilter === "all") {
                return solicitudesEnEspera;
            } else if (state.enEsperaFilter === "no_verified") {
                return solicitudesEnEspera.filter(s => s.pat && !s.pat.verified);
            } else if (state.enEsperaFilter === "verified") {
                return solicitudesEnEspera.filter(s => s.pat && s.pat.verified);
            }

            return solicitudesEnEspera;
        },
        solicitudesEnEsperaFirma: state => {
            const solicitudesEnEsperaFirma =  Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "Aceptada" && s.cert && s.cert.status === "pending")
                .sort((a, b) => a.wh - b.wh);

            if (state.enEsperaFirmaFilter === "all") {
                return solicitudesEnEsperaFirma;
            } else if (state.enEsperaFirmaFilter === "con_apost") {
                return solicitudesEnEsperaFirma.filter(s => s.reqApos);
            } else if (state.enEsperaFirmaFilter === "sin_apost") {
                return solicitudesEnEsperaFirma.filter(s => !s.reqApos);
            }
        },
        solicitudesEnEsperaApostilla: state => {
            return Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "Aceptada" && s.cert && s.cert.status === "approved" && s.actApos && s.actApos.status === "pending")
                .sort((a, b) => a.wh - b.wh);
        },
        solicitudesFirmando: state => {
            return Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "Aceptada" && s.cert && s.cert.status === "signing")
                .sort((a, b) => a.wh - b.wh);
        },
        solicitudesApostillando: state => {
            return Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "Aceptada" && s.cert && s.cert.status === "approved" && s.actApos && s.actApos.status === "reviewing")
                .sort((a, b) => a.wh - b.wh);
        },
        solicitudesEnRevision: state => {
            return Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "En revisión")
                .sort((a, b) => a.wh - b.wh);
        },
        solicitudesEnConflicto: state => {
            return Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "En conflicto")
                .sort((a, b) => a.wh - b.wh);
        },
        sortedVacunasHomologacion: state => {
            return Object.values(state.vacunasHomologacion).sort((a, b) => a.vacunaPcName.localeCompare(b.vacunaPcName));
        },
    },

    mutations: {
        set_is_loading(state, isLoading) {
            state.isLoading = isLoading;
        },

        set_observable(state, payload) {
            const { firestore, refetch, colaType, dev, regiones } = payload;

            let observableByColaType;

            if (colaType === "En espera") {
                observableByColaType = "observableEnEsperaNac";
            } else if (colaType === "En revisión") {
                observableByColaType = "observableEnRevisionNac";
            } else if (colaType === "En conflicto") {
                observableByColaType = "observableEnConflictoNac";
            } else if (colaType === "Por firmar") {
                observableByColaType = "observableEnEsperaFirmaNac";
            } else if (colaType === "Firmando") {
                observableByColaType = "observableFirmandoNac";
            } else if (colaType === "Por apostillar") {
                observableByColaType = "observableApostillamientoNac";
            } else if (colaType === "Apostillando") {
                observableByColaType = "observableApostillandoNac";
            }

            if (refetch) {
                state.allSolicitudes = {};
            }

            let collection = dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";

            console.log("seteando observable coleccion", collection);

            let query = firestore.collection(collection);

            if (colaType === "En espera") {
                query = query.where("est", "==", "En espera");
            } else if (colaType === "En revisión") {
                query = query.where("est", "==", "En revisión");
            } else if (colaType === "En conflicto") {
                query = query.where("est", "==", "En conflicto");
            } else if (colaType === "Por firmar") {
                query = query.where("est", "==", "Aceptada")
                    .where("cert.status", "==", "pending")
            } else if (colaType === "Firmando") {
                query = query.where("est", "==", "Aceptada")
                    .where("cert.status", "==", "signing");
            } else if (colaType === "Por apostillar") {
                query = query.where("est", "==", "Aceptada")
                    .where("cert.status", "==", "approved")
                    .where("actApos.status", "==", "pending");
            } else if (colaType === "Apostillando") {
                query = query.where("est", "==", "Aceptada")
                    .where("cert.status", "==", "approved")
                    .where("actApos.status", "==", "reviewing");
            }

            if (!regiones.includes("todas"))
                query = query.where("address.region", "in", regiones);
       

            query = query.orderBy("wh", "asc").limit(state.SOLICITUDES_FETCH_SIZE);

            state[observableByColaType] = query.onSnapshot(
                querySnapshot => {
                    console.log("observable query snapshot");

                    querySnapshot.forEach(doc => {
                        Vue.set(state.allSolicitudes, doc.id, doc.data());

                        console.log("nueva solicitud", doc.id);
                    });
                },
                error => {
                    console.error(error);
                }
            );
        },

        unset_observable(state, payload) {
            const { selectedCola } = payload;

            let observableByColaType;

            if (selectedCola === "En espera") {
                observableByColaType = "observableEnEsperaNac";
            } else if (selectedCola === "En revisión") {
                observableByColaType = "observableEnRevisionNac";
            } else if (selectedCola === "En conflicto") {
                observableByColaType = "observableEnConflictoNac";
            } else if (selectedCola === "Por firmar") {
                observableByColaType = "observableEnEsperaFirmaNac";
            } else if (colaType === "Firmando") {
                observableByColaType = "observableFirmandoNac";
            } else if (colaType === "Por apostillar") {
                observableByColaType = "observableApostillamientoNac";
            } else if (colaType === "Apostillando") {
                observableByColaType = "observableApostillandoNac";
            }

            if (state[observableByColaType]) {
                state[observableByColaType]();
            }
        },

        load_more_solicitudes_by_type(state, payload) {
            const { firestore, colaType, dev, regiones } = payload;

            let collection = dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";

            console.log("load more desde coleccion", collection);

            let query = firestore.collection(collection);

            if (colaType === "En espera") {
                query = query.where("est", "==", "En espera");
            } else if (colaType === "En revisión") {
                query = query.where("est", "==", "En revisión");
            } else if (colaType === "En conflicto") {
                query = query.where("est", "==", "En conflicto");
            } else if (colaType === "Por firmar") {
                query = query.where("est", "==", "Aceptada")
                    .where("cert.status", "==", "pending");
            } else if (colaType === "Firmando") {
                query = query.where("est", "==", "Aceptada")
                    .where("cert.status", "==", "signing");
            } else if (colaType === "Por apostillar") {
                query = query.where("est", "==", "Aceptada")
                    .where("cert.status", "==", "approved")
                    .where("actApos.status", "==", "pending");
            } else if (colaType === "Apostillando") {
                query = query.where("est", "==", "Aceptada")
                    .where("cert.status", "==", "approved")
                    .where("actApos.status", "==", "reviewing");
            }
  
            if (!regiones.includes("todas"))
                query = query.where("address.region", "in", regiones);
            

            // la ultima solicitud de la lista que estas viendo, a partir de esa se fetchean mas
            const oldestSolicitudByType = Object.values(state.allSolicitudes || {})
                .filter(s => {
                    if(colaType === "En espera" || colaType === "En revisión" || colaType === "En conflicto")
                        return s.est === colaType;
                    else if(colaType === "Por firmar")
                        return s.est === "Aceptada" && s.cert && s.cert.status === "pending";
                    else if(colaType === "Firmando")
                        return s.est === "Aceptada" && s.cert && s.cert.status === "signing";
                    else if(colaType === "Por apostillar")
                        return s.est === "Aceptada" && s.cert && s.cert.status === "approved" && s.actApos && s.actApos.status === "pending";
                    else if(colaType === "Apostillando")
                        return s.est === "Aceptada" && s.cert && s.cert.status === "approved" && s.actApos && s.actApos.status === "reviewing";
                })
                .sort((a, b) => a.wh - b.wh)
                .pop();

            query = query.orderBy("wh", "asc");

            if (oldestSolicitudByType) {
                query = query.startAfter(oldestSolicitudByType.wh);
            }

            query = query.limit(state.SOLICITUDES_LOAD_MORE_FETCH_SIZE);

            query.get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    Vue.set(state.allSolicitudes, doc.id, doc.data());
                });
            });
        },

        unset_all_observables(state) {
            const observables = ["observableEnEsperaNac", "observableEnRevisionNac", "observableEnConflictoNac"];

            observables.forEach(observable => {
                if (state[observable]) {
                    state[observable]();

                    state[observable] = null;
                }
            });

            state.allSolicitudes = {};
        },

        unset_all_observables_firma(state) {
            const observables = ["observableEnEsperaFirmaNac", "observableFirmandoNac", "observableApostillamientoNac", "observableApostillandoNac"];

            observables.forEach(observable => {
                if (state[observable]) {
                    state[observable]();

                    state[observable] = null;
                }
            });

            state.allSolicitudes = {};
        },

        set_current_solicitud(state, solicitudId) {
            if (solicitudId === null) {
                state.currentSolicitud = null;
            }

            const solicitud = state.allSolicitudes[solicitudId];

            if (solicitud) {
                state.currentSolicitud = solicitud;
            }
        },
        set_current_solicitud_object(state, solicitud) {
            state.currentSolicitud = solicitud;
        },

        update_solicitud(state, payload) {
            const { solicitudId, update } = payload;

            const updatedSolicitud = { ...state.allSolicitudes[solicitudId], ...update };

            Vue.set(state.allSolicitudes, solicitudId, updatedSolicitud);
        },

        set_filter_lista_en_espera(state, filter) {
            state.enEsperaFilter = filter;
        },

        set_filter_lista_en_espera_firma(state, filter) {
            state.enEsperaFirmaFilter = filter;
        },

        set_motivos_rechazo(state, motivosRechazo) {
            state.motivosRechazo = motivosRechazo;
        },

        set_acaba_de_rechazar(state, decision) {
            state.acabaDeRechazarIdentidad = decision;
        },

        remove_solicitud(state, solicitudId) {
            Vue.delete(state.allSolicitudes, solicitudId);
        },

        set_vacunas_homologacion(state, vacunas) {
            state.vacunasHomologacion = vacunas;
        },

    },

    actions: {
        fetchSolicitudes({ commit }, payload) {
            const { selectedCola, firestore, refetch, dev, regiones } = payload;

            commit("set_is_loading", true);

            commit("set_observable", { firestore, refetch, colaType: selectedCola, dev, regiones });

            commit("set_is_loading", false);
        },

        loadMoreSolicitudes({ commit }, payload) {
            const { selectedCola, firestore, dev, regiones } = payload;

            commit("set_is_loading", true);

            commit("load_more_solicitudes_by_type", { firestore, colaType: selectedCola, dev, regiones });

            commit("set_is_loading", false);
        }
    },
};
