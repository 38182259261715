<template>
<f7-view>
       <f7-page>
        <f7-navbar title="Asignar privilegios en un vacunatorio">
          <f7-nav-right>
            <f7-link popup-close>Cancelar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list class="no-margin-top">
              <f7-list-item divider title="1.- Seleccione vacunatorio"></f7-list-item>
          <f7-list-item title="Vacunatorio" 
          smart-select 
          :smart-select-params="{ searchbar: {removeDiacritics: true}, closeOnSelect: true,
            virtualList: true, searchbarPlaceholder: 'Seleccione vacunatorio'}">
            <select @change="nuevoPriVacunat.vacunatorioId = $event.target.value" name="vacunatoriox">
                <option value="">Seleccione</option>
                <option   
                v-for="vacunatorio in vacunatoriosToShow"
                :key="vacunatorio.id"
                :value="vacunatorio.id">{{vacunatorio.name}} ({{vacunatorio.address.name}})</option>
            </select>
          </f7-list-item>
       
        <template v-if="nuevoPriVacunat.vacunatorioId">
          <f7-list-item divider title="2.- Indique privilegios en el vacunatorio"></f7-list-item>
          <f7-list-item
          v-for="unpriv in privsPorVacunatorio"
          :key="unpriv.id"
          :title="unpriv.id"
          :footer="unpriv.descr"
           >
          <f7-toggle slot="after" @toggle:change="creaPrivenVac($event,unpriv.id)" color="teal" :checked="nuevoPriVacunat.privs[unpriv.id]"></f7-toggle>
           </f7-list-item>
        </template>
         </f7-list>
         <f7-block>
           <f7-row>
             <f7-col><f7-button color="gray" popup-close large>Cancelar</f7-button></f7-col>
             <f7-col><f7-button :disabled="!Object.values(nuevoPriVacunat.privs).length || !Object.values(nuevoPriVacunat.privs).includes(true)" color="teal" @click="darprivilegios()" large raised fill>Otorgar Privilegios</f7-button></f7-col>
           </f7-row>
         </f7-block>
      </f7-page>
     </f7-view>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { commonmixin } from '../mixins/common'

export default {
  mixins: [commonmixin],
  props:['uid','susprivsbysite'],
    data() {
      return {
        nuevoPriVacunat:{
          vacunatorioId: null,
          privs:{}
        }
      }
    },
    computed:{
     ...mapState(['privsPorVacunatorio']),
     ...mapGetters(['vacunatorios']),
     vacunatoriosToShow(){
       return this.vacunatorios.filter(unvacunat=>{
         return !this.susprivsbysite[unvacunat.id]
       })
     },
    },
    methods:{

      async darprivilegios(){
         try {
            if(!this.nuevoPriVacunat.vacunatorioId){
              throw new Error('Es necesario una ID de vacunatorio')
            }
            let losprivsArrayed = Object.keys(this.nuevoPriVacunat.privs);
            if(!losprivsArrayed.length){
              throw new Error('Debe indicar al menos un privilegio')
            }
            this.$f7.dialog.preloader('Creando privilegios en vacunatorio');
            let datous = await this.commonExecute({uid:this.uid,array_cuales:losprivsArrayed,vacunatorioid:this.nuevoPriVacunat.vacunatorioId},'admin-creaAdmnEnVacunatorio',false);
            this.$f7.dialog.close();
            if(datous.error){
              throw new Error(datous.error.message);
            }
            this.$emit('nuevosPrivsVacs',datous.payload)
            this.avisaOk();
             } catch (error) {
               this.$f7.dialog.close();
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
      creaPrivenVac(nuevovalor,privid){
          this.$set(this.nuevoPriVacunat.privs, privid, nuevovalor);
      },
    }
}
</script>
<style>


</style>