<template>
    <f7-popup :opened="opened" @popup:opened="handlePopupOpened" @popup:closed="$emit('closed')">
        <f7-page>
            <f7-navbar title="Editando cuenta de usuario">
                <f7-nav-right>
                    <f7-link @click="$emit('closed')">Cancelar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="loading" class="text-align-center">
                <f7-preloader></f7-preloader>
            </f7-block>
            <template v-else-if="Object.keys(editingAccount || {}).length">
                <f7-block-title>Información de la cuenta</f7-block-title>
                <f7-list class="no-margin">
                    <f7-list-input
                        type="select"
                        placeholder="Tipo de cuenta"
                        label="Tipo de cuenta"
                        :value="editingAccount.regional === true ? 'regional' : 'central'"
                        @change="handleAccountTypeChanged($event.target.value)"
                    >
                        <option value="central">Central</option>
                        <option value="regional">Regional</option>
                    </f7-list-input>
                    <f7-list-item
                        class="select-region-item"
                        v-if="editingAccount.regional === true"
                        header="Seleccione la(s) región(es) a las que pertenece el usuario"
                        :title="editingAccount.regiones.length ? `${editingAccount.regiones.length} regiones seleccionadas` : 'Ninguna región seleccionada'"
                        after=""
                        smart-select
                        :smart-select-params="{
                            openIn: 'popup',
                            searchbar: { removeDiacritics: true },
                            searchbarPlaceholder: 'Buscar región',
                            routableModals: false,
                            closeOnSelect: false,
                            virtualList: true,
                            setValueText: false,
                        }"
                    >
                        <select multiple v-model="editingAccount.regiones">
                            <option v-for="region in regiones" :key="region.number" :value="region.id">
                                {{ `${region.name} (${region.number})` }}
                            </option>
                        </select>
                    </f7-list-item>
                    <f7-list-input
                        type="text"
                        placeholder="Juan"
                        label="Nombre"
                        :value="editingAccount.nms"
                        @input="editingAccount.nms = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="text"
                        placeholder="Pérez"
                        label="Apellido 1"
                        :value="editingAccount.ap1"
                        @input="editingAccount.ap1 = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="text"
                        placeholder="Contreras"
                        label="Apellido 2"
                        :value="editingAccount.ap2"
                        @input="editingAccount.ap2 = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="text"
                        placeholder="11.111.111-1"
                        label="RUN"
                        :value="editingAccount.id"
                        @input="editingAccount.id = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="text"
                        label="Correo electrónico"
                        placeholder="juan@example.com"
                        :value="editingAccount.em"
                        @input="editingAccount.em = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="select"
                        placeholder="Rol asignado (según tipo de cuenta)"
                        label="Rol asignado (según tipo de cuenta)"
                        :value="editingAccount.role"
                        @change="editingAccount.role = $event.target.value"
                    >
                        <option value="">Ninguno</option>
                        <option v-for="role in rolesByRoleType" :value="role.id" :key="role.id">{{ role.name }}</option>
                    </f7-list-input>
                    <f7-list-input
                        type="select"
                        placeholder="Estado de la cuenta"
                        label="Estado de la cuenta"
                        :value="editingAccount.inactive ? 'true' : ''"
                        @change="handleAccountInactiveChanged($event.target.value)"
                    >
                        <option value="">Activa</option>
                        <option value="true">Inactiva</option>
                    </f7-list-input>
                </f7-list>
                <f7-block-title>Privilegios (según rol asignado)</f7-block-title>
                <f7-list>
                    <f7-list-item v-for="privilege in privsByRoleType" :key="privilege.id" media-item>
                        <span slot="title">{{ privilege.id }}</span>
                        <span slot="footer">{{ privilege.descr }}</span>
                        <f7-checkbox
                            disabled
                            slot="after"
                            :checked="editingAccount.prv[privilege.id]"
                            @change="handlePrivilegeCheckboxChanged(privilege.id, $event.target.checked)"
                        ></f7-checkbox>
                    </f7-list-item>
                </f7-list>

                <f7-button @click="saveAccount" fill large class="margin">Guardar cambios</f7-button>
            </template>
        </f7-page>
    </f7-popup>
</template>

<script>
import { commonmixin } from "../mixins/common";
import { comunasHelper } from "@/js/comunas.js";
export default {
    mixins: [commonmixin, comunasHelper],
    props: {
        opened: {
            type: Boolean,
            default: false,
        },
        account: {
            required: false,
        },
    },
    data() {
        return {
            editingAccount: {
                uid: "",
                nms: "",
                ap1: "",
                ap2: "",
                id: "",
                em: "",
                inactive: null,
                role: "",
                prv: {},
                regional: false,
                regiones: [],
            },
            loading: true,
        };
    },
    computed: {
        regiones() {
            return comunasHelper.data().comunas.map(c => {
                return {
                    id: c.region_iso_3166_2,
                    name: c.region,
                    number: c.region_number,
                };
            }).filter(r => r.id);
        },
        privsByRoleType() {
            if (this.editingAccount.regional !== true) return Object.values(this.$store.state.privsCentrales || {}).filter(p => p.central === true);
            else if (this.editingAccount.regional === true) return Object.values(this.$store.state.privsCentrales || {}).filter(p => p.regional === true);
            else return [];
        },
        rolesByRoleType() {
            if (this.editingAccount.regional !== true) return this.$store.getters.activeRoles.filter(r => r.type === "central");
            else if (this.editingAccount.regional === true) return this.$store.getters.activeRoles.filter(r => r.type === "regional");
            else return [];
        },
    },
    methods: {
        async saveAccount() {
            try {
                this.$f7.dialog.preloader("Guardando cambios...");

                const response = await this.commonExecute({ account: this.editingAccount }, "admin-editAccount", false);

                if (response.error) {
                    throw new Error(response.error);
                }

                this.editingAccount = response.account;
                this.$emit("edited", response.account);

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, "Error");
            }
        },
        handlePopupOpened() {
            this.editingAccount = this.account;

            if (Object.keys(this.editingAccount.regiones || {}).length) {
                this.$set(this.editingAccount, "regiones", Object.keys(this.editingAccount.regiones));
            } else {
                this.$set(this.editingAccount, "regiones", []);
            }

            this.loading = false;
        },
        handlePrivilegeCheckboxChanged(privilegeId, checked) {
            if (checked === true) this.$set(this.editingAccount.prv, privilegeId, true);
            else this.$delete(this.editingAccount.prv, privilegeId);
        },
        handleAccountInactiveChanged(value) {
            if (!value) this.$set(this.editingAccount, "inactive", null);
            else this.$set(this.editingAccount, "inactive", true);
        },
        handleAccountTypeChanged(value) {
            if (value === "central") {
                this.$set(this.editingAccount, "regional", null);
                this.$set(this.editingAccount, "regiones", []);
            } else {
                this.$set(this.editingAccount, "regional", true);
            }

            this.$set(this.editingAccount, "role", "");
        },
    },
};
</script>
