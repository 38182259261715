<template>
    <div>
        <f7-row class="margin-bottom">
            <f7-col>
                <f7-list class="no-margin">
                    <f7-list-input type="text" placeholder="Juan" label="Nombre" @input="user.name = $event.target.value"></f7-list-input>
                    <f7-list-input type="text" placeholder="Pérez" label="Apellido 1" @input="user.lastname1 = $event.target.value"></f7-list-input>
                    <f7-list-input
                        type="text"
                        placeholder="Contreras"
                        label="Apellido 2"
                        @input="user.lastname2 = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="select"
                        placeholder="Estado de la cuenta"
                        label="Estado de la cuenta"
                        :value="user.inactive === 'any' ? 'any' : user.inactive === 'true' ? 'true' : 'false'"
                        @change="handleAccountInactiveChanged($event.target.value)"
                    >
                        <option value="any">Cualquiera</option>
                        <option value="false">Activa</option>
                        <option value="true">Inactiva</option>
                    </f7-list-input>
                </f7-list>
            </f7-col>
            <f7-col>
                <f7-list class="no-margin">
                    <f7-list-input type="text" placeholder="11.111.111-1" label="RUN" @input="user.run = $event.target.value"></f7-list-input>
                    <f7-list-input
                        type="text"
                        placeholder="juan@example.com"
                        label="Correo"
                        @input="user.email = $event.target.value"
                    ></f7-list-input>
                    <f7-list-input
                        type="select"
                        placeholder="Rol asignado"
                        label="Rol asignado"
                        :value="user.role"
                        @change="user.role = $event.target.value"
                    >
                        <option value="">Cualquiera</option>
                        <option v-for="role in $store.state.roles" :value="role.id" :key="role.id">{{ role.name }}</option>
                    </f7-list-input>
                </f7-list>
            </f7-col>
        </f7-row>
        <f7-button fill large @click="search" class="margin-bottom">
            <font-awesome-icon :icon="['fa', 'search']" class="margin-half-right"></font-awesome-icon>
            Buscar
        </f7-button>
    </div>
</template>

<script>
import { commonmixin } from "../mixins/common";
export default {
    mixins: [commonmixin],

    data() {
        return {
            user: {
                name: "",
                lastname1: "",
                lastname2: "",
                run: "",
                email: "",
                role: "",
                status: "",
                inactive: 'any',
            },
        };
    },

    methods: {
        async search() {
            try {
                this.$f7.dialog.preloader("Buscando...");

                const response = await this.commonExecute({ query: this.user }, "admin-searchAccounts", false);

                if (response.error) {
                    throw new Error(response.error);
                }

                this.$emit("found-accounts", response.accounts);

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },
        handleAccountInactiveChanged(value) {
            if (value === 'true') this.$set(this.user, 'inactive', 'true');
            else if (value === 'false') this.$set(this.user, 'inactive', 'false');
            else this.$set(this.user, 'inactive', 'any');
        }
    },
};
</script>
