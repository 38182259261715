<template>
    <f7-page name="gestion_verifica" @page:beforeout="sacame()">
        <f7-navbar
            title="Gestión solicitud"
            :subtitle="`Recibida a las ${$dayjs.unix(soliticudMeta.wh).format('HH:mm [el] D [de] MMMM [de] YYYY')}`"
            back-link="Back"
        ></f7-navbar>

        <f7-block class="margin-top">
            <f7-row>
                <f7-col class="bg-color-white padding-horizontal" width="100" medium="50">
                    <h4>Usuario solicitante</h4>
                    <f7-block class="no-margin text-align-center">
                        <f7-chip media-bg-color="blue" :text="soliticudMeta.email"
                            ><font-awesome-icon slot="media" icon="at"></font-awesome-icon
                        ></f7-chip>
                        <f7-chip media-bg-color="purple" :text="soliticudMeta.send.lephone"
                            ><font-awesome-icon slot="media" icon="phone"></font-awesome-icon
                        ></f7-chip>
                    </f7-block>
                    <f7-row>
                        <f7-col width="100" medium="50">
                            <p class="margin-bottom-half text-color-primary">Documento de identidad:</p>
                            <template v-if="documentoIsImage">
                                <f7-photo-browser
                                    :photos="[docu]"
                                    ref="docuadjunta"
                                    type="popup"
                                    popup-close-link-text="Cerrar"
                                    :toolbar="false"
                                    theme="dark"
                                    :navbar="false"
                                ></f7-photo-browser>
                                <f7-block class="no-margin no-padding">
                                    <f7-button
                                        @click="$refs.docuadjunta.open()"
                                        class="botonconimagen padding-top raised"
                                        :style="`background-image: url('${docu}')`"
                                    >
                                        <font-awesome-icon icon="search-plus" class="fa-3x"></font-awesome-icon>
                                    </f7-button>
                                </f7-block>
                            </template>
                            <f7-list v-else media-list class="no-margin">
                                <f7-list-item media-item>
                                    <div slot="title">{{ soliticudMeta.docu }}</div>
                                    <div slot="after">
                                        <f7-button small @click="viewDocument('document')">Ver</f7-button>
                                    </div>
                                </f7-list-item>
                            </f7-list>
                        </f7-col>

                        <f7-col width="100" medium="50">
                            <p class="margin-bottom-half text-color-primary">Usuario y documento:</p>
                            <template v-if="fotoIsImage">
                                <f7-photo-browser
                                    :photos="[foto]"
                                    ref="photoadjunta"
                                    type="popup"
                                    popup-close-link-text="Cerrar"
                                    :toolbar="false"
                                    theme="dark"
                                    :navbar="false"
                                ></f7-photo-browser>
                                <f7-block class="no-margin no-padding">
                                    <f7-button
                                        @click="$refs.photoadjunta.open()"
                                        class="botonconimagen padding-top raised"
                                        :style="`background-image: url('${foto}')`"
                                    >
                                        <font-awesome-icon icon="search-plus" class="fa-3x"></font-awesome-icon>
                                    </f7-button>
                                </f7-block>
                            </template>
                            <f7-list v-else media-list class="no-margin">
                                <f7-list-item media-item>
                                    <div slot="title">{{ soliticudMeta.foto }}</div>
                                    <div slot="after">
                                        <f7-button small @click="viewDocument('foto')">Ver</f7-button>
                                    </div>
                                </f7-list-item>
                            </f7-list>
                        </f7-col>
                    </f7-row>

                    <h4 class="padding-horizontal">Identidad declarada por el usuario solicitante</h4>
                    <f7-list media-list class="no-margin no-padding">
                        <f7-list-item
                            :header="'Nacionalidad'"
                            :title="nacionalidad"
                            @click="fetchPaisesAndGo()"
                            :value="paisVacunaAdministrada"
                            :info="isDeclaredCountryDifferentFromPatient ? 'El usuario declaró un país diferente al paciente encontrado.' : ''"
                            link="#"
                            ><font-awesome-icon icon="pen" slot="after"
                        /></f7-list-item>


                        <f7-list-item header="Nombres" @click="editaString('nombres')" :title="clonedidentity.nombres" link="#">
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item header="Primer apellido" @click="editaString('apellido1')" :title="clonedidentity.apellido1" link="#">
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item header="Segundo apellido" @click="editaString('apellido2')" :title="clonedidentity.apellido2" link="#">
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Fecha de nacimiento"
                            @click="editaFechanac()"
                            :title="`${$dayjs(clonedidentity.fechax).format('DD [de] MMMM [de] YYYY')}`"
                            link="#"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>

                        <f7-list-item header="Sexo" popover-open=".popover-mutagenero" :title="`${$store.state.sexo[clonedidentity.sexo]}`" link="#">
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                    </f7-list>

                    <f7-popover class="popover-mutagenero">
                        <f7-list>
                            <f7-list-item
                                v-for="(sexo, sexoid) in $store.state.sexo"
                                :key="sexoid"
                                radio
                                :value="sexoid"
                                name="muta-sexo-admn"
                                :checked="clonedidentity && sexoid === clonedidentity.sexo"
                                @change="mutagenero($event.target.value)"
                                :title="sexo"
                            ></f7-list-item>
                        </f7-list>
                    </f7-popover>

                    <h4 class="padding-horizontal">Detalles provistos por el usuario solicitante:</h4>
                    <f7-list class="no-margin">
                        <f7-list-item title="Homologación en curso" :after="soliticudMeta.homopend ? 'Si' : 'No'"></f7-list-item>
                        <f7-list-item title="Motivo" :after="soliticudMeta.send.tipoSituacion || 'No detallado'"></f7-list-item>
                    </f7-list>
                    <f7-block class="no-margin padding-half italic" strong>{{ soliticudMeta.send.freetext || "(Sin notas)" }}</f7-block>
                </f7-col>
                <f7-col width="100" medium="50">
                    <h4 class="padding-horizontal ">Paciente al que desea vincularse</h4>

                    <f7c-alert v-if="isDeclaredCountryDifferentFromPatient" type="warning">
                        El paciente al que desea vincularse tiene <b>distinta nacionalidad</b>. Si aprueba esta solicitud, debe decidir entre revocar
                        la identidad del paciente actual o crear un paciente nuevo con el mismo identificador más la nacionalidad.
                    </f7c-alert>

                    <f7-list media-list class="bg-color-white no-margin-top">
                        <f7-list-item
                            :title="
                                `${clonedidentity.idenType} ${
                                    clonedidentity.idenType == 'RUN' ? formatRut(clonedidentity.identity) : clonedidentity.identity
                                }`
                            "
                            :after="paisVacunaAdministrada"
                        >
                        </f7-list-item>
                        <f7-list-item
                            v-if="infoDelPaciente"
                            :header="`id: ${infoDelPaciente.id}`"
                            :title="`${infoDelPaciente.apellido1} ${infoDelPaciente.apellido2 || ''}, ${infoDelPaciente.nombres}`"
                            :subtitle="
                                `${infoDelPaciente.idenType} ${
                                    infoDelPaciente.idenType == 'RUN' ? formatRut(infoDelPaciente.identity) : infoDelPaciente.identity
                                }  | ${infoDelPaciente.nac_iso2}`
                            "
                            :text="`${$dayjs(infoDelPaciente.fechanac).format('DD/MM/YYYY')} - ${suageStrg(infoDelPaciente.fechanac)}`"
                            :footer="
                                `Origen: ${infoDelPaciente.src || '-'} ${
                                    infoDelPaciente.mdf ? '| Editado el ' + $dayjs(infoDelPaciente.mdf).format('DD/MM/YYYY[ a las ]HH:mm:ss') : ''
                                }`
                            "
                        >
                            <font-awesome-icon
                                slot="media"
                                class="fa-3x"
                                :class="
                                    infoDelPaciente.sexo == 'Intersex'
                                        ? 'text-color-gray'
                                        : infoDelPaciente.sexo == 'F'
                                        ? 'text-color-pink'
                                        : 'text-color-blue'
                                "
                                icon="user-circle"
                            ></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            class="text-color-red"
                            v-else
                            title="Paciente no existe en mevacuno"
                            footer="Se creará con la información provista"
                        ></f7-list-item>
                    </f7-list>

                    <h4 class="padding-horizontal ">Usuario actualmente verificado para este paciente</h4>
                    <f7-list media-list class="no-margin-top">
                        <f7-list-item v-if="ownerPrivx" :title="ownerPrivx.em" :subtitle="`Verificado por: ${ownerPrivx.verified}`"></f7-list-item>
                        <f7-list-item v-else title="Sin usuario verificado previamente"></f7-list-item>
                    </f7-list>

                    <h4 class="padding-horizontal ">Otros usuarios solicitando esta identidad</h4>
                    <f7-list class="no-margin-top">
                        <f7-list-item
                            v-if="!otrosUsuariosMismaIdentidad.length"
                            title="No hay otros usuarios solicitando esta identidad"
                        ></f7-list-item>
                        <f7-list-item
                            v-for="unotro in otrosUsuariosMismaIdentidad"
                            :key="unotro.id"
                            :footer="$dayjs.unix(unotro.wh).format('HH:mm [el] D [de] MMMM [de] YYYY')"
                            :title="unotro.email"
                        ></f7-list-item>
                    </f7-list>

                    <h4 class="padding-horizontal ">Solicitudes previas de este usuario</h4>
                    <f7-list media-list class="no-margin-top">
                        <f7-list-item
                            v-if="!historialSolicitudes || !historialSolicitudes.length"
                            title="No hay solicitudes previas de este usuario"
                        ></f7-list-item>

                        <f7-list-item
                            v-for="previo in historialSolicitudes"
                            :key="previo.id"
                            :header="$dayjs.unix(previo.wh).format('HH:mm [el] D [de] MMMM [de] YYYY') + ` por ${previo.answ_by}`"                            
                        >
                            <span slot="title"
                                ><font-awesome-icon :icon="previo.rjt ? 'user-slash' : 'user-check'" class="fa-lg"></font-awesome-icon>
                                {{ previo.send.idenType }} {{ previo.send.rawIdentity }}</span
                            >
                            <span slot="footer" v-html="$sanitize(previo.rjt)">{{previo.rjt ? previo.rjt : null}}</span>
                        </f7-list-item>
                    </f7-list>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block>
            <f7-row>
                <f7-col class="text-alignt-center">
                    <p class="no-margin padding-vertical-half padding-horizontal text-align-center bold text-color-primary">Resolución del caso</p>
                    <f7-list class="no-margin">
                        <f7-list-input
                            outline
                            label="Resolución"
                            type="select"
                            defaultValue=""
                            placeholder="Favor seleccione..."
                            @input="cambiaTipoDecision($event.target.value)"
                            :value="tipoDecisionSelected"
                            :info="
                                tipoDecisionSelected == 1
                                    ? `Al aprobar, ${
                                          !infoDelPaciente || !infoDelPaciente.id ? 'creará al paciente con la información provista, y' : ''
                                      } vinculará al usuario solicitante con este paciente, quedando su identidad verificada.`
                                    : null
                            "
                        >
                            <option value="">Seleccione</option>
                            <option value="1">Apruebo</option>
                            <option v-for="tipoRechazo in tiposRechazo" :key="tipoRechazo.id" :value="tipoRechazo.id">{{ tipoRechazo.name }}</option>
                            <option value="9999">Otra razón de rechazo</option>
                        </f7-list-input>
                        <f7-list-item
                            class="margin-horizontal-half overflow-text"
                            checkbox
                            no-hairlines
                            v-if="tipoDecisionSelected == 1 && isDeclaredCountryDifferentFromPatient"
                            :checked="shouldAvoidPatientIdCollision"
                            @change="shouldAvoidPatientIdCollision = !shouldAvoidPatientIdCollision"
                        >
                            <div slot="title">
                                Evitar colisión de identidades creando un nuevo paciente con identificador incluyendo nacionalidad ({{
                                    this.patientIdWithoutConflict
                                }})
                            </div>
                        </f7-list-item>
                        <f7-list-input
                            label="Justificación o comentarios"
                            type="texteditor"
                            :text-editor-params="{
                                buttons: [
                                    ['bold', 'italic', 'underline', 'strikeThrough'],
                                    ['orderedList', 'unorderedList'],
                                ],
                            }"
                            outline
                            placeholder="Escriba aquí comentarios relativos a su decisión del caso. Opcional si aprueba, mandatorio si rechaza."
                            @texteditor:change="freetxt = $event"
                            :value="freetxt"
                        >
                        </f7-list-input>
                    </f7-list>
                    <f7-block class="text-align-center" v-if="!tipoDecisionSelected">
                        <p>Seleccione un tipo de resolución del selector mas arriba para poder guardar.</p>
                    </f7-block>
                    <f7-block v-else>
                        <f7-button
                            large
                            @click="confirma(tipoDecisionSelected == 1)"
                            fill
                            raised
                            :color="tipoDecisionSelected == 1 ? 'teal' : 'red'"
                            >{{ tipoDecisionSelected == 1 ? "Aprobar" : "Rechazar" }}</f7-button
                        >
                    </f7-block>
                </f7-col>
            </f7-row>
        </f7-block>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import Alert from "../components/Alert.vue";
import paisesJson from "@/paises.json";

export default {
    mixins: [commonmixin],
    components: { "f7c-alert": Alert },
    props: [
        "soliticudMeta",
        "foto",
        "docu",
        "infoDelPaciente",
        "identidadDeclarada",
        "historialSolicitudes",
        "otrosUsuariosMismaIdentidad",
        "ownerPrivx",
    ],
    data() {
        return {
            freetxt: "",
            tipoDecisionSelected: "",
            clonedidentity: {},
            fechanacsplit: {
                ano: "1985",
                mes: "8",
                dia: "30",
            },
            shouldAvoidPatientIdCollision: false,
            paisVacunaAdministrada: "",
        };
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["centralAdminAccount", "tiposRechazo"]),

        isDeclaredCountryDifferentFromPatient() {
            if (!this.infoDelPaciente || !this.infoDelPaciente.idenType || !this.infoDelPaciente.nac_iso2) return false;

            if (this.identidadDeclarada.idenType !== "RUN") {
                return this.identidadDeclarada.nacionalidad !== this.infoDelPaciente.nac_iso2;
            }

            return false;
        },

        patientIdWithoutConflict() {
            let iso_2 = this.$store.state.decl_id_pais ? this.$store.state.decl_id_pais : this.paisVacunaAdministrada
            return `${this.identidadDeclarada.idenType}_${this.identidadDeclarada.identity}_${iso_2}`;
        },
        nacionalidad(){
            if(this.$store.state.decl_id_pais){
                this.paisVacunaAdministrada = this.$store.state.decl_id_pais
                this.clonedidentity.nacionalidad = this.$store.state.decl_id_pais;
            }
            return this.$store.state.decl_id_pais ? paisesJson[this.$store.state.decl_id_pais].name : this.paisVacunaAdministrada;
        },

        documentoIsImage() {
            const splittedSolicitudMetaDocu = this.soliticudMeta.docu.split(".");

            const fileType = splittedSolicitudMetaDocu[splittedSolicitudMetaDocu.length - 1];

            return ["jpeg", "jpg", "png", "gif"].includes(fileType);
        },
        fotoIsImage() {
            const splittedFoto = this.soliticudMeta.foto.split(".");

            const fileType = splittedFoto[splittedFoto.length - 1];

            return ["jpeg", "jpg", "png", "gif"].includes(fileType);
        },
    },
    mounted() {
        console.log("ownerPrivx", this.ownerPrivx);
        this.clonedidentity = Object.assign({}, this.identidadDeclarada);
        this.fechanacsplit.ano = this.$dayjs(this.clonedidentity.fechax).format("YYYY");
        this.fechanacsplit.mes = this.$dayjs(this.clonedidentity.fechax).format("M");
        this.fechanacsplit.dia = this.$dayjs(this.clonedidentity.fechax).format("D");
        this.paisVacunaAdministrada = this.clonedidentity.nacionalidad ? paisesJson[this.clonedidentity.nacionalidad].name : "";
        console.log("Fecha", this.fechanacsplit);
        if (!this.tiposRechazo.length) {
            this.traerTiposRechazos();
        }
    },
    beforeCreate() {
        this.$store.commit('setWhatTo',{what:'update_nationality',to:false});

    },
    beforeMount(){
        this.clonedidentity = Object.assign({}, this.identidadDeclarada);
        this.$store.commit('setWhatTo',{what:'decl_id_pais',to:this.identidadDeclarada.nacionalidad});
    },
    methods: {
        fetchPaisesAndGo() {
            if (!this.$store.getters.paisesArraySorted.length) {
                this.$f7.dialog.preloader("Cargando paises");
                this.$firebase
                    .database()
                    .ref("paises")
                    .once("value")
                    .then(snps => {
                        this.$store.commit("setWhatTo", { what: "paises", to: snps.val() });
                        this.$f7.dialog.close();
                        this.$f7.views.main.router.navigate("/paises/");
                    })
                    .catch(error => {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            } else {
                this.$f7.views.main.router.navigate("/paises/");
            }
        },
        viewDocument(type) {
            let url = "";

            if (type === "document") {
                url = this.docu;
            } else if (type === "foto") {
                url = this.foto;
            }

            if (!url) return;

            window.open(url, "_blank");
        },

        cambiaTipoDecision(tipoid) {
            this.tipoDecisionSelected = tipoid;
            let cual = this.tiposRechazo.find(untiprech => {
                return untiprech.id == tipoid;
            });
            this.freetxt = cual ? cual.text || "" : "";
        },
        async sacame() {
            this.clonedidentity = Object.assign({}, this.identidadDeclarada);
            this.$store.commit('setWhatTo',{what:'decl_id_pais',to:this.identidadDeclarada.nacionalidad});
            await this.$firebase_coreapp
                .database(this.$store.getters.usrtrxDatabaseUrl)
                .ref(`cola_sols_agentes/${this.dev ? "d" : "p"}/${this.user.email.split(".").join("|")}`)
                .set(null);
        },
        confirma(aprueba) {
            if (!aprueba && !this.freetxt) {
                return this.$f7.dialog.alert("Si rechaza, debe proveer un breve texto explicativo");
            }

            const confirmText = aprueba
                ? `¿Está seguro de aprobar esta solicitud? ${
                      this.shouldAvoidPatientIdCollision ? `Se creará un nuevo paciente con identificador ${this.patientIdWithoutConflict}` : ""
                  }`
                : "¿Está seguro de rechazar esta solicitud?";

            return this.$f7.dialog.confirm(confirmText, () => {
                this.responde(aprueba);
            });
        },
        async responde(aprueba) {
            try {
                let mesx = parseInt(this.fechanacsplit.mes) < 10 ? `0${this.fechanacsplit.mes}` : this.fechanacsplit.mes;
                let diax = parseInt(this.fechanacsplit.dia) < 10 ? `0${this.fechanacsplit.dia}` : this.fechanacsplit.dia;
                let fechax = `${this.fechanacsplit.ano}${mesx}${diax}`;
                if (!this.infoDelPaciente || !this.infoDelPaciente.id) {
                    let ano = parseInt(this.fechanacsplit.ano);
                    if (ano < 1900) {
                        return this.$f7.dialog.alert("Fecha de nacimiento no plausible");
                    }

                    if (!this.$dayjs(fechax, "YYYYMMDD").isValid()) {
                        return this.$f7.dialog.alert("Fecha de nacimiento inválida");
                    }
                    let mes = this.$dayjs(fechax, "YYYYMMDD").format("MM");
                    if (mes != mesx) {
                        return this.$f7.dialog.alert("Fecha de nacimiento parece inválida");
                    }
                    if (this.$dayjs().isBefore(`${this.fechanacsplit.ano}-${mesx}-${diax}`, "day")) {
                        return this.$f7.dialog.alert("Fecha de nacimiento no plausible");
                    }
                }

                let datatosend = {
                    apellido1: this.clonedidentity.apellido1,
                    apellido2: this.clonedidentity.apellido2,
                    fechanac: fechax,
                    nombres: this.clonedidentity.nombres,
                    sexo: this.clonedidentity.sexo,
                    nacionalidad: this.clonedidentity.nacionalidad
                };
                console.log("this.freetxt", this.freetxt);
                this.$f7.dialog.preloader("Guardando resolución");
                  let datous = await this.commonExecute(
                      {
                          solId: this.soliticudMeta.id,
                          aprueba,
                          tipoDecisionSelected: this.tipoDecisionSelected,
                          shouldAvoidPatientIdCollision: this.shouldAvoidPatientIdCollision,
                          freetxt: this.freetxt,
                          dataToFix: datatosend,
                      },
                      "admin-respondeSolicitudVerificacion",
                      false
                  );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log("Parece que todo bien, volver");
                this.$f7.dialog.close();
                //recheckPaxInfo_homolacion
                this.$store.commit("setWhatTo", { what: "recheckPaxInfo_homolacion", to: Date.now() });

                if (!aprueba) {
                    this.$store.commit("homologacionVacunas/set_acaba_de_rechazar", true);
                }

                this.avisaOk();
                this.$f7.views.main.router.back();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async traerTiposRechazos() {
            try {
                let protipos = await this.$firebase_coreapp
                    .database(this.$store.getters.usrtrxDatabaseUrl)
                    .ref("solicit_tipos_rechazo")
                    .once("value");
                console.log("tiposRechazo", protipos.val());
                this.$store.commit("setWhatTo", { what: "tiposRechazo", to: protipos.val() || {} });
            } catch (error) {
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        editaFechanac() {
            this.$f7.dialog.prompt(
                `Nueva fecha de nacimiento (DD/MM/YYYY)`,
                "Editar",
                nuevovalor => {
                    let fechax = this.$dayjs(nuevovalor, "DD/MM/YYYY").format("YYYYMMDD");
                    if (!this.$dayjs(fechax, "YYYYMMDD").isValid()) {
                        return this.$f7.dialog.alert("Fecha de nacimiento inválida");
                    }else{
                        this.clonedidentity.fechax = fechax;
                        this.fechanacsplit.ano = this.$dayjs(this.clonedidentity.fechax).format("YYYY");
                        this.fechanacsplit.mes = this.$dayjs(this.clonedidentity.fechax).format("M");
                        this.fechanacsplit.dia = this.$dayjs(this.clonedidentity.fechax).format("D");
                    }
                },
                () => {
                    console.log("Abortando edit");
                },
                this.$dayjs(this.clonedidentity.fechax).format("DD/MM/YYYY")
            );
        },
        mutagenero(nuevogenerp) {
            this.$f7.popover.close(".popover-mutagenero", true);
            this.clonedidentity.sexo = nuevogenerp;
        },
        editaString(cual) {
             this.$f7.dialog.prompt(
                 `Nuevo valor para ${cual}`,
                 "Editar",
                 nuevovalor => {
                     this.clonedidentity[cual] = nuevovalor;
                 },
                 () => {
                     console.log("Abortando edit");
                 },
                 this.clonedidentity[cual]
             );
        },
    },
};
</script>
<style>
.botonconimagen {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    max-width: 100%;
    height: 200px;
    line-height: 200px;
    border: 2px solid #cecece;
    border-radius: 9px !important;
}
.inputano input {
    width: 100% !important;
    text-align: center;
}
.inputmes select {
    width: 100% !important;
}
.inputdia select {
    width: 100% !important;
}
.inputfechatitlefull .item-title {
    width: 100% !important;
}
.previothumb {
    width: 40px;
}

.avisopx {
    font-size: 11px;
    margin: 2px !important;
}

.overflow-text .item-title {
    white-space: normal;
}
.red-info .item-input-info {
    color: red;
}
</style>
