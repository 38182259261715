<template>
    <f7-list-item media-item link="#" @click="$emit('tomar-solicitud', { solicitud, listType })">
        <div slot="header">
            <span v-if="solicitud.last_sent_at">
                Ultimo envío a revisión: {{ solicitud.last_sent_at ? $dayjs.unix(solicitud.last_sent_at).format("dddd D [de] MMMM [-] HH:mm") : "-" }}
            </span>
            <span v-else>
                Creada el: {{ solicitud.wh ? $dayjs.unix(solicitud.wh).format("dddd D [de] MMMM [-] HH:mm") : "-" }}
            </span>
            <span v-if="!patientIsVerified">
                &bull;
                <span class="text-color-red">Paciente no verificado</span>
            </span>
            <span v-if="solicitud.est === 'Cerrada'"><f7-badge color="gray">Cerrada</f7-badge> </span>
            <span v-else-if="solicitud.vac_rej"><f7-badge color="red">Conflicto</f7-badge> </span>
            <span v-if="requiereApostillamiento"><f7-badge color="blue">Apostilla</f7-badge> </span>
        </div>

        <div slot="title">
            <span class="font-weight-bold">{{ patientName }}</span>
        </div>

        <div slot="subtitle">
            <span v-if="listType.startsWith('Busqueda')">{{ solicitud.est }}: </span>
            <span v-if="!isApostilla">{{ solicitudCompletion }}</span>
            <span v-if="isSign">
                &bull; Solicitud aceptada por: {{ solicitud.last_resol_by }} el {{ $dayjs.unix(solicitud.last_resol_at).format("DD/MM/YYYY HH:mm") }}
            </span>
            <span v-else-if="isApostilla">
                Solicitud apostillamiento creada el: {{ $dayjs.unix(solicitud.actApos.when).format("DD/MM/YYYY HH:mm") }}
            </span>
            <span v-else-if="solicitud.last_resol_by">
                &bull; Ultima resolución por: {{ solicitud.last_resol_by }} el {{ $dayjs.unix(solicitud.last_resol_at).format("DD/MM/YYYY HH:mm") }}
            </span>
        </div>

        <div slot="after">
            <span v-if="solicitud.last_tkn">Tomada por: {{ solicitud.last_tkn }}</span>
        </div>
    </f7-list-item>
</template>

<script>
export default {
    props: {
        listType: String,
        solicitud: Object,
    },

    computed: {
        patientName() {
            const patient = this.solicitud.pat;

            if (!patient) return "Sin paciente identificado";

            return `${patient.apellido1} ${patient.apellido2}, ${patient.nombres}`;
        },

        patientIsVerified() {
            const patient = this.solicitud.pat;

            if (!patient) return false;

            return patient.verified;
        },

        solicitudVacunas() {
            return Object.values(this.solicitud.vacs || {});
        },

        solicitudCompletion() {
            if (this.solicitud.periodo === "post2013")
                return `Vacunas existentes en RNI`

            const totalVacunas = this.solicitudVacunas.length;

            const acceptedVacunas = this.solicitudVacunas.filter(v => v.est && v.est.resol === "Aceptada").length;
            const rejectedVacunas = this.solicitudVacunas.filter(v => v.est && v.est.resol === "Rechazada").length;

            return `${totalVacunas} vacunas. ${acceptedVacunas} aceptadas, ${rejectedVacunas} rechazadas`;
        },

        requiereApostillamiento() {
            return this.solicitud.reqApos;
        },

        isSign() {
            return this.listType === "Por firmar" || this.listType === "Firmando" || (this.listType === "Busqueda signer" && this.solicitud.cert && this.solicitud.cert.status !== "approved");
        },

        isApostilla() {
            return this.listType === "Por apostillar" || this.listType === "Apostillando" || (this.listType === "Busqueda signer" && this.solicitud.cert && this.solicitud.cert.status === "approved" && this.solicitud.actApos);
        },
    },
};
</script>
