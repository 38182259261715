<template>
 <f7-page name="vacuna_crea">
        <f7-navbar title="Crear nueva vacuna">
          <f7-nav-right>
            <f7-link popup-close>Cancelar</f7-link>
          </f7-nav-right>
        </f7-navbar>
 <f7-list class="margin-vertical">
        <f7-list-input
            label="Laboratorio"
            input-id="autodown-labs"
            autocomplete="off"
            type="text"
            :value="tocreate.laboratorioName"
            @input="tocreate.laboratorioName = $event.target.value"
            placeholder="i.e. Laboratorio Chile"
             :disabled="laboratorioOk"
        ></f7-list-input>
  <template v-if="laboratorioOk">
        <f7-list-input
            label="Nombre de la Vacuna"
            type="text"
            autocomplete="off"
            input-id="autodown-vacs"
            :value="tocreate.vacunaPcName"
            @input="tocreate.vacunaPcName = $event.target.value"
            placeholder="i.e. Rotarix"
            clear-button
        ></f7-list-input>

        <f7-list-input
            label="Presentación"
            type="text"
            :value="tocreate.presentacion"
            @input="tocreate.presentacion = $event.target.value"
            placeholder="i.e. solución liofilizada"
            clear-button
        ></f7-list-input>

        <f7-list-input
            label="Mapeo Terminología (opcional)"
            type="text"
            :value="tocreate.mapeoid"
            @input="tocreate.mapeoid = $event.target.value"
            placeholder="172831912234"
            clear-button
        ></f7-list-input>
  </template>
    </f7-list>
    <f7-block>
      <f7-row>
        <f7-col><f7-button @click="$emit('abort')" large color="gray">Cancelar</f7-button></f7-col>
        <f7-col><f7-button @click="gonext()" large fill raised color="teal">Siguiente</f7-button></f7-col>
    </f7-row>
    </f7-block>
      </f7-page>
</template>
<script>
import { mapGetters } from 'vuex'
import { commonmixin } from '../mixins/common'

export default {
     mixins: [commonmixin],
    data() {
      return {
       tocreate:{
            laboratorioName: null,
            vacunaPcName: null,
            presentacion: null,
            mapeoid: null,
       },
       laboratorioOk: false,
        autocompleteLaboratorios: null,
            autocompleteVacunas: null,
            autocompletePatogenos: null,
      }
    },
    computed:{
     ...mapGetters(['vacunas','laboratorios','patogenos']),
        labListParaAutoComplete() {
            return this.laboratorios.map((unlab) => {
                return unlab.name;
            });
        },
        vacListParaAutoComplete() {
          if(!this.tocreate.laboratorioName){
            return [];
          }
          else{
           return this.vacunas.filter(unavac => {
              return unavac.laboratorioName == this.tocreate.laboratorioName
            }).map((unavac) => {
                return unavac.vacunaPcName;
            });
          }
        },
        patsListParaAutoComplete() {
            return this.patogenos.map((unpat) => {
                return unpat.nm;
            });
        },
        normalizedListOfPagotens() {
            return this.patsListParaAutoComplete.map((unpat) => {
                return this.normalize(unpat);
            });
        },
    },
    mounted(){
          this.autocompleteLaboratorios = this.$f7.autocomplete.create({
            inputEl: "#autodown-labs",
            openIn: "dropdown",
            source: (query, render) => {
                let results = [];
                for (let i = 0; i < this.labListParaAutoComplete.length; i++) {
                    if (this.labListParaAutoComplete[i].toLowerCase().indexOf(query.toLowerCase()) >= 0)
                        results.push(this.labListParaAutoComplete[i]);
                }
                render(results);
            },
        });
        this.autocompletePatogenos = this.$f7.autocomplete.create({
            inputEl: "#autocomplete-dropdown-patogenos",
            openIn: "dropdown",
            source: (query, render) => {
                let results = [];
                for (let i = 0; i < this.patsListParaAutoComplete.length; i++) {
                    if (this.patsListParaAutoComplete[i].toLowerCase().indexOf(query.toLowerCase()) >= 0)
                        results.push(this.patsListParaAutoComplete[i]);
                }
                render(results);
            },
            on: {
                change: (value, px) => {
                    let suindice = this.encuentraIdPat(value[0]);
                    this.modelapat(suindice, value[0]);
                },
                closed: (sdf) => {
                    // this.cobertInput = '';
                },
            },
        });
    },
    methods:{
      gonext(){
        if(!this.laboratorioOk){
          //es primer paso, ver que laboratorio tenga un valor
          if(!this.tocreate.laboratorioName){
            return this.$f7.dialog.alert('Debe seleccionar o crear un Laboratorio','Campo no puede ser vacío');
          }
          this.laboratorioOk=true;
          setTimeout(()=>{ 
                  this.loadAutoCompleteVacunas();
              }, 300);

        }
        else{
          //ver que esté el resto
          if(!this.tocreate.vacunaPcName || !this.tocreate.presentacion){
            return this.$f7.dialog.alert('Debe seleccionar o crear una vacuna, e indicar la presentación','Falta información');
          }
          //proceder a crear creaVacuna
          this.crearVacuna();
        }
      },
      async crearVacuna(){
        try{
            this.$f7.dialog.preloader('Creando vacuna...');
            console.log('enviando',this.tocreate);
            let datous = await this.commonExecute(this.tocreate,'admin-creaVacuna',true);
            if(datous.error){
              throw new Error(datous.error.message);
            }
            console.log('creacion ok',datous);
            this.$f7.dialog.close();
            this.$emit('creado',datous.payload);
          return true;
        } catch(error){
           this.$f7.dialog.close();
           console.error(error);
           this.$f7.dialog.alert(error.message, error.code);
        }
      },
      loadAutoCompleteVacunas(){
        this.autocompleteVacunas = this.$f7.autocomplete.create({
            inputEl: "#autodown-vacs",
            openIn: "dropdown",
            source: (query, render) => {
                let results = [];
                for (let i = 0; i < this.vacListParaAutoComplete.length; i++) {
                    if (this.vacListParaAutoComplete[i].toLowerCase().indexOf(query.toLowerCase()) >= 0)
                        results.push(this.vacListParaAutoComplete[i]);
                }
                render(results);
            },
        });
      }
   
    }
}
</script>
<style>


</style>