<template>
<f7-view>
  <f7-page name="restriccion_edad_esquema">
    <f7-navbar title="Configurar restricción edad para esquema">
        </f7-navbar>
                    <f7-list class="no-margin-top no-margin-bottom">
                                <f7-list-item divider title="Restricción por edades"></f7-list-item>
                                <f7-list-item  :title="tosend.agemin?`${tosend.agemin.y} años ${tosend.agemin.m} meses y ${tosend.agemin.d} días`:'Sin restricción de edad mínima'" header="Edad mínima">
                                  <f7-link v-if="tosend.agemin" @click="tosend.agemin=null" slot="title"><font-awesome-icon class="text-color-gray margin-left-half" icon="trash"></font-awesome-icon></f7-link>
                                    <f7-row no-gap>
                                        <f7-col>
                                           <f7-list class="no-margin noulpaddingleft">
                                                <f7-list-input
                                                    type="select"
                                                    label="Años"
                                                    :value="tosend.agemin?tosend.agemin.y:'no'"
                                                    @change="handleAgeChange('agemin', 'y', $event.target.value)"
                                                >
                                                 <option value="no">-</option>
                                                    <option :value="(ano-1)" v-for="ano in 126" :key="`m-${ano-1}`">
                                                        {{ ano-1 }}
                                                    </option>
                                                </f7-list-input>
                                            </f7-list>
                                        </f7-col>
                                        <f7-col>
                                           <f7-list class="no-margin noulpaddingleft">
                                                <f7-list-input
                                                    type="select"
                                                    label="Meses"
                                                     :value="tosend.agemin?tosend.agemin.m:''"
                                                    @change="handleAgeChange('agemin', 'm', $event.target.value)"
                                                >
                                                    <option v-if="!tosend.agemin" value="">-</option>
                                                    <option :value="(mes-1)" v-for="mes in 12" :key="`m-${mes-1}`">
                                                        {{ (mes-1) }}
                                                    </option>
                                                </f7-list-input>
                                            </f7-list>
                                        </f7-col>
                                        <f7-col>
                                           <f7-list class="no-margin noulpaddingleft">
                                                <f7-list-input
                                                    type="select"
                                                    label="Días"
                                                     :value="tosend.agemin?tosend.agemin.d:''"
                                                    @change="handleAgeChange('agemin', 'd', $event.target.value)"
                                                >
                                                    <option v-if="!tosend.agemin" value="">-</option>
                                                    <option :value="(dia-1)" v-for="dia in 32" :key="`d-${dia-1}`">
                                                        {{ (dia-1) }}
                                                    </option>
                                                </f7-list-input>
                                            </f7-list>
                                        </f7-col>
                                    </f7-row>
                                </f7-list-item>
                          <f7-list-item  :title="tosend.agemax?`${tosend.agemax.y} años ${tosend.agemax.m} meses y ${tosend.agemax.d} días`:'Sin restricción de edad máxima'" header="Edad máxima">
                                  <f7-link v-if="tosend.agemax" @click="tosend.agemax=null" slot="title"><font-awesome-icon class="text-color-gray margin-left-half" icon="trash"></font-awesome-icon></f7-link>
                                    <f7-row no-gap>
                                        <f7-col>
                                           <f7-list class="no-margin noulpaddingleft">
                                                <f7-list-input
                                                    type="select"
                                                    label="Años"
                                                    :value="tosend.agemax?tosend.agemax.y:'no'"
                                                    @change="handleAgeChange('agemax', 'y', $event.target.value)"
                                                >
                                                 <option value="no">-</option>
                                                    <option :value="(ano-1)" v-for="ano in 126" :key="`m-${ano-1}`">
                                                        {{ ano-1 }}
                                                    </option>
                                                </f7-list-input>
                                            </f7-list>
                                        </f7-col>
                                        <f7-col>
                                           <f7-list class="no-margin noulpaddingleft">
                                                <f7-list-input
                                                    type="select"
                                                    label="Meses"
                                                     :value="tosend.agemax?tosend.agemax.m:''"
                                                    @change="handleAgeChange('agemax', 'm', $event.target.value)"
                                                >
                                                    <option v-if="!tosend.agemax" value="">-</option>
                                                    <option :value="(mes-1)" v-for="mes in 12" :key="`m-${mes-1}`">
                                                        {{ (mes-1) }}
                                                    </option>
                                                </f7-list-input>
                                            </f7-list>
                                        </f7-col>
                                        <f7-col>
                                           <f7-list class="no-margin noulpaddingleft">
                                                <f7-list-input
                                                    type="select"
                                                    label="Días"
                                                     :value="tosend.agemax?tosend.agemax.d:''"
                                                    @change="handleAgeChange('agemax', 'd', $event.target.value)"
                                                >
                                                    <option v-if="!tosend.agemax" value="">-</option>
                                                    <option :value="(dia-1)" v-for="dia in 32" :key="`d-${dia-1}`">
                                                        {{ (dia-1) }}
                                                    </option>
                                                </f7-list-input>
                                            </f7-list>
                                        </f7-col>
                                    </f7-row>
                                </f7-list-item>
                                  </f7-list>
 
                      <f7-block>
                          <f7-row>
                              <f7-col><f7-button popup-close large outline color="red">Cancelar</f7-button></f7-col>
                                <f7-col>
                                  <f7-button @click="guardarestrix()" large fill raised color="teal" >Guardar cambios</f7-button>
                                  </f7-col>
                          </f7-row>
                          </f7-block>




  </f7-page>
</f7-view>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { commonmixin } from '../mixins/common'

export default {
  mixins: [commonmixin],
  props:['id','esquemaid'],
    data() {
      return {
          tosend:{
            agemin:null,
            agemax: null,
          }
      }
    },
      computed:{
         campanaEnFoco(){
          return this.$store.state.campanas[this.id];
        }
      },
    created(){
     
      let criteriosPrevios = this.campanaEnFoco.esquemas[this.esquemaid] || {};
      let prepare={
         agemin: criteriosPrevios.agemin || null,
         agemax: criteriosPrevios.agemax ||null,
      };
      this.tosend = Object.assign({},prepare);
    },
    methods:{
        async guardarestrix(){
         try {
           console.log('va a guardar',this.tosend);
           this.$f7.dialog.preloader('Guardando restricción...');
            await this.$firebase.database().ref(`campanas/${this.id}/esquemas/${this.esquemaid}`).update(this.tosend);
            this.$f7.dialog.close();
            this.$emit('nuevorestriage',this.tosend);

             } catch (error) {
             this.$f7.dialog.close();
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
          handleAgeChange(cualrange, property, value) {
           if(value=='no'){
             this.tosend[cualrange]=null;
           }
           else{
            if(!this.tosend[cualrange]){
              this.tosend[cualrange]={
                d: 0,
                m: 0,
                y: 0
              }
            }
            this.tosend[cualrange][property]=parseInt(value);
           }

        },
  
 

    }
}
</script>
<style>

</style>