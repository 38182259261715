<template>
    <f7-list class="no-margin-top" media-list>
        <ul>
            <f7-list-item v-if="solicitudes && !solicitudes.length" title="No hay solicitudes"> </f7-list-item>

            <solicitud-list-item
                v-for="solicitud in solicitudes"
                :key="solicitud.id"
                :solicitud="solicitud"
                :list-type="listType"
                @tomar-solicitud="handleTomarSolicitud"
            ></solicitud-list-item>
        </ul>
    </f7-list>
</template>

<script>
import { mapState } from "vuex";
import SolicitudListItem from "./solicitud-list-item.vue";

export default {
    components: {
        "solicitud-list-item": SolicitudListItem,
    },

    props: {
        listType: String,
        solicitudes: Array,
    },

    computed: {
        ...mapState(["user", "dev"]),
    },

    methods: {
        async handleTomarSolicitud(payload) {
            if (!this.user) return;

            const { solicitud, listType } = payload;

            let actionType = "";

            const firmandoFromBusqueda = listType === "Busqueda signer" && solicitud.est === "Aceptada" 
                && solicitud.cert &&  solicitud.cert.status === "signing";

            const porFirmarFromBusqueda = listType === "Busqueda signer" && solicitud.est === "Aceptada" 
                && solicitud.cert &&  solicitud.cert.status === "pending";

            const apostillandoFromBusqueda = listType === "Busqueda signer" && solicitud.est === "Aceptada" 
                && solicitud.cert && solicitud.cert.status === "approved" && solicitud.actApos && solicitud.actApos.status === "reviewing";

            const porApostillarFromBusqueda = listType === "Busqueda signer" && solicitud.est === "Aceptada" 
                && solicitud.cert && solicitud.cert.status === "approved" && solicitud.actApos && solicitud.actApos.status === "pending";
            
            if (listType === "Por firmar" || listType === "Firmando" || firmandoFromBusqueda || porFirmarFromBusqueda)
                actionType = "sign";
            else if (listType === "Por apostillar" || listType === "Apostillando" || apostillandoFromBusqueda || porApostillarFromBusqueda)
                actionType = "apostilla";
            else if (listType === "Busqueda aceptadas" || listType === "Aceptadas")
                actionType = "view";
            else 
                actionType = "nac";

            if (listType === "Aceptadas" || firmandoFromBusqueda || apostillandoFromBusqueda
                || listType === "Firmando" || listType === "Apostillando" || listType === "Busqueda aceptadas"
                || solicitud.est === "En revisión" || solicitud.est === "Cerrada") {
                
                this.$store.commit("homologacionVacunasNac/set_current_solicitud_object", solicitud);

                this.$f7.views.main.router.navigate(`/gestor_homologacion_vacunas_nac/resolving/${actionType}`);
           
                this.$emit("solicitud-taken", true);
            } else {
                try {
                    this.$f7.dialog.preloader("Tomando solicitud");

                    const toggleTakeSolicitudHomologacion = this.$firebase.functions().httpsCallable("admin-toggleTakeSolicitudHomologacion");

                    const { data } = await toggleTakeSolicitudHomologacion({
                        solicitudId: solicitud.id,
                        dev: this.dev ? true : false,
                        take: true,
                        tipo: actionType,
                    });

                    if (data.error) {
                        throw new Error(data.error.message);
                    }

                    const updatedSolicitud = Object.assign({}, solicitud, data.solicitudUpdate);

                    this.$store.commit("homologacionVacunasNac/set_current_solicitud_object", updatedSolicitud);

                    this.$emit("solicitud-taken", true);

                    console.log("actionType", actionType);

                    this.$f7.views.main.router.navigate(`/gestor_homologacion_vacunas_nac/resolving/${actionType}`);

                    this.$f7.dialog.close();
                } catch (error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            }
        },
    },
};
</script>
