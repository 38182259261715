<template>
  <f7-card>
    <f7-card-header>{{ `Aceptada el: ${$dayjs.unix(solicitud.last_resol_at)}` }}</f7-card-header>
    <f7-card-content>
      <f7-list v-if="sortedVacunas.length" class="no-margin-top nowrpwhtspac fixfamarg" media-list>
        <div class="timeline margin-vertical">
          <div v-for="(record, indx) in sortedVacunas" :key="record.id" class="timeline-item">
            <div class="timeline-item-date text-align-center">{{ indx + 1 }}</div>
            <div class="timeline-item-divider"></div>
            <div class="timeline-item-content">
                <f7-list class="no-margin-vertical" media-list>
                    <f7-list-item
                        class="titlebold"
                        :subtitle="record.vac.name"
                        :header="$dayjs(record.fechavac, 'DD/MM/YYYY').format('DD MMM YYYY')"
                        :text="record.establecimiento"
                        :footer="`Lote: ${record.lote ? record.lote : '-'}`"
                    >
                    </f7-list-item>
                </f7-list>
            </div>
          </div>
        </div>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>
<script>
export default {
  props: {
    solicitud: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    sortedVacunas() {
      if (!Object.values(this.solicitud.vacs)) return [];
      return Object.values(this.solicitud.vacs).sort((a, b) => {
        return this.$dayjs(a.fechavac, 'DD/MM/YYYY').unix() - this.$dayjs(b.fechavac, 'DD/MM/YYYY').unix();
      });
    }
  },
}
</script>