<template>
    <f7-page name="editor-registros-vacuna-edit">
        <f7-navbar back-link="Back" title="Editando registro"> </f7-navbar>

        <f7-block class="no-margin">
            <f7-list media-list>
                <f7-list-item media-item>
                    <div slot="header">ID de inmunización</div>
                    <div slot="title">{{ currentRegistro.rv.id }}</div>
                </f7-list-item>
                <f7-list-item media-item>
                    <div slot="header">Vacunatorio</div>
                    <div slot="title">{{ currentRegistro.vacunatorio.name }}</div>
                </f7-list-item>
                <f7-list-item media-item>
                    <div slot="header">Campaña</div>
                    <div slot="title">{{ currentRegistro.campana.nombre }}</div>
                </f7-list-item>
                <f7-list-item media-item>
                    <div slot="header">Identidad del paciente</div>
                    <div slot="title">{{ currentRegistro.rv.a }}</div>
                    <div slot="after">
                        <f7-button small @click="handleEditRegistroVacuna('patientId')">Editar</f7-button>
                    </div>
                </f7-list-item>
                <f7-list-item media-item>
                    <div slot="header">Fecha de vacunación</div>
                    <div slot="title">{{ $dayjs(currentRegistro.rv.d, "YYYYMMDD").format("DD/MM/YYYY") }}</div>
                    <div slot="after">
                        <f7-button small @click="handleEditRegistroVacuna('fechaVacunacion')">Editar</f7-button>
                    </div>
                </f7-list-item>
                <f7-list-item media-item>
                    <div slot="header">Esquema</div>
                    <div slot="title">{{ currentRegistroEsquema }}</div>
                    <div slot="after" v-if="!isHomologacionInternacional">
                        <f7-button small @click="handleEditRegistroVacuna('esquema')">Editar</f7-button>
                    </div>
                </f7-list-item>
                <f7-list-item media-item>
                    <div slot="header">Dosis del esquema</div>
                    <div slot="title">{{ currentRegistro.rv.i }}</div>
                    <div slot="after" v-if="!isHomologacionInternacional">
                        <f7-button small @click="handleEditRegistroVacuna('dosis')">Editar</f7-button>
                    </div>
                </f7-list-item>
                <f7-list-item media-item>
                    <div slot="header">Lote</div>
                    <div slot="title">{{ currentRegistro.rv.l }}</div>
                    <div slot="after">
                        <f7-button small @click="handleEditRegistroVacuna('lote')">Editar</f7-button>
                    </div>
                </f7-list-item>
                <f7-list-item media-item>
                    <div slot="header">Vacuna administrada</div>
                    <div slot="title">{{ currentRegistroVacunaAdministrada.vacunaPcName }}</div>
                    <div slot="subtitle">{{ currentRegistroVacunaAdministrada.laboratorioName }}</div>
                    <div slot="after">
                        <f7-button small @click="handleEditRegistroVacuna('vacuna')">Editar</f7-button>
                    </div>
                </f7-list-item>
            </f7-list>

            <f7c-alert v-if="currentRegistro.logs.length" type="info" class="margin">
                Última edición del registro fue realizada por <b>{{ currentRegistro.logs[0].w }}</b> 
                el {{$dayjs.unix(currentRegistro.logs[0].x).format('dddd DD/MM/YYYY [a las] HH:mm')}}. 
                <f7-link @click="viewAllLog = true">Ver todo el log</f7-link>
            </f7c-alert>

            <f7-button v-if="!currentRegistro.rv.el" outline color="red" large class="margin-bottom" @click="handleDeleteRegistro">Eliminar vacuna</f7-button>
            <f7-button v-else outline color="green" large class="margin-bottom" @click="handleRestoreRegistro">Restaurar vacuna</f7-button>
        </f7-block>

        <f7-popup :opened="editPatientIdPopupOpened" @popup:closed="editPatientIdPopupOpened = false">
            <f7-page>
                <f7-navbar title="Editando ID de paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cancelar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-list class="margin">
                    <f7-list-input type="select" label="Tipo de documento" @change="editedTipoDoc = $event.target.value">
                        <option value="RUN" :selected="editedTipoDoc === 'RUN'">RUN</option>
                        <option value="PAS" :selected="editedTipoDoc === 'PAS'">PAS</option>
                        <option value="DNI" :selected="editedTipoDoc === 'DNI'">DNI</option>
                    </f7-list-input>
                    <f7-list-input
                        type="text"
                        label="Número de documento"
                        :value="editedNroDoc"
                        @change="editedNroDoc = $event.target.value"
                    ></f7-list-input>
                </f7-list>

                <f7-button fill large raised @click="editPatientId" class="margin">Editar registro</f7-button>
            </f7-page>
        </f7-popup>

        <f7-popup :opened="editEsquemaDosisVacunaPopupOpened" @popup:closed="editEsquemaDosisVacunaPopupOpened = false">
            <f7-page>
                <f7-navbar title="Editando información">
                    <f7-nav-right>
                        <f7-link popup-close>Cancelar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-list class="margin">
                    <f7-list-input
                        type="select"
                        label="Esquema"
                        @change="newEsquemaSelected = $event.target.value"
                        v-if="!isHomologacionInternacional"
                    >
                        <option
                            v-for="esquema in currentRegistro.campana.esquemas"
                            :key="esquema.id"
                            :value="esquema.id"
                            :selected="currentRegistro.rv.h === esquema.id"
                        >
                            {{ esquema.name }}
                        </option>
                    </f7-list-input>

                    <f7-list-input type="select" label="Dosis" @change="newDosisSelected = $event.target.value" v-if="!isHomologacionInternacional">
                        <option value="">Seleccione dosis</option>
                        <option v-for="dosis in dosisSegunEsquema" :key="dosis.id" :value="dosis.id" :selected="newDosisSelected === dosis.id">
                            {{ dosis.id }}
                        </option>
                    </f7-list-input>

                    <f7-list-input type="select" label="Vacuna" @change="newVacunaSelected = $event.target.value">
                        <option value="">Seleccione vacuna...</option>
                        <option
                            v-for="vacuna in vacunasToSelect"
                            :key="vacuna.vacunaPcId"
                            :value="vacuna.vacunaPcId"
                            :selected="newVacunaSelected === vacuna.vacunaPcId"
                        >
                            {{ vacuna.vacunaPcName }}
                        </option>
                    </f7-list-input>
                </f7-list>

                <f7-button fill large raised @click="editEsquemaDosisVacuna" class="margin">Editar registro</f7-button>
            </f7-page>
        </f7-popup>

        <f7-popup :opened="viewAllLog" @popup:closed="viewAllLog= false">
            <f7-page>
                <!-- <f7-navbar :title="`Logs registro`" :subtitle="`ID: ${currentRegistro.rv.id}`">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar> -->

                <f7-card>
                    <f7-card-header>Logs Registro de Inmunización</f7-card-header>
                    <f7-card-content>
                        <div class="timeline margin-vertical">
                            <div class="timeline-item" v-for="(log, ind) in currentRegistro.logs" :key="ind">
                                <div class="timeline-item-date text-align-center">{{ (ind + 1) }}</div>
                                <div class="timeline-item-divider"></div>
                                <div class="timeline-item-content">
                                    <f7-list class="no-margin-vertical" media-list>
                                        <f7-list-item
                                            class="titlebold"
                                            :subtitle="`Acción: ${log.i}`"
                                            :header="`Fecha: ${$dayjs.unix(log.x).format('dddd DD/MM/YYYY [a las] HH:mm')}`"
                                            :text="`Detalle: ${log.msg ? log.msg : '-'}`"
                                            :footer="`Usuario: ${log.w}`"
                                        >
                                        </f7-list-item>
                                    </f7-list>
                                </div>
                            </div>
                        </div>
                    </f7-card-content>
                </f7-card>
            </f7-page>
        </f7-popup>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
import Alert from "../../components/Alert.vue";

export default {
    components: {"f7c-alert": Alert},
    data() {
        return {
            editPatientIdPopupOpened: false,
            editedTipoDoc: "",
            editedNroDoc: "",

            editEsquemaDosisVacunaPopupOpened: false,

            newEsquemaSelected: "",
            newDosisSelected: "",
            newVacunaSelected: "",

            vacunasToSelect: {},

            vacunasCache: {},

            viewAllLog: false,
        };
    },

    mounted() {
        this.newEsquemaSelected = this.currentRegistro.rv.h;
        this.newDosisSelected = this.currentRegistro.rv.i;
        this.newVacunaSelected = this.currentRegistro.rv.b;
        console.log(this.currentRegistro);
    },

    watch: {
        async newEsquemaSelected() {
            this.newDosisSelected = "";
            this.newVacunaSelected = "";
            await this.fetchVacunas();
        },
        async newDosisSelected() {
            await this.fetchVacunas();
        },
    },

    computed: {
        ...mapState(["dev"]),

        ...mapState("editorRegistrosVacunas", ["currentRegistro"]),

        isHomologacionInternacional() {
            return this.currentRegistro.rv.cr === "homologacion_internacional";
        },

        currentRegistroEsquema() {
            if (this.isHomologacionInternacional) {
                return "homologacion_internacional";
            }

            const esquemaId = this.currentRegistro.rv.h;

            const esquema = this.currentRegistro.campana.esquemas[esquemaId];

            if (esquema) {
                return esquema.name;
            } else {
                ("Esquema no encontrado");
            }
        },

        dosisSegunEsquema() {
            if (!this.currentRegistro.campana.esquemas[this.newEsquemaSelected]) {
                return [];
            }

            return this.currentRegistro.campana.esquemas[this.newEsquemaSelected].cal || [];
        },

        currentRegistroVacunaAdministrada() {
            const vacunaId = this.currentRegistro.rv.b;

            const vacuna = this.vacunasCache[vacunaId];

            if (!vacuna) {
                return this.currentRegistro.vacuna;
            }

            return vacuna || {};
        },
    },

    methods: {
        async fetchVacunas() {
            if (!this.newDosisSelected && !this.isHomologacionInternacional) {
                return;
            }

            console.log("fetcheando vacunas");
            this.vacunasToSelect = {};

            const fetchVacunasPromises = [];

            if (this.isHomologacionInternacional) {
                const vacunasHomolgInternacionalSnap = await this.$firebase
                    .database()
                    .ref(`vacunas`)
                    .orderByChild("show_hmlg")
                    .equalTo(true)
                    .once("value");

                const vacunasHomolgInternacional = vacunasHomolgInternacionalSnap.val() || {};

                console.log("vacunasHomolgInternacional", vacunasHomolgInternacional);

                Object.values(vacunasHomolgInternacional).forEach(vacuna => {
                    this.$set(this.vacunasToSelect, vacuna.vacunaPcId, vacuna);
                    this.$set(this.vacunasCache, vacuna.vacunaPcId, vacuna);
                });
            } else {
                console.log("esquema selected", this.newEsquemaSelected);
                console.log("dosis selected", this.newDosisSelected);

                Object.values(this.currentRegistro.campana.esquemas[this.newEsquemaSelected].cal[this.newDosisSelected].req || {}).forEach(unReq => {
                    const vacunasToFetch = Object.keys(unReq.alts);

                    console.log("vacunas to fetch", vacunasToFetch);

                    vacunasToFetch.forEach(vacunaId => {
                        if (!this.vacunasCache[vacunaId]) {
                            console.log("vacuna no en cache, fetcheando");
                            fetchVacunasPromises.push(
                                this.$firebase
                                    .database()
                                    .ref(`vacunas/${vacunaId}`)
                                    .once("value")
                            );
                        } else {
                            console.log("vacuna en cache");
                            this.vacunasToSelect[vacunaId] = this.vacunasCache[vacunaId];
                        }
                    });
                });

                const vacunasSnap = await Promise.all(fetchVacunasPromises);

                vacunasSnap.forEach(vacunaSnapshot => {
                    const vacuna = vacunaSnapshot.val();

                    this.$set(this.vacunasToSelect, vacuna.vacunaPcId, vacuna);
                    this.$set(this.vacunasCache, vacuna.vacunaPcId, vacuna);
                });
            }
        },

        handleEditRegistroVacuna(field) {
            switch (field) {
                case "patientId":
                    const [tipoDoc, nroDoc, iso2] = this.currentRegistro.rv.a.split("_");

                    this.editedTipoDoc = tipoDoc;
                    this.editedNroDoc = `${nroDoc}`;

                    if (iso2) {
                        this.editedNroDoc = `${this.editedNroDoc}_${iso2}`;
                    }

                    this.editPatientIdPopupOpened = true;
                    break;
                case "lote":
                    this.$f7.dialog.prompt("Ingrese el lote", "Editando lote", async lote => {

                        const cleanLote = lote.replace(/[^a-zA-Z0-9]/g, "");
                        console.log("cleanLote", cleanLote);

                        if (cleanLote !== lote) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("El lote solo puede contener letras y números");
                            return;
                        }

                        if (lote.length > 20) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("El lote no puede tener más de 20 caracteres");
                            return;
                        }

                        if (lote === this.currentRegistro.rv.l) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("El lote ingresado es igual al actual");
                            return;
                        }

                        this.$f7.dialog.preloader("Actualizando lote...");

                        const editRegistroVacunacion = this.$firebase.functions().httpsCallable("admin-editRegistroVacunacion");

                        const { data } = await editRegistroVacunacion({
                            registroId: this.currentRegistro.rv.id,
                            patientId: this.currentRegistro.rv.a,
                            update: {
                                l: lote,
                            },
                            dev: this.dev ? true : false,
                        });

                        if (data.error) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(data.error.message || data.error.code);
                            return;
                        }

                        this.$store.commit("editorRegistrosVacunas/update_current_registro", data.updatedRv);

                        this.$f7.toast.show({
                            text: "Registro de vacunación editado correctamente.",
                            closeTimeout: 2000,
                            position: "bottom",
                            horizontalPosition: "center",
                        });

                        this.$f7.dialog.close();

                    }, () => {}, this.currentRegistro.rv.l || "");
                    break;
                case "fechaVacunacion":
                    this.$f7.dialog.prompt("Ingrese la fecha de vacunación DD/MM/YYYY", "Editando fecha", async fechaVacunacion => {
                        const validation = /^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;

                        const fechaIsValid = validation.test(fechaVacunacion);

                        if (!fechaIsValid) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("Fecha inválida");
                            return;
                        }

                        if (this.$dayjs(fechaVacunacion, "DD/MM/YYYY").isAfter(this.$dayjs())) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("Fecha de vacunación no puede ser posterior al día de hoy");
                            return;
                        }

                        if (this.$dayjs(this.currentRegistro.rv.d, "YYYYMMDD").format("DD/MM/YYYY") === fechaVacunacion) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("La fecha de vacunación no ha cambiado");
                            return;
                        }

                        this.$f7.dialog.preloader("Actualizando fecha...");

                        const editRegistroVacunacion = this.$firebase.functions().httpsCallable("admin-editRegistroVacunacion");

                        const { data } = await editRegistroVacunacion({
                            registroId: this.currentRegistro.rv.id,
                            patientId: this.currentRegistro.rv.a,
                            update: {
                                d: fechaVacunacion,
                            },
                            dev: this.dev ? true : false,
                        });

                        if (data.error) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(data.error.message || data.error.code);
                            return;
                        }

                        this.$store.commit("editorRegistrosVacunas/update_current_registro", data.updatedRv);

                        this.$f7.toast.show({
                            text: "Registro de vacunación editado correctamente.",
                            closeTimeout: 2000,
                            position: "bottom",
                            horizontalPosition: "center",
                        });

                        this.$f7.dialog.close();
                    });
                    break;
                case "vacuna":
                case "esquema":
                case "dosis":
                    this.editEsquemaDosisVacunaPopupOpened = true;
                    break;
            }
        },

        editEsquemaDosisVacuna() {
            const updateVacunaInfo = {};

            if (this.isHomologacionInternacional) {
                updateVacunaInfo.vacuna = this.newVacunaSelected;
            } else {
                updateVacunaInfo.esquema = this.newEsquemaSelected;
                updateVacunaInfo.dosis = this.newDosisSelected;
                updateVacunaInfo.vacuna = this.newVacunaSelected;
            }

            const errors = [];

            if (!this.isHomologacionInternacional) {
                if (!updateVacunaInfo.esquema) {
                    errors.push("Debe seleccionar un esquema");
                }
                if (!updateVacunaInfo.dosis) {
                    errors.push("Debe seleccionar una dosis");
                }
            }

            if (!updateVacunaInfo.vacuna) {
                errors.push("Debe seleccionar una vacuna");
            }

            if (errors.length > 0) {
                this.$f7.dialog.alert(errors.join(", "));
                return;
            }

            console.log("enviar esquema, dosis, vacuna a actualizar", updateVacunaInfo);
            this.$f7.dialog.confirm("¿Está seguro que desea actualizar el registro?", async () => {
                this.$f7.dialog.preloader("Actualizando registro...");

                const editRegistroVacunacion = this.$firebase.functions().httpsCallable("admin-editRegistroVacunacion");

                const { data } = await editRegistroVacunacion({
                    registroId: this.currentRegistro.rv.id,
                    patientId: this.currentRegistro.rv.a,
                    campaignId: this.currentRegistro.rv.g,
                    updateVacunaInfo,
                    isHomologacionInternacional: this.isHomologacionInternacional,
                    dev: this.dev ? true : false,
                });

                if (data.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(data.error.message || data.error.code);
                    return;
                }

                this.$store.commit("editorRegistrosVacunas/update_current_registro", data.updatedRv);

                this.editEsquemaDosisVacunaPopupOpened = false;

                this.$f7.toast.show({
                    text: "Registro de vacunación editado correctamente.",
                    closeTimeout: 2000,
                    position: "bottom",
                    horizontalPosition: "center",
                });

                this.$f7.dialog.close();
            });
        },

        cleanIdentity(rawIdentity) {
            return String(rawIdentity)
                .trim()
                .toUpperCase()
                .split(".")
                .join("")
                .split("-")
                .join("")
                .split(" ")
                .join("")
                .split("/")
                .join("")
                .split("[")
                .join("")
                .split("]")
                .join("")
                .split("#")
                .join("")
                .split("$")
                .join("");
        },

        editPatientId() {
            this.$f7.dialog.close();

            if (!this.editedTipoDoc || !this.editedNroDoc) {
                this.$f7.dialog.alert("Debe completar todos los campos");
                return;
            }

            const cleanedIdentity = this.cleanIdentity(this.editedNroDoc);

            const editedPatientIdentity = `${this.editedTipoDoc}_${cleanedIdentity}`;

            if (editedPatientIdentity === this.currentRegistro.rv.a) {
                this.$f7.dialog.alert("No se han detectado cambios en el registro de vacunación.");
                return;
            }

            this.$f7.dialog.confirm("¿Esta seguro?", async () => {
                this.$f7.dialog.preloader("Guardando cambios...");

                const editRegistroVacunacion = this.$firebase.functions().httpsCallable("admin-editRegistroVacunacion");

                const { data } = await editRegistroVacunacion({
                    registroId: this.currentRegistro.rv.id,
                    update: {
                        a: {
                            tipoDoc: this.editedTipoDoc,
                            nroDoc: cleanedIdentity,
                        },
                    },
                    dev: this.dev ? true : false,
                });

                if (data.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(data.error.message || data.error.code);
                    return;
                }

                this.$store.commit("editorRegistrosVacunas/update_current_registro", data.updatedRv);

                this.editPatientIdPopupOpened = false;

                this.$f7.toast.show({
                    text: "Registro de vacunación editado correctamente.",
                    closeTimeout: 2000,
                    position: "bottom",
                    horizontalPosition: "center",
                });

                this.$f7.dialog.close();
            });
        },

        handleDeleteRegistro() {
            this.$f7.dialog.confirm(
                "¿Está seguro que desea eliminar el registro?",
                "CUIDADO: Acción crítica",
                async () => {
                    try {
                        this.$f7.dialog.preloader("Eliminando registro de vacunación...");

                        const deleteRegistroVacunacion = this.$firebase.functions().httpsCallable("admin-deleteRegistroVacunacion");

                        const { data } = await deleteRegistroVacunacion({
                            registroId: this.currentRegistro.rv.id,
                            dev: this.dev ? true : false,
                        });

                        if (data.error) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(data.error.message || data.error.code);
                            return;
                        }


                        this.$f7.views.main.router.back();
                        this.$f7.dialog.close();

                        this.$f7.toast.show({
                            text: "Registro de vacunación eliminado correctamente.",
                            closeTimeout: 2000,
                            position: "bottom",
                            horizontalPosition: "center",
                        });
                    } catch (error) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                }
            );
        },
        handleRestoreRegistro() {
            this.$f7.dialog.confirm(
                "¿Está seguro que desea restaurar el registro?",
                "CUIDADO: Acción crítica",
                async () => {
                    try {
                        this.$f7.dialog.preloader("Restaurando registro de vacunación...");

                        const restoreRegistroVacunacion = this.$firebase.functions().httpsCallable("admin-restoreRegistroVacunacion");

                        const { data } = await restoreRegistroVacunacion({
                            registroId: this.currentRegistro.rv.id,
                            dev: this.dev ? true : false,
                        });

                        if (data.error) {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert(data.error.message || data.error.code);
                            return;
                        }


                        this.$f7.views.main.router.back();
                        this.$f7.dialog.close();

                        this.$f7.toast.show({
                            text: "Registro de vacunación restaurado correctamente.",
                            closeTimeout: 2000,
                            position: "bottom",
                            horizontalPosition: "center",
                        });
                    } catch (error) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message, error.code);
                    }
                }
            );
        },
    },
};
</script>
