<template>
    <f7-page name="comprobantes">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="de vacunación" title="Información"></f7-nav-title>
        </f7-navbar>

        <f7-block>
            <f7-row no-gap>
                <f7-col width="30">
                    <f7-list class="no-margin">
                        <f7-list-input
                            label="Tipo documento"
                            type="select"
                            defaultValue="RUN"
                            :value="tipodoc"
                            @input="tipodoc = $event.target.value"
                        >
                            <option selected value="RUN">RUN</option>
                            <option value="PAS">Pasaporte</option>
                            <option value="DNI">DNI</option>
                        </f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col width="70">
                    <f7-list class="no-margin">
                        <f7-list-input
                            label="Documento"
                            type="text"
                            placeholder="Número de documento"
                            :value="busqueda"
                            @keyup.native.enter="preSearchPatients"
                            @input="busqueda = $event.target.value"
                            clear-button
                        ></f7-list-input>
                    </f7-list>
                </f7-col>
            </f7-row>
            <f7-row no-gap>
                <f7-col width="33">
                    <f7-list class="no-margin">
                        <f7-list-input
                            label="Nombres"
                            type="text"
                            :value="nombres"
                            @input="nombres = $event.target.value"
                            @keyup.native.enter="preSearchPatients"
                            clear-button
                        ></f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col width="33">
                    <f7-list class="no-margin">
                        <f7-list-input
                            label="Apellido 1"
                            type="text"
                            :value="apellido1"
                            @input="apellido1 = $event.target.value"
                            @keyup.native.enter="preSearchPatients"
                            clear-button
                        ></f7-list-input>
                    </f7-list>
                </f7-col>
                <f7-col width="33">
                    <f7-list class="no-margin">
                        <f7-list-input
                            label="Apellido 2"
                            type="text"
                            :value="apellido2"
                            @input="apellido2 = $event.target.value"
                            @keyup.native.enter="preSearchPatients"
                            clear-button
                        ></f7-list-input>
                    </f7-list>
                </f7-col>
            </f7-row>

            <f7-button class="normaltobu padding-top-half" @click="preSearchPatients" fill large raised>
                <font-awesome-icon class="fa-lg" icon="search"></font-awesome-icon>
                Buscar pacientes
            </f7-button>
        </f7-block>

        <f7-list v-if="!buscando && existe" inset media-list>
            <f7-list-item divider title="Información del Paciente"></f7-list-item>
            <f7-list-item
                accordion-item
                :header="`id: ${existe.id}`"
                :title="`${existe.apellido1 || ''} ${existe.apellido2 || ''}, ${existe.nombres || ''}`"
                :subtitle="`${existe.idenType} ${existe.idenType == 'RUN' ? formatRut(existe.identity) : existe.identity}  | ${existe.nac_iso2}`"
                :text="`${$dayjs(existe.fechanac).format('DD/MM/YYYY')} - ${suageStrg(existe.fechanac)}`"
            >
                <font-awesome-icon
                    slot="media"
                    class="fa-3x"
                    :class="existe.sexo == 'Intersex' ? 'text-color-gray' : existe.sexo == 'F' ? 'text-color-pink' : 'text-color-blue'"
                    icon="user-circle"
                ></font-awesome-icon>
                <span slot="footer">
                    {{ `Datos Verificados: ${existe.src || 'admin'} ${existe.mdf ? '| Editado el ' + $dayjs(existe.mdf).format('DD/MM/YYYY[ a las ]HH:mm:ss') : ''}` }}
                    | <f7-link @click.stop="viewLog">Ver todo el log</f7-link>
                </span>
                <f7-accordion-content>
                    <f7-list media-list>
                        <f7-list-item
                            header="Tipo de documento"
                            @click="changeTypeAndDocumentPopOpen()"
                            :title="`${existe.idenType} ${existe.idenType == 'RUN' ? formatRut(existe.identity) : existe.identity}`"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Email"
                            @click="editaString('email')"
                            :title="`${this.validuser ? this.reguser.em : ''}`"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Nacionalidad"
                            @click="fetchPaisesAndGo()"
                            :title="labelNacionalidad"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Primer apellido"
                            @click="editaString('apellido1')"
                            :title="existe.apellido1"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Segundo apellido"
                            @click="editaString('apellido2')"
                            :title="existe.apellido2"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Nombres"
                            @click="editaString('nombres')"
                            :title="existe.nombres"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Nombre Social"
                            @click="editaString('socialName')"
                            :title="existe.socialName || '-'"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Fecha de nacimiento"
                            @click="editaFechanac()"
                            :title="`${$dayjs(existe.fechanac).format('DD [de] MMMM [de] YYYY')}`"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>

                        <f7-list-item
                            header="Sexo"
                            popover-open=".popover-mutasexo"
                            :title="`${$store.state.sexo[existe.sexo]}`"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item
                            header="Identidad de género"
                            popover-open=".popover-mutagenero"
                            :title="displayGender"
                            link="#"
                            :disabled="!hasEditPatientPrivilege"
                        >
                            <font-awesome-icon slot="after" icon="pencil-alt"></font-awesome-icon>
                        </f7-list-item>
                        <f7-list-item v-if="existe.idenType === 'RUN'">
                            <f7-button @click="editandoReal('recall', 'save')" outline>Sobreescribir con API Registro Civil</f7-button>
                        </f7-list-item>
                    </f7-list>
                </f7-accordion-content>
            </f7-list-item>
            <f7-list-item divider title="Usuario verificado"></f7-list-item>
            <f7-list-item v-if="!validuser" title="No hay un usuario verificado para este paciente"></f7-list-item>
            <f7-list-item
                v-else
                :header="`uid: ${validuser}`"
                :title="`${reguser.em}`"
                :subtitle="`Vía: ${reguser.verified}`"
                :text="
                    reguser.contc_pref && reguser.contc_pref.address
                        ? `${reguser.contc_pref.address.comuna} ${reguser.contc_pref.address.provincia}`
                        : 'Sin dirección'
                "
                :footer="reguser.contc_pref && reguser.contc_pref.phone ? reguser.contc_pref.phone : 'Sin teléfono declarado'"
            >
            </f7-list-item>
            <f7-list-item divider title="Consultas"></f7-list-item>
            <f7-list-item title="Historial Vacunación" footer="Ver y descargar comprobantes" @click="pidecamps()" link="#"></f7-list-item>
            <f7-list-item title="Solicitudes validación vacunas PNI" footer="Listado vacunas aprobdas" @click="pidesolicitudes()" link="#"></f7-list-item>
        </f7-list>

        <f7-popover class="popover-mutasexo">
            <f7-list>
                <f7-list-item
                    v-for="(sexo, sexoid) in $store.state.sexo"
                    :key="sexoid"
                    radio
                    :value="sexoid"
                    name="muta-sexo-admn"
                    :checked="existe && sexoid === existe.sexo"
                    @change="mutasexo($event.target.value)"
                    :title="sexo"
                ></f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popover class="popover-mutagenero">
            <f7-list>
                <f7-list-item
                        v-for="item in generos"
                        :key="item.id"
                        :title="item.name"
                        @click="mutagenero(item.id)"
                        radio
                        :checked="existe && item.id === existe.genero"
                    ></f7-list-item>
            </f7-list>
        </f7-popover>

        <f7-popup class="tipo-numero-documento" :opened="popupOpened" @popup:closed="closePopup">
            <f7-page>
                <f7-navbar title="Cambiar tipo y número de documento">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <f7-list class="no-margin" no-hairlines>
                    <f7-list-input
                        class="no-margin"
                        no-hairlines
                        label="Tipo de documento"
                        type="select"
                        defaultValue="RUN"
                        :value="tipodoc"
                        @input="tipodoc = $event.target.value"
                    >
                        <option selected value="RUN">RUN</option>
                        <option selected value="PAS">PAS</option>
                        <option selected value="DNI">DNI</option>
                    </f7-list-input>
                </f7-list>
                <f7-list class="no-margin" no-hairlines>
                    <f7-list-input
                        label="Número de documento"
                        type="text"
                        placeholder="valor a buscar"
                        :value="numeroDocumento"
                        @input="numeroDocumento = $event.target.value"
                        clear-button
                    ></f7-list-input>
                </f7-list>

                <f7-button @click="changeTypeAndDocument()">Guardar</f7-button>
            </f7-page>
        </f7-popup>

        <select-patient-popup
            :opened="selectPatientPopupOpened"
            :patients="patientsToSelect"
            @popup-closed="selectPatientPopupOpened = false"
            @patient-selected="handlePatientSelected"
        ></select-patient-popup>

        <PatientLogs :opened="logPopupOpened" @closed="logPopupOpened = false" :patient-id="viewingPatientId" />
    </f7-page>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin, cleanAndFormatValue, validaRut } from "../mixins/common";
import SelectPatientPopup from "../components/select-patient-popup.vue";
import PatientLogs from "../components/PatientLogs.vue";
import * as EmailValidator from "email-validator";

export default {
    mixins: [commonmixin],

    components: {
        "select-patient-popup": SelectPatientPopup,
        PatientLogs,
    },

    data() {
        return {
            buscado: "",
            tipodoc: "RUN",
            numeroDocumento: "",
            email: "",
            nombres: "",
            apellido1: "",
            apellido2: "",
            busqueda: "",
            existe: null,
            validuser: null,
            reguser: null,
            buscando: false,
            popupOpened: false,
            patientsToSelect: [],
            selectPatientPopupOpened: false,
            nacionalidad: "",
            generos: [
                { id: "M", name: "Masculino" },
                { id: "F", name: "Femenina" },
                { id: "TM", name: "Transgénero Masculino" },
                { id: "TF", name: "Transgénero Femenina" },
                { id: "NB", name: "No binarie" },
                { id: "NR", name: "No revelado"},
                { id: "O", name: "Otro" },
            ],
            viewingPatientId: "",
            logPopupOpened: false,
        };
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["centralAdminAccount"]),
        tipodocarr() {
            return this.existe ? this.existe.id.split("_") : [];
        },
        labelNacionalidad() {
            let nac_iso2 = "";
            console.log(this.$store.state.update_nationality);
            if (this.$store.state.decl_id_pais) {
                nac_iso2 = this.$store.state.decl_id_pais;
                this.existe.nac_iso2 = this.$store.state.decl_id_pais;
            } else {
                nac_iso2 = this.existe.nac_iso2;
            }
            if (this.$store.state.update_nationality) {
                let update_nationality = this.$store.state.update_nationality;
                if (update_nationality == true) {
                    console.log("entra");
                    this.editandoReal("nacionalidad", this.$store.state.decl_id_pais);
                    this.$store.commit("setWhatTo", { what: "update_nationality", to: false });
                }
            }
            return nac_iso2;
        },
        hasEditPatientPrivilege() {
            return this.centralAdminAccount.prv.edita_paciente;
        },
        displayGender() {
            return this.existe.genero ? this.generos.find((x) => x.id == this.existe.genero).name : "-";
        },
    },
    beforeCreate() {
        this.$store.commit("setWhatTo", { what: "update_nationality", to: false });
    },
    methods: {
        viewLog() {
            this.viewingPatientId = this.existe.id;
            this.logPopupOpened = true;
        },
        mutasexo(nuevosexo) {
            console.log("mutando sexo a ", nuevosexo);
            this.$f7.popover.close(".popover-mutasexo", true);
            this.editandoReal("sexo", nuevosexo);
        },
        mutagenero(nuevogenero) {
            console.log("mutando genero a ", nuevogenero);
            this.$f7.popover.close(".popover-mutagenero", true);
            this.editandoReal("genero", nuevogenero);
        },
        editaFechanac() {
            this.$f7.dialog.prompt(
                `Nueva fecha de nacimiento (DD/MM/YYYY)`,
                "Editar",
                nuevovalor => {
                    this.editandoReal("fechanac", nuevovalor);
                },
                () => {
                    console.log("Abortando edit");
                },
                this.$dayjs(this.existe.fechanac).format("DD/MM/YYYY")
            );
        },
        editaString(cual) {
            let placeholder = cual === "email" && this.reguser ? this.reguser.em : this.existe[cual];

            this.$f7.dialog.prompt(
                `Nuevo valor para ${cual}`,
                "Editar",
                nuevovalor => {
                    this.editandoReal(cual, nuevovalor);
                },
                () => {
                    console.log("Abortando edit");
                },
                placeholder
            );
        },
        fetchPaisesAndGo() {
            if (!this.$store.getters.paisesArraySorted.length) {
                this.$f7.dialog.preloader("Cargando paises");
                this.$firebase
                    .database()
                    .ref("paises")
                    .once("value")
                    .then(snps => {
                        this.$store.commit("setWhatTo", { what: "paises", to: snps.val() });
                        this.$f7.dialog.close();
                        this.$f7.views.main.router.navigate("/paises/");
                    })
                    .catch(error => {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            } else {
                this.$f7.views.main.router.navigate("/paises/");
            }
        },
        async editandoReal(cual, nuevovalor) {
            try {
                console.log(cual, nuevovalor);

                if (cual === "email" && !EmailValidator.validate(nuevovalor)) {
                    throw new Error("Email parece ser inválido");
                }

                this.$f7.dialog.preloader("Guardando...");

                let nroDoc = this.tipodocarr[1];

                if (this.tipodocarr.length === 3) {
                    nroDoc += `_${this.tipodocarr[2]}`;
                }

                let datous = await this.commonExecute(
                    { idenType: this.tipodocarr[0], identity: nroDoc, propiedad: cual, valor: nuevovalor },
                    "admin-mutaInfoPaxPro",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                //existe
                this.existe = Object.assign({}, this.existe, datous.payload);
                console.log("datous", datous);
                if (cual === "email") {
                    if (datous.reguser) {
                        this.validuser = datous.reguser;
                        this.reguser = datous.reguser;
                    } else {
                        this.reguser.em = nuevovalor;
                    }
                }
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async pidecamps() {
            try {
                let nroDoc = this.tipodocarr[1];

                if (this.tipodocarr.length === 3) {
                    nroDoc += `_${this.tipodocarr[2]}`;
                }

                this.$f7.dialog.preloader("Cargando Info...");
                let datous = await this.commonExecute({ idenType: this.tipodocarr[0], identity: nroDoc }, "admin-comprobanteSusCampaxs", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                this.$f7.dialog.close();
                this.$f7.views.main.router.navigate("/copmrobanteficha/", { props: { existe: this.existe, acumuladorPorCampanas: datous.payload } });
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async pidesolicitudes() {
            try {
                let nroDoc = this.tipodocarr[1];

                if (this.tipodocarr.length === 3) {
                    nroDoc += `_${this.tipodocarr[2]}`;
                }

                this.$f7.dialog.preloader("Cargando Info...");
                let datous = await this.commonExecute({ idenType: this.tipodocarr[0], identity: nroDoc }, "admin-getPatientSolicitudesPNI", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                this.$f7.dialog.close();
                this.$f7.views.main.router.navigate("/comprobante_sol_nac/", { props: { solicitudes: datous.solicitudesNac } });
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        changeTypeAndDocumentPopOpen() {
            this.popupOpened = true;
        },
        async changeTypeAndDocument() {
            try {
                let cual = "tipo_numero_documento";
                let cleanIdentity = this.cleanAndFormatValue(this.numeroDocumento);

                if (this.tipodoc === "RUN" && !this.validaRut(cleanIdentity)) {
                    throw new Error("RUT no parece válido.");
                }
                let nuevovalor = `${this.tipodoc}_${cleanIdentity}`;
                this.editandoReal(cual, nuevovalor);
                this.popupOpened = false;
            } catch (error) {
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },

        async preSearchPatients() {
            this.$store.commit("setWhatTo", { what: "decl_id_pais", to: null });
            const trimBusqueda = this.busqueda.trim();
            this.numeroDocumento = trimBusqueda;

            /* if (this.busqueda.length < 2) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe ingresar al menos 2 caracteres");
                return;
            } */

            if (false) {
                await this.buscar();
            } else {
                this.$f7.dialog.preloader("Buscando...");

                const response = await this.commonExecute(
                    { tipoDoc: this.tipodoc, nroDoc: trimBusqueda, email: this.email, nombres: this.nombres, apellido1: this.apellido1, apellido2: this.apellido2, dev: this.dev },
                    "admin-preSearchPatient",
                    false
                );

                if (response.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(response.error.message);
                    return;
                }

                const { patients } = response;

                if (patients.length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron pacientes");
                    return;
                }

                if (patients.length === 1 && patients[0].nroDoc === trimBusqueda) {
                    this.$f7.dialog.close();

                    const patient = patients[0];

                    this.tipodoc = patient.idenType;
                    this.busqueda = patient.identity;

                    await this.buscar();

                    return;
                }

                this.patientsToSelect = patients;
                this.selectPatientPopupOpened = true;

                this.$f7.dialog.close();
            }
        },

        async handlePatientSelected(patient) {
            this.tipodoc = patient.idenType;
            this.busqueda = patient.identity;

            await this.buscar();

            this.patientsToSelect = [];
            this.selectPatientPopupOpened = false;
        },

        async buscar() {
            try {
                this.buscando = true;
                this.buscado = "";
                this.existe = null;
                this.validuser = null;
                this.reguser = null;
                console.log(this.busqueda);
                this.$f7.dialog.preloader("Buscando...");

                // let datous = await this.commonExecute({idenType:this.tipodoc, identity: this.busqueda },'admin-comprobantegetpax',false);
                let datous = await this.commonExecute({ idenType: this.tipodoc, identity: this.busqueda }, "admin-comprobanteJustPax", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log(datous.payload);
                if (!datous.payload.existe) {
                    throw new Error("Paciente no existe o no tiene registros");
                }
                this.existe = datous.payload.existe;
                this.validuser = datous.payload.validuser;
                this.reguser = datous.payload.reguser;
                this.buscando = false;
                console.log(this.existe, this.validuser, this.reguser);
                console.log(this.validuser);
                // this.$f7.views.main.router.navigate('/copmrobanteficha/',{props:datous.payload})

                this.$f7.dialog.close();
                this.buscado = this.busqueda;
            } catch (error) {
                this.buscando = false;
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        closePopup() {
            this.popupOpened = false;
        },
    },
};
</script>
<style>
.normaltobu {
    height: 67px !important;
}
.popup-tomas {
    max-height: 400px;
    overflow-y: auto;
}

.tomaslinfoxs.accordion-item .item-link {
    background: #e6fffc !important;
}
.popover-mutagenero {
    width: 300px;
}
</style>
