<template>
  <f7-page name="vacuna">

        <f7-navbar :title="vacunaenfoco.vacunaPcName" :subtitle="`${vacunaenfoco.presentacion} | ${vacunaenfoco.laboratorioName}`" back-link="Back">
          <f7-nav-right>
                  <f7-block>  <f7-chip outline :text="vacunaenfoco.borrador?'Borrador':'Publicada'" :color="vacunaenfoco.borrador?'red':'teal'" ></f7-chip></f7-block>
          </f7-nav-right>
        </f7-navbar>
   


<f7-list inset class="marfixicons">
      <f7-list-item @click="updtMapeo()" link="#" header="Mapeo a Terminología" :title="vacunaenfoco.mapeoid || 'Sin mapeo'">
             <f7-preloader slot="after" v-if="updt.mapeoid"></f7-preloader>
             <font-awesome-icon v-else slot="after" icon="pencil-alt"></font-awesome-icon>
              <font-awesome-icon slot="media" icon="book-medical" class="fa-lg text-color-primary"/>
           </f7-list-item>
</f7-list>


<f7-card>
  <f7-card-header class="no-padding">
    <f7-list class="marfixicons">
      <f7-list-item title="Patógenos que cubre">
              <font-awesome-icon slot="media" icon="viruses" class="fa-lg text-color-primary"/>
      </f7-list-item>
</f7-list>
  </f7-card-header>
  <f7-card-content>
<f7-block class="margin-vertical-half">
    <p v-if="!vacunaenfoco.cobertura_detai || !Object.keys(vacunaenfoco.cobertura_detai).length">Sin coberturas declaradas</p>
            <f7-chip
                class="chip margin-right-half"
                v-for="(unpat, idx) in vacunaenfoco.cobertura_detai"
                :key="idx"
                :text="unpat.nm"
                deleteable
                @click="modificaPatogeno(idx,'elimina_patogeno')"
            ></f7-chip>
</f7-block>
</f7-card-content>
  <f7-card-footer class="display-block fondogrisagregar" v-if="agregarNuevopatogeno">
    <f7-row>
      <f7-col class="padding-top-half" width="20"><f7-button @click="agregarNuevopatogeno = false" class="margin-top" color="red">Cancelar</f7-button></f7-col>
      <f7-col width="80">
           <crear-patogeno @closeDuplicate="avisaduplicado" :cobertura="vacunaenfoco.cobertura_detai" @patogenoAAgregar="addPatogen"></crear-patogeno>
      </f7-col>
    </f7-row>
    </f7-card-footer>
     <f7-card-footer v-else>
    <span></span><f7-button v-if="!updt.patogen" @click="agregarNuevopatogeno = true" outline><font-awesome-icon icon="plus"></font-awesome-icon> Agregar cobertura a otro patógeno</f7-button><f7-block v-else><f7-preloader></f7-preloader> Guardando...</f7-block>
    </f7-card-footer>
</f7-card>

<f7-block class="text-align-center">
  <f7-preloader v-if="updt.statuschange"></f7-preloader>
  <f7-button v-else-if="!vacunaenfoco.borrador" @click="cambioEstadoPublicacion()" large color="red">Despublicar Vacuna</f7-button>
  <f7-button v-else large fill raised  @click="cambioEstadoPublicacion()" color="teal">Re-Publicar Vacuna</f7-button>
</f7-block>

  </f7-page>
</template>
<script>
import { commonmixin } from '../mixins/common'
import crearPatogeno from '../components/nuevopatogeno.vue';

export default {
  mixins: [commonmixin],
  components:{crearPatogeno},
  props:['id'],
    data() {
      return {
        agregarNuevopatogeno: false,
       updt:{
         mapeoid: false,
         statuschange: false,
         patogen: false
       }
      }
    },
    computed:{
     vacunaenfoco(){
        return this.$store.getters.getUno('vacunas',this.id);
      }
    },
    methods:{
        avisaduplicado(patogenid){
          this.agregarNuevopatogeno = false;
          let patogeno = this.$store.getters.getUno('patogenos',patogenid);
          return this.$f7.dialog.alert(patogeno.nm+ ' ya existe en cobertura declarada','Duplicado')
        },
        addPatogen(patogenid){
           this.agregarNuevopatogeno = false;
           this.modificaPatogeno(patogenid,'agrega_patogeno')
        },
        cambioEstadoPublicacion(){
        if(this.vacunaenfoco.borrador){
          this.guardaPublicStatus();
        }
        else{
          this.$f7.dialog.confirm('Todos los vacunatorios ya no podrán recibir mas stock y ofrecer esta vacuna','Confirme que desea despublicar esta Vacuna', ()=> {
           this.guardaPublicStatus();
          });
        }
      },
       async guardaPublicStatus(){
         try {
             this.updt.statuschange=true;
            let datous = await this.commonExecute({id: this.id, action:this.vacunaenfoco.borrador?'publica':'despublica', valor: true},'admin-editaVacunaInfo',false);
             this.updt.statuschange=false;
            if(datous.error){
              throw new Error(datous.error.message);
            }
           // console.log('creacion ok',datous);
           this.$store.commit(
             'updtObjProperty',{
               what:'vacunas',
               to: Object.assign({},this.vacunaenfoco,
                 { borrador: !this.vacunaenfoco.borrador, id: this.vacunaenfoco.vacunaPcId }
              )
            });
            this.avisaOk();
             } catch (error) {
              this.updt.statuschange=false;
              console.error(error);
              this.$f7.dialog.alert(error.message, error.code);
          }
      },
       updtMapeo(){
        return this.$f7.dialog.prompt('Indique nuevo mapeo', (nuevomapeoid)=> {
            this.guardaMapeo(nuevomapeoid === "" ? nuevomapeoid : nuevomapeoid.trim());
        },()=>{},this.vacunaenfoco.mapeoid);
      },
       async guardaMapeo(nuevomapeoid){
         try {
             this.updt.mapeoid=true;
            let datous = await this.commonExecute({id: this.id, action:'nuevo_mapeo', valor: nuevomapeoid},'admin-editaVacunaInfo',false);
             this.updt.mapeoid=false;
            if(datous.error){
              throw new Error(datous.error.message);
            }
            //console.log('creacion ok',datous);
           this.$store.commit(
             'updtObjProperty',{
               what:'vacunas',
               to: Object.assign({},this.vacunaenfoco,
                 { mapeoid:nuevomapeoid, id: this.vacunaenfoco.vacunaPcId }
              )
            });
            this.avisaOk();
             } catch (error) {
              this.updt.mapeoid=false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
        async modificaPatogeno(patogenoid,action){
         try {
             this.updt.patogen=true;
            let datous = await this.commonExecute({id: this.id, action, valor: patogenoid},'admin-editaVacunaInfo',false);
             this.updt.patogen=false;
            if(datous.error){
              throw new Error(datous.error.message);
            }
           // console.log('creacion ok',datous);
            if(action=='agrega_patogeno'){
                let patogeno = this.$store.getters.getUno('patogenos',patogenoid);
                this.$store.commit('addItemAColeccionNested',{vid: this.id, pid: patogenoid,col:'vacunas',prop: 'cobertura_detai', val: patogeno })
            }
            else{
                this.$store.commit('borrarItemDeColeccionNested',{vid: this.id,col:'vacunas', prop: 'cobertura_detai', pid: patogenoid });
            }
            this.avisaOk();
             } catch (error) {
              this.updt.patogen=false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
    }
}
</script>
<style>
.fondogrisagregar{
  background: #f3f3f3;
}

</style>