<template>
    <f7-page @page:beforein="fetchLogs">
        <f7-navbar back-link>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title>Histórico de solicitudes aprobadas</f7-nav-title>
            <f7-nav-right>
                <f7-block class="no-margin no-padding">
                    <f7-link tooltip="Rango de fechas" @click="openPopup"><font-awesome-icon icon="calendar"></font-awesome-icon></f7-link>
                </f7-block>
                <f7-block class="no-margin no-padding">
                    <f7-link tooltip="Busqueda" popover-open=".popover-busqueda"><font-awesome-icon icon="search"></font-awesome-icon></f7-link>
                </f7-block>

                <f7-block class="no-margin no-padding">
                    <f7-link  @click="fetchLogs(true, false)" tooltip="Actualizar listado">
                        <font-awesome-icon icon="sync-alt"></font-awesome-icon>
                    </f7-link>
                </f7-block>
            </f7-nav-right>
        </f7-navbar>

        <f7-popup class="rango-fechas" :opened="popupOpened" @popup:closed="closePopup">
            <f7-page>
                <f7-navbar title="Buscar solicitudes aprobadas">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <div class="margin">
                    <p>Tipo de búsqueda</p>
                </div>

                <f7-list class="no-margin" no-hairlines>
                    <f7-list-input
                            id="dateRange"
                            label="Rango de fechas"
                            type="datepicker"
                            clear-button
                            :calendar-params="{
                                dateFormat: 'dd-mm-yyyy',
                                locale: 'es',
                                rangePicker: true,
                                maxDate: new Date().setDate(new Date().getDate()),
                            }"
                            @change="filters.rangeDate = $event.target.value"
                        ></f7-list-input>
                </f7-list>

                <f7-button @click="filterByDateRange()">Buscar</f7-button>
            </f7-page>
        </f7-popup>

        <f7-popover class="popover-busqueda">
            <f7-list class="no-margin">
                <f7-list-input label="Tipo búsqueda" type="select" defaultValue="email" :value="tipoSearch" @input="tipoSearch = $event.target.value">
                    <option selected value="email">e-mail</option>
                    <option selected value="send/identity">RUN/PAS/DNI</option>
                </f7-list-input>
                <f7-list-input
                    label="valor"
                    type="text"
                    :placeholder="`${tipoSearch == 'email' ? 'usuario@test.cl' : '11223334'}`"
                    :value="busqueda"
                    @keyup.native.enter="buscar()"
                    @input="busqueda = $event.target.value"
                    clear-button
                >
                </f7-list-input>
            </f7-list>
            <f7-button @click="buscar()">Buscar</f7-button>
        </f7-popover>

        <f7-row v-if="loading">
            <f7-col class="text-align-center">
                <f7-preloader></f7-preloader>
            </f7-col>
        </f7-row>        
        <template v-else-if="Object.values(logs).length > 0">
            <div class="margin-half">
                Viendo lista con filtro: <b>{{ filters[selectedFilter] || "Unknown" }}</b>
            </div>
            <f7-block-title class="margin-top">
                Mostrando {{ Object.values(logs).length }} aprobadas
            </f7-block-title>

            <f7-list class="no-margin-vertical" media-list>
                <ul>
                    <f7-list-item 
                        v-for="log in filtersData"
                        :key="log.id"
                        class="text-overflow"
                        :style="`top: ${filtersData.topPosition}px`"
                    >
                        <span slot="header">Ingresada el {{ $dayjs.unix(log.wh).format("DD/MM/YYYY [a las] HH:mm:ss") }}</span>
                        <span slot="title" class="text-color-blue">{{ log.answ_at ? `Aprobada el ${$dayjs.unix(log.answ_at).format("DD/MM/YYYY [a las] HH:mm:ss")}` : "Sin fecha de aprobación especificada" }}</span>
                        <span slot="text">
                            <b>Documento:</b> {{ `${log.send.idenType} ${log.send.identity}` }}<br>
                            <b>Nombre:</b> {{ formatPatientName(log) }}<br>
                            <b>Sexo:</b> {{ log.send.sexo }}<br>
                            <b>Nacionalidad:</b> {{ log.send.nacionalidad }}<br>
                        </span>
                        <span slot="after">Aprobada por {{ log.answ_by }}</span>
                    </f7-list-item>
                </ul>
            </f7-list>
        </template>
        <template v-else-if="!loading && !logs.length">
            <div class="margin-half">
                Viendo lista con filtro: <b>{{ filters[selectedFilter] || "Unknown" }}</b>
            </div>
            <f7-block-title class="margin-top">
                Mostrando {{ Object.values(logs).length }} aprobadas
            </f7-block-title>

            <f7-list class="no-margin-vertical" media-list>
                <ul>
                    <f7-list-item  title="No se encontraron resultados"></f7-list-item>
                </ul>
            </f7-list>
        </template>

        <f7-button v-if="hideLoadMore" @click="fetchLogs(false, true)" fill class="margin">Cargar más solicitudes</f7-button>
        <p v-else-if="!hideLoadMore && logs.length != 0" class="margin-horizontal text-align-center text-color-gray">
            Se cargaron todas las aprobadas
        </p>
    </f7-page>
</template>

<script>
export default {
    data() {
        return {
            logs: [],

            vlData: {
                items: [],
            },
            busqueda: "",
            tipoSearch: "email",
            fetchSize: 30,
            loading: false,
            loadingMore: false,
            dateFilterStart: this.$dayjs().subtract(1, 'days').startOf('day').unix(),
            dateFilterEnd: this.$dayjs().endOf('day').unix(),
            hideLoadMore: true,
            selectedFilter: "default",
            popupOpened: false,
            filters: {
                default: `Fecha desde ${this.$dayjs().subtract(1, 'days').format('DD-MM-YYYY')} - hasta ${this.$dayjs().format('DD-MM-YYYY')}`,
                rangeDate: "",
                rangeDateFilter: "",
                email: "",
                paxID: ""
            },
        }
    },

    computed: {
        filtersData(){
            return this.logs
        }
    },

    methods: {
        async fetchLogs(update = false, addFetchSize = false) {
            try {
                if(update){
                    this.loading = true;
                    this.selectedFilter = "default";
                    this.dateFilterStart = this.$dayjs().subtract(1, 'days').startOf('day').unix();
                    this.dateFilterEnd = this.$dayjs().endOf('day').unix();
                    this.fetchSize = 30;
                }
                const env = this.$store.state.dev ? "d" : "p";

                if (addFetchSize) {
                    this.$f7.dialog.preloader("Cargando más solicitudes");
                    this.fetchSize += 30;
                } else {
                    this.loading = true;
                }                
                const snapshot = await this.$firebase_coreapp
                    .database(this.$store.getters.usrtrxDatabaseUrl)
                    .ref(`hstr_slcs/${env}`)
                    .orderByChild("wh")
                    .startAt(this.dateFilterStart)
                    .endAt(this.dateFilterEnd)
                    .limitToLast(this.fetchSize)
                    .once("value")

                const logs = snapshot.val() || {};

                const logsEntries = Object.entries(logs);


                let  logsEntriesUpdate = logsEntries.filter( item => {
                    if (!item[1].rjt) {
                        return item
                    }
                })                

                logsEntriesUpdate = logsEntriesUpdate.sort((x,y) => {
                    return y[1].wh - x[1].wh 
                })

                if(logsEntriesUpdate.length == this.logs.length){
                    this.hideLoadMore = false; 
                }else{
                    this.hideLoadMore = true; 
                }

                this.logs = Object.values(Object.fromEntries(logsEntriesUpdate));

                this.loading = false;
                this.$f7.dialog.close();
            } catch (error) {
                this.loading = false;
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },

        async filterByDateRange(addFetchSize){
            try {
                this.loading = true;
                const range = this.formattedDates()
                const env = this.$store.state.dev ? "d" : "p";
                let dateStart;
                let dateEnd;
                
                if(range.startDate != '' && range.endDate != ''){
                    this.dateFilterStart = this.$dayjs(range.startDate, "DD-MM-YYYY").startOf('day').unix();
                    this.dateFilterEnd = this.$dayjs(range.endDate, "DD-MM-YYYY").endOf('day').unix();
                }else{
                    if(range.endDate == '' && range.startDate != '') {
                        console.log("ingreso")
                        this.dateFilterStart = this.$dayjs(range.startDate, "DD-MM-YYYY").startOf('day').unix();
                        this.dateFilterEnd = this.$dayjs(range.startDate, "DD-MM-YYYY").endOf('day').unix();
                        range.endDate = range.startDate
                    }else {
                        this.dateFilterStart = this.$dayjs().subtract(7, 'days').startOf('day').unix();
                        this.dateFilterEnd = this.$dayjs().endOf('day').unix();
                    }
                }

                this.fetchSize = 30;
                
                const snapshot = await this.$firebase_coreapp
                    .database(this.$store.getters.usrtrxDatabaseUrl)
                    .ref(`hstr_slcs/${env}`)
                    .orderByChild("wh")
                    .startAt(this.dateFilterStart)
                    .endAt(this.dateFilterEnd)
                    .limitToLast(this.fetchSize)
                    .once("value")

                const logs = snapshot.val() || {};

                const logsEntries = Object.entries(logs);

                let  logsEntriesUpdate = logsEntries.filter( item => {
                    if (!item[1].rjt) {
                        return item
                    }
                })                

                logsEntriesUpdate = logsEntriesUpdate.sort((x,y) => {
                    return x[1].wh - y[1].wh
                })

                this.logs = Object.values(Object.fromEntries(logsEntriesUpdate));
                 
                if (this.logs.length !== this.fetchSize) {
                    this.hideLoadMore = true;
                }
                this.selectedFilter = "rangeDateFilter"
                this.filters.rangeDateFilter = `Fecha desde ${range.startDate} - hasta ${range.endDate}`
                this.loading = false;
                this.popupOpened = false;

            } catch (error) {
                this.loading = false;
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },

        async buscar() {
            this.loading = true;
            console.log("va a buscar", this.tipoSearch, this.busqueda);
            const env = this.$store.state.dev ? "d" : "p";
            let limpio =
                this.tipoSearch == "send/identity"
                    ? this.busqueda
                          .split(".")
                          .join("")
                          .split("/")
                          .join("")
                          .split("-")
                          .join("")
                          .split("_")
                          .join("")
                          .split("/")
                          .join("")
                          .split(" ")
                          .join("")
                          .toUpperCase()
                    : this.busqueda.trim();
            this.$f7.popover.close(".popover-busqueda", true);
            try {
                this.contentToList = [];
                this.lastnotikeyCargada = null;
                this.contentYaRendereado = Object.assign({});
                this.$f7.dialog.preloader("Buscando...");
                let potresults = await this.$firebase_coreapp
                    .database(this.$store.getters.usrtrxDatabaseUrl)
                    .ref(`hstr_slcs/${env}`)
                    .orderByChild(this.tipoSearch)
                    .equalTo(limpio)
                    .once("value");

                this.selectedFilter = this.tipoSearch == "email" ? "email" : "paxID"
                if(this.tipoSearch == "email") {
                    this.filters.email = `Búsqueda por ${this.tipoSearch} - input ${this.busqueda}`
                }else {
                    this.filters.paxID = `Búsqueda por RUN/PAS/OTRO - input ${this.busqueda}`
                }

                const logsEntries = Object.entries(potresults.val() || {});
                
                let  logsEntriesUpdate = logsEntries.filter( item => {
                    if (!item[1].rjt) {
                        return item
                    }
                }) 
                
                this.logs = Object.values(Object.fromEntries(logsEntriesUpdate));
                if (logsEntriesUpdate.length == 0) {
                    this.hideLoadMore = false; 
                    this.loading = false;
                    throw new Error("No se encontraron resultados.");
                }
                this.loading = false;
                this.hideLoadMore = false; 
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },

        formattedDates() {
            const formattedDates = {
                startDate: "",
                endDate: "",
            };

            if (!this.filters.rangeDate.length) {
                return formattedDates;
            }

            const dates = this.filters.rangeDate.split(" - ");

            formattedDates.startDate = dates[0] || "";
            formattedDates.endDate = dates[1] || "";

            return formattedDates;
        },

        formatPatientName(log) {
            const { nombres, apellido1, apellido2 } = log.send;

            return `${nombres} ${apellido1} ${apellido2 || ""}`.trim();
        },

        openPopup() {
            this.popupOpened = true;
        },

        closePopup() {
            this.popupOpened = false;
        },

        // Virtual list related methods
        renderExternal(vl, vlData) {
            this.vlData = vlData;
        },
    },
}
</script>

<style scoped>
.text-overflow {
    --f7-list-item-text-max-lines: 5;
}
</style>