<template>
  <f7-page @page:beforein="traevacunas()" name="center_Stock">
    <f7-navbar >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :subtitle="`en ${centerInFocoInfo.name}`" title="Stock Vacunas"></f7-nav-title>
                 <f7-subnavbar :inner="false">
                    <f7-searchbar
                        class="no-margin"
                        search-container=".listovacunas"
                        search-in=".item-title,.item-text"
                        placeholder="Busque nombre vacuna"
                        :backdrop="false"
                    ></f7-searchbar>
         </f7-subnavbar>
    </f7-navbar>

  <f7-list class="no-margin-vertical searchbar-not-found">
        <f7-list-item title="Sin resultados"></f7-list-item>
    </f7-list>
    <f7-list class="no-margin-vertical listovacunas" media-list>
        <f7-list-item v-if="cargandovacunas" title="Cargando vacunas...">
            <f7-preloader slot="after"></f7-preloader>
        </f7-list-item>
        <f7-list-item
            v-else-if="!vacunas.length"
            title="No existen vacunas creadas,"
        ></f7-list-item>
        <f7-list-item
            v-for="vacuna in vacunaspub"
            :key="vacuna.vacunaPcId"
            :title="`${vacuna.vacunaPcName} (${vacuna.laboratorioName})`"
            link="#"
            @click="gotoVacuna(vacuna)"
            :subtitle="vacuna.presentacion"
        >
        </f7-list-item>
    </f7-list>



  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { commonmixin } from '../mixins/common'

export default {
      mixins: [commonmixin],
    data() {
      return {
       cargandovacunas: true
      }
    },
    computed:{
     ...mapState(['centerInFoco','agenda_molde_activa']),
     ...mapGetters(['centerInFocoInfo','vacunas']),
     vacunaspub(){
       return this.vacunas.filter(unavac=>{
         return !unavac.borrador
       })
     }
    },
    methods:{
     async gotoVacuna(vacuna){
       try {
         if(!this.agenda_molde_activa){
           this.$f7.dialog.preloader('Cargando...');
            console.log('cargando semana molde');
            let semmol = await this.$firebase.database().ref(`agenda_molde/${this.centerInFoco}/activa`).once('value');
            this.$f7.dialog.close();
            if(semmol.exists()){
              this.$store.commit('setObjTo',{what:'agenda_molde_activa',to:semmol.val()});
            }
        }
        this.$f7.views.main.router.navigate('/center/center_stock_vacuna/'+vacuna.vacunaPcId);
       } catch (error) {
         this.$f7.dialog.close();
          console.error(error);
          this.$f7.dialog.alert(error.message, error.code || 'Error');
       }
       
      },
      async traevacunas(){
        if(!this.vacunas.length){
          try {
           this.cargandovacunas=true;
           let datous = await this.commonExecute({vacunatorioid: this.centerInFoco},'admin-getStockTodasVacunasPorVacunatorio',false);
           this.cargandovacunas=false;
            if(datous.error){
              throw new Error(datous.error.message);
            }
              this.$store.commit("setObjTo", {what:'vacunas', to: datous.payload || {}});

             } catch (error) {
            this.cargandovacunas=false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
        }
        else{
           this.cargandovacunas=false;
        }
      },
    }
}
</script>
<style>


</style>