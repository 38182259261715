<template>
  <f7-page @page:beforein="cargaVacs()" name="eligeCentro">
        <f7-navbar title="Selecccione" subtitle="Vacunatorio a gestionar" back-link="Back">
           <f7-subnavbar>
       <f7-searchbar
      class="searchbar-vacunax"
      placeholder="Busque nombre o localidad"
      search-container=".vacucentros"
      search-in=".item-title,.item-text"
      :disable-button	="false"
      :inline="true"
    ></f7-searchbar>
    </f7-subnavbar>
        </f7-navbar>

  <f7-list class="no-margin searchbar-not-found">
    <f7-list-item title="Sin resultados"></f7-list-item>
  </f7-list>
<f7-list media-list class="no-margin vacucentros">
  <f7-list-item
  v-for="centro in misCentros"
  :key="centro.id"
  :class="{'skeleton-text skeleton-effect-blink': !centro.name, 'titlebold': centerInFoco==centro.id}"
  :title="centro.name || 'Cargando...'"
  link="#"
  @click="eligecentro(centro.id)"
  :subtitle="centro.direccion || 'Cargando...'"
  :text="centro.address?`${centro.address.name} (${centro.address.provname}), ${centro.address.region}`:'Cargando...'"
  >
  <font-awesome-icon class="text-color-teal" icon="check" slot="after" v-if="centerInFoco==centro.id"></font-awesome-icon>
  </f7-list-item>
</f7-list>





  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

export default {
    data() {
      return {
       misCentros:{}
      }
    },
    computed:{
     ...mapState(['user','centerInFoco']),
     ...mapGetters(['centersAdminPrivs']),
    },
    methods:{
      eligecentro(centroid){
        this.$store.commit('setWhatTo',{what:'centerInFoco', to: centroid});
        this.$f7router.back();
      },
      cargaVacs(){
        Object.keys(this.centersAdminPrivs).forEach(uncenterid => {
            this.addVacunatToUser(uncenterid);
        });
      },
      addVacunatToUser(vaccenterid){
          let elvacunatorio = this.$store.getters.getUno('vacunatorios',vaccenterid);
          let elvaccenter= elvacunatorio || {
            id: vaccenterid
          };
         this.$set(this.misCentros, vaccenterid, elvaccenter);
          if(!elvacunatorio){
            console.log('pidiendo info vacunatorio');
            return this.$firebase.database().ref('vacunatorios/'+vaccenterid).once('value').then(snpsht=>{
              console.log('llegando info vacuntaorio')
              let vacunatinfo = snpsht.val();
              this.$set(this.misCentros, vaccenterid, vacunatinfo);
               this.$store.commit('updtObjProperty',{what:'vacunatorios',to: vacunatinfo});
            }).catch(error=>{
              console.log('error de retrieval de vacunatorio',error);
            })
          }

        
      }
    }
}
</script>
<style>


</style>