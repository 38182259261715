<template>
  <f7-page @page:beforein="getPacienteInfo()" name="ficha">
        <f7-navbar :title="`${paxinfo.apellido1} ${paxinfo.apellido2 || ''}, ${paxinfo.nombres}`" subtitle="Ficha de vacunación" back-link="Back"></f7-navbar>
 


<f7-list class="fixfamarg no-margin-vertical" media-list>
  <f7-list-item 
  :title="`${paxinfo.apellido1} ${paxinfo.apellido2 || ''}, ${paxinfo.nombres}`"
  :subtitle="`${paxinfo.idenType} ${paxinfo.idenType=='RUN'?formatRut(paxinfo.identity):paxinfo.identity}  | ${paxinfo.nac_iso2}`"
  :text="$dayjs(paxinfo.fechanac).format('DD/MM/YYYY')"
  :after="suageStrg(paxinfo.fechanac)"
  >
  <font-awesome-icon slot="media" class="fa-3x" :class="paxinfo.sexo=='I'?'text-color-gray':paxinfo.sexo=='F'?'text-color-pink':'text-color-blue'" icon="user-circle" ></font-awesome-icon>

  </f7-list-item>
</f7-list>
<f7-list class="smalldividers" :class="{'skeleton-text skeleton-effect-blink':cargandoinfo}" >
  <f7-list-item  divider title="email PERSONAL de ingreso"></f7-list-item>
  <f7-list-item>
    <span slot="title"><font-awesome-icon v-if="!paxmetainfo.mainAccountEmail" icon="user-slash"></font-awesome-icon> {{cargandoinfo?'Cargando posible cuenta...':paxmetainfo.mainAccountEmail || 'No hay un email vinculado a este paciente'}}</span>
    <f7-button v-if="!cargandoinfo"  @click="getcandidatosIfNeeded()" class="no-margin-vertical fixanchobot botchgmailx" outline slot="after"><f7-preloader v-if="cargaCandidatos"></f7-preloader><span v-else-if="paxmetainfo.mainAccountEmail">Cambiar cuenta</span><span v-else>Indicar email</span></f7-button>
  </f7-list-item>
    <f7-list-item  divider title="email de TERCERAS personas con acceso"></f7-list-item>
  <f7-list-item footer="Estos usuarios pueden acceder a ficha de vacunas del paciente y responder encuestas de seguimientos de efectosa adversos">
    <f7-block slot="title" class="margin-vertical-half no-padding-left">
      <f7-chip :text="candidato" v-for="candidato in paxmetainfo.tercerosConAcceso" :key="candidato" media-bg-color="primary" deleteable @click="desAutorizaTercero(candidato)">
     <font-awesome-icon slot="media"   icon="user-check" ></font-awesome-icon>
      </f7-chip>
      <span v-if="!paxmetainfo.tercerosConAcceso || !paxmetainfo.tercerosConAcceso.length"><font-awesome-icon icon="users-slash"></font-awesome-icon> No hay terceras personas con acceso a este paciente</span>
      </f7-block>
        <f7-button v-if="!cargandoinfo"  @click="fetchotrosYopenpope" class="no-margin-vertical" outline slot="after">Agregar tercero</f7-button>

  </f7-list-item>
</f7-list>

<f7-block v-if="tieneInfoContacto">

<f7-button @click="preAbrirVacunador(null)" fill raised large>Registrar nueva vacunación manual</f7-button>
<template v-if="hayagendamientos">
   <p class="text-align-center">o elija un agendamiento para registrar vacunación precargada</p>
   <f7-list v-for="(untipagm,keysd) in agednsiterable" :key="keysd" media-list class="smalldividers no-margin">
      <f7-list-item v-if="paxmetainfo.agendmts[keysd].length" divider :title="`Agendamientos hechos por ${untipagm}`"></f7-list-item>
     <f7-list-item
     v-for="(agd,indike) in paxmetainfo.agendmts[keysd]"
     :key="agd.id"
      :header="`Agendamiento para el ${$dayjs(`${agd.fch} ${agd.hin}`,'YYYYMMDD HH:mm').format('dddd D [de] MMMM [a las] HH:mm')}`" 
      :title="agd.campinfo.fraseqeuma"
      >
      <span :class="{'text-color-red':agd.prov}" slot="subtitle"><font-awesome-icon icon="syringe"></font-awesome-icon> {{ agd.prov?'Agendamiento sin stock confirmado. Elegir vacuna manual.': agd.campinfo.vacunas}}</span>
      <f7-button @click="agd.prov?preAbrirVacunador(null):registrEstaVac(keysd,indike)" fill slot="after">Registrar</f7-button>
      </f7-list-item>
   </f7-list>
   </template>
</f7-block>
<f7-block v-else>Antes de poder registrar una adminsitracion de vacunas, por favor indique el email del paciente o de un tercero.</f7-block>
<f7-block>
  <f7-block-title>Vacunas recibidas</f7-block-title>
  <f7-list>
    <f7-list-item v-for="unavac in paxmetainfo.historico" :key="unavac.id" :footer="`Fuente: ${unavac.rni?'RNI':'vacunator.io'}| id: ${unavac.id}`" :header="$dayjs(unavac.d).format('dddd D [de] MMMM [de] YYYY')" :title="`${$store.state.vacunas[unavac.b].vacunaPcName} (${$store.state.vacunas[unavac.b].laboratorioName})`">
      <f7-link v-if="false && !unavac.rni" color="gray" @click="borrarRegistro(unavac.id)" slot="after"><font-awesome-icon icon="trash"></font-awesome-icon></f7-link>
    </f7-list-item>
  </f7-list>
  <f7-block v-if="!paxmetainfo.historico || !paxmetainfo.historico.length">No hay registro de vacunas previas para este paciente.</f7-block>
</f7-block>
  <f7-popover class="otrosUsrs">
    <f7-list>
      <f7-list-item link="#" @click="cambiausuario()" popover-close title="Otro correo electrónico"></f7-list-item>
    </f7-list>
    <f7-list class="no-margin smalldividers maxshcrollmails">
      <f7-list-item divider title="emails declarando esta identidad"></f7-list-item>
      <f7-list-item v-for="candidato in paxmetainfo.candidatos" @click="vincemailApaciente(candidato)" :key="candidato" link="#" popover-close :title="candidato"></f7-list-item>
    </f7-list>
  </f7-popover>


  <f7-popover class="tercerosx">
    <f7-list>
      <f7-list-item link="#" @click="agregaTercero()" popover-close title="Otro correo electrónico"></f7-list-item>
    </f7-list>
    <f7-list class="no-margin smalldividers maxshcrollmails">
      <f7-list-item divider title="emails que han pedido hora para esta identidad"></f7-list-item>
      <f7-list-item v-for="candidato in paxmetainfo.tercerosDeclarados" :key="candidato" @click="accesosaTerceros(candidato,'dar')" link="#" popover-close :title="candidato"></f7-list-item>
    </f7-list>
  </f7-popover>



 <f7-popup class="registroVax" :opened="regvaxopened" @popup:closed="regvaxopened = false">
      <f7-page>
        <f7-navbar title="Registrar nueva vacunación" :subtitle="neovacunacion.automatic?'Registro en base a agendamiento':'Registro manual'">
          <f7-nav-right>
            <f7-link popup-close>Cancelar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list class="no-margin liselectorregisro">
          <f7-list-input
          :class="{disabled: neovacunacion.automatic}"
            :label="`Campana ${neovacunacion.automatic?'(por agendamiento)':''} `"
            type="select"
             :value="neovacunacion.campanaid"
            @input="eligecampanaysetesquem($event.target.value)"
            placeholder="Seleccione Campaña..."
          >
            <option value="">Seleccione</option>
            <option v-for="campana in campanasActivas" :key="campana.id" :value="campana.id">{{campana.nombre}}</option>
          </f7-list-input>
          <f7-list-input
            v-if="neovacunacion.campanaid"
            :label="`Esquema ${neovacunacion.automatic?'(por agendamiento)':''} `"
            type="select"
              :class="{disabled: neovacunacion.automatic}"
            :value="neovacunacion.esquemaid"
            @input="eligeEsquema($event.target.value)"
            placeholder="Seleccione esquema..."
          >
           <option value="">Seleccione</option>
            <option v-for="esquema in campanas[neovacunacion.campanaid].esquemas" :key="esquema.id" :value="esquema.id">{{esquema.name}}</option>
          </f7-list-input>

          <f7-list-input
            v-if="neovacunacion.esquemaid"
              :label="`Dia de secuencia ${neovacunacion.automatic?'(por agendamiento)':''} `"
             :class="{disabled: neovacunacion.automatic}"
            type="select"
             @input="eligeDia($event.target.value)"
            :value="neovacunacion.diaid"
            placeholder="Seleccione dia de secuencia..."
          >
           <option value="">Seleccione</option>
            <option v-for="dia in campanas[neovacunacion.campanaid].esquemas[neovacunacion.esquemaid].cal" :key="dia.id" :value="dia.id">{{dia.id}}</option>
          </f7-list-input>
        </f7-list>
        <template v-if="neovacunacion.diaid">
        <f7-list v-for="requerimiento in campanas[neovacunacion.campanaid].esquemas[neovacunacion.esquemaid].cal[neovacunacion.diaid].req" :key="requerimiento.id" class="smalldividers margin-half">
          <f7-list-item divider title="Un requerimiento"></f7-list-item>
            <f7-list-item 
            v-for="vacunaid in requerimiento.alts" 
            :key="vacunaid" 
            checkbox 
            :value="vacunaid" 
            @change="selvactore(requerimiento.id,vacunaid,$event.target.checked)"
            :checked="neovacunacion.vacunas[requerimiento.id] && neovacunacion.vacunas[requerimiento.id][vacunaid]"
            :title="`${vacunas[vacunaid].vacunaPcName} (${vacunas[vacunaid].laboratorioName})`">
            <f7-preloader slot="after" v-if="stockVacunas[vacunaid] && stockVacunas[vacunaid].loading"></f7-preloader>
              <f7-input
              v-else
              :class="{disabled: !neovacunacion.vacunas[requerimiento.id] || !neovacunacion.vacunas[requerimiento.id][vacunaid]}"
           slot="after"
            type="select"
            :value="stockVacunas[vacunaid]?stockVacunas[vacunaid].sel : 'virtual'"
             @change="stockVacunas[vacunaid].sel =$event.target.value "
            placeholder="Seleccione origen..."
          >
           <option value="virtual">Stock virtual</option>
            <option v-for="bandja in getpotstockiterable(vacunaid)" :key="bandja.trayid" :value="bandja.trayid">Bandeja {{bandja.trayid}} {{bandja.descrip || ''}}</option>
          </f7-input>
            </f7-list-item>
        </f7-list>
        </template>
      <f7-block>
        <f7-button @click="registravacunacion()" :class="{disabled: !hayvacunas || hayCargaPendienteDeStock}" large fill raised color="teal">Registrar Vacunación</f7-button>
      </f7-block>
      </f7-page>
    </f7-popup>


  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { commonmixin } from '../mixins/common'
import * as EmailValidator from 'email-validator';
import formularios from './formularios.vue';

export default {
  components: { formularios },
    mixins: [commonmixin],
    props:['paxinfo'],
    data() {
      return {
       cargandoinfo: true,
       paxmetainfo:{},
       cargaCandidatos: false,
       regvaxopened: false,
       neovacunacion:{
         automatic: null,
         campanaid: '',
         esquemaid: '',
         diaid: '',
         vacunas:{}
       },
       stockVacunas:{},
       agednsiterable:{
         propios: 'el paciente',
         tercerosOk: 'terceros autorizados',
         terceros_otros: 'terceros no autorizados'
       }
      }
    },
   
    computed:{
     ...mapState(['centerInFoco','campanas','vacunas']),
     ...mapGetters(['campanasActivas']),
     tieneInfoContacto(){
       return this.paxmetainfo.mainAccountEmail || (this.paxmetainfo.tercerosConAcceso && this.paxmetainfo.tercerosConAcceso.length)
     },
     hayvacunas(){
       return Object.values(this.neovacunacion.vacunas).some(reques=>{
          return Object.values(reques).some(unavac=>{
            return unavac
          })
       })
     },
     hayagendamientos(){
       return this.paxmetainfo.agendmts && (this.paxmetainfo.agendmts.propios.length || this.paxmetainfo.agendmts.tercerosOk.length || this.paxmetainfo.agendmts.terceros_otros.length)
     },
     hayCargaPendienteDeStock(){
       return  Object.values(this.stockVacunas || {}).some(objevac => {
         return objevac.loading;
       });
     }
    },
      watch:{
        centerInFoco(nuevocentro){
            console.log('ATENTO, CAMBIO DE CENTRO',nuevocentro);
           this.$f7.views.main.router.back();
        }
    },
    mounted(){
      console.log('paxinfo',this.paxinfo);
    },
    methods:{
      borrarRegistro(estaid){
        this.$f7.dialog.confirm('Está seguro que desea eliminar este registro?','Confirme',()=>{
          console.log('Quiere eliminar este registro de vacunacion',estaid);
        });
      },
      async registravacunacion(){
        try {
          let vacunax= this.neovacunacion.vacunas || {};
          let avasArrayados = Object.keys(vacunax);
          if(!avasArrayados.length){
            return this.$f7.dialog.alert('Debe seleccionar al menos una vacuna','No ha seleccionado vacunas');
          }
          let promeserax=[];
          avasArrayados.forEach(unreqid => {
            Object.keys(vacunax[unreqid]).forEach(vacunaid => {
            if(vacunax[unreqid][vacunaid]){
             
              let tosend={
                pacienteid: this.paxinfo.id,
                campanaid: this.neovacunacion.campanaid,
                esquemaid: this.neovacunacion.esquemaid,
                diaid: this.neovacunacion.diaid,
                requerimientoid: unreqid,
                vacunaid: vacunaid,
                trayid: this.stockVacunas[vacunaid]?this.stockVacunas[vacunaid].sel:'virtual',
                vacunatorioid:this.centerInFoco,
                agendamientoid: this.neovacunacion.automatic?this.neovacunacion.automatic.id : null
              };
              console.log('tosend',tosend);
               promeserax.push( this.commonExecute(tosend,'admin-registraVacunacion',false) );
            }
          });
          });
          this.$f7.dialog.preloader('Guardando registro...')
          let multianswr = await Promise.all(promeserax);
           this.$f7.dialog.close();
          multianswr.forEach(datous => {
             if(datous.error){
              throw new Error(datous.error.message);
            }
            let nuevoregistro = datous.payload;
            console.log('nuevo registro',nuevoregistro);
            //si viene con agendamiento id, buscarlo y borrarlo
           if(this.neovacunacion.automatic && this.neovacunacion.automatic.id){
             this.borrarEsteAgendamientid(this.neovacunacion.automatic.id);
           }

           //agregar a listado de vacunaciones
            if(!this.paxmetainfo.historico){
              this.$set(this.paxmetainfo,'historico',[]);
            }
            this.paxmetainfo.historico.unshift(nuevoregistro);
          });
           this.regvaxopened = false;
        } catch (error) {
           this.$f7.dialog.close();
            console.log(error)
            this.$f7.dialog.alert(error.message,error.code || 'Error');
        }
      },
      borrarEsteAgendamientid(agmdid){
      let borro = Object.keys(this.agednsiterable).some(keyid => {
            if(this.paxmetainfo.agendmts &&  this.paxmetainfo.agendmts[keyid]){
                let index = this.paxmetainfo.agendmts[keyid].findIndex((item) =>item.id === agmdid);
                if(index !== -1){
                 return this.paxmetainfo.agendmts[keyid].splice(index, 1);
                }
            }
        });
  
      },
      registrEstaVac(tipo,posicion){
        let agendamitno  = this.paxmetainfo.agendmts[tipo][posicion];
        console.log('quiere registrar este agendamiento',agendamitno)
        this.preAbrirVacunador(agendamitno);
      },
      getpotstockiterable(vacunaid){
        if(this.stockVacunas[vacunaid]){
          return this.stockVacunas[vacunaid].bandejas;
        }
        else{
          return [];
        }
      },
      selvactore(requerimientoid,vacunaid,ischecked){
        if(!this.neovacunacion.vacunas[requerimientoid]){
           this.$set(this.neovacunacion.vacunas,requerimientoid,{});
        }
        this.$set(this.neovacunacion.vacunas[requerimientoid],vacunaid,ischecked);
        if(ischecked){
          let forcedtray = this.neovacunacion.automatic? this.neovacunacion.automatic.rqs[requerimientoid].tray : null;
          this.loadStock(vacunaid,forcedtray);
        }
        else{
            this.$set(this.stockVacunas,vacunaid,{loading: false, sel:'virtual', bandejas:[]})
        }
      },
      async loadStock(vacunaid,forcedtray){
        try {
           this.$set(this.stockVacunas,vacunaid,{loading: true, sel:'virtual', bandejas:[]});
          let datous = await this.commonExecute({vacunaid, vacunatorioid: this.centerInFoco},'admin-getStockVacunaHere',false);
          if(datous.error){
              throw new Error(datous.error.message);
          }
          console.log(datous.payload);
          
          let bandejxax=datous.payload || []
          if(forcedtray ){
            //enontrar si existe mi bandeja
            let index = bandejxax.findIndex(bdj => bdj.trayid == forcedtray);
            if(index!=-1){
              bandejxax[index].left++;
            }
            else{
              bandejxax.push({left: 1, descrip: bandejxax.descrip,  trayid: forcedtray});
            }
          }
          let setlec= forcedtray?forcedtray : (datous.payload.length?datous.payload[0].trayid : 'virtual');
          console.log('va a setear',setlec);
          //forcedtray
          this.$set(this.stockVacunas,vacunaid,{loading: false, sel:'virtual', bandejas:bandejxax });
          this.$set(this.stockVacunas[vacunaid],'sel',setlec)
        } catch (error) {
           this.$set(this.stockVacunas[vacunaid],'loading',false);
            console.log(error)
            this.$f7.dialog.alert(error.message,error.code || 'Error');
        }
      },
      eligeDia(diaid){
         this.neovacunacion.diaid = diaid;
         this.$set(this.neovacunacion,'vacunas',{});
      },
      eligeEsquema(esquemaid){
         this.neovacunacion.diaid='';
         this.neovacunacion.esquemaid = esquemaid;
         if(esquemaid){
           let diasArray = Object.values(this.campanas[this.neovacunacion.campanaid].esquemas[esquemaid].cal ||{});
            if(diasArray.length!=1){
              this.neovacunacion.diaid = '';
            }
            else{
              this.neovacunacion.diaid =diasArray[0].id;
        }
         }

      },
      eligecampanaysetesquem(campanaid){
         this.neovacunacion.diaid='';
          this.neovacunacion.esquemaid = '';
         this.neovacunacion.campanaid = campanaid;
        let esquemarray = Object.values(this.campanas[campanaid].esquemas ||{});
        if(esquemarray.length!=1){
          this.neovacunacion.esquemaid = '';
        }
        else{
          this.neovacunacion.esquemaid =esquemarray[0].id;
        }
      },
      async preAbrirVacunador(agendamietno){
        if(agendamietno){
          //setear con estos valores
           this.neovacunacion.campanaid = agendamietno.cmp;
           this.neovacunacion.esquemaid = agendamietno.sqm;
           this.neovacunacion.diaid = agendamietno.dia;
           this.neovacunacion.automatic = agendamietno;
           Object.keys(agendamietno.rqs).forEach(reqid => {
             let vacinfo=agendamietno.rqs[reqid];
              if(!this.neovacunacion.vacunas[reqid]){
                  this.$set(this.neovacunacion.vacunas,reqid,{});
                }
                
              this.$set(this.neovacunacion.vacunas[reqid],vacinfo.vacid,true);
              console.log('SETENADO BANDEJ',vacinfo.tray)
              this.loadStock(vacinfo.vacid,vacinfo.tray);
           });
        }
        else{
          this.neovacunacion.automatic = null;
          this.neovacunacion.diaid='';
          this.neovacunacion.esquemaid = '';
          this.neovacunacion.campanaid = '';
        }
        this.regvaxopened = true;
      },
      desAutorizaTercero(email){
        console.log('mata tercero');
        this.$f7.dialog.confirm('Confirme que desea quitar a '+email+' acceso a ficha de vacunación de este paciente.','Confirme revocación de acceso',()=>{
          console.log('revocar acceso');
          this.accesosaTerceros(email,'quitar');
        })
      },
      async getcandidatosIfNeeded(){
              try {
                if(!this.paxmetainfo.candidatosLoaded){
                  this.cargaCandidatos=true;
                  let datous = await this.commonExecute({emailexclude: this.paxmetainfo.mainAccountEmail, identity: this.paxinfo.identity},'admin-getCandidatosNotEste',false);
                    if(datous.error){
                        throw new Error(datous.error.message);
                    }
                    console.log('respuesta',datous.payload)
                    if(datous.payload && datous.payload.length){
                      this.$set(this.paxmetainfo,'candidatos',datous.payload);
                    }
                    this.cargaCandidatos=false;
                    this.paxmetainfo.candidatosLoaded=true;
                   console.log('candidatos son',this.paxmetainfo.candidatos );
                }
                this.attmptcambiaEmailPrinpal();
              } catch (error) {
                  this.cargaCandidatos=false;
                  console.log(error)
                  this.$f7.dialog.alert(error.message,error.code || 'Error');
              }
      },
      attmptcambiaEmailPrinpal(){
        if(!this.paxmetainfo.candidatos || !this.paxmetainfo.candidatos.length){
            //abre directo la pregunta
            this.cambiausuario();
          }
          else{
            //abre selector
            this.$f7.popover.open('.otrosUsrs', '.botchgmailx', true)
          }
      },
       async vincemailApaciente(email){
              try {
                    this.$f7.dialog.preloader('Vinculando paciente a cuenta...')
                    let datous = await this.commonExecute({email,pacienteid: this.paxinfo.id, vacunatorioid: this.centerInFoco},'admin-vincPacienteaEmail',false);
                    if(datous.error){
                        throw new Error(datous.error.message);
                    }
                     if(!this.paxmetainfo.candidatos){
                          this.paxmetainfo.candidatos=[];
                        }
                    if(this.paxmetainfo.mainAccountEmail){
                      //si tenia un email antes, agregar a lista de potenciales
                        this.paxmetainfo.candidatos.push(this.paxmetainfo.mainAccountEmail);
                    }
                    //quitar de lista de potenciales si es que existia
                    let index = this.paxmetainfo.candidatos.findIndex((item) =>item === email);
                      if(index !== -1){
                        this.paxmetainfo.candidatos.splice(index, 1);
                    }
                    this.paxmetainfo.mainAccountEmail=email;
                    this.avisaOk('Vinculación exitosa');
                    this.$f7.dialog.close();

              } catch (error) {
                  this.$f7.dialog.close();
                  console.log(error)
                  this.$f7.dialog.alert(error.message,error.code || 'Error');
              }
          },
        async accesosaTerceros(emailtercero,accion){
              try {
                    this.$f7.dialog.preloader('Gestionando acceso de tercero...')
                    let datous = await this.commonExecute({emailtercero,accion,pacienteid: this.paxinfo.id, vacunatorioid: this.centerInFoco},'admin-accesosaTerceros',false);
                    if(datous.error){
                        throw new Error(datous.error.message);
                    }
                    if(accion=='quitar'){
                      let index = this.paxmetainfo.tercerosConAcceso.findIndex((item) =>item === emailtercero);
                      if(index !== -1){
                          this.paxmetainfo.tercerosConAcceso.splice(index, 1);
                      }
                    }
                    else if(accion=='dar'){
                      if(!this.paxmetainfo.tercerosConAcceso){
                        this.paxmetainfo.tercerosConAcceso=[];
                      }
                      this.paxmetainfo.tercerosConAcceso.push(emailtercero);
                      //borrar del arreglo de posibles si es que existia ahi
                      let index = this.paxmetainfo.tercerosDeclarados.findIndex((item) =>item === emailtercero);
                      if(index !== -1){
                          this.paxmetainfo.tercerosDeclarados.splice(index, 1);
                      }
                    }
                    this.avisaOk('Proceso OK');
                    this.$f7.dialog.close();

              } catch (error) {
                  this.$f7.dialog.close();
                  console.log(error)
                  this.$f7.dialog.alert(error.message,error.code || 'Error');
              }
          },
       async getPacienteInfo(){
              try {
                  this.cargandoinfo=true;
                    let datous = await this.commonExecute({pacienteid: this.paxinfo.id, vacunatorioid: this.centerInFoco},'admin-getInfoFichaPaciente',false);
                    if(datous.error){
                        throw new Error(datous.error.message);
                    }
                    this.paxmetainfo = Object.assign({},datous.payload);
                   console.log(this.paxmetainfo );
                   this.cargandoinfo=false;

              } catch (error) {
                  this.cargandoinfo=false;
                  console.log(error)
                  this.$f7.dialog.alert(error.message,error.code || 'Error');
              }
          },
      fetchotrosYopenpope(e){
        if(this.paxmetainfo.tercerosDeclarados && this.paxmetainfo.tercerosDeclarados.length){
          return this.$f7.popover.open('.tercerosx', e.target, true);
        }
        else{
          this.agregaTercero();
        }
      },
       agregaTercero(){
        let titulo='Otorgar permiso a tercera persona';
        let frasex=`El correo que ingrese podrá acceder a la ficha de vacunación de este paciente y responder encuestas de seguimientos de efectos adversos.`;
        return this.$f7.dialog.prompt(frasex,titulo,(nuevoemail)=>{
          if(!nuevoemail || !EmailValidator.validate(nuevoemail)){
            return this.$f7.dialog.alert('Debe indicar un email valido');
          }
          console.log('ingresó',nuevoemail);
          this.accesosaTerceros(nuevoemail,'dar');
        });
      },
      cambiausuario(){
        let titulo='Vinculación de email personal de usuario';
        let frasex=`El paciente podrá ingresar a mevacuno.cl con el email que ingrese aquí`;
        if(this.paxmetainfo.mainAccountEmail){
          titulo='Desvinculación de cuenta de usuario';
          frasex=`El usuario ${this.paxmetainfo.mainAccountEmail} perderá acceso a esta ficha. El paciente deberá ingresar con el nuevo email que ingrese aquí`;
        }
        return this.$f7.dialog.prompt(frasex,titulo,(nuevoemail)=>{
           if(!nuevoemail || !EmailValidator.validate(nuevoemail)){
            return this.$f7.dialog.alert('Debe indicar un email valido');
          }
          console.log('ingresó',nuevoemail);
          this.vincemailApaciente(nuevoemail);
        });
      }
    }
}
</script>
<style>
.maxshcrollmails{
  max-height: 170px;
  overflow-y: auto;
}
.fixanchobot{
  min-width: 145px!important;
}

.liselectorregisro .disabled{
  opacity: 1 !important;
}
.liselectorregisro .disabled select{
  color:#6f00ff!important;
}
</style>