import Vue from "vue";

export default {
    namespaced: true,

    state: {
        SOLICITUDES_FETCH_SIZE: 20,
        SOLICITUDES_LOAD_MORE_FETCH_SIZE: 20,

        motivosRechazo: [],

        currentSolicitud: null,

        isLoading: false,
        allSolicitudes: {},

        observableEnEspera: null,
        observableEnRevision: null,
        observableEnConflicto: null,

        enEsperaFilter: "all",

        acabaDeRechazarIdentidad: null,

        vacunasHomologacion: {},
    },

    getters: {
        solicitudesEnEspera: state => {
            const solicitudesEnEspera = Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "En espera")
                .sort((a, b) => a.wh - b.wh);

            if (state.enEsperaFilter === "all") {
                return solicitudesEnEspera;
            } else if (state.enEsperaFilter === "no_verified") {
                return solicitudesEnEspera.filter(s => s.pat && !s.pat.verified);
            } else if (state.enEsperaFilter === "verified") {
                return solicitudesEnEspera.filter(s => s.pat && s.pat.verified);
            }

            return solicitudesEnEspera;
        },
        solicitudesEnRevision: state => {
            return Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "En revisión")
                .sort((a, b) => a.wh - b.wh);
        },
        solicitudesEnConflicto: state => {
            return Object.values(state.allSolicitudes || {})
                .filter(s => s.est === "En conflicto")
                .sort((a, b) => a.wh - b.wh);
        },
    },

    mutations: {
        set_is_loading(state, isLoading) {
            state.isLoading = isLoading;
        },

        set_observable(state, payload) {
            const { firestore, refetch, colaType, dev} = payload;

            let observableByColaType;

            if (colaType === "En espera") {
                observableByColaType = "observableEnEspera";
            } else if (colaType === "En revisión") {
                observableByColaType = "observableEnRevision";
            } else if (colaType === "En conflicto") {
                observableByColaType = "observableEnConflicto";
            } 

            if (refetch) {
                state.allSolicitudes = {};
            }

            let collection = dev ? "solicitudes_homologacion_dev" : "solicitudes_homologacion";
            
            console.log("seteando observable coleccion", collection);

            let query = firestore.collection(collection);

            if (colaType === "En espera") {
                query = query.where("est", "==", "En espera");
            } else if (colaType === "En revisión") {
                query = query.where("est", "==", "En revisión");
            } else if (colaType === "En conflicto") {
                query = query.where("est", "==", "En conflicto");
            } 

            query = query.orderBy("wh", "asc").limit(state.SOLICITUDES_FETCH_SIZE);

            state[observableByColaType] = query.onSnapshot(
                querySnapshot => {
                    console.log("observable query snapshot");

                    querySnapshot.forEach(doc => {
                        Vue.set(state.allSolicitudes, doc.id, doc.data());

                        console.log("nueva solicitud", doc.id);
                    });
                },
                error => {
                    console.error(error);
                }
            );
        },

        unset_observable(state, payload) {
            const { selectedCola } = payload;

            let observableByColaType;

            if (selectedCola === "En espera") {
                observableByColaType = "observableEnEspera";
            } else if (selectedCola === "En revisión") {
                observableByColaType = "observableEnRevision";
            } else if (selectedCola === "En conflicto") {
                observableByColaType = "observableEnConflicto";
            } 

            if (state[observableByColaType]) {
                state[observableByColaType]();
            }
        },

        load_more_solicitudes_by_type(state, payload) {
            const { firestore, colaType, dev } = payload;

            let collection = dev ? "solicitudes_homologacion_dev" : "solicitudes_homologacion";

            console.log("load more desde coleccion", collection);

            let query = firestore.collection(collection);

            if (colaType === "En espera") {
                query = query.where("est", "==", "En espera");
            } else if (colaType === "En revisión") {
                query = query.where("est", "==", "En revisión");
            } else if (colaType === "En conflicto") {
                query = query.where("est", "==", "En conflicto");
            } 

            // la ultima solicitud de la lista que estas viendo, a partir de esa se fetchean mas
            const oldestSolicitudByType = Object.values(state.allSolicitudes || {})
                .filter(s => s.est === colaType)
                .sort((a, b) => a.wh - b.wh)
                .pop();

            console.log("last solicitud", oldestSolicitudByType);

            query = query.orderBy("wh", "asc");

            if (oldestSolicitudByType) {
                query = query.startAfter(oldestSolicitudByType.wh);
            }

            query = query.limit(state.SOLICITUDES_LOAD_MORE_FETCH_SIZE);

            query.get().then(querySnapshot => {
                querySnapshot.forEach(doc => {
                    Vue.set(state.allSolicitudes, doc.id, doc.data());
                });
            });
        },

        unset_all_observables(state) {
            const observables = ["observableEnEspera", "observableEnRevision", "observableEnConflicto"];

            observables.forEach(observable => {
                if (state[observable]) {
                    state[observable]();

                    state[observable] = null;
                }
            });

            state.allSolicitudes = {};
        },

        set_current_solicitud(state, solicitudId) {
            if (solicitudId === null) {
                state.currentSolicitud = null;
            }

            const solicitud = state.allSolicitudes[solicitudId];

            if (solicitud) {
                state.currentSolicitud = solicitud;
            }
        },
        set_current_solicitud_object(state, solicitud) {
            state.currentSolicitud = solicitud;
        },

        update_solicitud(state, payload) {
            const { solicitudId, update } = payload;

            const updatedSolicitud = { ...state.allSolicitudes[solicitudId], ...update };

            Vue.set(state.allSolicitudes, solicitudId, updatedSolicitud);
        },

        set_filter_lista_en_espera(state, filter) {
            state.enEsperaFilter = filter;
        },

        set_motivos_rechazo(state, motivosRechazo) {
            state.motivosRechazo = motivosRechazo;
        },

        set_acaba_de_rechazar(state, decision) {
            state.acabaDeRechazarIdentidad = decision;
        },

        remove_solicitud(state, solicitudId) {
            Vue.delete(state.allSolicitudes, solicitudId);
        },

        set_vacunas_homologacion(state, vacunas) {
            state.vacunasHomologacion = vacunas;
        },

    },

    actions: {
        fetchSolicitudes({ commit }, payload) {
            const { selectedCola, firestore, refetch, dev } = payload;

            commit("set_is_loading", true);

            commit("set_observable", { firestore, refetch, colaType: selectedCola, dev });

            commit("set_is_loading", false);
        },

        loadMoreSolicitudes({ commit }, payload) {
            const { selectedCola, firestore, dev } = payload;

            commit("set_is_loading", true);

            commit("load_more_solicitudes_by_type", { firestore, colaType: selectedCola, dev });

            commit("set_is_loading", false);
        }
    },
};
