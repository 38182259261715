<template>
    <div>
        <table v-if="accounts.length">
            <thead>
                <tr>
                    <th class="text-align-center">Nombre</th>
                    <th class="text-align-center">Apellido 1</th>
                    <th class="text-align-center">Apellido 2</th>
                    <th class="text-align-center">RUN</th>
                    <th class="text-align-center">Correo</th>
                    <th class="text-align-center">Rol asignado</th>
                    <th class="text-align-center">Estado de la cuenta</th>
                    <th class="text-align-center">Tipo de cuenta</th>
                    <th class="text-align-center">Acción</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="account in accounts" :key="account.uid">
                    <td>{{ account.nms }}</td>
                    <td>{{ account.ap1 }}</td>
                    <td>{{ account.ap2 }}</td>
                    <td>{{ account.id }}</td>
                    <td>{{ account.em }}</td>
                    <td>{{ $store.getters.getRoleName(account.role) }}</td>
                    <td>{{ account.inactive ? 'Inactiva' : 'Activa' }}</td>
                    <td>{{ account.regional ? 'Regional' : 'Central' }}</td>
                    <td>
                        <div class="display-flex justify-content-center">
                            <f7-button @click="openEditAccountPopup(account)" tooltip="Editar cuenta">
                                <font-awesome-icon :icon="['fa', 'edit']" class="margin-half-right"></font-awesome-icon>
                            </f7-button>
                            <f7-button v-if="account.inactive" @click="changeAccountActiveStatus(account.uid, true)" tooltip="Activar cuenta">
                                <font-awesome-icon :icon="['fa', 'toggle-off']" class="margin-half-right fa-lg"></font-awesome-icon>
                            </f7-button>
                            <f7-button v-else @click="changeAccountActiveStatus(account.uid, false)" tooltip="Activar cuenta">
                                <font-awesome-icon :icon="['fa', 'toggle-on']" class="margin-half-right fa-lg"></font-awesome-icon>
                            </f7-button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        
        <AccountEditPopup :opened="isEditingPopupOpened" @closed="isEditingPopupOpened = false" :account="editingAccount" @edited="handleEditedAccount" />
    </div>
</template>

<script>
import { commonmixin } from "../mixins/common";
import AccountEditPopup from "../components/AccountEditPopup.vue";
export default {
    mixins: [commonmixin],
    components: { AccountEditPopup },
    props: {
        accounts: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            editingAccount: null,
            isEditingPopupOpened: false,
        };
    },
    methods: {
        async changeAccountActiveStatus(uid, active) {
            const confirmationMessage = active
                ? "Estás a punto de activar esta cuenta. El usuario podrá acceder a todas las funciones en las cuales posee privilegios. ¿Desea activarla de todas formas?"
                : "Estás a punto de inactivar esta cuenta. El usuario no podrá acceder a la plataforma. ¿Desea inactivarla de todas formas?"
            const actionText = active ? "Activando" : "Inactivando" ;

            this.$f7.dialog.confirm(confirmationMessage, "CUIDADO: Acción crítica", async () => {
                try {
                    this.$f7.dialog.preloader(`${actionText} cuenta...`);

                    const response = await this.commonExecute({ uid, inactive: !active }, "admin-changeAccountActiveStatus", false);

                    if (response.error) {
                        throw new Error(response.error);
                    }

                    this.$emit("account-active-status-changed", uid, !active);

                    this.$f7.dialog.close();
                } catch (error) {
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            });
        },
        openEditAccountPopup(account) {
            this.editingAccount = {
                ...account,
                prv: account.prv || {},
            };
            this.isEditingPopupOpened = true;
        },
        handleEditedAccount(editedAccount) {
            this.editingAccount = editedAccount;
            this.isEditingPopupOpened = false;
            this.$emit("edited-account", editedAccount);

            this.$f7.toast.show({
                text: "La cuenta ha sido editada exitosamente.",
                horizontalPosition: "center",
                closeTimeout: 2000,
            });
        },
    },
};
</script>

<style>
table {
    width: 100%;
    border-collapse: collapse;
}
th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}
th {
    background-color: var(--f7-theme-color);
    color: #fff;
    font-weight: bold;
    border-bottom: 2px solid #999;
}
tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
</style>
