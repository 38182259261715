<template>
    <f7-page @page:beforein="handleBeforein" infinite :infinite-distance="40" :infinite-preloader="showInfinitePreloader" @infinite="handleInfinite">
        <f7-navbar back-link="Back">
            <f7-nav-title v-if="tipo == 'sign'" title="Solicitudes firmadas" subtitle="Validación de vacunas recibidas en Chile"></f7-nav-title>
            <f7-nav-title v-else title="Solicitudes aceptadas" :subtitle="tipo === 'nac' ? 'Validación de vacunas recibidas en Chile' : 'Validación de vacunas recibidas en el extranjero'"></f7-nav-title>
            <f7-nav-right>
                <f7-link tooltip="Descargar excel" @click="openPopup"><font-awesome-icon icon="cloud-download-alt"></font-awesome-icon></f7-link>
                <f7-link tooltip="Buscar solicitud" @click="searchSolicitudesPopupOpened = true"
                    ><font-awesome-icon icon="search"></font-awesome-icon
                ></f7-link>

                <f7-link :class="{ disabled: isLoading }" @click="fetchSolicitudes" tooltip="Actualizar listado"
                    ><font-awesome-icon :class="{ 'fa-spin': isLoading }" icon="sync-alt"></font-awesome-icon
                ></f7-link>
            </f7-nav-right>
        </f7-navbar>

        <solicitudes-list list-type="Aceptadas" :solicitudes="solicitudes"></solicitudes-list>

        <f7-popup class="solicitud-search" :opened="searchSolicitudesPopupOpened" @popup:closed="handleSearchPopupClosed">
            <f7-page>
                <f7-navbar title="Buscar solicitud">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <div class="margin">
                    <p>Tipo de búsqueda</p>
                    <f7-segmented strong tag="p">
                        <f7-button @click="selectedSearchTypeChanged('user_email')" :active="selectedSearchType === 'user_email'">Email</f7-button>
                        <f7-button @click="selectedSearchTypeChanged('patient_id')" :active="selectedSearchType === 'patient_id'"
                            >ID de paciente</f7-button
                        >
                        <f7-button @click="selectedSearchTypeChanged('nombres_apellidos')" :active="selectedSearchType === 'nombres_apellidos'"
                            >Nombres y apellidos</f7-button
                        >
                    </f7-segmented>
                </div>

                <f7-list class="no-margin" no-hairlines v-if="selectedSearchType === 'user_email'">
                    <f7-list-input
                        type="text"
                        label="Email usuario MeVacuno"
                        @blur="e => searchInputChanged(e, 'user_email')"
                        :value="search.user_email"
                        placeholder="usuario@test.cl"
                    ></f7-list-input>
                </f7-list>

                <f7-list class="no-margin-vertical" v-if="selectedSearchType === 'patient_id'">
                    <f7-list-input
                        type="text"
                        label="ID de paciente"
                        @blur="e => searchInputChanged(e, 'pid')"
                        :value="search.pid"
                        placeholder="Ej: RUN_112223334"
                    ></f7-list-input>
                </f7-list>

                <f7-row no-gap v-if="selectedSearchType === 'nombres_apellidos'">
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Nombres" @blur="e => searchInputChanged(e, 'nombres')"></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Apellido 1" @blur="e => searchInputChanged(e, 'apellido1')"></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Apellido 2" @blur="e => searchInputChanged(e, 'apellido2')"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <div class="margin">
                    <f7-button fill color="primary" @click="searchSolicitudes(false)">Buscar</f7-button>
                    <f7-button v-if="foundSolicitudes.length" class="margin-top" small @click="foundSolicitudes = []"
                        >Limpiar resultados de búsqueda</f7-button
                    >
                </div>

                <solicitudes-list
                    list-type="Busqueda aceptadas"
                    :solicitudes="foundSolicitudesOrdered"
                    @solicitud-taken="handleSolicitudTakenFromBusqueda"
                ></solicitudes-list>

                <f7-button @click="searchSolicitudes(true)">Cargar más</f7-button>
            </f7-page>
        </f7-popup>

        <f7-popup class="rango-fechas" :opened="popupOpened" @popup:closed="closePopup">
            <f7-page>
                <f7-navbar title="Seleccione la fecha a descargar">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-list class="no-margin" no-hairlines>
                    <f7-list>
                        <f7-list-item title="Mes" smart-select :smart-select-params="{ openIn: 'popover', closeOnSelect: true }" ref="month">
                            <select name="meses" v-model="selectedMonth">
                                <option value="01">Enero</option>
                                <option value="02">Febrero</option>
                                <option value="03">Marzo</option>
                                <option value="04">Abril</option>
                                <option value="05">Mayo</option>
                                <option value="06">Junio</option>
                                <option value="07">Julio</option>
                                <option value="08">Agosto</option>
                                <option value="09">Septiembre</option>
                                <option value="10">Octubre</option>
                                <option value="11">Noviembre</option>
                                <option value="12">Diciembre</option>
                            </select>
                        </f7-list-item>
                        <f7-list-item title="Año" smart-select :smart-select-params="{ openIn: 'popover', closeOnSelect: true }"  ref="year">
                            <select name="año" v-model="selectedYear">
                                <option v-for="year in years" v-bind:value="year" v-bind:key="year">{{ year }}</option>
                            </select>
                        </f7-list-item>
                    </f7-list>
                </f7-list>
                <f7-button @click="generaDescarga()" fill class="margin">Descargar</f7-button>
            </f7-page>
        </f7-popup>

        <f7-popover class="popover-filtros"> </f7-popover>
    </f7-page>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import SolicitudesList from "./components/solicitudes-list.vue";
import axios from "axios";

export default {
    components: {
        "solicitudes-list": SolicitudesList,
    },
    props: {
        tipo: {
            type: String
        },
    },
    data() {
        return {
            isLoading: false,
            solicitudes: [],
            fechaDescarga: [new Date()],
            searchSolicitudesPopupOpened: false,
            selectedSearchType: "user_email",
            search: {},
            foundSolicitudes: [],
            selectedMonth:
                this.$dayjs()
                    .add(1, "month")
                    .month() < 10
                    ? `0${this.$dayjs()
                          .add(1, "month")
                          .month()}`
                    : this.$dayjs()
                          .add(1, "month")
                          .month(),
            selectedYear: this.$dayjs().year(),
            years: [],
            isLoadingMore: false,
            showInfinitePreloader: true,
            popupOpened: false,
            justLoaded: false,
        };
    },

    computed: {
        ...mapState(["dev"]),
        ...mapState("homologacionVacunasNac", ["SOLICITUDES_FETCH_SIZE", "SOLICITUDES_LOAD_MORE_FETCH_SIZE"]),
        ...mapGetters(["centralAdminAccount"]),

        foundSolicitudesOrdered() {
            return this.foundSolicitudes.sort((a, b) => {
                if (a.wh < b.wh) return -1;
                if (a.wh > b.wh) return 1;
                return 0;
            });
        },
    },

    beforeMount() {
        let primerAño = this.$dayjs('01/01/2021').year();
        let actualAño = this.$dayjs().year();
        let cantidad = actualAño-primerAño;
        let years = [];
        
        for (let index = 0; index <= cantidad; index++) {
            const element = this.$dayjs()
                    .subtract(index, "year")
                    .year()

            years.push(element)
        }
        this.years = years;
    },

    methods: {
        openPopup() {
            this.popupOpened = true;
        },
        closePopup() {
            this.popupOpened = false;
        },
        resetSelectedFilter(){
            this.selectedMonth =
                        this.$dayjs()
                            .add(1, "month")
                            .month() < 10
                            ? `0${this.$dayjs()
                                  .add(1, "month")
                                  .month()}`
                            : this.$dayjs()
                                  .add(1, "month")
                                  .month();                      
            this.selectedYear = this.$dayjs().year();
            this.$refs.month.f7SmartSelect.setValue([this.selectedMonth]);
            this.$refs.year.f7SmartSelect.setValue([`${this.selectedYear}`]);
        },
        async generaDescarga() {
            try {
                let date = `${this.selectedMonth}/14/${this.selectedYear}`;
                let startDate = this.$dayjs(date)
                    .startOf("month")
                    .unix();
                let endDate = this.$dayjs(date)
                    .endOf("month")
                    .unix();
                let firstElement = this.$dayjs(`12/14/2021`)
                    .startOf("month")
                    .unix();
                let now = this.$dayjs()
                    .startOf("month")
                    .unix();

                if(now < startDate || firstElement > startDate){
                    return this.$f7.dialog.alert('No existen registros en la fecha seleccionada.', "Aviso");
                }
                
                this.$f7.dialog.preloader("Generando archivo...");
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let envx = this.$store.state.dev ? "dev" : "prod";
                let filter = "Aceptada";
                let response = await axios.get(
                    `${this.$store.getters.getApiUrl}/prosex-homolog_export/getcolahomologpending/${envx}/${filter}/${this.tipo}/${startDate}/${endDate}/${0}`,
                    {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob", // important
                    }
                );
                this.$f7.dialog.close();

                const responseText = await response.data.text();

                if (response.data.type == "application/json") {
                    const body = responseText.replace(/['"]+/g, '');
                    const title = body === "No existen registros en la fecha seleccionada." ? "Aviso": "Error";
                    return this.$f7.dialog.alert(body, title);
                } else if (response.data.type == "text/html") {
                    this.popupOpened = false;
                    this.resetSelectedFilter()
                    return this.$f7.dialog
                        .create({
                            title: "Excel generado",
                            text: "Puede descargar el Excel presionando el botón.",
                            buttons: [
                                {
                                    text: "Cancelar",
                                },
                                {
                                    text: "Descargar",
                                    onClick: function() {
                                        window.open(responseText, "_blank");
                                    },
                                },
                            ],
                        })
                        .open();
                }
                // this.avisaOk("Descarga OK");
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error, error.message, error.response, error?.response?.data);
                this.$f7.dialog.alert(error.response?.data?.message || error.message);
            }
        },
        async handleInfinite() {
            console.log("infinite scroll triggered");
            await this.loadMore();
        },

        async handleBeforein() {
            if (this.solicitudes.length === 0) {
                await this.fetchSolicitudes();
            }
        },

        async fetchSolicitudes() {
            this.justLoaded = true;

            // para no triggerear loadMore
            setTimeout(() => {
                this.justLoaded = false;
            }, 0);

            this.solicitudes = [];

            this.isLoadingMore = false;
            this.showInfinitePreloader = true;

            let collection = this.dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";
            let estado = "Aceptada";

            this.isLoading = true;

            let query = await this.$firebase
                .firestore()
                .collection(collection)
                .where("est", "==", estado);

           
            if (this.centralAdminAccount.regional) {
                const regiones = Object.keys(this.centralAdminAccount.regiones) || [];
                if (regiones.length == 0) {
                    return this.$f7.dialog.alert("No tiene regiones asignadas");
                } else if (regiones.length > 10) {
                    return this.$f7.dialog.alert("No puede tener más de 10 regiones asignadas");
                }
                query = query.where("address.region", "in", regiones);
            }

            if (this.tipo === "sign") {
                query = query.where("cert.status", "==", "approved");
            }
            
            const solicitudesSnap = await query.orderBy("wh", "asc")
                .limit(this.SOLICITUDES_FETCH_SIZE)
                .get();

            solicitudesSnap.forEach(solicitud => {
                this.solicitudes.push(solicitud.data());
            });

            this.isLoading = false;

            if (solicitudesSnap.size < this.SOLICITUDES_FETCH_SIZE) {
                this.showInfinitePreloader = false;
            }
        },

        async loadMore() {
            if (this.isLoadingMore || !this.showInfinitePreloader || this.justLoaded) return;

            console.log("loading more");

            this.isLoadingMore = true;

            let collection = this.dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";
            let estado = "Aceptada";
          
            let query = this.$firebase
                .firestore()
                .collection(collection)
                .where("est", "==", estado);

            if (this.centralAdminAccount.regional) {
                const regiones = Object.keys(this.centralAdminAccount.regiones);
                if (regiones.length == 0) {
                    return this.$f7.dialog.alert("No tiene regiones asignadas");
                } else if (regiones.length > 10) {
                    return this.$f7.dialog.alert("No puede tener más de 10 regiones asignadas");
                }
                query = query.where("address.region", "in", regiones);
            }

            if (this.tipo === "sign") {
                query = query.where("cert.status", "==", "approved");
            }

            query = query.orderBy("wh", "asc");
                
            const lastSolicitud = this.solicitudes.sort((a, b) => a.wh - b.wh)[this.solicitudes.length - 1];

            if (lastSolicitud) {
                query = query.startAfter(lastSolicitud.wh);
            }

            const solicitudesSnap = await query.limit(this.SOLICITUDES_LOAD_MORE_FETCH_SIZE).get();

            const newSolicitudes = [];

            solicitudesSnap.forEach(solicitud => {
                newSolicitudes.push(solicitud.data());
            });

            if (newSolicitudes.length > 0) {
                this.solicitudes = this.solicitudes.concat(newSolicitudes);
                this.showInfinitePreloader = true;
            } else {
                this.showInfinitePreloader = false;
            }

            this.isLoadingMore = false;
        },

        selectedSearchTypeChanged(searchType) {
            this.selectedSearchType = searchType;

            if (this.selectedSearchType === "user_email") {
                this.$delete(this.search, "pid");
                this.$delete(this.search, "nombres");
                this.$delete(this.search, "apellido1");
                this.$delete(this.search, "apellido2");
            } else if (this.selectedSearchType === "patient_id") {
                this.$delete(this.search, "nombres");
                this.$delete(this.search, "apellido1");
                this.$delete(this.search, "apellido2");
                this.$delete(this.search, "user_email");
            } else if (this.selectedSearchType === "nombres_apellidos") {
                this.$delete(this.search, "user_email");
                this.$delete(this.search, "pid");
            }
        },

        searchInputChanged(e, property) {
            const value = e.target.value;

            if (value) {
                this.$set(this.search, property, e.target.value);
            } else {
                this.$delete(this.search, property);
            }
        },

        async searchSolicitudes(loadMore = false) {
            const preloaderText = loadMore ? "Cargando más resultados..." : "Buscando solicitudes...";

            this.$f7.dialog.preloader(preloaderText);

            let collection = this.dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";

            let query = this.$firebase
                .firestore()
                .collection(collection)
                .where("est", "==", "Aceptada");

            if (this.centralAdminAccount.regional) {
                const regiones = Object.keys(this.centralAdminAccount.regiones) || [];
                if (regiones.length == 0) {
                    return this.$f7.dialog.alert("No tiene regiones asignadas");
                } else if (regiones.length > 10) {
                    return this.$f7.dialog.alert("No puede tener más de 10 regiones asignadas");
                }
                query = query.where("address.region", "in", regiones);
            }

            if (this.tipo === "sign") {
                query = query.where("cert.status", "==", "approved");
            }

            if (this.selectedSearchType === "user_email") {
                if (!this.search.user_email) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Debe ingresar un email");
                    return;
                }

                query = query.where("user_email", "==", this.search.user_email);
            } else if (this.selectedSearchType === "patient_id") {
                if (!this.search.pid) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Debe ingresar un número de documento");
                    return;
                }

                query = query.where("pat.pid", "==", this.search.pid);
            } else if (this.selectedSearchType === "nombres_apellidos") {
                if (!this.search.nombres && !this.search.apellido1 && !this.search.apellido2) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Debe ingresar al menos un campo de búsqueda");
                    return;
                }

                if (this.search.apellido1) {
                    query = query.where("pat.apellido1", "==", this.search.apellido1);
                }

                if (this.search.apellido2) {
                    query = query.where("pat.apellido2", "==", this.search.apellido2);
                }

                if (this.search.nombres) {
                    query = query.where("pat.nombres", "==", this.search.nombres);
                }
            }

            query = query.orderBy("wh");

            if (loadMore) {
                const lastFoundSolicitud = this.foundSolicitudes[this.foundSolicitudes.length - 1];

                if (lastFoundSolicitud) {
                    query = query.startAfter(lastFoundSolicitud.wh);
                }
            }

            query = query.limit(this.SOLICITUDES_LOAD_MORE_FETCH_SIZE);

            query
                .get()
                .then(snapshot => {
                    const solicitudes = snapshot.docs.map(doc => doc.data());

                    if (!loadMore) {
                        this.foundSolicitudes = solicitudes;
                    } else {
                        this.foundSolicitudes = this.foundSolicitudes.concat(solicitudes);
                    }

                    if (this.foundSolicitudes.length === 0) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("No se encontraron solicitudes");
                        return;
                    }

                    this.$f7.dialog.close();
                })
                .catch(error => {
                    console.error("error buscando solicitudes", error);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("Error buscando solicitudes. " + error);
                });
        },

        handleSearchPopupClosed() {
            this.searchSolicitudesPopupOpened = false;
            this.search = {};
            this.foundSolicitudes = [];
        },

        handleSolicitudTakenFromBusqueda() {
            this.searchSolicitudesPopupOpened = false;
        },
    },
};
</script>
