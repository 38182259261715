<template>
    <f7-page @page:beforein="attemptoGetEsavi(null)" @page:beforeout="apagaPotObs()" name="isp" :page-content="false">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Encuestas" :subtitle="`Seguimiento síntomas post vacunación`"></f7-nav-title>
        </f7-navbar>
        <f7-toolbar tabbar top>
            <f7-link tab-link="#tab-isp-descargas" tab-link-active>Descargas</f7-link>
            <f7-link tab-link="#tab-1-isp">Graves</f7-link>
            <f7-link tab-link="#tab-2-isp">Estadísticas</f7-link>
            <f7-link tab-link="#tab-3-isp">Consultas</f7-link>
        </f7-toolbar>
        <f7-tabs animated>
            <f7-tab id="tab-isp-descargas" class="page-content" tab-active>
                <f7-block class="margin-top margin-bottom">
                    <f7-block-title>Descargar respuestas por fecha</f7-block-title>

                    <f7-list class="no-margin">
                        <f7-list-input
                            label="Fecha a descargar"
                            type="datepicker"
                            outline
                            @change="setDateDescargas($event.target.value)"
                            readonly
                            :calendar-params="{
                                dateFormat: 'dd/mm/yyyy',
                                value: [new Date().setDate(new Date().getDate() - 1)],
                                locale: 'es',
                                closeOnSelect: true,
                                maxDate: new Date().setDate(new Date().getDate() - 1),
                            }"
                        >
                        </f7-list-input>
                        <f7-list-input
                            label="Campaña"
                            type="select"
                            defaultValue=""
                            :value="campanaFocusDesarga"
                            @input="queFormulariosMostrar($event.target.value)"
                        >
                            <option value="">Todas</option>
                            <option v-for="campana in campanas" :key="campana.id" :value="campana.id">{{ campana.nombre }}</option>
                        </f7-list-input>
                        <f7-list-input
                            v-if="campanaFocusDesarga && formsAmostrar.length > 1"
                            label="Instrumento"
                            type="select"
                            :value="formularioFocoDescarga"
                            @input="formularioFocoDescarga = $event.target.value"
                            :disabled="!campanaFocusDesarga ? true : null"
                            defaultValue=""
                        >
                            <option value="">Todos</option>

                            <option v-for="unformid in formsAmostrar" :key="unformid" :value="unformid">{{
                                $store.state.formularios[unformid].name
                            }}</option>
                        </f7-list-input>
                        <f7-list-item
                            v-else-if="campanaFocusDesarga && formsAmostrar.length < 2"
                            :title="
                                !formsAmostrar.length
                                    ? 'No hay formularios de seguimientos en esta campana'
                                    : $store.state.formularios[formsAmostrar[0]].name
                            "
                            header="Instrumento"
                        >
                        </f7-list-item>

                        <f7-list-input
                            label="Tipo de respuestas"
                            type="select"
                            defaultValue=""
                            :value="tiporesps"
                            @input="tiporesps = $event.target.value"
                        >
                            <option value="x">Todas</option>
                            <option value="eg">Solo Graves</option>
                        </f7-list-input>
                    </f7-list>

                    <f7-button
                        :class="{ disabled: campanaFocusDesarga && !formsAmostrar.length }"
                        fill
                        @click="generaDescarga('x', lafecha)"
                        large
                        raised
                        >Descargar</f7-button
                    >
                </f7-block>
            </f7-tab>
            <f7-tab id="tab-1-isp" class="page-content">
                <f7-list class="no-margin-vertical" no-hairlines-md>
                    <f7-list-input
                        label="Fecha de consulta"
                        type="datepicker"
                        outline
                        @change="setDate($event.target.value)"
                        readonly
                        :calendar-params="{
                            dateFormat: 'dd/mm/yyyy',
                            value: [new Date().setDate(new Date().getDate() - 1)],
                            locale: 'es',
                            closeOnSelect: true,
                            maxDate: new Date().setDate(new Date().getDate() - 1),
                        }"
                    >
                    </f7-list-input>
                </f7-list>

                <f7-block class="text-align-center" v-if="cargandoESAVIgrave"
                    ><f7-preloader></f7-preloader><br />Cargando efectos adversos graves reportados...</f7-block
                >
                <f7-block v-else-if="!Object.values(esagarviehoy).length"
                    >No hay efectos adversos graves reportados para el {{ $dayjs(lafecha).format("DD/MM/YYYY") }}</f7-block
                >
                <f7-list v-else class="no-margin-vertical lista scrolleablexisp" media-list>
                    <f7-list-item divider :title="`Efectos adversos graves reportados el ${$dayjs(lafecha).format('DD/MM/YYYY')}`"></f7-list-item>
                    <f7-list-item
                        v-for="segmt in esagarviehoy"
                        :key="segmt.id"
                        :title="segmt.b"
                        :subtitle="segmt.a"
                        link="#"
                        @click="feteceha(segmt)"
                    >
                    </f7-list-item>
                </f7-list>
            </f7-tab>
            <f7-tab id="tab-2-isp" @tab:show="getRecrds(false)" class="page-content">
                <f7-block v-if="cargandoStats" class="text-align-center">
                    <f7-preloader></f7-preloader><br />Proceso de actualización de reporte ejecutandose (puede tardar hasta una hora) ...
                </f7-block>
                <f7-block class="no-padding no-margin">
                    <p class="padding no-margin">
                        Última actualización: {{ statsLoaded ? $dayjs.unix(statsLoaded.last_computed).format("HH:mm:ss DD/MM/YYYY") : "--"
                        }}<br /><small
                            >Este reporte muestra el número de encuestas agendadas por día, y de ellas, cuantas fueron respondidas (hasta 72 hr
                            despues del agendamiento).</small
                        >
                    </p>
                    <div class="data-table card">
                        <table>
                            <thead>
                                <tr>
                                    <th class="label-cell">Fecha</th>
                                    <th class="numeric-cell">Agendados</th>
                                    <th class="numeric-cell">Respondidos</th>
                                    <th class="numeric-cell">Grave</th>
                                    <th v-if="false" class="numeric-cell">.xls</th>
                                </tr>
                            </thead>
                            <tbody v-if="statsLoaded && statsLoaded.segs">
                                <tr v-for="(dtinf, dia) in statsLoaded.segs" :key="dia">
                                    <td class="label-cell">{{ $dayjs(dia).format("DD/MM/YYYY") }}</td>
                                    <td class="numeric-cell">{{ dtinf.a || 0 }}</td>
                                    <td class="numeric-cell">{{ dtinf.r ? `${dtinf.r} (${porcentj(dtinf.r, dtinf.a)}%)` : 0 }}</td>
                                    <td class="numeric-cell">{{ dtinf.g || 0 }}</td>
                                    <td v-if="false">
                                        <f7-link outline v-if="dtinf.r" @click="generaDescarga('x', dia)"
                                            ><font-awesome-icon icon="file-download"></font-awesome-icon
                                        ></f7-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </f7-block>
            </f7-tab>
            <f7-tab id="tab-3-isp" class="page-content">
                <f7-list class="no-margin">
                    <f7-list-input label="Tipo documento" type="select" defaultValue="RUN" :value="tipodoc" @input="tipodoc = $event.target.value">
                        <option selected value="RUN">RUN</option>
                        <option value="PAS">Pasaporte</option>
                        <option value="DNI">DNI</option>
                    </f7-list-input>
                    <f7-list-input
                        label="Documento"
                        type="text"
                        placeholder="documento"
                        :value="busqueda"
                        @keyup.native.enter="preSearchPatients"
                        @input="busqueda = $event.target.value"
                        clear-button
                    >
                    </f7-list-input>
                </f7-list>
                <f7-block class="margin-top"
                    ><f7-button @click="preSearchPatients" fill large raised
                        ><font-awesome-icon class="fa-lg" icon="search"></font-awesome-icon></f7-button
                ></f7-block>
            </f7-tab>
        </f7-tabs>

        <f7-popup class="paxinfoxdet" :opened="!!paxinfodetOpened" @popup:closed="paxinfodetOpened = null">
            <f7-page v-if="paxinfodetOpened">
                <f7-navbar title="Detalles paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-list media-list class="evlado no-margin-top">
                    <f7-list-item
                        :title="
                            `${paxinfodetOpened.paxinfo.apellido1 || ''} ${paxinfodetOpened.paxinfo.apellido2 || ''}, ${paxinfodetOpened.paxinfo
                                .nombres || ''}`
                        "
                        :subtitle="
                            `${paxinfodetOpened.paxinfo.idenType} ${
                                paxinfodetOpened.paxinfo.idenType == 'RUN'
                                    ? formatRut(paxinfodetOpened.paxinfo.identity)
                                    : paxinfodetOpened.paxinfo.identity
                            } ${paxinfodetOpened.paxinfo.nac_iso2 ? '| ' + paxinfodetOpened.paxinfo.nac_iso2 : ''}`
                        "
                        :text="$dayjs(paxinfodetOpened.paxinfo.fechanac).format('DD/MM/YYYY')"
                        :after="suageStrg(paxinfodetOpened.paxinfo.fechanac)"
                    >
                        <img src="../static/heros/avatar.jpg" slot="media" />
                    </f7-list-item>
                    <f7-list-item divider title="Información de contacto"></f7-list-item>
                    <f7-list-item
                        v-if="paxinfodetOpened.contactinfo && paxinfodetOpened.contactinfo.email"
                        title="email"
                        :after="paxinfodetOpened.contactinfo.email"
                    ></f7-list-item>
                    <f7-list-item
                        v-if="paxinfodetOpened.contactinfo && paxinfodetOpened.contactinfo.address"
                        title="Comuna de Residencia"
                        :after="paxinfodetOpened.contactinfo.address.comuna"
                    ></f7-list-item>
                    <f7-list-item
                        title="Teléfono"
                        v-if="paxinfodetOpened.contactinfo && paxinfodetOpened.contactinfo.phone"
                        :after="paxinfodetOpened.contactinfo.phone"
                    ></f7-list-item>
                </f7-list>
                <f7-block>
                    <f7-block-title>Historial de Vacunación</f7-block-title>
                    <f7-list class="maxilatox">
                        <f7-list-item
                            v-for="unavac in paxinfodetOpened.historial"
                            :key="unavac.id"
                            :header="$dayjs(unavac.d).format('dddd D [de] MMMM [de] YYYY')"
                            :title="`${$store.state.vacunas[unavac.b].vacunaPcName} (${$store.state.vacunas[unavac.b].laboratorioName})`"
                        ></f7-list-item>
                    </f7-list>
                    <f7-block v-if="!paxinfodetOpened.historial || !Object.values(paxinfodetOpened.historial).length"
                        >No hay registro de vacunas para este paciente.</f7-block
                    >
                </f7-block>
                <f7-block strong class="text-align-center"
                    >ESAVI grave reportado en contexto de Campaña:<br />
                    <h3 class="no-margin">{{ $store.state.campanas[Object.values(paxinfodetOpened.segui_camp)[0].g].nombre }}</h3>
                    bajo el esquema
                    <b>{{
                        Object.values(paxinfodetOpened.segui_camp)[0].h == "Refuerzo"
                            ? "Refuerzo"
                            : $store.state.campanas[Object.values(paxinfodetOpened.segui_camp)[0].g].esquemas[
                                  Object.values(paxinfodetOpened.segui_camp)[0].h
                              ].name
                    }}</b></f7-block
                >

                <f7-list accordion-list>
                    <f7-list-item
                        v-for="unseg in paxinfodetOpened.segui_camp"
                        :key="unseg.id"
                        accordion-item
                        :title="`Respuestas a seguimiento día ${unseg.dp}`"
                    >
                        <font-awesome-icon v-if="unseg.w" icon="check" class="text-color-teal" slot="after"></font-awesome-icon>
                        <font-awesome-icon v-else icon="clock" class="text-color-red" slot="after"></font-awesome-icon>
                        <f7-accordion-content>
                            <f7-block v-if="!unseg.w">No ha respondido este seguimiento</f7-block>
                            <f7-block v-else>
                                <div
                                    v-for="preg in getSoloPreguntasAbled(
                                        $store.state.formularios[Object.values(paxinfodetOpened.segui_camp)[0].f].preguntas
                                    )"
                                    :key="preg.id"
                                >
                                    <f7-card v-if="unseg.r && unseg.r[preg.id]">
                                        <f7-card-header class="bold"><p v-html="$sanitize(titleEsavi(preg))">{{ titleEsavi(preg) }}</p></f7-card-header>
                                        <f7-card-content>
                                            <f7-list v-if="preg.tipo == 'elige_many'">
                                                <f7-list-item
                                                    v-for="alt in preg.alternativas"
                                                    disabled
                                                    readonly
                                                    :checked="unseg.r[preg.id][alt.id]"
                                                    :key="alt.id"
                                                    checkbox
                                                    :value="alt.id"
                                                    :title="alt.tx"
                                                ></f7-list-item>
                                            </f7-list>
                                            <f7-list v-if="preg.tipo == 'elige_una'">
                                                <f7-list-item
                                                    v-for="alt in preg.alternativas"
                                                    :name="`elradio_${preg.id}`"
                                                    disabled
                                                    readonly
                                                    :checked="unseg.r[preg.id] == alt.id"
                                                    :key="alt.id"
                                                    radio
                                                    :value="alt.id"
                                                    :title="alt.tx"
                                                ></f7-list-item>
                                            </f7-list>
                                            <f7-list v-else>
                                                <f7-list-input
                                                    v-if="preg.tipo == 'input_fecha'"
                                                    type="text"
                                                    outline
                                                    :value="$dayjs(unseg.r[preg.id]).format('DD/MM/YYYY')"
                                                    readonly
                                                >
                                                </f7-list-input>
                                                <f7-list-input
                                                    v-if="preg.tipo == 'input_numero'"
                                                    type="number"
                                                    outline
                                                    :value="unseg.r[preg.id]"
                                                    readonly
                                                >
                                                </f7-list-input>
                                                <f7-list-input
                                                    v-if="preg.tipo == 'input_texto'"
                                                    :type="preg.txtarea ? 'textarea' : 'text'"
                                                    outline
                                                    readonly
                                                    :value="unseg.r[preg.id]"
                                                >
                                                </f7-list-input>
                                            </f7-list>
                                        </f7-card-content>
                                    </f7-card>
                                </div>
                            </f7-block>
                        </f7-accordion-content>
                    </f7-list-item>
                </f7-list>
            </f7-page>
        </f7-popup>

        <f7-popup class="onepaxdetailsips" :opened="paxResultadosInfoOpened" @popup:closed="paxResultadosInfoOpened = null">
            <f7-page v-if="paxResultadosInfoOpened">
                <f7-navbar title="Detalles paciente">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-list media-list class="evlado no-margin-top">
                    <f7-list-item
                        :title="
                            `${paxBuscadoMeta.paxinfo.apellido1 || ''} ${paxBuscadoMeta.paxinfo.apellido2 || ''}, ${paxBuscadoMeta.paxinfo.nombres ||
                                ''}`
                        "
                        :subtitle="
                            `${paxBuscadoMeta.paxinfo.idenType} ${
                                paxBuscadoMeta.paxinfo.idenType == 'RUN'
                                    ? formatRut(paxBuscadoMeta.paxinfo.identity)
                                    : paxBuscadoMeta.paxinfo.identity
                            }  ${paxBuscadoMeta.paxinfo.nac_iso2 ? '| ' + paxBuscadoMeta.paxinfo.nac_iso2 : ''}`
                        "
                        :text="$dayjs(paxBuscadoMeta.paxinfo.fechanac).format('DD/MM/YYYY')"
                        :after="suageStrg(paxBuscadoMeta.paxinfo.fechanac)"
                    >
                        <img src="../static/heros/avatar.jpg" slot="media" />
                    </f7-list-item>
                    <f7-list-item divider title="Información de contacto"></f7-list-item>
                    <f7-list-item
                        v-if="paxBuscadoMeta.contactinfo && paxBuscadoMeta.contactinfo.email"
                        title="email"
                        :after="paxBuscadoMeta.contactinfo.email"
                    ></f7-list-item>
                    <f7-list-item
                        v-if="paxBuscadoMeta.contactinfo && paxBuscadoMeta.contactinfo.address"
                        title="Comuna de Residencia"
                        :after="paxBuscadoMeta.contactinfo.address.comuna"
                    ></f7-list-item>
                    <f7-list-item
                        title="Teléfono"
                        v-if="paxBuscadoMeta.contactinfo && paxBuscadoMeta.contactinfo.phone"
                        :after="paxBuscadoMeta.contactinfo.phone"
                    ></f7-list-item>
                </f7-list>
                <f7-block>
                    <f7-block-title>Historial de Vacunación</f7-block-title>
                    <f7-list class="maxilatox">
                        <f7-list-item
                            v-for="unavac in paxBuscadoMeta.historial"
                            :key="unavac.id"
                            :header="$dayjs(unavac.d).format('dddd D [de] MMMM [de] YYYY')"
                            :title="`${$store.state.vacunas[unavac.b].vacunaPcName} (${$store.state.vacunas[unavac.b].laboratorioName})`"
                        ></f7-list-item>
                    </f7-list>
                    <f7-block v-if="!paxBuscadoMeta.historial || !Object.values(paxBuscadoMeta.historial).length"
                        >No hay registro de vacunas para este paciente.</f7-block
                    >
                </f7-block>

                <f7-block v-for="(campnifo, campid) in paxBuscadoMeta.respuestasPorCampana" :key="campid">
                    <f7-list v-for="(esquemameta, esquemaid) in campnifo" :key="esquemaid" accordion-list>
                        <f7-list-item
                            divider
                            :title="
                                `${paxBuscadoMeta.campanasYaPedidas[campid].nombre} | esquema ${
                                    paxBuscadoMeta.campanasYaPedidas[campid].esquemas[esquemaid]
                                        ? paxBuscadoMeta.campanasYaPedidas[campid].esquemas[esquemaid].name
                                        : esquemaid
                                }`
                            "
                        ></f7-list-item>
                        <f7-list-item
                            v-for="unseg in esquemameta"
                            :key="unseg.id"
                            accordion-item
                            :header="paxBuscadoMeta.formulariosYapedidos[unseg.f].name"
                            :footer="unseg.hd ? `Gatillado por dosis del ${unseg.hd}` : null"
                            :title="`Respuestas a seguimiento día ${unseg.dp}`"
                        >
                            <font-awesome-icon v-if="unseg.w" icon="check" class="text-color-teal" slot="after"></font-awesome-icon>
                            <font-awesome-icon v-else icon="clock" class="text-color-red" slot="after"></font-awesome-icon>
                            <f7-accordion-content>
                                <f7-block v-if="!unseg.w">No ha respondido este seguimiento</f7-block>
                                <f7-block v-else>
                                    <div v-for="preg in getSoloPreguntasAbled(paxBuscadoMeta.formulariosYapedidos[unseg.f].preguntas)" :key="preg.id">
                                        <f7-card v-if="unseg.r && unseg.r[preg.id]">
                                            <f7-card-header class="bold"><p v-html="$sanitize(titleEsavi(preg))">{{ titleEsavi(preg) }}</p></f7-card-header>
                                            <f7-card-content>
                                                <f7-list v-if="preg.tipo == 'elige_many'">
                                                    <f7-list-item
                                                        v-for="alt in preg.alternativas"
                                                        disabled
                                                        readonly
                                                        :checked="unseg.r[preg.id][alt.id]"
                                                        :key="alt.id"
                                                        checkbox
                                                        :value="alt.id"
                                                        :title="alt.tx"
                                                    ></f7-list-item>
                                                </f7-list>
                                                <f7-list v-if="preg.tipo == 'elige_una'">
                                                    <f7-list-item
                                                        v-for="alt in preg.alternativas"
                                                        :name="`elradio_${preg.id}`"
                                                        disabled
                                                        readonly
                                                        :checked="unseg.r[preg.id] == alt.id"
                                                        :key="alt.id"
                                                        radio
                                                        :value="alt.id"
                                                        :title="alt.tx"
                                                    ></f7-list-item>
                                                </f7-list>
                                                <f7-list v-else>
                                                    <f7-list-input
                                                        v-if="preg.tipo == 'input_fecha'"
                                                        type="text"
                                                        outline
                                                        :value="$dayjs(unseg.r[preg.id]).format('DD/MM/YYYY')"
                                                        readonly
                                                    >
                                                    </f7-list-input>
                                                    <f7-list-input
                                                        v-if="preg.tipo == 'input_numero'"
                                                        type="number"
                                                        outline
                                                        :value="unseg.r[preg.id]"
                                                        readonly
                                                    >
                                                    </f7-list-input>
                                                    <f7-list-input
                                                        v-if="preg.tipo == 'input_texto'"
                                                        :type="preg.txtarea ? 'textarea' : 'text'"
                                                        outline
                                                        readonly
                                                        :value="unseg.r[preg.id]"
                                                    >
                                                    </f7-list-input>
                                                </f7-list>
                                            </f7-card-content>
                                        </f7-card>
                                    </div>
                                </f7-block>
                            </f7-accordion-content>
                        </f7-list-item>
                    </f7-list>
                </f7-block>
            </f7-page>
        </f7-popup>

        <select-patient-popup
            :opened="selectPatientPopupOpened"
            :patients="patientsToSelect"
            @popup-closed="selectPatientPopupOpened = false"
            @patient-selected="handlePatientSelected"
        ></select-patient-popup>
    </f7-page>
</template>
<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import SelectPatientPopup from "../components/select-patient-popup.vue";

export default {
    mixins: [commonmixin],

    components: {
        "select-patient-popup": SelectPatientPopup,
    },

    data() {
        return {
            campanaFocusDesarga: "",
            formularioFocoDescarga: "",
            tiporesps: "x",
            fechadescargas: "",
            formsAmostrar: [],
            buscado: "",
            tipodoc: "RUN",
            busqueda: "",
            observaSolicitudes: null,
            esagarviehoy: {},
            cargandoESAVIgrave: false,
            paxinfodetOpened: null,
            paxResultadosInfoOpened: false,
            paxBuscadoMeta: {
                campanasYaPedidas: {},
                formulariosYapedidos: {},
                historial: {},
                paxinfo: {},
                respuestasPorCampana: {},
            },
            lafecha: "",
            statsLoaded: null,
            cargandoStats: true,
            computarefobs: null,

            patientsToSelect: [],
            selectPatientPopupOpened: false,
        };
    },
    computed: {
        ...mapState(["user", "campanas", "formularios", "dev"]),
        ...mapGetters(["centralAdminAccount"]),
    },
    mounted() {
        this.getFormsYcMPANAS();
    },
    methods: {
        setDateDescargas(value) {
            if (value) {
                console.log("seteando fecha a", value);
                let cleanfecha = this.$dayjs(value, "DD/MM/YYYY").format("YYYYMMDD");
                this.fechadescargas = cleanfecha;
            }
        },
        queFormulariosMostrar(campanaid) {
            let formsAmostrar = {};
            this.formularioFocoDescarga = "";
            let segus = ["segu", "segu2"];
            if (campanaid) {
                Object.values(this.campanas[campanaid].esquemas || {}).forEach(unesquema => {
                    Object.values(unesquema.cal || {}).forEach(uncal => {
                        segus.forEach(unsegu => {
                            let seguinfo = uncal[unsegu] || {};
                            if (seguinfo.formid) {
                                formsAmostrar[seguinfo.formid] = seguinfo.formid;
                            }
                        });
                    });
                });
                Object.values(this.campanas[campanaid].refuerzos_seg || {}).forEach(segrefrx => {
                    if (segrefrx.formid) {
                        formsAmostrar[segrefrx.formid] = segrefrx.formid;
                    }
                });

                if (campanaid === "-MSxHZSB7GpMsVDfVtZb" || campanaid === "-MSm5MP2qjUBkMhulcNd") {
                    formsAmostrar["-MoZlq08xBkQhB8KthI9"] = "-MoZlq08xBkQhB8KthI9";
                }
            }

            this.formsAmostrar = Object.keys(formsAmostrar);
            this.campanaFocusDesarga = campanaid;
            if (this.formsAmostrar.length == 1) {
                this.formularioFocoDescarga = this.formsAmostrar[0];
            }
        },
        async getFormsYcMPANAS() {
            try {
                await Promise.all([this.getFormularios(), this.loadcampanas()]);
                console.log(this.formularios, this.campanas);
            } catch (error) {
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async getFormularios() {
            if (!this.formularios.length) {
                let snpshot = await this.$firebase
                    .database()
                    .ref("formularios")
                    .once("value");
                this.$store.commit("setWhatTo", { what: "formularios", to: snpshot.val() || {} });
            }
        },
        async loadcampanas() {
            if (!Object.keys(this.campanas).length) {
                let snpstho = await this.$firebase
                    .database()
                    .ref("campanas")
                    .once("value");
                this.$store.commit("setObjTo", { what: "campanas", to: snpstho.val() || {} });
            }
        },
        getRVInfo({ seg, rvs }) {
            let fechaini = this.$dayjs(seg.dt);
        },

        async preSearchPatients() {
            const trimBusqueda = this.busqueda.trim();

            if (trimBusqueda.length < 2) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe ingresar al menos 2 caracteres");
                return;
            }

            if (this.tipodoc === "RUN") {
                await this.isp_oneguy_oneform();
            } else {
                this.$f7.dialog.preloader("Buscando...");

                const response = await this.commonExecute(
                    { tipoDoc: this.tipodoc, nroDoc: trimBusqueda, dev: this.dev },
                    "admin-preSearchPatient",
                    false
                );

                if (response.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(response.error.message);
                    return;
                }

                const { patients } = response;

                if (patients.length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron pacientes");
                    return;
                }

                if (patients.length === 1 && patients[0].identity === trimBusqueda) {
                    this.$f7.dialog.close();

                    const patient = patients[0];

                    this.tipodoc = patient.idenType;
                    this.busqueda = patient.identity;

                    await this.isp_oneguy_oneform();
                } else {
                    this.patientsToSelect = patients;
                    this.selectPatientPopupOpened = true;
                }

                this.$f7.dialog.close();
            }
        },

        async handlePatientSelected(patient) {
            this.tipodoc = patient.idenType;
            this.busqueda = patient.identity;

            this.selectPatientPopupOpened = false;

            await this.isp_oneguy_oneform();

            this.patientsToSelect = [];
        },

        async isp_oneguy_oneform() {
            try {
                this.buscado = "";
                console.log(this.busqueda);
                this.$f7.dialog.preloader("Buscando...");
                let datous = await this.commonExecute({ idenType: this.tipodoc, identity: this.busqueda }, "admin-ispOneguyOneForm", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log(datous.payload);
                this.paxBuscadoMeta = Object.assign({}, datous.payload);

                // paxBuscadoMeta:{
                //   campanasYaPedidas:{},
                //   formulariosYapedidos:{},
                //   historial:{},
                //   paxinfo:{},
                //   respuestasPorCampana:{}
                // },
                this.paxResultadosInfoOpened = true;
                this.$f7.dialog.close();
                this.buscado = this.busqueda;
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        porcentj(cuanto, decuanto) {
            if (decuanto) {
                return Math.round((cuanto / decuanto) * 100);
            } else {
                return 0;
            }
        },
        apagaPotObs() {
            if (this.computarefobs) {
                console.log("turning off observable");
                this.computarefobs.off();
            }
        },
        async observaProcesamient() {
            if (!this.computarefobs) {
                console.log("turning on observable");
                this.computarefobs = this.$firebase.database().ref("compu_stats/computing");
                this.computarefobs.on("value", snapshot => {
                    if (!snapshot.val()) {
                        console.log("computo termino, pedir datos");
                        this.getRecrds(false);
                    } else {
                        console.log("Aun estan computando, esperar");
                    }
                });
            }
        },
        async getRecrds(force) {
            if (!force && this.statsLoaded) {
                return false;
            }
            try {
                this.cargandoStats = true;
                let datous = await this.commonExecute({ force }, "admin-getEsaviLogs", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                if (datous.payload.computing) {
                    console.log("datous.payload", datous.payload);
                    console.log("se estan computando, encender observable");
                    //encender observable
                    this.statsLoaded = Object.assign({}, datous.payload);
                    //TODO AQUI VAS CHELO
                    this.observaProcesamient();
                    this.cargandoStats = false;
                } else {
                    console.log("stats are here", datous.payload);
                    this.statsLoaded = Object.assign({}, datous.payload);
                    this.cargandoStats = false;
                }
            } catch (error) {
                this.cargandoStats = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        getSoloPreguntasAbled(pregunts) {
            if (!pregunts || !Object.keys(pregunts).length) {
                return [];
            }
            return Object.values(pregunts).filter(unapreg => {
                return !unapreg.disabled;
            });
        },
        async generaDescarga() {
            let lafecha = this.fechadescargas;
            let tipo = this.tiporesps;
            let campanaid = this.campanaFocusDesarga || "0";
            let formularioid = this.formularioFocoDescarga || "0";
            /*
      campanaFocusDesarga:'',
        formularioFocoDescarga:'',
        tiporesps:'x',
        fechadescargas:'',

        */
            console.log("descargando esavis esta fecha", lafecha, tipo, campanaid, formularioid);

            try {
                this.$f7.dialog.preloader("Generando archivo. Este proceso podría demorar varios minutos.");
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let envx = this.$store.state.dev ? "dev" : "prod";
                let response = await axios.get(
                    `${this.$store.getters.getApiUrl}/prosex-isp_export_neo/corxpt/esavi_filtered/${lafecha}/${tipo}/${envx}/${campanaid}/${formularioid}`,
                    {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob", // important
                    }
                );
                this.$f7.dialog.close();
                if (response.data.type == "application/json") {
                    var textPromise = await response.data.text();
                    console.log("error", textPromise);
                    return this.$f7.dialog.alert(textPromise, "Error");
                } else if (response.data.type == "text/html") {
                    var textPromise = await response.data.text();
                    return this.$f7.dialog
                        .create({
                            title: "Excel generado",
                            text: "Puede descargar el Excel presionando el botón.",
                            buttons: [
                                {
                                    text: "Cancelar",
                                },
                                {
                                    text: "Descargar",
                                    onClick: function() {
                                        window.open(textPromise, "_blank");
                                    },
                                },
                            ],
                        })
                        .open();
                }
                this.avisaOk("Descarga OK");
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error, error.message, error.response, error?.response?.data);
                this.$f7.dialog.alert(error.response?.data?.message || error.message);
            }
        },

        async feteceha(seuimgitno) {
            try {
                this.$f7.dialog.preloader("Cargando...");
                let llamaSupport = 1;
                console.log("llamando a", {
                    smtid: seuimgitno.id,
                    llamaSupport,
                    tguid: seuimgitno.u,
                    campid: seuimgitno.g,
                    pacienteid: seuimgitno.a,
                });
                let datous = await this.commonExecute(
                    { smtid: seuimgitno.id, llamaSupport, tguid: seuimgitno.u, campid: seuimgitno.g, pacienteid: seuimgitno.a },
                    "admin-ispfecther",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                let todalinfo = datous.payload;
                this.paxinfodetOpened = Object.assign({}, todalinfo);
                console.log(todalinfo);
                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        setDate(value) {
            if (value) {
                console.log("seteando fecha a", value);
                let cleanfecha = this.$dayjs(value, "DD/MM/YYYY").format("YYYYMMDD");
                this.lafecha = cleanfecha;
                this.attemptoGetEsavi(cleanfecha);
            }
        },
        async attemptoGetEsavi(date) {
            if (!this.cargandoESAVIgrave) {
                try {
                    this.cargandoESAVIgrave = true;
                    console.log("va a tratar de leer esavi grave de", date);

                    if (!date) {
                        date = this.$dayjs()
                            .subtract(1, "day")
                            .format("YYYYMMDD");
                    }
                    this.esagarviehoy = {};

                    let datous = await this.commonExecute({ date }, "admin-getEsaviSupportDb", false);
                    if (datous.error) {
                        throw new Error(datous.error.message);
                    }
                    let llegada = datous.payload || {};
                    this.esagarviehoy = Object.assign({}, llegada);
                    // let antesdeayer = this.$dayjs().subtract(4,'day').format('YYYYMMDD');
                    // if(date>antesdeayer){
                    //   console.log('llamar a base de datos de produccion')
                    //    let potesave = await this.$firebase.database().ref(`segs`).orderByChild(`eg`).equalTo(date).once('value');
                    //    if(potesave.exists()){
                    //     console.log('hay graves hoy',potesave.val());
                    //     this.esagarviehoy = Object.assign({},potesave.val());
                    //   }
                    // }
                    // else{
                    //   console.log('llamar a base de datos de apoyo')
                    //   let datous = await this.commonExecute({date},'admin-getEsaviSupportDb',false);
                    //   if(datous.error){
                    //       throw new Error(datous.error.message);
                    //   }
                    //   let llegada = datous.payload || {};
                    //   this.esagarviehoy = Object.assign({},llegada);
                    // }
                    this.cargandoESAVIgrave = false;
                } catch (error) {
                    this.cargandoESAVIgrave = false;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            }
        },
        titleEsavi(data){
            return `${data.encabezado} ${data.req ? "*" : ""}`
        }
    },
};
</script>
<style>
.evlado img {
    width: 40px;
}
.maxilatox {
    max-height: 400px;
    overflow-y: auto;
}
.fschinput {
    width: 108px;
    font-size: 16px;
}
.scrolleablexisp {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
}
</style>
