<template>
  <div class="margin-bottom list no-hairlines-md">
        <ul>
            <li class="item-content item-input">
                <div class="item-inner">
                    <div class="item-title item-label">Busque Patógeno a agregar</div>
                    <div class="item-input-wrap">
                        <input
                            id="autodown-patogens"
                            @keyup.enter="manualAdd()"
                            v-model="cobertInput"
                            autocomplete="off"
                            name="nocomplet"
                            type="text"
                            placeholder="i.e. Bordetella pertussis"
                        />
                        <div class="item-input-info">
                            Presione enter para agregar nuevo
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { commonmixin } from '../mixins/common'

export default {
    mixins: [commonmixin],
    props:['cobertura'],
     data() {
      return {
        cobertInput: null,
        autocompletePatogenos: null
      }
     },
     computed:{
        ...mapGetters(['patogenos']),
        patsListParaAutoComplete() {
          let coerbur = this.cobertura || {};
          return this.patogenos.filter(unpatogen => {
            return !coerbur[unpatogen.id]
          }).map((unpat) => {
                return unpat.nm;
            });
        },
        normalizedListOfPagotens() {
            return this.patogenos.map((unpat) => {
                return this.normalize(unpat.nm);
            });
        },
     },
     mounted(){
         this.autocompletePatogenos = this.$f7.autocomplete.create({
            inputEl: "#autodown-patogens",
            openIn: "dropdown",
            source: (query, render) => {
                let results = [];
                for (let i = 0; i < this.patsListParaAutoComplete.length; i++) {
                    if (this.patsListParaAutoComplete[i].toLowerCase().indexOf(query.toLowerCase()) >= 0)
                        results.push(this.patsListParaAutoComplete[i]);
                }
                render(results);
            },
            on: {
                change: (value, px) => {
                    let suindice = this.encuentraIdPat(value[0]);
                    this.modelapat(suindice);
                }
            },
        });
     },
     methods:{
        manualAdd() {
            console.log("agregando a mano", this.normalizedListOfPagotens);
            let aGregar = this.cobertInput;
            if (!this.normalizedListOfPagotens.includes(this.normalize(aGregar))) {
                this.$f7.dialog.prompt(
                    "Indique enfermedades causadas por este patógeno (separadas por coma, puede incluir sinonimos).",
                    "Indique enfermedades",
                    (enfx) => {
                        console.log(enfx);
                        return this.crearPatogeno(aGregar, enfx);
                    }
                );
            } else {
              //quiere decir que existia en la lista, ver si no lo tenia agregado ya
              let suindice = this.encuentraIdPat(aGregar);
              let coerbur = this.cobertura || {};
              if(coerbur[suindice]){
                this.$emit('closeDuplicate',suindice)
              }
              else{
                  this.modelapat(suindice);
              }
            }
        },
        encuentraIdPat(patostring) {
            let noramlizada = this.normalize(patostring);
            const patogenox = this.patogenos.find((unpatx) => unpatx.namenorm == noramlizada);
            if (!patogenox) {
                return this.$f7.dialog.alert("Error al seleccionar patógeno.");
            }
            return patogenox.id;
        },
      async crearPatogeno(patogenoName, enfermedades) {
        try{
            this.$f7.dialog.preloader('Agregando cobertura...');
            let datous = await this.commonExecute({patogenoName,enfermedades},'admin-creaPatogeno',true);
            if(datous.error){
              throw new Error(datous.error.message);
            }
            this.$f7.dialog.close();
            this.$store.commit('updtObjProperty',{what:'patogenos',to: datous.payload});
            this.modelapat(datous.payload.id);
          return true;
        } catch(error){
           this.$f7.dialog.close();
           console.error(error);
           this.$f7.dialog.alert(error.message, error.code);
        }
      },
        modelapat(indicex) {
          this.$emit('patogenoAAgregar',indicex);
        }
     }
}
</script>
<style>

</style>