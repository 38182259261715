<template>
    <f7-page name="estadisticas" @page:beforein="fetchData">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Estadísticas" subtitle="de MeVacuno"></f7-nav-title>
        </f7-navbar>

        <f7-block>
            <f7c-alert v-if="isFilteringDate" type="warning" class="margin-half">
                Estás filtrando por fecha. Las consultas bajo este atributo <b>pueden tomar varios minutos</b> en responder.
            </f7c-alert>

            <!-- Filtros -->
            <f7-card outline>
                <f7-card-header class="display-flex justify-content-space-between">
                    <div>Filtrar estadísticas por:</div>
                    <f7-button small color="red" @click="clearFilters">Limpiar filtros</f7-button>
                </f7-card-header>
                <f7-card-content>
                    <f7-list>
                        <!-- Comunas -->
                        <f7-list-item
                            title="Comunas"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: 'Buscar comuna',
                                popupCloseLinkText: 'Cerrar',
                                virtualList: true,
                                setValueText: false,
                            }"
                            :disabled="!comunas.data"
                        >
                            <select v-model="filters.comunas" multiple name="comuna">
                                <option v-for="comuna in comunas.data" :key="comuna.code" :value="comuna.code">
                                    {{ `${comuna.name} (${comuna.provname})` }}
                                </option>
                            </select>
                            <div slot="after">{{ comunasFilter }}</div>
                        </f7-list-item>

                        <!-- Regiones -->
                        <f7-list-item
                            title="Regiones"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: 'Buscar región',
                                popupCloseLinkText: 'Cerrar',
                                virtualList: true,
                                setValueText: false,
                            }"
                            :disabled="!regiones.data"
                        >
                            <select v-model="filters.regiones" multiple name="region">
                                <option v-for="(region, index) in regiones.data" :key="index" :value="region.id">
                                    {{ region.name }}
                                </option>
                            </select>
                            <div slot="after">{{ regionesFilter }}</div>
                        </f7-list-item>

                        <!-- Fecha inicio -->
                        <f7-list-input
                            id="dateRange"
                            label="Rango de fechas"
                            type="datepicker"
                            clear-button
                            :calendar-params="{
                                dateFormat: 'yyyy-mm-dd',
                                locale: 'es',
                                rangePicker: true,
                                maxDate: new Date().setDate(new Date().getDate() - 1),
                                minDate: MIN_DATE,
                            }"
                            @change="filters.rangeDate = $event.target.value"
                        ></f7-list-input>
                    </f7-list>

                    <f7-button @click="applyFilters" fill class="margin-top" :disabled="loadingComunas || loadingRegiones">Aplicar filtros</f7-button>
                </f7-card-content>
            </f7-card>

            <!-- Gráfico de estadísticas por comuna -->
            <f7-card outline class="padding">
                <f7-col v-if="loadingLogsPatientsActions && loadingLogsPatientsActionsCounts" class="text-align-center">
                    <f7-preloader></f7-preloader>
                </f7-col>
                <span v-else-if="!estadisticas.data || !estadisticas.data.length">
                    No se encontraron estadísticas para los parámetros indicados.
                </span>
                <div v-else>
                    <small class="text-color-gray margin-bottom">Estadísticas computadas desde el {{ $dayjs(MIN_DATE).format("DD [de] MMMM [del] YYYY") }}</small>

                    <h4 class="no-margin-bottom">Estadísticas totales</h4>
                    <div v-if="estadisticasCount.data">
                        <p class="no-margin-vertical">Total descargas de pases: {{ estadisticasCount.data.descarga_pase_total.toLocaleString("es-CL") }}</p>
                        <p class="no-margin-vertical">Total descargas de pases con FEA: {{ estadisticasCount.data.descarga_pase_fea_total.toLocaleString("es-CL") }}</p>
                        <p class="no-margin-vertical">Total solicitudes de FEA: {{ estadisticasCount.data.cola_firma_total.toLocaleString("es-CL") }}</p>
                    </div>

                    <h2 class="margin-top-max text-align-center">Estadísticas por comuna</h2>
                    <bar-chart :chart-data="computedChartEstadisticasData" :options="{ responsive: true, stacked: false }" :height="180"></bar-chart>
                </div>
            </f7-card>

            <!-- Gráfico de estadísticas resoluciones de identidad -->
            <f7-card outline class="padding">
                <template v-if="!loadingLogsResolucionIdentidad">
                    <h4 class="no-margin-bottom">Estadísticas totales</h4>
                    <p class="no-margin-vertical">Total de resoluciones: {{ getTotalResoluciones.toLocaleString("es-CL") }}</p>
                </template>

                <div class="margin-bottom">
                    <f7-col v-if="loadingLogsResolucionIdentidad" class="text-align-center">
                        <f7-preloader></f7-preloader>
                    </f7-col>
                    <span v-else-if="!logsResolucionIdentidad.data || !logsResolucionIdentidad.data.length">
                        No se encontraron estadísticas para los parámetros indicados.
                    </span>
                    <template v-else>
                        <h2 class="margin-top-max text-align-center">Estadísticas resolución solicitudes de verificación de identidad</h2>
                        <pie-chart :chart-data="computedChartLogsResolucionIdentidad" :options="{ responsive: true, stacked: false }" :height="180"></pie-chart>
                    </template>
                </div>

                <f7-col v-if="loadingLogsResolucionIdentidadPorUsuario" class="text-align-center">
                    <f7-preloader></f7-preloader>
                </f7-col>
                <span v-else-if="!logsResolucionIdentidadPorUsuario.data || !logsResolucionIdentidadPorUsuario.data.length">
                    No se encontraron estadísticas para los parámetros indicados.
                </span>
                <template v-else>
                    <h2 class="margin-top-max text-align-center">Estadísticas resolución solicitudes de verificación de identidad por usuario</h2>

                    <f7-list>
                        <f7-list-item 
                            title="Filtrar por usuario"
                            outline 
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: 'true',
                                searchbarPlaceholder: 'Busque usuario por email',
                            }"
                        >
                            <select v-model="filters.resolucionIdentidadUsers" name="users" multiple>
                                <option 
                                    v-for="(userEmail, index) in resolucionIdentidadUsersList" 
                                    :key="index"
                                    :value="userEmail"
                                >{{ userEmail }}</option>
                            </select>
                        </f7-list-item>
                    </f7-list>
                    <bar-chart :chart-data="computedChartLogsResolucionIdentidadPorUsuario" :options="{ responsive: true, stacked: false }" :height="180"></bar-chart>
                </template>
            </f7-card>

            <!-- Gráfico de estadísticas de verificaciones de usuarios-->
            <f7-card outline class="padding">
                <f7-col v-if="loadingLogsUsersVerified" class="text-align-center">
                    <f7-preloader></f7-preloader>
                </f7-col>
                <span v-else-if="!logsUsersVerified.data">
                    No se encontraron estadísticas para los parámetros indicados.
                </span>
                <div v-else>
                    <h4 class="no-margin-bottom">Estadísticas totales</h4>
                    <p class="no-margin-vertical">Total de verificaciones: {{ getTotalVerificaciones.toLocaleString("es-CL") }}</p>

                    <h2 class="margin-top-max text-align-center">Estadísticas Verificaciones de usuarios</h2>
                    <bar-chart :chart-data="computedChartLogsUsersVerified" :options="{ responsive: true, stacked: false }" :height="180"></bar-chart>
                </div>
            </f7-card>
        </f7-block>
    </f7-page>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

import Alert from "../components/Alert.vue";
import BarraVertical from "../components/BarraVertical.js";
import PieChart from "../components/PieChart.js";

export default {
    components: {
        "f7c-alert": Alert,
        "bar-chart": BarraVertical,
        "pie-chart": PieChart
    },

    data() {
        return {
            comunas: {},
            regiones: {},
            estadisticas: {},
            estadisticasCount: {},

            logsUsersVerified: {},
            logsResolucionIdentidad: {},
            logsResolucionIdentidadPorUsuario: {},

            filters: {
                comunas: [],
                regiones: [],
                rangeDate: "",
                resolucionIdentidadUsers: [],
            },

            loading: false,

            loadingComunas: true,
            loadingRegiones: true,
            loadingLogsPatientsActions: true,
            loadingLogsPatientsActionsCounts: true,
            loadingLogsUsersVerified: true,
            loadingLogsResolucionIdentidad: true,
            loadingLogsResolucionIdentidadPorUsuario: true,

            MIN_DATE: "2021-09-27"
        };
    },

    computed: {
        ...mapState([
            "cacheComunas",
            "cacheRegiones",
            "cacheEstadisticasPatientsActions",
            "cacheEstadisticasPatientsActionsCount",
            "cacheEstadisticasLogsUsersVerified",
            "cacheEstadisticasLogsResolucionIdentidad",
            "cacheEstadisticasLogsResolucionIdentidadPorUsuario",
        ]),

        comunasFilter() {
            const comunasNames = [];

            this.filters.comunas.forEach(comunaCode => {
                const comuna = this.comunas.data.find(c => c.code == comunaCode);

                if (comuna) {
                    comunasNames.push(comuna.name);
                }
            });

            return comunasNames.join(", ");
        },
        regionesFilter() {
            const regionesNames = [];

            this.filters.regiones.forEach(regionId => {
                const region = this.regiones.data.find(r => r.id == regionId);

                if (region) {
                    regionesNames.push(region.name);
                }
            });

            return regionesNames.join(", ");
        },

        url() {
            return `${this.$store.getters.getApiUrl}/api-estadisticas`;
        },

        env() {
            return this.$store.state.dev ? "dev" : "prod";
        },

        computedChartEstadisticasData() {
            if (!this.estadisticas.data || !this.estadisticas.data.length) {
                return {
                    labels: [],
                    datasets: [],
                };
            }

            return {
                labels: this.estadisticas.data.map(e => e.comuna_name),
                datasets: [
                    {
                        label: "Descargas de pases",
                        backgroundColor: "#2ab059",
                        data: this.estadisticas.data.map(e => Number(e.descarga_pase)),
                    },
                    {
                        label: "Descargas de pases con FEA",
                        backgroundColor: "#e89b27",
                        data: this.estadisticas.data.map(e => Number(e.descarga_pase_fea)),
                    },
                    {
                        label: "Solicitudes de FEA",
                        backgroundColor: "#a039bf",
                        data: this.estadisticas.data.map(e => Number(e.cola_firma)),
                    },
                ],
            };
        },

        computedChartLogsResolucionIdentidadPorUsuario() {
            if (!Object.keys(this.logsResolucionIdentidadPorUsuario.data || {}).length) {
                return {
                    labels: [],
                    datasets: [],
                };
            }

            return {
                labels: this.selectedResolucionIdentidadUsers.map((sriu) => sriu.answered_user_email),
                datasets: [
                    {
                        label: "Aprobadas",
                        backgroundColor: "#2ab059",
                        data: this.selectedResolucionIdentidadUsers.map(lri => lri.aprobadas_count),
                    },
                    {
                        label: "Rechazadas",
                        backgroundColor: "red",
                        data: this.selectedResolucionIdentidadUsers.map(lri => lri.rechazadas_count),
                    },
                ],
            };
        },

        selectedResolucionIdentidadUsers() {
            if (!Object.values(this.logsResolucionIdentidadPorUsuario.data || {}).length) return [];

            if (!this.filters.resolucionIdentidadUsers.length) {
                return this.logsResolucionIdentidadPorUsuario.data;
            }

            return this.logsResolucionIdentidadPorUsuario.data.filter((lri) => {
                return this.filters.resolucionIdentidadUsers.includes(lri.answered_user_email)
            });
        },

        resolucionIdentidadUsersList() {
            if (!Object.values(this.logsResolucionIdentidadPorUsuario.data || {}).length) {
                return [];
            }

            return this.logsResolucionIdentidadPorUsuario.data.map(lri => lri.answered_user_email)
        },

        computedChartEstadisticasCountData() {
            if (!Object.keys(this.estadisticasCount.data || {}).length) {
                return {
                    labels: [],
                    datasets: [],
                };
            }

            const descarga_pase_total = Number(this.estadisticasCount.data.descarga_pase_total).toLocaleString("es-CL");
            const descarga_pase_fea_total = Number(this.estadisticasCount.data.descarga_pase_fea_total).toLocaleString("es-CL");
            const cola_firma_total = Number(this.estadisticasCount.data.cola_firma_total).toLocaleString("es-CL");

            return {
                labels: [
                    `Total descargas de pases (${descarga_pase_total})`,
                    `Total descargas de pases con FEA (${descarga_pase_fea_total})`,
                    `Total solicitudes de FEA (${cola_firma_total})`,
                ],
                datasets: [
                    {
                        label: "Totales",
                        backgroundColor: ["#2ab059", "#e89b27", "#a039bf"],
                        data: [
                            this.estadisticasCount.data.descarga_pase_total,
                            this.estadisticasCount.data.descarga_pase_fea_total,
                            this.estadisticasCount.data.cola_firma_total,
                        ]
                    }
                ],
            };
        },

        computedChartLogsUsersVerified() {
            if (!Object.keys(this.logsUsersVerified.data || {}).length) {
                return {
                    labels: [],
                    datasets: [],
                };
            }

            const mecanismos = this.logsUsersVerified.data.map(l => l.mecanismo);

            const mecanismosCount = mecanismos.map(mec => {
                return (this.logsUsersVerified.data.find(l => l.mecanismo == mec) || {}).conteo || 0;
            });

            const data = {
                labels: mecanismos,
                datasets: [
                    {
                        label: "Conteo",
                        backgroundColor: "#2ab059",
                        data: mecanismosCount,
                    },
                ],
            };

            return data;
        },

        computedChartLogsResolucionIdentidad() {
            if (!Object.keys(this.logsResolucionIdentidad.data || {}).length) {
                return {
                    labels: [],
                    datasets: [],
                };
            }

            const statuses = this.logsResolucionIdentidad.data.map(l => l.status);

            const statusesCount = statuses.map(status => {
                return (this.logsResolucionIdentidad.data.find(l => l.status == status) || {}).count || 0;
            });

            const data = {
                labels: ["Aprobadas", "Rechazadas"],
                datasets: [
                    {
                        label: "Conteo",
                        backgroundColor: ["#2ab059", "red"],
                        data: statusesCount,
                    },
                ],
            };

            return data;
        },

        getTotalResoluciones() {
            if (!Object.values(this.logsResolucionIdentidad.data || {}).length) {
                return 0;
            }

            return this.logsResolucionIdentidad.data.reduce((acc, curr) => {
                return acc + curr.count
            }, 0);
        },

        getTotalVerificaciones() {
            if (!Object.values(this.logsUsersVerified.data || {}).length) {
                return 0;
            }

            return this.logsUsersVerified.data.reduce((acc, curr) => {
                return acc + curr.conteo
            }, 0);
        },

        formattedDates() {
            const formattedDates = {
                startDate: "",
                endDate: "",
            };

            if (!this.filters.rangeDate.length) {
                return formattedDates;
            }

            const dates = this.filters.rangeDate.split(" - ");

            formattedDates.startDate = dates[0] || "";
            formattedDates.endDate = dates[1] || "";

            return formattedDates;
        },

        isFilteringDate() {
            return this.filters.rangeDate.length > 0;
        },
    },

    methods: {
        async fetchData() {
            try {
                console.log("Cargando estadísticas...");

                const options = await this.getOptions();

                if (Object.keys(this.cacheComunas.data || {}).length === 0) {
                    axios.get(`${this.url}/comunas/${this.env}?perPage=1000`, options).then(response => {
                        this.$store.commit("setWhatTo", { what: "cacheComunas", to: response.data || {} });
                        this.loadingComunas = false;
                        this.comunas = this.cacheComunas;
                    });
                } else {
                    this.loadingComunas = false;
                    this.comunas = this.cacheComunas;
                }

                if (Object.keys(this.cacheRegiones.data || {}).length === 0) {
                    axios.get(`${this.url}/regiones/${this.env}?perPage=500`, options).then(response => {
                        this.$store.commit("setWhatTo", { what: "cacheRegiones", to: response.data || {} });
                        this.loadingRegiones = false;
                        this.regiones = this.cacheRegiones;
                    })
                } else {
                    this.loadingRegiones = false;
                    this.regiones = this.cacheRegiones;
                }

                if (Object.keys(this.cacheEstadisticasPatientsActions.data || {}).length === 0) {
                    axios.get(`${this.url}/patients-actions/computed/${this.env}`, options).then(response => {
                        this.$store.commit("setWhatTo", { what: "cacheEstadisticasPatientsActions", to: response.data || {} });
                        this.loadingLogsPatientsActions = false;
                        this.estadisticas = this.cacheEstadisticasPatientsActions
                    })
                } else {
                    this.loadingLogsPatientsActions = false;
                    this.estadisticas = this.cacheEstadisticasPatientsActions;
                }

                if (Object.keys(this.cacheEstadisticasPatientsActionsCount.data || {}).length === 0) {
                    axios.get(`${this.url}/patients-actions/computed/count/${this.env}`, options).then(response => {
                        this.$store.commit("setWhatTo", { what: "cacheEstadisticasPatientsActionsCount", to: response.data || {} });
                        this.loadingLogsPatientsActions = false;
                        this.estadisticasCount = this.cacheEstadisticasPatientsActionsCount;
                    });
                } else {
                    this.loadingLogsPatientsActionsCounts = false;
                    this.estadisticasCount = this.cacheEstadisticasPatientsActionsCount;
                }

                if (Object.keys(this.cacheEstadisticasLogsUsersVerified.data || {}).length === 0) {
                    axios.get(`${this.url}/logs/users-verified/query/${this.env}`, options).then(response => {
                        this.$store.commit("setWhatTo", { what: "cacheEstadisticasLogsUsersVerified", to: response.data || {} });
                        this.loadingLogsUsersVerified = false;
                        this.logsUsersVerified = this.cacheEstadisticasLogsUsersVerified;
                    })
                } else {
                    this.loadingLogsUsersVerified = false;
                    this.logsUsersVerified = this.cacheEstadisticasLogsUsersVerified;
                }

                if (Object.keys(this.cacheEstadisticasLogsResolucionIdentidad.data || {}).length === 0) {
                    axios.get(`${this.url}/logs/resolucion-identidad/query/${this.env}`, options).then(response => {
                        this.$store.commit("setWhatTo", { what: "cacheEstadisticasLogsResolucionIdentidad", to: response.data || {} });
                        this.loadingLogsResolucionIdentidad = false;
                        this.logsResolucionIdentidad = this.cacheEstadisticasLogsResolucionIdentidad;
                    })
                } else {
                    this.loadingLogsResolucionIdentidad = false;
                    this.logsResolucionIdentidad = this.cacheEstadisticasLogsResolucionIdentidad;
                }

                if (Object.keys(this.cacheEstadisticasLogsResolucionIdentidadPorUsuario.data || {}).length === 0) {
                    axios.get(`${this.url}/logs/resolucion-identidad/perUser/${this.env}`, options).then(response => {
                        this.$store.commit("setWhatTo", { what: "cacheEstadisticasLogsResolucionIdentidadPorUsuario", to: response.data || {} });
                        this.loadingLogsResolucionIdentidadPorUsuario = false;
                        this.logsResolucionIdentidadPorUsuario = this.cacheEstadisticasLogsResolucionIdentidadPorUsuario;
                    })
                } else {
                    this.loadingLogsResolucionIdentidad = false;
                    this.logsResolucionIdentidad = this.cacheEstadisticasLogsResolucionIdentidadPorUsuario;
                }
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al obtener estadísticas computadas.", error);
                this.loading = false;
            }
        },

        async applyFilters() {
            try {
                this.loading = true;
                this.loadingLogsPatientsActions = true;
                this.loadingLogsPatientsActionsCounts = true;
                this.loadingLogsUsersVerified = true;
                this.loadingLogsResolucionIdentidad = true;
                this.loadingLogsResolucionIdentidadPorUsuario = true;

                let queryString = "?perPage=1000";

                if (this.filters.comunas.length) {
                    queryString = `${queryString}&comunas=${this.filters.comunas.join(",")}`;
                }
                if (this.filters.regiones.length) {
                    queryString = `${queryString}&regiones=${this.filters.regiones.join(",")}`;
                }
                if (this.formattedDates.startDate.length) {
                    queryString = `${queryString}&startDate=${this.formattedDates.startDate}`;
                }
                if (this.formattedDates.endDate.length) {
                    queryString = `${queryString}&endDate=${this.formattedDates.endDate}`;
                }

                const options = await this.getOptions();

                const endpointEstadisticas = this.isFilteringDate
                    ? `${this.url}/patients-actions/query/${this.env}${queryString}`
                    : `${this.url}/patients-actions/computed/${this.env}${queryString}`;

                axios.get(endpointEstadisticas, options).then(response => {
                    this.estadisticas = response.data;
                    this.loadingLogsPatientsActions = false;
                });

                const endpointEstadisticasCount = this.isFilteringDate
                    ? `${this.url}/patients-actions/query/count/${this.env}${queryString}`
                    : `${this.url}/patients-actions/computed/count/${this.env}${queryString}`;

                axios.get(endpointEstadisticasCount, options).then(response => {
                    this.estadisticasCount = response.data;
                    this.loadingLogsPatientsActionsCounts = false;
                });

                const endpointLogsUsersVerified = `${this.url}/logs/users-verified/query/${this.env}${queryString}`;

                axios.get(endpointLogsUsersVerified, options).then(response => {
                    this.logsUsersVerified = response.data;
                    this.loadingLogsUsersVerified = false;
                });

                const endpointLogsResolucionIdentidad = `${this.url}/logs/resolucion-identidad/query/${this.env}${queryString}`;

                axios.get(endpointLogsResolucionIdentidad, options).then(response => {
                    this.logsResolucionIdentidad = response.data;
                    this.loadingLogsResolucionIdentidad = false;
                });

                const endpointLogsResolucionIdentidadPorUsuario = `${this.url}/logs/resolucion-identidad/perUser/${this.env}${queryString}`;

                axios.get(endpointLogsResolucionIdentidadPorUsuario, options).then(response => {
                    this.logsResolucionIdentidadPorUsuario = response.data;
                    this.loadingLogsResolucionIdentidadPorUsuario = false;
                });

                this.loading = false;
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Hubo un error al obtener estadísticas computadas.", error);
                this.loading = false;
            }
        },

        async getOptions() {
            const idToken = await this.$firebase.auth().currentUser.getIdToken();

            return {
                headers: { Authorization: `Bearer ${idToken}` },
            };
        },

        clearFilters() {
            this.$set(this.filters, "comunas", []);
            this.$set(this.filters, "regiones", []);
            this.$set(this.filters, "rangeDate", "");
            this.$set(this.filters, "resolucionIdentidadUsers", []);
            this.$$('#dateRange .input-clear-button').click();
        },
    },
};
</script>

<style scoped>
.margin-top-max {
    margin-top: 25px;
}
</style>