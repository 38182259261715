<template>
    <f7-page name="campana_reporte" @page:beforein="cargaStats(false)"  >
        <!-- Top Navbar -->
        <f7-navbar :subtitle="punteroStats.computing?'Actualizando...':`Actualizado el ${$dayjs.unix(punteroStats.last_comp).format('dddd DD/MM/YYYY [a las] HH:mm')}`" :title="campanaEnFoco.nombre" back-link="Back">
            <f7-nav-right>
                <f7-block>
                <f7-button v-if="!punteroStats.computing" @click="cargaStats(true)" fill color="teal" class="no-margin">Actualizar</f7-button>
                <f7-preloader v-else></f7-preloader>
                </f7-block>
                </f7-nav-right>
        </f7-navbar>
   <f7-progressbar v-if="cargandoStats" infinite></f7-progressbar>
    <f7-block class="text-align-center no-margin-bottom"><h2 class="no-margin text-color-primary">Eligibilidad</h2></f7-block>
    <f7-list class="no-margin-top nowhitespacetitle smalldividers" inset>
        <f7-list-item link="#"  @click="gotocharts('manfinters')" :title="`Pacientes que han ${(Object.values(reglaspreguntas).length?'completado la declaración':'manifestado interés')}`"><f7-preloader v-if="cargandoStats" slot="after"></f7-preloader><f7-chip v-else slot="after" :text="stats.manfinters || '0'"></f7-chip></f7-list-item>
        <f7-list-item divider title="Pacientes elegibles"></f7-list-item>
        <f7-list-item title="Aun no inician esquema"  ><f7-preloader v-if="cargandoStats" slot="after"></f7-preloader><f7-chip v-else slot="after" :text="stats.aunnoinicia || '0'"></f7-chip></f7-list-item>
        <f7-list-item class="padding-left margin-left" title="Con hora agendada"  ><f7-preloader v-if="cargandoStats" slot="after"></f7-preloader><f7-chip v-else slot="after" :text="stats.aunnoinicia_conhora || '0'"></f7-chip></f7-list-item>
        <f7-list-item class="padding-left margin-left" title="Sin hora agendada" ><f7-preloader v-if="cargandoStats" slot="after"></f7-preloader><f7-chip v-else slot="after" :text="stats.aunnoinicia_sinhora || '0'"></f7-chip></f7-list-item>
        <f7-list-item divider title="Pacientes no elegibles"></f7-list-item>
        <f7-list-item title="En espera" @click="gotocharts('enespera')" link="#" ><f7-preloader v-if="cargandoStats" slot="after"></f7-preloader><f7-chip v-else slot="after" :text="stats.enespera || '0'"></f7-chip></f7-list-item>

    </f7-list>
     <f7-block class="text-align-center no-margin-bottom"><h2 class="no-margin text-color-primary">Estado esquema{{Object.values(campanaEnFoco.esquemas).length>1?'s':''}}</h2></f7-block>
    <f7-block class="no-margin-top">
    <f7-row>
        <f7-col v-for="esquema in campanaEnFoco.esquemas" :key="esquema.id" class="marzeroauto" width="50">
            <f7-card>
                <f7-card-header><span><span class="bold">{{esquema.name}}</span> <f7-link @click="abreesquema($event,dia)" v-for="dia in Object.values(esquema.cal)" :key="dia.id" :tooltip="`Ver esquema ${dia.id}` ">- {{dia.id}}</f7-link></span> </f7-card-header>
                <f7-card-content :padding="false">
                     <f7-list class="no-margin-top nowhitespacetitle smalldividers">
                            <f7-list-item divider title="Pacientes esquema iniciado, pero incompleto"></f7-list-item>
                            <f7-list-item title="Esquema iniciado, en curso" footer="Puede ser superior a pacientes elegibles por pacientes de consulta espontánea" ><f7-chip slot="after" text="--"></f7-chip></f7-list-item>
                            <f7-list-item title="Esquema iniciado, fuera de rango" footer="Recibieron menos vacunas que las configuradas en el plazo establecido" ><f7-chip slot="after" text="--"></f7-chip></f7-list-item>
                            <f7-list-item divider title="Pacientes esquema completo"></f7-list-item>
                            <f7-list-item title="Esquema completado dentro de plazo" ><f7-chip slot="after" text="--"></f7-chip></f7-list-item>
                            <f7-list-item title="Esquema completado fuera de plazo" ><f7-chip slot="after" text="--"></f7-chip></f7-list-item>
                        </f7-list>
                </f7-card-content>
            </f7-card>
        </f7-col>
    </f7-row>
    </f7-block>
     <f7-block class="text-align-center no-margin-bottom"><h2 class="no-margin text-color-primary">Estado general</h2></f7-block>
    <f7-list class="no-margin-top nowhitespacetitle" inset>
        <f7-list-item title="Pacientes con esquema completo" ><f7-chip slot="after" text="--"></f7-chip></f7-list-item>
    </f7-list>


<f7-popover class="unesquemamenu">
    <f7-list v-if="diaOnFocus.id" class="no-margin"  >
        <template v-for="(unreq,indx) in Object.values(diaOnFocus.req)">
        <f7-list-item divider :title="`Requerimiento ${indx+1}`" :key="unreq.id"></f7-list-item>
         <f7-list-item v-for="vacuna in unreq.alts" :key="vacuna" :footer="Object.values(unreq.alts).length>1?'Alternativa':null" :title="`${$store.getters.getUno('vacunas',vacuna).vacunaPcName} (${$store.getters.getUno('vacunas',vacuna).laboratorioName})`"></f7-list-item>
        </template>
    </f7-list>
  </f7-popover>

    </f7-page>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { commonmixin } from '../mixins/common'
import formularios from './formularios.vue';

export default {
  components: { formularios },
        mixins: [commonmixin],
    props:['id'],
    data() {
        return {
             reglas:{},
             diaOnFocus:{},
             stats:{
                manfinters: 0,
                enespera: 0,
                elegibles: 0,
                aunnoinicia: '--',
                aunnoinicia_conhora: '--',
                aunnoinicia_sinhora: '--',
             },
             statsLoaded: false,
             punteroStats: {
                last_comp: null,
                computing: false,
                dlta: 0
             },
             cargandoStats:false
        };
    },
   created(){
        this.reglas = Object.assign({},this.loadedsusreglas);
    },
     computed:{
    // ...mapState(['comunas','crit_rules']),
    // ...mapGetters(['formularios','formularios_publicados','vacunas','regiones']),

     campanaEnFoco(){
        return this.$store.getters.getUno('campanas',this.id);
      },
      reglasusuarios(){
          return this.reglas.usuarios || {};
      },
      reglaspreguntas(){
          return this.reglas.criterios || {};
      },
      tieneMasdeUnaVacunaoMasdeunDia(){
          let tiene= false;
          let esquemas = this.campanaEnFoco.esquemas || {};
        return Object.values(esquemas).some(unesquema => {
            let dias = unesquema.cal || {};
            let arrayDias=Object.values(dias)
            if(arrayDias.length > 1){
                return true;
            }
            else{
                return arrayDias.some(undia => {
                    let requerimientos = undia.req || {};
                    return Object.values(requerimientos).length>1;
                });
            }
          });
      }
    },



    methods: {
        gotocharts(cual){
            this.$f7.views.main.router.navigate('/charter/',{props:{id: this.id,cual}})
        },
        abreesquema(evento,dia){
            //unesquemamenu
            this.diaOnFocus = Object.assign({},dia);
            this.$f7.popover.open('.unesquemamenu', evento.target);
        },
        async cargaStats(force){
         try {
             if(!this.statsLoaded || force){
                this.cargandoStats=true;
                this.punteroStats.computing = true;
                let datous = await this.commonExecute({campanaid: this.id, force},'admin-getstatscamana',false);
                if(datous.error){
                    throw new Error(datous.error.message);
                }
                console.log('datous.payload',datous.payload);
                this.stats = Object.assign(datous.payload.datum);
                this.punteroStats = Object.assign(datous.payload.punter);
                console.log(this.stats);
                this.cargandoStats=false;
                this.statsLoaded = true;
            }
            }
            catch (error) {
            this.cargandoStats=false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
    },
};
</script>
<style>

</style>
