<template>
   <f7-page>
        <f7-navbar title="Configurar restriccion de concurrencia" >
          <f7-nav-right>
            <f7-link popup-close>Cancelar</f7-link>
          </f7-nav-right>
        </f7-navbar>
         <f7-block-title class="no-margin-bottom">
          1.- Seleccione la campaña en base a la cual se creará la restricción
        </f7-block-title>
         <f7-block class="no-margin-top no-margin-bottom">
          <f7-list class="margin-top margin-bottom">
                <f7-list-input type="select" @change="selectedCampanaId = $event.target.value">
                      <option value="">Seleccione la campana a referenciar</option>
                      <option v-for="campana in otrascampanas" :key="campana.id" :value="campana.id">{{campana.nombre}}</option>
                  </f7-list-input>
          </f7-list>
         </f7-block>
          <template v-if="selectedCampanaId">
          <f7-block class="no-margin-bottom no-margin-top">
          2.- Indique a partir de cuantos dias despues de completada la campaña "{{campanas[selectedCampanaId].nombre}}"" se puede iniciar la campaña "{{campanaEnFoco.nombre}}""
        </f7-block>
            <f7-block class="no-margin-top no-margin-bottom">
            <f7-list class="margin-top-half margin-bottom-half ">
                <f7-list-input
                    type="number"
                    min="1"
                    label="Dias desde última dosis"
                    :value="diasDespues"
                    @input="handleDiasPost"
                ></f7-list-input>
            </f7-list>
            <p v-if="diasDespues">En palabras: <b> La campaña "{{campanaEnFoco.nombre}}" podrá agendarse sólo a partir del {{diasDespues}} dia desde la última dosis de la campaña "{{campanas[selectedCampanaId].nombre}}"</b></p>
            </f7-block>
        <f7-block><f7-button @click="guardrestriccion()" large raised fill color="teal">Guardar restricción</f7-button></f7-block>
      </template>
      </f7-page>
</template>
<script>
import { mapState } from 'vuex'

export default {
    props:['campanaEnFoco'],
    data() {
      return {
        selectedCampanaId: '',
        diasDespues: 1
      }
    },
    computed:{
    ...mapState(['campanas']),
    otrascampanas(){
          let yaslecciondas = this.campanaEnFoco.gtw && this.campanaEnFoco.gtw.bfr?this.campanaEnFoco.gtw.bfr : {};
        return Object.values(this.campanas).filter(unacman=>{
            return unacman.id!==this.campanaEnFoco.id && !yaslecciondas[unacman.id]
        });
      }
    },
    mounted(){
        console.log('origen info',this.otrascampanas);
    },
    methods:{
      handleDiasPost(event) {
            let numbertosave = Number(event.target.value);
            if(numbertosave<1) numbertosave = 1;
            this.diasDespues = numbertosave;
        },
        guardrestriccion(){
            let aemitir={
                campanaid: this.selectedCampanaId,
                dias: this.diasDespues
            };
            this.$emit('restringe',aemitir);

        }
    }
}
</script>
<style>


</style>