<template>
    <f7-page @page:beforein="traeAgendamientosHoy()" name="center_registro">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title :subtitle="centerInFocoInfo.name" title="Registro de Vacunación"></f7-nav-title>
            <f7-nav-right>
                <f7-block>
                    <f7-link @click="traeAgendamientosHoy()" tooltip="Recarga lista de agendamientos de hoy"
                        ><font-awesome-icon icon="sync-alt"></font-awesome-icon></f7-link
                ></f7-block>
            </f7-nav-right>
            <f7-subnavbar :inner="false">
                <f7-searchbar
                    search-container=".paxagendadoshoyaqui"
                    search-in=".item-title"
                    :disable-button="false"
                    @input="busqueda = $event.target.value"
                    @keyup.native.enter="buscam('RUN')"
                    :backdrop="false"
                    placeholder="Ingrese identificador del paciente"
                ></f7-searchbar>
            </f7-subnavbar>
        </f7-navbar>
        <f7-progressbar v-if="cargandoAgendahoy" infinite></f7-progressbar>
        <f7-block v-if="cargandoAgendahoy && !agendahoy.length" class="text-align-center">
            <f7-preloader></f7-preloader><br />Cargando pacientes agendados hoy en {{ centerInFocoInfo.name }}.
        </f7-block>
        <f7-list media-list class="search-list titlebold no-margin-top paxagendadoshoyaqui searchbar-found">
            <f7-list-item v-if="agendahoy.length" divider>Pacientes agendados <b>hoy</b> {{ hoystring }} en {{ centerInFocoInfo.name }}</f7-list-item>
            <f7-list-item
                v-for="agendm in agendahoy"
                :key="agendm.id"
                :footer="agendm.campinfo.fraseqeuma || null"
                :subtitle="agendm.campinfo.vacunas || null"
                :header="agendm.userinfo ? `${agendm.userinfo.idenType} ${agendm.userinfo.rawIdentity}` : `${agendm.for}`"
                :title="
                    agendm.userinfo
                        ? `${agendm.userinfo.nombres} ${agendm.userinfo.apellido1} ${agendm.userinfo.apellido2 || ''} (${suageStrg(
                              agendm.userinfo.fechanac
                          )})`
                        : agendm.for
                "
            >
                <div class="text-align-center bold" slot="media">
                    <font-awesome-icon
                        class="fa-2x"
                        :class="
                            agendm.userinfo
                                ? agendm.userinfo.sexo == 'Intersex'
                                    ? 'text-color-gray'
                                    : agendm.userinfo.sexo == 'F'
                                    ? 'text-color-pink'
                                    : 'text-color-blue'
                                : 'text-color-gray'
                        "
                        icon="user-circle"
                    ></font-awesome-icon>
                    <div class="text-color-primary">{{ agendm.hin }}</div>
                </div>
                <f7-button @click="checkExiste(agendm)" outline slot="after">Ver Ficha</f7-button>
            </f7-list-item>
        </f7-list>

        <f7-block v-if="!cargandoAgendahoy && !agendahoy.length" class="text-align-center">
            No hay pacientes con el identificador <b>{{ busqueda }}</b> con agendamiento hoy.<br />Buscar en base de datos:<br />
            <p class="margin-top no-margin-bottom">
                <span class="rutinvalidbusq" v-if="busqueda && rutinvalido"
                    ><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> Atención: Rut parece inválido</span
                >
                <span class="text-color-teal fsizequat" v-else-if="busqueda.length > 5"
                    ><font-awesome-icon icon="user-check"></font-awesome-icon> RUT Válido</span
                >
                <span v-else>-</span>
            </p>
            <f7-button class="margin-bottom" @click="preSearchPatients('RUN')" large fill raised>Buscar RUT {{ foramtedBusqueda }}</f7-button>
            <f7-row class="margin-top">
                <f7-col
                    ><f7-button @click="preSearchPatients('DNI')" outline class="margin-top">Buscar DNI {{ busqueda }}</f7-button></f7-col
                >
                <f7-col
                    ><f7-button @click="preSearchPatients('PAS')" outline class="margin-top">Buscar Pasaporte {{ busqueda }}</f7-button></f7-col
                >
            </f7-row>
        </f7-block>

        <f7-popup class="creaPacientePop" :opened="creaPaxPopOpened" @popup:closed="creaPaxPopOpened = false">
            <f7-view name="inpop">
                <f7-page @page:beforein="mirapais()">
                    <f7-navbar title="Creando nuevo paciente" subtitle="Porfavor confirme datos para crear">
                        <f7-nav-right>
                            <f7-link popup-close>Cancelar</f7-link>
                        </f7-nav-right>
                    </f7-navbar>
                    <f7-list class="no-margin no-padding">
                        <f7-list-item
                            class="titlebold"
                            header="Documento de Identidad"
                            :title="
                                `${clonedidentity.idenType} ${
                                    clonedidentity.idenType == 'RUN' ? formatRut(clonedidentity.identity) : clonedidentity.identity
                                }`
                            "
                        ></f7-list-item>
                        <f7-list-input
                            label="Nombre"
                            type="text"
                            outline
                            @input="clonedidentity.nombres = $event.target.value"
                            :value="clonedidentity.nombres"
                        ></f7-list-input>
                        <f7-list-input
                            label="Primer Apellido"
                            type="text"
                            outline
                            @input="clonedidentity.apellido1 = $event.target.value"
                            :value="clonedidentity.apellido1"
                        ></f7-list-input>
                        <f7-list-input
                            label="Segundo Apellido"
                            type="text"
                            outline
                            @input="clonedidentity.apellido2 = $event.target.value"
                            :value="clonedidentity.apellido2"
                        ></f7-list-input>
                        <f7-list-item class="inputfechatitlefull" header="Fecha de Nacimiento">
                            <f7-row slot="footer">
                                <f7-col width="20">
                                    <f7-input
                                        type="select"
                                        outline
                                        class="inputdia"
                                        @change="fechanacsplit.dia = $event.target.value"
                                        :value="fechanacsplit.dia"
                                    >
                                        <option v-for="dia in 31" :key="dia" :value="dia">{{ dia }}</option>
                                    </f7-input>
                                </f7-col>
                                <f7-col width="50">
                                    <f7-input
                                        type="select"
                                        outline
                                        class="inputmes"
                                        @change="fechanacsplit.mes = $event.target.value"
                                        :value="fechanacsplit.mes"
                                    >
                                        <option v-for="(mes, idx) in $dayjs.months()" :key="idx + 1" :value="idx + 1">{{ mes }}</option>
                                    </f7-input>
                                </f7-col>
                                <f7-col width="30">
                                    <f7-input
                                        class="inputano"
                                        type="text"
                                        outline
                                        @change="fechanacsplit.ano = $event.target.value"
                                        :value="fechanacsplit.ano"
                                    >
                                    </f7-input>
                                </f7-col>
                            </f7-row>
                        </f7-list-item>
                        <f7-list-input
                            label="Sexo biológico"
                            type="select"
                            outline
                            @input="clonedidentity.sexo = $event.target.value"
                            :value="clonedidentity.sexo"
                        >
                            <option value="F">Femenino</option>
                            <option value="M">Masculino</option>
                            <option value="Intersex">Intersex</option>
                        </f7-list-input>
                        <f7-list-item
                            header="Pais de Nacionalidad"
                            :title="
                                clonedidentity.nac_iso2
                                    ? clonedidentity.nac_iso2 == 'CL'
                                        ? 'Chile'
                                        : $store.state.paises[clonedidentity.nac_iso2]
                                        ? $store.state.paises[clonedidentity.nac_iso2].name
                                        : clonedidentity.nac_iso2
                                    : 'Seleccione país'
                            "
                            @click="fetchPaisesAndGo()"
                            link="#"
                            ><font-awesome-icon icon="pen" slot="after"
                        /></f7-list-item>
                    </f7-list>
                    <f7-block><f7-button @click="crearPaciente()" large raised fill color="teal">Crear paciente</f7-button></f7-block>
                </f7-page>
            </f7-view>
        </f7-popup>

        <f7-popup class="descargax-popup" :opened="descargOpened" @popup:closed="descargOpened = false">
            <f7-page>
                <f7-navbar title="Generar Excel" subtitle="Dosis administradas por fecha">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-list no-hairlines-md>
                    <f7-list-input
                        type="datepicker"
                        label="Seleccione fecha a consultar"
                        outline
                        @change="setDescargaDate($event.target.value)"
                        readonly
                        :calendar-params="{ dateFormat: 'dd/mm/yyyy', value: [new Date()], locale: 'es', closeOnSelect: true }"
                    >
                    </f7-list-input>
                </f7-list>
                <f7-block>
                    <f7-button @click="getDosisAdminsFecha()" fill large raised color="teal">Descargar</f7-button>
                </f7-block>
            </f7-page>
        </f7-popup>

        <f7-fab position="right-bottom" slot="fixed" color="primary">
            <f7-icon material="settings"></f7-icon>
            <f7-icon material="close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="descargOpened = true" label="Generar descarga"
                    ><font-awesome-icon icon="download"></font-awesome-icon
                ></f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>

        <select-patient-popup
            :opened="selectPatientPopupOpened"
            :patients="patientsToSelect"
            @popup-closed="selectPatientPopupOpened = false"
            @patient-selected="handlePatientSelected"
        ></select-patient-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import SelectPatientPopup from "../components/select-patient-popup.vue";

import axios from "axios";

export default {
    mixins: [commonmixin],

    components: {
        "select-patient-popup": SelectPatientPopup,
    },

    data() {
        return {
            cargandoAgendahoy: true,
            descargafechfix: "",
            descargOpened: false,
            busqueda: "",
            agendahoy: [],
            creaPaxPopOpened: false,
            clonedidentity: {
                apellido1: "",
                apellido2: "",
                fechanac: "",
                idenType: "",
                identity: "",
                rawIdentity: "",
                nac_iso2: "",
                nombres: "",
                sexo: "",
            },
            agendamientoFoco: null,
            fechanacsplit: {
                ano: "",
                mes: "",
                dia: "",
            },

            patientsToSelect: [],
            selectPatientPopupOpened: false,
        };
    },
    computed: {
        ...mapState(["centerInFoco", "decl_id_pais"]),
        ...mapGetters(["centerInFocoInfo"]),
        hoystring() {
            return this.$dayjs().format("dddd D [de] MMMM [de] YYYY");
        },
        rutinvalido() {
            let isinvalid = false;
            if (this.busqueda.length > 5) {
                return !this.validaRut(this.busqueda);
            }
            return isinvalid;
        },
        foramtedBusqueda() {
            return this.formatRut(this.busqueda);
        },
    },
    watch: {
        centerInFoco(nuevocentro) {
            console.log("ATENTO, CAMBIO DE CENTRO", nuevocentro);
            this.traeAgendamientosHoy();
        },
    },
    methods: {
        setDescargaDate(value) {
            if (value) {
                console.log("seteando fecha a", value);
                this.descargafechfix = this.$dayjs(value, "DD/MM/YYYY").format("YYYYMMDD");
            }
        },
        async getDosisAdminsFecha() {
            try {
                if (!this.descargafechfix) {
                    return this.$f7.dialog.alert("Debe indicar una fecha de consulta", "Indique fecha");
                }
                this.$f7.dialog.preloader("Generando archivo...");
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let envx = this.$store.state.dev ? "dev" : "prod";
                // console.log('lllamando a ',`${endpoint}/prosex-exporter_isp/corxpt/vacsperdia/${this.centerInFoco}/${this.descargafechfix}/${envx}`)
                let response = await axios.get(
                    `${this.$store.getters.getApiUrl}/prosex-exporter_isp/corxpt/vacsperdia/${this.centerInFoco}/${this.descargafechfix}/${envx}`,
                    {
                        headers: { Authorization: "Bearer " + idToken },
                        responseType: "blob", // important
                    }
                );
                this.$f7.dialog.close();
                console.log(response.data);
                if (response.data.type == "application/json") {
                    var textPromise = await response.data.text();
                    console.log("error", textPromise);
                    return this.$f7.dialog.alert(textPromise, "Error");
                }
                const fileURL = window.URL.createObjectURL(
                    new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                );
                let fileLink = document.createElement("a");
                document.body.appendChild(fileLink);
                fileLink.href = fileURL;
                fileLink.setAttribute("download", this.centerInFocoInfo.name + "_" + this.descargafechfix + ".xlsx");
                fileLink.classList.add("external");
                fileLink.click();
                window.URL.revokeObjectURL(fileURL);
                this.avisaOk("Descarga OK");
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error, error.message, error.response, error?.response?.data);
                this.$f7.dialog.alert(error.response?.data?.message || error.message);
            }
        },
        mirapais() {
            console.log("revolviendo a vista. Seter pais con el valor que tenga en el store?");
            this.clonedidentity.nac_iso2 = this.decl_id_pais;
        },
        fetchPaisesAndGo() {
            if (!this.$store.getters.paisesArraySorted.length) {
                this.$f7.dialog.preloader("Cargando paises...");
                this.$firebase
                    .database()
                    .ref("paises")
                    .once("value")
                    .then(snps => {
                        snps.forEach(potpais => {
                            let paisinfo = potpais.val();
                            if (!paisinfo.name) {
                                console.log(potpais.key);
                                console.log("NO TIENE NOMBRE");
                            }
                        });
                        this.$store.commit("setWhatTo", { what: "paises", to: snps.val() });
                        this.$f7.dialog.close();
                        this.$f7.views.inpop.router.navigate("/paises/");
                    })
                    .catch(error => {
                        this.$f7.dialog.close();
                        console.log(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            } else {
                this.$f7.views.inpop.router.navigate("/paises/");
            }
        },
        async traeAgendamientosHoy() {
            try {
                this.cargandoAgendahoy = true;
                let datous = await this.commonExecute({ vacunatorioid: this.centerInFoco }, "admin-getAllAgendamientosHoyAqui", false);
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                this.agendahoy = datous.payload;
                this.cargandoAgendahoy = false;
            } catch (error) {
                this.cargandoAgendahoy = false;
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        resetPotPax() {
            this.clonedidentity.apellido1 = "";
            this.clonedidentity.apellido2 = "";
            this.clonedidentity.fechanac = "";
            this.clonedidentity.idenType = "RUN";
            this.clonedidentity.identity = this.busqueda;
            this.clonedidentity.nac_iso2 = "";
            this.$store.commit("setWhatTo", { what: "decl_id_pais", to: null });
            this.clonedidentity.nombres = "";
            this.clonedidentity.sexo = "";
        },
        computeEdadSelectors() {
            if (this.clonedidentity.fechanac) {
                let prsea = this.$dayjs(this.clonedidentity.fechanac);
                this.fechanacsplit.ano = prsea.format("YYYY");
                this.fechanacsplit.mes = prsea.format("M");
                this.fechanacsplit.dia = prsea.format("D");
            } else {
                this.fechanacsplit = Object.assign({}, { ano: "", mes: "", dia: "" });
            }
        },
        async crearPaciente() {
            try {
                this.$f7.dialog.preloader("Creando paciente...");
                this.clonedidentity.rawIdentity = this.clonedidentity.identity;

                //check valid fecha nacimiento
                let mesx = parseInt(this.fechanacsplit.mes) < 10 ? `0${this.fechanacsplit.mes}` : this.fechanacsplit.mes;
                let diax = parseInt(this.fechanacsplit.dia) < 10 ? `0${this.fechanacsplit.dia}` : this.fechanacsplit.dia;
                this.clonedidentity.fechanac = `${this.fechanacsplit.ano}${mesx}${diax}`;
                if (!this.$dayjs(this.clonedidentity.fechanac, "YYYYMMDD").isValid()) {
                    return this.$f7.dialog.alert("Fecha de nacimiento inválida");
                }
                let datous = await this.commonExecute(
                    { pacienteinfo: this.clonedidentity, agendamientoid: this.agendamientoFoco, vacunatorioid: this.centerInFoco },
                    "admin-creaPacienteEnVacunacion",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                let paxinfo = datous.payload;
                console.log(paxinfo);
                this.creaPaxPopOpened = false;
                await this.getMeThese(["vacunas", "campanas"], true);
                this.$f7.views.main.router.navigate("/ficha/", {
                    props: {
                        paxinfo,
                    },
                });
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async checkExiste(agendm) {
            try {
                this.agendamientoFoco = agendm.id;
                this.$f7.dialog.preloader("Cargando paciente...");
                let origin = agendm.for == agendm.by ? "privx" : "agndmts_teceros";
                let datous = await this.commonExecute(
                    {
                        pacienteid: `${agendm.userinfo.idenType}_${agendm.userinfo.identity}`,
                        usersource: origin,
                        userid: agendm.for,
                        agendamientoid: agendm.id,
                        vacunatorioid: this.centerInFoco,
                    },
                    "admin-getInfoPaxUnAgendamiento",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                let paxinfo = datous.payload;
                console.log(paxinfo);
                if (!paxinfo) {
                    this.resetPotPax();
                    this.clonedidentity.apellido1 = agendm.userinfo.apellido1;
                    this.clonedidentity.apellido2 = agendm.userinfo.apellido2;
                    this.clonedidentity.fechanac = agendm.userinfo.fechanac;
                    this.clonedidentity.idenType = agendm.userinfo.idenType;
                    this.clonedidentity.identity = agendm.userinfo.identity;
                    this.clonedidentity.nac_iso2 = agendm.userinfo.nac_iso2;
                    this.$store.commit("setWhatTo", { what: "decl_id_pais", to: agendm.userinfo.nac_iso2 });
                    this.clonedidentity.nombres = agendm.userinfo.nombres;
                    this.clonedidentity.sexo = agendm.userinfo.sexo;
                    this.computeEdadSelectors();
                    this.creaPaxPopOpened = true;
                    this.$f7.dialog.close();
                } else {
                    await this.getMeThese(["vacunas", "campanas"], true);
                    this.$f7.views.main.router.navigate("/ficha/", {
                        props: {
                            paxinfo,
                        },
                    });
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async preSearchPatients(tipoDoc) {
            const trimBusqueda = this.busqueda.trim();

            if (trimBusqueda.length < 2) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe ingresar al menos 2 caracteres");
                return;
            }

            if (tipoDoc === "RUN") {
                await this.buscam("RUN");
            } else {
                this.$f7.dialog.preloader("Buscando...");

                const response = await this.commonExecute({ tipoDoc, nroDoc: trimBusqueda, dev: this.dev }, "admin-preSearchPatient", false);

                if (response.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(response.error.message);
                    return;
                }

                const { patients } = response;

                if (patients.length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron pacientes");
                    return;
                }

                if (patients.length === 1 && patients[0].identity === trimBusqueda) {
                    this.$f7.dialog.close();

                    const patient = patients[0];

                    this.tipodoc = patient.idenType;
                    this.busqueda = patient.identity;

                    await this.buscam(tipoDoc);
                } else {
                    this.patientsToSelect = patients;
                    this.selectPatientPopupOpened = true;
                }

                this.$f7.dialog.close();
            }
        },
        async handlePatientSelected(patient) {
            this.busqueda = patient.identity;

            await this.buscam(patient.idenType);

            this.patientsToSelect = [];
            this.selectPatientPopupOpened = false;
        },
        async buscam(cualsource) {
            try {
                if (!this.busqueda) {
                    return this.$f7.dialog.alert("Ingrese un identificador a buscar");
                }
                this.agendamientoFoco = null;
                this.$f7.dialog.preloader("Buscando paciente...");
                let datous = await this.commonExecute(
                    { idenType: cualsource, identity: this.busqueda, vacunatorioid: this.centerInFoco },
                    "admin-getonepaxinfo",
                    false
                );
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log(datous.payload);
                let payload = datous.payload;
                if (!payload.existe) {
                    //reseteemos
                    this.resetPotPax();
                    this.clonedidentity.idenType = cualsource;
                    if (payload.sugestRC) {
                        let sexomap = {
                            "02": "F",
                            "01": "M",
                            "03": "I",
                        };
                        this.clonedidentity.apellido1 = payload.sugestRC.primerApellidoPersona || "";
                        this.clonedidentity.apellido2 = payload.sugestRC.segundoApellidoPersona || "";
                        this.clonedidentity.nombres = payload.sugestRC.nombresPersona || "";
                        this.clonedidentity.sexo = sexomap[payload.sugestRC.codSexo] || "I";
                        if (payload.sugestRC.fechaNacimiento) {
                            this.clonedidentity.fechanac = this.$dayjs(payload.sugestRC.fechaNacimiento, "DD/MM/YYYY").format("YYYYMMDD");
                        }
                        if (payload.sugestRC.codPaisOrigen == "152") {
                            this.$store.commit("setWhatTo", { what: "decl_id_pais", to: "CL" });
                            this.clonedidentity.nac_iso2 = "CL";
                        }
                    }
                    //resetAgeSelectors
                    this.computeEdadSelectors();
                    this.creaPaxPopOpened = true;
                    this.$f7.dialog.close();
                } else {
                    await this.getMeThese(["vacunas", "campanas"], true);
                    this.$f7.views.main.router.navigate("/ficha/", {
                        props: {
                            paxinfo: payload.existe,
                        },
                    });
                }
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
    },
};
</script>
<style>
.rutinvalidbusq {
    color: #ff1818;
    font-size: 14px;
    font-weight: bold;
}
.fsizequat {
    font-size: 14px;
    font-weight: bold;
}
</style>
