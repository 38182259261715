<template>
    <f7-page @page:afterin="getRequestsStatuses">
        <f7-navbar title="Estados de solicitudes" subtitle="Firma Electrónica Avanzada" back-link>
            <f7-nav-right>
                <f7-link @click="getRequestsStatuses" tooltip="Refrescar">
                    <f7-icon f7="arrow_clockwise"></f7-icon>
                </f7-link>
            </f7-nav-right>
        </f7-navbar>
        
        <f7-col v-if="isLoading" class="text-align-center margin">
            <f7-preloader></f7-preloader>
        </f7-col>
        <f7-list class="no-margin" v-else>
            <f7-list-item v-for="(request, index) in requestsStatuses" :key="index" media-item>
                <div slot="header">
                    <f7-badge v-if="request.status === 'pending'" color="orange">Procesando</f7-badge>
                    <f7-link v-else-if="request.status === 'error'" :tooltip="`Error: ${request.errorMessage}`">
                        <f7-badge color="red">Error</f7-badge>
                    </f7-link>
                    <f7-badge v-else-if="request.status === 'done'" color="green">Firmado</f7-badge>
                </div>
                <span slot="title">Paciente: <b>{{ request.pid }}</b></span>
                <f7-link v-if="request.status === 'done'" @click="downloadFEA(request)" slot="after" tooltip="Descargar certificado">
                    <font-awesome-icon icon="download" class="fa-lg"></font-awesome-icon>
                </f7-link>
                <span slot="footer">Solicitado el {{ $dayjs.unix(request.requestedAt / 1000).format("DD/MM/YYYY [a las] HH:mm") }}</span>
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>

<script>
import { commonmixin } from "../mixins/common";

export default {
    mixins: [commonmixin],

    data() {
        return {
            requestsStatuses: [],
            isLoading: false,

            COVID_CAMPAIGN_ID: '-MSxHZSB7GpMsVDfVtZb',
        };
    },

    methods: {
        async getRequestsStatuses() {
            try {
                this.isLoading = true;

                const response = await this.commonExecute({}, 'admin-getFEARequestsStatuses', false);
                if (response.error) {
                    throw new Error(response.error);
                }

                this.requestsStatuses = response.requestsStatuses;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, 'Hubo un error al obtener los estados de las solicitudes');
                console.error(error.message);
            }
        },

        async downloadFEA(request) {
            try {
                this.$f7.dialog.preloader("Descargando certificado...");

                const response = await this.commonExecute({ pid: request.pid }, 'admin-downloadFEAManual', false);
                console.log("downloadFEAManual response", response);
                if (response.error) {
                    throw new Error(response.error);
                }

                window.open(response.url, '_blank');

                this.$f7.dialog.close();
            } catch (error) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, 'Hubo un error al descargar el certificado');
                console.error(error.message);
            }
        },
    },
};
</script>