<template>
    <f7-page @page:beforeout="handlePageBeforeout">
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title title="Certificador SEREMI" subtitle="Firma certificado solicitud de vacunas"></f7-nav-title>
            <f7-nav-right>
                <f7-link @click="goToHistoricoAceptadas" tooltip="Ver historico firmadas"
                    ><font-awesome-icon icon="check"></font-awesome-icon
                ></f7-link>

                <f7-link tooltip="Buscar solicitud" @click="openSearchSolicitudes"><font-awesome-icon icon="search"></font-awesome-icon></f7-link>

                <f7-link :class="{ disabled: isLoading }" @click="fetchSolicitudesByCola(true)" tooltip="Actualizar listado"
                    ><font-awesome-icon :class="{ 'fa-spin': isLoading }" icon="sync-alt"></font-awesome-icon
                ></f7-link>
            </f7-nav-right>
        </f7-navbar>

        <f7-toolbar tabbar top>
            <f7-link tab-link="#tab-por-firmar" :tab-link-active="selectedCola === 'Por firmar'" @click="selectedCola = 'Por firmar'"
                >Por Firmar <template v-if="solicitudesEnEsperaFirma.length > 0"> ({{ solicitudesEnEsperaFirma.length }})</template></f7-link
            >
            <f7-link tab-link="#tab-firmando" :tab-link-active="selectedCola === 'Firmando'" @click="selectedCola = 'Firmando'"
                >Firmando <template v-if="solicitudesFirmando.length > 0"> ({{ solicitudesFirmando.length }})</template></f7-link
            >
            <f7-link tab-link="#tab-por-apostillar" :tab-link-active="selectedCola === 'Por apostillar'" @click="selectedCola = 'Por apostillar'"
                >Por Apostillar <template v-if="solicitudesEnEsperaApostilla.length > 0"> ({{ solicitudesEnEsperaApostilla.length }})</template></f7-link
            >
            <f7-link tab-link="#tab-apostillando" :tab-link-active="selectedCola === 'Apostillando'" @click="selectedCola = 'Apostillando'"
                >Apostillando <template v-if="solicitudesApostillando.length > 0"> ({{ solicitudesApostillando.length }})</template></f7-link
            >
        </f7-toolbar>

        <f7-tabs>
            <f7-tab id="tab-por-firmar" tab-active>
                <div class="display-flex margin">
                    <div class="margin-right display-flex align-items-center">
                        <f7-radio
                            name="filter-en-espera-firma"
                            :checked="enEsperaFirmaFilter === 'all'"
                            @change="$store.commit('homologacionVacunasNac/set_filter_lista_en_espera_firma', 'all')"
                            class="margin-right-half"
                        ></f7-radio>
                        Todas las solicitudes
                    </div>

                    <div class="margin-right display-flex align-items-center">
                        <f7-radio
                            name="filter-en-espera-firma"
                            :checked="enEsperaFirmaFilter === 'con_apost'"
                            @change="$store.commit('homologacionVacunasNac/set_filter_lista_en_espera_firma', 'con_apost')"
                            class="margin-right-half"
                        ></f7-radio>
                        Con apostillamiento
                    </div>

                    <div class="margin-right display-flex align-items-center">
                        <f7-radio
                            name="filter-en-espera-firma"
                            :checked="enEsperaFirmaFilter === 'sin_apost'"
                            @change="$store.commit('homologacionVacunasNac/set_filter_lista_en_espera_firma', 'sin_apost')"
                            class="margin-right-half"
                        ></f7-radio>
                        Sin apostillamiento
                    </div>
                </div>
                <solicitudes-list list-type="Por firmar" :solicitudes="solicitudesEnEsperaFirma"></solicitudes-list>
            </f7-tab>
            <f7-tab id="tab-firmando">
                <solicitudes-list list-type="Firmando" :solicitudes="solicitudesFirmando"></solicitudes-list>
            </f7-tab>
            <f7-tab id="tab-por-apostillar">
                <solicitudes-list list-type="Por apostillar" :solicitudes="solicitudesEnEsperaApostilla"></solicitudes-list>
            </f7-tab>
            <f7-tab id="tab-apostillando">
                <solicitudes-list list-type="Apostillando" :solicitudes="solicitudesApostillando"></solicitudes-list>
            </f7-tab>
        </f7-tabs>

        <div class="display-flex justify-content-center">
            <f7-button large fill raised @click="loadMoreSolicitudesByCola" class="padding-horizontal">Cargar más</f7-button>
        </div>

        <f7-popup class="solicitud-search" :opened="searchSolicitudesPopupOpened" @popup:closed="handleSearchPopupClosed">
            <f7-page>
                <f7-navbar title="Buscar solicitud">
                    <f7-nav-right>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>

                <div class="margin">
                    <p>Tipo de búsqueda</p>
                    <f7-segmented strong tag="p">
                        <f7-button @click="selectedSearchTypeChanged('user_email')" :active="selectedSearchType === 'user_email'">Email</f7-button>
                        <f7-button @click="selectedSearchTypeChanged('patient_id')" :active="selectedSearchType === 'patient_id'"
                            >Documento</f7-button
                        >
                        <f7-button @click="selectedSearchTypeChanged('nombres_apellidos')" :active="selectedSearchType === 'nombres_apellidos'"
                            >Nombres</f7-button
                        >
                        <f7-button @click="selectedSearchTypeChanged('solicitud_id')" :active="selectedSearchType === 'solicitud_id'">Folio</f7-button>
                    </f7-segmented>
                </div>

                <f7-list class="no-margin" no-hairlines v-if="selectedSearchType === 'user_email'">
                    <f7-list-input
                        type="text"
                        label="Email usuario MeVacuno"
                        @blur="e => searchInputChanged(e, 'user_email')"
                        :value="search.user_email"
                        placeholder="usuario@test.cl"
                    ></f7-list-input>
                </f7-list>

                <f7-list class="no-margin-vertical" v-if="selectedSearchType === 'patient_id'">
                    <f7-list-input  label="Tipo documento" type="select" :value="search.tipoDoc" @input="e => searchInputChanged(e, 'tipoDoc')">
                        <option value="RUN">RUN</option>
                        <option value="PAS">PASAPORTE</option>
                        <option value="DNI">DNI</option>
                    </f7-list-input>
                    <f7-list-input
                        type="text"
                        label="Número documento"
                        @blur="e => searchInputChanged(e, 'numDoc')"
                        :value="search.numDoc"
                        placeholder="Ej: 112223334"
                    ></f7-list-input>
                </f7-list>

                <f7-row no-gap v-if="selectedSearchType === 'nombres_apellidos'">
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Nombres" @blur="e => searchInputChanged(e, 'nombres')" :value="search.nombres"></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Apellido 1" @blur="e => searchInputChanged(e, 'apellido1')" :value="search.apellido1"></f7-list-input>
                        </f7-list>
                    </f7-col>
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="Apellido 2" @blur="e => searchInputChanged(e, 'apellido2')" :value="search.apellido2"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row no-gap v-if="selectedSearchType === 'solicitud_id'">
                    <f7-col>
                        <f7-list class="no-margin">
                            <f7-list-input type="text" label="ID Solicitud" @blur="e => searchInputChanged(e, 'sid')" :value="search.sid"></f7-list-input>
                        </f7-list>
                    </f7-col>
                </f7-row>

                <div class="margin">
                    <f7-button fill color="primary" @click="selectedSearchType === 'patient_id' ? preSearchPatients() : searchSolicitudes(false)"
                        >Buscar</f7-button
                    >
                    <f7-button v-if="foundSolicitudes.length" class="margin-top" small @click="foundSolicitudes = []"
                        >Limpiar resultados de búsqueda</f7-button
                    >
                </div>

                <solicitudes-list
                    list-type="Busqueda signer"
                    :solicitudes="foundSolicitudesOrdered"
                    @solicitud-taken="handleSolicitudTakenFromBusqueda"
                ></solicitudes-list>

                <f7-button v-if="selectedSearchType !== 'solicitud_id'" @click="searchSolicitudes(true)">Cargar más</f7-button>
            </f7-page>
        </f7-popup>

        <select-patient-popup
            :opened="selectPatientPopupOpened"
            :patients="patientsToSelect"
            @popup-closed="selectPatientPopupOpened = false"
            @patient-selected="handlePatientSelected"
        ></select-patient-popup>
    </f7-page>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../../mixins/common";
import SolicitudesList from "./components/solicitudes-list.vue";
import SelectPatientPopup from "../../components/select-patient-popup.vue";

export default {
    mixins: [commonmixin],

    components: {
        "solicitudes-list": SolicitudesList,
        "select-patient-popup": SelectPatientPopup,
    },

    data() {
        return {
            selectedCola: "Por firmar",
            searchSolicitudesPopupOpened: false,
            search: {},
            foundSolicitudes: [],
            avisaDescargaok: null,
            selectedSearchType: "user_email",
            patientsToSelect: [],
            selectPatientPopupOpened: false,
        };
    },

    watch: {
        selectedCola: {
            immediate: true,
            handler() {
                this.fetchSolicitudesByCola();
            },
        },
    },

    computed: {
        ...mapState(["dev"]),
        ...mapState("homologacionVacunasNac", [
            "isLoading", 
            "observableEnEsperaFirmaNac",
            "observableFirmandoNac", 
            "observableApostillamientoNac",
            "observableApostillandoNac",
            "SOLICITUDES_FETCH_SIZE", 
            "SOLICITUDES_LOAD_MORE_FETCH_SIZE", 
            "enEsperaFirmaFilter"
        ]),

        ...mapGetters("homologacionVacunasNac", ["solicitudesEnEsperaFirma", "solicitudesFirmando", "solicitudesEnEsperaApostilla", "solicitudesApostillando"]),
        ...mapGetters(["centralAdminAccount"]),

        foundSolicitudesOrdered() {
            return this.foundSolicitudes.sort((a, b) => {
                if (a.wh < b.wh) return -1;
                if (a.wh > b.wh) return 1;
                return 0;
            });
        },
    },

    methods: {
        fetchSolicitudesByCola(refetch = false) {
            let targetObservable;

            if (!refetch) {
                if (this.selectedCola === "Por firmar")
                    targetObservable = this.observableEnEsperaFirmaNac;
                else if (this.selectedCola === "Por apostillar")
                    targetObservable = this.observableApostillamientoNac;
                else if (this.selectedCola === "Firmando")
                    targetObservable = this.observableFirmandoNac;
                else if (this.selectedCola === "Apostillando")
                    targetObservable = this.observableApostillandoNac;

                console.log("observable existe", targetObservable !== null);

                if (targetObservable) return;
            } else {
                this.$store.commit("homologacionVacunasNac/unset_all_observables_firma");
            }

            const reg = this.centralAdminAccount.regiones ? Object.keys(this.centralAdminAccount.regiones) : [];

            if (this.centralAdminAccount.regional) {
                if (reg.length == 0) {
                    return this.$f7.dialog.alert("No tiene regiones asignadas");
                } else if (reg.length > 10) {
                    return this.$f7.dialog.alert("No puede tener más de 10 regiones asignadas");
                }
            } else {
                reg.push("todas");
            }

            this.$store.dispatch("homologacionVacunasNac/fetchSolicitudes", {
                selectedCola: this.selectedCola,
                firestore: this.$firebase.firestore(),
                refetch,
                dev: this.dev ? true : false,
                regiones: reg
            });
        },

        loadMoreSolicitudesByCola() {

            const reg = this.centralAdminAccount.regiones ? Object.keys(this.centralAdminAccount.regiones) : [];

            if (this.centralAdminAccount.regional) {
                if (reg.length == 0) {
                    return this.$f7.dialog.alert("No tiene regiones asignadas");
                } else if (reg.length > 10) {
                    return this.$f7.dialog.alert("No puede tener más de 10 regiones asignadas");
                }
            } else {
                reg.push("todas");
            }

            this.$store.dispatch("homologacionVacunasNac/loadMoreSolicitudes", {
                selectedCola: this.selectedCola,
                firestore: this.$firebase.firestore(),
                dev: this.dev ? true : false,
                regiones: reg
            });
        },

        openSearchSolicitudes() {
            this.searchSolicitudesPopupOpened = true;
        },

        goToHistoricoAceptadas() {
            this.$f7.views.main.router.navigate(`/gestor_homologacion_vacunas_nac/accepted/sign`);
            
        },

        searchInputChanged(e, property) {
            const value = e.target.value;

            if (value) {
                this.$set(this.search, property, e.target.value);
            } else {
                this.$delete(this.search, property);
            }
        },

        selectedSearchTypeChanged(searchType) {
            this.selectedSearchType = searchType;

            if (this.selectedSearchType === "user_email") {
                this.$delete(this.search, "tipoDoc");
                this.$delete(this.search, "numDoc");
                this.$delete(this.search, "nombres");
                this.$delete(this.search, "apellido1");
                this.$delete(this.search, "apellido2");
                this.$delete(this.search, "sid");
            } else if (this.selectedSearchType === "patient_id") {
                this.$set(this.search, "tipoDoc", "RUN");
                this.$delete(this.search, "nombres");
                this.$delete(this.search, "apellido1");
                this.$delete(this.search, "apellido2");
                this.$delete(this.search, "user_email");
                this.$delete(this.search, "sid");
            } else if (this.selectedSearchType === "nombres_apellidos") {
                this.$delete(this.search, "user_email");
                this.$delete(this.search, "tipoDoc");
                this.$delete(this.search, "numDoc");
                this.$delete(this.search, "sid");
            } else if (this.selectedSearchType === "solicitud_id") {
                this.$delete(this.search, "user_email");
                this.$delete(this.search, "tipoDoc");
                this.$delete(this.search, "numDoc");
                this.$delete(this.search, "nombres");
                this.$delete(this.search, "apellido1");
                this.$delete(this.search, "apellido2");
            }
        },

        async preSearchPatients() {
            if (!this.search.tipoDoc || !this.search.numDoc) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Debe ingresar un tipo y número de documento");
                return;
            }
            const [nroDoc, iso2] = this.search.numDoc.split("_");
            const tipoDoc = this.search.tipoDoc;

            let formatedNroDoc = nroDoc;

            if (iso2) {
                formatedNroDoc = `${formatedNroDoc}_${iso2}`;
            }

            const trimBusqueda = formatedNroDoc.trim();

            if (tipoDoc === "RUN") {
                await this.searchSolicitudes();
            } else {
                this.$f7.dialog.preloader("Buscando...");

                const response = await this.commonExecute({ tipoDoc, nroDoc: trimBusqueda, dev: this.dev }, "admin-preSearchPatient", false);

                if (response.error) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(response.error.message);
                    return;
                }

                const { patients } = response;

                if (patients.length === 0) {
                    this.$f7.dialog.close();
                    await this.searchSolicitudes(true);
                    return;
                }

                if (patients.length === 1 && patients[0].identity === trimBusqueda) {
                    this.$f7.dialog.close();

                    const patient = patients[0];

                    const { idenType, identity } = patient;

                    this.search.tipoDoc = idenType;
                    this.search.numDoc = identity;

                    await this.searchSolicitudes();
                } else {
                    this.patientsToSelect = patients;
                    this.selectPatientPopupOpened = true;
                }

                this.$f7.dialog.close();
            }
        },

        async handlePatientSelected(patient) {
            const { idenType, identity } = patient;

            this.search.tipoDoc = idenType;
            this.search.numDoc = identity;

            await this.searchSolicitudes();

            this.patientsToSelect = [];
            this.selectPatientPopupOpened = false;
        },

        async searchSolicitudes(loadMore = false) {
            try {
                let collection = this.dev ? "solicitudes_homologacion_nac_dev" : "solicitudes_homologacion_nac";

                let query = this.$firebase
                    .firestore()
                    .collection(collection)
                    .where("est", "==", "Aceptada");

                if (this.selectedCola === "Por firmar")
                    query = query.where("cert.status", "==", "pending");
                else if (this.selectedCola === "Por apostillar") {
                    query = query.where("cert.status", "==", "approved")
                        .where("actApos.status", "==", "pending");
                } else if (this.selectedCola === "Firmando")
                    query = query.where("cert.status", "==", "signing");
                else if (this.selectedCola === "Apostillando"){
                    query = query.where("cert.status", "==", "approved")
                        .where("actApos.status", "==", "reviewing");  
                }  


                if (this.selectedSearchType === "user_email") {
                    if (!this.search.user_email) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar un email");
                        return;
                    }

                    query = query.where("user_email", "==", this.search.user_email);
                } else if (this.selectedSearchType === "patient_id") {
                    if (!this.search.tipoDoc || !this.search.numDoc) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar un número de documento");
                        return;
                    }

                    query = query.where("pat.pid", "==", `${this.search.tipoDoc}_${this.search.numDoc}`);
                } else if (this.selectedSearchType === "nombres_apellidos") {
                    if (!this.search.nombres && !this.search.apellido1 && !this.search.apellido2) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar al menos un campo de búsqueda");
                        return;
                    }

                    if (this.search.apellido1) {
                        query = query.where("pat.apellido1", "==", this.search.apellido1);
                    }

                    if (this.search.apellido2) {
                        query = query.where("pat.apellido2", "==", this.search.apellido2);
                    }

                    if (this.search.nombres) {
                        query = query.where("pat.nombres", "==", this.search.nombres);
                    }
                } else if (this.selectedSearchType === "solicitud_id") {
                    if (!this.search.sid) {
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert("Debe ingresar un ID de solicitud");
                        return;
                    }

                    const custom = this.$firebase
                    .firestore()
                    .collection(collection)
                    .doc(this.search.sid);

                    const snapshot = await custom.get();
                    this.foundSolicitudes = snapshot.exists ? [snapshot.data()] : [];
                    return;
                }

                query = query.orderBy("wh");

                if (loadMore) {
                    const lastFoundSolicitud = this.foundSolicitudes[this.foundSolicitudes.length - 1];

                    if (lastFoundSolicitud) {
                        query = query.startAfter(lastFoundSolicitud.wh);
                    }
                }

                query = query.limit(this.SOLICITUDES_LOAD_MORE_FETCH_SIZE);

                const snapshot = await query.get();

                const solicitudes = snapshot.docs.map(doc => doc.data());

                if (!loadMore) {
                    this.foundSolicitudes = solicitudes;
                } else {
                    this.foundSolicitudes = this.foundSolicitudes.concat(solicitudes);
                }

                if (this.foundSolicitudes.length === 0) {
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert("No se encontraron solicitudes");
                    return;
                }
            } catch (error) {
                console.error("error buscando solicitudes", error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert("Error buscando solicitudes. " + error);
            }
        },

        handleSearchPopupClosed() {
            this.searchSolicitudesPopupOpened = false;
            this.search = {};
            this.foundSolicitudes = [];
        },

        handleSolicitudTakenFromBusqueda() {
            this.searchSolicitudesPopupOpened = false;
        },

        handlePageBeforeout() {
            //validate if is navigate to resolving page
            if (this.$f7.views.main.router.url === "/gestor_homologacion_vacunas_nac/resolving/sign" 
                || this.$f7.views.main.router.url === "/gestor_homologacion_vacunas_nac/resolving/apostilla") return;
            this.$store.commit("homologacionVacunasNac/unset_all_observables_firma");
        },
    }
}
</script>