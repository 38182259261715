<template>
  <f7-page  name="center_Cuentas">
    <f7-navbar >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :subtitle="`Gestión de privilegios en ${centerInFocoInfo.name}`" title="Cuentas"></f7-nav-title>
        <f7-nav-right>
      <f7-link class="searchbar-enable" data-searchbar=".searchbar-cuentascetro" icon-material="search"></f7-link>
       <f7-link @click="traeCuentasAqui(true)" icon-material="cached"></f7-link>
    </f7-nav-right>
    <f7-searchbar
      class="searchbar-cuentascetro"
      expandable
      search-container=".misconts"
      search-in=".item-title, .item-subtitle, .item-text"
      placeholder="Busque cuenta"
    ></f7-searchbar>
    </f7-navbar>

<f7-progressbar v-if="cargando" infinite></f7-progressbar>
<f7-block v-if="!almanosunavezcargado"><f7-button large raised fill @click="traeCuentasAqui(false)">Cargar cuentas con privilegios en este vacunatorio</f7-button></f7-block>
<f7-block v-if="cargando && noHayUsersHere" class="text-align-center"><f7-preloader></f7-preloader><br/>Cargando cuentas...</f7-block>
<f7-list class="searchbar-not-found">
    <f7-list-item title="No encontrado"></f7-list-item>
  </f7-list>
<f7-list media-list class="no-margin-top misconts" >
  <f7-list-item
  v-for="cuenta in misUsersHere"
  :key="cuenta.uid"
  :title="`${cuenta.info.ap1} ${cuenta.info.ap2}, ${cuenta.info.nms} `"
  :subtitle="formatRut(cuenta.info.id)"
  @click="gotoCuenta(cuenta)"
  :text="cuenta.info.em"
  link="#"
  
  >
  <div slot="after">
    <font-awesome-icon v-for="unpriv in getonlyturues(cuenta.prv)" class="fa-lg text-color-teal margin-left-half" :key="unpriv" :icon="ikosprivs[unpriv]"></font-awesome-icon>
  </div>
  </f7-list-item>
</f7-list>
  <f7-block class="text-align-center" strong v-if="!cargando && almanosunavezcargado && noHayUsersHere">No hay cuentas con privilegios en este vacunatorio></f7-block>


<f7-fab  @click="popupCrearCuentaOpened = true" position="right-bottom" slot="fixed" color="teal">
    <f7-icon material="add"></f7-icon>
  </f7-fab>

 <f7-popup class="crear-cuenta" :close-by-backdrop-click="false" :opened="popupCrearCuentaOpened"  @popup:closed="popupCrearCuentaOpened = false">
     <crea-cuenta v-if="popupCrearCuentaOpened" :vacunatorioid="centerInFoco" @abortCreacion="popupCrearCuentaOpened = false" @gotoCuenta="goToCuentaInner"></crea-cuenta>
    </f7-popup>


  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { commonmixin } from '../mixins/common'
import creaCuenta from './cuenta_crea_popup.vue'

export default {
  mixins: [commonmixin],
  components:{
        creaCuenta
      },
    data() {
      return {
       cargando:false,
       popupCrearCuentaOpened: false,
       almanosunavezcargado:false,
      }
    },
    computed:{
     ...mapState(['centerInFoco','ikosprivs']),
    ...mapGetters(['centerInFocoInfo']),

     misUsersHere(){
       return this.$store.getters.getUno('cuentasByCentro',this.centerInFoco);
     },
     noHayUsersHere(){
       return !this.misUsersHere || !Object.values(this.misUsersHere).length
     }
    },
    watch:{
        centerInFoco(nuevocentro){
            console.log('ATENTO, CAMBIO DE CENTRO',nuevocentro);
           this.traeCuentasAqui(true);
        }
    },
    methods:{
      getonlyturues(losprvs){
        return Object.keys(losprvs).filter(unakey=>{
          return losprvs[unakey]
        })
      },
       goToCuentaInner(cuenta){
         this.popupCrearCuentaOpened=false;
         let cuentatogo={
           cid: this.centerInFoco,
           info: cuenta.admn,
           prv: {},
           uid: cuenta.uid
         };
        this.gotoCuenta(cuentatogo);
      },
       gotoCuenta(cuenta){
       console.log(cuenta);
        this.$f7.views.main.router.navigate('/center/vac_cuenta/',
        {
          props: cuenta
        })
      },
      async traeCuentasAqui(force){
        this.almanosunavezcargado=true;
         if(this.noHayUsersHere || force){
            this.cargando= true;
          try {
            let datous = await this.commonExecute({vacunatorioid:this.centerInFoco },'admin-getMisUsersPorVac',false);
              if(datous.error){
                throw new Error(datous.error.message);
              }
          this.$store.commit('updtObjProperty',{what:'cuentasByCentro',to: datous.payload, idx:this.centerInFoco});
          this.cargando= false;
          } catch(error){
            this.cargando= false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
         }
      }
    }
}
</script>
<style>


</style>