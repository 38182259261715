 <template>
 <f7-list-item
        :title="address.comuna || 'No definida'"
        header="Comuna: "
        class="noshow"
        :footer="
            address.comuna ? address.provincia.split('-')[2] + ' | ' + address.region_hr : ''
        "
        smart-select
        :smart-select-params="{
            searchbar: {removeDiacritics: true},
            searchbarPlaceholder: 'Busque comunas',
            closeOnSelect: true,
            virtualList: true
        }"
    >
        <select @change="eligiendoComuna($event.target.value)" name="comuna">
            <template v-for="region in comunas">
                <optgroup
                    v-for="provincia in region.provincias"
                    :key="region.region_number + provincia.name"
                    :label="region.region + ': ' + provincia.name"
                >
                    <option
                        v-for="comuna in provincia.comunas"
                        :selected="
                            selectedComuna ==
                            comuna.name + '|' + provincia.name + '|' + region.region
                        "
                        :key="comuna.code"
                        :value="
                            comuna.name + '|' + provincia.name + '|' + region.region_iso_3166_2 + '|' + comuna.code+ '|' + region.region
                        "
                    >
                        {{ comuna.name }} ({{provincia.name}})
                    </option>
                </optgroup>
            </template>
        </select>
    </f7-list-item>
</template>
<script>
import { comunasHelper } from "@/js/comunas.js";
export default {
   mixins: [comunasHelper],
  props:['address'],
    data() {
      return {

      }
    },
    computed:{
        selectedComuna() {
            return this.address.comuna ? this.address.comuna +
                    "|" +
                    this.address.provincia +
                    "|" +
                    this.address.region
                    : "a|b|c";
        },
    },
    methods:{
         eligiendoComuna(comuna) {
            let linfo = comuna.split("|");
            let neoaddress={
              comuna: linfo[0],
              region: linfo[2],
              provincia: `${linfo[2]}-${linfo[1]}`,
              region_hr: linfo[4],
              code: linfo[3]
            };
            console.log(neoaddress);
            this.$emit('newAddress',neoaddress)
        },
    }
}


</script>