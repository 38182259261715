<template>
 <f7-page>
        <f7-navbar title="Log">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block class="text-align-center" v-if="cargando">
         <f7-preloader></f7-preloader><br/>Cargando log...
        </f7-block>
        <f7-list class="no-margin-top" v-else>
          <f7-list-item v-for="(log,idx) in loginfo" :key="idx" :title="`${log.i}`" :after="$dayjs.unix(log.x).format('HH:mm dddd DD/MM/YY')" :footer="log.w"></f7-list-item>
        </f7-list>
      </f7-page>
</template>
<script>
import { commonmixin } from '../mixins/common'

export default {
  mixins: [commonmixin],
  props:['tipo','cual','centerInFoco'],
    data() {
      return {
         loginfo:[],
         cargando: true
      }
    },
    mounted(){
      console.log('cargando tipo',this.tipo, this.cual);
      this.fetchLatests();
    },
      computed:{
   
      },

    methods:{
           async fetchLatests(){
          try {
              this.cargando = true;
                 let fulog = await this.$firebase.database().ref(`adm_log`).orderByChild(this.tipo).equalTo(this.cual).once('value');
                if(fulog.exists()){
                  fulog.forEach(unlog => {
                    let vual=unlog.val();
                    if(this.tipo=='k' && vual.i=='guarda'){
                      vual.i='cambia_criterios';
                    }
                    this.loginfo.push(vual);
                  });
                }
            this.cargando = false;
          } catch (error) {
             this.cargando = false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }

        }
    }
}
</script>
<style>

</style>