import Vuex from "vuex";
import Vue from "vue";
import dayjs from "dayjs";
Vue.use(Vuex);

import HomologacionVacunasStore from "./modules/homologacion_vacunas";
import HomologacionVacunasNacStore from "./modules/homologacion_vacunas_nac";
import EditorRegistrosVacunasStore from "./modules/editor_registros_vacunas";

//ADMIN - VACUNATOR.IO
export const store = new Vuex.Store({
    modules: {
        homologacionVacunas: HomologacionVacunasStore,
        homologacionVacunasNac: HomologacionVacunasNacStore,
        editorRegistrosVacunas: EditorRegistrosVacunasStore,
    },

    state: {
        activeTab: "/",
        appVersion: "1.59.6",
        agenda_molde_activa: null,
        banedejasVacunaFoco: {},
        campanas: {},
        centerInFoco: null,
        cerrandoSesion: false,
        comunas: {},
        crit_rules: {
            IN: { tx: "Incluye", col: "teal", iko: "user-check" },
            EX: { tx: "Excluye", col: "red", iko: "user-slash" },
            NA: { tx: "Sin efecto", col: "primary", iko: "user-tag" },
        },
        cuentasByCentro: {},
        dateFoco: null,
        decl_id_pais: null,
        dev: process.env.NODE_ENV === "production" ? false : true,
        usingEmulators: process.env.USE_FUNCTIONS_EMULATORS === "true",
        emulatorsUrl:
            process.env.FUNCTIONS_EMULATOR_HOST && process.env.FUNCTIONS_EMULATOR_PORT
                ? `http://${process.env.FUNCTIONS_EMULATOR_HOST}:${process.env.FUNCTIONS_EMULATOR_PORT}/mevacuno-pro${
                      process.env.NODE_ENV === "production" ? "" : "-dev"
                  }/us-central1`
                : null,
        apiUrl: `https://us-central1-mevacuno-pro${process.env.NODE_ENV === "production" ? "" : "-dev"}.cloudfunctions.net`,
        formularios: {},
        forms_logic: null,
        ikosprivs: {
            vac_cuentas: "user-nurse",
            vac_agenda: "calendar-alt",
            vac_reg: "prescription",
            vac_stock: "syringe",
        },
        laboratorios: {},
        lastFullLoad: {},
        loggingin: false,
        paises: {},
        patogenos: {},
        roles: [],
        privsCentrales: {},
        privsPorVacunatorio: {},
        regiones: {},
        recheckPaxInfo_homolacion: 0,
        user: { privx: {}, centers: {} },
        vacunatorios: {},
        vacunas: {},
        verificacionesArray: [],
        sexo: {
            M: "Masculino",
            F: "Femenino",
            Intersex: "Intersex",
        },
        tiposRechazo: {},
        cacheComunas: {},
        cacheRegiones: {},
        cacheEstadisticasPatientsActions: {},
        cacheEstadisticasPatientsActionsCount: {},
        cacheEstadisticasLogsUsersVerified: {},
        cacheEstadisticasLogsResolucionIdentidad: {},
        cacheEstadisticasLogsResolucionIdentidadPorUsuario: {},
    },
    getters: {
        activeRoles: state => {
            return state.roles.filter(r => r.isActive);
        },
        userHasRolesPrivilege: state => {
            return state.user?.admn?.prv?.roles === true;
        },
        getRoleName: state => roleId => {
            const role = state.roles.find(r => r.id === roleId);
            if (!role) return "N/A";
            return role.isActive ? role.name : `${role.name} (inactivo)`;
        },
        getApiUrl: state => {
            return state.usingEmulators && state.emulatorsUrl ? state.emulatorsUrl : state.apiUrl;
        },
        usrtrxDatabaseUrl: state => {
            return state.dev ? "https://usrtrx-mvcn-dev.firebaseio.com" : "https://usrtrx-mvcn.firebaseio.com";
        },
        campanasConluidas: state => {
            let cuales = Object.values(state.campanas).filter(unacamp => {
                return unacamp.pubfin && dayjs().isAfter(unacamp.pubfin, "day");
            });
            cuales.sort((a, b) => {
                if (a.pubfin > b.pubfin) {
                    return 1;
                } else if (a.pubfin < b.pubfin) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return cuales;
        },
        campanasActivas: state => {
            let cuales = Object.values(state.campanas).filter(unacamp => {
                return unacamp.pubfin && dayjs().isBefore(unacamp.pubfin, "day");
            });
            cuales.sort((a, b) => {
                if (a.pubfin > b.pubfin) {
                    return 1;
                } else if (a.pubfin < b.pubfin) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return cuales;
        },
        verificacionesArray: state => {
            state.verificacionesArray.sort((a, b) => {
                if (a.wh > b.wh) {
                    return 1;
                } else if (a.wh < b.wh) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return state.verificacionesArray;
        },
        tiposRechazo: state => {
            return Object.values(state.tiposRechazo).filter(unrech => {
                return !unrech.inactive;
            });
        },
        campanasDraft: state => {
            let cuales = Object.values(state.campanas).filter(unacamp => {
                return !unacamp.pubfin;
            });
            cuales.sort((a, b) => {
                if (a.fin > b.fin) {
                    return 1;
                } else if (a.fin < b.fin) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return cuales;
        },
        paisesArraySorted: state => {
            let prearreglado = Object.values(state.paises) || [];
            prearreglado.sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                } else if (a.name < b.name) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        formularios: state => {
            return Object.values(state.formularios);
        },
        formularios_publicados: state => {
            return Object.values(state.formularios).filter(unform => {
                return unform.published;
            });
        },
        getUno: state => {
            return (fuente, id) => {
                return state[fuente][id];
            };
        },
        vacunatorios: state => {
            //return Object.values(state.vacunatorios) || [];
            let prearreglado = Object.values(state.vacunatorios) || [];
            prearreglado.sort((a, b) => {
                if (a.norm > b.norm) {
                    return 1;
                } else if (a.norm < b.norm) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        unaVacuna: state => {
            return id => {
                return state.vacunas[id];
            };
        },
        unPatogeno: state => {
            return id => {
                return state.patogenos[id];
            };
        },
        unPais: state => {
            return id => {
                return state.paises[id] || {};
            };
        },
        vacunas: state => {
            let prearreglado = Object.values(state.vacunas) || [];
            prearreglado.sort((a, b) => {
                if (a.namenorm > b.namenorm) {
                    return 1;
                } else if (a.namenorm < b.namenorm) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        patogenos: state => {
            let prearreglado = Object.values(state.patogenos) || [];
            prearreglado.sort((a, b) => {
                if (a.namenorm > b.namenorm) {
                    return 1;
                } else if (a.namenorm < b.namenorm) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        laboratorios: state => {
            let prearreglado = Object.values(state.laboratorios) || [];
            prearreglado.sort((a, b) => {
                if (a.namenorm > b.namenorm) {
                    return 1;
                } else if (a.namenorm < b.namenorm) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        regiones: state => {
            let prearreglado = Object.values(state.regiones) || [];
            prearreglado.sort((a, b) => {
                if (a.orden > b.orden) {
                    return 1;
                } else if (a.orden < b.orden) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return prearreglado;
        },
        centralAdminAccount: state => {
            return state.user.admn || {};
        },
        centralAdminPrivs: state => {
            let admn = state.user.admn || {};
            return admn.prv || {};
        },
        centersAdminPrivs: state => {
            return state.user.centers || {};
        },
        centerInFocoInfo: state => {
            return state.vacunatorios[state.centerInFoco] || { id: state.centerInFoco, address: {} };
        },
    },
    mutations: {
        editRole: (state, role) => {
            const index = state.roles.findIndex((r) => r.id === role.id);
            state.roles[index] = role;
        },
        changeRoleActiveStatus: (state, payload) => {
            const { roleId, isActive } = payload;
            const index = state.roles.findIndex((role) => role.id === roleId);
            state.roles[index].isActive = isActive;
        },
        deleteRole: (state, roleId) => {
            const index = state.roles.findIndex((role) => role.id === roleId);
            state.roles.splice(index, 1);
        },
        addRole: (state, role) => {
            state.roles.push(role);
        },
        setWhatTo: (state, pyld) => {
            state[pyld.what] = pyld.to;
            state.lastFullLoad[pyld.what] = dayjs().unix();
        },
        pushToArraySimple: (state, pyld) => {
            if (!state[pyld.array].includes(pyld.value)) {
                state[pyld.array].push(pyld.value);
            }
        },
        pushToArrayOfObjcts: (state, pyld) => {
            let indiexist = state[pyld.array].findIndex(unitem => unitem[pyld.indexatrr] == pyld.value[pyld.indexatrr]);
            if (indiexist == -1) {
                state[pyld.array].push(pyld.value);
            }
        },
        removeFromArrayByIndexAtrr: (state, pyld) => {
            let indiexist = state[pyld.array].findIndex(unitem => unitem[pyld.indexatrr] == pyld.value);
            if (indiexist != -1) {
                state[pyld.array].splice(indiexist, 1);
            }
        },
        mergeObjTo: (state, pyld) => {
            state[pyld.what] = Object.assign({}, state[pyld.what], pyld.to);
        },
        setObjTo: (state, pyld) => {
            state[pyld.what] = Object.assign({}, pyld.to);
            state.lastFullLoad[pyld.what] = dayjs().unix();
        },
        removeFromSimpleArray(state, pyld) {
            let index = state[pyld.array].findIndex(item => item === pyld.value);
            if (index !== -1) {
                state[pyld.array].splice(index, 1);
            }
        },
        updtObjProperty: (state, pyld) => {
            let nueo = {};
            if (!pyld.idx) {
                nueo[pyld.to.id] = pyld.to;
            } else {
                nueo[pyld.idx] = pyld.to;
            }
            state[pyld.what] = Object.assign({}, state[pyld.what], nueo);
        },
        replaceAllItemsAColeccionNested: (state, p) => {
            if (!state[p.col][p.vid][p.prop]) {
                let nueo = {};
                nueo[p.prop] = p.val;
                state[p.col][p.vid] = Object.assign({}, state[p.col][p.vid], nueo);
            } else {
                state[p.col][p.vid][p.prop] = Object.assign({}, state[p.col][p.vid][p.prop], p.val);
            }
        },
        addItemAColeccionNested: (state, p) => {
            let lojbs = {};
            lojbs[p.pid] = p.val;
            if (!state[p.col][p.vid][p.prop]) {
                let nueo = {};
                nueo[p.prop] = lojbs;
                state[p.col][p.vid] = Object.assign({}, state[p.col][p.vid], nueo);
            } else {
                state[p.col][p.vid][p.prop] = Object.assign({}, state[p.col][p.vid][p.prop], lojbs);
            }
        },
        borrarItemDeColeccionNested: (state, p) => {
            let prevs = Object.assign({}, state[p.col][p.vid][p.prop] || {});
            delete prevs[p.pid];
            state[p.col][p.vid][p.prop] = Object.assign({}, prevs);
        },
        addItemNiveltree: (state, p) => {
            let lojbs = {};
            lojbs[p.c3] = p.d4;
            if (!state[p.a1][p.b2]) {
                let nueo = {};
                nueo[p.b2] = lojbs;
                state[p.a1] = Object.assign({}, state[p.a1], nueo);
            } else {
                state[p.a1][p.b2] = Object.assign({}, state[p.a1][p.b2], lojbs);
            }
        },
    },
    actions: {},
});
