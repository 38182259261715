<template>
    <f7-page name="campaigns-Charter" @page:beforein="cargainfo(false)" class="bg-page">
        <!-- Top Navbar -->
        <f7-navbar :subtitle="punteroStats.computing?'Actualizando...':`Actualizado el ${$dayjs.unix(punteroStats.last_comp).format('dddd DD/MM/YYYY [a las] HH:mm:ss')}`" :title="`${tot} ${name}`" back-link="Back">
            <f7-nav-right>
                <f7-block>
                <f7-button v-if="!punteroStats.computing" @click="cargainfo(true)" fill color="teal" class="no-margin">Actualizar</f7-button>
                <f7-preloader v-else></f7-preloader>
                </f7-block>
                </f7-nav-right>
        </f7-navbar>

    <f7-block class="no-margin-top">
    <f7-row>
        <f7-col>
            <f7-block-title class="text-color-primary bold">Sexo biológico</f7-block-title>
                <f7-card>
        <f7-card-content class="text-align-center">
                <pie-chart :chart-data="sexo_collection"></pie-chart>
        </f7-card-content>
      
    </f7-card>
        </f7-col>

            <f7-col>
            <f7-block-title class="text-color-primary bold">Edad</f7-block-title>
                <f7-card>
        <f7-card-content class="text-align-center">
                    <barra-vertical :chart-data="edad_collection" :options="opcion_sin_leyenda" ></barra-vertical>
        </f7-card-content>
    </f7-card>
        </f7-col>


         <f7-col>
            <f7-block-title class="text-color-primary bold">Sexo/edad</f7-block-title>
                <f7-card>
        <f7-card-content class="text-align-center">
                   <bar-chart :chart-data="datacollection"></bar-chart>
        </f7-card-content>
    </f7-card>
        </f7-col>
               
    </f7-row>
    <f7-row>
        <f7-col>
            <f7-block-title class="text-color-primary bold">Regiones</f7-block-title>
             <f7-searchbar
                search-container=".regionserchlist"
                search-item="li"
                search-in=".item-title"
                placeholder="Regiones"
                found-el=".serfonudregi"
                not-found-el=".notfoundregion"
                class="no-margin-horizontal"
                :backdrop="false"
                ></f7-searchbar>
                 <f7-block class="no-margin no-padding sclore">
                       <f7-list class="notfoundregion searchbar-not-found no-margin">
                        <f7-list-item title="No encontrado"></f7-list-item>
                    </f7-list>
                    <f7-list class="no-margin regionserchlist searchbar-found serfonudregi">
                            <f7-list-item
                            v-for="region in geoLooper.regionesArray"
                                :key="region.id"
                                :after="region.k || '--'"
                            :title="region.region" :footer="`Región ${region.region_number}`" ></f7-list-item>
                        </f7-list>
                 </f7-block>
        </f7-col>
        <f7-col>
            <f7-block-title class="text-color-primary bold">Provincias</f7-block-title>
             <f7-searchbar
                search-container=".provincisearhslit"
                search-item="li"
                search-in=".item-title"
                placeholder="Provincias"
                found-el=".serchfoundprovi"
                not-found-el=".notofunprovi"
                class="no-margin-horizontal"
                :backdrop="false"
                ></f7-searchbar>
                 <f7-block class="no-margin no-padding sclore">
                       <f7-list class="notofunprovi searchbar-not-found no-margin">
                        <f7-list-item title="No encontrado"></f7-list-item>
                    </f7-list>
                    <f7-list  class="no-margin provincisearhslit searchbar-found serchfoundprovi">
                        <f7-list-item 
                        v-for="provincia in geoLooper.provincisArray"
                        :key="provincia.id"
                        :after="provincia.k || '--'"
                        :title="provincia.name" :footer="provincia.region" ></f7-list-item>
                    </f7-list>
                 </f7-block>
        </f7-col>
        <f7-col >
            <f7-block-title class="text-color-primary bold">Comunas</f7-block-title>
            <f7-searchbar
                search-container=".comunavirlistt"
                search-item="li"
                search-in=".item-title"
                placeholder="Comunas"
                found-el=".searchbarfoundcomunas"
                not-found-el=".searchbarnotfoundcomunas"
                class="no-margin-horizontal"
                :backdrop="false"
                ></f7-searchbar>
            <f7-block class="no-margin no-padding sclore comskroll">
                 <f7-list class="searchbarnotfoundcomunas searchbar-not-found no-margin">
                <f7-list-item title="No encontrado"></f7-list-item>
                </f7-list>
             <f7-list  
             class="no-margin comunavirlistt searchbar-found searchbarfoundcomunas"
                    virtual-list
                     ref="stulist"
                    :virtual-list-params="{ items, searchAll, renderExternal, scrollableParentEl:'.comskroll', height: 54}">
                 <ul>
                <f7-list-item
                v-for="(unacomuna, index) in vlData.items"
                :key="index"
                :style="`top: ${vlData.topPosition}px`"
                 :after="unacomuna.k || '--'"
                 :title="unacomuna.name" :footer="unacomuna.prov" ></f7-list-item>
                 </ul>
            </f7-list>
            </f7-block>

        </f7-col>
    </f7-row>
    </f7-block>
<f7-block>
    <f7-block-title class="text-color-primary bold">Nacionalidad</f7-block-title>
  <f7-list class="sclore">
    <f7-list-item v-for="pais in damenac(nac)" :key="pais.id" :title="$store.getters.unPais(pais.id).name || pais.id" :after="pais.k"></f7-list-item>
</f7-list>
</f7-block>
   

    </f7-page>
</template>

<script>
import BarChart from '../components/BarChart.js'
import BarraVertical from '../components/BarraVertical.js'
import PieChart from '../components/PieChart.js'
import { commonmixin } from '../mixins/common'

export default {
components: {
    BarChart,
    BarraVertical,
    PieChart
  },
    mixins: [commonmixin],
    props: ['id','cual'],
    data() {
        return {
            name:'Cargando...',
            tot: '--',
             punteroStats: {
                last_comp: null,
                computing: false,
                dlta: 0
             },
            items:[],
            vlData:{
                 items: [],
            },
            geoLooper:{
                comunsArray:[],
                provincisArray:[],
                regionesArray:[],
            },
            nac:[],
               datacollection: {
                labels:['90+','80-89','70-79','60-69','50-59','40-49','30-39','20-29','10-19','0-9'],
                datasets:[]
            },
            edad_collection:{
                labels:['0-9','10-19','20-29','30-39','40-49','50-59','60-69','70-79','80-89','90+'],
                datasets:[]
            },
            sexo_collection:{
                labels:['Masculino','Femenino','Indeterminado'],
                datasets:[]
            },
            opcion_sin_leyenda:{
                 legend: {
                    display: false,
                }
            }
        };
    },

    computed: {

    },
    methods: {
   damenac(nac){
       let cual = nac.find(pais=>{
           return !pais.id
       });

    console.log('el error es ',cual)
       return nac;
   },
    renderExternal(vl, vlData) {
        this.vlData = vlData;
    },
    searchAll(query, items) {
        const found = [];
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].name.toLowerCase().indexOf(query.toLowerCase()) >= 0 || query.trim() === '') found.push(i);
        }
        return found; // return array with mathced indexes
      },
    async cargainfo(force){
         try {
            this.$f7.dialog.preloader('Cargando datos...');
            this.punteroStats.computing = true;
            let datous = await this.commonExecute({campanaid: this.id, repid: this.cual, force},'admin-getstatsgrupocampana',false);
            if(datous.error){
                throw new Error(datous.error.message);
            }
            console.log(datous.payload);
            let recpecdata = datous.payload.datum;
          
            this.edad_collection=Object.assign({},this.edad_collection,{datasets:[{
              label: 'n',
              backgroundColor: '#3fafbd',
               data: Object.values(recpecdata.edad),
            }]});
            this.sexo_collection=Object.assign({},this.sexo_collection,{datasets:[{
               data: [recpecdata.sex.M,recpecdata.sex.F,recpecdata.sex.I],
               backgroundColor:['#0095ff','#ff71da','#cecece']
            }]});
             this.datacollection=Object.assign({},this.datacollection,{datasets:[{
              label: 'Masculino',
              backgroundColor: '#0095ff',
               data: Object.values(recpecdata.edad_sex.M).reverse(),
              stack: 'a'
            }, {
              label: 'Femenino',
              backgroundColor: '#ff71da',
                 data: Object.values(recpecdata.edad_sex.F).reverse(),
              stack: 'a'
            }]});
            this.geoLooper = recpecdata.geoarray;
            this.$refs.stulist.f7VirtualList.replaceAllItems(this.geoLooper.comunsArray);
            this.nac = recpecdata.nac || [];
            this.name = recpecdata.name;
            this.tot =  recpecdata.tot;

            this.punteroStats = Object.assign(datous.payload.punter);
            this.$f7.dialog.close();
            }
            catch (error) {
            this.$f7.dialog.close();
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      }
    },
};
</script>
<style>
.hiperalto{
    font-size:24px;
        color: #000!important;

}
.meganum{
    font-size: 40px;
}
.sclore{
    height: 300px;
    max-height: 300px;
    overflow-y: scroll;
}
</style>