<template>
  <f7-page @page:beforein="traerBanejasConStock()" name="center_stock_vacuna">

        <f7-navbar :title="`${vacunaenfoco.vacunaPcName} (${vacunaenfoco.laboratorioName})`" :subtitle="`Stock en ${centerInFocoInfo.name}`" back-link="Back">
          <f7-nav-right>
            <f7-block v-if="cargandoBandejas"><f7-preloader></f7-preloader></f7-block>
            <f7-link @click="abrirlog(null)" tooltip="Ver log"><font-awesome-icon  icon="history"></font-awesome-icon></f7-link>
            </f7-nav-right>
        </f7-navbar>



  <template v-if="provisorios.count" >
    <f7-block class="no-margin-bottom margin-top-half text-color-primary">Alertas</f7-block>
    <f7-block class="la_alerta">Hay {{provisorios.count}} paciente{{provisorios.count==1?'':'s'}} agendado{{provisorios.count==1?'':'s'}} para una dosis futura que no existe en el stock aún. <f7-link @click="proviosiorsOpened = true">Ver detalle</f7-link></f7-block>
  </template>
<f7-block class="no-margin-bottom margin-top-half text-color-primary">Resumen</f7-block>

<f7-block class="no-margin-top">
<f7-row>
  <f7-col class="text-align-center" width="33">
    <h1 class="ngrande">{{globalStats.total}}</h1>
    <p class="unidosis"><b>Unidosis</b> en stock</p>
    <p class="nbandejas">de {{globalStats.nbandejas}} bandeja{{globalStats.nbandejas==1?'':'s'}}</p>
    <f7-button @click="abrirNuevoTray()" fill raised large>Agregar nueva bandeja</f7-button>
  </f7-col>
  <f7-col width="66">
    <f7-list class="fixfamarg no-margin">
      <f7-list-item
      title="Reservadas via agenda web"
      >
      <font-awesome-icon slot="media" class="fa-lg text-color-orange" icon="user-lock"></font-awesome-icon>
      <f7-chip :text="globalStats.reservadas || '0'" slot="after"></f7-chip></f7-list-item>

      <f7-list-item
      title="Aún disponibles para agenda web"
      >
      <font-awesome-icon slot="media" class="fa-lg text-color-teal" icon="user-clock"></font-awesome-icon>
      <f7-chip :text="globalStats.web || '0'" slot="after"></f7-chip></f7-list-item>

      <f7-list-item
      title="Aún disponibles para demanda espontánea"
      >
      <font-awesome-icon slot="media" class="fa-lg text-color-teal" icon="hospital-user"></font-awesome-icon>
      <f7-chip :text="globalStats.pres || '0'" slot="after"></f7-chip></f7-list-item>
    </f7-list>
  </f7-col>
</f7-row>

</f7-block>

<f7-block class="no-margin-bottom text-color-primary">Bandejas con Stock</f7-block>
<f7-block class="text-align-center" v-if="cargandoBandejas"><f7-preloader></f7-preloader><br/>Cargando bandejas con stock...</f7-block>

<f7-block v-if="!cargandoBandejas && !bandejassorted.length">No hay bandejas con stock para {{`${vacunaenfoco.vacunaPcName} (${vacunaenfoco.laboratorioName})`}}</f7-block>
<f7-card v-for="bandeja in bandejassorted" :key="bandeja.id">
  <f7-card-header class="bold">Bandeja #{{bandeja.id}} {{bandeja.metadata.descrip?`: ${bandeja.metadata.descrip}`:''}} <span><f7-link @click="clonetray(bandeja)" tooltip="Crear nueva bandeja con este mismo lote"><font-awesome-icon  icon="clone"></font-awesome-icon></f7-link> <f7-link class="margin-left" @click="abrirlog(bandeja.id)" tooltip="Ver log movimientos esta bandeja"><font-awesome-icon  icon="history"></font-awesome-icon></f7-link></span></f7-card-header>
  <f7-card-content :padding="false">
    <f7-row>
      <f7-col>
        <f7-block-title class="margin-vertical-half">Metadata</f7-block-title>
        <f7-list class="no-margin">
             <f7-list-item :title="`Ingresadas en bandeja #${bandeja.id}`">
                <font-awesome-icon slot="media" class="fa-lg text-color-primary" icon="plane-arrival"></font-awesome-icon>
                <f7-chip :text="bandeja.metadata.dosis" slot="after"></f7-chip>
              </f7-list-item>
          <f7-list-item title="Lote" :after="bandeja.metadata.loteid">
            <f7-link class="margin-left" @click="updateaSlotid(bandeja)" slot="after"><font-awesome-icon icon="edit"></font-awesome-icon></f7-link>
          </f7-list-item>
          <f7-list-item title="Fecha de vencimiento" :after="$dayjs(bandeja.metadata.exp).format('dddd D [de] MMMM [de] YYYY')"></f7-list-item>
          <f7-list-item header="Registro por" :after="$dayjs.unix(bandeja.metadata.wh).format('dddd D [de] MMMM [de] YYYY')" :title="bandeja.metadata.by"></f7-list-item>
        </f7-list>
        <f7-block v-if="provisorios.count && canIcover(bandeja.id)"><f7-button @click="coverConmigo(bandeja.id)" outline raised >Cubrir {{canIcover(bandeja.id)}} dosis faltantes con esta bandeja</f7-button></f7-block>
        </f7-col>
        <f7-col>
           <f7-list class="fixfamarg no-margin smalldividers">

           <f7-list-item divider title="Egresos"></f7-list-item>
           <f7-list-item title="Registro manual de administradas"  >
              <f7-button outline slot="after" @click="abremodalregManual(bandeja.id)" class="margin-right" color="primary">Registrar</f7-button>
              <font-awesome-icon slot="media" class="fa-lg text-color-primary" icon="prescription"></font-awesome-icon>
           <f7-chip :text="bandeja.contabilidad && bandeja.contabilidad.regmanual?getnregmanual(bandeja.contabilidad.regmanual):'0'" slot="after"></f7-chip>
            </f7-list-item>
            <f7-list-item title="Registro automático de administradas"  >
              <font-awesome-icon slot="media" class="fa-lg text-color-primary" icon="prescription"></font-awesome-icon>
              <f7-chip :text="bandeja.contabilidad && bandeja.contabilidad.administradas?Object.values(bandeja.contabilidad.administradas).length:'0'" slot="after"></f7-chip>
            </f7-list-item>
        <f7-list-item title="Otros egresos" >
          <f7-button outline slot="after" @click="abremodalperdida(bandeja.id)" class="margin-right" color="red">Registrar</f7-button>
          <font-awesome-icon slot="media" class="fa-lg text-color-red" icon="folder-minus"></font-awesome-icon>
          <f7-chip :text="bandeja.contabilidad && bandeja.contabilidad.perdidas?getnperdidas(bandeja.contabilidad.perdidas):'0'" slot="after"></f7-chip>
        </f7-list-item>
           <f7-list-item divider title="En Stock"></f7-list-item>

           <f7-list-item title="Ya reservadas via agenda web" >
              <font-awesome-icon slot="media" class="fa-lg text-color-orange" icon="user-lock"></font-awesome-icon>
             <div class="margin-right" slot="after"> <f7-button @click="recuperar(bandeja.id)" tooltip="Recuperar dosis reservadas ya administradas"  outline class="display-inline-block"><font-awesome-icon icon="reply"></font-awesome-icon></f7-button></div>
              <f7-chip :text="bandeja.contabilidad && bandeja.contabilidad.reservadasweb?Object.values(bandeja.contabilidad.reservadasweb).length:'0'" slot="after"></f7-chip>
          </f7-list-item>

        <f7-list-item title="Aún disponibles para agenda web" >
          <div class="moverstock" slot="after">
            <f7-button @click="sacarstockweb(bandeja.id)" tooltip="Mover dosis de agenda web a demanda espontánea" :class="{disabled: !banedejasVacunaFoco[bandeja.id].stock.web}" outline class="display-inline-block no-margin-right" color="primary"><font-awesome-icon icon="download"></font-awesome-icon></f7-button>
             <f7-button @click="addStockweb(bandeja.id)" tooltip="Mover dosis de demanda espontánea a agenda web" :class="{disabled: !banedejasVacunaFoco[bandeja.id].stock.pres}" outline class="margin-right display-inline-block no-margin-left" color="teal"><font-awesome-icon icon="upload"></font-awesome-icon></f7-button>
            </div>
          <font-awesome-icon slot="media" class="fa-lg text-color-teal" icon="user-clock"></font-awesome-icon>
          <f7-chip :text="bandeja.stock.web || '0'" slot="after"></f7-chip>
        </f7-list-item>

        <f7-list-item title="Aún disponibles para demanda espontánea" >
          <font-awesome-icon slot="media" class="fa-lg text-color-teal" icon="hospital-user"></font-awesome-icon>
          <f7-chip :text="bandeja.stock.pres || '0'" slot="after"></f7-chip>
        </f7-list-item>



          </f7-list>
        </f7-col>
    </f7-row>
  </f7-card-content>
</f7-card>



  <f7-popup :opened="newtraypopupopened" @popup:closed="newtraypopupopened = false">
      <f7-page v-if="newtraypopupopened">
        <f7-navbar title="Ingreso Nueva Bandeja" :subtitle="`de ${vacunaenfoco.vacunaPcName} (${vacunaenfoco.laboratorioName})`">
        </f7-navbar>
        <f7-list no-hairlines-md>
            <f7-list-input
              label="ID del lote"
              type="text"
              placeholder="ID del lote"
              clear-button
              :value="newtray.loteid"
              @input="newtray.loteid = $event.target.value"
            >
            </f7-list-input>
             <f7-list-input
              label="Cantidad de Unidosis"
              type="number"
              min="1"
              step="1"
              placeholder="500"
              clear-button
              :value="newtray.ndosis"
              @input="newtray.ndosis = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              type="datepicker"
              label="Fecha de Vencimiento"
              placeholder="Seleccione fecha"
              :value="newtray.fechaexp?[$dayjs(newtray.fechaexp).toDate()]:null"
              readonly
              :calendar-params="{ dateFormat: 'dd/mm/yyyy', locale:'es', disabled: { to: new Date()}, closeOnSelect: true, on: {
                    change: cambiafech
                  }}"
            ></f7-list-input>
            <f7-list-input
              label="Nombre origen bandeja (opcional)"
              type="text"
              placeholder="desde Vacunatorio Central 1"
              clear-button
              :value="newtray.descrip"
              @input="newtray.descrip = $event.target.value"
            >
            </f7-list-input>
        </f7-list>
        <f7-block>
          <f7-row>
            <f7-col><f7-button popup-close large outline color="gray">Cancelar</f7-button></f7-col>
            <f7-col><f7-button @click="registraBandeja()" large fill raised color="teal">Registrar</f7-button></f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-popup>


      <f7-popup :opened="!!perdida.bandejaid" @popup:closed="perdida.bandejaid = null">
      <f7-page v-if="perdida.bandejaid">
        <f7-navbar :title="`Registro otros descuentos en #${perdida.bandejaid} `+(this.banedejasVacunaFoco[perdida.bandejaid].metadata.descrip?`: ${this.banedejasVacunaFoco[perdida.bandejaid].metadata.descrip}`:'')" :subtitle="`de ${vacunaenfoco.vacunaPcName} (${vacunaenfoco.laboratorioName})`">
        </f7-navbar>
        <f7-list no-hairlines>
          <f7-list-item divider title="Indique de cual stock desea descontar las dosis"></f7-list-item>
            <f7-list-item :class="{disabled: !banedejasVacunaFoco[perdida.bandejaid].stock.web }" radio value="web" name="origen-perdida" :checked="perdida.origen=='web'"  @change="perdida.origen = $event.target.value" title="Descontar de stock para agendamiento web" :after="`Disp: ${banedejasVacunaFoco[perdida.bandejaid].stock.web}`"></f7-list-item>
            <f7-list-item radio value="pres" name="origen-perdida" :checked="perdida.origen=='pres'" @change="perdida.origen = $event.target.value" title="Descontar de stock para demanda espontánea" :after="`Disp: ${banedejasVacunaFoco[perdida.bandejaid].stock.pres}`"></f7-list-item>
            <f7-list-item divider title="Complete el registro del descuento"></f7-list-item>
          <f7-list-input
              label="Cantidad de Unidosis a descontar"
              type="number"
              min="1"
              step="1"
              placeholder="5"
              clear-button
              :value="perdida.ndosis"
              @input="perdida.ndosis = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              label="Motivo"
              type="textarea"
              placeholder="Razón de la baja"
              clear-button
              :value="perdida.razon"
              @input="perdida.razon = $event.target.value"
            >
            </f7-list-input>
        </f7-list>
        <f7-block>
          <f7-row>
            <f7-col><f7-button popup-close large outline color="gray">Cancelar</f7-button></f7-col>
            <f7-col><f7-button @click="registraPerdida()" large fill raised color="teal">Registrar</f7-button></f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-popup>

      <f7-popup :opened="!!reg_manual.bandejaid" @popup:closed="reg_manual.bandejaid = null">
      <f7-page v-if="reg_manual.bandejaid">
        <f7-navbar :title="`Dosis administradas #${reg_manual.bandejaid} `+(this.banedejasVacunaFoco[reg_manual.bandejaid].metadata.descrip?`: ${this.banedejasVacunaFoco[reg_manual.bandejaid].metadata.descrip}`:'')" :subtitle="`de ${vacunaenfoco.vacunaPcName} (${vacunaenfoco.laboratorioName})`">
        </f7-navbar>
        <f7-list no-hairlines>
          <f7-list-item divider title="Indique de cual stock desea descontar las dosis"></f7-list-item>
            <f7-list-item :class="{disabled: !banedejasVacunaFoco[reg_manual.bandejaid].stock.web }" radio value="web" name="origen-reg_manual" :checked="reg_manual.origen=='web'"  @change="reg_manual.origen = $event.target.value" title="Descontar de stock para agendamiento web" :after="`Disp: ${banedejasVacunaFoco[reg_manual.bandejaid].stock.web}`"></f7-list-item>
            <f7-list-item radio value="pres" name="origen-reg_manual" :checked="reg_manual.origen=='pres'" @change="reg_manual.origen = $event.target.value" title="Descontar de stock para demanda espontánea" :after="`Disp: ${banedejasVacunaFoco[reg_manual.bandejaid].stock.pres}`"></f7-list-item>
            <f7-list-item divider title="Complete el registro del registro"></f7-list-item>
          <f7-list-input
              label="Cantidad de Unidosis administradas"
              type="number"
              min="1"
              step="1"
              placeholder="5"
              clear-button
              :value="reg_manual.ndosis"
              @input="reg_manual.ndosis = $event.target.value"
            >
            </f7-list-input>
            <f7-list-input
              label="Nota (opcional)"
              type="textarea"
              placeholder="Nota (opcional)"
              clear-button
              :value="reg_manual.razon"
              @input="reg_manual.razon = $event.target.value"
            >
            </f7-list-input>
        </f7-list>
        <f7-block>
          <f7-row>
            <f7-col><f7-button popup-close large outline color="gray">Cancelar</f7-button></f7-col>
            <f7-col><f7-button @click="regmanual()" large fill raised color="teal">Registrar</f7-button></f7-col>
          </f7-row>
        </f7-block>
      </f7-page>
    </f7-popup>


  <f7-popup class="nextdosesoutofstck" :opened="proviosiorsOpened" @popup:closed="proviosiorsOpened = false">
      <f7-page>
        <f7-navbar title="Dosis comprometidas no existentes">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-list>
          <f7-list-item v-for="uncalen in provisorios.calendx" :key="uncalen.fch" :title="$dayjs(uncalen.fch).format('D [de] MMMM [de] YYYY')" ><f7-chip slot="after" class="bold" :text="uncalen.count"></f7-chip></f7-list-item>
        </f7-list>
      </f7-page>
    </f7-popup>


 <f7-popup class="my-log" :opened="logOpened" @popup:closed="logOpened = false">
     <logbandejas v-if="logOpened" :centerInFoco="centerInFoco" :bandejafocolog="bandejafocolog" :vacunaid="vacunaid"></logbandejas>
    </f7-popup>

  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { commonmixin } from '../mixins/common'
import logbandejas from './logbandejas.vue'

export default {
    mixins: [commonmixin],
     components:{
            logbandejas
        },
    props:['vacunaid'],
    data() {
      return {
        logOpened: false,
        bandejafocolog: null,
        proviosiorsOpened: false,
        provisorios:{
          count:0,
        calendx:{}
        },
       newtraypopupopened: false,
       cargandoBandejas: true,
       newtray:{
         loteid:'',
         ndosis:0,
         fechaexp:'',
         descrip:''
       },
       perdida:{
         bandejaid: null,
         origen:null,
         ndosis: 0,
         razon: ''
       },
       reg_manual:{
         bandejaid: null,
         origen:null,
         ndosis: 0,
         razon: ''
       }
      }
    },
    computed:{
      ...mapState(['centerInFoco','agenda_molde_activa','banedejasVacunaFoco']),
      ...mapGetters(['centerInFocoInfo']),
      vacunaenfoco(){
        return this.$store.getters.getUno('vacunas',this.vacunaid);
      },

      globalStats(){
        let stats={
          total: 0,
          nbandejas: 0,
          reservadas: 0,
          web: 0,
          pres: 0
        };
        Object.values(this.banedejasVacunaFoco).forEach(unabandeja => {
          stats.nbandejas++;
          let elstock=unabandeja.stock;
          stats.web=stats.web+elstock.web;
          stats.pres=stats.pres+elstock.pres;
          stats.reservadas=stats.reservadas+(unabandeja.contabilidad && unabandeja.contabilidad.reservadasweb?Object.values(unabandeja.contabilidad.reservadasweb).length:0);
        });
        stats.total = stats.web + stats.pres + stats.reservadas;
        return stats
      },
      bandejassorted(){
        return Object.values(this.banedejasVacunaFoco).filter(unaban=>{
          return ((unaban.stock && (unaban.stock.web>0 || unaban.stock.pres>0) ) || (unaban.contabilidad && unaban.contabilidad.reservadasweb && Object.values(unaban.contabilidad.reservadasweb).length ) )
        }).sort((a, b) => {
                  if(a.id > b.id){return -1}
                  else if(a.id < b.id){return 1}
                  else{return 0}
              });
      },
    },
      watch:{
        centerInFoco(nuevocentro){
            console.log('ATENTO, CAMBIO DE CENTRO',nuevocentro);
           this.$f7.views.main.router.back();
        }
    },
    created(){
        this.$store.commit('setObjTo',{what:'banedejasVacunaFoco',to: {}});
        console.log(this.bandejassorted,'bandejassorted')
    },
    methods:{
      clonetray(bandjeameta){
        console.log('clonando tray',bandjeameta);
        this.newtray = Object.assign({},{
         loteid: bandjeameta.metadata.loteid,
         ndosis:0,
         fechaexp: bandjeameta.metadata.exp,
         descrip: bandjeameta.metadata.descrip
       });
       this.newtraypopupopened = true;
      },
      abrirNuevoTray(){
        this.newtray = Object.assign({},{
         loteid:'',
         ndosis:0,
         fechaexp:'',
         descrip:''
       });
       this.newtraypopupopened = true;
      },
      abrirlog(bandejaid){
        this.bandejafocolog = bandejaid;
        this.logOpened=true;
      },
      updateaSlotid(bandeja){
        let bandejaid = bandeja.id;
        let loteid = bandeja.metadata.loteid;
        console.log('el lote id es',loteid)
        return this.$f7.dialog.prompt('Favor indique con mucho cuidado el número correcto de Lote de esta bandeja. Este cambio puede conducir a errores.','Cambio sensible',async (neoid)=>{
          if(!neoid){
            return this.$f7.dialog.alert('Debe indicar un id de lote')
          }
          try {
            this.$f7.dialog.preloader('Guardando...');
           let datous = await this.commonExecute({bandejaid, vacunatorioid: this.centerInFoco , nuevoloteid: neoid },'admin-cambiaLoteid',false);
            if(datous.error){
              throw new Error(datous.error.message);
            }
          this.$set(this.banedejasVacunaFoco[bandejaid].metadata,'loteid',neoid);
          this.$f7.dialog.close();
          } catch (error) {
            this.$f7.dialog.close();
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }

        },()=>{},loteid);
      },
      async recuperar(bandejaid){
        return this.$f7.dialog.confirm('Si su vacunatorio no usa esta plataforma como reigstro de administración de la vacuna, es necesario recuperar las dosis reservadas via web al stock de la bandeja, y luego regitrar manualmente cuantas dosis fueron efectivamente administradas','Recuperar dosis reservadas',async ()=>{
          console.log('las va a recupear');
           try {
          this.$f7.dialog.preloader('Reincorporando stock...');
           let datous = await this.commonExecute({bandejaid, vacunatorioid: this.centerInFoco },'admin-recuperaStocknoadministrado',false);
            if(datous.error){
              throw new Error(datous.error.message);
            }
            let recargado = datous.payload;
            console.log('recuperé ',recargado);
            //cambiar numeros esta bandeja 8080
            if(recargado && recargado>0){
              this.$set(this.banedejasVacunaFoco[bandejaid].stock,'pres',((this.banedejasVacunaFoco[bandejaid].stock.pres || 0)+recargado));
              this.$set(this.banedejasVacunaFoco[bandejaid].contabilidad,'reservadasweb',(this.banedejasVacunaFoco[bandejaid].contabilidad.reservadasweb - recargado));
            }


            this.$f7.dialog.close();
        } catch (error) {
          this.$f7.dialog.close();
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
        }
        })
      },
      async coverConmigo(trayid){
        try {
          this.$f7.dialog.preloader('Procesando...');
           let datous = await this.commonExecute({trayid,vacunatorioid: this.centerInFoco, vacunaid: this.vacunaid },'admin-cubreprovisoriosconbandeja',false);
            if(datous.error){
              throw new Error(datous.error.message);
            }
            this.$f7.dialog.close();
             this.cargandoBandejas=true;
            this.$store.commit('setObjTo',{what:'banedejasVacunaFoco',to: {}});
           let datoustwo = await this.commonExecute({vacunatorioid: this.centerInFoco, vacunaid: this.vacunaid },'admin-traeStockVacunaVacunatorio',false);
            if(datoustwo.error){
              throw new Error(datoustwo.error.message);
            }
            this.provisorios = Object.assign({}, datoustwo.payload.provisorias);
            this.$store.commit('setObjTo',{what:'banedejasVacunaFoco',to: datoustwo.payload.bandejas});
           this.cargandoBandejas=false;
        } catch (error) {
          this.$f7.dialog.close();
            this.cargandoBandejas=false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
        }
      },
      canIcover(bandejaid){
        let miexpiracion = this.banedejasVacunaFoco[bandejaid].metadata.exp;
        let cubribles = 0;
        if(this.banedejasVacunaFoco[bandejaid].stock && (this.banedejasVacunaFoco[bandejaid].stock.web>0 || this.banedejasVacunaFoco[bandejaid].stock.pres>0)){
          Object.values(this.provisorios.calendx).forEach(unpottosave => {
            if(parseInt(unpottosave.fch) <= parseInt(miexpiracion)){
              cubribles+=unpottosave.count;
            }
          });
        }
        return cubribles;
      },
      regmanual(){
        console.log('Vamos a registrar esto',this.reg_manual);
        if(!this.reg_manual.origen){
          return this.$f7.dialog.alert('Debe indicar de cual stock desea descontar las dosis');
        }
        if(!this.reg_manual.ndosis || parseInt(this.reg_manual.ndosis)<1){
          return this.$f7.dialog.alert('Debe indicar el número de dosis mayor a 0');
        }
        let unidosisnumero = parseInt(this.reg_manual.ndosis);
         if(unidosisnumero > this.banedejasVacunaFoco[this.reg_manual.bandejaid].stock[this.reg_manual.origen]){
          return this.$f7.dialog.alert('Número de unidosis a registrar no puede ser mayor a la fuente a descontar.');
        }
        this.modificaStockWeb({bandejaid:this.reg_manual.bandejaid ,tipo:'registra_admin_manual',cuanto:unidosisnumero,origen:this.reg_manual.origen, razon: this.reg_manual.razon});

      },
      registraPerdida(){
        console.log('Vamos a registrar esto',this.perdida);
        if(!this.perdida.origen){
          return this.$f7.dialog.alert('Debe indicar de cual stock desea descontar las dosis');
        }
        if(!this.perdida.ndosis || parseInt(this.perdida.ndosis)<1){
          return this.$f7.dialog.alert('Debe indicar el número de dosis mayor a 0');
        }
        let unidosisnumero = parseInt(this.perdida.ndosis);
         if(unidosisnumero > this.banedejasVacunaFoco[this.perdida.bandejaid].stock[this.perdida.origen]){
          return this.$f7.dialog.alert('Número de unidosis perdidas no puede ser mayor a la fuente a descontar.');
        }
        if(!this.perdida.razon){
          return this.$f7.dialog.alert('Debe indicar un motivo de la pérdida');
        }
        this.modificaStockWeb({bandejaid:this.perdida.bandejaid ,tipo:'registra_perdida',cuanto:unidosisnumero,origen:this.perdida.origen, razon: this.perdida.razon});

      },
      abremodalregManual(bandejaid){
        this.reg_manual={
         bandejaid: bandejaid,
         origen:null,
         ndosis: 0,
         razon: ''
        };
      },
      abremodalperdida(bandejaid){
        this.perdida={
         bandejaid: bandejaid,
         origen:null,
         ndosis: 0,
         razon: ''
        };
      },
      sacarstockweb(bandejaid){
        let stockwebahora = this.banedejasVacunaFoco[bandejaid].stock.web || 0;
        return this.$f7.dialog.prompt('Indique cuantas unidosis desea rebajar de la agenda web (max '+stockwebahora+')','Indique número de unidosis a reubicar',(numero)=>{
          if(!numero || isNaN(numero) || parseInt(numero)>stockwebahora ){
            return this.$f7.dialog.alert('Debe indicar un número válido, menor o igual a '+stockwebahora)
          }
          this.modificaStockWeb({bandejaid,tipo:'resta',cuanto:parseInt(numero)});
        });
      },
      addStockweb(bandejaid){
        if(!this.agenda_molde_activa){
          return this.$f7.dialog.alert('Para poder exponer dosis para agendamiento web, primero debe declarar la semana molde de funcionamiento del vacuantorio en el menú Agenda');
        }
        let stockdisponbileesponatena = this.banedejasVacunaFoco[bandejaid].stock.pres || 0;
        return this.$f7.dialog.prompt('Indique cuantas unidosis desea disponibilizar para agenda web (max '+stockdisponbileesponatena+')','Indique número de unidosis a reubicar',(numero)=>{
          if(!numero || isNaN(numero) || parseInt(numero)>stockdisponbileesponatena ){
            return this.$f7.dialog.alert('Debe indicar un número válido, menor o igual a '+stockdisponbileesponatena)
          }
          this.modificaStockWeb({bandejaid,tipo:'suma',cuanto:parseInt(numero)});
        });
      },
      //this.modificaStockWeb({bandejaid:this.reg_manual.bandejaid ,tipo:'registra_admin_manual',cuanto:unidosisnumero,origen:this.reg_manual.origen, razon: this.reg_manual.razon});
      async modificaStockWeb({bandejaid,tipo,cuanto,origen,razon}){
         try {
          this.$f7.dialog.preloader('Actualizando stock...');
           let datous = await this.commonExecute({vacunatorioid: this.centerInFoco, bandejaid,  tipo, cuanto,origen,razon },'admin-cambiaStockWebLocal',false);
            if(datous.error){
              throw new Error(datous.error.message);
            }
          if(datous.perdidax){
            let contablid=this.banedejasVacunaFoco[bandejaid].contabilidad || {administradas:{}, reservadasweb:{}, perdidas:{} };
            let properdidas={
              perdidas: Object.assign({},contablid.perdidas || {},datous.perdidax)
            };

            this.$set(this.banedejasVacunaFoco[bandejaid].stock,this.perdida.origen,this.banedejasVacunaFoco[bandejaid].stock[this.perdida.origen]-this.perdida.ndosis)
            let contablidad = Object.assign({},this.banedejasVacunaFoco[bandejaid].contabilidad,properdidas);
            this.$store.commit('addItemNiveltree',{a1:'banedejasVacunaFoco', b2: bandejaid, c3: 'contabilidad', d4: contablidad });
            this.perdida={
              bandejaid: null,
              origen:null,
              ndosis: 0,
              razon: ''
              };
          }
          else if(datous.regmanual){
            let contablid=this.banedejasVacunaFoco[bandejaid].contabilidad || {administradas:{}, regmanual:{}, reservadasweb:{}, perdidas:{} };
            let proregmanual={
              regmanual: Object.assign({},contablid.regmanual || {},datous.regmanual)
            };

            this.$set(this.banedejasVacunaFoco[bandejaid].stock,origen,this.banedejasVacunaFoco[bandejaid].stock[origen]-cuanto)
            let contablidad = Object.assign({},this.banedejasVacunaFoco[bandejaid].contabilidad,proregmanual);
            this.$store.commit('addItemNiveltree',{a1:'banedejasVacunaFoco', b2: bandejaid, c3: 'contabilidad', d4: contablidad });
            this.reg_manual={
              bandejaid: null,
              origen:null,
              ndosis: 0,
              razon: ''
              };
          }
          else{
            this.$store.commit('addItemNiveltree',{a1:'banedejasVacunaFoco', b2: bandejaid, c3: 'stock', d4: datous.payload });
          }
          this.$f7.dialog.close();
        } catch (error) {
            this.$f7.dialog.close();
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code || 'Error');
        }
      },
      getnperdidas(peridds){
        let toalperidas = 0;
        if(peridds){
          toalperidas =  Object.values(peridds).map(per => per.count).reduce((total,unaperd) => {
            return total+parseInt(unaperd);
          });
        }
        console.log('perdidas son',toalperidas)
        return toalperidas;
      },
       getnregmanual(regmanualxs){
        let totalregmanual = 0;
        if(regmanualxs){
          totalregmanual =  Object.values(regmanualxs).map(per => per.count).reduce((total,unaperd) => {
            return total+parseInt(unaperd);
          });
        }
        console.log('perdidas son',totalregmanual)
        return totalregmanual;
      },
        cambiafech(calendar, value){
        console.log('cambiando fecha',value[0])
         this.newtray.fechaexp = this.$dayjs(value[0]).format('YYYYMMDD');
         console.log('neuva fecha', this.newtray.fechaexp);
      },
      async traerBanejasConStock(){
        try {
          this.cargandoBandejas=true;

           let datous = await this.commonExecute({vacunatorioid: this.centerInFoco, vacunaid: this.vacunaid },'admin-traeStockVacunaVacunatorio',false);
            if(datous.error){
              throw new Error(datous.error.message);
            }
            this.provisorios = Object.assign({}, datous.payload.provisorias);
            this.$store.commit('setObjTo',{what:'banedejasVacunaFoco',to: datous.payload.bandejas});
            console.log(this.banedejasVacunaFoco);
           this.cargandoBandejas=false;
        } catch (error) {
            this.cargandoBandejas=false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
        }
      },
       async registraBandeja(){
          try {
            if(!this.newtray.loteid){
              return this.$f7.dialog.alert('Debe indicar ID de lote');
            }
            if(parseInt(this.newtray.ndosis)<1){
              return this.$f7.dialog.alert('Numero de dosis debe ser mayor a 0');
            }
            if(!this.newtray.fechaexp || !this.$dayjs(this.newtray.fechaexp).isValid()){
              return this.$f7.dialog.alert('Debe indicar una fecha de expiración válida');
            }
           this.$f7.dialog.preloader('Registrando nueva bandeja...');
           let datous = await this.commonExecute({vacunatorioid: this.centerInFoco, vacunaid: this.vacunaid, ndosis: this.newtray.ndosis, exp: this.newtray.fechaexp, descrip: this.newtray.descrip, idlote: this.newtray.loteid },'admin-registrarStock',false);
            if(datous.error){
              throw new Error(datous.error.message);
            }
            console.log(datous.payload);
            this.$store.commit('updtObjProperty',{what:'banedejasVacunaFoco',to: datous.payload, idx: datous.payload.metadata.id});
            console.log(this.banedejasVacunaFoco);
            this.$f7.dialog.close();
            this.avisaOk();
            this.newtraypopupopened = false;
            this.newtray={
                  loteid:'',
                  ndosis:0,
                  fechaexp:''
                };
             } catch (error) {
            this.$f7.dialog.close();
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
    }
}
</script>
<style>
.ngrande{
  font-size:50px;
  margin:0px!important;
  line-height: 50px;
}
.unidosis{
  font-size:13px;
    margin:0px!important;

}
.nbandejas{
  font-size:18px;
   margin-top:0px!important;
  margin-bottom: 10px!important;
}
.la_alerta{
      background: #ffe6e6;
    color: #690000;
    padding: 10px 20px;
    margin: 20px;
    text-align: center;
    font-weight: bold;
}
.moverstock{
  position: relative;
    top: 25px;
    z-index: 99999;
}
.moverstock a.button{
  background: #FFF!important;
}
</style>