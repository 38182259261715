<template>
  <f7-page name="comprobanesolnac">
    <f7-navbar title="Solicitudes nacionales" subtitle="Validación vacunas" back-link="Back"></f7-navbar>
    <f7-block v-if="!solicitudes.length">No hay solicitudes aprobadas para el paciente consultado</f7-block>
    <one-solicitud v-for="(solicitud, indx) in solicitudes" :key="indx" :solicitud="solicitud"></one-solicitud>
  </f7-page>
</template>

<script>
import { commonmixin } from '../mixins/common'
import oneSolicitud from '../components/oneSolicitud.vue';

export default {
  components: {
    oneSolicitud
  },
  mixins: [commonmixin],
  
  props: {
    solicitudes: {
      type: Array,
      default: () => []
    }
  }
}
</script>