<template>
    <f7-page name="cuenta" @page:beforein="checkdixpriv()">
        <!-- Top Navbar -->
        <f7-navbar :title="admn.em" :subtitle="formatRut(admn.id)" back-link="Back"></f7-navbar>

        <f7-block class="text-align-center no-margin-bottom">
            <img class="superavatar" src="../static/heros/avatar.jpg" /><br />
            <f7-chip media-bg-color="primary" :text="admn.em"
                ><font-awesome-icon icon="user-circle" class="fa-lg" slot="media"></font-awesome-icon
            ></f7-chip>
        </f7-block>
        <f7-block class="margin-bottom no-margin-top"><f7-block-title>Información del usuario</f7-block-title></f7-block>
        <f7-list inset class="no-margin-top">
            <f7-list-item
                v-for="(campo, indx) in flds"
                @click="updateValue(indx)"
                :key="indx"
                link="#"
                :header="campo"
                :title="indx == 'id' ? formatRut(admn[indx]) : admn[indx]"
            >
                <f7-preloader slot="after" v-if="updt[indx]"></f7-preloader>
                <font-awesome-icon v-else slot="after" icon="pencil-alt"></font-awesome-icon>
            </f7-list-item>
        </f7-list>
        <f7-block class="margin-bottom"><f7-block-title>Privilegios en Admin-web</f7-block-title></f7-block>
        <f7-block class="text-align-center" v-if="!Object.keys(privsCentrales).length"
            ><f7-preloader></f7-preloader><br />Cargando privilegios</f7-block
        >
        <f7-list class="no-margin-top" inset>
            <f7-list-item :footer="unpriv.descr" v-for="unpriv in privsCentrales" :key="unpriv.id" :title="unpriv.id">
                <f7-preloader slot="after" class="margin-right-half" v-if="updt[unpriv.id]"></f7-preloader>
                <f7-toggle
                    slot="after"
                    @toggle:change="cambiando($event, unpriv.id)"
                    color="teal"
                    :checked="admn.prv && admn.prv[unpriv.id]"
                ></f7-toggle>
            </f7-list-item>
        </f7-list>
        <f7-block class="margin-bottom"><f7-block-title>Privilegios por Vacunatorio</f7-block-title></f7-block>
        <f7-block class="text-align-center" v-if="cargandoPrivsBysite"
            ><f7-preloader></f7-preloader><br />Cargando privilegios por vacunatorio</f7-block
        >
        <f7-block class="text-align-center" v-else-if="!Object.values(susprivsbysite).length">Usuario sin privilegios en un vacunatorio</f7-block>

        <f7-list class="no-margin-top" accordion-list inset>
            <f7-list-item
                v-for="(vacunatorio, vacid) in susprivsbysite"
                :key="vacid"
                accordion-item
                :title="vacunatorio.elvacunatorio ? vacunatorio.elvacunatorio.name : vacid"
                :footer="
                    vacunatorio.elvacunatorio
                        ? `${vacunatorio.elvacunatorio.address.name} (${vacunatorio.elvacunatorio.address.provname}), ${vacunatorio.elvacunatorio.address.region}`
                        : 'Cargando...'
                "
                :class="{ 'skeleton-text skeleton-effect-blink': !vacunatorio.elvacunatorio }"
            >
                <f7-accordion-content>
                    <f7-list-item :footer="unpriv.descr" v-for="unpriv in privsPorVacunatorio" :key="unpriv.id" :title="unpriv.id">
                        <f7-preloader slot="after" class="margin-right-half" v-if="actualizando == vacid + unpriv.id"></f7-preloader>
                        <f7-toggle
                            slot="after"
                            @toggle:change="cambiandoPrivVacunat($event, unpriv.id, vacid)"
                            color="teal"
                            :checked="vacunatorio.prv[unpriv.id]"
                        ></f7-toggle>
                    </f7-list-item>
                </f7-accordion-content>
            </f7-list-item>
        </f7-list>

        <f7-block class="margin-top"
            ><f7-button @click="abrirprivporvac()"
                ><font-awesome-icon icon="plus"></font-awesome-icon> Agregar privilegios en un vacunatorio</f7-button
            ></f7-block
        >

        <f7-popup class="privsenvacunat" :opened="vacprivsopened" @popup:closed="vacprivsopened = false">
            <privs-crear v-if="vacprivsopened" :uid="admn.uid" :susprivsbysite="susprivsbysite" @nuevosPrivsVacs="nuevosprivsenvac"></privs-crear>
        </f7-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import privsCrear from "./privs_vac_crear.vue";
export default {
    mixins: [commonmixin],
    components: {
        privsCrear,
    },
    props: ["admn"],
    data() {
        return {
            flds: {
                //em: 'email de acceso',
                id: "RUT",
                nms: "Nombre(s)",
                ap1: "Primer Apellido",
                ap2: "Segundo Apellido",
            },
            updt: {
                id: false,
                em: false,
                nms: false,
                ap1: false,
                ap2: false,
            },
            vacprivsopened: false,
            susprivsbysite: {},
            cargandoPrivsBysite: false,
            actualizando: null,
        };
    },
    computed: {
        ...mapState(["privsCentrales", "privsPorVacunatorio"]),
        ...mapGetters(["vacunatorios"]),
    },
    mounted() {},
    methods: {
        nuevosprivsenvac(payerloadx) {
            console.log("nuevos privilegios", payerloadx);
            this.vacprivsopened = false;
            this.addVacunatToUser(payerloadx);
        },
        abrirprivporvac() {
            return this.getMeThese(["vacunatorios"])
                .then(() => {
                    this.vacprivsopened = true;
                })
                .catch(error => {
                    this.$f7.dialog.close();
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                });
        },
        async cambiandoPrivVacunat(nuevovalor, privid, vacid) {
            try {
                this.actualizando = vacid + privid;
                let datous = await this.commonExecute(
                    { uid: this.admn.uid, cual: privid, neox: nuevovalor, vacunatorioid: vacid },
                    "admin-daPrivsEnVacunatorio",
                    false
                );
                this.actualizando = null;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                this.$set(this.susprivsbysite[vacid], privid, nuevovalor);
                this.avisaOk();
            } catch (error) {
                this.actualizando = null;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        cambiando(nuevovalor, privid) {
            console.log("Cambiando un privilegio", nuevovalor, privid);
            this.guardarCambioPermiso({ uid: this.admn.uid, cual: privid, neox: nuevovalor });
        },
        async guardarCambioPermiso({ uid, cual, neox }) {
            try {
                let neoprivstatus = {};
                neoprivstatus[cual] = true;
                this.updt = Object.assign({}, this.updt, neoprivstatus);
                let datous = await this.commonExecute({ uid, cual, neox }, "admin-editaCuentaPrivs", false);
                this.updt[cual] = false;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log("creacion ok", datous);
                if (!this.admn.prv) {
                    this.admn.prv = {};
                }
                let neov = {};
                neov[cual] = neox;
                this.admn.prv = Object.assign({}, this.admn.prv, neov);
                this.avisaOk();
            } catch (error) {
                this.updt[cual] = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        updateValue(cual) {
            return this.$f7.dialog.prompt(
                "Indique un nuevo " + this.flds[cual],
                cambio => {
                    if (!cambio) {
                        return this.$f7.dialog.alert("Nueva propiedad no puede ser vacía", "Error");
                    }
                    console.log("nuevo es", cual, cambio);
                    this.guardarCambio({
                        uid: this.admn.uid,
                        cual: cual,
                        valor: cambio.trim(),
                    });
                },
                () => {},
                this.admn[cual]
            );
        },
        async guardarCambio({ uid, cual, valor }) {
            try {
                this.updt[cual] = true;
                let datous = await this.commonExecute({ uid, cual, valor }, "admin-editaCuentaInfo", false);
                this.updt[cual] = false;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log("creacion ok", datous);
                this.admn[cual] = valor;
                this.avisaOk();
            } catch (error) {
                this.updt[cual] = false;
                if (error.message == "The email address is improperly formatted.") {
                    error.message = "El email no tiene un formato válido (direccion@dominio.extensión)";
                } else if (error.message == "The email address is already in use by another account.") {
                    error.message = "El email ya se encuentra en uso por otra cuenta en vacunator.io";
                }
                console.error(error.code, error.message);
                this.$f7.dialog.alert(error.message, error.code || "Error");
            }
        },
        async checkdixpriv() {
            try {
                this.cargandoPrivsBysite = true;
                let promesex = [
                    this.$firebase
                        .database()
                        .ref("privsByCenter")
                        .orderByChild("uid")
                        .equalTo(this.admn.uid)
                        .once("value"),
                ];
                if (!Object.keys(this.privsCentrales).length) {
                    promesex.push(
                        this.$firebase
                            .database()
                            .ref("dix")
                            .once("value")
                    );
                }
                let multisnapsx = await Promise.all(promesex);
                if (multisnapsx[1]) {
                    let privs = multisnapsx[1];
                    this.$store.commit("setWhatTo", { what: "privsCentrales", to: privs.child("privs").val() || {} });
                    this.$store.commit("setWhatTo", { what: "privsPorVacunatorio", to: privs.child("vacprivs").val() });
                }
                if (multisnapsx[0].exists()) {
                    multisnapsx[0].forEach(childsnap => {
                        this.addVacunatToUser(childsnap.val());
                    });
                }
                this.cargandoPrivsBysite = false;
            } catch (error) {
                this.cargandoPrivsBysite = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        addVacunatToUser(vacunatx) {
            if (vacunatx.prv) {
                let elvacunatorio = this.$store.getters.getUno("vacunatorios", vacunatx.cid);
                if (elvacunatorio) {
                    vacunatx.elvacunatorio = elvacunatorio;
                }
                this.$set(this.susprivsbysite, vacunatx.cid, vacunatx);
                if (!elvacunatorio) {
                    console.log("pidiendo info vacunatorio");
                    return this.$firebase
                        .database()
                        .ref("vacunatorios/" + vacunatx.cid)
                        .once("value")
                        .then(snpsht => {
                            console.log("llegando info vacuntaorio");
                            let vacunatinfo = snpsht.val();
                            this.$set(this.susprivsbysite[vacunatx.cid], "elvacunatorio", vacunatinfo);
                            this.$store.commit("updtObjProperty", { what: "vacunatorios", to: vacunatinfo });
                        })
                        .catch(error => {
                            console.log("error de retrieval de vacunatorio", error);
                        });
                }
            }
        },
    },
};
</script>
<style>
.superavatar {
    width: 150px;
    border-radius: 50%;
    border: 6px solid white;
}
</style>
