<template>
    <div>
        <div class="display-flex justify-content-end margin-bottom">
            <f7-button @click="isCreateRolePopupOpened = true" fill>
                <font-awesome-icon :icon="['fa', 'plus']" class="margin-half-right"></font-awesome-icon>
                Crear nuevo rol
            </f7-button>
        </div>
        <table>
            <thead>
                <tr>
                    <th class="text-align-center">Nombre del rol</th>
                    <th class="text-align-center">Descripción</th>
                    <th class="text-align-center">Estado</th>
                    <th class="text-align-center">Tipo de rol</th>
                    <th class="text-align-center">Acción</th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr>
                    <td colspan="4" class="text-align-center">
                        <f7-preloader></f7-preloader>
                    </td>
                </tr>
            </tbody>
            <tbody v-else-if="$store.state.roles.length">
                <tr v-for="role in $store.state.roles" :key="role.id">
                    <td>{{ role.name }}</td>
                    <td>{{ role.description }}</td>
                    <td>{{ role.isActive === true ? "Activo" : "No activo" }}</td>
                    <td>{{ capitalize(role.type) }}</td>
                    <td>
                        <div class="display-flex justify-content-center">
                            <f7-button @click="handleEditRole(role)" tooltip="Editar rol">
                                <font-awesome-icon :icon="['fa', 'edit']" class="margin-half-right"></font-awesome-icon>
                            </f7-button>
                            <!-- <f7-toggle :checked="role.isActive" @change="changeRoleActiveStatus(role.id, $event.target.checked)"></f7-toggle> -->

                            <f7-button v-if="role.isActive" @click="changeRoleActiveStatus(role.id, false)" tooltip="Inactivar rol">
                                <font-awesome-icon :icon="['fa', 'toggle-on']" class="margin-half-right fa-lg"></font-awesome-icon>
                            </f7-button>
                            <f7-button v-else @click="changeRoleActiveStatus(role.id, true)" tooltip="Activar rol">
                                <font-awesome-icon :icon="['fa', 'toggle-off']" class="margin-half-right fa-lg"></font-awesome-icon>
                            </f7-button>
                            <f7-button @click="deleteRole(role.id)" tooltip="Eliminar rol">
                                <font-awesome-icon :icon="['fa', 'trash']" class="margin-half-right"></font-awesome-icon>
                            </f7-button>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="4" class="text-align-center">
                        No hay roles registrados todavía.
                        <f7-link @click="isCreateRolePopupOpened = true" class="no-ripple">Haga clic aquí para crear uno.</f7-link>
                    </td>
                </tr>
            </tbody>
        </table>

        <CreateRolePopup :opened="isCreateRolePopupOpened" @closed="handleCreateRolePopupClosed" :editing-role="editingRole" @created="handleRoleCreated" @edited="handleRoleEdited" />
    </div>
</template>

<script>
import CreateRolePopup from "../components/CreateRolePopup.vue";
import { commonmixin } from "../mixins/common";
export default {
    mixins: [commonmixin],

    components: { CreateRolePopup },

    data() {
        return {
            roles: [],
            loading: false,

            editingRole: null,
            isCreateRolePopupOpened: false,
        };
    },

    mounted() {
        this.getRoles();
        this.getPrivileges();
    },

    methods: {
        async getRoles() {
            try {
                this.loading = true;

                const response = await this.commonExecute({}, "admin-getRoles", false);

                if (response.error) {
                    throw new Error(response.error);
                }

                this.$store.commit("setWhatTo", { what: "roles", to: response.roles });
                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.error(error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },

        async getPrivileges() {
            try {
                this.loading = true;

                const response = await this.commonExecute({}, "admin-getPrivileges", false);

                if (response.error) {
                    throw new Error(response.error);
                }

                this.$store.commit("setObjTo", { what: "privsCentrales", to: response.privileges });

                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.error(error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },

        async deleteRole(roleId) {
            this.$f7.dialog.confirm(
                "Al eliminar un rol, se quitarán todos los privilegios de usuarios asociados a este rol. ¿Desea eliminarlo de todas formas?",
                "CUIDADO: Acción crítica",
                async () => {
                    try {
                        this.$f7.dialog.preloader("Eliminando rol...");

                        const response = await this.commonExecute({ roleId }, "admin-deleteRole", false);

                        if (response.error) {
                            throw new Error(response.error);
                        }

                        this.$store.commit("deleteRole", roleId);

                        this.$f7.dialog.close();
                    } catch (error) {
                        this.loading = false;
                        console.error(error.message);
                        this.$f7.dialog.close();
                        this.$f7.dialog.alert(error.message);
                    }
                }
            );
        },

        async changeRoleActiveStatus(roleId, isActive) {
            const confirmationMessage = isActive
                ? "Al activar un rol, se asignarán todos los privilegios de usuarios asociados a este rol. ¿Desea activarlo de todas formas?"
                : "Al inactivar un rol, se quitarán todos los privilegios de usuarios asociados a este rol. ¿Desea inactivarlo de todas formas?";
            const actionText = isActive ? "Activando" : "Inactivando";

            this.$f7.dialog.confirm(confirmationMessage, "CUIDADO: Acción crítica", async () => {
                try {
                    this.$f7.dialog.preloader(`${actionText} rol...`);

                    const response = await this.commonExecute({ roleId, isActive }, "admin-changeRoleActiveStatus", false);

                    if (response.error) {
                        throw new Error(response.error);
                    }

                    this.$store.commit("changeRoleActiveStatus", { roleId, isActive });

                    this.$f7.dialog.close();
                } catch (error) {
                    this.loading = false;
                    console.error(error.message);
                    this.$f7.dialog.close();
                    this.$f7.dialog.alert(error.message);
                }
            });
        },

        handleEditRole(role) {
            this.editingRole = role;
            this.isCreateRolePopupOpened = true;
        },

        handleRoleEdited(role) {
            this.$store.commit("editRole", role);
            this.editingRole = null;
            this.isCreateRolePopupOpened = false;
        },

        handleRoleCreated(role) {
            this.$store.commit("addRole", role);
            this.isCreateRolePopupOpened = false;
        },

        handleCreateRolePopupClosed() {
            this.editingRole = null;
            this.isCreateRolePopupOpened = false;
        },

        capitalize(string) {
            return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        },
    },
};
</script>

<style>
table {
    width: 100%;
    border-collapse: collapse;
}
th,
td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}
th {
    background-color: var(--f7-theme-color);
    color: #fff;
    font-weight: bold;
    border-bottom: 2px solid #999;
}
tr:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
</style>
