<template>
 <f7-card >
    <f7-card-header class="bold">{{campana.nombre}}</f7-card-header>
    <f7-card-content>
            <p class="no-margin text-color-primary">{{campana.ini?('Desde el '+$dayjs(campana.ini).format('DD [de] MMMM [de] YYYY')):'Fecha de inicio aún no configurada'}} {{campana.fin?(' hasta el '+$dayjs(campana.fin).format('DD [de] MMMM [de] YYYY')):''}}</p>
        <p class="no-margin-top">{{campana.descp?campana.descp:'Descripción aún no configurada'}}</p>
    </f7-card-content>
    <f7-card-footer><span v-if="!centralAdminPrivs.campanas"></span><f7-button v-if="centralAdminPrivs.reportes" @click="andaacampana(campana.id,true)" fill color="teal">Reportes</f7-button><span v-else></span><f7-button v-if="centralAdminPrivs.campanas" @click="andaacampana(campana.id,false)" fill color="purple" raised>Configurar</f7-button></f7-card-footer>
</f7-card>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    props:['campana'],
    computed:{
        ...mapGetters(['centralAdminPrivs'])
    },
     methods:{
         andaacampana(campanaid,reporte=false){
             console.log(campanaid,reporte);
             let emiteesto={campanaid,reporte};
            this.$emit('abrecampana',emiteesto);
         }
     }
}
</script>