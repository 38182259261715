<template>
  <f7-page  @page:beforein="cargaSemanaMolde(false)" name="center_agenda">
    <f7-navbar >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title :subtitle="`Gestión de horas en ${centerInFocoInfo.name}`" title="Agenda"></f7-nav-title>
    </f7-navbar>
   <f7-block>
           <f7-row>
            <f7-col width="30">
                  <div class="lekalendar bg-color-white esteacl"></div>
                    <f7-block class="margin-bottom text-align-center text-color-primary bold">Resumen de {{$dayjs(hoyx).format("DD/MM/YY")}}</f7-block>
                    <f7-list class="margin-top">
                        <f7-list-item  title="Agendados" ><f7-preloader v-if="cargandoDia" slot="after"></f7-preloader><span v-else slot="after">{{statsFoco.agendados}}</span></f7-list-item>
                        <f7-list-item title="Confirmados" ><f7-preloader v-if="cargandoDia" slot="after"></f7-preloader><span v-else slot="after">{{statsFoco.confirmados}}</span></f7-list-item>
                    </f7-list>

                  <f7-block class="margin-bottom text-align-center text-color-primary bold">Semana molde</f7-block>
                  <f7-block v-if="!agenda_molde_activa">Este vacunatorio no cuenta con una semana molde configurada. Para abrir su agenda, debe configurar una semana molde.</f7-block>
                    <f7-button href="/center/center_semana_molde/" fill raised large>Configurar semana molde</f7-button>
            </f7-col>
            <f7-col width="70">
            <f7-card>
                <f7-progressbar v-if="cargandoDia" infinite></f7-progressbar>
                <div v-else class="prelholder"></div>
            <f7-card-header>
                 <f7-link @click="cambiardia(false)"><f7-icon material="chevron_left"></f7-icon></f7-link>
                <span>{{$dayjs(hoyx).format("dddd DD [de] MMMM [de] YYYY")}}</span>
                <f7-link @click="cambiardia(true)"><f7-icon material="chevron_right"></f7-icon></f7-link>
            </f7-card-header>
                <f7-searchbar
                class="no-margin"
                :custom-search="true"
                @searchbar:search="buscand"
                placeholder="Busqueda por apellido, nombre o RUT"
                :backdrop="false"
            ></f7-searchbar>
            <f7-card-content :padding="false" class="scrollagenda ">
                <f7-block v-if="!agenda_molde_activa" class="abriragenda" >Debe configurar una semana molde para gestionar su agenda web.<br/><br/></f7-block>
                <template v-else-if="diaenFoco && Object.keys(diaenFoco).length">
                 <f7-list v-if="elquery && !Object.keys(slotes).length">
                    <f7-list-item :title="`Sin resultados para ${elquery}`"></f7-list-item>
                </f7-list>
                <f7-list 
                media-list
                 class="fixfamarg listagendax searchbar-found no-margin-vertical">
<f7-list-group media-list v-for="unhini in slotes" :key="`${hoyx}-${unhini.i}`" >
    <f7-list-item  class="listrgrupsmall" :title="unhini.i" group-title></f7-list-item>

                 <f7-list-item
                    v-for="unslot in unhini.c"
                    :key="`${hoyx}_${unhini.i}${unslot.i}`"
                    :class="{blokeadage:unslot.p && unslot.p=='bloq', titlebold: unslot.p, agendado: unslot.p && unslot.p!=='bloq' }"
                    :footer="unslot.meta?unslot.meta.campinfo.fraseqeuma:null" 
                    :subtitle="unslot.meta?unslot.meta.campinfo.vacunas : null"
                    :header="unslot.meta?`${unslot.meta.userinfo.idenType} ${unslot.meta.userinfo.rawIdentity}`: `slot ${unslot.i}`" 
                    :title="unslot.meta? `${unslot.meta.userinfo.nombres} ${unslot.meta.userinfo.apellido1} ${unslot.meta.userinfo.apellido2 || ''} (${suageStrg(unslot.meta.userinfo.fechanac)})`: (unslot.p?(unslot.p=='bloq'?'Bloqueado':unslot.p): 'Cupo disponible')" 
                    >
                    <div class="text-align-center" slot="media">
                        {{unhini.i}}
                        <div  v-if="unslot.p && unslot.p!=='bloq'" > <font-awesome-icon class="fa-2x" :class="{'text-color-gray': (!unslot.meta || unslot.meta.userinfo.sexo=='I'), 'text-color-pink': ( unslot.meta && unslot.meta.userinfo.sexo=='F'), 'text-color-blue': ( unslot.meta && unslot.meta.userinfo.sexo=='M')  }"  icon="user-circle"  ></font-awesome-icon></div>
                    <div v-if="unslot.meta && unslot.meta.cnf"><font-awesome-icon icon="check-double" class="text-color-teal"></font-awesome-icon></div>
                    </div>
                   <span slot="after">
                        <f7-button raised v-if="unslot.meta && unslot.meta.userinfo" @click="revokaslot(unslot.i,unhini.i,false)" class="text-color-gray display-inline-block" tooltip="Desagendar paciente"><font-awesome-icon icon="user-minus"></font-awesome-icon></f7-button>
                        <f7-button raised v-if="unslot.p=='bloq'" @click="revokaslot(unslot.i,unhini.i,false)" class="text-color-gray display-inline-block" tooltip="Re-abrir cupo"><font-awesome-icon icon="lock-open"></font-awesome-icon></f7-button>
                        <f7-button raised v-else @click="revokaslot(unslot.i,unhini.i,true)" class="text-color-gray display-inline-block" tooltip="Bloquear cupo"><font-awesome-icon icon="lock"></font-awesome-icon></f7-button>
                    </span>
                    </f7-list-item>
</f7-list-group>
                </f7-list>
                </template>
            </f7-card-content>
            <f7-card-footer><f7-button outline @click="getEsteDia(hoyx)"><font-awesome-icon icon="sync"></font-awesome-icon> Actualizar</f7-button><f7-button popover-open=".popveractionagnd" fill color="teal" raised><font-awesome-icon icon="tools"></font-awesome-icon> Acciones</f7-button> </f7-card-footer>
            </f7-card>
            </f7-col>
        </f7-row>

        </f7-block>


<f7-popover class="popveractionagnd">
    <f7-list>
      <f7-list-item divider :title="`${$dayjs(hoyx).format('dddd DD [de] MMMM [de] YYYY')}`"></f7-list-item>
      <f7-list-item link="#" v-if="diaenFoco && Object.keys(diaenFoco).length" @click="descargarAgenda()" popover-close title="Descargar Excel"><font-awesome-icon slot="after" icon="cloud-download-alt"></font-awesome-icon></f7-list-item>
      <f7-list-item link="#" @click="confirmEliminaTodo()" popover-close title="Bloquear el día"><font-awesome-icon slot="after" icon="lock"></font-awesome-icon></f7-list-item>
      <f7-list-item link="#" @click="desbloqueadia()" popover-close title="Desbloquear el día"><font-awesome-icon slot="after" icon="lock-open"></font-awesome-icon></f7-list-item>
    </f7-list>
  </f7-popover>


  </f7-page>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { commonmixin } from '../mixins/common'
import axios from 'axios';

export default {
    mixins: [commonmixin],
    data() {
      return {
         calendarInline: null,
        cargandoDia: false,
        statsFoco:{
            agendados:0,
            confirmados:0
        },
        hoyx: this.$dayjs().format('YYYYMMDD'),
        diaenFoco:{},
        elquery:'',
        slotes:[]
      }
    },
    computed:{
     ...mapState(['centerInFoco','dateFoco','agenda_molde_activa']),
      ...mapGetters(['centerInFocoInfo']),
    },
     watch:{
        hoyx(nuoe){
            console.log('cambindo hoy');
            this.$store.commit('setWhatTo',{what:'dateFoco', to: nuoe})
        },
        centerInFoco(nuevocentro){
            console.log('ATENTO, CAMBIO DE CENTRO',nuevocentro);
            this.cargaSemanaMolde(true);
        }
    },
      created(){
        //this.dateFoco
        this.hoyx = this.dateFoco || this.$dayjs().format('YYYYMMDD');
    },
      methods:{
          confirmEliminaTodo(){
              return this.$f7.dialog.confirm('Confirme que desea bloquear todos los slots del dia. Esto cancelará además cualquier hora que exista.','Confirme acción',()=>{
                  this.bloqunlocktodos();
              })
          },
        async desbloqueadia(){
              try {
                  this.$f7.dialog.preloader('Desbloqueando dia completo');
                  let ushers=[];
                Object.values(this.diaenFoco).forEach(unhnini => {
                    let suscs = unhnini.c || [];
                    suscs.forEach(slot => {
                        if(slot.p=='bloq' ){
                            ushers.push( this.commonExecute({borra: false,concurrenciaid: slot.i, anomesdia: this.hoyx, hini: unhnini.i , vacunatorioid: this.centerInFoco},'admin-modificaConcurrencia',false) )
                        }
                    });
                });
                let multipromex =  await Promise.all(ushers);
                let poterrores=[];
                multipromex.forEach(datous => {
                    if(datous.error){
                        poterrores.push(datous.error.message);
                    }
                });
                if(poterrores.length){
                    throw new Error(poterrores.join(','));
                }
                Object.values(this.diaenFoco).forEach(unhnini => {
                    let suscs = unhnini.c || [];
                    let hubocambioaqui = false;
                    suscs.forEach(slot => {
                       if(slot.p=='bloq' ){
                            hubocambioaqui=true;
                              //borrar el p 
                            this.$delete(this.diaenFoco[unhnini.i].c[slot.i],'p');
                        }
                    });
                    if(hubocambioaqui){
                        if(suscs.length){
                            unhnini.publicados=suscs.filter(uns=>{
                                return !uns.p || uns.p!='bloq'
                            }).length;
                            unhnini.agendados = suscs.filter(unc=>{
                                return unc.p && unc.p!='bloq';
                            }).length;
                        }
                        else{
                            unhnini.publicados=0;
                            unhnini.agendados=0;
                        }
                    }
                });
               this.$f7.dialog.close();
              } catch (error) {
                  this.$f7.dialog.close();
                  console.log(error);
                  return this.$f7.dialog.alert(error.message,error.code || 'Error')
              }
          },
          async bloqunlocktodos(){
              try {
                  this.$f7.dialog.preloader('Bloqueando dia completo');
                  let ushers=[];
                Object.values(this.diaenFoco).forEach(unhnini => {
                    let suscs = unhnini.c || [];
                    suscs.forEach(slot => {
                        if(!slot.p || slot.p!='bloq' ){
                            ushers.push( this.commonExecute({borra: true,concurrenciaid: slot.i, anomesdia: this.hoyx, hini: unhnini.i , vacunatorioid: this.centerInFoco},'admin-modificaConcurrencia',false) )
                        }
                    });
                });
                let multipromex =  await Promise.all(ushers);
                let poterrores=[];
                multipromex.forEach(datous => {
                    if(datous.error){
                        poterrores.push(datous.error.message);
                    }
                });
                if(poterrores.length){
                    throw new Error(poterrores.join(','));
                }
                Object.values(this.diaenFoco).forEach(unhnini => {
                    let suscs = unhnini.c || [];
                    let hubocambioaqui = false;
                    suscs.forEach(slot => {
                        if(!slot.p || slot.p!='bloq' ){
                            hubocambioaqui=true;
                             if(!slot.p){
                                    this.$set(this.diaenFoco[unhnini.i].c,slot.i,{i:slot.i,p:'bloq'});
                                }
                                else{
                                    this.$set(this.diaenFoco[unhnini.i].c[slot.i],'p','bloq');
                                }
                        }
                    });
                    if(hubocambioaqui){
                        if(suscs.length){
                            unhnini.publicados=suscs.filter(uns=>{
                                return !uns.p || uns.p!='bloq'
                            }).length;
                            unhnini.agendados = suscs.filter(unc=>{
                                return unc.p && unc.p!='bloq';
                            }).length;
                        }
                        else{
                            unhnini.publicados=0;
                            unhnini.agendados=0;
                        }
                    }
                });
               this.$f7.dialog.close();
              } catch (error) {
                  this.$f7.dialog.close();
                  console.log(error);
                  return this.$f7.dialog.alert(error.message,error.code || 'Error')
              }
          },
          buscand(sb,qr,prev){
               this.elquery=qr.toLowerCase();
               console.log('buscando ',this.elquery)
              if(!this.elquery){
                this.slotes = Object.values(this.diaenFoco);
              }
              else{
                let arrayado=Object.values(this.diaenFoco);
                let neoslotes=[];
                arrayado.forEach(unhini => {
                        let suscs = unhini.c || [];
                        let filtadso = suscs.filter(unc => {
                            if(unc.meta){
                                let paxstin=unc.meta.userinfo?(`${unc.meta.userinfo.apellido1} ${unc.meta.userinfo.apellido2 || ''} ${unc.meta.userinfo.nombres} ${unc.meta.userinfo.identity}`).toLowerCase() :'';
                                console.log('buscando en ',paxstin)
                                return paxstin.includes(this.elquery);
                            }
                            else{
                                return false;
                            }
                        });
                    if(filtadso.length){
                        neoslotes.push({agendados: unhini.agendados,c: filtadso,i: unhini.i,publicados: unhini.publicados,t: unhini.t })
                    }
                    });
                   this.slotes = neoslotes;
              }
          },
          async descargarAgenda(){
                try {
                this.$f7.dialog.preloader('Generando archivo...');
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let envx = this.$store.state.dev?'dev':'prod';
                let response = await axios.get(`${this.$store.getters.getApiUrl}/prosex-exporter_isp/corxpt/agendapordia/${this.centerInFoco}/${this.hoyx}/${envx}`, {
                            headers: {'Authorization': 'Bearer ' + idToken},
                            responseType: 'blob', // important
                            });
                this.$f7.dialog.close();
                console.log(response.data);
                if(response.data.type=='application/json'){
                    var textPromise = await response.data.text();
                    console.log('error',textPromise);
                    return this.$f7.dialog.alert(textPromise,'Error');
                }
                const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
                let fileLink = document.createElement('a');
                document.body.appendChild(fileLink);
                fileLink.href = fileURL;
                fileLink.setAttribute('download', this.$dayjs(this.hoyx).format('DD-MM-YYYY')+'.xlsx');
                fileLink.classList.add("external");
                fileLink.click();
                window.URL.revokeObjectURL(fileURL);
                this.avisaOk('Descarga OK');
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error, error.message,error.response, error?.response?.data);
                this.$f7.dialog.alert( error.response?.data?.message || error.message);
            }
          },
          async cargaSemanaMolde(force){
              try {
                    if(force || !this.agenda_molde_activa){
                        console.log('cargando semana molde');
                        let semmol = await this.$firebase.database().ref(`agenda_molde/${this.centerInFoco}/activa`).once('value');
                        if(semmol.exists()){
                        this.$store.commit('setObjTo',{what:'agenda_molde_activa',to:semmol.val()});
                        }
                    }
                     this.montarCalendario();
                        await this.getEsteDia(this.hoyx);
              } catch (error) {
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code || 'Error');
              }

          },
          //reevaluar de aqui a abajo
          async revokaslot(concurrenciaid,hini,borra){
              try {
                  this.$f7.dialog.preloader('Modificando cupo...');
                  console.log({borra,concurrenciaid, anomesdia: this.hoyx, hini, vacunatorioid: this.centerInFoco});
                    let datous = await this.commonExecute({borra,concurrenciaid, anomesdia: this.hoyx, hini, vacunatorioid: this.centerInFoco},'admin-modificaConcurrencia',false);
                    if(datous.error){
                        throw new Error(datous.error.message);
                    }
                  this.$f7.dialog.close();
                  this.avisaOk('Cupo modificado correctamente');
                let slotModificando = Object.assign({},this.diaenFoco[hini]);
                console.log('SLOT MODIFICANDO',slotModificando)
                //eliinar el C 
                let concurrarraypos = concurrenciaid;//parseInt(concurrenciaid.split('_')[1]);
                if(borra){
                    if(!slotModificando.c[concurrarraypos].p){
                        this.$set(slotModificando.c,concurrarraypos,{i:concurrarraypos,p:'bloq'});
                    }
                    else{
                        this.$set(slotModificando.c[concurrarraypos],'p','bloq');
                    }
                }
                else{
                    //borrar el p y el meta
                    this.$delete(slotModificando.c[concurrarraypos],'p');
                    this.$delete(slotModificando.c[concurrarraypos],'meta');
                }
                if(slotModificando.c && slotModificando.c.length){
                    slotModificando.publicados=slotModificando.c.filter(uns=>{
                        return !uns.p || uns.p!='bloq'
                    }).length;
                    slotModificando.agendados = slotModificando.c.filter(unc=>{
                        return unc.p && unc.p!='bloq';
                    }).length;
                }
                else{
                    slotModificando.publicados=0;
                    slotModificando.agendados=0;
                }
                console.log('FINAL STLO',slotModificando)
                    this.$set(this.diaenFoco,hini,slotModificando);
              } catch (error) {
                  this.$f7.dialog.close();
                  console.log(error)
                  this.$f7.dialog.alert(error.message,error.code || 'Error');
              }
          },
       
        fxnodisponibleaun(){
          return this.$f7.dialog.alert('Funcion no disponible en esta rama','Feature backlog');
        },
        montarCalendario(){
          console.log('tratando de montar calendario')
          if(!this.calendarInline){
            console.log('no existe, go')
              let monthNames = this.$dayjs.months();
            this.calendarInline = this.$f7.calendar.create({
            containerEl: '.lekalendar',
            locale: 'es',
            value: [new Date()],
            events:[],
            renderToolbar: () => {
                return '<div class="toolbar calendar-custom-toolbar no-shadow">' +
                '<div class="toolbar-inner">' +
                    '<div class="left">' +
                    '<a href="#" class="link icon-only"><i class="material-icons">chevron_left</i></a>' +
                    '</div>' +
                    '<div class="center"></div>' +
                    '<div class="right">' +
                    '<a href="#" class="link icon-only"><i class="material-icons">chevron_right</i></a>' +
                    '</div>' +
                '</div>' +
                '</div>';
            },
            on: {
                init: (c) => {
                    this.$$('.calendar-custom-toolbar .center').text(monthNames[c.currentMonth] +', ' + c.currentYear);
                    this.$$('.calendar-custom-toolbar .left .link').on('click', ()=> {
                        this.calendarInline.prevMonth();
                    });
                    this.$$('.calendar-custom-toolbar .right .link').on('click', ()=> {
                        this.calendarInline.nextMonth();
                    });
                },
                monthYearChangeStart: (c)=> {
                    this.$$('.calendar-custom-toolbar .center').text(monthNames[c.currentMonth] +', ' + c.currentYear);
                },
                change: (c,v)=>{
                    console.log('evento de cambio de calendario',v);
                    let nueox = this.$dayjs(v[0]).format('YYYYMMDD');
                    if(nueox!=this.hoyx){
                        this.hoyx = nueox;
                        console.log(this.hoyx);
                        this.getEsteDia(this.hoyx);
                    }
                }
            }
            });
          }
        },
        async getEsteDia(fecha){
          try {
            if(!this.agenda_molde_activa){
                return false;
            }
             this.cargandoDia = true;
              this.statsFoco.agendados = 0;
            this.statsFoco.confirmados = 0;
             this.slotes = [];
            let datous = await this.commonExecute({fecha, vacunatorioid: this.centerInFoco},'admin-agendafetcher',false);
             if(datous.error){
                throw new Error(datous.error.message);
            }

            console.log('datos del dia',datous.payload);
            this.diaenFoco = Object.assign({},datous.payload.diabase);
            this.statsFoco.agendados = datous.payload.statsFoco.agendados;
            this.statsFoco.confirmados = datous.payload.statsFoco.confirmados;
            this.slotes = Object.values(this.diaenFoco);
            this.cargandoDia = false;
          } catch (error) {
            this.cargandoDia=false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }

        },
        pintarCal(ano,mes,diasEsteMes){
           let eventos = Object.keys(diasEsteMes).map(dia => {
               let publicados = diasEsteMes[dia].p || 0;
               let agendados = diasEsteMes[dia].a || 0;
               let haySlotsDisponibles = ((publicados - agendados) >0);
                return {
                        date: this.$dayjs(ano+'-'+mes+'-'+dia).toDate(),
                        color: haySlotsDisponibles?'#0279ff':'#8e8e93'
                    }
            });
            this.calendarInline.params.events = eventos;
            this.calendarInline.update();
        },
        cambiardia(futuro){
            let nuevafecha;
            if(futuro){
                nuevafecha = this.$dayjs(this.hoyx).add(1, 'd');
            }
            else{
                nuevafecha = this.$dayjs(this.hoyx).subtract(1, 'd');
            }
            if(nuevafecha.isBefore(this.hoyx,'month')){
                 this.calendarInline.prevMonth();
            }
            else if(nuevafecha.isAfter(this.hoyx,'month')){
                this.calendarInline.nextMonth();
            }
            this.calendarInline.setValue([this.$dayjs(nuevafecha).toDate()]);
        }
    }
}
</script>
<style>


.prelholder{
    width:100%;
    height: 4px!important;
}
.esteacl .calendar-day .calendar-day-event{
    width: 8px!important;
    height: 8px!important;
    border-radius: 4px!important;
}
.scrollagenda{
    height: 500px;
    overflow-y: auto;
}
.abriragenda{
    text-align: center;
    margin-top: 30%!important;
}
.borderabajoxx{
    border-bottom: 1px solid #cecece;
}
.availchip{
    height: 22px!important;
    line-height: 22px!important;
    margin-left: 12px;
}


.listrgrupsmall.list-group-title{
    height: 25px!important;
    line-height: 25px!important;
}

.blokeadage{
    background:#fff4f4;
}

.blokeadage .item-title{
    color: #dc0000;
    font-weight: bolder!important;
}
.listagendax .item-title-row{
    height: 23px!important;
}

.agendado{
    background: #effdff;
}
</style>