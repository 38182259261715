<template>
    <f7-page name="campana" :page-content="false">
        <!-- Top Navbar -->
        <f7-navbar :subtitle="!campanaEnFoco.pubfin ? 'Borrador' : 'Publicada'" :title="campanaEnFoco.nombre" back-link="Back">
            <f7-nav-right>
                <f7-link @click="openLog()" tooltip="Ver log"><font-awesome-icon icon="history"></font-awesome-icon></f7-link>
                <f7-link
                    :disabled="enedicion == 'despublica'"
                    tooltip="Despublicar"
                    v-if="campanaEnFoco.pubfin"
                    @click="guardaModificacion({ action: 'despublica', valor: true })"
                    large
                    color="red"
                    ><f7-preloader v-if="enedicion == 'despublica'"></f7-preloader><font-awesome-icon v-else icon="folder-minus"></font-awesome-icon
                ></f7-link>
                <f7-link
                    :disabled="enedicion == 'publica'"
                    tooltip="Publicar"
                    v-else
                    fill
                    @click="guardaModificacion({ action: 'publica', valor: true })"
                    raised
                    large
                    color="teal"
                    ><f7-preloader v-if="enedicion == 'publica'"></f7-preloader><font-awesome-icon v-else icon="clipboard-check"></font-awesome-icon
                ></f7-link>
            </f7-nav-right>
        </f7-navbar>
        <f7-toolbar tabbar top>
            <f7-link tab-link="#camp_metadata" tab-link-active>Metadata</f7-link>
            <f7-link tab-link="#camp_esquemas">Esquemas</f7-link>
            <f7-link tab-link="#camp_criterios">Criterios<br />Usuarios</f7-link>
            <f7-link tab-link="#camp_crit_pregs">Criterios<br />Preguntas</f7-link>
        </f7-toolbar>

        <f7-tabs animated>
            <f7-tab id="camp_metadata" class="page-content" tab-active>
                <f7-block>
                    <f7-card>
                        <f7-card-header class="bold">
                            <span>
                                <font-awesome-icon icon="first-aid" class="fa-lg text-color-primary margin-right-half"></font-awesome-icon> Nombre y
                                descripción</span
                            >
                        </f7-card-header>
                        <f7-card-content>
                            <f7-list class="marfixicons">
                                <f7-list-item @click="updatetext('nombre')" link="#" header="Nombre de la campaña" :title="campanaEnFoco.nombre">
                                    <f7-preloader slot="after" v-if="enedicion == 'edita_nombre'"></f7-preloader>
                                    <font-awesome-icon v-else slot="after" icon="pen"></font-awesome-icon>
                                </f7-list-item>
                                <f7-list-item @click="updatetext('descp')" link="#" header="Descripción" :title="campanaEnFoco.descp || '--'">
                                    <f7-preloader slot="after" v-if="enedicion == 'edita_descp'"></f7-preloader>
                                    <font-awesome-icon v-else slot="after" icon="pen"></font-awesome-icon>
                                    <span v-if="!campanaEnFoco.descp" class="text-color-red" slot="footer"
                                        ><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> Este campo es obligatorio</span
                                    >
                                </f7-list-item>
                            </f7-list>
                        </f7-card-content>
                    </f7-card>
                    <f7-card>
                        <f7-card-header class="bold"
                            ><span>
                                <font-awesome-icon icon="calendar-day" class="fa-lg text-color-primary margin-right-half"></font-awesome-icon>
                                Agendamiento de la campaña</span
                            ><f7-preloader v-if="enedicion == 'edita_ini' || enedicion == 'edita_fin'"></f7-preloader
                        ></f7-card-header>
                        <f7-card-content>
                            <f7-block>
                                <f7-row class="text-align-center">
                                    <f7-col>
                                        <f7-list class="listaConTitleCentrado">
                                            <f7-list-item header="Fecha de inicio de vacunación">
                                                <f7-input
                                                    slot="title"
                                                    type="datepicker"
                                                    outline
                                                    placeholder="--"
                                                    @change="setDate('ini', $event.target.value)"
                                                    readonly
                                                    :calendar-params="{
                                                        dateFormat: 'dd/mm/yyyy',
                                                        value: campanaEnFoco.ini ? [$dayjs(campanaEnFoco.ini).toDate()] : [],
                                                        locale: 'es',
                                                        closeOnSelect: true,
                                                    }"
                                                >
                                                </f7-input>
                                                <span v-if="enedicion == 'edita_ini'" slot="footer">Guardando...</span>
                                                <span v-else-if="!campanaEnFoco.ini" class="text-color-red" slot="footer"
                                                    ><font-awesome-icon icon="exclamation-triangle"></font-awesome-icon> Este campo es
                                                    obligatorio</span
                                                >
                                            </f7-list-item>
                                        </f7-list>
                                    </f7-col>
                                    <f7-col>
                                        <f7-list class="listaConTitleCentrado">
                                            <f7-list-item header="Fecha de término de vacunación">
                                                <f7-input
                                                    slot="title"
                                                    type="datepicker"
                                                    outline
                                                    placeholder="--"
                                                    @change="setDate('fin', $event.target.value)"
                                                    readonly
                                                    :calendar-params="{
                                                        dateFormat: 'dd/mm/yyyy',
                                                        value: campanaEnFoco.fin ? [$dayjs(campanaEnFoco.fin).toDate()] : [],
                                                        locale: 'es',
                                                        closeOnSelect: true,
                                                    }"
                                                >
                                                </f7-input>
                                                <span v-if="enedicion == 'edita_ini'" slot="footer">Guardando...</span>
                                                <span v-else class="text-color-gray" slot="footer"> (Opcional)</span>
                                            </f7-list-item>
                                        </f7-list>
                                    </f7-col>
                                </f7-row>
                            </f7-block>
                        </f7-card-content>
                    </f7-card>

                    <f7-card>
                        <f7-card-header class="bold">
                            <span>
                                <font-awesome-icon icon="ban" class="fa-lg text-color-primary margin-right-half"></font-awesome-icon> Restricción
                                concurrencia otras campañas</span
                            >
                            <f7-preloader v-if="enedicion == 'set_seguimiento' || enedicion == 'borra_seguimiento'"></f7-preloader>
                        </f7-card-header>
                        <f7-card-content>
                            <f7-list media-list>
                                <f7-list-item v-if="!campanaEnFoco.gtw || !campanaEnFoco.gtw.bfr || !Object.keys(campanaEnFoco.gtw.bfr).length"
                                    >No existen restricciones de concurrencias con otras campañas.</f7-list-item
                                >
                                <template v-else-if="campanaEnFoco.gtw && campanaEnFoco.gtw.bfr">
                                    <f7-list-item
                                        v-for="(dias, campanaidx) in campanaEnFoco.gtw.bfr"
                                        :key="campanaidx"
                                        :title="campanas[campanaidx].nombre"
                                        :header="`Se puede iniciar a partir de ${dias} días después de última dosis de`"
                                    >
                                        <f7-preloader v-if="enedicion == `borra_concurr_${campanaidx}`" slot="after"></f7-preloader>
                                        <f7-link
                                            v-else
                                            @click="guardaModificacion({ action: 'borra_concurr', valor: campanaidx })"
                                            color="gray"
                                            slot="after"
                                            ><font-awesome-icon icon="trash"></font-awesome-icon
                                        ></f7-link>
                                    </f7-list-item>
                                </template>
                                <f7-list-item v-if="otrascampanas.length">
                                    <f7-preloader v-if="enedicion == 'set_restriccion'" slot="after"></f7-preloader>
                                    <f7-button v-else outline color="teal" @click="abreRestriccionesConcurrencias()" slot="after"
                                        ><font-awesome-icon icon="plus-square"></font-awesome-icon> Agregar restricción</f7-button
                                    >
                                </f7-list-item>
                            </f7-list>
                        </f7-card-content>
                    </f7-card>
                </f7-block>
            </f7-tab>
            <f7-tab id="camp_esquemas" class="page-content">
                <f7-block>
                    <esquema
                        v-for="esquema in campanaEnFoco.esquemas"
                        @abreSegumiento="abreSeguimiento"
                        @borra_seguimiento_fecha="borra_seguimiento_fecha"
                        @editaschemeagelimit="abreageranger"
                        :enedicion="enedicion"
                        :campanaid="campanaEnFoco.id"
                        :esquema="esquema"
                        :key="esquema.id"
                    ></esquema>

                    <f7-block
                        ><f7-preloader v-if="enedicion == 'add_esquema_alt'"></f7-preloader>
                        <f7-button v-else @click="creanuevoesquema()" outline large
                            ><f7-icon material="add"></f7-icon> Agregar esquema alternativo</f7-button
                        ></f7-block
                    >
                </f7-block>
                <f7-block class="margin-bottom-half"><h3 class="no-margin-vertical">Gestión dosis de refuerzo</h3></f7-block>

                <f7-block class="no-margin-bottom">
                    <f7-row class="padding-horizontal">
                        <f7-col>Vacuna(s) permitida(s) como refuerzo para esta campaña</f7-col>
                        <f7-col class="text-align-right"
                            ><f7-link
                                tooltip="Configurar vacuna(s) permitida(s) como refuerzo"
                                color="teal"
                                @click="selectorVacunaRefuerzoOpened = true"
                                ><font-awesome-icon class="fa-lg" icon="wrench"></font-awesome-icon></f7-link
                        ></f7-col>
                    </f7-row>
                </f7-block>
                <f7-list class="no-margin-vertical" inset media-list>
                    <f7-list-item
                        v-if="!Object.keys(refuerzosDeCampana).length"
                        title="no existen vacunas permitidas como refuerzo para esta campaña"
                    ></f7-list-item>
                    <f7-list-item
                        v-for="unrefuerzo in refuerzosDeCampana"
                        :key="unrefuerzo.vacunaPcId"
                        :title="
                            `${$store.getters.unaVacuna(unrefuerzo.vacunaPcId).vacunaPcName} (${
                                $store.getters.unaVacuna(unrefuerzo.vacunaPcId).laboratorioName
                            }) `
                        "
                        :subtitle="$store.getters.unaVacuna(unrefuerzo.vacunaPcId).presentacion"
                    >
                        <font-awesome-icon icon="check" slot="after"></font-awesome-icon
                    ></f7-list-item>
                </f7-list>

                <template v-if="Object.keys(refuerzosDeCampana).length">
                    <f7-block class="no-margin-bottom">
                        <f7-row class="padding-horizontal">
                            <f7-col>Configurar seguimiento de refuerzos</f7-col>
                            <f7-col class="text-align-right"
                                ><f7-preloader v-if="enedicion == 'nuevoSeguimientoRefuerzo'"></f7-preloader>
                                <f7-link tooltip="Agregar seguimiento a las dosis de refuerzo" color="teal" @click="modalSeguiRefuerzoOpened = true"
                                    ><font-awesome-icon class="fa-lg" icon="folder-plus"></font-awesome-icon></f7-link
                            ></f7-col>
                        </f7-row>
                    </f7-block>
                    <f7-list class="no-margin-top" inset media-list>
                        <f7-list-item
                            v-if="!campanaEnFoco.refuerzos_seg || !Object.keys(campanaEnFoco.refuerzos_seg).length"
                            title="No hay seguimientos configurados"
                        ></f7-list-item>
                        <f7-list-item
                            v-for="segui_ref in campanaEnFoco.refuerzos_seg"
                            :key="segui_ref.id"
                            header="Seguimiento activo de segumientos en base a:"
                            :title="$store.getters.getUno('formularios', segui_ref.formid).name"
                            :text="
                                `Enviar los dias: ${Object.keys(segui_ref.when)
                                    .filter(unseg => {
                                        return segui_ref.when[unseg];
                                    })
                                    .join('|')}`
                            "
                            :footer="segui_ref.criterio ? `Criterio: ${segui_ref.criterio}` : null"
                        >
                            <f7-link slot="after" @click="modifica_seguimiento_refuerzo(segui_ref, false)"
                                ><font-awesome-icon color="gray" icon="trash"></font-awesome-icon></f7-link
                        ></f7-list-item>
                    </f7-list>
                </template>
            </f7-tab>
            <f7-tab id="camp_criterios" @tab:show="abrirCirtusers()" class="page-content">
                <f7-block class="text-align-center" v-if="!criteuriosUsersOpened"><f7-preloader></f7-preloader><br />Cargando...</f7-block>
                <f7-block v-else>
                    <f7-card :padding="false">
                        <f7-card-header class="bold"
                            >Edad como criterio de Inclusión
                            <f7-button @click="edadinclusionOpened = true" outline color="teal">Modificar criterio</f7-button>
                        </f7-card-header>
                        <f7-card-content :padding="false">
                            <f7-list class="no-margin fixfamarg">
                                <f7-list-item divider title="Edad"></f7-list-item>
                                <f7-list-item
                                    :header="!inclusionporedad.agemin ? 'Sin inclusión en base a edad mīnima' : 'Se incluirá si mayor de'"
                                    :title="
                                        !inclusionporedad.agemin
                                            ? `Sin inclusión a 'mayores de'`
                                            : `${inclusionporedad.agemin.y} años ${inclusionporedad.agemin.m} meses ${inclusionporedad.agemin.d} días`
                                    "
                                >
                                    <font-awesome-icon
                                        :icon="!inclusionporedad.agemin ? 'user' : 'user-check'"
                                        :class="`text-color-${!inclusionporedad.agemin ? 'gray' : 'teal'} fa-lg`"
                                        slot="media"
                                    ></font-awesome-icon>
                                </f7-list-item>
                                <f7-list-item
                                    :header="!inclusionporedad.agemax ? 'Sin inclusión en base a edad máxima' : 'Se incluriá si es menor de'"
                                    :title="
                                        !inclusionporedad.agemax
                                            ? `Sin inclusión a 'menores de'`
                                            : `${inclusionporedad.agemax.y} años ${inclusionporedad.agemax.m} meses ${inclusionporedad.agemax.d} días`
                                    "
                                >
                                    <font-awesome-icon
                                        :icon="!inclusionporedad.agemax ? 'user' : 'user-check'"
                                        :class="`text-color-${!inclusionporedad.agemax ? 'gray' : 'teal'} fa-lg`"
                                        slot="media"
                                    ></font-awesome-icon>
                                </f7-list-item>
                            </f7-list>
                        </f7-card-content>
                    </f7-card>

                    <f7-card :padding="false">
                        <f7-card-header class="bold"
                            >Criterios exclusión en base a propiedades del perfil de usuario
                            <f7-button @click="editandoCriteriosUsuario = true" outline color="teal">Modificar criterios</f7-button>
                        </f7-card-header>
                        <f7-card-content :padding="false">
                            <f7-row :no-gap="true">
                                <f7-col>
                                    <f7-list class="no-margin fixfamarg">
                                        <f7-list-item divider title="Edad"></f7-list-item>
                                        <f7-list-item
                                            :header="!reglasusuarios.agemin ? 'Sin exclusión en base a edad mīnima' : 'Se excluirá a menores de'"
                                            :title="
                                                !reglasusuarios.agemin
                                                    ? `Sin exclusión a 'menores de'`
                                                    : `${reglasusuarios.agemin.y} años ${reglasusuarios.agemin.m} meses ${reglasusuarios.agemin.d} días`
                                            "
                                        >
                                            <font-awesome-icon
                                                :icon="!reglasusuarios.agemin ? 'user-check' : 'user-clock'"
                                                :class="`text-color-${!reglasusuarios.agemin ? 'teal' : 'red'} fa-lg`"
                                                slot="media"
                                            ></font-awesome-icon>
                                        </f7-list-item>
                                        <f7-list-item
                                            :header="!reglasusuarios.agemax ? 'Sin exclusión en base a edad máxima' : 'Se excluirá a mayores de'"
                                            :title="
                                                !reglasusuarios.agemax
                                                    ? `Sin exclusión a 'mayores de'`
                                                    : `${reglasusuarios.agemax.y} años ${reglasusuarios.agemax.m} meses ${reglasusuarios.agemax.d} días`
                                            "
                                        >
                                            <font-awesome-icon
                                                :icon="!reglasusuarios.agemax ? 'user-check' : 'user-clock'"
                                                :class="`text-color-${!reglasusuarios.agemax ? 'teal' : 'red'} fa-lg`"
                                                slot="media"
                                            ></font-awesome-icon>
                                        </f7-list-item>
                                        <f7-list-item divider title="Sexo Biológico"></f7-list-item>
                                        <f7-list-item
                                            :header="!reglasusuarios.sex ? 'Sin exclusión en base a sexo biológico' : 'Se exlucirán otros'"
                                            :title="
                                                !reglasusuarios.sex
                                                    ? 'Todos'
                                                    : Object.keys(reglasusuarios.sex)
                                                          .filter(unalet => {
                                                              return reglasusuarios.sex[unalet];
                                                          })
                                                          .map(sexlet => {
                                                              return $store.state.sexo[sexlet];
                                                          })
                                                          .join('|')
                                            "
                                        >
                                            <font-awesome-icon
                                                :icon="!reglasusuarios.sex ? 'user-check' : 'user-clock'"
                                                :class="`text-color-${!reglasusuarios.sex ? 'teal' : 'red'} fa-lg`"
                                                slot="media"
                                            ></font-awesome-icon>
                                        </f7-list-item>
                                        <f7-list-item divider title="Nacionalidad"></f7-list-item>
                                        <f7-list-item
                                            :header="
                                                !reglasusuarios.nacionalidades ? 'Sin exclusión en base a Nacionalidades' : 'Se excluirá a otros'
                                            "
                                            :title="!reglasusuarios.nacionalidades ? 'Todas' : Object.keys(reglasusuarios.nacionalidades).join('|')"
                                        >
                                            <font-awesome-icon
                                                :icon="!reglasusuarios.nacionalidades ? 'user-check' : 'user-clock'"
                                                :class="`text-color-${!reglasusuarios.nacionalidades ? 'teal' : 'red'} fa-lg`"
                                                slot="media"
                                            ></font-awesome-icon>
                                        </f7-list-item>
                                    </f7-list>
                                </f7-col>
                                <f7-col>
                                    <f7-list class="no-margin-top fixfamarg">
                                        <f7-list-item divider title="Comunas por región"></f7-list-item>
                                        <f7-list-item footer="Sectores no seleccionados serán excluidos"></f7-list-item>
                                        <f7-block class="bg-color-white padding-bottom no-margin">
                                            <f7-treeview>
                                                <f7-treeview-item v-for="region in regiones" :key="region.id" :label="region.region">
                                                    <font-awesome-icon
                                                        icon="globe-americas"
                                                        class="fa-lg"
                                                        :class="{
                                                            'text-color-teal': comIncludedByregion[region.id] == '2',
                                                            'text-color-gray': comIncludedByregion[region.id] == '0',
                                                            'text-color-orange': comIncludedByregion[region.id] == '1',
                                                        }"
                                                        slot="media"
                                                    ></font-awesome-icon>
                                                    <f7-treeview-item v-for="province in region.provinces" :key="province.id" :label="province.name">
                                                        <font-awesome-icon
                                                            icon="map-marked-alt"
                                                            class="fa-lg"
                                                            :class="{
                                                                'text-color-teal': comIncludedByProvince[province.id] == '2',
                                                                'text-color-gray': comIncludedByProvince[province.id] == '0',
                                                                'text-color-orange': comIncludedByProvince[province.id] == '1',
                                                            }"
                                                            slot="media"
                                                        ></font-awesome-icon>
                                                        <f7-treeview-item v-for="comune in province.comunes" :key="comune.code" :label="comune.name">
                                                            <font-awesome-icon
                                                                icon="map-pin"
                                                                :class="
                                                                    `fa-lg ${
                                                                        !reglasusuarios.comunas || reglausariosComuna[comune.code]
                                                                            ? 'text-color-teal'
                                                                            : 'text-color-gray'
                                                                    }`
                                                                "
                                                                slot="media"
                                                            ></font-awesome-icon>
                                                        </f7-treeview-item>
                                                    </f7-treeview-item>
                                                </f7-treeview-item>
                                            </f7-treeview>
                                        </f7-block>
                                    </f7-list>
                                </f7-col>
                            </f7-row>
                        </f7-card-content>
                    </f7-card>
                </f7-block>
            </f7-tab>
            <f7-tab id="camp_crit_pregs" class="page-content">
                <f7-block>
                    <f7-card>
                        <f7-card-header class="bold"
                            >Criterios en base a preguntas explícitas
                            <f7-button @click="editandoCriteriosPreguntas = true" class="margin-left" outline color="teal"
                                >Modificar criterios</f7-button
                            >
                        </f7-card-header>
                        <f7-card-content :padding="false">
                            <f7-list class="no-margin nowhitespacetitle" v-for="seccion in reglaspreguntas" :key="seccion.id">
                                <f7-list-item divider>Sección: {{ seccion.encab }}</f7-list-item>
                                <f7-list-item v-for="criterio in seccion.crits" :key="criterio.id" :title="criterio.txt">
                                    <f7-chip :text="crit_rules[criterio.rule].tx" :media-bg-color="crit_rules[criterio.rule].col" slot="after"
                                        ><font-awesome-icon :icon="crit_rules[criterio.rule].iko" slot="media"></font-awesome-icon>
                                    </f7-chip>
                                </f7-list-item>
                            </f7-list>
                        </f7-card-content>
                    </f7-card>
                </f7-block>
            </f7-tab>
        </f7-tabs>

        <f7-popup class="seguimientoPopUp" :opened="!!modalSeguimientosOpened" @popup:closed="modalSeguimientosOpened = null">
            <seguimiento
                v-if="modalSeguimientosOpened"
                :seginfo="modalSeguimientosOpened"
                :campanaEnFoco="campanaEnFoco"
                @tosend="recibiendo"
            ></seguimiento>
        </f7-popup>

        <f7-popup class="editacritusario" :opened="editandoCriteriosUsuario" @popup:closed="editandoCriteriosUsuario = false">
            <criterios-usuarios
                v-if="editandoCriteriosUsuario"
                @nuevosaved="nuevoscriteriosuser"
                :critusarios="reglasusuarios"
                :campid="id"
            ></criterios-usuarios>
        </f7-popup>

        <f7-popup class="editacritpreguntas" :opened="editandoCriteriosPreguntas" @popup:closed="editandoCriteriosPreguntas = false">
            <criterios-preguntas
                :id="id"
                @nuevosaved="nuevocriterpreguntas"
                v-if="editandoCriteriosPreguntas"
                :reglaspreguntas="reglaspreguntas"
            ></criterios-preguntas>
        </f7-popup>

        <f7-popup class="restringeesquema" :opened="!!editandoRestriccionEdadEsquema" @popup:closed="editandoRestriccionEdadEsquema = null">
            <restriccion-edad-esquema
                @nuevorestriage="neoagerestrix"
                :id="id"
                :esquemaid="editandoRestriccionEdadEsquema"
                v-if="editandoRestriccionEdadEsquema"
            ></restriccion-edad-esquema>
        </f7-popup>

        <f7-popup class="edadincluye" :opened="edadinclusionOpened" @popup:closed="edadinclusionOpened = false">
            <edad-inclusion @nuevosaved="nuevainclux" :id="id" :inclusionporedad="inclusionporedad" v-if="edadinclusionOpened"></edad-inclusion>
        </f7-popup>

        <f7-popup class="my-log" :opened="logOpened" @popup:closed="logOpened = false">
            <log v-if="logOpened" tipo="k" :cual="id"></log>
        </f7-popup>

        <f7-popup class="resconcupop" :opened="resconcupopOpened" @popup:closed="resconcupopOpened = false">
            <restriconcurr v-if="resconcupopOpened" :campanaEnFoco="campanaEnFoco" @restringe="restrigiendo"></restriconcurr>
        </f7-popup>

        <f7-popup class="nueva-vac-refuerzo" :opened="selectorVacunaRefuerzoOpened" @popup:closed="selectorVacunaRefuerzoOpened = false">
            <f7-page>
                <f7-navbar title="Vacunas permitidas como refuerzo">
                    <f7-nav-right>
                        <f7-preloader v-if="enedicion == 'nuevaVacunaRefurezo'"></f7-preloader>
                        <f7-link popup-close>Cerrar</f7-link>
                    </f7-nav-right>
                </f7-navbar>
                <f7-searchbar
                    class="no-margin"
                    search-container=".selector-vacuna-refuerzo"
                    search-in=".item-title,.item-text"
                    placeholder="Busque nombre vacuna o enfermedad"
                    :backdrop="false"
                ></f7-searchbar>
                <f7-list class="no-margin-vertical searchbar-not-found">
                    <f7-list-item title="Sin resultados"></f7-list-item>
                </f7-list>
                <f7-list class="no-margin-vertical selector-vacuna-refuerzo">
                    <f7-list-item v-if="!vacunas.length" title="No existen vacunas creadas,"></f7-list-item>
                    <f7-list-item
                        v-for="vacuna in solovacpublicadas"
                        :key="vacuna.vacunaPcId"
                        :title="`${vacuna.vacunaPcName} (${vacuna.laboratorioName}) `"
                        :subtitle="vacuna.presentacion"
                    >
                        <f7-toggle
                            @toggle:change="setNuevoRefuerzo($event, vacuna.vacunaPcId)"
                            :checked="!!refuerzosDeCampana[vacuna.vacunaPcId]"
                            slot="after"
                        ></f7-toggle>
                    </f7-list-item>
                </f7-list>
            </f7-page>
        </f7-popup>

        <f7-popup class="seguimientoRefuerzo" :opened="modalSeguiRefuerzoOpened" @popup:closed="modalSeguiRefuerzoOpened = false">
            <seguimiento-refuerzo v-if="modalSeguiRefuerzoOpened" @tosend="modifica_seguimiento_refuerzo($event, true)"></seguimiento-refuerzo>
        </f7-popup>
    </f7-page>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { commonmixin } from "../mixins/common";
import seguimiento from "./seguimiento.vue";
import seguimientoRefuerzo from "./seguimiento_refuerzos.vue";
import restriconcurr from "./restriconcurr.vue";
import esquema from "../components/esquema.vue";
import criteriosUsuarios from "./criterios_usuario_editar.vue";
import criteriosPreguntas from "./criterios_preguntas_editar.vue";
import restriccionEdadEsquema from "./restriccion_edad_esquema.vue";
import edadInclusion from "./edad_inclusion.vue";
import log from "./log.vue";

export default {
    mixins: [commonmixin],
    components: {
        seguimiento,
        esquema,
        criteriosUsuarios,
        criteriosPreguntas,
        restriccionEdadEsquema,
        edadInclusion,
        restriconcurr,
        log,
        seguimientoRefuerzo,
    },
    props: ["id", "loadedsusreglas"],
    data() {
        return {
            selectorVacunaRefuerzoOpened: false,
            edadinclusionOpened: false,
            criteuriosUsersOpened: false,
            editandoRestriccionEdadEsquema: null,
            enedicion: null,
            modalSeguimientosOpened: null,
            resconcupopOpened: false,
            editandoCriteriosUsuario: false,
            editandoCriteriosPreguntas: false,
            reglas: {},
            logOpened: false,
            modalSeguiRefuerzoOpened: false,
        };
    },
    created() {
        this.reglas = Object.assign({}, this.loadedsusreglas);
    },
    computed: {
        ...mapState(["comunas", "crit_rules", "campanas"]),
        ...mapGetters(["vacunas", "regiones", "formularios_publicados"]),
        solovacpublicadas() {
            return this.vacunas.filter(unavac => {
                return !unavac.borrador;
            });
        },
        campanaEnFoco() {
            return this.$store.state.campanas[this.id];
        },
        reglasusuarios() {
            return this.reglas.usuarios || {};
        },
        inclusionporedad() {
            return this.reglas.incluage || {};
        },
        reglaspreguntas() {
            return this.reglas.criterios || {};
        },
        reglausariosComuna() {
            return this.reglasusuarios.comunas || {};
        },
        comIncludedByregion() {
            let elbojec = {};
            this.regiones.forEach(unareg => {
                if (!this.reglasusuarios.comunas) {
                    elbojec[unareg.id] = "2";
                } else {
                    let cuantas = this.countMyComs(unareg.id);
                    elbojec[unareg.id] = unareg.coms == cuantas ? "2" : !cuantas ? "0" : "1";
                }
            });
            return elbojec;
        },
        comIncludedByProvince() {
            let elbojec = {};
            this.regiones.forEach(unareg => {
                let provinces = unareg.provinces || {};
                Object.values(provinces).forEach(unaprov => {
                    let cuantascoms = Object.values(unaprov.comunes).length;
                    if (!this.reglasusuarios.comunas) {
                        elbojec[unaprov.id] = "2";
                    } else {
                        let cuantas = this.countMyComsProv(unaprov.id);
                        elbojec[unaprov.id] = cuantascoms == cuantas ? "2" : !cuantas ? "0" : "1";
                    }
                });
            });
            return elbojec;
        },
        otrascampanas() {
            let yaslecciondas = this.campanaEnFoco.gtw && this.campanaEnFoco.gtw.bfr ? this.campanaEnFoco.gtw.bfr : {};
            return Object.values(this.campanas).filter(unacman => {
                return unacman.id !== this.campanaEnFoco.id && !yaslecciondas[unacman.id];
            });
        },
        refuerzosDeCampana() {
            return this.campanaEnFoco.refuerzos || {};
        },
    },

    methods: {
        async setNuevoRefuerzo(valors, vacunaPcId) {
            console.log("recibd", valors, vacunaPcId);
            if ((valors && !this.refuerzosDeCampana[vacunaPcId]) || (!valors && this.refuerzosDeCampana[vacunaPcId])) {
                try {
                    this.enedicion = "nuevaVacunaRefurezo";
                    let modific = { refuerzos: Object.assign({}, this.refuerzosDeCampana) };
                    if (valors && !this.refuerzosDeCampana[vacunaPcId]) {
                        await this.$firebase
                            .database()
                            .ref(`campanas/${this.id}/refuerzos/${vacunaPcId}`)
                            .set({ vacunaPcId });
                        modific.refuerzos[vacunaPcId] = { vacunaPcId };
                    } else if (!valors && this.refuerzosDeCampana[vacunaPcId]) {
                        await this.$firebase
                            .database()
                            .ref(`campanas/${this.id}/refuerzos/${vacunaPcId}`)
                            .set(null);
                        this.$delete(modific.refuerzos, vacunaPcId);
                    }
                    this.$store.commit("updtObjProperty", {
                        what: "campanas",
                        to: Object.assign({}, this.campanaEnFoco, modific),
                    });
                    this.enedicion = null;
                } catch (error) {
                    this.enedicion = null;
                    console.error(error);
                    this.$f7.dialog.alert(error.message, error.code);
                }
            }
        },
        openLog() {
            console.log("abrir log");
            this.logOpened = true;
        },
        nuevainclux(limits) {
            this.$set(this.reglas, "incluage", limits);
            this.edadinclusionOpened = false;
            this.avisaOk("Criterios guardados Ok");
        },
        neoagerestrix(limits) {
            this.$set(this.campanaEnFoco.esquemas[this.editandoRestriccionEdadEsquema], "agemin", limits.agemin);
            this.$set(this.campanaEnFoco.esquemas[this.editandoRestriccionEdadEsquema], "agemax", limits.agemax);
            this.editandoRestriccionEdadEsquema = null;
            this.avisaOk("Nueva restricción guardada Ok");
        },
        abreageranger(esquemaid) {
            console.log("el esquema", esquemaid);
            this.editandoRestriccionEdadEsquema = esquemaid;
        },
        abrirCirtusers() {
            return setTimeout(() => {
                this.criteuriosUsersOpened = true;
            }, 300);
        },
        nuevoscriteriosuser(nuevoscriterios) {
            this.editandoCriteriosUsuario = false;
            this.$set(this.reglas, "usuarios", nuevoscriterios);
        },
        nuevocriterpreguntas(nuevoscriterios) {
            this.editandoCriteriosPreguntas = false;
            this.$set(this.reglas, "criterios", nuevoscriterios);
        },
        countMyComs(regionid) {
            let lascomiunas = this.reglasusuarios.comunas || {};
            let filtradas = Object.keys(lascomiunas).filter(comunacode => {
                return this.comunas[comunacode] && this.comunas[comunacode].regionid == regionid;
            });
            return filtradas.length;
        },
        countMyComsProv(provid) {
            let lascomiunas = this.reglasusuarios.comunas || {};
            let filtradas = Object.keys(lascomiunas).filter(comunacode => {
                return this.comunas[comunacode] && this.comunas[comunacode].provid == provid;
            });
            return filtradas.length;
        },
        restrigiendo(restriccion) {
            console.log("restirigendo", restriccion);
            this.resconcupopOpened = false;
            //set_restriccion
            this.guardaModificacion({
                action: "set_restriccion",
                valor: restriccion,
            });
        },
        async modifica_seguimiento_refuerzo(elseguimiento, create) {
            console.log("a guardar", elseguimiento, create);
            try {
                this.enedicion = "nuevoSeguimientoRefuerzo";
                let modific = { refuerzos_seg: Object.assign({}, this.campanaEnFoco.refuerzos_seg || {}) };
                if (create) {
                    elseguimiento.id = this.$firebase
                        .database()
                        .ref(`campanas/${this.id}/refuerzos_seg`)
                        .push().key;
                }
                let tosave = create ? elseguimiento : null;
                await this.$firebase
                    .database()
                    .ref(`campanas/${this.id}/refuerzos_seg/${elseguimiento.id}`)
                    .set(tosave);
                if (create) {
                    modific.refuerzos_seg[elseguimiento.id] = elseguimiento;
                } else {
                    this.$delete(modific.refuerzos_seg, elseguimiento.id);
                }
                this.$store.commit("updtObjProperty", {
                    what: "campanas",
                    to: Object.assign({}, this.campanaEnFoco, modific),
                });
                this.enedicion = null;
            } catch (error) {
                this.enedicion = null;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
            //refuerzos_seg

            this.modalSeguiRefuerzoOpened = false;
        },
        recibiendo(elseguimiento) {
            let seguiset = Object.assign({}, elseguimiento, this.modalSeguimientosOpened);
            console.log("a guardar", seguiset);
            //cualx
            this.guardaModificacion({
                action: "set_seguimiento_fecha",
                valor: seguiset,
            });
            this.modalSeguimientosOpened = null;
        },
        abreSeguimiento(payloader) {
            console.log("intentanto abrir seguimieintos", payloader);
            console.log("abriendo seguimieintos");
            this.modalSeguimientosOpened = payloader;
        },
        abreRestriccionesConcurrencias() {
            this.resconcupopOpened = true;
        },
        borra_seguimiento_fecha(payloader) {
            console.log("intentanto borrar seguimieintos", payloader);
            this.guardaModificacion({ action: "borra_seguimiento_fecha", valor: payloader });
        },
        setDate(cual, valor) {
            if (valor) {
                let dayjsdate = this.$dayjs(valor, "DD/MM/YYYY").format("YYYYMMDD");
                if (this.campanaEnFoco[cual] != dayjsdate) {
                    console.log("llama set DATE Y PASA CON valor", valor, cual);
                    if (
                        (cual == "ini" && this.campanaEnFoco.fin && this.$dayjs(this.campanaEnFoco.fin).isBefore(dayjsdate, "day")) ||
                        (cual == "fin" && this.campanaEnFoco.ini && this.$dayjs(this.campanaEnFoco.ini).isAfter(dayjsdate, "day"))
                    ) {
                        return this.$f7.dialog.alert("Fecha de término no puede ser anterior a fecha de inicio");
                    } else {
                        console.log("cambiando fecha a", cual, valor, dayjsdate);
                        this.guardaModificacion({
                            action: "edita_" + cual,
                            valor: dayjsdate,
                        });
                    }
                } else {
                    console.log("llama set date pero no pasa por ser igual", valor, cual);
                }
            } else {
                console.log("LLAMA SET DATE, pero NO pasa por ser null", valor, cual);
            }
        },
        updatetext(cual) {
            return this.$f7.dialog.prompt(
                "Indique nuevo valor",
                cambio => {
                    if (!cambio) {
                        return this.$f7.dialog.alert("Valor no puede ser vacío", "Error");
                    }
                    this.guardaModificacion({
                        action: "edita_" + cual,
                        valor: cambio.trim(),
                    });
                },
                () => {},
                this.campanaEnFoco[cual]
            );
        },
        creanuevoesquema() {
            return this.$f7.dialog.prompt("Indique nombre del nuevo esquema", "Creando esquema", cambio => {
                if (!cambio) {
                    return this.$f7.dialog.alert("Nombre no puede ser vacío", "Error");
                }
                this.guardaModificacion({
                    action: "add_esquema_alt",
                    valor: cambio.trim(),
                });
            });
        },
        async guardaModificacion({ action, valor }) {
            try {
                if (action == "set_seguimiento_fecha") {
                    this.enedicion = "set_seguimiento_fecha" + valor.esquemaid + valor.diaid + valor.cualx;
                } else if (action == "borra_seguimiento_fecha") {
                    this.enedicion = "borra_seguimiento_fecha" + valor.esquemaid + valor.diaid + valor.cualx;
                } else if (action == "borra_concurr") {
                    this.enedicion = "borra_concurr_" + valor;
                } else {
                    this.enedicion = action;
                }
                let datous = await this.commonExecute({ id: this.id, action, valor }, "admin-editaCampana", false);
                this.enedicion = null;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log("creacion ok", datous);
                let modific = {};
                if (action == "edita_nombre") {
                    modific.nombre = valor;
                } else if (action == "edita_descp") {
                    modific.descp = valor;
                } else if (action == "edita_ini") {
                    modific.ini = valor;
                } else if (action == "edita_fin") {
                    modific.fin = valor;
                } else if (action == "publica") {
                    modific.pubfin = this.campanaEnFoco.fin || "20850830";
                    modific.fp = true;
                } else if (action == "despublica") {
                    modific.pubfin = null;
                } else if (action == "set_seguimiento") {
                    modific.segu = valor;
                } else if (action == "set_restriccion") {
                    modific.gtw = Object.assign({ bfr: {}, aftr: {} }, this.campanaEnFoco.gtw);
                    modific.gtw.bfr[valor.campanaid] = valor.dias;
                } else if (action == "borra_concurr") {
                    // modific.gtw = Object.assign({bfr:{},aftr:{}},this.campanaEnFoco.gtw);
                    // modific.gtw.bfr[valor] = null;
                    // delete modific.gtw.bfr[valor];
                    this.$delete(this.campanaEnFoco.gtw.bfr, valor);
                } else if (action == "borra_seguimiento") {
                    modific.segu = null;
                } else if (action == "borra_seguimiento_fecha") {
                    modific.esquemas = Object.assign({}, this.campanaEnFoco.esquemas);
                    modific.esquemas[valor.esquemaid].cal[valor.diaid][valor.cualx] = null;
                } else if (action == "add_esquema_alt") {
                    modific.esquemas = Object.assign({}, this.campanaEnFoco.esquemas);
                    modific.esquemas[datous.nuevoesquema.id] = datous.nuevoesquema;
                } else if (action == "set_seguimiento_fecha") {
                    modific.esquemas = Object.assign({}, this.campanaEnFoco.esquemas);
                    modific.esquemas[valor.esquemaid].cal[valor.diaid][valor.cualx] = Object.assign(
                        {},
                        { formid: valor.formid, when: valor.when, criterio: valor.criterio || null }
                    );
                }

                if (Object.keys(modific).length) {
                    this.$store.commit("updtObjProperty", {
                        what: "campanas",
                        to: Object.assign({}, this.campanaEnFoco, modific),
                    });
                }
                if (!["edita_ini", "edita_fin"].includes(action)) {
                    this.avisaOk();
                }
                console.log("IFNAL STATUS", this.campanaEnFoco.gtw);
            } catch (error) {
                this.enedicion = null;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
    },
};
</script>
<style>
.listaConTitleCentrado .item-title {
    margin: 0 auto !important;
}
.listaConTitleCentrado .item-title input {
    text-align: center !important;
}

.segmewitdh {
    width: 200px !important;
}
</style>
