<template>
 <f7-page>
        <f7-navbar title="Criterios en base a preguntas explícitas">
        </f7-navbar>
        
        <f7-block class="display-flex justify-content-space-between margin-bottom">Secciones <f7-button @click="addSection()" ><font-awesome-icon icon="plus"></font-awesome-icon> Agregar sección</f7-button></f7-block>

            <f7-card v-for="seccion in tosend"
                :key="seccion.id">
                <f7-card-header class="bold">Sección: {{seccion.encab}} <f7-link @click="eliminaseccion(seccion.id)" class="text-color-gray"><font-awesome-icon icon="trash"></font-awesome-icon></f7-link></f7-card-header>
                <f7-card-content>
                    <f7-list class="nowhitespacetitle">
                        <f7-list-item 
                        v-for="uncriterio in seccion.crits"
                        :key="uncriterio.id"
                        :title="uncriterio.txt"
                        >
                         <f7-input
                        type="select"
                        defaultValue="NA"
                        outline
                        slot="media"
                         :value="tosend[seccion.id].crits[uncriterio.id].rule"
                        @input="tosend[seccion.id].crits[uncriterio.id].rule = $event.target.value"
                    >
                        <option value="NA">Sin efecto</option>
                        <option value="IN">Incluye</option>
                        <option value="EX">Excluye</option>
                    </f7-input>
                    <div v-if="uncriterio.rule=='NA'" slot="footer">La respuesta a este criterio no tendrá efecto en la inclusión o exclusión actual, pero podría ser utilizado para filtrar pacientes elegibles en el futuro.</div>
                     <div v-if="uncriterio.rule=='IN'" slot="footer">Si el paciente selecciona este crietrio, será <b>incluido</b> en la campaña</div>
                     <div v-if="uncriterio.rule=='EX'" slot="footer">Si el paciente selecciona este criterio, será <b>excluido</b> de la campaña</div>
                     <f7-link class="text-color-gray"  @click="eliminaCriterio(seccion.id,uncriterio.id)" slot="after"><font-awesome-icon icon="trash"></font-awesome-icon></f7-link>
                        </f7-list-item>
                        <f7-list-item v-if="!Object.values(seccion.crits).length" class="text-color-orange" footer="Sección sin criterios creados"></f7-list-item>
                    </f7-list>
                </f7-card-content>
                <f7-card-footer><f7-button @click="agregaCriterioASecc(seccion.id)" class="no-margin"><font-awesome-icon icon="plus"></font-awesome-icon> Agregar criterio a sección</f7-button></f7-card-footer>
            </f7-card>

        <f7-block class="text-align-center" v-if="!Object.values(tosend).length">Campaña sin criterios en base a preguntas explícitas. <br/> Si desea agregar criterios de inclusión en base a preguntas explīcitas, primero cree una sección contenedora de criterios.</f7-block>
 <f7-block>
     <p class="text-align-center text-color-orange" v-if="Object.values(tosend).length && !puedeEvaluarOGuardar">No pueden haber secciones sin preguntas</p>
        <f7-row>
            <f7-col><f7-button popup-close large outline color="red">Cancelar</f7-button></f7-col>
            <f7-col>
                 <f7-button :disabled="!puedeEvaluarOGuardar" v-if="false" @click="preparasave(false)" large outline raised color="teal" >Evaluar cambios</f7-button>
                 <f7-button :disabled="!puedeEvaluarOGuardar" v-else @click="preparasave(true)" large fill raised color="teal" >Guardar cambios</f7-button>
            </f7-col>
        </f7-row>
        </f7-block>
      </f7-page>
</template>
<script>
import { commonmixin } from '../mixins/common'

export default {
      mixins: [commonmixin],
    props:['id','reglaspreguntas'],
    data() {
      return {
          tosend:{
          }
      }
    },
      computed:{
        campanaEnFoco(){
         return this.$store.getters.getUno('campanas',this.id);
         },
          puedeEvaluarOGuardar(){
              let arrayado =Object.values(this.tosend);
              if(!arrayado.length){
                  return false;
              }
              let algunaSeccionSinCriterios = arrayado.some(unaseccion => {
                  return !Object.values(unaseccion.crits).length
              });
              return !algunaSeccionSinCriterios;
          }
      },
    created(){
        //reglaspreguntas
        this.tosend = JSON.parse(JSON.stringify(this.reglaspreguntas));
    },
    methods:{
        preparasave(realsave){
        console.log('vamosa a guardar');
        this.guardaModificacion({action:realsave?'guarda':'evalua', payload: this.tosend});
      },
         async guardaModificacion({action, payload}){
         try {
           this.$f7.dialog.preloader(action=='evalua'?'Evaluando impacto':'Guardando cambios');
           let datous = await this.commonExecute({campanaid: this.id, action, tipocriterio:'criterios', payload},'admin-cambiaCriteriosCampana',false);
            this.$f7.dialog.close();
            if(datous.error){
              throw new Error(datous.error.message);
            }
            console.log('creacion ok',datous);
            if(action=='evalua'){
             console.log('mostrar resultado evaluacion y mostrar boton guardar',datous.evaluacion)
            }
            else if(action=='guarda'){
                console.log('elguardado',datous.tosave),
                console.log('cerrar emitiendo');
                this.$emit('nuevosaved',datous.tosave);
            }

             } catch (error) {
             this.$f7.dialog.close();
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
        eliminaCriterio(seccionid,criterioid){
              this.$delete(this.tosend[seccionid].crits, criterioid);
        },
        eliminaseccion(secccionid){
            console.log('borrando',secccionid);
             this.$delete(this.tosend, secccionid);
        },
        addSection(){
            return this.$f7.dialog.prompt('Indique el nombre de la sección que contendrá criterios','Crear nueva sección',(nueva)=>{
                if(!nueva){
                    return this.$f7.dialog.alert('El nombre no puede ser vacīo','Error');
                }
                else{
                    console.log('Agregando seccion',nueva);
                    let nuevaSeccionId=this.$firebase.database().ref('campanas_criterios/'+this.id+'/criterios').push().key;
                    this.$set(this.tosend, nuevaSeccionId, {
                        id: nuevaSeccionId,
                        encab: nueva.trim(),
                        crits:{}
                    });
                }
            });
        },
        agregaCriterioASecc(secccionid){
            return this.$f7.dialog.prompt('Indique el texto a mostrar junto a un checkbox','Nuevo criterio',(nueva)=>{
                if(!nueva){
                    return this.$f7.dialog.alert('El criterio no puede ser vacīo','Error');
                }
                else{
                    console.log('Agregando criterio',nueva);
                    let nuevoCriterioId=this.$firebase.database().ref('campanas_criterios/'+this.id+'/criterios/'+secccionid+'/crits').push().key;
                    this.$set(this.tosend[secccionid].crits, nuevoCriterioId, {
                        id: nuevoCriterioId,
                        txt: nueva.trim(),
                        rule:'NA'
                    });
                }
            });
        }
    }
}
</script>
<style>
.noulpaddingleft ul{
  padding-left: 0px!important;
}

</style>