<template>
	<f7-card>
		<f7-card-header class="bold">
           {{esquema.name}}
           <div>
           <f7-preloader v-if="vacunaloading=='chgingname'"></f7-preloader>
           <template v-else>
            <f7-link  @click="editanombreesquema()" tooltip="Editar nombre"><f7-icon material="edit"></f7-icon></f7-link>
            <f7-link v-if="Object.keys(campanaEnFoco.esquemas).length>1" @click="confirmadelecion()" class="margin-left-half" tooltip="Eliminar esquema"><f7-icon material="delete"></f7-icon> </f7-link>
           </template>
            </div>
            </f7-card-header>
		<f7-card-content>
            <f7-list inset class="no-margin agerstric" :class="{conrestriccion: (esquema.agemin  || esquema.agemax) }">
                <f7-list-item :title="!esquema.agemin && !esquema.agemax?'Sin restricciones de edad':((esquema.agemin?`Desde ${esquema.agemin.y} años ${esquema.agemin.m} meses y ${esquema.agemin.d} días`:'')+' '+(esquema.agemax?`hasta ${esquema.agemax.y} años ${esquema.agemax.m} meses y ${esquema.agemax.d} días`:''))">
                    <f7-link @click="$emit('editaschemeagelimit',esquema.id)" tooltip="Definir restricción de edad" slot="after"><font-awesome-icon class="fa-lg" icon="wrench"></font-awesome-icon></f7-link>
                </f7-list-item>
            </f7-list>
			<f7-row class="bold">
				<f7-col class="text-align-left">
					Dia del esquema
				</f7-col>
				<f7-col class="text-align-left">
					Vacuna(s) requerida(s)
				</f7-col>
			</f7-row>
			<f7-row v-for="dia_del_esquema in esquema.cal" :key="dia_del_esquema.id" class="bordertoprowgray padding-top padding-bottom">
				<f7-col class="text-align-left">
					<h2 class="no-margin-bottom">Día {{dia_del_esquema.dnum}} <f7-preloader v-if="vacunaloading=='edtrng_'+esquema.id+dia_del_esquema.id"></f7-preloader><span v-else-if="dia_del_esquema.id!='Día 0'"> - Día {{dia_del_esquema.dto?dia_del_esquema.dto: dia_del_esquema.dnum}}<f7-link @click="editRange(esquema.id,dia_del_esquema.id)" tooltip="Editar rango permitido"><f7-icon material="edit"></f7-icon></f7-link></span></h2>
                    <f7-preloader v-if="vacunaloading=='deling'+dia_del_esquema.dnum"></f7-preloader>
                    <f7-link v-else-if="Object.keys(esquema.cal).length>1 && maxdiadelesquema==dia_del_esquema.dnum"
                    @click="guardaModificacion({
                        action:'del_lastday_esquema', 
                        valor:{ esquemaid: esquema.id},
                        vacunaloading:'deling'+dia_del_esquema.dnum})"
                    color="red"><font-awesome-icon class="margin-right-half" icon="calendar-times"></font-awesome-icon> Eliminar día</f7-link>
                    <f7-list media-list>
                        <f7-list-item
                        v-if="!dia_del_esquema.segu"
                        title="Seguimiento"
                        :footer="!formularios_publicados.length?'No hay formularios publicados. Ingrese al menu de formularios y configure uno':'Haga click para seleccionar un formulario y determinar frecuencia y duración del seguimiento'"
                        >
                        <f7-preloader v-if="enedicion =='set_seguimiento_fecha'+esquema.id+dia_del_esquema.id+'segu'" slot="after"></f7-preloader>
                        <f7-button v-else outline color="teal"  @click="$emit('abreSegumiento',{diaid:dia_del_esquema.id,esquemaid: esquema.id,cualx:'segu'})" slot="after"><font-awesome-icon class="fa-lg" icon="folder-plus"></font-awesome-icon></f7-button>
                        </f7-list-item>
                        <template v-else>
                        <f7-list-item
                        header="Seguimiento activo en base a formulario:"
                        :title="$store.getters.getUno('formularios',dia_del_esquema.segu.formid).name"
                        :text="`Enviar los dias: ${Object.keys(dia_del_esquema.segu.when).filter((unseg)=>{ return  dia_del_esquema.segu.when[unseg]}).join('|')}`"
                        >
                        <f7-preloader v-if="enedicion =='borra_seguimiento_fecha'+esquema.id+dia_del_esquema.id+'segu'" slot="after"></f7-preloader>
                        <f7-link v-else-if="!dia_del_esquema.segu2" @click="$emit('borra_seguimiento_fecha',{diaid:dia_del_esquema.id,esquemaid: esquema.id,cualx:'segu'})" color="gray"  slot="after"><font-awesome-icon icon="trash"></font-awesome-icon></f7-link>
                        </f7-list-item>
                         <f7-list-item
                        v-if="!dia_del_esquema.segu2"
                        title="Seguimiento complementario"
                        :footer="!formularios_publicados.length?'No hay formularios publicados. Ingrese al menu de formularios y configure uno':'Haga click para seleccionar un formulario y determinar frecuencia y duración del seguimiento complementario'"
                        >
                        <f7-preloader v-if="enedicion =='set_seguimiento_fecha'+esquema.id+dia_del_esquema.id+'segu2'" slot="after"></f7-preloader>
                        <f7-button v-else outline color="teal"  @click="$emit('abreSegumiento',{diaid:dia_del_esquema.id,esquemaid: esquema.id,cualx:'segu2'})" slot="after"><font-awesome-icon class="fa-lg" icon="folder-plus"></font-awesome-icon></f7-button>
                        </f7-list-item>
                        <template v-else>
                        <f7-list-item
                        header="Seguimiento activo en base a formulario:"
                        :title="$store.getters.getUno('formularios',dia_del_esquema.segu2.formid).name"
                        :text="`Enviar los dias: ${Object.keys(dia_del_esquema.segu2.when).filter((unseg)=>{ return  dia_del_esquema.segu2.when[unseg]}).join('|')}`"
                        >
                        <f7-preloader v-if="enedicion =='borra_seguimiento_fecha'+esquema.id+dia_del_esquema.id+'segu2'" slot="after"></f7-preloader>
                        <f7-link v-else @click="$emit('borra_seguimiento_fecha',{diaid:dia_del_esquema.id,esquemaid: esquema.id,cualx:'segu2'})" color="gray"  slot="after"><font-awesome-icon icon="trash"></font-awesome-icon></f7-link>
                        </f7-list-item>
                        </template>
                        </template>

                    </f7-list>
				</f7-col>
				<f7-col v-for="unreq in dia_del_esquema.req" :key="unreq.id" class="text-align-center borlex">
                    <f7-preloader v-if="vacunaloading==dia_del_esquema.id+unreq.id+'0'"></f7-preloader>
                    <f7-block v-else-if="!unreq.alts || !Object.keys(unreq.alts).length ">
					    <f7-button @click="invokeVaccineSelector(dia_del_esquema.id,unreq.id,'0')" outline><font-awesome-icon icon="syringe"></font-awesome-icon> Seleccionar vacuna</f7-button>
                    </f7-block>
                    <f7-block v-else>
                        <f7-block v-for="vacid in unreq.alts" :key="vacid"><f7-chip class="limitwidh" media-bg-color="teal"  :text="$store.getters.unaVacuna(vacid).vacunaPcName" @click="guardaModificacion({action:'del_vac_from_set_req', valor:{esquemaid: esquema.id, diaid: dia_del_esquema.id, reqid: unreq.id, vacunaid: vacid },vacunaloading:dia_del_esquema.id+unreq.id+vacid})" :deleteable="vacunaloading!==dia_del_esquema.id+unreq.id+vacid"><f7-preloader slot="media" v-if="vacunaloading==dia_del_esquema.id+unreq.id+vacid"></f7-preloader><font-awesome-icon v-else slot="media" icon="syringe"></font-awesome-icon> </f7-chip><br/> - ó -</f7-block>
                        <f7-preloader v-if="vacunaloading==dia_del_esquema.id+unreq.id+'1'"></f7-preloader>
                        <f7-link v-else @click="invokeVaccineSelector(dia_del_esquema.id,unreq.id,1)">Agregar alternativa</f7-link>
                    </f7-block>
				</f7-col>
                <f7-col v-if="Object.values(dia_del_esquema.req)[0].alts && Object.keys(Object.values(dia_del_esquema.req)[0].alts).length" class="text-align-center borlex">
                    <f7-block>
                        <f7-preloader v-if="vacunaloading==dia_del_esquema.id+'0'+'0'"></f7-preloader>
                        <f7-link v-else @click="invokeVaccineSelector(dia_del_esquema.id,null,'0')">Agregar otra vacuna requerida {{dia_del_esquema.dto?`entre el ${dia_del_esquema.id} y el Día ${dia_del_esquema.dto}`: `el ${dia_del_esquema.id}` }}</f7-link></f7-block>
                </f7-col>
			</f7-row>
			<f7-row class="bordsupx">
				<f7-col>
                    <f7-preloader v-if="vacunaloading=='addingDay'"></f7-preloader>
                    <f7-button v-else @click="preaddDia()"><font-awesome-icon class="fa-lg" icon="calendar-plus"></font-awesome-icon> Agregar día al esquema</f7-button></f7-col>
			</f7-row>
		</f7-card-content>
    <f7-popup class="innerpop" :opened="popupOpened" @popup:closed="popupOpened = false">
      <f7-page>
           <f7-searchbar
                        class="no-margin"
                        search-container=".vacstoselec"
                        search-in=".item-title,.item-text"
                        placeholder="Busque nombre vacuna o enfermedad"
                        :backdrop="false"
                    ></f7-searchbar>
    <f7-list class="no-margin-vertical searchbar-not-found">
        <f7-list-item title="Sin resultados"></f7-list-item>
    </f7-list>
    <f7-list class="no-margin-vertical vacstoselec">
        <f7-list-item
            v-if="!vacunas.length"
            title="No existen vacunas creadas,"
        ></f7-list-item>
        <f7-list-item
            v-for="vacuna in solovacpublicadas"
            :key="vacuna.vacunaPcId"
            :title="`${vacuna.vacunaPcName} (${vacuna.laboratorioName}) `"
            :subtitle="vacuna.presentacion"
            radio :value="vacuna.vacunaPcId" name="eligevacunaschem"
             @change="eligeVacuna($event.target.value)"
            :checked="chosenvax === vacuna.vacunaPcId"
        >
        </f7-list-item>
    </f7-list>
      </f7-page>
    </f7-popup>
	</f7-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { commonmixin } from '../mixins/common';

export default {
	mixins: [commonmixin],
	props: ['campanaid','esquema','enedicion'],
	data() {
		return {
            popupOpened: false,
            chosenvax:null,
            vacunaloading: null,
            toaddVaccine:{
                diaid: null,
                reqid: null,
                preloadpos: 0
            }
		};
	},
	computed: {
        ...mapGetters(['vacunas','formularios_publicados']),
       campanaEnFoco(){
        return this.$store.getters.getUno('campanas',this.campanaid);
      },
    solovacpublicadas(){
          return this.vacunas.filter(unavac=>{
              return !unavac.borrador
          })
      },
      maxdiadelesquema(){
           let diasExistentes = Object.values(this.campanaEnFoco.esquemas[this.esquema.id].cal).map(undia=>{
            return parseInt(undia.dnum);
        });
        return Math.max(...diasExistentes);
      }
	},

	methods: {
        confirmadelecion(){
            return this.$f7.dialog.confirm('Confirme que desea eliminar este esquema de vacunación','Confirme',()=>{
                this.guardaModificacion({
                        action:'elimina_esquema_alt', 
                        valor:this.esquema.id,
                vacunaloading:'chgingname'})
            });
        },
        editanombreesquema(){
            this.$f7.dialog.prompt('Indique nuevo nombre del esquema','Editando nombre',(nuevonmae)=>{
                if(!nuevonmae){
                    return this.$f7.dialog.alert('Nombre no puede ser vacío');
                }
                let aguardar = nuevonmae.trim();
                this.guardaModificacion({
                        action:'edita_esquema_name', 
                        valor:{
                            esquemaid: this.esquema.id,
                            nuevoname: aguardar
                        },
                vacunaloading:'chgingname'})
            },()=>{},this.campanaEnFoco.esquemas[this.esquema.id].name);
        },
        editRange(esquemaid,diaid){
           this.$f7.dialog.prompt(`Desde el día ${this.campanaEnFoco.esquemas[esquemaid].cal[diaid].dnum} hasta el día:`,'Indique el rango permitido',(eldia)=>{
                if(!eldia || isNaN(eldia)){
                    this.$f7.dialog.alert('Debe ingresar un número entero');
                }
                else{
                    let elnuevoida=parseInt(eldia);
                    //check que sea mayor al primer diapermitido en este 'dia
                    if(elnuevoida<this.campanaEnFoco.esquemas[esquemaid].cal[diaid].dnum){
                        this.$f7.dialog.alert('El día limite debe ser mayor al día inicial');
                    }
                    else{
                        console.log('el dia es',elnuevoida);
                        //add_dia_a_esquema
                        this.guardaModificacion({
                        action:'edita_rango_dias_dosis_subsecuentes', 
                        valor:{
                            esquemaid: esquemaid,
                            diaid: diaid,
                            nuevodiaid: elnuevoida
                        },
                        vacunaloading:'edtrng_'+esquemaid+diaid})
                    }
                }
            },()=>{},this.campanaEnFoco.esquemas[esquemaid].cal[diaid].dto);
        },
        preaddDia(){
            this.$f7.dialog.prompt('0 es el dia de la primera vacuna','Indique el número del día',(eldia)=>{
                if(!eldia || isNaN(eldia)){
                    this.$f7.dialog.alert('Debe ingresar un número entero');
                }
                else{
                    let elnuevoida=parseInt(eldia);
                    //check que sea mayor al ultimo dia de este esquema
                    let diasExistentes = Object.values(this.esquema.cal).map(undia=>{
                       return parseInt(undia.dnum);
                    });
                    if(elnuevoida<=this.maxdiadelesquema){
                        this.$f7.dialog.alert('El nuevo dia debe ser mayor al ultimo dia del esquema');
                    }
                    else{
                        console.log('el dia es',elnuevoida);
                        //add_dia_a_esquema
                        this.guardaModificacion({
                        action:'add_dia_a_esquema', 
                        valor:{
                            esquemaid: this.esquema.id,
                            nuevodiaid: elnuevoida
                        },
                        vacunaloading:'addingDay'})
                    }
                }
            });
        },
        invokeVaccineSelector(diaid,reqid,preloadpos){
            this.toaddVaccine.diaid=diaid;
            this.toaddVaccine.reqid=reqid;
             this.toaddVaccine.preloadpos=preloadpos;
             this.chosenvax = null;
            this.popupOpened=true;
        },

        eligeVacuna(vacunaidx){
            console.log('eligio vacuna',vacunaidx);
            if(vacunaidx){
                this.chosenvax = vacunaidx;
                //proceder
                this.popupOpened =false;
                if(this.toaddVaccine.reqid){
                    this.guardaModificacion({
                        action:'add_vac_a_set_req', 
                        valor:{
                            esquemaid: this.esquema.id,
                            diaid: this.toaddVaccine.diaid,
                            reqid: this.toaddVaccine.reqid,
                            vacunaid: vacunaidx
                        },
                        vacunaloading:this.toaddVaccine.diaid+this.toaddVaccine.reqid+this.toaddVaccine.preloadpos
                        })
                }
                else{
                    this.guardaModificacion({
                        action:'add_vac_a_nuevo_set_req', 
                        valor:{
                            esquemaid: this.esquema.id,
                            diaid: this.toaddVaccine.diaid,
                            vacunaid: vacunaidx
                        },
                        vacunaloading:this.toaddVaccine.diaid+'0'+this.toaddVaccine.preloadpos})
                }
            }
        },
        async guardaModificacion({action, valor,vacunaloading}){
         try {
           this.vacunaloading=vacunaloading;
           let datous = await this.commonExecute({id: this.campanaid, action, valor},'admin-editaCampana',false);
           this.vacunaloading=null;
            if(datous.error){
              throw new Error(datous.error.message);
            }
            console.log('creacion ok',datous);
            let clonedCampana = Object.assign({},this.campanaEnFoco);
            if(action=='add_vac_a_set_req'){
                if(!clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].req[valor.reqid].alts){
                    clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].req[valor.reqid].alts={};
                }
              clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].req[valor.reqid].alts[valor.vacunaid] = valor.vacunaid;
            }
            else if(action=='del_vac_from_set_req'){
                if(datous.eliminarEsteSet){
                    clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].req[valor.reqid] = null;
                    delete clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].req[valor.reqid];
                }
                else{
                    clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].req[valor.reqid].alts[valor.vacunaid] = null;
                    delete clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].req[valor.reqid].alts[valor.vacunaid];
                }
            }
            else if(action=='add_vac_a_nuevo_set_req'){
                let nuevorequid= datous.nuevoRequerimientoId;
                let neoals={};
                neoals[valor.vacunaid] = valor.vacunaid;
                clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].req[nuevorequid]={
                    id: nuevorequid,
                    alts:neoals
                };
            }
            else if(action=='add_dia_a_esquema'){
                let nuevorequid= datous.nuevoRequerimientoId;
                 let nuevoreq={};
                    nuevoreq[nuevorequid]={
                    id: nuevorequid
                    };
                    let nuevodiaid='Día '+valor.nuevodiaid;
                clonedCampana.esquemas[this.esquema.id].cal[nuevodiaid]={
                     dnum: valor.nuevodiaid,
                     id: nuevodiaid,
                    req: nuevoreq
                };
            }
            else if(action=='del_lastday_esquema'){
                 clonedCampana.esquemas[this.esquema.id].cal[datous.delday] = null;
                delete clonedCampana.esquemas[this.esquema.id].cal[datous.delday];
            }
            else if(action=='edita_rango_dias_dosis_subsecuentes'){
                clonedCampana.esquemas[this.esquema.id].cal[valor.diaid].dto=valor.nuevodiaid;
            }
            else if(action=='edita_esquema_name'){
                clonedCampana.esquemas[this.esquema.id].name = valor.nuevoname;
                clonedCampana.esquemas[this.esquema.id].namenorm = this.normalize(valor.nuevoname);
            }
            else if(action=='elimina_esquema_alt'){
                clonedCampana.esquemas[this.esquema.id] = null;
                delete clonedCampana.esquemas[this.esquema.id];
            }
              this.$store.commit(
                'updtObjProperty',{
                  what:'campanas',
                  to: Object.assign({},clonedCampana)
                });
         
              this.avisaOk();
             } catch (error) {
            this.vacunaloading=null;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
	},
};
</script>
<style>
.borlex{
    border-left: 1px dotted rgb(17, 22, 43);
    padding-bottom: 2px;
}
.bordsupx{
    border-top: 1px dotted rgb(17, 22, 43);
}
.limitwidh{
    max-width: 100%;
}
.agerstric{
    background:#e8e8e8;
}
.conrestriccion{
    background: #ffe8bd!important;
}
.bordertoprowgray{
    border-bottom: 1px dotted;
}
</style>
