<template>
    <f7-page>
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="Admnistración de cuentas" title="Cuentas"></f7-nav-title>
        </f7-navbar>

        <f7-toolbar top tabbar>
            <f7-link tab-link="#accounts" tab-link-active>Cuentas</f7-link>
            <f7-link v-if="$store.getters.userHasRolesPrivilege" tab-link="#roles">Roles</f7-link>
        </f7-toolbar>

        <f7-tabs animated>
            <f7-tab id="accounts" tab-active class="page-content no-padding">
                <f7-block>
                    <f7-row>
                        <f7-col>
                            <f7-block-title>Buscar cuenta por</f7-block-title>
                        </f7-col>
                        <f7-col class="text-align-right">
                            <f7-button @click="createAccountPopupOpened = true" fill>
                                Crear nueva cuenta
                                <font-awesome-icon icon="user-plus"></font-awesome-icon>
                            </f7-button>
                        </f7-col>
                    </f7-row>
                    <AccountSearchForm @found-accounts="handleFoundAccounts" />

                    <template v-if="accounts.length">
                        <div class="display-flex justify-content-space-between align-items-center">
                            <f7-block-title class="no-margin-horizontal">Se han encontrado {{ accounts.length }} cuentas</f7-block-title>
                            <f7-button @click="exportAccounts" tooltip="Exportar cuentas de usuario">
                                <font-awesome-icon class="fa-lg" icon="download"></font-awesome-icon>
                            </f7-button>
                        </div>
                        <AccountsTable
                            :accounts="accounts"
                            @edited-account="handleEditedAccount"
                            @account-active-status-changed="handleAccountActiveStatusChanged"
                        />
                    </template>
                </f7-block>
            </f7-tab>
            <f7-tab id="roles">
                <f7-block>
                    <RolesTable />
                </f7-block>
            </f7-tab>
        </f7-tabs>

        <f7-popup
            class="crear-cuenta"
            :close-by-backdrop-click="false"
            :opened="createAccountPopupOpened"
            @popup:closed="createAccountPopupOpened = false"
        >
            <CreateAccountPopup
                v-if="createAccountPopupOpened"
                @abortCreacion="createAccountPopupOpened = false"
                @gotoCuenta="handleAccountCreated"
            />
        </f7-popup>
    </f7-page>
</template>

<script>
import AccountSearchForm from "../components/AccountSearchForm.vue";
import AccountsTable from "../components/AccountsTable.vue";
import RolesTable from "../components/RolesTable.vue";
import CreateAccountPopup from "./cuenta_crea_popup.vue";
import { commonmixin } from "../mixins/common";
export default {
    components: {
        AccountSearchForm,
        AccountsTable,
        RolesTable,
        CreateAccountPopup,
    },

    mixins: [commonmixin],

    data() {
        return {
            accounts: [],

            createAccountPopupOpened: false,
        };
    },

    methods: {
        handleFoundAccounts(accounts) {
            this.accounts = accounts;

            if (!accounts.length) {
                this.$f7.dialog.alert("No se han encontrado cuentas con los criterios de búsqueda especificados.");
            }
        },
        handleAccountCreated(account) {
            this.accounts.push(account);
            this.createAccountPopupOpened = false;
        },
        handleEditedAccount(account) {
            const index = this.accounts.findIndex((a) => a.id === account.id);
            if (index !== -1) this.$set(this.accounts, index, account);
        },
        handleAccountActiveStatusChanged(uid, inactive) {
            const index = this.accounts.findIndex((a) => a.uid === uid);
            if (index !== -1) this.$set(this.accounts[index], "inactive", inactive);
        },
        async exportAccounts() {
            try {
                this.$f7.dialog.preloader("Exportando cuentas de usuario...");

                const response = await this.commonExecute({}, "admin-exportAccounts", false);

                if (response.error) {
                    throw new Error(response.error);
                }

                // Download file in base64
                this.downloadBase64File(response.xlsxData, "cuentas_de_usuario.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet");

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error.message);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message);
            }
        },

        downloadBase64File(base64Data, fileName, mimeType) {
            const blobData = this.base64ToBlob(base64Data, mimeType);
            const url = window.URL.createObjectURL(blobData);
            const link = document.createElement("a");

            document.body.appendChild(link);
            link.href = url;
            link.setAttribute("download", fileName);
            link.classList.add("external");
            link.click();
            window.URL.revokeObjectURL(url);
        },

        base64ToBlob(base64Data, mimeType) {
            const byteCharacters = atob(base64Data);
            const byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);

                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: mimeType });
        },
    },
};
</script>
