<template>
    <f7-page @page:beforein="getvacunatorios()" name="vacunatorios">
        <!-- Top Navbar -->
        <f7-navbar>
            <f7-nav-left>
                <f7-link icon-material="menu" panel-open="left"></f7-link>
            </f7-nav-left>
            <f7-nav-title subtitle="Listado" title="Vacunatorios"></f7-nav-title>
            <f7-nav-right>
                <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-material="search"></f7-link>
            </f7-nav-right>
            <f7-searchbar
                class="searchbar-demo"
                expandable
                placeholder="Busque nombre o localidad"
                search-container=".vacunatorios"
                search-in=".item-title,.item-text"
            ></f7-searchbar>
        </f7-navbar>

        <f7-block class="text-align-center" v-if="cargandoVacunatorios"><f7-preloader></f7-preloader><br />Cargando vacunatorios...</f7-block>
        <f7-block v-else-if="!vacunatorios.length" class="text-align-center">No hay vacunatorios creados</f7-block>

        <f7-list
            ref="myvaculist"
            media-list
            class="vacunatorios no-margin"
            virtual-list
            :virtual-list-params="{ items: vacunatorios, searchAll, renderExternal, height: 93 }"
        >
            <ul>
                <f7-list-item
                    v-for="(item, index) in vlData.items"
                    :key="index"
                    :title="item.name"
                    link="#"
                    @click="gotoVac(item)"
                    :subtitle="item.direccion"
                    :text="`${item.address.name} (${item.address.provname}), ${item.address.region}`"
                    :style="`top: ${vlData.topPosition}px`"
                >
                    <span :class="item.inactive ? 'text-color-red' : 'text-color-teal'" slot="after">{{
                        item.inactive ? "Inactivo" : "Activo"
                    }}</span>
                </f7-list-item>
            </ul>
        </f7-list>

        <f7-fab position="right-bottom" slot="fixed" color="primary">
            <f7-icon material="settings"></f7-icon>
            <f7-icon material="close"></f7-icon>
            <f7-fab-buttons position="top">
                <f7-fab-button fab-close @click="modalCreaVacOpened = true" label="Crear nuevo vacunatorio"
                    ><font-awesome-icon class="fa-lg" icon="plus"></font-awesome-icon
                ></f7-fab-button>
                <f7-fab-button fab-close @click="generaDescarga('eg')" label="Descargar vacunatorios"
                    ><font-awesome-icon icon="cloud-download-alt"></font-awesome-icon
                ></f7-fab-button>
            </f7-fab-buttons>
        </f7-fab>

        <f7-popup class="crea-vac" :opened="modalCreaVacOpened" @popup:closed="modalCreaVacOpened = false">
            <crea-vacunatorio v-if="modalCreaVacOpened" @creado="creadoOk"></crea-vacunatorio>
        </f7-popup>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import creaVacunatorio from "./vacunatorio_crea.vue";
import axios from "axios";

export default {
    components: { creaVacunatorio },
    data() {
        return {
            modalCreaVacOpened: false,
            cargandoVacunatorios: false,
            vlData: {
                items: [],
            },
        };
    },
    computed: {
        ...mapState(["user"]),
        ...mapGetters(["centralAdminPrivs", "vacunatorios"]),
    },
    watch: {
        vacunatorios(neovasc) {
            // console.log('hay nuevos vacunatorios',neovasc);
            this.$refs.myvaculist.f7VirtualList.replaceAllItems(neovasc);
        },
    },
    mounted() {
        // console.log('centralAdminPrivs',this.centralAdminPrivs)
    },
    methods: {
        async generaDescarga() {
            //  console.log('descagando')
            try {
                this.$f7.dialog.preloader("Descargando vacunatorios creados en Vacunator...");
                let idToken = await this.$firebase.auth().currentUser.getIdToken();
                let envx = this.$store.state.dev ? "dev" : "prod";

                let response = await axios.get(`${this.$store.getters.getApiUrl}/prosex-exporter_isp/corxpt/vacunators/nuevos/${envx}`, {
                    headers: { Authorization: "Bearer " + idToken },
                    responseType: "blob", // important
                });
                this.$f7.dialog.close();
                //  console.log(response.data);
                if (response.data.type == "application/json") {
                    var textPromise = await response.data.text();
                    //  console.log('error',textPromise);
                    return this.$f7.dialog.alert(textPromise, "Error");
                }
                const fileURL = window.URL.createObjectURL(
                    new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })
                );
                let fileLink = document.createElement("a");
                document.body.appendChild(fileLink);
                fileLink.href = fileURL;
                fileLink.setAttribute("download", "vacunatorios.xlsx");
                fileLink.classList.add("external");
                fileLink.click();
                window.URL.revokeObjectURL(fileURL);
                // this.avisaOk('Descarga OK');
            } catch (error) {
                this.$f7.dialog.close();
                console.log(error, error.message, error.response, error?.response?.data);
                this.$f7.dialog.alert(error.response?.data?.message || error.message);
            }
        },
        searchAll(query, items) {
            const found = [];
            let elkery = query.toUpperCase().replace(/ /g, "");
            for (let i = 0; i < items.length; i += 1) {
                if (query.trim() === "" || items[i].norm.indexOf(elkery) >= 0) found.push(i);
            }
            return found;
        },
        renderExternal(vl, vlData) {
            this.vlData = vlData;
        },
        getvacunatorios() {
            if (!this.vacunatorios.length || !this.$store.state.lastFullLoad.vacunatorios) {
                this.cargandoVacunatorios = true;
                this.$firebase
                    .database()
                    .ref("vacunatorios")
                    .once("value")
                    .then(snpshot => {
                        let allvasc = snpshot.val() || {};
                        this.$store.commit("setWhatTo", { what: "vacunatorios", to: allvasc });
                        this.cargandoVacunatorios = false;
                    })
                    .catch(error => {
                        this.cargandoVacunatorios = false;
                        console.error(error);
                        this.$f7.dialog.alert(error.message, error.code);
                    });
            }
        },
        creadoOk(nuevocentro) {
            // console.log('Llegao nueo centro',nuevocentro);
            this.modalCreaVacOpened = false;
            this.$store.commit("updtObjProperty", { what: "vacunatorios", to: nuevocentro });
            this.gotoVac(nuevocentro);
        },
        gotoVac(vacunaotiro) {
            this.$f7.views.main.router.navigate("/vacunatorio/", {
                props: vacunaotiro,
            });
        },
    },
};
</script>
<style></style>
