<template>
    <f7-popup :opened="opened" @popup:opened="getPatientLogs" @popup:closed="$emit('closed')">
        <f7-page>
            <f7-navbar title="Viendo logs del paciente">
                <f7-nav-right>
                    <f7-link @click="$emit('closed')">Cerrar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="loading" class="text-align-center">
                <f7-preloader></f7-preloader>
            </f7-block>
            <f7-block v-else-if="!logs.length">
                <f7-block class="text-align-center">
                    <f7-icon f7="exclamationmark_circle_fill"></f7-icon>
                </f7-block>
                <f7-block class="text-align-center">
                    No hay registros de modificaciones de datos del paciente.
                </f7-block>
            </f7-block>
            <f7-list media-list v-else>
                <f7-list-item v-for="log in sortedLogs" :key="log.id">
                    <span slot="title">
                        {{ log.actionText }}
                    </span>
                    <span slot="after">
                        {{ $dayjs.unix(log.timestamp).format("DD/MM/YYYY HH:mm:ss") }} ({{ $dayjs.unix(log.timestamp).fromNow() }})
                    </span>
                    <span slot="subtitle">
                        Cambió de "<b>{{ log.from || 'No establecido' }}</b>" a "<b>{{ log.to }}</b>"
                    </span>
                    <span slot="footer">
                        Usuario que realizó la acción: {{ log.userName }}
                    </span>
                </f7-list-item>
            </f7-list>
        </f7-page>
    </f7-popup>
</template>
<script>
import { commonmixin } from "../mixins/common";
export default {
    mixins: [commonmixin],

    props: {
        opened: {
            type: Boolean,
            default: false,
        },
        patientId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            logs: [],
            loading: true,
        };
    },

    computed: {
        sortedLogs() {
            return this.logs.sort((a, b) => {
                return a.timestamp < b.timestamp ? 1 : -1;
            });
        },
    },

    methods: {
        async getPatientLogs() {
            try {
                this.loading = true;

                const response = await this.commonExecute({ patientId: this.patientId }, "admin-getPatientLogs", false);

                if (response.error) {
                    throw new Error(response.error);
                }

                this.logs = response.logs;
                this.loading = false;

                this.$f7.dialog.close();
            } catch (error) {
                console.error(error);
                this.$f7.dialog.close();
                this.$f7.dialog.alert(error.message, "Hubo un error al obtener los registros del paciente.");
            }
        },
    },
};
</script>
