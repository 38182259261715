<template>
    <f7-page name="cuenta" @page:beforein="checkdixpriv()">
        <!-- Top Navbar -->
        <f7-navbar :title="admn.em" :subtitle="formatRut(admn.id)" back-link="Back"></f7-navbar>

        <f7-block class="text-align-center no-margin-bottom">
            <img class="superavatar" src="../static/heros/avatar.jpg" /><br />
            <f7-chip media-bg-color="primary" :text="admn.em"
                ><font-awesome-icon icon="user-circle" class="fa-lg" slot="media"></font-awesome-icon
            ></f7-chip>
        </f7-block>
        <f7-block class="margin-bottom no-margin-top"><f7-block-title>Información del usuario</f7-block-title></f7-block>
        <f7-list inset class="no-margin-top">
            <f7-list-item
                v-for="(campo, indx) in flds"
                @click="updateValue(indx)"
                :key="indx"
                link="#"
                :header="campo"
                :title="indx == 'id' ? formatRut(admn[indx]) : admn[indx]"
            >
                <f7-preloader slot="after" v-if="updt[indx]"></f7-preloader>
                <font-awesome-icon v-else slot="after" icon="pencil-alt"></font-awesome-icon>
            </f7-list-item>
        </f7-list>
        <f7-block class="margin-bottom"><f7-block-title>Privilegios en este vacunatorio</f7-block-title></f7-block>
        <f7-block class="text-align-center" v-if="cargandoPrivsBysite"
            ><f7-preloader></f7-preloader><br />Cargando privilegios por vacunatorio</f7-block
        >
        <f7-block class="text-align-center" v-else-if="!Object.values(prv).length">Usuario sin privilegios aquí</f7-block>

        <f7-list class="no-margin-top" inset>
            <f7-list-item :footer="unpriv.descr" v-for="unpriv in privsPorVacunatorio" :key="unpriv.id" :title="unpriv.id">
                <f7-preloader slot="after" class="margin-right-half" v-if="actualizando == unpriv.id"></f7-preloader>
                <f7-toggle slot="after" @toggle:change="cambiandoPrivVacunat($event, unpriv.id)" color="teal" :checked="prv[unpriv.id]"></f7-toggle>
            </f7-list-item>
        </f7-list>
    </f7-page>
</template>
<script>
import { mapState } from "vuex";
import { commonmixin } from "../mixins/common";
export default {
    mixins: [commonmixin],
    props: ["info", "prv", "uid"],
    data() {
        return {
            flds: {
                id: "RUT",
                nms: "Nombre(s)",
                ap1: "Primer Apellido",
                ap2: "Segundo Apellido",
            },
            updt: {
                id: false,
                nms: false,
                ap1: false,
                ap2: false,
            },
            cargandoPrivsBysite: false,
            actualizando: null,
        };
    },
    computed: {
        ...mapState(["privsCentrales", "privsPorVacunatorio", "centerInFoco"]),
        admn() {
            return this.info || {};
        },
    },
    watch: {
        centerInFoco(nuevocentro) {
            console.log("ATENTO, CAMBIO DE CENTRO", nuevocentro);
            this.$f7.views.main.router.back();
        },
    },

    methods: {
        nuevosprivsenvac(payerloadx) {
            console.log("nuevos privilegios", payerloadx);
            this.vacprivsopened = false;
            this.addVacunatToUser(payerloadx);
        },
        async cambiandoPrivVacunat(nuevovalor, privid) {
            try {
                this.actualizando = privid;
                let datous = await this.commonExecute(
                    { uid: this.uid, cual: privid, neox: nuevovalor, vacunatorioid: this.centerInFoco },
                    "admin-daPrivsEnVacunatorio",
                    false
                );
                this.actualizando = null;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                //todo modicar esto
                let susprivsClonados = Object.assign({}, this.prv);
                susprivsClonados[privid] = nuevovalor;
                let usersExistentesAqui = this.$store.getters.getUno("cuentasByCentro", this.centerInFoco) || {};
                if (!usersExistentesAqui[this.uid]) {
                    //meterlo primero
                    this.$store.commit("addItemNiveltree", {
                        a1: "cuentasByCentro",
                        b2: this.centerInFoco,
                        c3: this.uid,
                        d4: { cid: this.centerInFoco, uid: this.uid, prv: {}, info: this.info },
                    });
                }
                this.$store.commit("addItemAColeccionNested", {
                    vid: this.centerInFoco,
                    col: "cuentasByCentro",
                    prop: this.uid,
                    pid: "prv",
                    val: susprivsClonados,
                });
                this.avisaOk();
            } catch (error) {
                this.actualizando = null;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        updateValue(cual) {
            return this.$f7.dialog.prompt(
                "Indique un nuevo " + this.flds[cual],
                cambio => {
                    if (!cambio) {
                        return this.$f7.dialog.alert("Nueva propiedad no puede ser vacía", "Error");
                    }
                    console.log("nuevo es", cual, cambio);
                    this.guardarCambio({
                        uid: this.uid,
                        cual: cual,
                        valor: cambio.trim(),
                    });
                },
                () => {},
                this.admn[cual]
            );
        },
        async guardarCambio({ uid, cual, valor }) {
            try {
                this.updt[cual] = true;
                let datous = await this.commonExecute({ uid, cual, valor, vacunatorioid: this.centerInFoco }, "admin-editaCuentaInfo", false);
                this.updt[cual] = false;
                if (datous.error) {
                    throw new Error(datous.error.message);
                }
                console.log("creacion ok", datous);
                this.admn[cual] = valor;
                this.$store.commit("addItemAColeccionNested", {
                    vid: this.centerInFoco,
                    col: "cuentasByCentro",
                    prop: uid,
                    pid: "info",
                    val: this.admn,
                });
                this.avisaOk();
            } catch (error) {
                this.updt[cual] = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        async checkdixpriv() {
            try {
                this.cargandoPrivsBysite = true;
                let promesex = [];
                if (!Object.keys(this.privsCentrales).length) {
                    promesex.push(
                        this.$firebase
                            .database()
                            .ref("dix")
                            .once("value")
                    );
                }
                let multisnapsx = await Promise.all(promesex);
                if (multisnapsx[0]) {
                    let privs = multisnapsx[0];
                    this.$store.commit("setWhatTo", { what: "privsCentrales", to: privs.child("privs").val() || {} });
                    this.$store.commit("setWhatTo", { what: "privsPorVacunatorio", to: privs.child("vacprivs").val() });
                }
                this.cargandoPrivsBysite = false;
            } catch (error) {
                this.cargandoPrivsBysite = false;
                console.error(error);
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
    },
};
</script>
<style>
.superavatar {
    width: 150px;
    border-radius: 50%;
    border: 6px solid white;
}
</style>
