<template>
  <f7-card >
    <f7-card-header>{{camp.nombre}}</f7-card-header>
        <f7-card-content>
          <f7-list v-for="(esquema,eid) in campEsquemasFechasSorted.esquemas" :key="eid" class="nowrpwhtspac fixfamarg" media-list>
            <f7-list-item divider :title="`Esquema: ${esquema.nombre}`" ></f7-list-item>
            <div class="timeline margin-vertical">
               <div v-for="(undia,indx) in esquema.fechas" :key="undia.id" class="timeline-item">
                <div class="timeline-item-date text-align-center">{{('dosis '+(indx+1))}}</div>
                <div class="timeline-item-divider"></div>
                <div class="timeline-item-content">
                 <f7-list class="no-margin-vertical" media-list>
                     <f7-list-item
                      v-for="unreq in undia.req" :key="unreq.id"
                      class="titlebold"
                      :subtitle="unreq.completado?unreq.completado.laboratorio:null"
                      :title="unreq.completado?`${unreq.completado.vacuna}`:'Vacuna pendiente. Recibirá un mensaje vía correo electrónico recordando la fecha de esta dosis.'"
                      :header="unreq.completado?$dayjs(unreq.completado.fecha).format('DD MMM YYYY'):null"
                      :text="unreq.completado?unreq.completado.centro:null"
                      :footer="unreq.completado?`Lote: ${unreq.completado.lote}`:null"
                      >
                      <font-awesome-icon v-if="unreq.completado" class="margin-left-half" color="teal" icon="check" slot="title"></font-awesome-icon>
                      <font-awesome-icon v-else class="margin-left-half" color="red" icon="exclamation-triangle" slot="title"></font-awesome-icon>
                      </f7-list-item>
                 </f7-list>
                </div>
              </div>
            </div>
          </f7-list>
           <f7-list v-if="camp.refuerzos && Object.keys(camp.refuerzos).length" class="no-margin-top nowrpwhtspac fixfamarg" media-list>
              <f7-list-item divider :title="`Refuerzo${Object.keys(camp.refuerzos).length==1?'':'s'}`" ></f7-list-item>
              <div class="timeline margin-vertical">
               <div v-for="(refz,indx) in sortedRefuerzos" :key="refz.id" class="timeline-item">
                <div class="timeline-item-date text-align-center">{{((indx+1))}}</div>
                <div class="timeline-item-divider"></div>
                <div class="timeline-item-content">
                 <f7-list class="no-margin-vertical" media-list>
                     <f7-list-item
                      class="titlebold"
                      :subtitle="refz.laboratorio"
                      :title="refz.vacuna"
                      :header="$dayjs(refz.fecha).format('DD MMM YYYY')"
                      :text="refz.centro"
                      :footer="`Lote: ${refz.lote}`"
                      >
                      <font-awesome-icon class="margin-left-half" color="teal" icon="check" slot="title"></font-awesome-icon>
                      </f7-list-item>
                 </f7-list>
                </div>
              </div>
            </div>
             </f7-list>
             <f7-list v-if="camp.internacionales && Object.keys(camp.internacionales).length" class="no-margin-top nowrpwhtspac fixfamarg" media-list>
                <f7-list-item divider title="Vacunas Recibidas en el Extranjero"></f7-list-item>
                <div class="timeline margin-vertical">
                    <div v-for="(refz, indx) in sortedInternacionales" :key="refz.id" class="timeline-item">
                        <div class="timeline-item-date text-align-center">{{ indx + 1 }}</div>
                        <div class="timeline-item-divider"></div>
                        <div class="timeline-item-content">
                            <f7-list class="no-margin-vertical" media-list>
                                <f7-list-item
                                    class="titlebold"
                                    :subtitle="refz.laboratorio"
                                    :title="refz.vacuna"
                                    :header="$dayjs(refz.fecha).format('DD MMM YYYY')"
                                    :text="refz.centro"
                                    :footer="`Lote: ${refz.lote} | ${refz.dd || '-'} | ${refz.xd || '-'}`"
                                >
                                    <font-awesome-icon class="margin-left-half" color="teal" icon="check" slot="title"></font-awesome-icon>
                                </f7-list-item>
                            </f7-list>
                        </div>
                    </div>
                </div>
            </f7-list>
        </f7-card-content>
        <f7-card-footer>
          <f7-button color="red" @click="$emit('descargapdf',campid)" href="#" fill raised ><font-awesome-icon icon="file-pdf" class="fa-lg"></font-awesome-icon> Descargar </f7-button>
          <f7-button @click="$emit('abrepass',camp)" href="#" fill raised ><font-awesome-icon icon="qrcode" class="fa-lg"></font-awesome-icon> Ver </f7-button>
        </f7-card-footer>
      </f7-card>
</template>
<script>

export default {
  props:['camp','campid'],
  

    data() {
      return {
      
      }
    },
    mounted(){
      console.log('camp',this.camp);
      console.log('campid',this.campid);
    },
    computed:{
     campEsquemasFechasSorted() {
        if (!this.camp.esquemas) return [];

        let esquemas = {};

        Object.keys(this.camp.esquemas).forEach(esquemaId => {
            const esquema = this.camp.esquemas[esquemaId];

            const formattedVacs = Object.values(esquema.fechas || {}).sort((a, b) => {
              let registroA = Object.values(a.req)
              let fechaA = registroA[0].completado.fecha

              let registroB = Object.values(b.req)
              let fechaB = registroB[0].completado.fecha
              
              return new Date (fechaA) - new Date(fechaB);
            });

            esquemas[esquemaId] = {
                ...esquema,
                fechas: formattedVacs
            };
        });

        let campCopy = JSON.parse(JSON.stringify(this.camp));
        this.$set(campCopy, "esquemas", esquemas);

        return campCopy;
      },
      sortedRefuerzos() {
        return this.sortByDate(this.camp.refuerzos || {});
      },
      sortedInternacionales() {
        return this.sortByDate(this.camp.internacionales || {});
      },

    },
    methods:{

      sortByDate(vacs) {
        if (!vacs || Object.values(vacs || {}).length === 0) {
            return [];
        }

        return Object.values(vacs).sort((a, b) => {
            return this.$dayjs(b.fecha).isBefore(this.$dayjs(a.fecha)) ? 1 : -1;
        });
      }
    
    }
}
</script>
<style>


</style>