<template>
  <f7-page name="stockvacdetail" @page:beforein="cargaStats(false)">
        <f7-navbar :subtitle="punteroStats.computing?'Actualizando...':`Actualizado el ${$dayjs.unix(punteroStats.last_comp).format('dddd DD/MM/YYYY [a las] HH:mm')}`" :title="`Stock ${vacunafoco.vacunaPcName}: ${punteroStats.computing?'--':totals}`" back-link="Back">
            <f7-nav-right>
                <f7-block>
                <f7-button v-if="!punteroStats.computing" @click="cargaStats(true)" fill color="teal" class="no-margin">Actualizar</f7-button>
                <f7-preloader v-else></f7-preloader>
                </f7-block>
                </f7-nav-right>
        </f7-navbar>
  
      <f7-block>
        <f7-button v-if="statsLoaded" @click="descargarep()" outline><font-awesome-icon class="fa-lg" icon="cloud-download-alt"></font-awesome-icon> Descargar Excel Stock por Vacunatorios</f7-button>
        <f7-row>
          <f7-col>
            <f7-block-title class="text-color-primary bold">Stock por regiones</f7-block-title>
              <f7-card>
                <f7-card-content class="text-align-center">
                            <barra-vertical :chart-data="regiones_collection" :options="opcion_sin_leyenda" ></barra-vertical>
                </f7-card-content>
             </f7-card>
        </f7-col>
         <f7-col>
            <f7-block-title class="text-color-primary bold">Con y sin stock</f7-block-title>
                <f7-card>
                  <f7-card-content class="text-align-center">
                          <pie-chart :chart-data="consinstock_collection"></pie-chart>
                  </f7-card-content>
              </f7-card>
        </f7-col>
        <f7-col>
            <f7-block-title class="text-color-primary bold">Con y sin stock por región</f7-block-title>
              <f7-card>
              <f7-card-content class="text-align-center">
                        <bar-chart :chart-data="datacollection"></bar-chart>
              </f7-card-content>
          </f7-card>
        </f7-col>
        </f7-row>

         <f7-row>
        <f7-col>
            <f7-block-title class="text-color-primary bold">Stock por Regiones</f7-block-title>
             <f7-searchbar
                search-container=".regionserchlist"
                search-item="li"
                search-in=".item-title"
                placeholder="Regiones"
                found-el=".serfonudregi"
                not-found-el=".notfoundregion"
                class="no-margin-horizontal"
                :backdrop="false"
                ></f7-searchbar>
                 <f7-block class="no-margin no-padding sclore">
                       <f7-list class="notfoundregion searchbar-not-found no-margin">
                        <f7-list-item title="No encontrado"></f7-list-item>
                    </f7-list>
                    <f7-list class="no-margin regionserchlist searchbar-found serfonudregi">
                            <f7-list-item
                            v-for="region in regionesArray"
                                :key="region.id"
                                :after="region.k || '--'"
                            :title="region.region" :footer="`Región ${region.region_number}`" ></f7-list-item>
                        </f7-list>
                 </f7-block>
        </f7-col>
        <f7-col >
            <f7-block-title class="text-color-primary bold">Stock por Comunas</f7-block-title>
            <f7-searchbar
                search-container=".comunavirlistt"
                search-item="li"
                search-in=".item-title"
                placeholder="Comunas"
                found-el=".searchbarfoundcomunas"
                not-found-el=".searchbarnotfoundcomunas"
                class="no-margin-horizontal"
                :backdrop="false"
                ></f7-searchbar>
            <f7-block class="no-margin no-padding sclore comskroll">
                 <f7-list class="searchbarnotfoundcomunas searchbar-not-found no-margin">
                <f7-list-item title="No encontrado"></f7-list-item>
                </f7-list>
             <f7-list  
             class="no-margin comunavirlistt searchbar-found searchbarfoundcomunas"
                    virtual-list
                     ref="stulist"
                    :virtual-list-params="{ items, searchAll, renderExternal, scrollableParentEl:'.comskroll', height: 54}">
                 <ul>
                <f7-list-item
                v-for="(unacomuna, index) in vlData.items"
                :key="index"
                :style="`top: ${vlData.topPosition}px`"
                 :after="unacomuna.k || '--'"
                 :title="unacomuna.name" :footer="unacomuna.prov" ></f7-list-item>
                 </ul>
            </f7-list>
            </f7-block>

        </f7-col>
          <f7-col >
            <f7-block-title class="text-color-primary bold">Stock por Vacunatorio</f7-block-title>
            <f7-searchbar
                search-container=".vaunatvirtlist"
                search-item="li"
                search-in=".item-title"
                placeholder="Vacunatorios"
                found-el=".searchbafoundvacuntorios"
                not-found-el=".srchbnotfoundvacunato"
                class="no-margin-horizontal"
                :backdrop="false"
                ></f7-searchbar>
            <f7-block class="no-margin no-padding sclore vacunatskroll">
                 <f7-list class="srchbnotfoundvacunato searchbar-not-found no-margin">
                <f7-list-item title="No encontrado"></f7-list-item>
                </f7-list>
             <f7-list  
             class="no-margin vaunatvirtlist searchbar-found searchbafoundvacuntorios"
                    virtual-list
                     ref="vacunatlist"
                    :virtual-list-params="{ items:vacunitems, searchAll:searchAllVacunats, renderExternal:vacunatRenderExternal, scrollableParentEl:'.vacunatskroll', height: 54}">
                 <ul>
                <f7-list-item
                v-for="(unvacunat, index) in vlVacunatData.items"
                :key="index"
                link="#"
                @click="centerForPopupDetail = unvacunat.id"
                :style="`top: ${vlVacunatData.topPosition}px`"
                 :after="unvacunat.stock?unvacunat.stock.total : '--'"
                 :title="unvacunat.name" :footer="unvacunat.address.provname" ></f7-list-item>
                 </ul>
            </f7-list>
            </f7-block>

        </f7-col>
         </f7-row>
      </f7-block>


 <f7-popup class="tray-log" :opened="!!centerForPopupDetail" @popup:closed="centerForPopupDetail = null">
     <logbandejas v-if="centerForPopupDetail" :centerInFoco="centerForPopupDetail" :vacunaid="id"></logbandejas>
    </f7-popup>


  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { commonmixin } from '../mixins/common'
import BarraVertical from '../components/BarraVertical.js'
import PieChart from '../components/PieChart.js'
import BarChart from '../components/BarChart.js'
import axios from 'axios';
import logbandejas from './logbandejas.vue'

export default {
    mixins: [commonmixin],
    components: {
      BarraVertical,
      PieChart,
      BarChart,
      logbandejas
    },
    props:['id'],
    data() {
      return {
      centerForPopupDetail:null,
      statsLoaded: false,
      totals:0,
      items:[],
      vlData:{
            items: [],
      },
      vacunitems:[],
      vlVacunatData:{
            items: [],
      },
      regionesArray:[],
       punteroStats: {
          last_comp: null,
          computing: false,
          dlta: 0
        },
        cargandoStats:false,
        regiones_collection:{
                labels:['AP','TA','AN','AT','CO','VS','RM','LI','ML','NB','BI','AR','LR','LL','AI','MA'],
                datasets:[]
            },
         datacollection: {
                labels:['AP','TA','AN','AT','CO','VS','RM','LI','ML','NB','BI','AR','LR','LL','AI','MA'],
                datasets:[]
            },
         consinstock_collection:{
                labels:['con_stock','sin_stock'],
                datasets:[]
            },
        opcion_sin_leyenda:{
                 legend: {
                    display: false,
                }
            }
      }
    },
    computed:{
     ...mapState(['user']),
     ...mapGetters(['centralAdminAccount']),
     vacunafoco(){
       return this.$store.state.vacunas[this.id]
     }
    },
    methods:{
    async descargarep(){
      console.log('descargando')
      try {
         this.$f7.dialog.preloader('Generando archivo...');
        let idToken = await this.$firebase.auth().currentUser.getIdToken();
        let envx = this.$store.state.dev?'dev':'prod';

        let response = await axios.get(`${this.$store.getters.getApiUrl}/prosex-exporter_isp/corxpt/stockbyvacuntas/${this.id}/${envx}`, {
                    headers: {'Authorization': 'Bearer ' + idToken},
                     responseType: 'blob', // important
                    });
        this.$f7.dialog.close();
        console.log(response.data);
        if(response.data.type=='application/json'){
          var textPromise = await response.data.text();
          console.log('error',textPromise);
          return this.$f7.dialog.alert(textPromise,'Error');
        }
          const fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          let fileLink = document.createElement('a');
          document.body.appendChild(fileLink);
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `stock_vacunatorios_${this.$dayjs().format('DD_MM_YY')}.xlsx`);
          fileLink.classList.add("external");
          fileLink.click();
          window.URL.revokeObjectURL(fileURL);
         // this.avisaOk('Descarga OK');

      } catch (error) {
          this.$f7.dialog.close();
        console.log(error, error.message,error.response, error?.response?.data);
         this.$f7.dialog.alert( error.response?.data?.message || error.message);
      }
   },
       renderExternal(vl, vlData) {
          this.vlData = vlData;
      },
      searchAll(query, items) {
        const found = [];
        let clnquery=query.toLowerCase();
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].name.toLowerCase().indexOf(clnquery) >= 0 || query.trim() === '') found.push(i);
        }
        return found; // return array with mathced indexes
      },
        vacunatRenderExternal(vl, vlVacunatData) {
          this.vlVacunatData = vlVacunatData;
      },
      searchAllVacunats(query, items) {
        const found = [];
        let clnquery=query.toUpperCase();
        for (let i = 0; i < items.length; i += 1) {
          if (items[i].norm.indexOf(clnquery) >= 0 || query.trim() === '') found.push(i);
        }
        return found;
      },
        async cargaStats(force){
         try {
             if(!this.statsLoaded || force){
                this.cargandoStats=true;
                this.punteroStats.computing = true;
                let datous = await this.commonExecute({vacunaid: this.id, force},'admin-getRepStockVacs',false);
                if(datous.error){
                    throw new Error(datous.error.message);
                }
                console.log('datous.payload',datous.payload);
               
                let byregion = datous.payload.datum.byregion || {};
                this.punteroStats = Object.assign(datous.payload.punter);
                
                let conysinrgi=datous.payload.datum.conysin_region || {};
                    this.totals = datous.payload.datum.totals;
                    if(datous.payload.datum.geoarray){
                      this.regionesArray = datous.payload.datum.geoarray.regionesArray;
                      this.$refs.stulist.f7VirtualList.replaceAllItems(datous.payload.datum.geoarray.comunsArray);
                    } 

                      this.$refs.vacunatlist.f7VirtualList.replaceAllItems(datous.payload.datum.allvacunatorios || []);
                      this.datacollection=Object.assign({},this.datacollection,{datasets:[{
                        label: 'Con stock',
                        backgroundColor: '#3fafbd',
                        data: [
                          conysinrgi['CL-AP']?conysinrgi['CL-AP'].con:0,
                          conysinrgi['CL-TA']?conysinrgi['CL-TA'].con:0,
                          conysinrgi['CL-AN']?conysinrgi['CL-AN'].con:0,
                          conysinrgi['CL-AT']?conysinrgi['CL-AT'].con:0,
                          conysinrgi['CL-CO']?conysinrgi['CL-CO'].con:0,
                          conysinrgi['CL-VS']?conysinrgi['CL-VS'].con:0,
                          conysinrgi['CL-RM']?conysinrgi['CL-RM'].con:0,
                          conysinrgi['CL-LI']?conysinrgi['CL-LI'].con:0,
                          conysinrgi['CL-ML']?conysinrgi['CL-ML'].con:0,
                          conysinrgi['CL-NB']?conysinrgi['CL-NB'].con:0,
                          conysinrgi['CL-BI']?conysinrgi['CL-BI'].con:0,
                          conysinrgi['CL-AR']?conysinrgi['CL-AR'].con:0,
                          conysinrgi['CL-LR']?conysinrgi['CL-LR'].con:0,
                          conysinrgi['CL-LL']?conysinrgi['CL-LL'].con:0,
                          conysinrgi['CL-AI']?conysinrgi['CL-AI'].con:0,
                          conysinrgi['CL-MA']?conysinrgi['CL-MA'].con:0
                        ],
                        stack: 'a'
                      }, {
                        label: 'Sin stock',
                        backgroundColor: '#cecece',
                          data: [
                          conysinrgi['CL-AP']?-conysinrgi['CL-AP'].sin:0,
                          conysinrgi['CL-TA']?-conysinrgi['CL-TA'].sin:0,
                          conysinrgi['CL-AN']?-conysinrgi['CL-AN'].sin:0,
                          conysinrgi['CL-AT']?-conysinrgi['CL-AT'].sin:0,
                          conysinrgi['CL-CO']?-conysinrgi['CL-CO'].sin:0,
                          conysinrgi['CL-VS']?-conysinrgi['CL-VS'].sin:0,
                          conysinrgi['CL-RM']?-conysinrgi['CL-RM'].sin:0,
                          conysinrgi['CL-LI']?-conysinrgi['CL-LI'].sin:0,
                          conysinrgi['CL-ML']?-conysinrgi['CL-ML'].sin:0,
                          conysinrgi['CL-NB']?-conysinrgi['CL-NB'].sin:0,
                          conysinrgi['CL-BI']?-conysinrgi['CL-BI'].sin:0,
                          conysinrgi['CL-AR']?-conysinrgi['CL-AR'].sin:0,
                          conysinrgi['CL-LR']?-conysinrgi['CL-LR'].sin:0,
                          conysinrgi['CL-LL']?-conysinrgi['CL-LL'].sin:0,
                          conysinrgi['CL-AI']?-conysinrgi['CL-AI'].sin:0,
                          conysinrgi['CL-MA']?-conysinrgi['CL-MA'].sin:0
                          ],
                        stack: 'a'
                      }]});


                 this.consinstock_collection=Object.assign({},this.consinstock_collection,{datasets:[{
                    data: [datous.payload.datum.conysin.con,datous.payload.datum.conysin.sin],
                    backgroundColor:['#3fafbd','#cecece']
                  }]});

                this.regiones_collection=Object.assign({},this.regiones_collection,{datasets:[{
                  label: 'n',
                  backgroundColor: '#3fafbd',
                  data: [
                    byregion['CL-AP']?byregion['CL-AP'].total:0,
                    byregion['CL-TA']?byregion['CL-TA'].total:0,
                    byregion['CL-AN']?byregion['CL-AN'].total:0,
                    byregion['CL-AT']?byregion['CL-AT'].total:0,
                    byregion['CL-CO']?byregion['CL-CO'].total:0,
                    byregion['CL-VS']?byregion['CL-VS'].total:0,
                    byregion['CL-RM']?byregion['CL-RM'].total:0,
                    byregion['CL-LI']?byregion['CL-LI'].total:0,
                    byregion['CL-ML']?byregion['CL-ML'].total:0,
                    byregion['CL-NB']?byregion['CL-NB'].total:0,
                    byregion['CL-BI']?byregion['CL-BI'].total:0,
                    byregion['CL-AR']?byregion['CL-AR'].total:0,
                    byregion['CL-LR']?byregion['CL-LR'].total:0,
                    byregion['CL-LL']?byregion['CL-LL'].total:0,
                    byregion['CL-AI']?byregion['CL-AI'].total:0,
                    byregion['CL-MA']?byregion['CL-MA'].total:0
                    ],
                }]});
                console.log('this.regiones_collection',this.regiones_collection)
                this.cargandoStats=false;
                this.statsLoaded = true;
            }
            }
            catch (error) {
            this.cargandoStats=false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
      },
    }
}
</script>
<style>


</style>