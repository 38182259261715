import Cuentas from "../pages/cuentas.vue";
import Accounts from "../pages/accounts.vue";
import Cuenta from "../pages/cuenta.vue";
import Vacunatorios from "../pages/vacunatorios.vue";
import Vacunatorio from "../pages/vacunatorio.vue";
import Vacunas from "../pages/vacunas.vue";
import Vacuna from "../pages/vacuna.vue";
import Formularios from "../pages/formularios.vue";
import Formulario from "../pages/formulario.vue";
import Campanas from "../pages/campanas.vue";
import Campana from "../pages/campana.vue";
import Campana_reporte from "../pages/campana_reporte.vue";
import Campana_report_chart from "../pages/campana_report_chart.vue";
import Estadisticas from "../pages/estadisticas.vue";
import FirmaElectronicaAvanzada from "../pages/FirmaElectronicaAvanzada.vue";
import FEARequestsStatuses from "../pages/FEARequestsStatuses.vue";

import Comprobantes from "../pages/comprobantes.vue";
import Copmrobanteficha from "../pages/copmrobanteficha.vue";
import ComprobanteSolNac from "../pages/comprobante_sol_nac.vue";

import Fiscalizador from "../pages/fiscalizador.vue";

import HomePage from "../pages/home.vue";
import Verifidentity from "../pages/verifidentity.vue";
import HistoricoRechazadas from "../pages/historico-rechazadas.vue";
import HistoricoAprobadas from "../pages/historico-aprobadas.vue";
import Gestionverifica from "../pages/gestion_verifica.vue";
import EligeCentro from "../pages/eligeCentro.vue";

import center_Cuentas from "../pages/center_Cuentas.vue";
import center_Cuenta from "../pages/center_Cuenta.vue";

import center_Stock from "../pages/center_Stock.vue";
import center_stock_vacuna from "../pages/center_stock_vacuna.vue";

import center_agenda from "../pages/center_agenda.vue";
import center_agenda_abrir from "../pages/center_agenda_abrir.vue";

import center_registro from "../pages/center_registro.vue";

import Paises from "../pages/paises.vue";
import Ficha from "../pages/ficha.vue";

import ISP from "../pages/isp.vue";

import stockvacs from "../pages/stockvacs.vue";
import stockvacdetail from "../pages/stockvacdetail.vue";

import NotFoundPage from "../pages/404.vue";

import HomologacionVacunasLists from "../pages/homologacion_vacunas/lists.vue";
import HomologacionVacunasNacLists from "../pages/homologacion_vacunas_nac/lists.vue";
import HomologacionVacunasResolving from "../pages/homologacion_vacunas/resolving.vue";
import HomologacionVacunasNacResolving from "../pages/homologacion_vacunas_nac/resolving.vue";
import HomologacionVacunasAccepted from "../pages/homologacion_vacunas/accepted.vue";
import HomologacionVacunasNacAccepted from "../pages/homologacion_vacunas_nac/accepted.vue";
import HomologacionVacunasSignerList from "../pages/homologacion_vacunas_nac/signer_list.vue";

import EditorRegistrosVacunasSearch from "../pages/editor_registros_vacunas/search.vue";
import EditorRegistrosVacunasEdit from "../pages/editor_registros_vacunas/edit.vue";

import ApiLaboratorios from "../pages/api_laboratorios.vue";

var routes = [
    {
        path: "/gestor_homologacion_vacunas/lists",
        component: HomologacionVacunasLists,
    },
    {
        path: "/gestor_homologacion_vacunas_nac/lists",
        component: HomologacionVacunasNacLists,
    },
    {
        path: "/gestor_homologacion_vacunas_nac/signer-lists",
        component: HomologacionVacunasSignerList,
    },
    {
        path: "/gestor_homologacion_vacunas/resolving",
        component: HomologacionVacunasResolving,
    },
    {
        path: "/gestor_homologacion_vacunas_nac/resolving/:tipo",
        component: HomologacionVacunasNacResolving,
    },
    {
        path: "/gestor_homologacion_vacunas/accepted",
        component: HomologacionVacunasAccepted,
    },
    {
        path: "/gestor_homologacion_vacunas_nac/accepted/:tipo",
        component: HomologacionVacunasNacAccepted,
    },
    {
        path: "/editor_registros_vacunas/search",
        component: EditorRegistrosVacunasSearch,
    },
    {
        path: "/editor_registros_vacunas/edit",
        component: EditorRegistrosVacunasEdit,
    },

    {
        path: "/campanas/",
        component: Campanas,
    },
    {
        path: "/stockvacdetail/:id",
        component: stockvacdetail,
    },
    {
        path: "/stockvacs/",
        component: stockvacs,
    },
    {
        path: "/comprobantes/",
        component: Comprobantes,
    },
    {
        path: "/copmrobanteficha/",
        component: Copmrobanteficha,
    },
    {
        path: "/comprobante_sol_nac/",
        component: ComprobanteSolNac,
    },
    {
        path: "/fiscalizador/",
        component: Fiscalizador,
    },
    {
        path: "/isp/",
        component: ISP,
    },
    {
        path: "/ficha/",
        component: Ficha,
    },
    {
        path: "/charter/",
        component: Campana_report_chart,
    },
    {
        path: "/estadisticas/",
        component: Estadisticas,
    },
    {
        path: "/fea/",
        component: FirmaElectronicaAvanzada,
    },
    {
        path: "/fea/requests-statuses",
        component: FEARequestsStatuses,
    },
    {
        path: "/campana/",
        component: Campana,
    },
    {
        path: "/campana_reporte/",
        component: Campana_reporte,
    },
    {
        path: "/center/center_stock_vacuna/:vacunaid",
        component: center_stock_vacuna,
    },
    {
        path: "/center/vac_reg/",
        component: center_registro,
    },
    {
        path: "/center/vac_agenda/",
        component: center_agenda,
    },
    // {
    //   path: '/center/center_agenda_abrir/:hoyx',
    //   component: center_agenda_abrir,
    // },
    {
        path: "/center/center_semana_molde/",
        component: center_agenda_abrir,
    },
    {
        path: "/center/vac_stock/",
        component: center_Stock,
    },
    {
        path: "/center/vac_cuentas/",
        component: center_Cuentas,
    },
    {
        path: "/center/vac_cuenta/",
        component: center_Cuenta,
    },

    {
        path: "/eligeCentro/",
        component: EligeCentro,
    },
    {
        path: "/gestion_verifica/",
        component: Gestionverifica,
    },
    {
        path: "/accounts/",
        component: Accounts,
    },
    {
        path: "/cuentas/",
        component: Cuentas,
    },
    {
        path: "/cuenta/",
        component: Cuenta,
    },
    {
        path: "/paises/",
        component: Paises,
    },
    {
        path: "/verifidentity/",
        component: Verifidentity,
    },
    {
        path: "/verifidentity/historico-rechazadas/",
        component: HistoricoRechazadas,
    },
    {
        path: "/verifidentity/historico-aprobadas/",
        component: HistoricoAprobadas,
    },
    {
        path: "/vacunatorios/",
        component: Vacunatorios,
    },
    {
        path: "/vacunatorio/",
        component: Vacunatorio,
    },
    {
        path: "/vacunas/",
        component: Vacunas,
    },
    {
        path: "/vacuna/:id",
        component: Vacuna,
    },
    {
        path: "/formularios/",
        component: Formularios,
    },
    {
        path: "/formulario/:id",
        component: Formulario,
    },
    {
        path: "/api-labs/",
        component: ApiLaboratorios,
    },

    {
        path: "/",
        component: HomePage,
    },

    {
        path: "(.*)",
        component: NotFoundPage,
    },
];

export default routes;
