<template>
    <f7-login-screen :opened="loginOpened" id="my-login-screen">
        <f7-view>
            <f7-page :class="`mylogin back${bg}`" login-screen>
                <f7-block class="logos-banner display-flex justify-content-space-between align-items-center">
                    <img class="minsallog" src="../static/logos/minsalsaquared.jpg" />
                    <img class="inmlog" src="../static/logos/depto_inmunizaciones.png" />
                </f7-block>
                <f7-login-screen-title class="no-margin-vertical lgtitle"
                    ><span class="count"><span class="mask">Vacunator.</span>io</span></f7-login-screen-title
                >
                <f7-block class="no-margin-top">
                    <f7-block class="login-bccly no-margin-vertical text-align-center">
                        <span v-if="loading">Cargando...</span>
                        <span v-else-if="cerrandoSesion">Cerrando sesión...</span>
                        <span v-else>Ingrese con su <span class="bold">email</span> y contraseña.</span>
                    </f7-block>
                    <f7-row class="altox" v-if="loading">
                        <f7-col
                            ><f7-button class="padtop" large><f7-preloader></f7-preloader></f7-button
                        ></f7-col>
                    </f7-row>
                    <f7-row class="altox" v-else-if="cerrandoSesion">
                        <f7-col
                            ><f7-button class="padtop" large><f7-preloader></f7-preloader></f7-button
                        ></f7-col>
                    </f7-row>
                    <f7-block class="no-margin-vertical" v-else>
                        <f7-list media-list class="logininputs no-margin-top">
                            <f7-list-input
                                label="email"
                                :value="email"
                                type="email"
                                @input="email = $event.target.value"
                                :clear-button="!loggingin"
                                :readonly="loggingin"
                            >
                                <font-awesome-icon icon="at" class="fa-2x" slot="media" />
                            </f7-list-input>
                            <f7-list-input
                                label="Contraseña"
                                :value="password"
                                @keyup.native.enter="ingresarConemailpass()"
                                type="password"
                                @input="password = $event.target.value"
                                :clear-button="!loggingin"
                                :readonly="loggingin"
                            >
                                <font-awesome-icon icon="key" class="fa-2x" slot="media" />
                            </f7-list-input>
                        </f7-list>
                        <f7-row>
                            <f7-col
                                ><f7-link class="margin-top" @click="forgotMyPass()" :class="{ disabled: loggingin }"
                                    >Recuperar contraseña</f7-link
                                ></f7-col
                            >
                            <f7-col>
                                <f7-button @click="ingresarConemailpass()" large fill
                                    ><f7-preloader color="white" v-if="loggingin"></f7-preloader><span v-else>Acceder</span></f7-button
                                >
                            </f7-col>
                        </f7-row>
                    </f7-block>
                </f7-block>
                <f7-list class="no-margin-bottom">
                    <f7-block-footer> <b>Vacunator.io</b> &copy; 2020<br /> </f7-block-footer>
                    <img src="../static/heros/loginback_2.jpg" class="hidemax" />
                    <img src="../static/heros/loginback_3.jpg" class="hidemax" />
                </f7-list>
                <f7-block class="text-align-center">
                    <f7-chip :media-bg-color="dev ? 'red' : 'primary'">
                        <span slot="text"
                            ><span class="bold">{{ $store.state.appVersion }}</span></span
                        >
                        <font-awesome-icon :icon="dev ? 'code' : 'syringe'" slot="media"></font-awesome-icon>
                    </f7-chip>
                </f7-block>
            </f7-page>
        </f7-view>
    </f7-login-screen>
</template>
<script>
import { mapState } from "vuex";

export default {
    props: ["loginOpened", "loading", "dev"],
    data() {
        return {
            bg: 1,
            email: "",
            password: "",
            verpass: false,
            veryfying: false,
            dictio: {
                "auth/invalid-email": { code: "Email inválido", message: "El email no parece ser válido o hay errores en el formato." },
                "auth/wrong-password": {
                    code: "Contraseña inválida",
                    message: "La contraseña es incorrecta. Tras algunos intentos fallidos, se bloqueará su cuenta.",
                },
                "auth/user-not-found": {
                    code: "Usuario no encontrado",
                    message: "No hay registro de este usuario. Solicite a su coordinador que cree una cuenta en MeVacuno ADMIN.",
                },
                "auth/weak-password": { code: "Contraseña insegura", message: "Su contraseña debe incluir al menos 6 caracteres." },
                "auth/email-already-in-use": {
                    code: "Email ya existe",
                    message: "El email indicado para crear la cuenta ya existe. Intente iniciar sesión o recuperar su contraseña.",
                },
            },
        };
    },
    computed: {
        ...mapState(["loggingin", "cerrandoSesion"]),
    },
    watch: {
        loginOpened() {
            this.$store.commit("setWhatTo", { what: "loggingin", to: false });
        },
    },
    mounted() {
        setTimeout(() => this.animaback(), 4000);
    },
    methods: {
        printError(error) {
            if (this.dictio[error.code]) {
                this.$f7.dialog.alert(this.dictio[error.code].message, this.dictio[error.code].code);
            } else {
                this.$f7.dialog.alert(error.message, error.code);
            }
        },
        forgotMyPass() {
            this.$f7.dialog.prompt(
                "Le haremos llegar un link para reestablecer su contraseña.",
                "Indique email de registro",
                (email) => {
                    if (!email) {
                        return this.$f7.dialog.alert("Debe indicar un email");
                    }
                    this.$f7.dialog.preloader("Enviando...");
                    this.$firebase
                        .auth()
                        .sendPasswordResetEmail(email)
                        .then(() => {
                            this.$f7.dialog.close();
                            this.$f7.dialog.alert("Revise la bandeja de entrada (y spam) de su correo " + email);
                        })
                        .catch((error) => {
                            this.$f7.dialog.close();
                            this.printError(error);
                            console.log(error);
                        });
                },
                () => {},
                this.email || ""
            );
        },
        animaback() {
            if (this.loginOpened) {
                this.bg = this.bg == 3 ? 1 : this.bg + 1;
                setTimeout(() => this.animaback(), 4000);
            }
        },
        ingresarConemailpass() {
            if (!this.email || !this.password) {
                return this.$f7.dialog.alert("Debe indicar un email y una contraseña");
            }
            this.$store.commit("setWhatTo", { what: "loggingin", to: true });
            return this.$firebase
                .auth()
                .signInWithEmailAndPassword(this.email, this.password)
                .catch((error) => {
                    this.$store.commit("setWhatTo", { what: "loggingin", to: false });
                    this.printError(error);
                    console.log(error);
                });
        },
    },
};
</script>
<style>
.mylogin.page {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
    /* transition: background 0.5s linear; */
    transition: background-image 0.5s linear;
}
.mylogin.back1.page {
    background-image: url("../static/heros/loginback_1.jpg");
}

.mylogin.back2.page {
    background-image: url("../static/heros/loginback_2.jpg");
}

.mylogin.back3.page {
    background-image: url("../static/heros/loginback_3.jpg");
}
.login-screen-content {
    background: unset;
}
.login-rwd {
    font-size: 18px;
}
.login-bccly {
    font-size: 16px;
}
.mylogin .pwdb {
    font-style: italic;
}
.mylogin .lgtitle {
    font-size: 36px;
}
.mylogin .mask {
    color: #1b727d;
}
.mylogin .count {
    color: #3fafbc;
}
.mylogin .version {
    font-size: 13px;
}
.hidemax {
    display: none !important;
}
.mylogin .padtop svg {
    margin-top: 9px;
}
.mylogin .gray {
    color: #696969;
}
.altox {
    height: 116px;
}

.logininputs input {
    padding-left: 8px !important;
    border-radius: 9px;
    border-color: #3fafbc;
    border: 2px solid #3fafbc;
}

.logininputs .item-input-wrap input {
    background: #fff;
}

.logininputs .item-media svg {
    margin: 0 auto !important;
    color: #4e4e4e;
}

.fixmedia .item-media svg {
    margin: 0 auto !important;
}

.inputpadlef input {
    padding-left: 5px !important;
}
.avisoclp {
    background: #dffcff;
}
.minsallog {
    width: 80px;
    max-width: 26%;
}
.inmlog {
    width: 250px;
    max-width: 46%;
    height: 100%;
}
.login-screen-content {
    margin-top: 0 !important;
}
</style>
