<template>
  <f7-page name="campanas" @page:beforein="loadcampanas()"  :page-content="false">
    <f7-navbar >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Campañas"></f7-nav-title>
    </f7-navbar>


  <f7-toolbar tabbar labels bottom>
    <f7-link tab-link="#cam_activas" tab-link-active text="Activas" icon-material="event_available"></f7-link>
    <f7-link tab-link="#camp_concluidas" text="Concluidas" icon-material="event_busy"></f7-link>
    <f7-link tab-link="#camp_draft" text="Borrador" icon-material="edit"></f7-link>
  </f7-toolbar>

<f7-tabs animated>
    <f7-tab id="cam_activas" class="page-content" tab-active>
         <f7-block class="display-flex justify-content-center">
                <div class="maxanchocolumn">
                  <f7-block class="text-align-center" v-if="cargandoCampanas"><f7-preloader></f7-preloader><br/>Cargando campañas</f7-block>
                   <f7-block class="text-align-center" v-else-if="!campanasActivas.length"><font-awesome-icon icon="frown" class="fa-2x text-color-primary"></font-awesome-icon> No existen campañas activas</f7-block>
                    <campanabox v-for="campana in campanasActivas" :key="campana.id" :campana="campana" @abrecampana="abrecamapana"></campanabox>

                </div>
         </f7-block>
    </f7-tab>

    <f7-tab id="camp_concluidas" class="page-content">
      <f7-block class="display-flex justify-content-center">
                <div class="maxanchocolumn">
                  <f7-block class="text-align-center" v-if="cargandoCampanas"><f7-preloader></f7-preloader><br/>Cargando campañas</f7-block>
                   <f7-block class="text-align-center" v-else-if="!campanasConluidas.length"><font-awesome-icon icon="frown" class="fa-2x text-color-primary"></font-awesome-icon> No existen campañas concluidas</f7-block>
                    <campanabox v-for="campana in campanasConluidas" :key="campana.id" :campana="campana" @abrecampana="abrecamapana"></campanabox>
                </div>
         </f7-block>

    </f7-tab>
     <f7-tab id="camp_draft" class="page-content">
  <f7-block class="display-flex justify-content-center">
                <div class="maxanchocolumn">
                  <f7-block class="text-align-center" v-if="cargandoCampanas"><f7-preloader></f7-preloader><br/>Cargando campañas</f7-block>
                   <f7-block class="text-align-center" v-else-if="!campanasDraft.length"><font-awesome-icon icon="frown" class="fa-2x text-color-primary"></font-awesome-icon> No existen campañas en estado de borrador</f7-block>
                     <campanabox v-for="campana in campanasDraft" :key="campana.id" :campana="campana" @abrecampana="abrecamapana"></campanabox>
                </div>
         </f7-block>

    </f7-tab>
</f7-tabs>

<f7-fab v-if="!cargandoCampanas && centralAdminPrivs.campanas" @click="promptCampanaName()" position="right-bottom" slot="fixed" color="teal">
    <f7-icon material="add"></f7-icon>
  </f7-fab>

  </f7-page>
</template>
<script>
import { commonmixin } from '../mixins/common'
import { mapGetters, mapState } from 'vuex'
import campanabox from '../components/campanabox.vue'
export default {
    mixins: [commonmixin],
    components:{
      campanabox
    },
    data() {
      return {
       cargandoCampanas: false
      }
    },
    computed:{
      ...mapState(['campanas']),
      ...mapGetters(['campanasActivas','centralAdminPrivs','campanasDraft','campanasConluidas'])
    },
    methods:{
      //.orderByChild('pubfin').startAt(this.$dayjs.format('YYYYMMDD'))
      abrecamapana({campanaid,reporte}){
       this.goToCampana(campanaid,reporte);
      },
      goToCampana(campanaid,alreporte=false){
        let moltiprom=[
          this.$firebase.database().ref('campanas_criterios/'+campanaid).once('value'),
          this.getMeThese(['formularios','vacunas','regiones','paises','comunas'])
        ];
        return Promise.all(moltiprom).then((multisnaps)=>{
          let loadedsusreglas = multisnaps[0].val() || {};
           if(alreporte){
               return this.$f7.views.main.router.navigate('/campana_reporte/',{
                props: {loadedsusreglas,id: campanaid}
              });
           }
           else{
              return this.$f7.views.main.router.navigate('/campana/',{
                 props: {loadedsusreglas,id: campanaid}
              });
           }
          
        }).catch(error=>{
           this.$f7.dialog.close();
            console.error(error);
           this.$f7.dialog.alert(error.message, error.code);
        })
      },
      loadcampanas(){
        if(!Object.keys(this.campanas).length){
           this.cargandoCampanas=true;
          return this.$firebase.database().ref('campanas').once('value').then(snpstho=>{
             this.cargandoCampanas=false;
            this.$store.commit("setObjTo", {what:'campanas', to: snpstho.val() || {}});
          }).catch(error=>{
            this.cargandoCampanas=false;
           console.error(error);
           this.$f7.dialog.alert(error.message, error.code);
          })
        }
      },
      promptCampanaName(){
        console.log('preguntar nombre campana');
          this.$f7.dialog.prompt('Indique el nombre de la nueva campaña', (nombre) => {
            if(!nombre){
              return this.$f7.dialog.alert('Nombre de la campaña no puede ser vacío');
            }
            this.creaCampana(nombre);
          });
      },
      async creaCampana(nombre){
        try{
            this.$f7.dialog.preloader('Creando campaña...');
            console.log('enviando',this.tocreate);
            let datous = await this.commonExecute({nombre},'admin-creaCampana',true);
            if(datous.duplicateid){
              this.$f7.dialog.close();
              return this.$f7.dialog.alert('Ya existe una campaña '+datous.tipo+' llamada '+nombre, 'Posible duplicado',()=>{
                    return this.goToCampana(datous.duplicateid);
              });
            }
            if(datous.error){
              throw new Error(datous.error.message);
            }
            console.log('creacion ok',datous);
            this.$store.commit('updtObjProperty',{what:'campanas',to: datous.payload});
             this.$f7.dialog.close();
             
            return this.goToCampana(datous.payload.id);
        } catch(error){
           this.$f7.dialog.close();
           console.error(error);
           this.$f7.dialog.alert(error.message, error.code);
        }
      },

    }
}
</script>
<style>


</style>