<template>
  <f7-page @page:beforein="getFormularios()" name="formularios">
    <f7-navbar >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title title="Formularios" subtitle="seguimiento síntomas"></f7-nav-title>
    </f7-navbar>


<f7-list class="no-margin">
  <f7-block class="text-align-center" v-if="cargandoFormularios"><f7-preloader></f7-preloader><br/>Cargando formularios de seguimiento...</f7-block>
  <f7-list-item v-else-if="!formularios.length" title="No hay formularios creados"></f7-list-item>
  <f7-list-item 
  v-for="formulario in formularios"
  :key="formulario.id"
  :title="formulario.name"
  link="#"
  @click="gotoForm(formulario.id)"
  >
  <f7-chip outline :text="!formulario.published?'Borrador':'Publicado'" :color="!formulario.published?'red':'teal'" slot="after"></f7-chip>
  </f7-list-item>
</f7-list>


<f7-fab @click="creaFormulario()" position="right-bottom" slot="fixed" color="teal">
    <f7-icon material="add"></f7-icon>
  </f7-fab>


  </f7-page>
</template>
<script>
import { mapGetters, mapState  } from 'vuex'
import { commonmixin } from '../mixins/common'

export default {
   mixins: [commonmixin],
    data() {
      return {
        cargandoFormularios:  false
      }
    },
    computed:{
      ...mapState(['forms_logic']),
     ...mapGetters(['formularios']),

    },
    methods:{
       gotoForm(formularioid){
         if(!this.forms_logic){
           return this.fetchLogicAndGo(formularioid);
         }
         else{
            this.$f7.views.main.router.navigate('/formulario/'+formularioid);
         }
      },
      fetchLogicAndGo(formularioid){
        this.$f7.dialog.preloader('Cargando...');
        return this.$firebase.database().ref('forms_logic').once('value').then(snpshot=>{
          this.$store.commit('setWhatTo',{what:'forms_logic',to: snpshot.val()});
           this.$f7.dialog.close();
            this.$f7.views.main.router.navigate('/formulario/'+formularioid);
        }).catch(error=>{
           this.$f7.dialog.close();
           console.error(error);
           this.$f7.dialog.alert(error.message, error.code);
        })
      },
      creaFormulario(){
        this.$f7.dialog.prompt('Indique el nombre para el nuevo formulario', (name) => {
         if(!name){
           return this.$f7.dialog.alert('Nombre no puede ser vacío','Error');
         }
        this.goGreate(name)
        });
      },
      async goGreate(name){
        try{
            this.$f7.dialog.preloader('Creando formulario...');
            let datous = await this.commonExecute({name},'admin-creaFormulario',true);
            if(datous.error){
              throw new Error(datous.error.message);
            }
            console.log('creacion ok',datous);
            this.$store.commit('updtObjProperty',{what:'formularios',to: datous.payload});
            this.$f7.dialog.close();
            this.gotoForm(datous.payload.id);
          return true;
        } catch(error){
           this.$f7.dialog.close();
           console.error(error);
           this.$f7.dialog.alert(error.message, error.code);
        }
      },
       getFormularios(){
        if(!this.formularios.length){
          this.cargandoFormularios = true;
          this.$firebase.database().ref('formularios').once('value').then(snpshot=>{
            this.$store.commit('setWhatTo',{what: 'formularios', to: snpshot.val() || {}})
            this.cargandoFormularios = false;
          }).catch(error=>{
            this.cargandoFormularios = false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          })
        }
      },
    }
}
</script>
<style>


</style>