<template>
 <f7-page>
        <f7-navbar title="Log">
          <f7-nav-right>
            <f7-link popup-close>Cerrar</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <f7-block class="text-align-center" v-if="cargando">
         <f7-preloader></f7-preloader><br/>Cargando log...
        </f7-block>

        <f7-card v-for="bandeja in bandejax" :key="bandeja.id">
          <f7-list class="no-margin-bottom titlebold" media-list>
            <f7-list-item :title="`${bandeja.id} - ${bandeja.descrip || ''}`" :after="`lote ${bandeja.loteid}`"></f7-list-item>
          </f7-list>
          <f7-card-content>
            <div class="data-table">
              <table>
                <thead>
                  <tr>
                    <th class="label-cell">Fecha</th>
                    <th class="label-cell">Evento</th>
                    <th class="label-cell">Agente</th>
                    <th class="numeric-cell">Delta</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(unevent,indx) in bandeja.finalRecords" :key="indx">
                    <td class="label-cell">{{$dayjs(unevent.f).format('DD/MM/YY')}}</td>
                    <td class="label-cell">{{unevent.t}}</td>
                    <td class="label-cell">{{unevent.b}}</td>
                    <td class="numeric-cell">{{unevent.n?`-${unevent.n}`:`+${unevent.p}`}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </f7-card-content>
        </f7-card>
      </f7-page>
</template>
<script>
import { commonmixin } from '../mixins/common'

export default {
  mixins: [commonmixin],
  props:['vacunaid','centerInFoco','bandejafocolog'],
    data() {
      return {
         loginfo:[],
         bandejax:[],
         cargando: true
      }
    },
    mounted(){
      console.log('cargando tipo',this.centerInFoco, this.vacunaid);
      this.fetchLatests();
    },
    methods:{
           async fetchLatests(){
          try {
              this.cargando = true;
              let datous;
              if(this.bandejafocolog){
                  datous = await this.commonExecute({bandejaid: this.bandejafocolog, vacunatorioid: this.centerInFoco },'admin-getlogOneBanejdaVacunatx',false);
              }
              else{
                  datous = await this.commonExecute({vacunaid: this.vacunaid, vacunatorioid: this.centerInFoco },'admin-getlogBanejdasVacunatx',false);
              }              
              
                if(datous.error){
                  throw new Error(datous.error.message);
                }
                console.log(datous.payload)
                this.bandejax=datous.payload;
            this.cargando = false;
          } catch (error) {
             this.cargando = false;
            console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }

        }
    }
}
</script>
<style>

</style>