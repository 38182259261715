<template>
  <f7-page name="cuentas">
    <!-- Top Navbar -->
    <f7-navbar >
      <f7-nav-left>
        <f7-link icon-material="menu" panel-open="left"></f7-link>
      </f7-nav-left>
      <f7-nav-title subtitle="Privilegios Admin y Vacunatorios" title="Cuentas"></f7-nav-title>
    </f7-navbar>


<f7-block class="margin-top">
  <f7-row>
    <f7-col></f7-col>
    <f7-col class="text-align-right"><f7-button @click="popupCrearCuentaOpened = true" fill>Crear nueva cuenta <font-awesome-icon icon="user-plus"></font-awesome-icon> </f7-button></f7-col>
  </f7-row>
</f7-block>
<f7-block>
  <f7-block-title>Búsqueda de cuentas admin</f7-block-title>
  <f7-row :no-gap="true"><f7-col width="70">
     <f7-searchbar
        class="no-margin"
        placeholder="Búsque por RUT o email"
        :custom-search="true"
        :value="busqueda"
         @keyup.native.enter="buscar()" 
         @searchbar:clear="busqueda=''"
         @searchbar:disable="busqueda=''"
        :backdrop="false"
        @input="busqueda = $event.target.value"
      ></f7-searchbar>
      </f7-col>
      <f7-col width="30"><f7-button @click="buscar()" fill large raised><font-awesome-icon icon="search"></font-awesome-icon></f7-button></f7-col>
  </f7-row>
</f7-block>
<template v-if="buscado && buscado==busqueda">
<f7-block class="margin-bottom">Resultados de la búsqueda</f7-block>
<f7-block class="no-margin-top">
  <p v-if="!Object.keys(resultados).length" class="no-margin">No hay cuentas asociadas a {{busqueda}}</p>
  
  <f7-list v-if="buscado && buscado==busqueda" class="cuentasresults" inset media-list>
    <f7-list-item v-for="unacuenta in resultados" :key="unacuenta.id" :title="`${unacuenta.ap1} ${unacuenta.ap2 || ''}, ${unacuenta.nms}`" @click="gotoCuenta(unacuenta)" link="#" :subtitle="formatRut(unacuenta.id)" :text="unacuenta.em">
       <img slot="media" src="../static/heros/avatar.jpg" />
       <div slot="after">
        <f7-chip text="Admin-web" :media-bg-color="unacuenta.prv?'teal':'gray'">
       <font-awesome-icon slot="media" :icon="unacuenta.prv?'check':'times'"></font-awesome-icon>
      </f7-chip>
       </div>
    </f7-list-item>
  </f7-list>
  </f7-block>
  </template>

 <f7-popup class="crear-cuenta" :close-by-backdrop-click="false" :opened="popupCrearCuentaOpened"  @popup:closed="popupCrearCuentaOpened = false">
     <crea-cuenta v-if="popupCrearCuentaOpened" @abortCreacion="popupCrearCuentaOpened = false" @gotoCuenta="goToCuentaInner"></crea-cuenta>
    </f7-popup>


  </f7-page>
</template>
<script>
import { commonmixin } from '../mixins/common'
import * as EmailValidator from 'email-validator';
import creaCuenta from './cuenta_crea_popup.vue'
export default {
      mixins: [commonmixin],
      components:{
        creaCuenta
      },
    data() {
      return {
       busqueda:'',
       buscado: '',
       popupCrearCuentaOpened: false,
       resultados:{},
      }
    },
    methods:{
      async buscar(){
        try {
          this.buscado='';
          console.log(this.busqueda);
        let lugar = 'id';
        let abuscar;
        if(EmailValidator.validate(this.busqueda)){
          abuscar=this.busqueda.toLowerCase()
          lugar = 'em';
        }
        else{
          abuscar=this.getCleanRut(this.busqueda);
        }
          if(!abuscar){
            throw new Error('Input no parece email ni RUN');
          }
          this.resultados = Object.assign({});
          this.$f7.dialog.preloader('Buscando...');
          // let datous = await this.commonExecute({abuscar},'admin-buscaCuentasAdmin',false);
          // if(datous.error){
          //   console.log(datous.error)
          //   throw new Error(datous.error.message);
          // }
          let potsnap = await this.$firebase.database().ref('privx_admn').orderByChild(lugar).equalTo(abuscar).once('value');
        //  console.log(datous.payload);
          this.$f7.dialog.close();
          this.resultados = Object.assign(potsnap.val() || {});
          console.log('buscando',abuscar,this.resultados);
          this.buscado = this.busqueda
        } catch (error) {
           this.$f7.dialog.close();
           console.error(error);
           this.$f7.dialog.alert(error.message, error.code);
        }

      },
      goToCuentaInner(cuenta){
         this.popupCrearCuentaOpened=false;
        this.gotoCuenta(cuenta);
      },
      gotoCuenta(cuenta){
        console.log(cuenta);
        this.$f7.views.main.router.navigate('/cuenta/',
        {
          props: {admn: cuenta}
        })
      }
    }
}
</script>
<style>
.cuentasresults img{
  width: 80px;
}

</style>