<template>
 <f7-page
        name="center_agenda_abrir"
        @page:beforein="cargainicial()"
        class="bg-page"
    >
        <!-- Top Navbar -->
        <f7-navbar title="Semana molde" subtitle="para agenda web" back-link="Back">
            <f7-nav-right v-if="!cargando"><f7-block><f7-button @click="publicar()" fill>{{yaexistemolde?'Guardar Cambios':'Crear Semana Molde'}}</f7-button></f7-block></f7-nav-right>
        </f7-navbar>
 
     <f7-block v-if="!yaexistemolde" class="no-margin-top">
         <div class="text-align-center margin-vertical-half">Configuraciones generales</div>
           <f7-row>
            <f7-col>
            <f7-list no-hairlines class="boradroun no-margin">
                <f7-list-input
                    label="Tiempo/paciente(min)"
                    type="number"
                    input-id="minperpackpicker"
                >
                </f7-list-input>
            </f7-list>
            </f7-col>
            <f7-col>
            <f7-list no-hairlines class="boradroun no-margin">
                <f7-list-input
                    label="Hora Apertura"
                    type="text"
                    input-id="statTimePicker"
                    @change="cambiaInicio($event.target.value)"
                    clear-button
                >
                </f7-list-input>
            </f7-list>
            </f7-col>
     
             <f7-col>
            <f7-list no-hairlines class="boradroun no-margin">
                <f7-list-input
                    label="Hora Cierre"
                    type="text"
                    @change="cambiaTermino($event.target.value)"
                    input-id="endTimePicker"
                    clear-button
                >
                </f7-list-input>
            </f7-list>
            </f7-col>
           </f7-row>
     </f7-block>
   <f7-block class="text-align-center" v-if="cargando"><f7-preloader></f7-preloader><br/><br/>Cargando configuraciones...</f7-block>
    <f7-block class="no-padding-horizontal no-margin" v-else>



<div class="fw100 padding-top prim bg-color-white">
    <div class="primcol bg-color-white text-align-center centesver">
        Horario \ día
    </div>
    <div class="resmund bg-color-white text-align-center">
          <f7-row class="f13r">
              <f7-col
              v-for="diaid in semordenada"
              :key="diaid"
              ><f7-checkbox :checked="!diassemana[diaid].bloq" @change="bloqdiasem($event.target.checked,diaid)" :name="`diasemx${diaid}`"></f7-checkbox><br/>{{diassemana[diaid].nam}}<br/><f7-segmented raised :class="{disabled: diassemana[diaid].bloq}" >
    <f7-button @click="chvaldiasem(diaid,false)">-</f7-button>
    <f7-button :tooltip="`Pacientes concurrentes simultaneos cada hora (${diassemana[diaid].nam})`" active>{{diassemana[diaid].concupacs}}</f7-button>
    <f7-button @click="chvaldiasem(diaid,true)">+</f7-button>
  </f7-segmented></f7-col>
         </f7-row>
    </div>
</div>
<div  v-for="(obj,hini) in horasComputadas"
        :key="hini" class="fw100 fixaltx bg-color-white" >
    <div class="primcol bg-color-white text-align-center">
         <f7-list class="no-margin" simple-list>
                <f7-list-item>
                    <span class="hrx" :class="{disabled: obj.bloq}">{{hini}}-{{obj.fin}}</span>
                    <f7-toggle @toggle:change="cambiarval($event,hini)" :checked="!obj.bloq"></f7-toggle>
                </f7-list-item>
               </f7-list>
    </div>
    <div class="resmund bg-color-white text-align-center" :class="{disabled: obj.bloq}">
         <f7-row
         class="bg-color-white text-align-center smbutfix">
             <f7-col
               v-for="diaid in semordenada"
              :key="diaid"
             >
                  <f7-segmented  :class="{disabled: diassemana[diaid].bloq}">
                    <f7-button @click="chgvalbloqdia(hini,diaid,false)">-</f7-button>
                    <f7-button class="fclr" :class="{zerofclr: !diassemana[diaid].slots[hini] || !diassemana[diaid].slots[hini].length}" >{{diassemana[diaid].slots[hini]?diassemana[diaid].slots[hini].length:0}}</f7-button>
                    <f7-button @click="chgvalbloqdia(hini,diaid,true)">+</f7-button>
                </f7-segmented>
             </f7-col>
         </f7-row>
    </div>
</div>
</f7-block>
    </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { commonmixin } from '../mixins/common'

export default {
    mixins: [commonmixin],
    props:['hoyx'],
    data() {
      return {
          yaexistemolde: true,
          hinicio: '09:00',
        htermino: '18:00',
        minperpac: 20,
         cargando: true,
         diassemana:{
             lu:{
                 id: 'lu',
                 nam: 'Lunes',
                 concupacs: 1,
                 bloq: false,
                 slots:{}
             },
             ma:{
                id: 'ma',
                 nam: 'Martes',
                 concupacs: 1,
                bloq: false,
                 slots:{}
             },
             mi:{
                id: 'mi',
                 nam: 'Miércoles',
                 concupacs: 1,
                 bloq: false,
                 slots:{}
             },
             ju:{
                id: 'ju',
                 nam: 'Jueves',
                 concupacs: 1,
                 bloq: false,
                 slots:{}
             },
             vi:{
                id: 'vi',
                 nam: 'Viernes',
                 concupacs: 1,
                 bloq: false,
                 slots:{}
             },
             sá:{
                id: 'sá',
                 nam: 'Sabado',
                 concupacs: 1,
                 bloq: false,
                 slots:{}
             },
             do:{
                  id: 'do',
                 nam: 'Domingo',
                 concupacs: 1,
                  bloq: false,
                 slots:{}
             }
         },
         horasComputadas:{},
          //validar abajo
      
           startTimePicker:null,
           endTimePicker: null,
           miperpacPicker: null,
           defSettings:{
                hinicio: '09:00',
                htermino: '18:00',
                minperpac: 20,
           },
            semordenada: ['lu','ma','mi','ju','vi','sá','do'],
           arraymins: ['00','05','10','15','20','25','30','35','40','45','50','55'],
           arrayhoras: ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']
      }
    },
    computed:{
     ...mapState(['centerInFoco','agenda_molde_activa']),
    },
    watch:{
        centerInFoco(nuevocentro){
            console.log('ATENTO, CAMBIO DE CENTRO',nuevocentro);
           this.$f7.views.main.router.back();
        }
    },
    methods: {
        async cargainicial(){
            try {
            this.cargando = true;
            this.$f7.dialog.preloader('Cargando...');
            //prrimero traer todas las fongis
            let configs = await this.$firebase.database().ref(`agenda_molde/${this.centerInFoco}`).once('value');
            if(!configs.exists()){
                this.yaexistemolde = false;
                await this.computslots();
                this.montarPickers();
            }
            else{
                this.cargando = false;
                this.$f7.dialog.close();
                let allinfox= configs.val();
                Object.keys(allinfox.diassemana).forEach(undiaid => {
                   if(!allinfox.diassemana[undiaid].slots){
                      allinfox.diassemana[undiaid].slots={};
                   }
                });
                this.diassemana = Object.assign({},allinfox.diassemana);
                this.hinicio = allinfox.hinicio;
                this.htermino = allinfox.htermino;
                this.minperpac = allinfox.minperpac;
                this.horasComputadas = Object.assign({},allinfox.horasComputadas);
            }
            

             } catch (error) {
            this.$f7.dialog.close();
             this.cargando = false;
              console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
        },
        async computslots(){
            try {
            this.cargando = true;
            let datous = await this.commonExecute({ hinicio:this.defSettings.hinicio, htermino:this.defSettings.htermino, minperpac:this.defSettings.minperpac },'admin-slotesdeagenda_computa',false);
            if(datous.error){
              throw new Error(datous.error.message);
            }
              this.$f7.dialog.close();
               this.cargando = false;
             let recep = datous.payload || {};
             Object.keys(recep).forEach(unhini => {
                 Object.keys(this.diassemana).forEach(diasem => {
                       this.$set(this.diassemana[diasem].slots,unhini,[])
                      if(!this.diassemana[diasem].bloq){
                            let i = 0;
                            while (i < this.diassemana[diasem].concupacs) {
                                this.agregarConcurrencia(diasem,unhini);
                                i++;
                            }
                      }
                });
             });
             this.horasComputadas = Object.assign({},recep);
             console.log('reibi',recep);
             } catch (error) {
             this.cargando = false;
              console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
        },
        async publicar(){
        try {
            console.log('va a tratar de publicar',this.diassemana, this.horasComputadas,this.defSettings);
            this.$f7.dialog.preloader('Guardando...');
            let datous = await this.commonExecute({ diassemana:this.diassemana, horasComputadas:this.horasComputadas,  hinicio:this.defSettings.hinicio, htermino:this.defSettings.htermino, minperpac:this.defSettings.minperpac, vacunatorioid: this.centerInFoco  },'admin-defineMolde',false);
            if(datous.error){
              throw new Error(datous.error.message);
            }
              this.$f7.dialog.close();
             console.log('reibi',datous.payload);
             this.$store.commit('setObjTo',{what:'agenda_molde_activa',to:datous.payload});
             this.avisaOk('Semana molde guardada correctamente');
              this.$f7.views.main.router.back();
             } catch (error) {
            this.$f7.dialog.close();
              console.error(error);
            this.$f7.dialog.alert(error.message, error.code);
          }
        },
        agregarConcurrencia(diasem,hini){
             if(!this.diassemana[diasem].slots[hini]){
                this.$set(this.diassemana[diasem].slots,hini,[])
            }
            let currentthen=Object.values(this.diassemana[diasem].slots[hini]).length;
            this.diassemana[diasem].slots[hini].push({i:currentthen});
        },
        rebajarConcurrencia(diasem,hini){
            let currentthen=Object.values(this.diassemana[diasem].slots[hini]).length;
            if(currentthen>0){
                this.diassemana[diasem].slots[hini].pop();
            }
        },
        bloqdiasem(neoval,diasem){
            console.log(neoval,diasem);
            this.diassemana[diasem].bloq = !neoval;
            if(neoval){
                let i = 0;
                while (i < this.diassemana[diasem].concupacs) {
                    Object.keys(this.horasComputadas).forEach(hini => {
                        if(!this.horasComputadas[hini].bloq){
                            this.agregarConcurrencia(diasem,hini);
                        }
                    });
                    i++;
                }
            }
            else{
                this.$set(this.diassemana[diasem],'slots',{})
            }
        },
        chvaldiasem(diasem,add){
            let valactual = this.diassemana[diasem].concupacs;
            if(add && valactual<100){
                this.diassemana[diasem].concupacs++;
                Object.keys(this.horasComputadas).forEach(hini => {
                    if(!this.horasComputadas[hini].bloq){
                        this.agregarConcurrencia(diasem,hini);
                    }
                });
            }
            else if(!add && valactual>1){
                 this.diassemana[diasem].concupacs--;
                 Object.keys(this.horasComputadas).forEach(hini => {
                     if(!this.horasComputadas[hini].bloq){
                            this.rebajarConcurrencia(diasem,hini);
                     }
                });
            }
        },
        cambiarval(evento,slot){
            this.horasComputadas[slot].bloq = !evento;
            if(evento){
                Object.keys(this.diassemana).forEach(diasem => {
                    if(!this.diassemana[diasem].bloq){
                        let i = 0;
                        while (i < this.diassemana[diasem].concupacs) {
                            this.agregarConcurrencia(diasem,slot);
                            i++;
                        }
                    }
                });
            }
            else{
                Object.keys(this.diassemana).forEach(diasem => {
                    this.$set(this.diassemana[diasem].slots,slot,[])
                });
            }
        },
        chgvalbloqdia(hini,diaid,add){
            let valactual=Object.values(this.diassemana[diaid].slots[hini]).length;
            if(add && valactual<100){
                this.agregarConcurrencia(diaid,hini)
            }
            else if(!add && valactual>0){
                 this.rebajarConcurrencia(diaid,hini);
            }
        },
        //validar de aqui a abajo
        montarPickers(){
             if(!this.miperpacPicker){
             this.miperpacPicker  = this.$f7.picker.create({
            inputEl: '#minperpackpicker',
            value: [this.defSettings.minperpac],
            on: {
                closed: (picker)=> {
                   this.cambiaTimpopac();
                }
            },
             rotateEffect: true,
                toolbarCloseText: 'Cerrar',
                cols: [
                {
                    values: [ ...Array(60).keys() ].map( i => i+1)
                }
                ]
        });
        }
         if(!this.startTimePicker){
        this.startTimePicker = this.$f7.picker.create({
            inputEl: '#statTimePicker',
            value: this.defSettings.hinicio.split(':'),
            on: {
                closed: (picker)=> {
                   let terminoComputado = this.computaHoraenBaseA('hinicio');
                    this.setOptionsForHtermino(terminoComputado);
                    this.computslots();
                },
                opened:(picker)=>{
                    //al abrir setear las opciones , que es lo mas tarde que puedo empezar en base al termino y duracion
                    let inicioComputado = this.horaMasTardiaDeInicio();//esto es lo mas tarde que podria iniciar
                    this.setOptionsForHinicio(inicioComputado);
                }
            },
             rotateEffect: true,
                toolbarCloseText: 'Cerrar',
                formatValue: (values, displayValues)=> {
                    return values[0] + ':' + values[1];
                },
                cols: [
                {
                    values: this.arrayhoras
                },
                {
                    divider: true,
                    content: ':'
                    },
                {
                    values: this.arraymins
                },
                ]
        });
        }
        if(!this.endTimePicker){
        this.endTimePicker = this.$f7.picker.create({
            inputEl: '#endTimePicker',
            value: this.defSettings.htermino.split(':'),
              rotateEffect: true,
                toolbarCloseText: 'Cerrar',
                formatValue: (values, displayValues)=> {
                    return values[0] + ':' + values[1];
                },
                cols: [
                {
                    values: this.arrayhoras
                },
                {
                    divider: true,
                    content: ':'
                    },
                {
                    values: this.arraymins
                },
                ],
            on: {
                opened: (picker)=>{
                    let terminoComputado = this.computaHoraenBaseA('hinicio');
                    this.setOptionsForHtermino(terminoComputado);
                },
                closed: (picker)=> {
                    this.computslots();
                },
            },
            });
        }
        },
        cambiaTimpopac(){
            this.defSettings.minperpac = this.miperpacPicker.getValue()[0];
           //computar horario de cierre en base a aprtura mas 1 paciente
           let cierreComputado = this.computaHoraenBaseA('hinicio');
            //si el cierre computado es MENOR en HHMM al inicio, es manana, no sirve
            let iniarray=this.defSettings.hinicio.split(':');
             let horarioInicioMoment=this.$dayjs().set({'hour': parseInt(iniarray[0]), 'minute': parseInt(iniarray[1])});
             let cierreComputadoMoment=this.$dayjs().set({'hour': parseInt(cierreComputado[0]), 'minute': parseInt(cierreComputado[1])});
            console.log('CIERRE COMPUTADO ES',cierreComputado);
            let finarray=this.defSettings.htermino.split(':');
             let horarioTerminoMoment=this.$dayjs().set({'hour': parseInt(finarray[0]), 'minute': parseInt(finarray[1])});
            let maximaHora=this.$dayjs().set({'hour': 23, 'minute': 55});
            if(cierreComputadoMoment.isBefore(horarioInicioMoment, 'minute') || cierreComputadoMoment.isAfter(maximaHora, 'minute')){
                //en este caso hay que setear la hora de termino como el maximo (23:55)
                //hora de inicio computado en base al fin y dur por pac
                let inicioComputado = this.horaMasTardiaDeInicio();//esto es lo mas tarde que podria iniciar
                this.setOptionsForHinicio(inicioComputado);
                this.startTimePicker.setValue([inicioComputado[0],arreglodeminutos[arreglodeminutos.length-1]], 350);
            }
            else if(horarioTerminoMoment.isBefore(cierreComputadoMoment, 'minute')){
                console.log('TERMINO COMPUTADO ES DESPUES DEL TERMINO SETEADO, HACER ALGO')
                //si la hora de termino actual es menor a la cierre computado, setear hora termino minima
                let arregloDeHoras = this.arrayhoras.filter(unahor=>{
                    return parseInt(unahor)>=parseInt(cierreComputado[0]);
                });
                //computar arreglo minutos tb
                let arreglodeminutos = this.arraymins.filter(unmin=>{
                    return parseInt(unmin)>=parseInt(cierreComputado[1]);
                });
                 if(this.endTimePicker && this.endTimePicker.cols && this.endTimePicker.cols[0]){
                    this.endTimePicker.cols[0].replaceValues(arregloDeHoras);
                    this.endTimePicker.cols[2].replaceValues(arreglodeminutos);
                }
                this.endTimePicker.setValue([cierreComputado[0],arreglodeminutos[0]], 350);
            }
            this.computslots();
        },
         computaHoraenBaseA(cual){
            let horaminarray=this.defSettings[cual].split(':');
            let prehort=this.$dayjs().set({'hour': parseInt(horaminarray[0]), 'minute': parseInt(horaminarray[1])});
            let hortrm;
            if(cual=='hinicio'){
                hortrm=prehort.add(this.defSettings.minperpac, 'm');
            }
            else{
                hortrm=prehort.subtract(this.defSettings.minperpac, 'm');
            }
            return [hortrm.format('HH'),hortrm.format('mm')];
        },
        horaMasTardiaDeInicio(){
            let prehort=this.$dayjs().set({'hour': 23, 'minute': 55});
            let hortrm=prehort.subtract(this.defSettings.minperpac, 'm');
            return [hortrm.format('HH'),hortrm.format('mm')];
        },
        setOptionsForHinicio(inicioComputado){
            console.log('INICIO MAS TARDIO POSIBLE COMPITADO',inicioComputado);
            let arregloDeHoras = this.arrayhoras.filter(unahor=>{
                return parseInt(unahor)<=parseInt(inicioComputado[0]);
            });
            console.log('HORAS PARA CARGAR EN SELECTOR',arregloDeHoras)
            if(this.startTimePicker && this.startTimePicker.cols && this.startTimePicker.cols[0]){
                let hubocambio=false;
                console.log('COLUMNAS YA EXISTEN, AJUSTAR')
                let valorInicioActual = this.defSettings.hinicio.split(':');
                //cambiar horas solo si es distinto
                let elementosHorColumHorActual = this.startTimePicker.cols[0].items.length;
                if(arregloDeHoras.length!==elementosHorColumHorActual){
                    hubocambio=true;
                    this.startTimePicker.cols[0].replaceValues(arregloDeHoras);
                }
                let elementosMinColumMinActual = this.startTimePicker.cols[2].items.length;
                //computar arreglo minutos tb
                let arreglodeminutos = this.arraymins.filter(unmin=>{
                    return parseInt(unmin)<=parseInt(inicioComputado[1]);
                });
                if((valorInicioActual[0]== inicioComputado[0]) && (arreglodeminutos.length!==elementosMinColumMinActual)){
                    //hay que reemplazar los minutos
                    hubocambio=true;
                    this.startTimePicker.cols[2].replaceValues(arreglodeminutos);
                }
                else if((valorInicioActual[0]!== inicioComputado[0]) && (this.arraymins.length!==elementosMinColumMinActual)){
                    //full minutos
                    hubocambio=true;
                    this.startTimePicker.cols[2].replaceValues(this.arraymins);
                }
                //setear valor despues de haber cambiado las opciones
                if(hubocambio){
                    this.startTimePicker.setValue(valorInicioActual, 350);
                }
            }
            else{
                console.log('COLUMNAS NO EXISTEN, NADA QUE AJUSTAR')
            }
        },
        cambiaInicio(inicio){
            console.log('nuevo valor iicio',inicio);
            if(inicio.split(':')[0]!=this.defSettings.hinicio.split(':')[0] && inicio!='00:00'){
                this.defSettings.hinicio = inicio;
                console.log('CAMBIO INCLUYE HORAS, DISPARA');
                let inicioComputado = this.horaMasTardiaDeInicio();//esto es lo mas tarde que podria iniciar
                 this.setOptionsForHinicio(inicioComputado);
            }
            else{
                this.defSettings.hinicio = inicio;
            }
        },
        setOptionsForHtermino(terminoComputado){
            console.log('TERMINO MAS TEMPRANO POSIBLE COMPITADO',terminoComputado);
            let arregloDeHoras = this.arrayhoras.filter(unahor=>{
                return parseInt(unahor)>=parseInt(terminoComputado[0]);
            });
            console.log('HORAS PARA CARGAR EN SELECTOR',arregloDeHoras);
             let valorTerminoActual = this.defSettings.htermino.split(':');
                  //computar arreglo minutos tb
                let arreglodeminutos = this.arraymins.filter(unmin=>{
                    return parseInt(unmin)>=parseInt(terminoComputado[1]);
                });
            if(this.endTimePicker && this.endTimePicker.cols && this.endTimePicker.cols[0]){
                let hubocambio=false;
                //cambiar horas solo si es distinto
                let elementosHorColumHorActual = this.endTimePicker.cols[0].items.length;
                if(arregloDeHoras.length!==elementosHorColumHorActual){
                    hubocambio=true;
                    this.endTimePicker.cols[0].replaceValues(arregloDeHoras);
                }
                let elementosMinColumMinActual = this.endTimePicker.cols[2].items.length;
           
                if((valorTerminoActual[0]== terminoComputado[0]) && (arreglodeminutos.length!==elementosMinColumMinActual)){
                    //hay que reemplazar los minutos
                    console.log('REEMPLAZANDO LOS MINUTOS')
                    hubocambio=true;
                    this.endTimePicker.cols[2].replaceValues(arreglodeminutos);
                    //if minutos valor termino actual es MENOR al menor de los arreglo de minutos, cambiar el actual al menor
                    if(valorTerminoActual[1] < arreglodeminutos[0]){
                        console.log('Cabmiando valor actual de minutos');
                        valorTerminoActual[1] = arreglodeminutos[0];
                    }
                }
                else if((valorTerminoActual[0]!== terminoComputado[0]) && (this.arraymins.length!==elementosMinColumMinActual)){
                    //full minutos
                    hubocambio=true;
                    this.endTimePicker.cols[2].replaceValues(this.arraymins);
                }
                //setear valor despues de haber cambiado las opciones
                if(hubocambio){
                    console.log('SETEANDO VALOR DEL PICKER',valorTerminoActual);
                    this.endTimePicker.setValue(valorTerminoActual, 350);
                }
            }
            else{
                //si la hora termino actual es antes de la hora de termino computada, cambiar
                let cierreComputadoMoment=this.$dayjs().set({'hour': parseInt(terminoComputado[0]), 'minute': parseInt(terminoComputado[1])});
                let horarioTerminoMoment=this.$dayjs().set({'hour': parseInt(valorTerminoActual[0]), 'minute': parseInt(valorTerminoActual[1])});
                if(horarioTerminoMoment.isBefore(cierreComputadoMoment, 'minute')){
                    this.endTimePicker.setValue([terminoComputado[0],arreglodeminutos[0]], 350);
                }

            }
        },
         cambiaTermino(termino){
            console.log('nuevo valor termino',termino);
            if(termino.split(':')[0]!=this.defSettings.htermino.split(':')[0] && termino!='00:00'){
                this.defSettings.htermino = termino;
                console.log('CAMBIO INCLUYE HORAS, DISPARA');
                let terminoComputado = this.computaHoraenBaseA('hinicio');
                 this.setOptionsForHtermino(terminoComputado);
            }
            else{
                this.defSettings.htermino = termino;
            }
        }
    },
};
</script>

<style >

.fw100.prim{
    height: 85px!important;
}
.fw100{
    width: 100%!important;
}
.primcol{
    width: 175px;
    float: left;
}

.resmund{
    width: calc(100% - 175px);
    float: left;
}
.primcol li{
    height: 36px!important;
}
.primcol li .hrx{
    height: 36px!important;
    line-height: 36px!important;
}

.smbutfix .button{
    height: 29px!important;
    line-height: 29px!important;
    margin-top: 3px!important;
}
.fixaltx{
        height: 36px!important;

}
.boradroun ul{
    border-radius: 10px;
}
.centesver{
    line-height: 80px;
}
.fclr{
    background: #c8dffd;
    color: #07006d;
}
.zerofclr{
    background: #e1edfd!important;
    color: #8c8c8c!important;
}
.row.f13r .col{
   font-size: 13px;
}
</style>
