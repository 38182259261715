import Vue from "vue";

export default {
    namespaced: true,

    state: {
        currentRegistro: null,
    },

    mutations: {
        set_current_registro(state, registro) {
            state.currentRegistro = registro;
        },

        update_current_registro(state, registro) {
            const updatedRv = {
                ...state.currentRegistro.rv,
                ...registro,
            }

            state.currentRegistro = {
                ...state.currentRegistro,
                rv: updatedRv
            };
        },
    },
};
