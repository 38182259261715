<template>
    <f7-popup
        :opened="opened"
        @popup:opened="init"
        @popup:closed="handleClosePopup"
        :close-by-backdrop-click="false"
    >
        <f7-page>
            <f7-navbar title="Agregando vacuna">
                <f7-nav-right>
                    <f7-link @click="handleClosePopup" >Cancelar</f7-link>
                </f7-nav-right>
            </f7-navbar>

            <f7-block v-if="opened">
                <template>
                    <f7-list media-list>
                        <f7-list-item
                            class="margin-vertical"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: 'Buscar vacuna',
                                popupCloseLinkText: 'Cerrar',
                                closeOnSelect: true,
                                setValueText: false,
                            }"
                            :disabled="!sortedVacunasHomologacion.length"
                            required
                        >
                            <div slot="header" class="header-style">{{ 'Vacuna recibida' }} <span class="text-color-red">*</span></div>
                            <div slot="inner">{{ editingVacuna.vac.name || "" }}</div>
                            <select @change="handleVacunaSelected($event.target.value)" :value="editingVacuna.vac.id" name="vacuna">
                                <option v-for="(vacuna, index) in sortedVacunasHomologacion" :key="index" :value="vacuna.vacunaPcId">
                                    {{ vacuna.vacunaPcName }}
                                </option>
                            </select>
                        </f7-list-item>
                        <f7-list-item>
                            <span slot="header" class="header-style">
                                {{ 'Fecha de vacunación' }} <span class="text-color-red">*</span>
                            </span>
                            <f7-row slot="footer" class="margin-top-half">
                                <f7-col width="20">
                                    <f7-input
                                        type="select"
                                        outline
                                        @change="splittedDate.dia = Number($event.target.value)"
                                        :value="splittedDate.dia"
                                    >
                                        <option v-for="dia in 31" :key="dia" :value="dia">{{ dia }}</option>
                                    </f7-input>
                                </f7-col>
                                <f7-col width="50">
                                    <f7-input
                                        type="select"
                                        outline
                                        class="inputmes"
                                        @change="splittedDate.mes = Number($event.target.value)"
                                        :value="splittedDate.mes"
                                    >
                                        <option v-for="(mes, idx) in $dayjs.months()" :key="idx + 1" :value="idx + 1">{{ mes }}</option>
                                    </f7-input>
                                </f7-col>
                                <f7-col width="30">
                                    <f7-input
                                        type="select"
                                        outline
                                        class="inputano"
                                        @change="splittedDate.ano = Number($event.target.value)"
                                        :value="splittedDate.ano"
                                    >
                                        <option v-for="(year, index) in vaccineYearsTillNow" :key="index" :value="year">{{ year }}</option>
                                    </f7-input>
                                </f7-col>
                            </f7-row>
                        </f7-list-item>
                        <f7-list-input
                            type="text"
                            :value="editingVacuna.establecimiento"
                            @input="editingVacuna.establecimiento = $event.target.value"
                        >
                            <span slot="label">
                                {{ 'Establecimiento' }} <span class="text-color-red">*</span>
                            </span>
                        </f7-list-input>
                        <!-- <f7-list-item
                            class="margin-vertical"
                            smart-select
                            :smart-select-params="{
                                openIn: 'popup',
                                searchbar: { removeDiacritics: true },
                                searchbarPlaceholder: 'Buscar comuna',
                                popupCloseLinkText: 'Cerrar',
                                closeOnSelect: true,
                                setValueText: false,
                            }"
                            required
                        >
                            <div slot="header" class="header-style">{{ 'Comuna Establecimiento' }} <span class="text-color-red">*</span></div>
                            <div slot="inner">
                                {{ editingVacuna.address.comuna ? editingVacuna.address.provincia.split('-')[2] + ' | ' + editingVacuna.address.region_hr : '' }}
                            </div>
                            <select @change="handleComunaSelected($event.target.value)" name="comuna">
                                <template v-for="region in comunas">
                                    <optgroup
                                        v-for="provincia in region.provincias"
                                        :key="region.region_number + provincia.name"
                                        :label="region.region + ': ' + provincia.name"
                                    >
                                        <option
                                            v-for="comuna in provincia.comunas"
                                            :selected="
                                                selectedComuna ==
                                                comuna.name + '|' + provincia.name + '|' + region.region
                                            "
                                            :key="comuna.code"
                                            :value="
                                                comuna.name + '|' + provincia.name + '|' + region.region_iso_3166_2 + '|' + comuna.code+ '|' + region.region
                                            "
                                        >
                                            {{ comuna.name }} ({{provincia.name}})
                                        </option>
                                    </optgroup>
                                </template>
                            </select>
                        </f7-list-item> -->
                        <f7-list-input
                            type="text"
                            :value="editingVacuna.lote"
                            @input="editingVacuna.lote = $event.target.value"
                        >
                            <span slot="label">
                                {{ 'Número de lote' }} <span class="text-color-red">*</span>
                            </span>
                        </f7-list-input>
                    </f7-list>
                    <f7-button
                        fill
                        large
                        @click="saveVacuna"
                        class="margin-bottom"
                    >
                        {{ 'Guardar Vacuna' }}
                    </f7-button>
                </template>
            </f7-block>
        </f7-page>
    </f7-popup>
</template>

<script>
import { mapGetters } from "vuex";
import selectComuna from "../../../components/selectComuna.vue";
import { comunasHelper } from "@/js/comunas.js"

export default {
    mixins: [comunasHelper],
    components: { selectComuna },
    props: {
        opened: {
            type: Boolean,
            required: true,
        },
        vaccine: {
            required: true,
        },
    },
    data() {
        return {
            editingVacuna: {
                id: null,
                fechavac: null,
                lote: null,
                establecimiento: null,
                // address: {
                //     code: null,
                //     comuna: null,
                //     provincia: null,
                //     region: null,
                //     region_hr: null,
                // },
                p: {
                    iso2: 'CL',
                    name: 'Chile',
                },
                vac: {
                    id: null,
                    name: null,
                    labName: null,
                },
            },

            splittedDate: {
                dia: null,
                mes: null,
                ano: null,
            },
        };
    },
    watch: {
        "splittedDate.dia": {
            handler() {
                if (!this.validateDate())
                    this.$f7.dialog.alert("Fecha vacunación inválida");
            },
        },
        "splittedDate.mes": {
            handler() {
                if (!this.validateDate())
                    this.$f7.dialog.alert("Fecha vacunación inválida");
            },
        },
    },
    computed: {
        ...mapGetters("homologacionVacunasNac", ["sortedVacunasHomologacion"]),
        mergedDate() {
            if (!this.splittedDate.dia || !this.splittedDate.mes || !this.splittedDate.ano) {
                return null;
            }

            return `${this.pad(this.splittedDate.dia, 2)}/${this.pad(this.splittedDate.mes, 2)}/${this.splittedDate.ano}`;
        },
        vaccineYearsTillNow() {
            const currentYear = this.$dayjs().year();
            return Array.from(Array(currentYear - 1960 + 1).keys()).map(year => year + 1960);
        },
        // selectedComuna() {
        //     return this.editingVacuna.address.comuna ? this.editingVacuna.address.comuna +
        //             "|" +
        //             this.editingVacuna.address.provincia +
        //             "|" +
        //             this.editingVacuna.address.region
        //             : "a|b|c";
        // },
    },
    methods: {
        async init() {
            if (this.vaccine) {
                this.editingVacuna = Object.assign({}, this.vaccine);

                if (this.editingVacuna.fechavac) {
                    this.splittedDate = {
                        dia: Number(this.editingVacuna.fechavac.split("/")[0]),
                        mes: Number(this.editingVacuna.fechavac.split("/")[1]),
                        ano: Number(this.editingVacuna.fechavac.split("/")[2]),
                    };
                }
            }

            if (!this.sortedVacunasHomologacion.length) {
                const vacunasSnap = await this.$firebase.database().ref("vacunas").orderByChild("show_hmlg_nac").equalTo(true).once("value");
                const vacunas = vacunasSnap.val() || {};

                this.$store.commit("homologacionVacunasNac/set_vacunas_homologacion", vacunas);
            }
        },
        async saveVacuna() {

            if (!this.mergedDate) {
                return this.$f7.dialog.alert("Fecha vacunación requerida");
            }
            
            if (!this.validateDate()) {
                return this.$f7.dialog.alert("Fecha vacunación inválida");
            }

            if (!this.editingVacuna.vac.id) {
                return this.$f7.dialog.alert("Vacuna recibida requerida");
            }

            if (!this.editingVacuna.establecimiento) {
                return this.$f7.dialog.alert("Establecimiento requerido");
            }

            if (!this.editingVacuna.lote) {
                return this.$f7.dialog.alert("Número de lote requerido");
            }

            // if (!this.editingVacuna.address.code) {
            //     return this.$f7.dialog.alert("Comuna establecimiento requerida");
            // }
            
            this.editingVacuna.fechavac = this.mergedDate;
            
            this.$emit("modified", this.editingVacuna);
            this.handleClosePopup();
        },
        handleClosePopup() {
            this.$emit("popup-closed");
            this.editingVacuna = {
                id: null,
                fechavac: null,
                lote: null,
                establecimiento: null,
                // address: {
                //     code: null,
                //     comuna: null,
                //     provincia: null,
                //     region: null,
                //     region_hr: null,
                // },
                p: {
                    iso2: 'CL',
                    name: 'Chile',
                },
                vac: {
                    id: null,
                    name: null,
                    labName: null,
                },
            };
            this.splittedDate = {
                dia: null,
                mes: null,
                ano: null,
            };
        },
        handleVacunaSelected(vacId) {
            const vacuna = this.sortedVacunasHomologacion.find(vacuna => vacuna.vacunaPcId === vacId);
            if (!vacuna) {
                this.$f7.dialog.close();
                this.$f7.dialog.alert("No se encontró la vacuna seleccionada");
                return;
            }

            this.$set(this.editingVacuna.vac, "id", vacId);
            this.$set(this.editingVacuna.vac, "name", vacuna.vacunaPcName);
            this.$set(this.editingVacuna.vac, "labName", vacuna.laboratorioName);
        },
        handleYearSelected(target) {
            this.splittedDate.ano = Number(target.value);
            if (this.splittedDate.ano > 1960) {
                target.blur();
            }
        },
        validateDate() {
            if (this.mergedDate && !this.$dayjs(this.mergedDate, "DD/MM/YYYY", true).isValid() ) {
                return false;
            }
            else if( this.$dayjs().isBefore( this.$dayjs(this.mergedDate, "DD/MM/YYYY"),'day' )){
                return false;
            }
            else if(this.splittedDate.dia && this.splittedDate.mes && this.splittedDate.ano && this.splittedDate.ano<1960){
                return false;
            }
            return true;
        },
        pad(num, size) {
            num = num.toString();

            if (num.length > 1) {
                return num;
            }

            while (num.length < size) num = "0" + num;
            return num;
        },
        // handleComunaSelected(comuna) {
        //     let linfo = comuna.split("|");
        //     this.editingVacuna.address = {
        //         comuna: linfo[0],
        //         region: linfo[2],
        //         provincia: `${linfo[2]}-${linfo[1]}`,
        //         region_hr: linfo[4],
        //         code: linfo[3],
        //     };
        // },
    }
}
</script>

<style>
.header-style {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    vertical-align: top;
}
</style>